import React, { ChangeEvent, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavigateFunction, useLocation, useNavigate, type Location } from 'react-router-dom';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import OrdersController from '@/controllers/OrdersController';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IOrderByCodeResponse } from '@/domain/interfaces/IOrder';

import lastPageName from '@/domain/utils/lastPageName';

import ErrorImage from '@/ui/assets/ErrorImage';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import InputForm from '@/ui/components/forms/InputForm';

import TrackingOrderDetails from '@/ui/pages/sales/TrackingOrderDetails/index';

/**
 * Página de búsqueda de ordenes
 * @returns {JSX.Element} Página de búsqueda de ordenes
 */
const SearchOrder = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /**Controlador para obtener las órdenes */
  const { getOrdersByCode } = OrdersController();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  //Estado para el valor del input
  const [inputValue, setInputValue] = useState<string>('');

  //Estado para el valor del input
  const [order, setOrder] = useState<IOrderByCodeResponse | null>(null);

  /**Estado de error del input de descripción */
  const [descriptionInputWithError, setDescriptionInputWithError] = useState<boolean>(false);
  /** Declaración inicial de los mensajes de error como vacíos para el campo de descripción  */
  const [descriptionMessageError, setDescriptionMessageError] = useState<string | undefined>('');

  //función para capturar el valor del input
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescriptionInputWithError(false);
    setInputValue(e.target.value);
  };

  //Función para buscar la orden
  const getOrdersBack = async (): Promise<void> => {
    await getOrdersByCode(inputValue).then((order: IOrderByCodeResponse | null) => {
      if (order === null) {
        setDescriptionMessageError('ID de compra incorrecto.');
        setDescriptionInputWithError(true);
      } else {
        navigate(RoutesDirections.TRACKING_ROUTE_DETAIL, { state: { order } });
      }
    });
  };

  /**Imagen de seguimiento */
  const TRACKING_IMAGE_URL: string = `${URL_IMAGES}/client/seguimiento.png`;

  /**Constantes de texto */
  const TRACKING_TITLE_TEXT = 'Seguimiento de compra';
  const TRACKING_DESCRIPTION_TEXT =
    'Descubre el estado de tu pedido de forma rápida y sencilla. Ingresa el ID de compra que recibiste en tu correo electrónico.';
  const SEARCH_BUTTON_TEXT = 'BUSCAR';

  /**Renderizacion de componente general */
  return order ? (
    <TrackingOrderDetails />
  ) : (
    <Container fluid className='bg-secondary-5 p-0'>
      <Container className={`p-0 bg-secondary-5 ${isMobile ? 'pb-2' : 'pb-5'}`}>
        <Row className='g-0 pt-2'>
          <Col lg={12} className={`p-2 bg-primary-4 shadow ${!isMobile ? 'rounded' : ''}`}>
            <Breadcrumbs list={breadcrumbsList} />
          </Col>
        </Row>
        <Row className={`g-0 bg-primary-4 shadow ${!isMobile ? 'rounded' : ''}  mt-2 mt-lg-4`}>
          <Col className='text-center'>
            <h2 className='text-primary-3 text-center my-3'>{TRACKING_TITLE_TEXT}</h2>
            <Image
              src={TRACKING_IMAGE_URL}
              alt='seguimiento de orden'
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
            <Col xs={11} lg={4} className='px-lg-4 my-4 mx-auto text-justify'>
              {TRACKING_DESCRIPTION_TEXT}
              <Col className='mt-4'>
                <InputForm
                  label='ID de la compra'
                  classLabel='text-secondary-2 p-regular'
                  type='text'
                  classMain='text-secondary-3 fw-bold mb-3'
                  className='bg-primary-4 '
                  withErrors={descriptionInputWithError}
                  messageError={descriptionMessageError}
                  onChange={handleInputChange}
                />
                <Button
                  className={`w-100 svg-primary-4 btn-primary-icon-standard ${inputValue.length < 1 ? 'disabled' : ''} `}
                  onClick={getOrdersBack}>
                  {SEARCH_BUTTON_TEXT}
                </Button>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SearchOrder;
