import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';

import IconList from '@/ui/components/IconList';

/**
 * Detallar componente
 * @component
 */
const OptionsEmptyList = () => {
  /**
   * Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido
   */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  return (
    <ul className="list-unstyled svg-primary-1 text-secondary-3">
      <IconList
        classMain="p-2 my-1"
        iconName={'ico-success'}
        classTitle={`${isMobile ? 'p-regular' : 'p-large'}`}
        title="Revisa la ortografía de las palabras que escribiste"
      />
      <IconList
        classMain="p-2 my-1"
        iconName={'ico-success'}
        classTitle={`${isMobile ? 'p-regular' : 'p-large'}`}
        title="Procura escribir en la barra de búsqueda palabras más genéricas o menos palabras"
      />
      <IconList
        classMain="p-2 my-1"
        iconName={'ico-success'}
        classTitle={`${isMobile ? 'p-regular' : 'p-large'}`}
        title="Verifica y reconfigura los filtros aplicados"
      />
      <IconList
        classMain="p-2 my-1"
        iconName={'ico-success'}
        classTitle={`${isMobile ? 'p-regular' : 'p-large'}`}
        title="Navega por las siguientes categorías, quizás encuentres productos"
      />
    </ul>
  );
};

export default OptionsEmptyList;
