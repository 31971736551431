/** Valida el formato del email
  * @param {string} name
  * @returns {boolean}
*/
const useNameValidation = (name: string): boolean => {

    /**
      * Almacena un patron para validar formato de name
      * @const {RegExp};
    */
    const expression: RegExp = /^([a-zA-Z\sÁÉÍÓÚáéíóúÑñ]{3,100})$/;


    /** Almacena el resultado de evaluar la expresion
      * @const {boolean}
    */
    const result: boolean = expression.test(name);
  
    return result;
  }
  
  export default useNameValidation;