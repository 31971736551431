import { PropsWithChildren } from 'react';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { Col, Container, Image, Row } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

/** Propiedades para el componente MessageLayout */
interface PropsMessageLayout {
  /** Titulo principal de la interface */
  title: string;
  /** Url de origen de la imagen */
  imgSrc: string;
  /** Tamaño en ancho de la imagen (532px por defecto) */
  imgWidth?: string;
  /** Descripción de la interface */
  description: string;
  /** Texto del link */
  linkText: string;
  /** Ruta del enlace */
  route: string;
  /** Función opcional al hacer click */
  onClick?: () => void;
}

/** Componente contenedor de mensajes genericos
 *@component
 */
const MessageLayout = ({
  title,
  imgSrc,
  description,
  linkText,
  route,
  imgWidth = '532px',
  onClick,
}: PropsWithChildren<PropsMessageLayout>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para generar una navegación dentro de la aplicación.  */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Titulo principal */
  const MAIN_TITLE = title;
  const TITLE_DESCRIPTION = description;
  const TITLE_LINK = linkText;

  /** Función que nos lleva a la página inicial */
  const handleNavigate = () => {
    route === '-1' ? navigate(-1) : navigate(route, { state: { originURL: location.pathname } });
    onClick && onClick();
  };

  /** Renderiza el componente de MessageLayout */
  return (
    <Container fluid className='p-0 bg-primary-4  d-flex align-items-center justify-content-center'>
      <Row className='bg-primary-4 g-0 '>
        <Col xs={12} className='my-5 px-5 d-flex justify-content-center'>
          <Image
            src={imgSrc}
            alt={linkText}
            width={isMobile ? '100%' : imgWidth}
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        </Col>
        <Col xs={12} className='d-flex justify-content-center '>
          <span className=' h2 fw-bold text-primary-3 text-center'> {MAIN_TITLE} </span>
        </Col>
        <Col xs={12} className='d-flex justify-content-center my-3 '>
          <span className='h5 text-secondary-2 fw-bold text-center'>{TITLE_DESCRIPTION}</span>
        </Col>
        <Col xs={12} className='d-flex justify-content-center my-4 '>
          <button className='svg-18 text-tertiary-4 p-title-bold text-center logo link-text-standard' onClick={handleNavigate}>
            {TITLE_LINK}
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default MessageLayout;
