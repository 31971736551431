import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Col } from 'react-bootstrap';

import { ISelectOptionType } from '@/domain/interfaces/ISelect';

interface PropsDropDownOptionImage {
  options: ISelectOptionType[];
  optionComponent: React.ComponentType<{ data: ISelectOptionType }>;
  defaultValue?: ISelectOptionType;
}

/**
 * Componente tipo Dropdown que recibe componentes para renderizar como "options"
 * @component
 */
const DropDownCustomImage = React.memo(({ options, optionComponent, defaultValue }: PropsDropDownOptionImage): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<ISelectOptionType | null>(defaultValue ?? null);
  const [open, setOpen] = useState(false);

  const handleChange = (option: ISelectOptionType | null) => {
    setSelectedOption(option);
  };

  const CustomOption = ({ data, selectProps }) => {
    const OptionComponent = optionComponent;
    const handleClick = () => {
      setOpen(false);
      selectProps.onChange(data);
    };
    return (
      <Col onClick={handleClick}>
        <OptionComponent data={data} />
      </Col>
    );
  };

  const CustomSingleValue = ({ data }) => {
    const OptionComponent = optionComponent;
    return <OptionComponent data={data} />;
  };

  useEffect(() => {
    setSelectedOption(defaultValue ?? null);
  }, [defaultValue]);

  return (
    <Select<ISelectOptionType>
      options={options}
      value={selectedOption}
      closeMenuOnSelect={true}
      onChange={handleChange}
      components={{
        Option: (props) => <CustomOption {...props} selectProps={{ onChange: handleChange }} />,
        SingleValue: CustomSingleValue,
      }}
      isSearchable={false}
      menuIsOpen={open}
      onMenuOpen={() => setOpen(true)}
      onMenuClose={() => setOpen(false)}
    />
  );
});

export default DropDownCustomImage;
