/**
 * Asigna un formato de moneda a un valor enviado
 * @param {number} value
 * @returns {string} value con formato
 */
const currencyFormat = (value: number | undefined | null, decimalPrecision: number): string => {
  // Si el valor es 0, no se le asigna formato
  let decimals: number = 0;
  // Si el valor es decimal, se asigna la cantidad de decimales
  if (value && value !== 0 && value % 1 !== 0) {
    // Si no se envía la cantidad de decimales, se asigna 0
    decimals = decimalPrecision ?? 0;
  }
  // Se retorna el valor con formato de moneda chilena y la cantidad de decimales asignada o 0 por defecto
  return value ? value.toLocaleString('es-CL', { style: 'currency', currency: 'CLP', minimumFractionDigits: decimals }) : '0';
};

export default currencyFormat;
