import { PropsWithChildren, ReactNode } from 'react';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import IconList from '@/ui/components/IconList';

interface PropsManagementListTitle {
  /** Evento al hacer click al componente */
  onClick?: (params: any) => ReactNode | void;
}

/** Componente List Usuario Administrativo
 * @component
 */
const ManagementListTitle = ({ onClick, children }: PropsWithChildren<PropsManagementListTitle>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  return (
    <>
      <ul id="management-title" className="list-unstyled  d-flex align-items-center svg-primary-3 m-0" onClick={onClick}>
        <IconList
          iconName="ico-admin"
          classMain="p-2 my-1"
          title={['Opciones Administrativas']}
          classTitle={`text-primary-3 ${!isMobile && 'p-title-bold'}`}
        />
        {children}
      </ul>
    </>
  );
};

export default ManagementListTitle;
