import { useRef, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';
import { useResize } from '@/ui/hooks/useScreenSize';

import { Col, Row, Image } from 'react-bootstrap';

import 'react-form-wizard-component/dist/style.css';
import '@/ui/components/formWizard/style.css';

import { useAppSelector } from '@/ui/store/helperRedux';

import ProductsController from '@/controllers/ProductsController';

import { IProductForm } from '@/domain/interfaces/IProduct';
import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IPathCategoryType } from '@/domain/interfaces/ICategories';
import { IProductValidation } from '@/domain/interfaces/IProductResponse';

import lastPageName from '@/domain/utils/lastPageName';

import ErrorImage from '@/ui/assets/ErrorImage';

import FormWizard from 'react-form-wizard-component';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import GifModal from '@/ui/components/modals/GifModal';
import BaseProductName from '@/ui/components/inventory/BaseProductName';
import SelectCategory from '@/ui/components/inventory/SelectCategory';
import BaseProductDescription from '@/ui/components/inventory/BaseProductDescription';
import AddVariant from '@/ui/components/inventory/AddVariant';
import MainVariantAttributes from '@/ui/components/inventory/MainVariantAttributes';
import AlertModal from '@/ui/components/modals/AlertModal';
import TechSpecifications from '@/ui/components/inventory/TechSpecifications';
import BranchOfficeInventory from '@/ui/components/inventory/BranchOfficeInventory';

import ManagementOptionsContainer from '@/ui/containers/managementOptions/ManagementOptionsContainer';

/** Componente Agregar productos wizard
 * @component
 */
const AddProducts = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Estado inicial del modal de alertas */
  const [alertError, setAlertError] = useState<boolean>(false);

  /** Mensaje de error del modal de alerta*/
  const [errorMessage, setErrorMessage] = useState<string>('');

  /** Función para navegar al inventario si no hay problemas */
  const navigateToInventory = (): void => {
    navigate(RoutesDirections.INVENTORY_ROUTE, { state: { originURL: location.pathname, successAlert: true } });
  };

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: 'Inventario', url: RoutesDirections.INVENTORY_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /** Referencia al Botón de ir Atrás */
  const backButtonRef = useRef<HTMLButtonElement | null>(null);

  /** Referencia al Botón de ir Adelante */
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);

  /** Referencia al Botón de Finalizar sin uso
  const finishButtonRef = useRef<HTMLButtonElement | null>(null);*/

  /** Función para ir atrás */
  const handleBackButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    backButtonRef.current && backButtonRef.current.click(); // Simula el clic en el input de archivo oculto
  };

  /** Función para ir adelante */
  const handleNextButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    nextButtonRef.current && nextButtonRef.current.click(); // Simula el clic en el input de archivo oculto
  };

  /**Función para finalizar el registro de producto base*/
  const handleFinishButtonClick = async (e: MouseEvent) => {
    e.preventDefault();
    await addProductBase(formRef.current, true, '', userLogued.user?.token ?? '').then((response: IProductValidation) => {
      if (response.error) {
        setErrorMessage(response.message);
        setAlertError(true);
      } else {
        setErrorMessage('');
        navigateToInventory();
      }
    });
  };

  /** Inicialización del formulario */
  const formRef = useRef<IProductForm>(useCreateBaseProductForm());

  const updateFormRef = (formProduct: IProductForm): void => {
    formRef.current = formProduct;
  };

  /** State para el CategoryPath  */
  const [pathCategoryState, setPathCategoryState] = useState<IPathCategoryType[]>([]);

  /** Actualiza el estado del CategoryPath  */
  const updatePathCategoryState = (newState: IPathCategoryType[]) => {
    setPathCategoryState(newState);
  };

  /** Estado inicial del modal para cancelar registro */
  const [showModal, setShowModal] = useState<boolean>(false);

  /** Evento que muestra y oculta el modal de para cancelar registro */
  const handleShowModal = (): void => {
    setShowModal(!showModal);
  };

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  /** Navega a la interfaz anterior al hacer click   */
  const handleNavigate = (e: React.MouseEvent): void => {
    e.preventDefault();
    navigate(RoutesDirections.INVENTORY_ROUTE, { state: { originURL: location.pathname } });
  };

  /**CONSTANTES DE TEXTO */
  const ADD_BASE_PRODUCT_TITLE: string = 'Agregar producto base';
  const SELECT_CATEGORY_TITLE: string = 'Clasificar producto';
  const BASE_PRODUCT_DESCRIPTION_TITLE: string = 'Descripción producto';
  const ADD_VARIANT_TITLE: string = 'Variante de producto';
  const VARIANT_ATTRIBUTES_TITLE: string = 'Configurar atributos';
  const TECHNICAL_SPECIFICATIONS_TITLE: string = 'Ficha técnica';
  const BRANCH_OFFICE_INVENTORY_TITLE: string = 'Inventario en sucursales';

  const BASE_PRODUCT_TITLE: string = 'Producto: ';
  const VARIANT_TITLE: string = 'Variante de producto: ';
  const CATEGORY_TITLE: string = 'Clasificación: ';

  const CANCEL_CONFIRMATION_TEXT: string = '¿Deseas cancelar el proceso de registro? No se guardarán los cambios realizados.';

  const CANCEL_BUTTON_TEXT: string = 'CANCELAR REGISTRO';
  const SKIP_BUTTON_TEXT: string = 'OMITIR';
  const SAVE_CONTINUE_BUTTON_TEXT: string = 'GUARDAR Y CONTINUAR';
  const DELETE_IMAGE_URL: string = `${URL_IMAGES}/client/doc-delete.png`;

  const fullSize: boolean = useResize() > 1740;

  return (
    /**ManagementOptionsContainer es el contenedor de la pagina de agregar productos*/
    <ManagementOptionsContainer type='management-inventory' classMain='d-flex justify-content-center' breadcrumbs={breadcrumbsList}>
      <Col lg={11}>
        <FormWizard
          onComplete={() => /*handleComplete(true)*/ null}
          /**el void es para que no retorne nada y no de error en la consola de la pagina*/
          backButtonTemplate={(handlePrevious: () => void) => (
            <button className='d-none' ref={backButtonRef} onClick={handlePrevious}>
              back
            </button>
          )}
          /**el void es para que no retorne nada y no de error en la consola de la pagina*/
          nextButtonTemplate={(handleNext: () => void) => (
            <button className='d-none' ref={nextButtonRef} onClick={handleNext}>
              next
            </button>
          )}
          /**el void es para que no retorne nada y no de error en la consola de la pagina*/
          finishButtonTemplate={(handleComplete: () => void) => (
            <button className='d-none' onClick={handleComplete}>
              finish
            </button>
          )}>
          {/*Agregar producto base*/}
          <FormWizard.TabContent title={fullSize ? ADD_BASE_PRODUCT_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <BaseProductName
                  mainTitle={ADD_BASE_PRODUCT_TITLE}
                  formRef={formRef}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*Clasificar producto*/}
          <FormWizard.TabContent title={fullSize ? SELECT_CATEGORY_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <SelectCategory
                  mainTitle={SELECT_CATEGORY_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  productTitle={BASE_PRODUCT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  formRef={formRef}
                  pathCategoryState={pathCategoryState}
                  updatePathCategoryState={updatePathCategoryState}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*Descripción producto*/}
          <FormWizard.TabContent title={fullSize ? BASE_PRODUCT_DESCRIPTION_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <BaseProductDescription
                  mainTitle={BASE_PRODUCT_DESCRIPTION_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  productTitle={BASE_PRODUCT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  formRef={formRef}
                  pathCategoryState={pathCategoryState}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*variante de producto*/}
          <FormWizard.TabContent title={fullSize ? ADD_VARIANT_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <AddVariant
                  mainTitle={ADD_VARIANT_TITLE}
                  variantProductTitle={VARIANT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  formRef={formRef}
                  pathCategoryState={pathCategoryState}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*Configurar atributos*/}
          <FormWizard.TabContent title={fullSize ? VARIANT_ATTRIBUTES_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <MainVariantAttributes
                  mainTitle={VARIANT_ATTRIBUTES_TITLE}
                  variantProductTitle={VARIANT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  formRef={formRef}
                  pathCategoryState={pathCategoryState}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*Ficha técnica*/}
          <FormWizard.TabContent title={fullSize ? TECHNICAL_SPECIFICATIONS_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <TechSpecifications
                  mainTitle={TECHNICAL_SPECIFICATIONS_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)}
                  handleCancelButton={handleShowModal}
                  formRef={formRef}
                  updateFormRef={updateFormRef}
                  pathCategoryState={pathCategoryState}
                  variantProductTitle={VARIANT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
          {/*Inventario en sucursales*/}
          <FormWizard.TabContent title={fullSize ? BRANCH_OFFICE_INVENTORY_TITLE : ''}>
            <Row className='g-0 text-start pt-3'>
              <Col lg={12}>
                <BranchOfficeInventory
                  mainTitle={BRANCH_OFFICE_INVENTORY_TITLE}
                  variantProductTitle={VARIANT_TITLE}
                  classificationLabelText={CATEGORY_TITLE}
                  cancelButtonText={CANCEL_BUTTON_TEXT}
                  nextButtonText={SAVE_CONTINUE_BUTTON_TEXT}
                  formRef={formRef}
                  pathCategoryState={pathCategoryState}
                  updateFormRef={updateFormRef}
                  handleNextButton={(e: MouseEvent) => handleFinishButtonClick(e)}
                  handleCancelButton={handleShowModal}
                />
              </Col>
            </Row>
          </FormWizard.TabContent>
        </FormWizard>
      </Col>
      {/*Modal para cancelar registro u omitir*/}
      <GifModal
        showModal={showModal}
        handleShowModal={handleShowModal}
        buttons={
          <Col>
            <TwoButtonsGroup
              firstButtonClass='btn-secondary-text-small'
              secondButtonClass='btn-primary-text-small'
              firstButtonText={CANCEL_BUTTON_TEXT}
              secondButtonText={SKIP_BUTTON_TEXT}
              firstButtonClick={handleNavigate}
              secondButtonClick={handleShowModal}
            />
          </Col>
        }
        image={
          <Image
            src={DELETE_IMAGE_URL}
            width={191}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{CANCEL_CONFIRMATION_TEXT}</span>}
      />
      {alertError && <AlertModal hideAlert={() => setAlertError(false)} type='danger' content={errorMessage} />}
    </ManagementOptionsContainer>
  );
};

export default AddProducts;
