import { useLayoutEffect, useState } from 'react';

import { useNavigate, useParams, useLocation, type NavigateFunction, Location } from 'react-router-dom';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import { StatusCodes } from '@/data/libraries/Status';
import { RoutesDirections } from '@/data/libraries/Routes';
import _, { isEmpty } from 'lodash';

import useReservation from '@/ui/hooks/useReservation';
import useValidateUnavailableProducts from '@/ui/hooks/useValidateUnavailableProducts';
import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import ProductsController from '@/controllers/ProductsController';

import { updateCart } from '@/domain/useCases/updateCartUseCase';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IMenu } from '@/domain/interfaces/IMenu';
import { IMainAttributesVariantsValues, IProduct } from '@/domain/interfaces/IProduct';
import { IAttributes } from '@/domain/interfaces/IAttributes';
import { IProductResponse, IVariantsAttributeName } from '@/domain/interfaces/IProductResponse';
import { ISelectOptionType } from '@/domain/interfaces/ISelect';

import findCategory from '@/domain/utils/findCategory';
import getParentCategory from '@/domain/utils/getParentCategory';
import { findMainAttribute } from '@/domain/utils/findAttribute';
import decimalFormat from '@/domain/utils/decimalFormat';

import BlankImageBanner from '@/ui/assets/BlankImageBanner';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import ProductCounter from '@/ui/components/product/ProductCounter';
import AttributeValue from '@/ui/components/product/AttributeValue';
import DropDownCustomImage from '@/ui/components/DropDownCustomImage';
import IconList from '@/ui/components/IconList';
import ReservationsModals from '@/ui/components/product/ReservationsModals';
import ProductImageSlider from '@/ui/components/product/ProductImageSlider';
import MainPrice from '@/ui/components/product/MainPrice';
import SecondaryPrice from '@/ui/components/product/SecondaryPrice';
import ProductStatusPill from '@/ui/components/product/ProductStatusPill';
import SideModal from '@/ui/components/modals/SideModal';
import EmptyList from '@/ui/components/store/EmptyList';
import TechnicalSheet from '@/ui/components/product/TechnicalSheet';

import ProductDetailsLoading from '@/ui/pages/product//ProductDetailsLoading';

/**
 * Página de detalle de Productos
 * @component
 */
/** Objeto para la inicialización del producto */
const initialStateCurrentProduct: IProduct = {
  id: '0',
  sku: '',
  title: '',
  description: '',
  bannerUrl: '',
  urlGuide: '',
  multimedia: [''],
  price: 0,
  offer: false,
  offerPrice: null,
  offerPercentage: 0,
  slugCategory: '',
  quantityAvailable: 0,
  totalProducts: 0,
  shippingTypes: [],
  status: 0,
};

/** Objeto para la inicialización del producto */
const initialStateCurrentMainAttribute: IAttributes = {
  id: '0',
  position: 0,
  name: '',
  main: true,
};

const ProductDetails = (): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Funciones del caso de uso actualizar carrito */
  const { getArticleFromCart } = updateCart();

  /** Hook para trabajar con el sku del producto que viene de la URL */
  const { sku } = useParams();

  /** State del sku */
  const [skuState, setSkuState] = useState<string>(sku ?? '');

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  /** Promesa que trae el producto desde el controlador   */
  const { getProductBySku, getVariantsSecondCustomization, getMainAttributeValuesVariants } = ProductsController();

  /**  Objeto Redux que almacena la data del menu */
  const navCategory: IMenu = useAppSelector((state: RootState) => state.categoriesMenuState);

  /** Hook para trabajar con el carrito */
  const reservationHook = useReservation();

  /** consulta si el producto esta en el carrito */
  const productInCart: boolean = !isEmpty(getArticleFromCart(sku ?? ''));

  /** Estado inicial del producto.  */
  const [product, setProduct] = useState<IProduct>(initialStateCurrentProduct);

  /** Estado del componente que almacena el objeto del atributo principal a mostrar. */
  const [mainAttribute, setMainAttribute] = useState<IAttributes>(initialStateCurrentMainAttribute);

  /** Estado del componente que almacena los valores del atributo principal de otras variantes. */
  const [mainAttributeValues, setMainAttributeValues] = useState<IMainAttributesVariantsValues[]>([]);

  /** Estado del componente que almacena el objeto de los otros atributos como Options .  */
  const [otherAttributesOptions, setOtherAttributesOptions] = useState<ISelectOptionType[]>([]);

  /** Estado que activa el segundo drowpdown de atributos */
  const [activeSecondDropdown, setActiveSecondDropdown] = useState<boolean>(false);

  /** Almacena el texto que describe el resto de atributos para segunda personalización del SKU actual */
  const [textSelectedOtherAttributes, setTextSelectedOtherAttributes] = useState<string>('');

  /** Estado del componente que almacena la URL de la imagen por defecto a mostrar.  */
  const [imageUrl, setImageUrl] = useState<string>('');

  /** Estado inicial de la interfaz de loading */
  const [loading, setLoading] = useState<boolean>(true);

  /** Estado inicial del modal de reservación */
  const [showReservationModal, setShowReservationModal] = useState<boolean>(false);

  /** Estado inicial del modal de error de reservación */
  const [showErrorReservationModal, setShowErrorReservationModal] = useState<boolean>(false);

  /** Estado inicial del modal de guía */
  const [showGuideModal, setShowGuideModal] = useState<boolean>(false);

  /** Data de la categoría a través de su Slug */
  const categoryData: IMenu | null = findCategory(navCategory, product.slugCategory);

  /** Data de la categoría padre a través de su Slug */
  const firstParentCategory: IMenu | null | undefined =
    categoryData && navCategory.children && getParentCategory(navCategory.children, categoryData?.slug);

  /** Hook  que valdia el carrito y sus items */
  const { hasUnavailableProducts } = useValidateUnavailableProducts();

  /**Respues del hook que valida el carrito */
  const validateCart = hasUnavailableProducts();

  /** State para manejar el estado del contador */
  const [productSelected, setProductSelected] = useState<boolean>(false);

  /** Lista de breadcrumbs en Desktop */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    {
      label: firstParentCategory?.title,
      url: `${RoutesDirections.STORE_ROUTE}/1/categoria/${firstParentCategory?.slug}`,
    },
    {
      label: categoryData?.title,
      url: `${RoutesDirections.STORE_ROUTE}/1/categoria/${product.slugCategory}`,
    },
    { label: product.title, url: '/sku' },
  ];

  const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null;
  const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;

  /**INICIO CONSTANTES DE TEXTO */
  const SPECIFICATION_GUIDE: string = process.env.REACT_APP_SPECIFICATION_GUIDE_TEXT ?? 'Guía';
  const SKU_LABEL: string = 'SKU:';
  const BUY_BUTTON_TEXT: string = 'COMPRAR AHORA';
  const DESCRIPTION_TEXT: string = 'Descripción';
  const SHIPPING_TYPE_AVALIABLE: string = 'Envío a domicilio';
  const SELECT_ITEM_TEXT: string = 'Seleccionar Articulo';

  /**FIN CONSTANTES DE TEXTO */

  /** Recupera la información de atributos del SKU actual */
  const fetchProductData = async (): Promise<void> => {
    /** Almacenara el SKU de la categoría a buscar si existe */
    try {
      setLoading(true);

      if (sku) {
        const productResponse: IProductResponse = await getProductBySku(sku);
        if (productResponse.products.length > 0) {
          // Si la solicitud fue exitosa y hay datos del producto, establecer el estado 'product'
          setProduct(productResponse.products[0]);
          setImageUrl(`${productResponse.products[0].multimedia[0]}`);
          setProductSelected(productResponse.products[0].quantityAvailable > 0);

          /** Actualiza el atributo principal del sku actual */
          setMainAttribute(findMainAttribute(productResponse.products[0].attributes ?? []) ?? initialStateCurrentMainAttribute);

          /** Establece el texto de los atributos (llave: valor) sin el atributo main del sku actual */
          const attributesWithoutMain = productResponse.products[0].attributes?.filter((element) => element.position !== 1);
          if (attributesWithoutMain) {
            const otherAtributesAuxiliar: IVariantsAttributeName = { sku: '', quantityAvailable: 0, attributes: [] };
            otherAtributesAuxiliar.attributes = attributesWithoutMain;
            const formatedAttributes: string = formatSecondaryAttributesName(otherAtributesAuxiliar);
            setTextSelectedOtherAttributes(formatedAttributes);
          }

          /** Recupera los valores del atributo principal de otras variantes */
          await getMainAttributeValuesVariants(sku).then((mainAttributeValues: IMainAttributesVariantsValues[] | null) => {
            mainAttributeValues !== null && setMainAttributeValues(mainAttributeValues);
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  /** Options para el primer dropdown de atributos */
  const convertAttributesToSelectOptions = (attributes: IMainAttributesVariantsValues[]): ISelectOptionType[] => {
    const optionValues: ISelectOptionType[] = attributes.map((element: IMainAttributesVariantsValues) => {
      return {
        id: element.sku,
        label: `${element.attribute}: ${element.attributeValue}`,
        imageUrl: element.image,
        value: element.sku,
        disabled: element.quantityAvailable <= 0,
        isALink: true,
      };
    });

    return optionValues;
  };

  /** Options para el segundo dropdown de atributos */
  const convertOtherAttributesToSelectOptions = (attributes: IVariantsAttributeName[]): ISelectOptionType[] => {
    const optionValues: ISelectOptionType[] = [];

    attributes.forEach((attribute) => {
      const attributesKeyValue = formatSecondaryAttributesName(attribute);

      let position: number;

      if (attribute.attributes[0].isQualitative && attribute.attributes[0].values) {
        position = attribute.attributes[0].values[0].position;
      } else if (attribute.attributes[0].values) {
        position = parseFloat(attribute.attributes[0].values[0].value ?? '0');
      } else {
        position = 0;
      }

      optionValues.push({
        id: attribute.sku,
        position: position,
        label: attributesKeyValue,
        value: attribute.sku,
        disabled: attribute.quantityAvailable <= 0,
        isALink: true,
      });
    });

    const orderedAttributes = _.orderBy(optionValues, ['position'], ['asc']);

    return orderedAttributes;
  };

  /** Recupera los atributos de las variantes para segunda personalización */
  const fetchVariantDataSecondCustomization = async (): Promise<void> => {
    try {
      const productResponse: IProductResponse = await getVariantsSecondCustomization(skuState);
      if (productResponse.variantsAttr && productResponse.variantsAttr.length > 0) {
        const filteredVariantsAttributes = productResponse.variantsAttr.map((variant) => {
          return {
            ...variant,
            attributes: variant.attributes.filter((element) => element.name !== mainAttribute.name),
          };
        });

        /** Actualiza el state que tiene los atributos secundarios en 'options' */
        setOtherAttributesOptions(convertOtherAttributesToSelectOptions(filteredVariantsAttributes));
      }
    } catch (error) {}
  };

  const handleReservation = async () => {
    setShowReservationModal(true);

    setTimeout(async () => {
      const isReserved: boolean = await reservationHook.reservation();

      if (!isReserved) {
        setShowReservationModal(false);
        setShowErrorReservationModal(true);
      } else {
        navigate(RoutesDirections.CHECK_OUT_ROUTE);
      }
    }, 2000);
  };

  /** Concatena con formato de (llave:valor) al arreglo de segunda personalización */
  const formatSecondaryAttributesName = (otherAttribute: IVariantsAttributeName): string => {
    let formatedText = '';

    if (otherAttribute?.attributes) {
      formatedText += otherAttribute.attributes
        .map((attribute) => {
          return returnAttributeKeyValue(attribute);
        })
        .join(', ');
    }
    return formatedText;
  };

  /** Da formato de (llave:valor) */
  const returnAttributeKeyValue = (attribute: IAttributes): string => {
    let formatedTextAuxiliar = '';
    if (attribute.values) {
      if (attribute.isQualitative) {
        formatedTextAuxiliar = `${attribute.name}: ${attribute.values.length > 0 ? attribute.values[0].name : ''}`;
      } else {
        formatedTextAuxiliar = `${attribute.name}:  ${
          attribute.values.length > 0 ? decimalFormat(attribute.values[0].value ?? '', attribute.values[0].decimalPrecision ?? 0) : ''
        } ${attribute.values.length > 0 ? attribute.values[0].prefix : ''}`;
      }
    }
    return formatedTextAuxiliar;
  };

  /** FIN DE FUNCIONALIDAD DE SEGUNDA VARIANTE */

  /** Función que muestra el modal de guía */
  const handleShowGuideModal = (): void => {
    setShowGuideModal(!showGuideModal);
  };

  /** Activa el segundo dropdown de atributos cuando esten listos
   * los elementos dependientes
   * */
  useLayoutEffect(() => {
    /** Habilita el drop down si existe más de una opción */
    textSelectedOtherAttributes.length > 1 && setActiveSecondDropdown(otherAttributesOptions.length > 1);
  }, [otherAttributesOptions]);

  useLayoutEffect(() => {
    /** Habilita el drop down si existe más de una opción */
    otherAttributesOptions.length > 1 && setActiveSecondDropdown(otherAttributesOptions.length > 1);
  }, [textSelectedOtherAttributes]);

  useLayoutEffect(() => {
    /** Consulta la segunda variante de producto según su SKU */
    fetchVariantDataSecondCustomization();
  }, [mainAttribute, product]);

  /** UseEffect para traer la información del producto en base al SKU */
  useLayoutEffect((): void => {
    setSkuState(sku ?? '');
    setActiveSecondDropdown(false);
    /** Consulta el producto según su SKU */
    fetchProductData();
    /** Si esta en el carrito inicializa la variable en verdadero */
    productInCart && setProductSelected(true);
  }, [sku]);

  /** INICIO FUNCIONALIDADES DE LA VENTA */

  // /** Estado Inicial de la alerta */
  // const [showAlert, setShowAlert] = useState<boolean>(false);

  // /**  Evento que muestra y oculta el modal de mensaje de carrito  */
  // const handleBuy = async (): Promise<void> => {
  //   const effectivePurchase: boolean = await purchaseOrder(
  //     JSON.parse(JSON.stringify(cart))
  //   );

  //   if (effectivePurchase) {
  //     deleteCart();
  //     fetchProductData();
  //     setShowAlert(true);
  //     setIsInCart(isArticleInCart());
  //   }
  // };

  /**COMPONENTES GENERALES */

  /**Componente SKU */
  const SkuComponent: JSX.Element = (
    <>
      <span className=' h5 fw-bold'>{SKU_LABEL}</span>
      <span className='h5 fw-bold px-1'>{product.sku}</span>
    </>
  );

  /**Componente Precios */
  const Prices: JSX.Element = (
    <>
      <MainPrice isFree={isFree} value={isInOffer ? product.offerPrice ?? 0 : product.price} className='fw-bold' />
      <SecondaryPrice isInOffer={isInOffer} value={product.price} />
      <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />
    </>
  );

  /**Componente Metodos de Envío (REFACTORIZAR) */

  const ShippingTypes: JSX.Element = <IconList title={SHIPPING_TYPE_AVALIABLE} classTitle='p-large' iconName={'ico-national-shipment'} />;

  /** Componente Guía de especificaciones */
  const GuideSpecifications: JSX.Element = (
    <IconList
      title={SPECIFICATION_GUIDE}
      iconName={'ico-info'}
      classTitle='h5 fw-bold link-icon-standard'
      classIcon='link-icon-standard '
      onClick={isMobile ? () => (window.location.href = product.urlGuide ?? '') : handleShowGuideModal}
    />
  );

  /**Componente atributo principal */
  const MainAttributesComponent = () => {
    const mainAttributeBySku = mainAttributeValues.find((attribute) => attribute.sku === skuState);
    return mainAttributeValues.length > 1 ? (
      <DropDownCustomImage
        options={convertAttributesToSelectOptions(mainAttributeValues)}
        optionComponent={AttributeValue}
        defaultValue={{
          id: skuState,
          imageUrl: imageUrl,
          label: `${returnAttributeKeyValue(mainAttribute)}`,
          value: skuState,
          disabled: mainAttributeBySku && mainAttributeBySku.quantityAvailable <= 0,
          isALink: true,
        }}
      />
    ) : (
      <div className='dropdown-border py-1'>
        <AttributeValue
          data={{
            id: skuState,
            imageUrl: imageUrl,
            label: `${returnAttributeKeyValue(mainAttribute)}`,
            value: skuState,
            disabled: product.quantityAvailable <= 0,
            isALink: false,
          }}
        />
      </div>
    );
  };

  /**Componente Atributo Secundario */
  const SecondAttributesComponent: JSX.Element = !activeSecondDropdown ? (
    <div className='dropdown-border d-flex align-items-center py-1'>
      <AttributeValue
        data={{
          id: skuState,
          label: `${textSelectedOtherAttributes}`,
          value: skuState,
          isALink: false,
          disabled: product.quantityAvailable <= 0,
        }}
      />
    </div>
  ) : (
    <DropDownCustomImage
      options={otherAttributesOptions}
      optionComponent={AttributeValue}
      defaultValue={{
        id: skuState,
        label: `${textSelectedOtherAttributes}`,
        value: skuState,
        isALink: true,
        disabled: product.quantityAvailable <= 0,
      }}
    />
  );

  /**Componente Descripción */
  const DescriptionComponent: JSX.Element = (
    <>
      <Col lg={12} className={`d-flex ${isMobile ? 'justify-content-start h5' : 'justify-content-center'}`}>
        <IconList
          title={DESCRIPTION_TEXT}
          iconName={'ico-info'}
          classTitle='h2'
          classIcon='svg-primary-3'
          classMain='text-primary-3 justify-content-center'
        />
      </Col>

      <Col lg={12} className={`mt-4 text-start ${isMobile ? '' : 'h5'}`}>
        <span className='text-secondary-2 '>{product.description}</span>
      </Col>
    </>
  );

  /**Componente Banner */
  const BannerComponent: JSX.Element = (
    <div className='image-banner-container  '>
      <Image
        className='image-full'
        src={`${product.bannerUrl}`}
        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
          e.currentTarget.src = BlankImageBanner;
        }}
      />
    </div>
  );

  /**Componente Boton de compra */
  const BuyButtonComponent: JSX.Element = (
    <Button
      className={`my-2 w-100 svg-primary-4 btn-primary-icon-standard ${(product.quantityAvailable < 1 || !productInCart) && ' disabled'}`}
      type='button'
      onClick={validateCart.error ? () => navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } }) : handleReservation}>
      <span className={`pe-2 p-regular-medium`}>{BUY_BUTTON_TEXT}</span>
    </Button>
  );

  /**Componente Inoformación de Producto */
  const ProductInfo: JSX.Element =
    product && product.status === StatusCodes.ACTIVE ? (
      <Container fluid className={`p-0  ${isMobile ? 'bg-secondary-5' : 'bg-primary-4'} `}>
        {isMobile ? (
          <>
            <Row className='g-0 svg-secondary-3 my-2'>
              <Col xs={12} className='bg-primary-4 p-2'>
                <Breadcrumbs />
              </Col>
            </Row>
            <Row className='g-0 my-2'>
              <Col xs={12} className='bg-primary-4 py-2 px-3'>
                <span className='text-secondary-3'>{SkuComponent}</span>
              </Col>
            </Row>
            <Row className='g-0 my-2'>
              <Col xs={12} className='h3 bg-primary-4 py-2 px-3 text-secondary-2 '>
                {product.title}
              </Col>
            </Row>
            <Row className='g-0 my-2'>
              <Col xs={12}>
                <ProductImageSlider data={product.multimedia} />
              </Col>
            </Row>
            <Row className='g-0 mt-5'>
              <Col xs={12} className='d-flex align-self-end align-items-center h5 justify-content-between bg-primary-4 py-2 px-3'>
                {Prices}
              </Col>
            </Row>
            <Row className='g-0 '>
              <Col xs={12} className='d-flex justify-content-center p-large bg-primary-4 py-2 px-3 text-secondary-2 '>
                {ShippingTypes}
              </Col>
            </Row>
            <Row className='g-0 my-2 py-2 px-3  bg-primary-4'>
              <Col xs={6} className='d-flex align-items-center h5 m-0 '>
                <span className='fw-bold text-primary-3'>{SELECT_ITEM_TEXT}</span>
              </Col>
              {product.urlGuide && (
                <Col xs={6} className='d-flex justify-content-end align-items-center'>
                  {GuideSpecifications}
                </Col>
              )}
              <Col xs={12} className='pt-3 pb-2 text-secondary-2 '>
                {MainAttributesComponent()}
              </Col>
              <Col xs={12} className='py-2 text-secondary-2 '>
                {SecondAttributesComponent}
              </Col>
              <Col xs={12} className='py-2 text-secondary-2 '>
                <ProductCounter skuProduct={skuState} productSelected={productSelected} />
              </Col>
              <Col xs={12} className='py-2 text-secondary-2 '>
                {BuyButtonComponent}
              </Col>
            </Row>
            <Row className='g-0 my-2'>
              <Col xs={12} className='bg-primary-5 py-3 px-3 text-secondary-2 '>
                {DescriptionComponent}
              </Col>
            </Row>
            {!isEmpty(product.titleTechnicalSheet) && (
              <Container fluid className='bg-secondary-5 p-0'>
                <TechnicalSheet title={product.titleTechnicalSheet ?? ''} technicalSheet={product.technicalSheet ?? []} />
              </Container>
            )}
            {product.bannerUrl && (
              <Row className='g-0 mt-2'>
                <Col>{BannerComponent}</Col>
              </Row>
            )}
          </>
        ) : (
          <>
            <Container className='mb-4 py-3'>
              <Row className='g-0 p-2  mb-3 bg-primary-4 shadow rounded svg-secondary-3 '>
                <Col xs={12}>
                  <Breadcrumbs list={breadcrumbsList} />
                </Col>
              </Row>
              <Row className='position-relative first-row g-0'>
                <Col xs={7}>
                  <Row className='g-0 mx-3 position-relative'>
                    <Col xs={10} className='mx-auto'>
                      <ProductImageSlider data={product.multimedia} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={5} className='border border-2 rounded rounded-3'>
                  {/* <Row className='g-0'>
              <Col xs={12} className='mt-3 d-flex justify-content-end'>
                <GuideTip position='bottom' message='Agregar a Favoritos'>
                  <FavoritesWidget classMain='btn-widget-light mx-3' skuProduct={product.sku} />
                </GuideTip>
                <GuideTip position='bottom' message='Compartir'>
                  <ShareWidget classMain='btn-widget-light mx-3' />
                </GuideTip>
              </Col>
            </Row> */}
                  <Row className='mx-3 g-0'>
                    <Col xs={12} className='text-break mt-2 mb-4'>
                      <span className='text-secondary-3  h5'>{SkuComponent}</span>
                    </Col>
                    <Col xs={12} className='text-break h3 text-secondary-2 '>
                      {product.title}
                    </Col>
                    <Col xs={12} className='d-flex mt-5 align-self-end align-items-center h4 justify-content-between'>
                      {Prices}
                    </Col>
                    <Row className='g-0 mt-3 row-cols-auto d-flex align-items-center'>{ShippingTypes}</Row>

                    <Row className='g-0 mt-4 d-flex flex-row'>
                      <Col lg={6} className='d-flex h4 '>
                        <span className='fw-bold text-primary-3'>{SELECT_ITEM_TEXT}</span>
                      </Col>
                      {product.urlGuide && (
                        <Col lg={6} className='d-flex justify-content-end align-items-center'>
                          {GuideSpecifications}
                        </Col>
                      )}
                    </Row>
                  </Row>

                  <Row className='g-0 my-3 mx-3'>{MainAttributesComponent()}</Row>
                  <Row className='g-0 my-3 mx-3'> {SecondAttributesComponent}</Row>
                  <Row className='g-0 mt-5 mb-1 mx-3'>
                    <ProductCounter skuProduct={skuState} productSelected={productSelected} />
                  </Row>
                  <Row className='g-0 mx-2 '>
                    <Col lg={12} className='px-2'>
                      {BuyButtonComponent}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container fluid className='p-0 py-5 bg-primary-5'>
              <Container className='py-3'>
                <Row className='g-0 '>{DescriptionComponent}</Row>
              </Container>
            </Container>
            {!isEmpty(product.titleTechnicalSheet) && (
              <Container fluid className='bg-secondary-5 p-0'>
                <TechnicalSheet title={product.titleTechnicalSheet ?? ''} technicalSheet={product.technicalSheet ?? []} />
              </Container>
            )}
            {product.bannerUrl && (
              <Row className='g-0 '>
                <Col>{BannerComponent}</Col>
              </Row>
            )}
          </>
        )}
        {showReservationModal && <ReservationsModals />}
        {showErrorReservationModal && <ReservationsModals error />}
      </Container>
    ) : (
      <EmptyList textSearched={`El Producto SKU: ${sku}`} />
    );

  return (
    <>
      {loading ? (
        <ProductDetailsLoading />
      ) : (
        <>
          {ProductInfo}
          <SideModal pdfUrl={product.urlGuide ?? ''} showModal={showGuideModal} handleClose={handleShowGuideModal} />
        </>
      )}
    </>
  );
};

export default ProductDetails;
