import { PropsWithChildren, ReactNode, useState } from 'react';
import { useAppSelector } from '@/ui/store/helperRedux';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { IUserStore } from '@/domain/interfaces/IUser';

import { Col, Container, Row } from 'react-bootstrap';

import BackIcon from '@/ui/assets/BackIcon';
import CloseIcon from '@/ui/assets/CloseIcon';

import AvatarUser from '@/ui/components/user/AvatarUser';
import BackClose from '@/ui/components/BackClose';
import ClientList from '@/ui/containers/userOptions/ClientList';
import ManagementList from '@/ui/containers/managementOptions/ManagementList';
import ManagementListTitle from '@/ui/containers/managementOptions/ManagementListTitle';

import './style.css';

interface PropsNavUser {
  /** Clase del boton back '<' */
  classBackIcon?: string;
  /** Clase del boton close 'x' */
  classCloseIcon?: string;
  /** Clase del enlance para cierre de sesión */
  classSignOutIcon?: string;
  /** Evento que oculta modal */
  handleModal?: (params: any) => ReactNode | void;
  /** Evento click del boton close 'x' */
  clickCloseIcon?: (params: any) => ReactNode | void;
  /** Evento click para cierre de sesión  */
  clickSignOutIcon?: (params: any) => ReactNode | void;
  /** Evento click para enlace de 'Información de mi cuenta' */
  clickAccount?: (params: any) => ReactNode | void;
  /** Evento click para enlace de 'Cambiar Contraseña' */
  clickPassword?: (params: any) => ReactNode | void;
  /** Clase Boostrap que ordena Lista de clientes dentro del row */
  orderClientList?: string;
  /** Clase Boostrap que ordena Lista de Opciones Administrativas dentro del row */
  orderManagementList?: string;
}

/** Componente Nav Usuario Logueado
 * @component
 */
const NavUser = ({
  classBackIcon = '',
  classCloseIcon = '',
  clickCloseIcon,
  clickSignOutIcon,
  orderClientList = '',
  orderManagementList = '',
  handleModal,
}: PropsWithChildren<PropsNavUser>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Maneja un state para ocultar la ventana de opciones administrativas de modal al cargar la web    */
  const [managementOptions, setManagementOptions] = useState<boolean>(false);

  /** Funciones que Muestra / Oculta el modulo de confirmación cierre de modal*/
  const switchManagementOptions = (): void => setManagementOptions(!managementOptions);

  /** Estado Redux del usuario */
  const userLogued: IUserStore = useAppSelector((state) => state.userState);

  /** Representa el tipo de usuario cliente */
  const kindClientUser: string = 'CLIENT';

  return isMobile ? (
    <Container id='nav-user' fluid className='p-0'>
      <Row className='g-0 py-2 d-flex align-items-center'>
        <Col className='px-2'>
          <BackClose
            classMain={
              classBackIcon === 'invisible'
                ? `justify-content-end`
                : classBackIcon === 'invisible'
                ? `justify-content-start`
                : `justify-content-between`
            }>
            <BackIcon classMain={`btn-icon-light ${managementOptions ? classBackIcon : 'invisible'}`} onClick={switchManagementOptions} />
            <CloseIcon classMain={`btn-icon-light ${classCloseIcon}`} onClick={clickCloseIcon} />
          </BackClose>
        </Col>
        {managementOptions ? (
          <Col xs={12} className={`px-4 ${orderManagementList}`}>
            <ManagementList handleModal={handleModal} />
          </Col>
        ) : (
          <>
            <Col xs={12} className='pb-4'>
              <AvatarUser>
                <p className='m-0'>{userLogued.user.firstName}</p>
                <small className='align-self-baseline'>
                  {['', 'null', null].includes(userLogued.user.position!) ? userLogued.user.email : userLogued.user.position}
                </small>
              </AvatarUser>
            </Col>

            {userLogued.user.kind !== kindClientUser && (
              <Col xs={12} className='px-4'>
                <ManagementListTitle onClick={switchManagementOptions} />
              </Col>
            )}

            <Col xs={12} className={`px-4 ${orderClientList}`}>
              <ClientList classBar='d-none' handleModal={handleModal} clickSignOutIcon={clickSignOutIcon} />
            </Col>
          </>
        )}
      </Row>
    </Container>
  ) : (
    <Container id='nav-user' fluid className='p-0'>
      <Row className='g-0 py-2 d-flex align-items-center'>
        <Col xs={12} className='py-2'>
          <AvatarUser>
            <div>
              <span className='m-0 p-large-medium text-capitalize'>{userLogued.user.firstName}</span>
            </div>
            <div>
              <span className='p-regular-medium text-tertiary-1 align-self-baseline'>
                {['', 'null', null].includes(userLogued.user.position!) ? userLogued.user.email : userLogued.user.position}
              </span>
            </div>
          </AvatarUser>
        </Col>

        <Col xs={12} className={`px-4`}>
          <ClientList handleModal={handleModal} clickSignOutIcon={clickSignOutIcon} />
        </Col>

        <Col xs={12} className={`px-4`}>
          <ManagementList handleModal={handleModal} />
        </Col>
      </Row>
    </Container>
  );
};

export default NavUser;
