import { PropsWithChildren, useRef, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';

import '@/ui/components/forms/form.css';

import UserController from '@/controllers/UserController';

import useEmailValidation from '@/domain/genericValidations/useEmailValidation';
import { lowercaseCapitalValidation, numberandSymbolValidation, usePasswordValidation } from '@/domain/genericValidations/usePasswordValidation';
import useNameValidation from '@/domain/genericValidations/useNameValidation';

import { IRegistrationData } from '@/domain/interfaces/IUser';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';

import CloseIcon from '@/ui/assets/CloseIcon';
import BackIcon from '@/ui/assets/BackIcon';

import InputForm from '@/ui/components/forms/InputForm';
import SignOutForm from '@/ui/components/user/SignOutForm';
import AccessByOthers from '@/ui/components/forms/AcessByOthers';
import TextAndLink from '@/ui/components/TextAndLink';

interface PropsSingUpForm {
  showRegister?: boolean;
  needAuth?: boolean;
  handleShowRegister: () => void;
  handleShowLogin: () => void;
}

/** Componente formulario para registro email y contraseña
 *@component
 */
const SignUpForm = ({ showRegister = false, needAuth, handleShowRegister, handleShowLogin }: PropsWithChildren<PropsSingUpForm>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const logo: string = `${URL_IMAGES}/client/logo-modal.png`;

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook para generar una navegación dentro de la aplicación.  */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Referencia al input email
   * @constant {HTMLInputElement}
   */
  const emailRef = useRef<HTMLInputElement>(null);

  /** Referencia al input repetir email
   * @constant {HTMLInputElement}
   */
  const repeatEmailRef = useRef<HTMLInputElement>(null);

  /** Referencia al input name
   * @constant {HTMLInputElement}
   */
  const nameRef = useRef<HTMLInputElement>(null);

  /** Referencia al check mantenerme conectado
   * @constant {HTMLInputElement}
   */
  const sessionCheckRef = useRef<HTMLInputElement>(null);

  /** Referencia al input password
   * @constant {HTMLInputElement}
   */
  const passwordRef = useRef<HTMLInputElement>(null);

  /** Referencia al Formulario SingUp
   * @constant {HTMLFormElement}
   */
  const signUpFormRef = useRef<HTMLFormElement>(null);

  /** Maneja un state para mostrar los inputs relacionados al email al cargar la web    */
  const [inEmailInput, setEmailInput] = useState<boolean>(true);
  /** Maneja un state para ocultar los inputs relacionados al email de confirmación al cargar la web    */
  const [inRepeatEmailInput, setRepeatEmailInput] = useState<boolean>(false);
  /** Maneja un state para ocultar los inputs relacionados al nombre al cargar la web    */
  const [inNameInput, setNameInput] = useState<boolean>(false);
  /** Maneja un state para ocultar los inputs relacionados al password al cargar la web    */
  const [inPasswordInput, setPasswordInput] = useState<boolean>(false);

  /** Maneja un state para ocultar la ventana de cierre de modal al cargar la web    */
  const [closeModal, setCloseModal] = useState<boolean>(false);
  /** Funciones que Muestra / Oculta el modulo de confirmación cierre de modal*/
  const switchCloseModal = (): void => setCloseModal(!closeModal);

  /** Declaración inicial de los botones como deshabilitado    */
  const [stateButton, setStateButton] = useState<string>('btn-primary-text-standard disabled');
  /** Declaración inicial un input en su estado normal    */
  const [inputWithError, setInputWithError] = useState<boolean>(false);
  /** Declaración inicial del campo de contraseña como oculta    */
  const [passwordRules, setPasswordRules] = useState<boolean>(false);

  /** Maneja un state para ocultar todos los checkbosx al cargar la web    */
  const [checkBox, setCheckBox] = useState<string>('invisible position-absolute');
  /** Declaración inicial de todos los check como un-checked   */
  const [check, setCheck] = useState<boolean>(false);
  /** Maneja un state para el check de mantenerme conectado*/
  const [checkKeep, setCheckKeep] = useState<boolean>(true);
  /** Oculta el conjunto de Checkbox*/
  const offCheckbox = (): void => setCheckBox('invisible position-absolute');

  /** Enciende el conjunto de checks para validar registro,
   *  Apaga los checks,
   *  Declara el input como vacio
   */
  const onCheckbox = (): void => {
    setCheckBox('display');
    setCheck(false);
    emptyInput();
  };

  /** Cambia el estado unCheked a Checked en terminos y condiciones */
  const changeCheck = (): void => setCheck(!check);

  /** Quita el Check de terminos y condiciones y apaga el boton de registrar */
  const uncheckValue = (): void => {
    changeCheck();
    offButton();
  };

  /** Activa el Check de terminos y condiciones y enciende el boton de registrar */
  const checkValue = (): void => {
    changeCheck();
    onButton();
  };

  /**Cambia el estado del check mantenerme conectado  */
  const changeCheckKeep = (): void => setCheckKeep(!checkKeep);

  /** Declaración inicial de los mensajes de error como vacios    */
  const [messageError, setMessageError] = useState<string>('');
  /** Declaración que borra el valor de un input    */
  const [inputValue, setInputValue] = useState<string>('');

  /** Maneja un state ocultar o mostrar el password digitado  */
  const [showPasswordText, setPasswordText] = useState<boolean>(false);
  /** Muestra y Oculta la contraseña cada vez que se declara  */
  const switchPasswordText = (): void => setPasswordText(!showPasswordText);

  /** Define el estado inicial del <li> de validación de longitud de texto */
  const [lengthLi, setLengthLi] = useState<string>('');
  /** Define el estado inicial del <li> de validación de letras mayusculas y minusculas */
  const [minusMayusLi, setMinusMayusLi] = useState<string>('');
  /** Define el estado inicial del <li> de validación de numeros y textos */
  const [numberAndSymbolLi, setNumberAndSymbolLi] = useState<string>('');
  /** Define el estado inicial del <li> de validación que el texto no contenga el correo */
  const [emailLi, setEmailLi] = useState<string>('');

  /** Enciende el input relacionado al email*/
  const onEmailInput = (): void => setEmailInput(true);
  /** Apaga el input relacionado al email*/
  const offEmailInput = (): void => setEmailInput(false);

  /** Enciende el input relacionado al email de confirmación*/
  const onRepeatEmailInput = (): void => setRepeatEmailInput(true);
  /** Apaga el input relacionado al email de confirmación*/
  const offRepeatEmailInput = (): void => setRepeatEmailInput(false);

  /** Enciende el input relacionado al nombre*/
  const onNameInput = (): void => setNameInput(true);
  /** Apaga el input relacionado al nombre*/
  const offNameInput = (): void => setNameInput(false);

  /** Enciende el input relacionado a la contraseña*/
  const onPasswordInput = (): void => setPasswordInput(true);
  /** Apaga el input relacionado a la contraseña*/
  const offPasswordInput = (): void => setPasswordInput(false);

  /** Habilita un botón para avanzar a la siguiente fase*/
  const onButton = (): void => setStateButton('btn-primary-text-standard');
  /** Deshabilita un botón para bloquear el avance a la siguiente fase*/
  const offButton = (): void => setStateButton('btn-primary-text-standard disabled');

  /** Muestra las <li> de validación de contraseñas*/
  const onPasswordRules = (): void => setPasswordRules(true);
  /** Oculta las <li> de validación de contraseñas*/
  const offPasswordRules = (): void => setPasswordRules(false);

  /** Funcion para activar input,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Enciente el boton,
   */
  const validInput = (): void => {
    setMessageError('');
    setInputWithError(false);
    onButton();
  };

  /** Funcion para etiquear un input con errores
   *  Coloca los mensajes como error (en caso de existir),
   *  Muestra los mensajes,
   *  Muestra los iconos de error,
   *  Coloca el input como error,
   *  Apaga el boton,
   */
  const errorInput = (): void => {
    setInputWithError(true);
    offButton();
  };

  /** Funcion para etiquear un input vacio,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Apaga el boton,
   */
  const emptyInput = (): void => {
    setMessageError('');
    setInputWithError(false);
    offButton();
  };

  /** Funcion para moverse de Email a Repetir Email
   *  Apaga Input de Email
   *  Enciende Input de Repetir Email,
   *  Ejecuta la @function repeatEmailToValidate con los parametros del correo del input anterior (@param emailtoValidate) y el correo que se ingresa en el nuevo input (@param repeatEmailToValidate)
   */
  const switchEmailToRepeatEmail = (): void => {
    onRepeatEmailInput();
    offEmailInput();

    let emailtoValidate: string = emailRef.current?.value!;
    let repeatEmailToValidate: string = repeatEmailRef.current?.value!;
    repeatEmailValidation(emailtoValidate, repeatEmailToValidate);
  };

  /** Funcion para moverse de Repetir Email a Email
   * Enciende Input de Email
   * Apaga Input de Repetir Email,
   * Enciente el botón,
   * Etiqueta el input como valido
   */
  const switchRepeatEmailToEmail = (): void => {
    onEmailInput();
    offRepeatEmailInput();

    onButton();
    validInput();
  };

  /** Funcion para moverse de Repetir Email a Nombre
   *  Apaga Input de Repetir Email
   *  Enciende Input de Nombre,
   *  Ejecuta la @function nameValidation enviandole como parametro el nombre ingresado en el input @param inputToValidate
   */
  const switchRepeatEmailToName = (): void => {
    onNameInput();
    offRepeatEmailInput();

    let inputToValidate: string = nameRef.current?.value!;
    nameValidation(inputToValidate);
  };

  /** Funcion para moverse de Nombre a Repetir Email
   * Enciende Input de Repetir Email
   * Apaga Input de Nombre,
   * Enciende el botón,
   * Etiqueta el input como valido
   */
  const switchNameToRepeatEmail = (): void => {
    onRepeatEmailInput();
    offNameInput();

    onButton();
    validInput();
  };

  /** Funcion para moverse de Nombre a Contraseña
   * Enciende Input de Password
   * Apaga Input de Nombre,
   * Vacia el valor de la contraseña,
   * Coloca el input en tipo password
   * Apaga el botón de registro
   */
  const switchNameToPassword = (): void => {
    onPasswordInput();
    offNameInput();
    setPasswordText(false);
    setInputValue('');
    offButton();
  };

  /** Funcion para moverse de Contraseña a Nombre
   * Enciende Input de Nombre
   * Apaga Input de Password,
   * Vacia el valor de la contraseña,
   * Oculta las listas de validación de contraseñas
   * Oculta los checkboxs
   * Enciente el boton,
   * Etiqueta el input como valido
   */
  const switchPasswordtoName = (): void => {
    onNameInput();
    offPasswordInput();
    setInputValue('');
    offPasswordRules();
    offCheckbox();
    onButton();
    validInput();
  };

  /** Almacena la funcion del controlador para hacer un registro
   * @constant {object}
   */
  const { clientRegistration: clientRegitration } = UserController();

  /** Resetea y Cierra el Formulario    */
  const closeForm = (): void => {
    resetForm();
    handleShowRegister();
  };

  /** Resetea el formulario de Registro,
   * Enciende el Input email y oculta el resto de Inputs,
   * Define el Input en su estado inicial,
   * Oculta iconos de error y componentes que no corresponden al input de email,
   * Borra todos los valores del form
   */
  const resetForm = (): void => {
    setCloseModal(false);
    onEmailInput();
    offRepeatEmailInput();
    offNameInput();
    offPasswordInput();
    emptyInput();
    offPasswordRules();
    offCheckbox();
    signUpFormRef.current?.reset();
  };

  /** Cambia el modal del registro por el de login    */
  const toLogIn = (): void => {
    closeForm();
    handleShowLogin();
  };

  /** Llamado de Caso de uso que valida el correo */
  const validaEmail: (email: string) => boolean = useEmailValidation;
  /** Llamado de Caso de uso que valida el Contraseña */
  const validaPassword: (password: string) => boolean = usePasswordValidation;
  /** Llamado de Caso de uso que valida el nombre */
  const validaName: (name: string) => boolean = useNameValidation;

  /** Llamado de Caso de uso que valida el <li> de Letras mayus y minus en la validación de contraseña */
  const validaMinusMayus: (password: string) => boolean = lowercaseCapitalValidation;
  /** Llamado de Caso de uso que valida el <li> de numeros y signos en la validación de contraseña */
  const validaNumberSymbol: (password: string) => boolean = numberandSymbolValidation;

  /** Función que válida el correo electrónico */
  const emailValidation = (email: string): void => {
    if (!validaEmail(email)) {
      setMessageError('Formato de email invalido');
      errorInput();
    } else {
      validInput();
    }
  };

  /** Función que válida la confirmación de correo*/
  const repeatEmailValidation = (email1: string, email2: string): void => {
    if (email2.length < 1) {
      emptyInput();
    } else if (email1 !== email2) {
      setMessageError('E-mail no coincide');
      errorInput();
    } else {
      validInput();
    }
  };

  /** Función que válida el nombre*/
  const nameValidation = (name: string): void => {
    if (name.length < 1) {
      emptyInput();
    } else if (!validaName(name)) {
      setMessageError('Nombre no válido');
      errorInput();
    } else if (name.length < 3) {
      setMessageError('Nombre muy corto');
      errorInput();
    } else if (name.length > 16) {
      setMessageError('Nombre muy largo');
      errorInput();
    } else {
      validInput();
    }
  };

  /** Función que válida el <li> de longitud en validación de contraseña*/
  const lengthLiValidation = (text: string): void => {
    if (text.length < 8 || text.length > 100) {
      setLengthLi('text-complementary-2');
    } else {
      setLengthLi('');
    }
  };

  /** Función que válida el <li> de letras mayusculas y minusculas en validación de contraseña*/
  const minusMayusLiValidation = (text: string): void => {
    if (!validaMinusMayus(text)) {
      setMinusMayusLi('text-complementary-2');
    } else {
      setMinusMayusLi('text-complementary-1');
    }
  };

  /** Función que válida el <li> de numeros y signos en validación de contraseña*/
  const numberSymbolLiValidation = (text: string): void => {
    if (!validaNumberSymbol(text)) {
      setNumberAndSymbolLi('text-complementary-2');
    } else {
      setNumberAndSymbolLi('text-complementary-1');
    }
  };

  /** Función que válida el <li> para que no contenga el correo electrónico*/
  const emailLiValidation = (text: string, email: string): void => {
    if (text === email) {
      setEmailLi('text-complementary-2');
    } else {
      setEmailLi('text-complementary-1');
    }
  };

  /** Función que válida la contraseña y que todos los <li> sean validos*/
  const passwordValidation = (password: string, email: string): void => {
    if (password.length < 1) {
      emptyInput();
      setLengthLi('');
      setMinusMayusLi('');
      setNumberAndSymbolLi('');
      setEmailLi('');
    } else if (password.length > 0) {
      lengthLiValidation(password);
      emailLiValidation(password, email);
      minusMayusLiValidation(password);
      numberSymbolLiValidation(password);
      if (!validaPassword(password) || password === email) {
        onPasswordRules();
        offCheckbox();
        setMessageError('Clave invalida');
        errorInput();
      } else {
        offPasswordRules();
        onCheckbox();
        emptyInput();
        setMessageError('Contraseña Exitosa');
      }
    }
  };

  /** Evento cambios del formulario de Registro  */
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (inEmailInput) {
      let inputToValidate: string = emailRef.current?.value!;
      emailValidation(inputToValidate);
    } else if (inRepeatEmailInput) {
      let inputToValidate: string = emailRef.current?.value!;
      let repeatEmailToValidate: string = repeatEmailRef.current?.value!;
      repeatEmailValidation(inputToValidate, repeatEmailToValidate);
    } else if (inNameInput) {
      let inputToValidate: string = nameRef.current?.value!;
      nameValidation(inputToValidate);
    } else if (inPasswordInput) {
      let inputToValidate: string = passwordRef.current?.value!;
      let emailToValidate: string = emailRef.current?.value!;
      setInputValue(e.target.value);
      passwordValidation(inputToValidate, emailToValidate);
    }
  };

  /** Evento submit del formulario de login  */
  const continueToSignUp = async (): Promise<void> => {
    const user: IRegistrationData = {
      email: emailRef.current?.value!,
      password: passwordRef.current?.value!,
      firstName: nameRef.current?.value!,
    };
    const keepLogin: boolean = sessionCheckRef.current?.checked ?? true;
    const doSingUpV: IUserResponse = await clientRegitration(user, keepLogin);

    if (doSingUpV.isError) {
      errorInput();
      setMessageError(doSingUpV.message[0]);
      onEmailInput();
      offRepeatEmailInput();
      offNameInput();
      offPasswordInput();

      offPasswordRules();
      offCheckbox();
    } else {
      const lastUrl = location.state?.from ?? RoutesDirections.MAIN_ROUTE;
      if (needAuth && lastUrl !== null) {
        navigate(lastUrl);
      }
      closeForm();
    }
  };

  return (
    <Modal id='sign-up-modal' show={showRegister} onHide={handleShowRegister} fullscreen={true} animation={false}>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row id='sign-up' className='min-vh-100 d-flex align-items-end flex-column p-0'>
            <Col xs={12} className='bg-ilis-modal min-vh-65'>
              <Col xs={12} className='align-self-center d-flex justify-content-around pb-5'>
                <div className='text-start w-10 align-top text-primary-4 pt-2'>
                  <BackIcon
                    onClick={
                      closeModal
                        ? switchCloseModal
                        : inEmailInput
                        ? closeForm
                        : inRepeatEmailInput
                        ? switchRepeatEmailToEmail
                        : inNameInput
                        ? switchNameToRepeatEmail
                        : inPasswordInput
                        ? switchPasswordtoName
                        : undefined
                    }
                    classMain='btn-icon-dark ps-2  svg-primary-4'
                  />
                </div>

                <div className=' text-center w-100 py-3'>
                  <img src={logo} id='modal-mobile-logo' alt='logo' className='d-sm-none'></img>
                </div>

                <div>
                  <img
                    src={logo}
                    id='modal-desktop-logo'
                    alt='logo'
                    className='login-desktop-logo d-none d-sm-block position-absolute top-10 start-10'></img>
                </div>

                <div className='text-end w-10 align-top p-2'>
                  <CloseIcon onClick={closeModal ? undefined : switchCloseModal} classMain='btn-icon-dark pe-2  svg-primary-4' />
                </div>
              </Col>
            </Col>

            <Col
              xs={12}
              sm={6}
              lg={4}
              className={`px-5 pb-3 bg-primary-4 position-absolute top-50 start-50 translate-middle rounded-top-5 ${!isMobile && 'shadow'}`}>
              <Col xs={12} className='pt-3 min-vh-75 d-flex align-items-end flex-column'>
                {closeModal && (
                  <Col xs={12} className='pt-5'>
                    <SignOutForm message='¿Desea salir y cancelar su registro?' submit={closeForm} cancel={switchCloseModal} />
                  </Col>
                )}

                <Col xs={12} className={closeModal ? 'd-none' : 'display'}>
                  <Col xs={12} className='py-3 text-center'>
                    <span className='h5 fw-bold'>Crear Cuenta</span>
                    <div className='pt-3 text-gray'>
                      <span className='text-center mb-0'>
                        {inEmailInput
                          ? '¡Descubre todo lo que tenemos para ti!'
                          : inRepeatEmailInput
                          ? 'Confirma tu correo electrónico.'
                          : inNameInput
                          ? 'Ingresa tu nombre.'
                          : '¡Crea una contraseña que te sea fácil de recordar!'}
                      </span>
                      {inEmailInput && (
                        <p>
                          <span className='text-center mb-0'>Ingresa tu correo electrónico.</span>
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Form
                      id='sign-up-form'
                      ref={signUpFormRef}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      className='svg-tertiary-1'>
                      <div className={inEmailInput ? 'display' : 'invisible position-absolute'}>
                        <InputForm
                          className='mb-3'
                          label='Correo Electrónico'
                          classLabel='p-regular-medium'
                          id='input-sign-up-email'
                          inputRef={emailRef}
                          type='email'
                          onChange={handleInput}
                          withErrors={inputWithError}
                          messageError={messageError}
                        />
                      </div>

                      <div className={inRepeatEmailInput ? 'display' : 'invisible position-absolute'}>
                        <InputForm
                          className='mb-3'
                          label='Confirma tu Correo Electrónico'
                          classLabel='p-regular-medium'
                          id='input-sign-up-repeat-email'
                          inputRef={repeatEmailRef}
                          type='email'
                          onChange={handleInput}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          withErrors={inputWithError}
                          messageError={messageError}
                        />
                      </div>

                      <div className={inNameInput ? 'display' : 'invisible position-absolute'}>
                        <InputForm
                          className='mb-3'
                          label='Nombre'
                          classLabel='p-regular-medium'
                          id='input-sign-up-name'
                          inputRef={nameRef}
                          type='text'
                          onChange={handleInput}
                          withErrors={inputWithError}
                          messageError={messageError}
                        />
                      </div>

                      <div className={inPasswordInput ? 'display' : 'invisible position-absolute'}>
                        <InputForm
                          className='mb-3'
                          label='Contraseña'
                          classLabel='p-regular-medium'
                          id='input-sign-up-password'
                          inputRef={passwordRef}
                          type={showPasswordText ? 'text' : 'password'}
                          onChange={handleInput}
                          withErrors={inputWithError}
                          messageError={messageError}
                          lastIconClass={`${inPasswordInput ? 'last-input-icon' : 'd-none'}`}
                          lastIconClicked={switchPasswordText}
                          lastIconName={showPasswordText ? 'ico-hide' : 'ico-show'}
                          value={inputValue}
                        />
                      </div>

                      {passwordRules && (
                        <div className='mb-3'>
                          <p className='text-black p-regular-medium'>Crea una contraseña que:</p>
                          <ul className='text-secondary-3'>
                            <li className={`p-regular-medium ${lengthLi}`}>Contenga de 8 a 16 caracteres.</li>
                            <li className={`p-regular-medium ${minusMayusLi}`}>Contenga tanto letras minúsculas (a-z) como mayúsculas (A-Z).</li>
                            <li className={`p-regular-medium ${numberAndSymbolLi}`}>
                              Contenga al menos un número (0-9) y un símbolo ($@!%*?&#.()_).
                            </li>
                            <li className={`p-regular-medium ${emailLi}`}>No sea tu dirección de correo electrónico</li>
                          </ul>
                        </div>
                      )}

                      <div className={checkBox}>
                        <Form.Check
                          type={'checkbox'}
                          id={'terms-check'}
                          label={'Acepto los Terminos y Condiciones'}
                          checked={check}
                          onChange={check ? uncheckValue : checkValue}
                          className='p-regular'
                        />

                        <Form.Check
                          type={'checkbox'}
                          id={'newsletter-check'}
                          label={'Suscribirme a Ofertas y Novedades'}
                          checked={false}
                          onChange={() => {}}
                          className='p-regular'
                        />

                        <Form.Check
                          type={'checkbox'}
                          id={'session-check'}
                          label={'Mantenme Conectado'}
                          ref={sessionCheckRef}
                          checked={checkKeep}
                          onChange={changeCheckKeep}
                          className='p-regular'
                        />

                        <div className='text-center fw-light text-complementary-2'>
                          <small> {!check ? 'Acepte Terminos y Condiciones para completar registro' : ''}</small>
                        </div>
                      </div>

                      <div className='d-grid gap-2'>
                        <Button
                          className={stateButton}
                          type='button'
                          onClick={
                            inEmailInput
                              ? switchEmailToRepeatEmail
                              : inRepeatEmailInput
                              ? switchRepeatEmailToName
                              : inNameInput
                              ? switchNameToPassword
                              : continueToSignUp
                          }>
                          <span className='p-regular-medium'>{inPasswordInput ? 'REGISTRAR' : 'CONTINUAR'}</span>
                        </Button>
                      </div>

                      {inEmailInput && (
                        <div className='display'>
                          <AccessByOthers />
                        </div>
                      )}
                    </Form>
                  </Col>
                  <Container fluid className='p-0 mt-auto'>
                    <Row>
                      {!inPasswordInput && (
                        <div className='display'>
                          <Col xs={12} className='align-self-center py-3'>
                            <TextAndLink text='¿Ya tienes una cuenta?' classText='text-center' onClick={toLogIn}>
                              <span className='p-regular-medium'>INICIAR SESIÓN</span>
                            </TextAndLink>
                          </Col>
                        </div>
                      )}
                    </Row>
                  </Container>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpForm;
