import IdTypesRepository from '@/data/repositories/IdTypesRepository';
import { IIdTypes } from '@/domain/interfaces/IIdTypes';

/** Representa el controlador que maneja los eventos de los casos de uso del usuario
  * @returns {object} Funciones del controlador
*/
const IdTypesController = () => {

  /** Solicita los tipos de identificación del back
    * @returns {IIdTypes} idTypes - tipos de identificación
  */
  const getIdTypes = async (): Promise<IIdTypes[]> => {

    /** Instancia del Repository
    * @const {IdTypesRepository}
    */
    const idTypesRepository = IdTypesRepository.instance;
    const result = await idTypesRepository.getIdTypes();
    const idTypes: IIdTypes[] = result.data;

    return idTypes;
  }

  /**
   * retorna la funciones del controlador
   */
  return { getIdTypes };
};

export default IdTypesController;
