import { PropsWithChildren, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { RootState } from '@/ui/store/store';
import { useAppSelector } from '@/ui/store/helperRedux';

import { ICart } from '@/domain/interfaces/ICart';

import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';
import NotificationsWidget from '@/ui/components/NotificationsWidget';
import LoginForm from '@/ui/components/user/LoginForm';
import SignUpForm from '@/ui/components/user/SignUpForm';

interface PropsUserMenu {
  /** Evento que oculta y muestra el modal de opciones de usuario */
  handleShowOptionsUser: () => void;
}

const MenuButton = ({ handleShowOptionsUser }: PropsWithChildren<PropsUserMenu>): JSX.Element => {
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  const userLogued = useAppSelector((state) => state.userState);
  /** Estado inicial del modal para Login */
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const handleShowLogin = (): void => setShowLogin(!showLogin);

  /** Estado inicial del modal para Registro */
  const [showRegister, setShowRegister] = useState<boolean>(false); // Fixed the useState call by destructuring it into value + setter pair
  /** Evento que muestra y oculta el modal para registro*/
  const handleShowRegister = (): void => setShowRegister(!showRegister);

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  return (
    <>
      {!isMobile ? (
        <Container fluid className='px-0 mx-0 ms-0'>
          <Row className='g-0 '>
            <Col
              className={` ${cartProducts.reservationId ? 'disabled' : ''} d-flex justify-content-center  `}
              onClick={cartProducts.reservationId ? undefined : userLogued.user.isLogued ? handleShowOptionsUser : handleShowLogin}>
              <Col className='d-flex justify-content-end '>
                <IconList
                  iconName='ico-user'
                  classMain={`${cartProducts.reservationId ? 'svg-tertiary-1' : 'svg-primary-1'}`}
                  classTitle='p-large-medium'
                  title=''
                />
              </Col>
            </Col>
          </Row>
        </Container>
      ) : (
        <div id='user-menu'>
          <nav className='menu'>
            <ul className='list-inline m-0 d-flex justify-content-end pe-3'>
              {/* <li className="list-inline-item pe-3">
                <div className="svg-link-dark">
                  <NotificationsWidget />
                </div>
              </li> */}
              <li className='list-inline-item' onClick={handleShowOptionsUser}>
                <div className='svg-link-dark'>
                  <Icon name='ico-user' />
                </div>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showLogin && <LoginForm showLogin={showLogin} handleShowLogin={handleShowLogin} handleShowRegister={() => setShowLogin(false)} />}
      {showRegister && <SignUpForm showRegister={showRegister} handleShowRegister={handleShowRegister} handleShowLogin={handleShowLogin} />}
    </>
  );
};

export default MenuButton;
