/**
 * Librería de Rutas
 */
export enum RoutesDirections {
  MAIN_ROUTE = '/',
  ERROR_ROUTE = '/error',
  NOT_FOUND_ROUTE = '*',

  /** Declaración de Rutas Relacionadas al usuario */
  USER_INFO_ROUTE = '/usuario/informacion-de-mi-cuenta',
  CHANGE_PASSWORD_ROUTE = '/usuario/cambio-de-clave',

  /** Declaración de Rutas Relacionadas a la tienda */
  STORE_ROUTE = '/tienda',
  STORE_BY_PAGE = '/tienda/:page',
  STORE_BY_CATEGORY_ROUTE = '/tienda/:page/:categoria/:categorySlug',
  PRODUCT_ROUTE = '/producto',
  PRODUCT_DETAILS_ROUTE = '/producto/:userStore/:sku',
  CART_ROUTE = '/carrito',
  CHECK_OUT_ROUTE = '/checkout',

  /** Declaración de Rutas Relacionadas al CRUD */
  INVENTORY_ROUTE = '/inventario',
  MASSIVE_CHARGE_ROUTE = '/inventario/carga-masiva',
  INSERT_VALIDATION_INTERFACE_ROUTE = '/inventario/carga-masiva/validación-inventario',
  ADD_PRODUCTS_FORM_WIZARD_ROUTE = '/inventario/agregar-producto-base',

  /** Declaración de Rutas Relacionadas al Proceso de ventas */
  TRANSBANK_RESPONSE_ROUTE = '/checkout/transbank-response',
  SALES_HISTORY_ROUTE = '/histórico-de-ventas',

  /**Ruta de seguimiento */
  TRACKING_ROUTE = '/orden/seguimiento',
  TRACKING_ROUTE_DETAIL = '/orden/detalle-seguimiento',

  /** Declaración de Rutas Temporales */
  LIBRARY_ROUTE = '/libreria',

  CONSTRUCTION_ROUTE = '/construccion',
}
