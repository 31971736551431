/**
 * Plantilla para definir objetos del Documento Excel
 */
export const chargeExcelTemplate = [
  'ID_TIENDA',

  'ID_PRODUCTO_BASE',
  'NOMBRE_PRODUCTO_BASE',
  'CATEGORÍA_PRODUCTO_BASE',
  'DESCRIPCION_PRODUCTO_BASE',

  'BANNER_PRODUCTO_BASE',

  'TAGS_PRODUCTO_BASE',

  'ID_VARIANTE',
  'VARIANTE_PRINCIPAL',

  'SKU',
  'SUCURSAL',
  'ES_TRANSFERIBLE',
  'CANTIDAD',

  'PRECIO_BASE',
  'PRECIO_OFERTA',

  'EXENTO_DE_IMPUESTOS',
  'IMPUESTO1',
  'IMPUESTO2',
  'IMPUESTO3',
  'IMPUESTO4',

  'MEDIA1',
  'MEDIA2',
  'MEDIA3',
  'MEDIA4',
  'MEDIA5',
  'MEDIA6',
  'MEDIA7',
  'MEDIA8',
  'MEDIA9',
  'MEDIA10',
  'ESTADO',

  'ATRIBUTO1',
  'ATRIBUTO2',
  'GUIA',
  'ATRIBUTO3',
  'ATRIBUTO4',
  'ATRIBUTO5',
  'ATRIBUTO6',
  'ATRIBUTO7',
  'ATRIBUTO8',
  'ATRIBUTO9',
  'ATRIBUTO10',
  'TITULO_FICHA_TECNICA',
  'ESPECIFICACION1',
  'ESPECIFICACION2',
  'ESPECIFICACION3',
  'ESPECIFICACION4',
  'ESPECIFICACION5',
  'ESPECIFICACION6',
  'ESPECIFICACION7',
  'ESPECIFICACION8',
  'ESPECIFICACION9',
  'ESPECIFICACION10',
];

export const titlesExcelTemplate = [
  'ID TIENDA',
  'ID PRODUCTO BASE',
  'NOMBRE PRODUCTO BASE',
  'CATEGORÍA PRODUCTO BASE',
  'DESCRIPCIÓN PRODUCTO BASE',
  'URL BANNER',
  'TAGS (OPCIONAL)',
  'ID VARIANTE',
  'VARIANTE PRINCIPAL',
  'SKU (OPCIONAL)',
  'SUCURSAL',
  '¿ES TRANSFERIBLE ENTRE SUCURSALES?',
  'CANTIDAD',
  'PRECIO BASE',
  'PRECIO OFERTA',
  'EXENTO DE IMPUESTOS',
  'IMPUESTO 1',
  'IMPUESTO 2',
  'IMPUESTO 3',
  'IMPUESTO 4',
  'URL FOTO 1',
  'URL FOTO 2',
  'URL FOTO 3',
  'URL FOTO 4',
  'URL FOTO 5',
  'URL FOTO 6',
  'URL FOTO 7',
  'URL FOTO 8',
  'URL FOTO 9',
  'URL FOTO 10',
  'ESTADO',
  'ATRIBUTO 1',
  'ATRIBUTO 2',
  'GUÍA',
  'ATRIBUTO 3',
  'ATRIBUTO 4',
  'ATRIBUTO 5',
  'ATRIBUTO 6',
  'ATRIBUTO 7',
  'ATRIBUTO 8',
  'ATRIBUTO 9',
  'ATRIBUTO 10',
  'TÍTULO FICHA TÉCNICA',
  'ESPECIFICACIÓN 1',
  'ESPECIFICACIÓN 2',
  'ESPECIFICACIÓN 3',
  'ESPECIFICACIÓN 4',
  'ESPECIFICACIÓN 5',
  'ESPECIFICACIÓN 6',
  'ESPECIFICACIÓN 7',
  'ESPECIFICACIÓN 8',
  'ESPECIFICACIÓN 9',
  'ESPECIFICACIÓN 10',
];
