import * as XLSX from 'xlsx';

/**
 * Genera y descarga un archivo de Excel a partir de un conjunto de datos JSON.
 *
 * @param data Array de objetos que representan los datos a incluir en Excel.
 * @param sheet El nombre que se asignará a la hoja de cálculo dentro del libro de Excel.
 *
 * @example
 * const sampleData = [
 *   { name: "Alice", age: 24, job: "Engineer" },
 *   { name: "Bob", age: 29, job: "Designer" }
 * ];
 * const sheetName = "Employees";
 * downloadExcelUseCase(sampleData, sheetName); // Esto generará y descargará "Employees.xlsx".
 *
 * @remarks
 * Esta función utiliza la biblioteca `xlsx` para manejar la creación y manipulación de archivos de Excel.
 * Asegúrese de que esta función se ejecute en un entorno que permita la descarga de archivos, como un navegador web.
 */

const useDownloadExcel = () => {
  const download = (data, sheet: string) => {
    // Convertir los datos ordenados en una hoja de cálculo de Excel
    const ws = XLSX.utils.json_to_sheet(data);

    // Crear un nuevo libro de Excel y agregar la hoja de cálculo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);

    // Descargar el archivo Excel
    XLSX.writeFile(wb, sheet + '.xlsx');
  };

  return { download };
};
export default useDownloadExcel;
