/**
 * Librería de tipos de identificacion
 */
export enum IdentificationTypes {
  RUT_TYPE = 1,
  PASSPORT_TYPE = 2,
  CC_TYPE = 3,
}

/**
 * Estructura de tipos
 */
export interface IIdentificationTypes {
  id: number;
  name: string;
}
/**
 * Lista de tipos de identificación.
 *
 * @Typedef {Object} IIdentificationTypes
 * @property {string} id - El ID del tipo de identificación.
 * @property {string} name - El nombre del tipo de identificación.
 */

/**
 * Lista de tipos de identificación disponibles.
 *
 * @type {IIdentificationTypes[]}
 */
const TypeList: IIdentificationTypes[] = [
  {
    id: IdentificationTypes.RUT_TYPE,
    name: 'RUT',
  },
  {
    id: IdentificationTypes.PASSPORT_TYPE,
    name: 'Pasaporte',
  },
  {
    id: IdentificationTypes.CC_TYPE,
    name: 'Cédula de ciudadanía',
  },
];

export default TypeList;
