import { ILoginData } from '@/domain/interfaces/IUser';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';

import useEmailValidation from '@/domain/genericValidations/useEmailValidation';
import { usePasswordValidation } from '@/domain/genericValidations/usePasswordValidation';

import ClientRepository from '@/data/repositories/ClientRepository';
import { ILoginApi } from '@/domain/interfaces/ILoginResponse';

/** Caso de uso de login de un usuario - Validaciones
  * @param {ILoginData} user - data para login
  * @param {boolean} isALogin - el metodo sirve para solo validar o validar y consultar el API
  * @returns {object} data de usuario y token que retorna el back 
*/
const loginUser = async (user: ILoginData, isALogin: boolean = true): Promise<IUserResponse> => {

  /** Hooks de validacion */
  const validaEmail = useEmailValidation;
  const validaPassword = usePasswordValidation;

  /** Mensajes de alertas */
  const statusInactive = 'INACTIVE';
  const messageInactive = 'Usuario bloqueado';
  const mgsInvalidEmail = 'Correo no válido';
  const mgsInvalidPassword = 'Contraseña no válida';
  const messageInvalidUser = 'Credenciales no válidas';

  /** Instancia del Repository
    * @const {ClientRepository}
  */
  const clientRepository = ClientRepository.instance;

  /** Objeto devuelto en la propiedad 'data' del objeto response */
  let dataResponse: ILoginApi = {
    id: 0,
    email: '',
    storeId: 0,
    branchOffice: { id: 0, name: '' },
    token: '',
    status: '',
    name: '',
    position: '',
    roles: [],
    isLogued: false
  };

  /** Objeto response */
  let response: IUserResponse = {
    data: null,
    isError: true,
    inputError: null,
    message: []
  }

  /** Validación de email */
  if (!validaEmail(user.email!)) {
    response.inputError = 'email';
    response.message.push(mgsInvalidEmail);
    return response;
  } else {
    user.email = user.email?.toLocaleLowerCase() ?? null;
  }

  /** validación contraseña */
  if (!validaPassword(user.password!)) {
    response.inputError = 'password';
    response.message.push(mgsInvalidPassword);
    return response;
  }

  /** Si es un submit se envía la info al Back */
  if (isALogin) {
    /** Almacena la data al consultar la API para el login
      * @const {Promise}
    */

    /** Captura el error de llamado si existe */
    let errorCatch: string | null = null;

    /** Llamada al back para hacer login */
    const data: Promise<any> = await clientRepository.loginUser(user)
      .then((result) => {
        if (result.status === 204) {
          return result.status;
        } else {
          dataResponse = result.data;
        }
      })
      .catch((error) => {
        errorCatch = error.response.status;
        return error;
      })

    /** Errores de back informando mal formato del objeto enviado o usuario no se pudo loguear */
    if (errorCatch === 403 || await data === 204) {
      response.message.push(messageInvalidUser);
      return response;
    }

    /** Identifica si el usuario se pudo loguear pero esta inactivo */
    if (dataResponse.status === statusInactive) {
      response.message.push(messageInactive);
      return response;
    }

    /** Apaga el error ya que no hubo un early return */
    response.isError = false;
    /** Marca que pudo loguearse */
    dataResponse.isLogued = true;
    /** Envia la información del login */
    response.data = dataResponse;
  }

  return response;
}

export { loginUser };