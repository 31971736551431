import { PropsWithChildren, ReactNode } from 'react';

import Icon from '@/ui/assets/Icon';

interface PropsBackIcon {
  /** Clase principal del icono back '<' */
  classMain?: string;
  /** Evento al hacer click al icono back '<' */
  onClick?: (params: any) => ReactNode | void;
}

/**
 * Componente SVG de atras
 * @param {string} prop1 - Description of the first property.
 * @param {number} prop2 - Description of the second property.
 * @returns {JSX.Element} The rendered JSX element.
 */
const BackIcon = ({ classMain = '', onClick }: PropsWithChildren<PropsBackIcon>): JSX.Element => {
  return (
    <button onClick={onClick} className={`${classMain} text-center link-text-standard`}>
      <Icon name="ico-left-arrow" />
    </button>
  );
};

export default BackIcon;
