import { PropsWithChildren, ReactNode } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';

interface PropsClientList {
  /** Clase para mostrar y ocultar barra al final del listado */
  classBar?: string;
  /** Evento click al enlance de cierre de sesión */
  clickSignOutIcon?: (params: any) => ReactNode | void;
  /** Evento que maneja el modal */
  handleModal?: (params: any) => ReactNode | void;
}

/** Componente Nav Usuario Logueado
 * @component
 */
const ClientList = ({ classBar = '', handleModal, clickSignOutIcon }: PropsWithChildren<PropsClientList>): JSX.Element => {
  /**  Hook para trabajar con document location  */
  const location: Location = useLocation();

  return (
    <Container id='client-list' fluid className='p-0'>
      <Row className='g-0'>
        <Col xs={12}>
          <ul className='list-unstyled svg-secondary-1'>
            {/* <NavLink
              to={RoutesDirections.USER_INFO_ROUTE}
              state={{originURL: location.pathname}}
            >
              <IconList
                id='account'
                iconName='ico-info'
                classMain='p-2 my-1 btn-list-link-icon-light-small'
                classTitle='p-regular'
                title={'Información de mi cuenta'}
                onClick={handleModal}
              />
            </NavLink>
            <NavLink
              to={RoutesDirections.CHANGE_PASSWORD_ROUTE}
              state={{originURL: location.pathname}}
            >
              <IconList
                id='password'
                iconName='ico-password'
                classMain='p-2 my-1 btn-list-link-icon-light-small'
                classTitle='p-regular'
                title='Contraseña'
                onClick={handleModal}
              />
            </NavLink>
            <IconList
              iconName='ico-bill'
              classMain='p-2 my-1 btn-list-link-icon-light-small'
              classTitle='p-regular'
              title='Datos de facturación'
            />
            <IconList
              iconName='ico-address'
              classMain='p-2 my-1 btn-list-link-icon-light-small'
              classTitle='p-regular'
              title='Mis direcciones'
            />
            <IconList
              iconName='ico-wallet'
              classMain='p-2 my-1 btn-list-link-icon-light-small'
              classTitle='p-regular'
              title='Mi Billetera'
            /> */}
            <IconList
              iconName='ico-exit'
              classMain='p-2 mt-2 btn-list-link-icon-light-small list-unstyled w-100'
              classTitle='p-regular'
              title='Cerrar sesión'
              id='close-session'
              onClick={clickSignOutIcon}
            />
            <li className={`mx-2 my-4 bg-secondary-5 mh-2-px ${classBar}`}></li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientList;
