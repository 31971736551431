import { useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { useAppSelector } from '@/ui/store/helperRedux';

/**
 * Guarda la URL consultada en el localstorage y envía el usuario al Login
 */

const useAskUserCredentials = () => {
  /** Hook para trabajar con la navegación  */
  const navigate: NavigateFunction = useNavigate();
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  const route = location.pathname + location.search + location.hash;

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  useEffect(() => {
    !userLogued.user.isLogued && navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname, needAuth: true, from: route } });
  }, [navigate, route, userLogued.user.isLogued]);
};

export default useAskUserCredentials;
