import { PropsWithChildren } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { Location, useLocation } from 'react-router-dom';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';

import lastPageName from '@/domain/utils/lastPageName';

import { Col, Container, Row } from 'react-bootstrap';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import useAskUserCredentials from '@/ui/hooks/useAskUserCredentials';

import './style.css';

interface PropsValidationInterface {
  /**Elemento React para Menu */
  menu: JSX.Element;
}

/**
 * Contenedor de pre carga de archivos carga masiva
 * @component
 */
const ValidationInterface = ({ menu, children }: PropsWithChildren<PropsValidationInterface>): JSX.Element => {
  /** Si el usuario no está logueado se ejecutará la utilidad para pedir credenciales */
  useAskUserCredentials();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: 'Inventario', url: RoutesDirections.INVENTORY_ROUTE },
    { label: 'Carga masiva', url: RoutesDirections.MASSIVE_CHARGE_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  return (
    <Container fluid className={`ps-4 pe-5 pb-5 pt-3 bg-secondary-5`}>
      <Row className=' mt-1 me-2 mb-3 ms-3 g-0'>
        <Col xs={12} className='p-2 bg-primary-4 shadow rounded'>
          <Breadcrumbs list={breadcrumbsList} />
        </Col>
      </Row>
      <Row className='mx-2 g-0'>
        <Col xs={2}>
          <Container className='nav-desktop'>
            <Row className='g-0'>
              <Col xs={12} className='p-0 align-self-center bg-primary-4 rounded-2'>
                <Container fluid className='px-1 mb-5'>
                  {menu}
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={12} md={10} className='bg-primary-4 rounded-2'>
          <Container fluid>
            <Row className='g-0 mx-3 py-4'>
              <Col xs={12}>{children}</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ValidationInterface;
