/**Función que genera un ID numerico unico */
const generateNumberId = (): number => {

    // Crear un conjunto para almacenar IDs generados
    const idGenerated = new Set<number>();

    // Función para generar un ID único
    const generateRandomId = ():number =>  {
        return Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    }

    // Función para obtener un ID único
    const obtainUniqueId = ():number => {
        let newId = generateRandomId();

        // Verificar si el ID ya ha sido generado
        while (idGenerated.has(newId)) {
            newId = generateRandomId();
        }

        // Agregar el nuevo ID al conjunto
        idGenerated.add(newId);

        return newId;
    }

    // Llamar a la función para obtener y devolver un ID único
    return obtainUniqueId();
}

export default generateNumberId;
