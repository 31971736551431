import { PropsWithChildren } from 'react';

import { Col, Container, Row } from 'react-bootstrap';


interface PropsBackClose {
    /** Clase del componente BackClose */
    classMain?: string;
}

/** Componente para salir y regresar
    * @component
*/
const BackClose = ({classMain='', children}:PropsWithChildren<PropsBackClose>):JSX.Element => {
    return (
        <Container fluid className='p-0 py-2'>
            <Row className='g-0'>
                <Col xs={12} className={`px-2 d-flex ${classMain}`}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

export default BackClose;