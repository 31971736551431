import { IAttributes } from '@/domain/interfaces/IAttributes';
import decimalFormat from '@/domain/utils/decimalFormat';

/**
 * Hook personalizado que formatea los atributos que vienen del back para hacer mostrados en el front con un formato definido.
 * @returns {string}  formato de atributos principales con llave y valor.
 */
const useAttributesPresenter = () => {

    //Transforma atributos en un objeto como se presentan para el cliente
    const attributesPresenter = (attributes: IAttributes[]): string => {

        let attributePresenterString: string = '';

        attributes.forEach(element => {
            if (element.main) {

                let attributeValue: string = '';

                if (element.values) {
                    if (element.isQualitative) {
                        attributeValue = element.values[0].name ?? '';
                    } else {
                        attributeValue = `${decimalFormat(element.values[0].value ?? '', element.values[0].decimalPrecision ?? 0)} ${element.values[0].prefix}`
                    }
                }

                if (attributePresenterString.length === 0) {
                    attributePresenterString = `${element.name}:${attributeValue}`;
                } else {
                    attributePresenterString += ` - ${element.name}:${attributeValue}`;
                }
            }
        });

        return attributePresenterString;
    }

    return { attributesPresenter };
}

export default useAttributesPresenter;
