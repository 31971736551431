import { Image } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';
import ErrorImage from '@/ui/assets/ErrorImage';

import './style.css';

interface ThumbnailProps {
  /** URL */
  imageSrc: string;
  /** Booleano que define si es una imagen principal */
  mainImg?: boolean;
  /** Évento eliminar */
  onClickErase?: (index: number) => void;
}

const Thumbnail = ({ imageSrc, onClickErase, mainImg = false }: ThumbnailProps): JSX.Element => {
  return (
    <div className='m-1 px-2 d-flex flex-row align-items-center '>
      <div className='position-relative'>
        <Image
          src={imageSrc}
          alt={`Thumbnail`}
          className='rounded rounded-3 shadow thumbnail-img'
          onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
            e.currentTarget.src = ErrorImage;
          }}
        />
        {mainImg && <span className='primary-img bg-dark rounded rounded-4 text-primary-4 position-absolute bottom-0 start-50 mb-1'>Portada</span>}
        <CloseIcon
          classMain='btn-tag position-absolute top-0 end-0 me-1 mt-1 bg-black-transparent svg-primary-4'
          {...(onClickErase ? { click: onClickErase } : {})}
        />
      </div>
    </div>
  );
};

export default Thumbnail;
