import { useEffect, useState } from 'react';

import { useLocation, useNavigate, type Location, NavigateFunction, NavLink } from 'react-router-dom';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import ProductsController from '@/controllers/ProductsController';
import IndexController from '@/controllers/IndexController';

import { useAppSelector } from '@/ui/store/helperRedux';
import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { IProduct } from '@/domain/interfaces/IProduct';
import { IMenu } from '@/domain/interfaces/IMenu';
import { ISocialMedia } from '@/domain/interfaces/ISocial';
import { IIndexConfiguration, ISectionIndex } from '@/domain/interfaces/IIndexConfiguration';

import generateNumberId from '@/domain/utils/generateNumberId';

import Icon from '@/ui/assets/Icon';
import BlankImageBanner from '@/ui/assets/BlankImageBanner';

import IconList from '@/ui/components/IconList';
import Carousel from '@/ui/components/Carousel';
import MainTitle from '@/ui/components/MainTitle';
import ProductPreview from '@/ui/components/store/ProductPreview';
import InfoContainer from '@/ui/components/InfoContainer';

import HomeLoading from '@/ui/pages/Home/HomeLoading';

/** Componente pagina Home
 * @component
 */
const Home = (): JSX.Element => {
  /**
   * Solicita los últimos productos principales creados
   * @param {number} productToShow
   * @returns {IProduct[]} productResponse objeto de respuesta
   */
  const { getNewProducts } = ProductsController();

  /** Controlador funciones del Index */
  const { getIndexJsonConfiguration } = IndexController();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();
  /** Objeto Redux que almacena la data del menu  */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);
  /** Listado de categorías */
  const categoryMenu: IMenu[] = navMenu?.[0]?.children?.[0]?.children || [];
  /**Estado de los productos en la sección de novedades */
  const [newProducts, setNewProducts] = useState<IProduct[]>([]);

  /** Estado inicial de la interfaz de loading */
  const [loading, setLoading] = useState<boolean>(true);

  /**secciones de index*/
  const [secciones, setSecciones] = useState<ISectionIndex[]>([]);

  /** estado de redes sociales*/
  const [socialMedia, setSocialMedia] = useState<ISocialMedia | null>(null);

  /** estado de imágenes del slider */
  const [images, setImages] = useState<string[]>([]);

  /**Si las categorías del menu son mayores a las categorías a mostrar , se mostrara categoriesToShow, si no la cantidad de categoriesToShow*/
  const categoriesInDesktopCarousel: number =
    categoryMenu.length > ConfigNumberParameters.CATEGORY_TO_SHOW ? ConfigNumberParameters.CATEGORY_TO_SHOW : categoryMenu.length;

  /** Si es mobile solo muestra 3 , si no muestra categoriesInDesktopCarousel */
  const categoriesInCarousel: number = isMobile ? 2 : categoriesInDesktopCarousel;

  /**UseEffect que trae los productos nuevos en base a la consulta del controlador */
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const newProducts = await getNewProducts(ConfigNumberParameters.PRODUCTS_TO_SHOW);
        setNewProducts(newProducts);
      } catch (err) {
        setNewProducts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const getIndexConfiguration = async () => {
    try {
      const data: IIndexConfiguration | null = await getIndexJsonConfiguration();
      if (data) {
        setSecciones(data.secciones || []);
        setSocialMedia(data.socialMedia || []);
        setImages(isMobile ? data.indexMobileImagesSlider : data.indexDesktopImagesSlider);
      }
    } catch (error) {
      console.error(error);
    }
  };

  ///useEffect que trae las secciones y redes sociales y carruseles de imágenes a traves del controlador
  useEffect(() => {
    getIndexConfiguration();
  }, []);

  /**Constantes de texto */
  const NEWS_TITLE_TEXT: string = 'Novedades';
  const NEWS_DETAIL_TEXT: string = 'Productos recientemente publicados.';

  const RRSS_TITLE_TEXT: string = socialMedia?.title ?? '';
  const RRSS_DETAIL_TEXT: string = socialMedia?.details ?? '';

  const areCategoriesAvailable: boolean = categoryMenu.length > 2;
  const areNewProductsAvailable: boolean = newProducts.length > 4;
  const areSocialMediaAvailable: boolean = !!socialMedia?.info?.length;

  return (
    <>
      {loading ? (
        <HomeLoading />
      ) : (
        <Container fluid className='p-0 bg-primary-4'>
          {/* Carousel de Imágenes */}
          <Row className='pt-2 mb-4 mb-lg-0 g-0 full-centered-carousel '>
            <Carousel
              slidesToShow={1}
              slidesToScroll={1}
              centerPadding={isMobile ? '10px' : '150px'}
              speed={500}
              autoplay={true}
              autoplaySpeed={5000}
              dots={true}
              centerMode={true}
              arrows={!isMobile}>
              {images?.map((image, index) => (
                <Col key={`index-image-${index}`} className='image-index-container'>
                  <Image
                    fluid
                    src={image}
                    alt={`Imagen ${index}`}
                    className='px-1 rounded-4 cursor-hand '
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = BlankImageBanner;
                    }}
                  />
                </Col>
              ))}
            </Carousel>
          </Row>
          {/* Carousel de Categorías */}
          {areCategoriesAvailable && (
            <Container className='p-0'>
              <Row>
                <Carousel
                  slidesToShow={categoriesInCarousel}
                  slidesToScroll={1}
                  speed={500}
                  dots={true}
                  variableWidth={isMobile}
                  className='carousel-category'>
                  {categoryMenu.map((category) => (
                    <span className='text-center' key={`carousel-category-${category.slug}}`}>
                      <IconList
                        classMain='my-2 py-2 btn-category mx-2 bg-primary-5 svg-secondary-2 rounded-pill cursor-hand'
                        iconName={category.icon}
                        title={category.title}
                        handleMouseMove={() => navigate(category.url, { state: { originURL: location.pathname } })}
                      />
                    </span>
                  ))}
                </Carousel>
              </Row>
            </Container>
          )}
          {/* Carousel de Novedades */}
          {areNewProductsAvailable && (
            <Row className={`${areCategoriesAvailable ? 'mt-5' : ''} pb-5 pb-lg-0 g-0 padding-arrows-carousel bg-primary-5`}>
              <Col className='px-2 mt-3'>
                <MainTitle titleSize={`${isMobile ? 'h5' : 'h1'}`} alignment='center' boldTitle={NEWS_TITLE_TEXT} detailsText={NEWS_DETAIL_TEXT} />
              </Col>
              <Carousel slidesToShow={isMobile ? 2 : 5} slidesToScroll={1} speed={200} arrows={!isMobile} dots={isMobile} draggable={false}>
                {newProducts.map((product) => (
                  <Col key={product.id} className='my-2 d-flex justify-content-center'>
                    <Col xs={11} lg={10}>
                      <ProductPreview isGrid={true} dataToRendering={product} shadow />
                    </Col>
                  </Col>
                ))}
              </Carousel>
            </Row>
          )}
          <div className={!areNewProductsAvailable && areCategoriesAvailable ? 'mt-5' : ''}>
            {secciones.length > 0 &&
              secciones.map((container, index) => (
                <Container key={`array1-container-${index}`} fluid className={`${index % 2 === 0 ? 'bg-secondary-5' : 'bg-primary-5'} py-5`}>
                  <InfoContainer data={container} />
                </Container>
              ))}
          </div>
          {areSocialMediaAvailable && (
            <Container fluid className='bg-rrss-container py-5 '>
              <Container className='py-lg-5'>
                <Row>
                  <Col lg={6} className='p-0 mt-2 my-lg-auto ms-auto bg-rrss rounded rounded-3 py-3'>
                    <Col lg={10} className='m-auto'>
                      <Col className='text-center mb-4'>
                        <span className={` h1  text-primary-4`}>{RRSS_TITLE_TEXT}</span>
                      </Col>
                      <Col className=' text-center mb-3'>
                        <span className='h5 text-primary-4'>{RRSS_DETAIL_TEXT}</span>
                      </Col>
                      {socialMedia?.info.map((media) => (
                        <Col key={`social-media-${generateNumberId()}`} className='my-2  '>
                          <NavLink to={`${media.url}`} target='_blank' rel='noreferrer'>
                            <Button className={`btn-${media.name.toLowerCase()} d-flex align-items-center w-100 border-0 rounded-0 `}>
                              <IconList
                                title={`${media.name}`}
                                iconName={media.icon}
                                classIcon='svg-primary-4'
                                classMain={`${isMobile ? 'p' : 'h4'} my-1`}
                                classTitle='text-capitalize'
                              />
                              <span className={`px-3 p-large m-0`}>{media.user}</span>
                              <Col className='text-end'>
                                <Icon name={'ico-redirect'} classMain={`px-3 svg-primary-4`} />
                              </Col>
                            </Button>
                          </NavLink>
                        </Col>
                      ))}
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default Home;
