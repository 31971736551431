import { useAppDispatch } from '@/ui/store/helperRedux';
import { login, logout, updClient } from '@/ui/store/slices/userSlice';

import ClientRepository from '@/data/repositories/ClientRepository';

import { loginUser } from '@/domain/useCases/loginUseCase';
import { registerUser } from '@/domain/useCases/registerUserCase';
import { getInfoUserCase } from '@/domain/useCases/getInfoUserCase';
import updateUser from '@/domain/useCases/updateUserCase';
import changePasswd from '@/domain/useCases/changePasswdUseCase';

import { IClient, ILoginData, IRegistrationData } from '@/domain/interfaces/IUser';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';
import { IChangePaswd } from '@/domain/interfaces/IChangePaswd';

/** Representa el controlador que maneja los eventos de los casos de uso del usuario
 * @returns {object} Funciones del controlador
 */
const UserController = () => {
  const dispatch = useAppDispatch();

  /** Instancia del Repository
   * @const {ClientRepository}
   */
  const clientRepository = ClientRepository.instance;

  /** Realiza el proceso de login
   * @param {ILoginData} user - data del usuario a loguear
   * @param {boolean} isALogin - el método sirve para solo validar o validar y hacer login
   * @param {boolean} keep - mantener la sesión
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const doLogin = async (user: ILoginData, isALogin: boolean = true, keep: boolean = true): Promise<IUserResponse> => {
    /** Realiza las validaciones y llamados al API     */
    const userLogued: IUserResponse = await loginUser(user, isALogin);

    if (userLogued.isError) {
      return userLogued;
    }

    /** Si se solicitó el login se actualiza redux y localeStorage     */
    if (isALogin) {
      /** Actualiza el estado Redux del usuario si logro loguear       */
      dispatch(login(userLogued.data));

      /** Mantiene la sesión si el usuario lo marcó */
      if (keep) {
        keepCredentials(userLogued);
      } else {
        cleanCredentialsLocalStorage();
      }
    }
    return userLogued;
  };

  /**
   * Realiza el proceso de registro
   * @param {IRegistrationData} user - data del usuario a loguear
   * @param {boolean} keep - Si desea mantener la sesión
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const clientRegistration = async (user: IRegistrationData, keep: boolean = true): Promise<IUserResponse> => {
    /**
     * Realiza las validaciones y realiza el registro
     */
    const userValidation: IUserResponse = await registerUser(user);

    /**
     * si existen errores almacena true
     */
    let userRegistered: boolean = userValidation.isError;

    /** luego de registro efectivo */
    if (!userRegistered) {
      /** Mantiene la sesión si el usuario lo marcó */
      if (keep) {
        keepCredentials(userValidation);
      } else {
        cleanCredentialsLocalStorage();
      }

      /** Actualiza el estado Redux del usuario si logro loguearse */
      dispatch(login(userValidation.data));
    }

    return userValidation;
  };

  /**
   * Realiza el proceso de logout
   */
  const doLogout = (): void => {
    /** Limpia localStorage */
    cleanCredentialsLocalStorage();

    /** Limpia el estado Redux del usuario */
    dispatch(logout());
  };

  /**
   * Realiza actualización de información de cliente/usuario
   * @param {IClient} user - data del usuario a loguear
   * @param {number} id - ID del usuario a actualizar
   * @param {string} token - token valido
   * @param {boolean} toSend - Solo validación o validación y submit
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const updateClient = async (user: IClient, id: number = 0, token: string = '', toSend: boolean): Promise<IUserResponse> => {
    /**
     * Realiza las validaciones y realiza el registro
     */
    const userValidation: IUserResponse = await updateUser(user, id, token, toSend);

    /**
     * si existen errores almacena true
     */
    let userRegistered: boolean = userValidation.isError;

    /** luego de actualización efectiva */
    if (!userRegistered && toSend) {
      /** Si se modificó el email */
      if (user.email !== undefined && user.email !== null) {
        localStorage.setItem('ilisPlacesEmail', user.email!);
        dispatch(updClient({ email: user.email }));
      }
      /** Si se modificó el nombre */
      if (user.firstName !== undefined && user.firstName !== null) {
        const name = user.firstName!.charAt(0).toUpperCase() + user.firstName!.slice(1).toLowerCase();
        localStorage.setItem('ilisPlacesName', name);
        dispatch(updClient({ name: name }));
      }
    }

    return userValidation;
  };

  /** Devuelve información del usuario
   * @param {string} email - email a consultar
   * @param {string} token - token valido
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const getUser = async (email: string = '', token: string = ''): Promise<IUserResponse> => {
    /** Realiza llamados al API y genera mensajes de error */
    const user: IUserResponse = await getInfoUserCase(email, token);

    return user;
  };

  /**
   *  Actualiza localStorage luego de login efectivo
   * @param {IUserResponse} user - Mensajes, estado y data del usuario encontrado previamente
   */
  const keepCredentials = (user: IUserResponse): void => {
    const userClient = 'CLIENT';
    const userAdmin = 'ADMIN';
    const loginTime: number = new Date().getTime();

    localStorage.setItem('ilisPlacesEmail', user.data!.email);
    localStorage.setItem('ilisPlacesActiveUser', user.data!.status);
    localStorage.setItem('ilisPlacesToken', user.data!.token);
    const name = user.data!.name.charAt(0).toUpperCase() + user.data!.name.slice(1).toLowerCase();
    localStorage.setItem('ilisPlacesName', name);
    localStorage.setItem('ilisPlacesPosition', user.data!.position);
    localStorage.setItem('ilisPlacesIdUser', String(user.data!.id));
    localStorage.setItem('ilisPlacesUserStore', String(user.data!.storeId));
    localStorage.setItem('ilisPlacesUserOfficeID', String(user.data!.branchOffice?.id));
    localStorage.setItem('ilisPlacesUserOfficeName', String(user.data!.branchOffice?.name));
    localStorage.setItem('ilisLoginDate', String(loginTime));

    if (user.data!.roles.includes(userClient) && user.data!.roles.length > 1) {
      localStorage.setItem('ilisPlacesKindUser', userAdmin);
    } else {
      localStorage.setItem('ilisPlacesKindUser', userClient);
    }

    localStorage.setItem('ipUser', '1.5.5.5');
  };

  /**
   * Realiza actualización de contraseña de cliente/usuario
   * @param {IChangePaswd} passwords - data del usuario a loguear
   * @param {boolean} onlyPassword - flag para saber si debe validar unicamente el password actual
   * @param {number} id - ID del cliente
   * @param {string} token - token valido
   * @param {boolean} toSend - flag para saber si debe enviar al back
   * @param {string} email - email del usuario activo
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const updatePassword = async (
    passwords: IChangePaswd,
    onlyPassword: boolean = true,
    id: number = 0,
    token: string = '',
    toSend: boolean,
    email: string = '',
  ): Promise<IUserResponse> => {
    /**
     * Realiza las validaciones y realiza el registro
     */
    const userValidation: IUserResponse = await changePasswd(passwords, onlyPassword, id, token, toSend, email);

    return userValidation;
  };

  /**
   *  Clean localStorage
   */
  const cleanCredentialsLocalStorage = (): void => {
    // Obtiene todas las claves almacenadas en localStorage
    const keys = Object.keys(localStorage);

    // Itera sobre las claves y elimina aquellas que no sean 'cart'
    keys.forEach(function (key) {
      if (key !== 'IlisPlacesCart') {
        localStorage.removeItem(key);
      }
    });
  };

  /** Renueva el token de usuario logueado
   * @param {string} token
   * @returns {IUserResponse} IUserResponse - Mensajes, estado y data
   */
  const tokenRenovation = async (token: string = ''): Promise<boolean> => {
    try {
      /** Solicita renovación del token al back   */
      const renovation = await clientRepository.renovateToken(token);

      if (renovation.status !== 200) {
        return false;
      }

      /** Objeto response */
      const response: IUserResponse = {
        data: renovation.data,
        isError: false,
        inputError: null,
        message: [],
      };

      /** Actualiza el estado Redux del usuario si logro renovar */
      dispatch(login(renovation.data));
      keepCredentials(response);

      return true;
    } catch (error) {
      console.error('Renovar token', error);
      return false;
    }
  };

  /**
   * retorna la funciones del controlador
   */
  return { doLogin, clientRegistration: clientRegistration, doLogout, getUser, updateClient, updatePassword, tokenRenovation };
};

export default UserController;
