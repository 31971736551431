import { ComponentType, PropsWithChildren } from 'react';
import { Accordion } from 'react-bootstrap';
import { PropsAttributeValue } from '@/ui/components/product/AttributeValue';

import './style.css';

/** Propiedades para el componente PriceSlider. */
interface PropsDropDownList {
  /**  Id del componente.  */
  id: string;
  /**  Campo de Texto del titulo principal.  */
  title: string;
  /**  Clase del titulo principal   */
  classTitle?: string;
  /** Imagen a renderizar */
  imageUrl?: string;
  /** Elemento a renderizar como item seleccionado */
  RenderHeaderValueSelected?: ComponentType<PropsAttributeValue>;
  /** Comportamiento expandible */
  expandable?: boolean;
}

/**
 * Muestra un componente de lista desplegable
 * El componente debe estar dentro de un <Accordion> de React Boostrap
 * @example
 * return (
 * <Accordion>
 *  <DropDownList id={'id'} title={'title'}>
 *      // Children components
 *  </DropDownList>
 * </Accordion>
 * )
 * @component
 */

const DropDownList = ({
  id,
  title = '',
  classTitle = '',
  imageUrl = '',
  RenderHeaderValueSelected,
  expandable = true,
  children,
}: PropsWithChildren<PropsDropDownList>): JSX.Element => {
  return (
    <Accordion>
      <Accordion.Item
        eventKey={id}
        className='                                                                                                                                                                                                                                               '>
        <Accordion.Header>
          {RenderHeaderValueSelected ? (
            <RenderHeaderValueSelected imageUrl={imageUrl} attributeValue={title} />
          ) : (
            <span className={classTitle}>{title}</span>
          )}
        </Accordion.Header>
        <Accordion.Body
          aria-expanded='true'
          style={
            !expandable
              ? {
                  position: 'absolute',
                  backgroundColor: 'white',
                  zIndex: 1000,
                }
              : {}
          }>
          <ul className='list-unstyled'>{children}</ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DropDownList;
