import axios, { AxiosResponse } from 'axios';
import config from '@/data/services/api';
import { IMenu } from '@/domain/interfaces/IMenu';

/** Representa la interacción con el Back */
export default class CategoriesRepository {
  /** Instancia de la clase   */
  private static _instance: CategoriesRepository;

  /** Patron Singleton
   * @returns {instance} _instance MenuRepository
   */
  static get instance(): CategoriesRepository {
    if (!this._instance) {
      this._instance = new CategoriesRepository();
    }
    return this._instance;
  }

  /** Solicita el menu de categorías
   *
   * @returns {object} data - Objeto de menú
   */
  async getMenuCategories(): Promise<AxiosResponse<IMenu[]>> {
    return await axios.get('/menu', config);
  }

  /** Solicita categorías disponibles
   *
   * @returns {object} data - Objeto de menú
   */
  async getCategories(): Promise<AxiosResponse<IMenu[]>> {
    return await axios.get('/categories', config);
  }
}
