import { PropsWithChildren, ReactNode, useState } from 'react';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import Icon from '@/ui/assets/Icon';

import Title from '@/ui/components/Title';

interface PropsIconList {
  /** Id del componente IconList */
  id?: string;
  /** Clase del componente IconList */
  classMain?: string;
  /** Clase del titulo */
  classTitle?: string;
  /** Clase del icono */
  classIcon?: string;
  /** Titulo del componente IconList */
  title: ReactNode;
  /** Nombre del Icono del componente IconList */
  iconName: string;
  /** Subtitulo del componente IconList */
  subtitle?: string | null;
  /** Texto alternativo en pila */
  pillText?: string | number;
  /** Evento al darle click al componente IconList */
  onClick?: (e: any) => void;
  /** Evento al mover y soltar el mouse */
  handleMouseMove?: () => void;
}

/** Componente SVG de salida de lista con icono
 * @component
 */
const IconList = ({
  id = '',
  classMain = '',
  classTitle = '',
  classIcon = '',
  iconName = '',
  title,
  subtitle = '',
  pillText = '',
  onClick,
  handleMouseMove,
}: PropsWithChildren<PropsIconList>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /**Estado que define la posición del mouse */
  const [startX, setStartX] = useState<number>(0);

  /**Función que cambia la posición del mouse al dar clic y mover el mismo */
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setStartX(e.clientX);
  };

  /**Función que navega hacia la categoría si la posición no varia mucho después de mover el mouse */
  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    const endX = e.clientX;
    if (Math.abs(endX - startX) < 5) {
      handleMouseMove && handleMouseMove();
    }
  };
  return (
    <div
      id={id}
      className={`d-inline-flex align-items-center ${classMain} `}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}>
      {iconName !== '' && <Icon name={iconName} classMain={`pe-2 ${classIcon}`} />}
      <Title>
        <span className={classTitle}>{title}</span>
        {subtitle && <span className={`align-self-baseline d-block ${isMobile ? 'p-regular' : 'p-small'}`}>{subtitle}</span>}
      </Title>
      {pillText && <span className='p-regular text-tertiary-1 pe-2'>({pillText})</span>}
    </div>
  );
};

export default IconList;
