import { PropsWithChildren, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useNavigate } from 'react-router';

import { useLocation, type NavigateFunction, Location } from 'react-router-dom';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { RootState } from '@/ui/store/store';
import { useAppSelector } from '@/ui/store/helperRedux';

import { ICart } from '@/domain/interfaces/ICart';

import ErrorImage from '@/ui/assets/ErrorImage';

import PurchaseTotals from '@/ui/components/sales/PurchaseTotals';
import InputForm from '@/ui/components/forms/InputForm';

interface PropsSuccessfulPayment {
  orderCode: string;
}

/**
 * Componente que muestra la página de pago exitoso
 * @returns
 */

const SuccessfulPayment = ({ orderCode }: PropsWithChildren<PropsSuccessfulPayment>): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /**Trae la información de la orden desde Redux */
  const orderProducts = useAppSelector((state: RootState) => state.orderState) as ICart;

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /**Texto de copiar que cambia cuando se ejecuta la función de copiado */
  const [copyText, setCopyText] = useState<string>('Copiar');

  /**Funcionalidad de copiar texto */
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopyText('Copiado');
  };

  const SUCCESSFUL_PAYMENT_IMAGE: string = `${URL_IMAGES}/client/successful-payment.png`;
  const SUCCESSFUL_PAYMENT_TITLE: string = '¡Felicidades! Tu compra se ha realizado con éxito';
  const SUCCESSFUL_PAYMENT_SUBTITLE: string =
    'Se enviarán los detalles de tu compra a tu correo, puedes seguir explorando nuestros productos ¡Gracias por tu confianza!';
  const EXPLORE_PRODUCTS_TEXT: string = 'EXPLORAR MÁS PRODUCTOS';

  return (
    <Container fluid className='px-0 bg-secondary-5 mh-700-px'>
      <Row className='pt-4 g-0'>
        <Col className='d-flex justify-content-center px-4'>
          <Col lg={8}>
            <Row className='g-0 px-1 '>
              <Col xs={12} lg={9} className='bg-primary-4 shadow rounded d-flex pt-3 px-4 '>
                <Col lg={12} className='d-flex flex-column justify-content-center align-items-center mb-5 pb-2'>
                  <Image
                    src={SUCCESSFUL_PAYMENT_IMAGE}
                    width={266.37}
                    height={224.47}
                    alt='gif'
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = ErrorImage;
                    }}
                  />

                  <Col lg={7}>
                    <Col className='pb-4 mb-2 d-flex text-center justify-content-center'>
                      <span className={`text-primary-3 fw-bold  ${isMobile ? 'h3' : 'h2'}  `}>{SUCCESSFUL_PAYMENT_TITLE}</span>
                    </Col>
                  </Col>

                  <Col lg={7} className='px-4'>
                    <Col className='pb-4'>
                      <InputForm
                        label='ID de la compra'
                        classLabel='text-secondary-2 p-regular'
                        type='text'
                        value={orderCode}
                        lastIconClass='last-input-icon p-0 svg-secondary-3 '
                        lastIconName='ico-copy'
                        classMain='text-secondary-3 fw-bold bg-secondary-4 '
                        lastIconClicked={() => copyToClipboard(orderCode)}
                        lastIconTitle={copyText}
                        onMouseMove={() => setCopyText('Copiar')}
                      />
                    </Col>

                    <Col className='d-flex  text-justify '>
                      <span className=' text-secondary-1 h5  text-center'>{SUCCESSFUL_PAYMENT_SUBTITLE}</span>
                    </Col>
                  </Col>
                </Col>
              </Col>
              <Col xs={12} lg={3} className='my-3 my-md-0 ps-md-3'>
                <Col lg={12} className='bg-primary-4 rounded rounded-3  shadow rounded  pt-2 pe-2 px-2 mh-600-px mh-xs-ignore'>
                  <Col className='px-2'>
                    <PurchaseTotals data={orderProducts} />
                  </Col>
                  <Col className='  mt-5 p-2'>
                    <Button
                      className='btn-primary-icon-standard w-100 logo cursor-hand'
                      onClick={() => navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } })}>
                      {EXPLORE_PRODUCTS_TEXT}
                    </Button>
                  </Col>
                </Col>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessfulPayment;
