import { PropsWithChildren, ReactNode } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavLink, useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { IUserStore } from '@/domain/interfaces/IUser';

import { useAppSelector } from '@/ui/store/helperRedux';

import { Col, Container, Row } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';
import ManagementListTitle from '@/ui/containers/managementOptions/ManagementListTitle';

interface PropsManagementList {
  /** Propiedad que define si viene o no con subtitulos */
  subtitles?: boolean;
  /** Evento que maneja el modal */
  handleModal?: (params: any) => ReactNode | void;
}

/** Componente List Usuario Administrativo
 * @component
 */
const ManagementList = ({ handleModal, subtitles = true }: PropsWithChildren<PropsManagementList>): JSX.Element => {
  /**  Hook para trabajar con document location  */
  const location: Location = useLocation();
  /** Estado Redux del usuario */
  const userLogued: IUserStore = useAppSelector((state) => state.userState);
  /** Representa el tipo de usuario cliente */
  const kindClientUser: string = 'CLIENT';

  return (
    <>
      {userLogued.user.kind !== kindClientUser && (
        <Container id='management-list' fluid className='p-0'>
          <Row className='g-0 align-items-center'>
            <Col xs={12} className='svg-primary-2'>
              <ManagementListTitle />
            </Col>
            <ul className='w-100 list-unstyled svg-secondary-1'>
              {/* <IconList
                iconName='ico-web-edit'
                classMain='my-1 px-2 my-1 btn-list-description-icon-light align-items-center'
                title={'Configurar sitio'}
                subtitle={
                  subtitles
                    ? 'Personalizar secciones que conforman la página.'
                    : null
                }
              />

              <IconList
                iconName='ico-users'
                classMain='my-1 px-2 btn-list-description-icon-light align-items-center'
                title={'Usuarios'}
                subtitle={
                  subtitles
                    ? 'Crear y configurar permisos de cada usuario.'
                    : null
                }
              />

              */}
              <li>
                <NavLink
                  to={RoutesDirections.INVENTORY_ROUTE}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  state={{ originURL: location.pathname }}>
                  <IconList
                    id='inventory'
                    iconName='ico-inventory'
                    classMain='my-1 px-2 align-items-center btn-list-description-icon-light w-100'
                    title='Inventario'
                    subtitle={subtitles ? 'Configurar inventario de productos o servicios.' : null}
                    onClick={handleModal}
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={RoutesDirections.SALES_HISTORY_ROUTE}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  state={{ originURL: location.pathname }}>
                  <IconList
                    iconName='ico-sales'
                    classMain='my-1 px-2 btn-list-description-icon-light align-items-center  w-100'
                    title={'Órdenes'}
                    subtitle={subtitles ? 'Consultar métricas de historial de ventas.' : null}
                    onClick={handleModal}
                  />
                </NavLink>
              </li>
              {/* <IconList
                iconName='ico-store'
                classMain='my-1 px-2 btn-list-description-icon-light'
                title={'Sucursales'}
                subtitle={
                  subtitles
                    ? 'Consultar y configurar información de sucursales.'
                    : null
                }
              /> */}
            </ul>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ManagementList;
