import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { validate } from 'rut.js';

import ClientRepository from '@/data/repositories/ClientRepository';

import { IUserResponse } from '@/domain/interfaces/ILoginResponse';
import { IClient } from '@/domain/interfaces/IUser';

import useEmailValidation from '@/domain/genericValidations/useEmailValidation';
import useNameValidation from '@/domain/genericValidations/useNameValidation';
import useDateValidation from '@/domain/genericValidations/useDateValidation';


/**
 * Caso de uso de registro de nuevo cliente - Validaciones
 * @param {IClient} user - data para nuevo registro
 * @param {string} token - token valido
 * @returns {IUserResponse} - usuario registrado
 */
const updateUser = async (user: IClient, id: number, token: string, toSend: boolean): Promise<IUserResponse> => {

  const messageNoExist: string = 'Usuario no existe!';
  /**
   * Instancia del Repository
   * @const {ClientRepository}
   */
  const clientRepository = ClientRepository.instance;

  /** Hooks de validaciones genericas */
  const validationEmail = useEmailValidation;
  const validationNames = useNameValidation;
  const validationDate = useDateValidation;


  /** Objeto enviado en el return */
  let response: IUserResponse = {
    data: null,
    isError: true,
    inputError: null,
    message: []
  };

  /** Almacenara el codigo de error en repository si existe */
  let errorCatch: number | string | null = null;

  /** Almacenara el mensaje de error en repository si existe */
  let messageErrorCatch: string = '';

  /** Mensajes de error */
  const messageErrEmail: string = 'Formato de email inválido';
  const messageErrName: string = 'Formato de nombre o apellido inválido';
  const messageErrDate: string = 'Formato de fecha inválido';
  const messageErrIdentification: string = 'Formato de identificación inválido';
  const messageErrIdType: string = 'Tipo de identificación inválido';
  const messageErrPhone: string = 'Número teléfonico inválido';
  const codChileRut: string = '1';

  /** Codigo E1006 entregado por el back con mensaje customisado para el usuario*/
  const codBackE1006: string = 'E1006';
  const msjBackE1006: string = 'Email ya existe';

  /** Codigo E1008 entregado por el back con mensaje customisado para el usuario*/
  const codBackE1008: string = 'E1008';
  const msjBackE1008: string = 'Nada por Cambiar';

  /** Validación  email */
  if (user.email !== undefined) {
    if (!validationEmail(user.email!)) {
      response.inputError = 'email';
      response.message.push(messageErrEmail);
      return response;
    } else {
      user.email = user.email?.toLocaleLowerCase();
    }
  }

  /** Validación  nombre */
  if (user.firstName !== undefined) {
    if (!validationNames(user.firstName!)) {
      response.inputError = 'name';
      response.message.push(messageErrName);
      return response;
    } else {
      user.firstName = user.firstName?.toLocaleLowerCase();
    }
  }

  /** Validación  apellido */
  if (user.lastName !== '' && user.lastName !== undefined) {
    if (!validationNames(user.lastName!)) {
      response.inputError = 'name';
      response.message.push(messageErrName);
      return response;
    } else {
      user.lastName = user.lastName?.toLocaleLowerCase();
    }
  }

  /** Validación  fecha nacimiento */
  if (user.birthDay !== undefined) {
    if (!validationDate(user.birthDay!)) {
      response.inputError = 'birthday';
      response.message.push(messageErrDate);
      return response;
    }
  }

  /** Validación  identificacion oficial */
  if (user.identification !== undefined) {
    /** Validación tipo de identificación */
    if (isNaN(Number(user.idType!)) || user.idType === null) {
      response.inputError = 'identification';
      response.message.push(messageErrIdType);
      return response;
    }

    /** Validación identificación Rut Chileno */
    if (user.idType === codChileRut) {
      if (!validate(user.identification!)) {
        response.inputError = 'identification';
        response.message.push(messageErrIdentification);
        return response;
      }
    } else {
      /** Validación identificación otros */
      const alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
      if (!alphanumericRegex.test(user.identification!) || user.identification?.length === 0) {
        response.inputError = 'identification';
        response.message.push(messageErrIdentification);
        return response;
      } else {
        user.identification = user.identification?.toLocaleUpperCase();
      }
    }
  }

  /** Validación teléfono */
  if (user.phone !== undefined) {
    const parsedNumber = parsePhoneNumber(user.phoneInputAux!, user.countryInputAux as CountryCode);
    if (!parsedNumber.isValid()) {
      response.inputError = 'phone';
      response.message.push(messageErrPhone);
      return response;
    }
  }

  /** Si pasa las validaciones apaga el error flag */
  response.isError = false;

  /**
   * Registra el cliente si es un submit action
   */
  if (toSend) {
    /** Repository de Cliente */
    await clientRepository.updateClient(user, id, token)
      .then((result) => {
        if (result.status === 200) {
          /** Apaga el flag de error */
          response.isError = false;
        }
      })
      .catch((error) => {
        console.error(error.response.data)
        /** Devuelve el error */
        errorCatch = error.response.status;
        messageErrorCatch = error.response.data;
      });

    /** Error 204 el usuario no existe */
    if (errorCatch === 204) {
      response.message.push(messageNoExist);
      return response;
    }

    /** Error 422 el usuario no paso validación en back */
    if (errorCatch === 422) {
      response.isError = true;

      if (messageErrorCatch.includes(codBackE1006)) {
        response.message.push(msjBackE1006);
      } else if (messageErrorCatch.includes(codBackE1008)) {
        response.message.push(msjBackE1008);
      } else {
        response.message.push(messageErrorCatch);
      }

      return response;
    }
  }

  return response;
}

export default updateUser;