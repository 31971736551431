import { PropsWithChildren, useEffect } from 'react';

import { NavigateFunction, useLocation, useNavigate, useParams, type Location } from 'react-router-dom';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Pagination } from 'react-bootstrap';

import generateNumberId from '@/domain/utils/generateNumberId';
import { IPaginationSettings } from '@/domain/interfaces/IPagination';
import { RoutesDirections } from '@/data/libraries/Routes';

import './style.css';

/**
 * Componente con la lógica de paginación del Store
 * Recibe como parámetros los que están definido en el  @param Props
 * @component
 */

interface PropsPaginationStore {
  settings: IPaginationSettings;
}

const PaginationStore = ({ settings }: PropsWithChildren<PropsPaginationStore>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Trae los parámetros de la url para renderizar los elementos */
  const { categorySlug } = useParams();

  /**Renderiza la pagina actual o 1 si no viene */
  const currentPage: number = settings.currentPage + 1;

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /**Scroll al navegar */
  useEffect(() => {
    window.scroll(0, 0);
  }, [location.search]);

  /**Función que genera la cantidad de paginas posteriores y anteriores al paginado */
  const generatePaginator = (currentPage: number): JSX.Element => {
    const paginatorHTML: JSX.Element[] = [];

    const link = (page: number) => `${RoutesDirections.STORE_ROUTE}/${page}${categorySlug ? `/categoria/${categorySlug}` : ''}${location.search}`;

    if (currentPage > 1) {
      for (let i = currentPage - 2; i <= currentPage - 1; i++) {
        if (i > 0) {
          paginatorHTML.push(
            <Pagination.Item key={`page-${i}`} className='btn-pagination' onClick={() => navigate(link(i))}>
              <span className='p-regular-bold'>{i}</span>
            </Pagination.Item>,
          );
        }
      }
    }

    // Página actual
    paginatorHTML.push(
      <Pagination.Item key={`page-${generateNumberId()}`} className='btn-pagination' active>
        <span className='p-regular-bold'>{currentPage}</span>
      </Pagination.Item>,
    );

    // Botones de páginas posteriores
    if (currentPage < settings.totalPages - 2) {
      for (let i = currentPage + 1; i <= currentPage + 2; i++) {
        paginatorHTML.push(
          <Pagination.Item key={`page-${i}`} className='btn-pagination' onClick={() => navigate(link(i))}>
            <span className='p-regular-bold'>{i}</span>
          </Pagination.Item>,
        );
      }
    } else if (currentPage < settings.totalPages) {
      for (let i = currentPage + 1; i <= currentPage + 1; i++) {
        paginatorHTML.push(
          <Pagination.Item key={`page-${i}`} className='btn-pagination' onClick={() => navigate(link(i))}>
            <span className='p-regular-bold'>{i}</span>
          </Pagination.Item>,
        );
      }
    }

    return <>{paginatorHTML}</>;
  };

  return (
    <Container fluid className='p-0'>
      <Pagination id='pagination' bsPrefix='pagination-bar' className='inline-items'>
        <Pagination.Prev
          className={`btn-pagination-prev ${isMobile && 'shadow'}`}
          onClick={() =>
            navigate(`${RoutesDirections.STORE_ROUTE}/${currentPage - 1}${categorySlug ? `/categoria/${categorySlug}` : ''}${location.search}`, {
              state: { originURL: location.pathname },
            })
          }
          disabled={settings.isFirst}>
          {'<'}
        </Pagination.Prev>
        <Pagination.First
          className='link-text-standard'
          onClick={() =>
            navigate(`${RoutesDirections.STORE_ROUTE}/${1}${categorySlug ? `/categoria/${categorySlug}` : ''}${location.search}`, {
              state: { originURL: location.pathname },
            })
          }
          disabled={settings.isFirst}>
          Primera
        </Pagination.First>
        {generatePaginator(currentPage)}
        <Pagination.Last
          className='link-text-standard'
          onClick={() =>
            navigate(`${RoutesDirections.STORE_ROUTE}/${settings.totalPages}${categorySlug ? `/categoria/${categorySlug}` : ''}${location.search}`, {
              state: { originURL: location.pathname },
            })
          }
          disabled={settings.isLast}>
          Última
        </Pagination.Last>
        <Pagination.Next
          className={`btn-pagination-next ${isMobile && 'shadow'}`}
          onClick={() =>
            navigate(`${RoutesDirections.STORE_ROUTE}/${currentPage + 1}${categorySlug ? `/categoria/${categorySlug}` : ''}${location.search}`, {
              state: { originURL: location.pathname },
            })
          }
          disabled={settings.isLast}>
          {'>'}
        </Pagination.Next>
      </Pagination>
    </Container>
  );
};

export default PaginationStore;
