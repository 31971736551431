import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';
import { PropsWithChildren, useCallback, useLayoutEffect } from 'react';

import _ from 'lodash';

import { NavLink, useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';

import { Col } from 'react-bootstrap';

import BackIcon from '@/ui/assets/BackIcon';
import Icon from '@/ui/assets/Icon';

interface PropsBreadcrumbs {
  /** Componente que define la lista que se mostrará en Breadcrumbs Desktop */
  list?: IBreadcrumbs[];
  /** Función especifica para el botón de ir atrás */
  backFunction?: (e) => void;
}

/**
 * Componente de Breadcrumbs para navegar en interfaces anteriores
 * @component
 */

const Breadcrumbs = ({ list, backFunction }: PropsWithChildren<PropsBreadcrumbs>): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  const BACK_TEXT: string = 'Atrás';

  /** Link de redirection */
  const handleClick = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  useLayoutEffect((): void => {
    window.scrollTo(0, 0);
  }, [handleClick]);

  return (
    <>
      {isMobile ? (
        <Col className='d-flex align-items-center' onClick={backFunction ?? handleClick}>
          <BackIcon classMain='btn-icon-light' />
          <span className='ps-3 text-tertiary-1 text-truncate'>{BACK_TEXT}</span>
        </Col>
      ) : (
        <Col className='d-flex align-items-center'>
          <BackIcon classMain='btn-icon-light me-4 ' onClick={backFunction ?? handleClick} />
          {list?.length === 0 && (
            <button className='px-1 p-regular-bold text-secondary-3 link-text-standard cursor-hand text-truncate' onClick={handleClick}>
              {BACK_TEXT}
            </button>
          )}
          {list?.map((option, index) => (
            <div key={`${option.label}-${index}`}>
              <NavLink to={option.url} state={{ originURL: location.pathname }}>
                <span
                  className={`px-1 text-truncate ${
                    index === list.length - 1 ? 'p-regular-bold text-secondary-3' : 'p-regular-medium text-tertiary-1'
                  }`}>
                  {option.label ?? ''}
                </span>
              </NavLink>
              {index !== list.length - 1 && (
                <span className='px-1 text-tertiary-1'>
                  <Icon name='ico-round-arrow-left' />
                </span>
              )}
            </div>
          ))}
        </Col>
      )}
    </>
  );
};

export default Breadcrumbs;
