import { PropsWithChildren } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

interface PropsTitle {
  classMain?: string;
}

/** Componente para mostrar el titulo
 * @component
 */
const Title = ({
  classMain = '',
  children,
}: PropsWithChildren<PropsTitle>): JSX.Element => {
  return (
    <Container fluid className='p-0'>
      <Row className='g-0'>
        <Col xs={12} className={classMain}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default Title;
