
import axios, { AxiosResponse } from 'axios';
import apiConfig from '@/data/services/api';

import { MockPaymentMethods } from '@/data/mocks/MockPaymentMethods';

import { IPaymentMethods } from '@/domain/interfaces/IProduct';
import { IWebPayCommitResponse } from '@/domain/interfaces/IPayment';



/** Representa la interacción con el Back */
export default class PaymentRepository {
  /** Instancia de la clase   */
  private static _instance: PaymentRepository;

  /** Patron Singleton
    * @returns {instance} _instance PaymentRepository
  */
  static get instance(): PaymentRepository {
    if (!this._instance) {
      this._instance = new PaymentRepository();
    }
    return this._instance;
  }

  /** Solicita los tipos de identificación
   * @param {string} token - token valido
   * @returns {object} data - tipos de identificación en el back
  */
  get getPaymentMethods(): IPaymentMethods[] {
    return MockPaymentMethods;
  }

  /** Obtiene respuesta de la confirmación de orden a Webpay
        * @param token Token que responde la transacción 
        * @returns @interface IWebPayResponse respuesta de webpay
    */
  async commitWebPayTransaction(token: string): Promise<AxiosResponse<IWebPayCommitResponse>> {

    const url: string = `${apiConfig.baseURL}/webpay/commit?token_ws=${token}`;

    return await axios.post(
      url,
      apiConfig,
    ).then((response) => {
      return response
    }
    ).catch((error) => {
      return error.response

    }
    );
  }

}