
import ShippingTypesRepository from '@/data/repositories/ShippingTypesRepository';
import { IShippingTypes } from '@/domain/interfaces/IProduct';

/** Representa el controlador que maneja los eventos de los casos de uso de los tipos de envio
  * @returns {object} Funciones del controlador
*/
const ShippingTypesController = () => {
  
    /** Solicita los Tipos de Envio del back
      * @returns {IShippingTypes} shippingTypesxes - Tipos de Envio
    */
    const getShippingTypes = (): IShippingTypes[] => {

        /** Instancia del Repository
        * @const {ShippingTypesRepository}
        */
        const shippingTypesRepository = ShippingTypesRepository.instance;
        const shippingTypes: IShippingTypes[] = shippingTypesRepository.getShippingTypes;
        
        return shippingTypes;
    }

    /**
     * retorna la funciones del controlador
     */
    return { getShippingTypes };
};

export default ShippingTypesController;
