import generateNumberId from '@/domain/utils/generateNumberId';
import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';
import { Container, Row, Col } from 'react-bootstrap';

/**
 * Componente Loading para la interfaz de detalle de producto
 * @component
 */
const ProductDetailsLoading = (): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  return (
    <>
      {isMobile ? (
        <Container fluid className='p-0'>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-3'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-3'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-4'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading mh-400-px'></Col>
          </Row>
          <Row className=' mt-4 '>
            <Col className='d-flex justify-content-center'>
              {[...Array(6)].map((_) => (
                <div key={`gradient-${generateNumberId()}`} className='gradient-loading p-2 mx-2 mb-3 rounded rounded-circle'></div>
              ))}
            </Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-3'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-3'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading mh-400-px'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading mh-265-px'></Col>
          </Row>
          <Row className='g-0 svg-secondary-3 my-2'>
            <Col xs={12} className='gradient-loading py-5'></Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container fluid className='p-0 bg-primary-4'>
            <Container className='py-3 px-1 '>
              <Row className='g-0'>
                <Col xs={12} className='justify-content-end px-3'>
                  <Row className='mx-3'>
                    <Col xs={12} className='shadow rounded svg-secondary-3 '>
                      <Row>
                        <Col md={12} className='gradient-loading py-4 rounded'></Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mx-2 mt-3'>
                    <Col xs={7} className={`h-100 p-0`}>
                      <Col xs={11} className='mx-auto bg-primary-4 gradient-loading  mh-500-px rounded '></Col>
                      <Row className=' mt-4 '>
                        <Col className='d-flex justify-content-center'>
                          {[...Array(5)].map((_) => (
                            <div key={`gradient-${generateNumberId()}`} className='gradient-loading p-4 mx-2 mb-3 rounded rounded-circle'></div>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={5} className={'p-0 '}>
                      <Row className=' mx-2'>
                        <Col xs={11} className='gradient-loading mh-600-px mx-auto mb-3 rounded'></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid className='p-0 my-4 bg-primary-5'>
            <Container className='gradient-loading mh-265-px rounded'></Container>
          </Container>
        </>
      )}
    </>
  );
};

export default ProductDetailsLoading;
