import { createSlice } from '@reduxjs/toolkit';

const initialState = {value:1}

/** Almacena el estado indexCheckOut de Redux con sus reducers
  * @constant {object}
*/
export const indexCheckOutSlice = createSlice({
  name: 'indexCheckOut',
  initialState,
  reducers: {
    updateIndexCheckOut: (state, action) => {
      state.value = action.payload;
    },
  }
})

export const { updateIndexCheckOut } = indexCheckOutSlice.actions;

export default indexCheckOutSlice.reducer;
