import { PropsWithChildren } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import currencyFormat from '@/domain/utils/currencyFormat';

interface PropSecondaryPrice {
  /**Identifica si es gratis */
  isInOffer: boolean;
  /** valor a formatear */
  value: number;
  /**Clase del componente */
  className?: string;
}
/**
 * Componente que muestra el precio secundario
 * @component
 */
const SecondaryPrice = ({ isInOffer, value, className }: PropsWithChildren<PropSecondaryPrice>): JSX.Element => {
  return (
    <span className={`${className} text-tertiary-5 text-decoration-line-through ${!isInOffer && 'invisible'}`}>
      {currencyFormat(value, ConfigNumberParameters.DECIMALS_ALLOWED)}
    </span>
  );
};

export default SecondaryPrice;
