import { createSlice } from '@reduxjs/toolkit';
import { ICart } from '@/domain/interfaces/ICart';

/** Objeto inicial del carrito */
const initialState: ICart | {} = {};

/** Almacena el estado userState de Redux con sus reducers
 * @constant {object}
 */
export const orderSlice = createSlice({
  name: 'orderState',
  initialState,
  reducers: {
    updateOrderRedux: (state, action) => {
      // Actualiza el estado mutando el objeto state con los nuevos cartItems
      Object.assign(state, action.payload);
    },
    removeOrderRedux: (state) => {
      // Limpia el estado haciendo que sea un objeto vacío
      Object.keys(state).forEach((key) => delete state[key]);
    },
  },
});

export const { updateOrderRedux, removeOrderRedux } = orderSlice.actions;

export default orderSlice.reducer;
