import { IPathCategoryType } from '@/domain/interfaces/ICategories';

import { Col, Row } from 'react-bootstrap';
import '@/ui/components/PillText/style.css';

import CloseIcon from '@/ui/assets/CloseIcon';
import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';

/** Propiedades del componente */
interface PropsCategoryPath {
  /** Objeto que representa la categoria referenciada */
  pathState: IPathCategoryType[];
  /** Funcion que recibe como parametros
   *  la accion @param 'add' o 'delete' para Agregar o Eliminar*/
  updatePathState?: (action: 'add' | 'delete', idCategory: string) => void;
  /** Modo confirmación que se muestra al seleccionar la ultima categoria en el paso 2 del Form Wizard*/
  confirmationMode?: boolean;
  /** Modo referencia que se muestra en el paso 3 del Form Wizard */
  referenced?: boolean;
}

/**CategoryPath es un componente que muestra la ruta de categorias seleccionadas en el paso 2 del Form Wizard y en el paso 3 del Form Wizard en modo referencia*/
const CategoryPath = ({ pathState, updatePathState, confirmationMode = false, referenced = false }: PropsCategoryPath): JSX.Element => {
  return (
    <>
      <Row className='g-0'>
        <Col lg={confirmationMode ? 11 : 12} className='d-flex'>
          {pathState.map((element, index) => {
            const firstValue: boolean = pathState.length === 1 && index === 0;
            const lastValue: boolean = pathState.length > 1 && index === pathState.length - 1;

            return (
              <Col lg={3} key={index} className='d-flex align-items-between'>
                <div
                  className={`d-flex align-items-center flex-wrap ${
                    (confirmationMode || referenced) && lastValue ? 'justify-content-start' : 'justify-content-around'
                  }  w-100 ${index === pathState.length - 1 && !referenced && !confirmationMode && 'tag'}`}>
                  <IconList
                    classTitle='px-1 p-large-medium text-secondary-3 '
                    title={element.nameCategory}
                    classIcon='svg-secondary-3'
                    iconName={index === 0 ? element.iconCategory : ''}
                  />
                  {!firstValue && !lastValue && <Icon name='ico-right-arrow' classMain='svg-secondary-3 svg-16' />}
                  {index === pathState.length - 1 && !referenced && !confirmationMode && (
                    <CloseIcon
                      classMain='btn-tag'
                      onClick={() => {
                        updatePathState && updatePathState('delete', element.idCategory);
                      }}
                    />
                  )}
                </div>
              </Col>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default CategoryPath;
