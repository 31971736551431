import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import PhoneInput, { CountryData } from 'react-phone-input-2';
import { clean, format } from 'rut.js';

import { Button, Col, Container, Form, InputGroup, Row, Image } from 'react-bootstrap';

import { RootState } from '@/ui/store/store';
import { useAppSelector } from '@/ui/store/helperRedux';

import IdTypesController from '@/controllers/IdTypesController';
import CartController from '@/controllers/CartController';

import validateClientDetailsUseCase, { IClientDetailsValidation } from '@/domain/useCases/validateClientDetailsUseCase';

import capitalize from '@/domain/utils/capitalize';

import { ICart } from '@/domain/interfaces/ICart';
import { IIdTypes } from '@/domain/interfaces/IIdTypes';
import { IIdentification, IPhone } from '@/domain/interfaces/IUser';

import ErrorImage from '@/ui/assets/ErrorImage';

import MainTitle from '@/ui/components/MainTitle';
import InputForm from '@/ui/components/forms/InputForm';
import PurchaseTotals from '@/ui/components/sales/PurchaseTotals';

/**propiedades de la interfaz de PaymentLayout*/
interface PropsPaymentLayout {
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<ICart>;
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: ICart) => void;
  /** Función para el boton del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
}

/**
 * Componente para formulario de pago y envío de productos
 * @component
 */
const PaymentLayout = ({ handleNextButton, formRef, updateFormRef }: PropsWithChildren<PropsPaymentLayout>): JSX.Element => {
  /**objeto vacio de identificación para el estado de la identificación*/
  const identificationEmpty: IIdentification = {
    type: '',
    value: '',
  };

  /**objeto vacio de telefono para el estado del telefono*/
  const phoneEmpty: IPhone = {
    number: '',
    country: '',
  };

  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /** Objeto inicial del carrito */
  const initialCartCheckoutForm: ICart = cartProducts;

  /** Controlador que obtiene los tipos de identificación */
  const { getIdTypes } = IdTypesController();

  /** Controlador de carrito */
  const { updateClientInCart } = CartController();

  /** Referencia al input nombre del cliente */
  const firstNameRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Referencia al input apellido del cliente */
  const lastNameRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Referencia al select del tipo de documento */
  const documentTypeRef: React.MutableRefObject<HTMLSelectElement | null> = useRef<HTMLSelectElement | null>(null);

  /** Referencia al input de numero de documento */
  const identificationIdRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Referencia al input de correo electrónico */
  const emailRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /**Estado de error del input de nombre */
  const [firstNameInputWithError, setFirstNameInputWithError] = useState<boolean>(false);

  /**Mensaje de error del input de nombre  */
  const [firstNameInputErrorMessage, setFirstNameInputErrorMessage] = useState<string>('');

  /**Estado de error del input de apellido */
  const [lastNameInputWithError, setLastNameInputWithError] = useState<boolean>(false);

  /**Mensaje de error del input de apellido  */
  const [lastNameInputErrorMessage, setLastNameInputErrorMessage] = useState<string>('');

  /**Estado de valores del input para indentificación */
  const [identificationId, setIdentificationId] = useState<IIdentification>(identificationEmpty);

  /**Estado de error del input de documento */
  const [documentInputWithError, setDocumentInputWithError] = useState<boolean>(false);

  /**Mensaje de error del input de documento  */
  const [documentInputErrorMessage, setDocumentInputErrorMessage] = useState<string>('');

  /**Estado de error del input de email */
  const [emailInputWithError, setEmailInputWithError] = useState<boolean>(false);

  /**Mensaje de error del input de email  */
  const [emailInputErrorMessage, setEmailInputErrorMessage] = useState<string>('');

  /** Estado inicial de arreglo de tipos */
  const [idTypes, setIdTypes] = useState<IIdTypes[]>([]);

  /**Estado de valores del input para telefono */
  const [phone, setPhone] = useState<IPhone>(phoneEmpty);
  /**Estado de error del input de telefono */
  const [phoneInputWithError, setPhoneInputWithError] = useState<boolean>(false);
  /**Mensaje de error del input de telefono  */
  const [phoneInputErrorMessage, setPhoneInputErrorMessage] = useState<string>('');

  /**Estado de la data para envío del formulario */
  const [validData, setValidData] = useState<boolean>(false);

  /**Estado de deshabilitar el botón de siguiente */
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  /** Trae los tipos de identificación del back */
  const fetchIdTypes = async (): Promise<void> => {
    /** Uso del controlador useIdTypesController con la funcion getIdTypes */
    const getIdTypesDropdown: IIdTypes[] = await getIdTypes();
    setIdTypes(getIdTypesDropdown);
  };

  /**Validacion de los campos del formulario de cliente y actualización de los valores en el DOM y en el carrito de compras*/
  const handleValidate = async (): Promise<void> => {
    const firstName = capitalize(firstNameRef.current?.value ?? '');
    const lastName = capitalize(lastNameRef.current?.value ?? '');
    const identificationIdType = documentTypeRef.current?.value ?? '';
    const identificationIdValue =
      identificationIdType === '1' ? format(identificationIdRef.current?.value ?? '', { dots: false }) : identificationIdRef.current?.value ?? '';
    const email = emailRef.current?.value ?? '';

    // Actualizar los valores de los campos de nombre y apellido en el DOM
    if (firstNameRef.current) firstNameRef.current.value = firstName;
    if (lastNameRef.current) lastNameRef.current.value = lastName;

    /** Establece el formulario a actualizar */
    const formCartCheckOut: ICart = formRef.current
      ? JSON.parse(JSON.stringify(formRef.current))
      : JSON.parse(JSON.stringify(initialCartCheckoutForm));
    formCartCheckOut.clientDetails.firstName = firstNameRef.current?.value ?? '';
    formCartCheckOut.clientDetails.lastName = lastNameRef.current?.value ?? '';
    formCartCheckOut.clientDetails.identificationId = {
      type: identificationIdType,
      value: identificationIdValue,
    };
    formCartCheckOut.clientDetails.email = email;
    formCartCheckOut.clientDetails.phone = phone;

    // Valida desde el controlador
    await validateClientDetailsUseCase(formCartCheckOut.clientDetails).then((errors: IClientDetailsValidation[]) => {
      const KEY_FIRST_NAME = 'FIRST_NAME';
      const KEY_LAST_NAME = 'LAST_NAME';
      const KEY_DOCUMENT = 'DOCUMENT_ID';
      const KEY_EMAIL = 'EMAIL';
      const KEY_PHONE = 'PHONE';
      // Validar errores en los campos del formulario de cliente y actualizar los mensajes de error
      const fisrtNameError = errors.find((element) => element.field === KEY_FIRST_NAME);
      //si hay error en el campo de nombre se muestra el mensaje de error y se pone el estado de error en true sino se pone en false y se limpia el mensaje de error
      if (fisrtNameError) {
        setFirstNameInputWithError(true);
        setFirstNameInputErrorMessage(fisrtNameError.message);
      } else {
        setFirstNameInputWithError(false);
        setFirstNameInputErrorMessage('');
      }
      //si hay error en el campo de apellido se muestra el mensaje de error y se pone el estado de error en true sino se pone en false y se limpia el mensaje de error
      const lastNameError = errors.find((element) => element.field === KEY_LAST_NAME);

      if (lastNameError) {
        setLastNameInputWithError(true);
        setLastNameInputErrorMessage(lastNameError.message);
      } else {
        setLastNameInputWithError(false);
        setLastNameInputErrorMessage('');
      }

      const documentError = errors.find((element) => element.field === KEY_DOCUMENT);
      //si hay error en el campo de documento se muestra el mensaje de error y se pone el estado de error en true sino se pone en false y se limpia el mensaje de error
      if (documentError) {
        setDocumentInputWithError(true);
        setDocumentInputErrorMessage(documentError.message);
      } else {
        setDocumentInputWithError(false);
        setDocumentInputErrorMessage('');
      }

      const emailError = errors.find((element) => element.field === KEY_EMAIL);
      //si hay error en el campo de email se muestra el mensaje de error y se pone el estado de error en true sino se pone en false y se limpia el mensaje de error
      if (emailError) {
        setEmailInputWithError(true);
        setEmailInputErrorMessage(emailError.message);
      } else {
        setEmailInputWithError(false);
        setEmailInputErrorMessage('');
      }

      const phoneError = errors.find((element) => element.field === KEY_PHONE);
      //si hay error en el campo de telefono se muestra el mensaje de error y se pone el estado de error en true sino se pone en false y se limpia el mensaje de error
      if (phoneError) {
        setPhoneInputWithError(true);
        setPhoneInputErrorMessage(phoneError.message);
      } else {
        setPhoneInputWithError(false);
        setPhoneInputErrorMessage('');
      }

      !errors.length ? setValidData(true) : setValidData(false);
      // Actualizar el formulario de carrito de compras con los valores validados
      if (validData) {
        updateFormRef(formCartCheckOut);
        updateClientInCart(formCartCheckOut.clientDetails);
      }
    });
  };

  //funcion para enviar la información del formulario al siguiente paso
  const handleSendInfo = () => {
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      handleValidate();
      handleNextButton(new MouseEvent('click'));
    }
  };

  const onkeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  /** UseEffect que trae la información del usuario y tipos de identificación al renderizar el componente */
  useEffect((): void => {
    /** Trae los tipos de identificación */
    fetchIdTypes();
  }, []);

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      const firstName = formRef.current?.clientDetails.firstName ?? '';
      const lastName = formRef.current?.clientDetails.lastName ?? '';
      const identificationIdType = formRef.current?.clientDetails.identificationId.type ?? '';
      const identificationIdValue =
        identificationIdType === '1'
          ? clean(formRef.current?.clientDetails.identificationId.value ?? '')
          : formRef.current?.clientDetails.identificationId.value ?? '';
      const email = formRef.current?.clientDetails.email ?? '';

      firstNameRef.current!.value = firstName;
      lastNameRef.current!.value = lastName;
      identificationIdRef.current!.value = formRef.current?.clientDetails.identificationId.value;
      setIdentificationId({
        type: identificationIdType,
        value: identificationIdValue,
      });
      emailRef.current!.value = email;
      if (formRef.current?.clientDetails.phone.number && formRef.current?.clientDetails.phone.number.length > 0) {
        setPhone({
          number: formRef.current?.clientDetails.phone.number,
          country: formRef.current?.clientDetails.phone.country,
        });
      }
    }
    handleValidate();

    window.scrollTo(0, 0);
  }, []);

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    handleValidate();
  }, [phone, identificationId, validData]);

  /**CONSTANTES DE TEXTO */
  const CLIENT_INFO_TITLE = 'Información de cliente ';
  const CLIENT_INFO_DESCRIPTION = '¡Estamos a punto de completar tu compra! Registra tus datos personales, verifica el monto y procede al pago.';
  const PAYMENT_METHODS_TITLE = 'Selecciona un método de pago ';

  const CLIENT_INFO_NAME_LABEL = 'Nombre';
  const CLIENT_INFO_LAST_NAME_LABEL = 'Apellido';
  const CLIENT_INFO_DOCUMENT_TYPE_LABEL = 'Tipo de Documento';
  const CLIENT_INFO_DOCUMENT_ID_LABEL = 'Número de Documento';
  const CLIENT_INFO_EMAIL_LABEL = 'Correo electrónico';
  const CLIENT_INFO_PHONE_NUMBER_LABEL = 'Teléfono Celular';

  const WEBPAY_LOGO: string = `${URL_IMAGES}/static/webpay-payment-button.png`;

  /**Renderizado del componente */
  return (
    <Container fluid className='px-0 pb-2'>
      <Row className='g-0 my-2 '>
        <Col xs={12} lg={8} className='bg-primary-4 rounded rounded-3 shadow px-3'>
          <Col xs={12} lg={10} className='text-start mx-auto pt-4 '>
            <Row className='g-0'>
              <MainTitle
                titleSize='h2'
                iconName='ico-user'
                iconClass='svg-primary-3 svg-32'
                boldTitle={CLIENT_INFO_TITLE}
                detailsText={CLIENT_INFO_DESCRIPTION}
              />
            </Row>
            <Row className='g-0'>
              <Form onKeyDown={onkeyDown}>
                <Row className='my-2 g-0'>
                  <Col lg={6}>
                    <InputForm
                      className='my-2 px-1'
                      label={CLIENT_INFO_NAME_LABEL}
                      inputRef={firstNameRef}
                      onChange={() => handleValidate()}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      withErrors={firstNameInputWithError}
                      messageError={firstNameInputErrorMessage}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputForm
                      className='my-2 px-1'
                      label={CLIENT_INFO_LAST_NAME_LABEL}
                      inputRef={lastNameRef}
                      onChange={() => handleValidate()}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      withErrors={lastNameInputWithError}
                      messageError={lastNameInputErrorMessage}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Group className='pt-2 px-1'>
                      <div className='d-flex justify-content-between'>
                        <Form.Label>
                          <span className={`text-nowrap p-regular-medium`}>{CLIENT_INFO_DOCUMENT_TYPE_LABEL}</span>
                        </Form.Label>
                      </div>
                      <Form.Select
                        aria-label='CLIENT_INFO_DOCUMENT_TYPE_LABEL'
                        ref={documentTypeRef}
                        onChange={(value) => {
                          if (value.target.value === '') {
                            setIdentificationId({
                              type: value.target.value,
                              value: '',
                            });
                            identificationIdRef.current && (identificationIdRef.current.value = '');
                          } else {
                            setIdentificationId({
                              type: value.target.value,
                              value: identificationIdRef.current?.value ?? '',
                            });
                          }
                        }}
                        value={identificationId.type ?? '0'}
                        className={`form-control ${documentInputWithError ? 'form-control-error' : ''}  position-relative`}>
                        <option value={''}>{CLIENT_INFO_DOCUMENT_TYPE_LABEL}</option>
                        {idTypes?.map((element) => {
                          return (
                            <option key={element.id} value={element.id}>
                              {element.title}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col lg={6}>
                    <InputForm
                      className='my-2 px-1 '
                      label={CLIENT_INFO_DOCUMENT_ID_LABEL}
                      inputRef={identificationIdRef}
                      disabled={documentTypeRef.current?.value === '' && identificationIdRef.current?.value === ''}
                      onChange={(value) => {
                        setIdentificationId({
                          type: documentTypeRef.current?.value ?? '',
                          value: value.target.value,
                        });
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      withErrors={documentInputWithError}
                      messageError={documentInputErrorMessage}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputForm
                      className='my-2 px-1'
                      label={CLIENT_INFO_EMAIL_LABEL}
                      inputRef={emailRef}
                      onChange={() => handleValidate()}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      withErrors={emailInputWithError}
                      messageError={emailInputErrorMessage}
                    />
                  </Col>
                  <Col lg={6}>
                    <InputGroup className='mb-3 text-center px-1'>
                      <div className='pt-2 w-100'>
                        <div className='d-flex justify-content-between'>
                          <Form.Label>
                            <span className={`text-nowrap p-regular-medium`}>{CLIENT_INFO_PHONE_NUMBER_LABEL}</span>
                          </Form.Label>
                          <div>
                            <span className={'text-complementary-2 text-nowrap'}>{phoneInputErrorMessage}</span>
                          </div>
                        </div>
                      </div>
                      <PhoneInput
                        country={'cl'}
                        onlyCountries={['cl']}
                        value={phone?.number ?? ''}
                        placeholder='Enter phone number'
                        onChange={(number: string, country: CountryData) =>
                          setPhone({
                            number,
                            country: country.countryCode.toUpperCase(),
                          })
                        }
                        countryCodeEditable={false}
                        masks={{ cl: '. .... ....' }}
                        autoFormat={true}
                        inputClass={` mh-40-px form-control ${phoneInputWithError ? 'form-control-error' : ''}  position-relative w-100`}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Col>
        </Col>
        {/* Columna de resumen */}
        <Col xs={12} lg={4} className='ps-md-3 mt-3  mt-md-0 h-100 '>
          <Col lg={12} className='bg-primary-4 rounded rounded-3  shadow rounded py-4 pt-2 pe-2 px-2 d-flex flex-column pb-0 '>
            <Col className='d-flex pb-5 px-0'>
              <PurchaseTotals data={cartProducts} />
            </Col>
            <Col className='pb-3 d-flex flex-column'>
              <Col>
                <MainTitle boldTitle={PAYMENT_METHODS_TITLE} />
              </Col>
              <Col className='pb-0 my-2'>
                <Button
                  className={`btn btn btn-webpay-standard border text-blue w-100 py-0 py-0  ${!validData || isButtonDisabled ? 'disabled' : ''}`}
                  type='button'
                  onClick={handleSendInfo}>
                  <Image src={WEBPAY_LOGO} alt='webpay' />
                </Button>
              </Col>
            </Col>
          </Col>
        </Col>
        {/* Fin columna de resumen */}
      </Row>
    </Container>
  );
};

export default PaymentLayout;
