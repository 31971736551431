import PaymentRepository from '@/data/repositories/PaymentRepository';
import { IWebPayCommitResponse } from '@/domain/interfaces/IPayment';
import { IPaymentMethods } from '@/domain/interfaces/IProduct';

/** Representa el controlador que maneja los eventos de los casos de uso de los tipos de Envío
 * @returns {object} Funciones del controlador
 */
const PaymentController = () => {
  /** Instancia del Repository
   * @const {PaymentRepository}
   */
  const paymentRepository = PaymentRepository.instance;
  const PaymentMethods: IPaymentMethods[] = paymentRepository.getPaymentMethods;

  /** Solicita los Tipos de Envío del back
   * @returns {IShippingTypes} shippingTypes - Tipos de Envío
   */
  const getPaymentMethods = (): IPaymentMethods[] => {
    return PaymentMethods;
  };

  /** Obtiene respuesta de la confirmación de orden a Webpay
   * @param token Token que responde la transacción
   * @returns @interface IWebPayResponse respuesta de webpay
   */
  const commitWebPayTransaction = async (token: string): Promise<IWebPayCommitResponse | null> => {
    try {
      const result = await paymentRepository.commitWebPayTransaction(token);
      if (result.status === 500) {
        return null;
      } else {
        return result.data;
      }
    } catch {
      return null;
    }
  };

  /**
   * retorna la funciones del controlador
   */
  return { getPaymentMethods, commitWebPayTransaction };
};

export default PaymentController;
