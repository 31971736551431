import { PropsWithChildren, useState } from 'react';
import { Magnify } from 'magnify-zone';
import generateNumberId from '@/domain/utils/generateNumberId';
import ErrorImage from '@/ui/assets/ErrorImage';
import { Image } from 'react-bootstrap';

interface PropsImageMagnify {
  imageUrl: string;
  alt?: string;
  zoomFactor?: number;
  zoomPosition?: 'over' | 'left' | 'right' | 'top' | 'bottom';
  zoomWidth?: number;
  zoomHeight?: number;
  marginSize?: string;
  mainImageWidth?: string;
}
/**
 * Detallar componente
 * @component
 */
const ImageMagnify = ({
  imageUrl,
  alt = `Imagen ${generateNumberId()}`,
  zoomFactor,
  zoomPosition,
  zoomWidth,
  zoomHeight,
  marginSize,
  mainImageWidth,
}: PropsWithChildren<PropsImageMagnify>): JSX.Element => {
  const [image, setImage] = useState<string>(ErrorImage);
  return (
    <>
      <Magnify
        imageUrl={image}
        alt={alt}
        zoomFactor={zoomFactor}
        zoomPosition={zoomPosition}
        zoomHeight={zoomHeight}
        zoomWidth={zoomWidth}
        marginSize={marginSize}
        mainImageWidth={mainImageWidth}
      />
      <Image className="d-none" src={imageUrl} onLoad={() => setImage(imageUrl)} onError={() => setImage(ErrorImage)} />
    </>
  );
};

export default ImageMagnify;
