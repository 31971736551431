import { PropsWithChildren, ReactNode } from 'react';

import IconsLibrary from '@/ui/assets/IconsLibrary';
import BaseIcon from '@/ui/assets/BaseIcon';

interface PropsIcon {
  /**  Nombre del icono para renderizar */
  name: string;
  /** Evento al hacer clic sobre el icono */
  onClick?: (params: any) => ReactNode | void;
  /** Clase principal del componente */
  classMain?: string;
}

/**
 * Renderización de Icono
 * @component
 */
const Icon = ({ name = '', onClick, classMain = '' }: PropsWithChildren<PropsIcon>): JSX.Element => {
  /** Función que busca icono por ID
   * Retorna el icono con el @param id registrado en la libreria
   * Si no coincide la busqueda retornará el icono Base
   */
  const findIcon = (id: string): JSX.Element | undefined => {
    const foundIcon = IconsLibrary.find((icon) => icon.id === id);
    if (!foundIcon) {
      return <BaseIcon></BaseIcon>;
    } else {
      return foundIcon.svg;
    }
  };

  return onClick ? (
    <button onClick={onClick} className={`${classMain}  link-text-standard`}>
      {findIcon(name)}
    </button>
  ) : (
    <span className={`${classMain}`}>{findIcon(name)}</span>
  );
};

export default Icon;
