import { IAttributes } from "@/domain/interfaces/IAttributes";

/**
 * Busca el atributo principal si el atributo main esta seteado en true.
 * @param {IAttributes[]} arr - La estructura del arreglo de atributos.
 * @returns {IAttributes | null} data El atributo encontrado, o un arreglo vacio si no se encontró.
 */
const findMainAttribute = (arr:IAttributes[]):IAttributes | null => {
  const data = arr.find((attribute:IAttributes)=>{
    return attribute.main && attribute.position === 1;
  });
  return data ?? null;
};

export { findMainAttribute };
