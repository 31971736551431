import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavLink, useLocation, useNavigate, useParams, useSearchParams, type Location, NavigateFunction } from 'react-router-dom';

import _ from 'lodash';

import { Button, Col, Container, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import { setCurrentRanges, updateCurrentRanges } from '@/ui/store/slices/priceRangeSlice';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { IMenu } from '@/domain/interfaces/IMenu';
import { ICurrentPriceRange, IInitialPriceRange } from '@/domain/interfaces/IPricesRange';
import { IAttributes } from '@/domain/interfaces/IAttributes';

import findCategory from '@/domain/utils/findCategory';
import generateNumberId from '@/domain/utils/generateNumberId';

import CloseIcon from '@/ui/assets/CloseIcon';

import PriceSlider from '@/ui/components/store/PriceSlider';
import DropDownList from '@/ui/components/DropDownList';
import IconList from '@/ui/components/IconList';
import IconCheck from '@/ui/components/forms/IconCheck';
import InputForm from '@/ui/components/forms/InputForm';
import decimalFormat from '@/domain/utils/decimalFormat';

/** Propiedades para el componente ModalOrderInfo. */
interface PropsFilterStoreForm {
  /** Total de items encontrados */
  totalItems?: string;
  /** Evento que oculta y muestra el modal. */
  handleShowFilterModal?: (e: MouseEvent) => void;
  /** Categorías asociadas en caso de no tener una categoría seleccionada en las búsquedas */
  associatedCategories?: IMenu[];
  /** Lista de categorías de los productos encontrados */
  productCategoriesFound?: string[];
}

interface CheckboxState {
  [key: string]: boolean;
}

/**
 * Modal que muestra toda la información a modificar del usuario.
 * @component
 */
const FilterStoreForm = ({
  handleShowFilterModal,
  totalItems = '',
  associatedCategories = [],
  productCategoriesFound = [],
}: PropsWithChildren<PropsFilterStoreForm>): JSX.Element => {
  /** Hook de actualización de states Redux */
  const dispatch = useAppDispatch();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook para trabajar con alias de url establecidas en el router  */
  const { page, categorySlug } = useParams();

  /**Trae los parámetros de búsqueda */
  const [searchParams] = useSearchParams();

  /** Hook para trabajar con la URL y query parameters */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /**  Objeto Redux que almacena la data del menu */
  const navCategory: IMenu = useAppSelector((state: RootState) => state.categoriesMenuState);

  /** Objeto Redux que almacena la data de los atributos  */
  const navAttributes: IAttributes[] = useAppSelector((state: RootState) => state.attributesState);

  /** Valores asociados al state de redux para rangos de precios */
  const priceRangeState = useAppSelector((state: RootState) => state.priceRangeState);

  /** Rango inicial */
  const priceInitialRange: IInitialPriceRange = priceRangeState.initialRange;

  /** Rango actual */
  const priceCurrentRange: ICurrentPriceRange = priceRangeState.currentRange;

  /** Constante de texto */
  const COLOR_TITLE: string = 'Color';
  const FILTER_TITLE: string = 'Filtrar';
  const INPUT_SEARCH_LABEL: string = 'Palabra Clave';
  const INPUT_SEARCH_PLACEHOLDER: string = 'Filtrar';
  const CATEGORY_TEXT: string = 'Categorías';
  const SUBCATEGORY_TEXT: string = 'Subcategorías';
  const REFRESH_BUTTON_TEXT: string = 'RESTABLECER';
  const APPLY_BUTTON_TEXT: string = 'APLICAR';

  /** Constante  que define si es la tienda sin categorías para mostrar el texto Categorías o Subcategorías */
  const isMainStore: boolean = !categorySlug;

  /** Objeto para la inicialización del state currentCategory */
  const initialStateCurrentCategory: IMenu = {
    icon: '',
    id: '',
    title: '',
    slug: '',
    url: '',
    quantityOfProducts: 0,
  };

  /** Estado inicial de la categoría actual */
  const [currentCategory, setCurrentCategory] = useState<IMenu>(initialStateCurrentCategory);

  /** Estado que renderizara las subcategorías */
  const [arraySubCategories, setArraySubCategories] = useState<IMenu[]>(associatedCategories);

  /** Referencia al input de búsqueda de texto
   * @constant {HTMLInputElement}
   */
  const filterKeyWordRef = useRef<HTMLInputElement>(null);

  /** Referencia al input de búsqueda de texto
   * @constant {HTMLInputElement}
   */
  const sliderRef = useRef<any>(null);

  /** Establecemos el state sobre el que tenemos referencias a los checkbox */
  const [checkedIconChecks, setCheckedIconChecks] = useState<{ [key: string]: boolean }>({});

  /** Estado inicial del input */
  const [inputValue, setInputValue] = useState<string>('');

  /** Establece el cambio en el state correspondiente al hacer click en el checkbox */
  const handleIconCheckChange = (key: string, isChecked: boolean): void => {
    setCheckedIconChecks((prevState: CheckboxState) => ({
      ...prevState,
      [key]: isChecked,
    }));
  };

  /** Función que resetea todos los IconChecks */
  const resetAllIconChecks = (navigation: boolean = true): void => {
    const updatedCheckboxes: CheckboxState = {};
    /**Recorre los checkbox del state checkedIconChecks y los apaga */
    Object.keys(checkedIconChecks).forEach((key) => {
      updatedCheckboxes[key] = false;
    });
    /** Actualiza el state */
    setCheckedIconChecks(updatedCheckboxes);

    if (navigation) {
      /** Navega a la tienda  */
      navigate(location.pathname, { state: { originURL: location.pathname } });
    }
  };

  /** Selecciona la categoría actual,
   *  Resetea los iconos, el input, actualiza el precio de rangos.
   *  Establece valores a los checkbox, input y range si se solicita por la URL
   */
  const setCategory = (): void => {
    /** Se resetean los checkbox, se envía false para evitar que se ejecute el filtro */
    resetAllIconChecks(false);
    /** Se resetea el input, se envía false para evitar que se ejecute el filtro */
    resetInput(false);

    /** Busca la nueva categoría en el objeto de categorías y la asignamos al state currentCategory */
    if (categorySlug) {
      const category: IMenu | null = findCategory(navCategory, categorySlug ?? '');
      if (category !== null) {
        setCurrentCategory(category);

        /** Muestra en la lista de subcategorías solo de los productos filtrados  */
        const categoriesOfProducts = category.children?.filter((child: IMenu) => productCategoriesFound.includes(child.slug));
        if (categoriesOfProducts && categoriesOfProducts.length > 0) {
          setArraySubCategories(categoriesOfProducts);
        } else {
          setArraySubCategories(category.children ?? []);
        }
      } else {
        setCurrentCategory(initialStateCurrentCategory);
        setArraySubCategories(associatedCategories);
      }
    } else {
      /** Se inicializa el state de la categoría actual */
      setCurrentCategory(initialStateCurrentCategory);
      /** Se asignan las categorías asociadas ya que no hay categoría seleccionada. */
      setArraySubCategories(associatedCategories);
    }

    /** Analizar los query parameters que llegan al copiar y pegar la url */
    const keysIterator = searchParams.keys();
    const isEmpty = keysIterator.next().done;

    /** Si vienen query parameters se modifican los checks, dropdowns, input y range */
    if (!isEmpty) {
      setFilterValues(searchParams);
    } else {
      /** Se resetea el rango de precios */
      dispatch(setCurrentRanges());
    }
  };

  /** Establece los valores de los filtros con parámetros al pegar la URL*/
  const setFilterValues = (searchParams: URLSearchParams): void => {
    let pricesUrl: string[];
    let minPriceUrl: number = 0;
    let maxPriceUrl: number = 0;

    for (const [key, value] of searchParams.entries()) {
      switch (key) {
        case 'find':
          /**Establece valor del input de búsqueda*/
          setInputValue(value);
          break;
        case 'price':
          /**Establece valores para el range de precios*/
          pricesUrl = value.split('_');
          minPriceUrl = Number(pricesUrl[0] ?? '0');
          maxPriceUrl = Number(pricesUrl[1] ?? '0');
          break;
        default:
          /** Prende los checkboxes que vienen en la url */
          handleIconCheckChange(key, true);
          break;
      }
    }
    /** Actualiza range de precios */
    if (minPriceUrl > 0 && maxPriceUrl > 0) {
      dispatch(
        updateCurrentRanges({
          minValue: minPriceUrl,
          maxValue: maxPriceUrl,
        }),
      );
    }
  };

  /** Función lee el valor del input al escribir dentro del mismo */
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  /** Función que envía a la url cuando se presiona enter */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleApplyFilters();
    }
  };

  /** Función Resetea Input de búsqueda */
  const resetInput = (filter: boolean = true): void => {
    if (filterKeyWordRef.current) {
      filterKeyWordRef.current.value = '';
    }
    setInputValue('');
    if (filter && filterKeyWordRef.current && filterKeyWordRef.current.value.length > 0) {
      handleApplyFilters();
    }
  };

  /** Función para resetear formulario */
  const resetForm = (): void => {
    /** Resetea Input de búsqueda */
    resetInput(false);
    /** Resetea todos los IconChecks y aplica filtros */
    resetAllIconChecks();
    /** Resetea el slide de precio, si se ha modificado */
    dispatch(setCurrentRanges());
  };

  /** Retorna los checkboxes en estado checked */
  const getCheckedCheckboxes = (): string[] => {
    const checkedCheckboxes: string[] = Object.keys(checkedIconChecks).filter((key) => checkedIconChecks[key]);

    return checkedCheckboxes;
  };

  /** Genera el objeto de búsqueda y navega en la tienda con los query searchParams */
  const handleApplyFilters = (): void => {
    let queryParams: string = '?';
    if (
      priceCurrentRange.minCurrentValue + priceCurrentRange.maxCurrentValue !==
      priceInitialRange.minInitialValue + priceInitialRange.maxInitialValue
    ) {
      queryParams += `price=${sliderRef.current?.value['min']}_${sliderRef.current?.value['max']}&`;
    }

    const checkedCheckboxes = getCheckedCheckboxes();

    if (checkedCheckboxes.length > 0) {
      /** Si encuentra checkboxes en estado checked genera los query parameters */
      checkedCheckboxes.forEach((check) => {
        queryParams += `${check}&`;
      });
    }
    const inputSearchValue: string = filterKeyWordRef.current?.value ?? '';
    if (inputSearchValue !== '') {
      if (searchParams.get('find') !== inputSearchValue) {
        queryParams = queryParams.replace(/price=[^&]*&?/, '');
      }
      queryParams += `find=${inputSearchValue}&`;
    }

    /** Elimina el ampersand (&) final */
    queryParams = queryParams.slice(0, -1);

    /** Navega a la tienda para aplicar los filtros */
    navigate(`${RoutesDirections.STORE_ROUTE}${page ? `/${page}` : ''}${categorySlug ? `/categoría/${categorySlug}` : ''}${queryParams}`, {
      replace: RoutesDirections.STORE_ROUTE + queryParams === RoutesDirections.STORE_ROUTE && true,
    });
  };

  /** Función para ocultar el formulario
   * Solo  se activa en entorno mobile y si ejecuta el ocultar filtro
   */
  const handleApplyFilterMobile = (e: MouseEvent): void => {
    handleApplyFilters();
    handleShowFilterModal && handleShowFilterModal(e);
  };

  /** UseEffect que consulta si la navegación es por categoría
   *  Establece el componente según la navegación
   */
  useEffect((): void => {
    /** Si se navega a otra categoría por el menú o copiando y pegando la URL */
    if (currentCategory.slug !== categorySlug || associatedCategories.length > 0) {
      setCategory();
    }
  }, [location.search, categorySlug, associatedCategories]);

  /** Componente que renderiza la sección de categorías y sub categorías  */
  const CategorySection = (): JSX.Element => {
    return (
      <Col xs={12} className='p-0'>
        {((Object.keys(currentCategory).length > 0 && currentCategory?.children) || arraySubCategories.length > 0) && (
          <DropDownList id={'id'} title={isMainStore ? CATEGORY_TEXT : SUBCATEGORY_TEXT} classTitle='p-regular-bold text-primary-3'>
            {arraySubCategories.map((list) => (
              <div key={list.id}>
                <NavLink to={`${list.url + location.search}`} state={{ originURL: location.pathname }} replace={!isMobile}>
                  <IconList
                    classMain='btn-list-link-icon-light-small px-0'
                    classTitle='p-regular'
                    iconName={isMainStore ? list.icon : ''}
                    title={list.title}
                    pillText={list.quantityOfProducts}
                    {...(!isMobile ? { onClick: resetForm } : {})}
                  />
                </NavLink>
              </div>
            ))}
          </DropDownList>
        )}
      </Col>
    );
  };

  return (
    <Container fluid>
      <Row>
        {isMobile ? (
          <Col xs={12} className='d-flex pt-4 pb-2 justify-content-between'>
            <IconList iconName='ico-filter' classTitle='p-large-bold' title={FILTER_TITLE} />
            <CloseIcon onClick={handleShowFilterModal} classMain='ps-2 btn-icon-light ' />
          </Col>
        ) : (
          <>
            {inputValue !== '' && isMainStore ? (
              <span className='h5 fw-bold text-primary-3 py-3'>{inputValue}</span>
            ) : (
              <>
                {Object.keys(currentCategory).length > 0 && currentCategory.id !== '' && (
                  <Col xs={12} className='pt-3 svg-primary-3'>
                    <IconList iconName={currentCategory.icon} classTitle='text-primary-3' title={currentCategory.title} />
                  </Col>
                )}
              </>
            )}

            <Col xs={12} className='pb-2 mt-4'>
              <span className='text-primary-2'>{`Resultados ${totalItems}`}</span>
            </Col>

            <CategorySection />

            <Col xs={12} className='py-3 svg-secondary3'>
              <IconList iconName='ico-filter' classTitle='h5 fw-bold text-secondary-3' title={FILTER_TITLE} />
            </Col>
          </>
        )}

        {isMobile && <CategorySection />}

        <Col xs={12} className='mt-3'>
          <InputForm
            className='mb-3'
            label={INPUT_SEARCH_LABEL}
            classLabel='p-regular-bold text-primary-3'
            id='input-store-keyword'
            type='text'
            placeholder={INPUT_SEARCH_PLACEHOLDER}
            value={inputValue}
            inputRef={filterKeyWordRef}
            lastIconClass={`${
              filterKeyWordRef.current && filterKeyWordRef.current.value === '' && inputValue === '' ? 'd-none' : 'last-input-icon cursor-hand'
            }`}
            lastIconClicked={resetInput}
            lastIconName={'ico-close'}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
          />
        </Col>

        <Col xs={12} className='px-1'>
          <PriceSlider sliderRef={sliderRef} />
        </Col>

        <Col xs={12}>
          {navAttributes?.map((attribute) => {
            const orderedAttributes = _.orderBy(attribute.values, ['position', (item) => Number(item.value)], ['asc', 'asc']);
            return (
              <div key={attribute.id}>
                <DropDownList id={attribute.id} title={attribute.name} classTitle='p-regular-bold text-primary-3'>
                  {orderedAttributes?.map((value) => {
                    const generateTitleValue = (): JSX.Element => {
                      let title = '';
                      if (attribute.isQualitative) {
                        if (attribute.name === COLOR_TITLE && value.value) {
                          title = `${value.name}`;
                        } else {
                          title = `${value.value} ${value.name}`;
                        }
                      } else {
                        title = `${decimalFormat(value.value ?? '0', value.decimalPrecision ?? 0)} ${value.prefix}`;
                      }
                      return <>{title}</>;
                    };
                    const classMainValue: string = `btn-list-check-icon-light-small ${
                      attribute.name === COLOR_TITLE && value.value ? '' : 'svg-tertiary-1 px-0'
                    }`;
                    const colorValue: string = attribute.name === COLOR_TITLE && value.value ? value.value : '';
                    const generateIndexValue = (): string => {
                      let index = '';
                      if (attribute.isQualitative) {
                        index = `${attribute.name}_${value.slug}_ql`;
                      } else {
                        index = `${value.slug}_${decimalFormat(value.value ?? '0', value.decimalPrecision ?? 0)}_qt`;
                      }
                      return index;
                    };

                    return (
                      <div key={value.id + generateNumberId()}>
                        <IconCheck
                          classMain={classMainValue}
                          title={generateTitleValue()}
                          color={colorValue}
                          isChecked={checkedIconChecks[generateIndexValue()]}
                          onCheckChange={(isChecked) => handleIconCheckChange(generateIndexValue(), isChecked)}
                        />
                      </div>
                    );
                  })}
                </DropDownList>
              </div>
            );
          })}
        </Col>

        <Col xs={12}>
          <Button
            className='btn-primary-icon-small w-100 my-2 '
            onClick={() => (isMobile ? handleApplyFilterMobile(new MouseEvent('click')) : handleApplyFilters())}>
            {APPLY_BUTTON_TEXT}
          </Button>
          <Button className='btn-secondary-icon-small w-100 my-2' onClick={resetForm}>
            {REFRESH_BUTTON_TEXT}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FilterStoreForm;
