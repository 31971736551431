import { createSlice } from '@reduxjs/toolkit';


/** Almacena la representacion del usuario en */
const initialState = {
  initialRange: {
    minInitialValue:0, 
    maxInitialValue:0
  },
  currentRange: {
    minCurrentValue:0, 
    maxCurrentValue:0
  }
};

/** Almacena el estado priceRangeState de Redux con sus reducers
  * @constant {object}
*/
export const priceRangeSlice = createSlice({
  name: 'priceRangeState',
  initialState,
  reducers: {
    updateInitialRanges: (state, action) => {
      state.initialRange.minInitialValue = action.payload.minValue;
      state.initialRange.maxInitialValue = action.payload.maxValue;
    },
    updateCurrentRanges: (state, action) => {
      state.currentRange.minCurrentValue = action.payload.minValue;
      state.currentRange.maxCurrentValue = action.payload.maxValue;
    },
    setCurrentRanges: (state) => {
      state.currentRange.minCurrentValue = state.initialRange.minInitialValue;
      state.currentRange.maxCurrentValue = state.initialRange.maxInitialValue;
    },
  },
  
})

export const { updateInitialRanges, updateCurrentRanges, setCurrentRanges } = priceRangeSlice.actions;

export default priceRangeSlice.reducer;
