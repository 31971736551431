import { format, isValid, parse } from 'date-fns';

const dateFormat = () => {
    /** Formato de fecha para el back */
    const formatDateBack: string = 'dd/MM/yyyy';

    /** Convierte una fecha tipo Date a un string con el formato solicitado por el back */
    const formatDateToString = (date: Date): string => {
        return format(new Date(date), formatDateBack);
    }

    /** Convierte una fecha tipo string a un Date  
     * @param {string} dateString - una fecha con formato dd-mm-yyyy o dd/mm/yyyy
    */
    const formatStringToDate = (dateString: string): Date | false => {

        /** Separa la fecha dada  */
        let parts = dateString.split('/');

        if (parts.length < 1) {
            parts = dateString.split('-');
        }

        if (parts.length < 1) {
            return false;
        }
        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1;
        const year = parseInt(parts[2]);

        const validDate = parse(`${day}/${month}/${year}`, formatDateBack, new Date());

        if (!isValid(validDate)) {
            return false;
        }

        const dateObject = new Date(year, month, day);

        return dateObject;
    }

    //formateo de hora
    const formatTimeToString = (date: Date): string => {
        return format(new Date(date), 'HH:mm');
    }


    return { formatDateToString, formatStringToDate, formatTimeToString }
}

export default dateFormat;
