import React, { PropsWithChildren, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import generateNumberId from '@/domain/utils/generateNumberId';

import { Col, Image, Modal, Row } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';
import ErrorImage from '@/ui/assets/ErrorImage';
import CloseIcon from '@/ui/assets/CloseIcon';

import IconList from '@/ui/components/IconList';
import ImageMagnify from '@/ui/components/product/ImageMagnify';

import './style.css';

/** Propiedades del componente */
interface PropsProductImageSlider {
  /**Arreglo de string de imágenes */
  data: string[];
}
/**
 * Componente ProductImageSlider que renderiza un carrusel de imágenes con funcionalidades de paginación y navegación.
 *
 * @component
 * @param {PropsWithChildren<PropsImageSlider>} props - Propiedades del componente, incluyendo `data` que es un array de URLs de imágenes.
 * @returns {JSX.Element} - El componente JSX que renderiza el carrusel de imágenes.
 */
const ProductImageSlider = React.memo(({ data }: PropsWithChildren<PropsProductImageSlider>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  //Booleano que define si el componente es una galería o no dependiendo de la cantidad de imágenes
  const isAGallery: boolean = data.length > 1;
  // Estado local para gestionar el índice actual de la imagen visible
  const [index, setIndex] = useState<number>(1);
  // Estado local para gestionar si el modal del zoom es visible o no
  const [zoomModal, setZoomModal] = useState<boolean>(false);

  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  /** Logo */
  const LOGO: string = `${URL_IMAGES}/client/logo-header-modal.png`;
  /**
   * Componente interno Carrousel que configura y renderiza el carrusel de imágenes usando react-slick.
   * @returns {JSX.Element} - Elemento JSX del carrusel.
   */
  const Carrousel = (): JSX.Element => {
    // Configuraciones del componente Slider de react-slick
    const settings = isMobile
      ? {
          dots: true,
          lazyLoad: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: (
            <Icon name={'ico-right-arrow'} classMain={`btn-slider shadow p-2 rounded-circle bg-primary-4 position-absolute top-50 end-0 z-1 me-1`} />
          ),
          prevArrow: (
            <Icon name={'ico-left-arrow'} classMain={`btn-slider shadow p-2 rounded-circle bg-primary-4 position-absolute top-50 start-0 z-1 ms-1`} />
          ),
          afterChange: (current) => {
            !zoomModal && setIndex(current + 1);
          },
        }
      : {
          customPaging: (i: number) => {
            return (
              <Image
                fluid
                className='rounded-circle image-full logo-header-modal'
                src={data[i]}
                alt={`Imagen ${i}`}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = ErrorImage;
                }}
              />
            );
          },
          dots: true,
          dotsClass: 'slick-dots slick-thumb',
          lazyLoad: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: (
            <Icon name={'ico-right-arrow'} classMain={`btn-slider shadow p-3 rounded-circle bg-primary-4 position-absolute top-50 end-0 z-1 me-1`} />
          ),
          prevArrow: (
            <Icon name={'ico-left-arrow'} classMain={`btn-slider shadow p-3 rounded-circle bg-primary-4 position-absolute top-50 start-0 z-1 ms-1`} />
          ),
          afterChange: (current) => {
            !zoomModal && setIndex(current + 1);
          },
        };

    return isAGallery ? (
      <Slider {...settings}>
        {data.map((image, index) => (
          <Col key={`image-${generateNumberId()}`} onClick={() => setZoomModal(true)} className='image-detail-container '>
            {isMobile ? (
              <Image
                src={image}
                alt={`Imagen ${index}`}
                className='img-fluid image-full'
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = ErrorImage;
                }}
              />
            ) : (
              <div className={`${zoomModal ? 'image-zoom-container' : 'image-detail-container'} d-flex align-items-center`}>
                <ImageMagnify imageUrl={image} alt={`Imagen ${index}`} zoomFactor={2} zoomPosition='over' />
              </div>
            )}
          </Col>
        ))}
      </Slider>
    ) : (
      <Col
        className={`${zoomModal ? 'image-zoom-container' : 'image-detail-container'}`}
        key={`image-${generateNumberId()}`}
        onClick={() => setZoomModal(true)}>
        {isMobile ? (
          <Image
            src={data[0]}
            alt={`Imagen ${index}`}
            className='image-full'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        ) : (
          <ImageMagnify imageUrl={data[0]} alt={`Imagen ${index}`} zoomFactor={2} />
        )}
      </Col>
    );
  };

  // Renderización condicional basada en la cantidad de imágenes
  return (
    <Row className='g-0 bg-primary-4 product-image-slider'>
      <Col xs={12} className='slider-container position-relative image-detail-container position-relative'>
        {Carrousel()}
        <Col xs={12} className={`position-absolute  px-2 ${isMobile ? 'top-90' : 'top-92'} d-flex justify-content-between`}>
          <IconList classMain={'p-1 bg-secondary-4 rounded-pill'} iconName='ico-media' classIcon={'ps-1'} title={`${index}/${data.length}`} />
          <Icon name='ico-enlarge' classMain='p-1 me-2 bg-black-transparent rounded-circle svg-primary-4' onClick={() => setZoomModal(true)} />
        </Col>
      </Col>
      {zoomModal && (
        <Modal
          size='xl'
          onHide={() => setZoomModal(false)}
          show={zoomModal}
          fullscreen
          contentClassName='product-image-slider bg-gray-transparent p-0'>
          <Modal.Body className='py-0 col-fixed p-0'>
            <Row className='min-vh-100 g-0'>
              <Col xs={12} className='d-flex align-items-center justify-content-between ps-5 mb-auto align-self-top bg-primary-3 mh-60-px'>
                <Image id='logo' src={LOGO} alt='logo' className='logo-header-modal' />
                <CloseIcon onClick={() => setZoomModal(false)} classMain='svg-primary-4 pe-3' />
              </Col>
              <Col lg={5} className={` mx-md-auto image-zoom-container  `}>
                {Carrousel()}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Row>
  );
});

export default ProductImageSlider;
