import { PropsWithChildren, useState } from 'react';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router';

import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

interface PropsReservationsModals {
  error?: boolean;
}
/**
 * Detallar componente
 * @component
 */
const ReservationsModals = ({ error = false }: PropsWithChildren<PropsReservationsModals>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  const [modalValidateStock, setModalValidateStock] = useState<boolean>(true);
  const [modalNotAvailable, setModalNotAvailable] = useState<boolean>(true);

  /** Funcion de refrescar pagina */
  const handleRefresh = () => {
    setModalNotAvailable(false);
    navigate(location.pathname);
    window.location.reload();
  };

  const BUY_BUTTON_TEXT: string = 'ACTUALIZAR COMPRA';
  const MODAL_VALIDATE_STOCK_PNG_URL: string = `${URL_IMAGES}/client/validate-stock.png`;
  const MODAL_VALIDATE_STOCK_DESCRIPTION: string = 'Estamos verificando la disponibilidad de tus productos en el inventario...';
  const MODAL_NOT_AVAILABLE_STOCK_URL: string = `${URL_IMAGES}/client/not-available-stock.png`;
  const MODAL_NOT_AVAILABLE_STOCK_DESCRIPTION: string = 'Algunos productos agregados ya no se encuentran disponibles, actualiza tu compra.';

  return (
    <>
      {error ? (
        // Modal de productos no disponibles
        <Modal
          show={modalNotAvailable}
          dialogClassName='modal-dialog-centered'
          contentClassName=' rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
          size='lg'>
          <Container fluid className='px-0 g-0 '>
            <Row className='d-flex justify-content-center g-0  '>
              <Col lg={12} className='mt-5 pt-3'>
                <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0  '>
                  <Image
                    src={MODAL_NOT_AVAILABLE_STOCK_URL}
                    width={175}
                    alt='gif'
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = ErrorImage;
                    }}
                  />
                </Col>
              </Col>

              <Col lg={8} className='d-flex justify-content-center text-primary-3 g-0 px-3'>
                <span className='text-primary-3 p-large-bold text-center'>{MODAL_NOT_AVAILABLE_STOCK_DESCRIPTION}</span>
              </Col>

              <Col lg={5} className='d-flex justify-content-center mb-5 g-0 my-4'>
                <Button className={`my-2  svg-primary-4 btn-primary-icon-standard `} type='button' onClick={handleRefresh}>
                  <span className={`pe-2 px-2 p-regular-medium`}>{BUY_BUTTON_TEXT}</span>
                </Button>
              </Col>
            </Row>
          </Container>
          <Col xs={12} className='align-self-end pt-2 '>
            <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
          </Col>
        </Modal>
      ) : (
        // Modal de validación de stock
        <Modal
          show={modalValidateStock}
          onHide={() => setModalValidateStock(false)}
          dialogClassName='modal-dialog-centered'
          contentClassName=' rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
          size='lg'>
          <Container fluid className='px-0 g-0 '>
            <Row className='d-flex justify-content-center g-0  '>
              <Col lg={12} className='mt-5 pt-3 px-2'>
                <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0 px-2 '>
                  <Image src={MODAL_VALIDATE_STOCK_PNG_URL} width={250} alt='gif' />
                </Col>
                <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0  py-3 '>
                  <span className='text-secondary-1 p-large-bold mt-2 text-center'>{MODAL_VALIDATE_STOCK_DESCRIPTION}</span>
                </Col>
              </Col>
            </Row>
          </Container>
          <Col xs={12} className='align-self-end pt-2 '>
            <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
          </Col>
        </Modal>
      )}
    </>
  );
};

export default ReservationsModals;
