import generateNumberId from '@/domain/utils/generateNumberId';
import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';
import { Container, Row, Col } from 'react-bootstrap';

/**
 * Componente Loading para la interfaz de la tienda
 * @component
 */
const StoreLoading = (): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  return (
    <>
      {isMobile ? (
        <Container>
          <Row className='gradient-loading py-5'></Row>
          <Row className=' mt-2'>
            {[...Array(6)].map((_) => (
              <Col xs={6} key={`gradient-${generateNumberId()}`}>
                <Col xs={11} className='gradient-loading mh-400-px mx-auto mb-3 rounded'></Col>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <Container className='py-3 px-1'>
          <Row className='g-0'>
            <Col xs={12} className='justify-content-end px-3'>
              <Row className='mx-3'>
                <Col xs={12} className='shadow rounded svg-secondary-3 '>
                  <Row>
                    <Col md={12} className='gradient-loading py-4 rounded'></Col>
                  </Row>
                </Col>
              </Row>
              <Row className='mx-2 mt-3'>
                <Col xs={3} className={`h-100 p-0`}>
                  <Col xs={11} className='mx-auto bg-primary-4 gradient-loading  mh-400-px rounded '></Col>
                </Col>
                <Col xs={9} className={'p-0 '}>
                  <Row className=' mx-2'>
                    {[...Array(6)].map((_) => (
                      <Col xs={4} key={`gradient-${generateNumberId()}`}>
                        <Col xs={11} className='gradient-loading mh-400-px mx-auto mb-3 rounded'></Col>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default StoreLoading;
