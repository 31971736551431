import { PropsWithChildren, useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { change } from '@/ui/store/slices/managementSlice';

import lastPageName from '@/domain/utils/lastPageName';
import useAskUserCredentials from '@/ui/hooks/useAskUserCredentials';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';

import { Col, Container, Row } from 'react-bootstrap';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import ManagementList from '@/ui/containers/managementOptions/ManagementList';

interface PropsManagementOptionsContainer {
  /** Propiedad que define el tipo de Alert*/
  type: string;
  /** Clase Principal del contenedor */
  classMain?: string;
  /** Listado de breadcrumbs */
  breadcrumbs?: IBreadcrumbs[] | null;
  /** Función especifica para el botón de ir atrás del breadcrumbs */
  breadcrumbsFunction?: (e) => void;
}

/** Componente pagina de Inventario
 * @component
 */

const ManagementOptionsContainer = ({
  type = '',
  classMain,
  breadcrumbs = null,
  breadcrumbsFunction,
  children,
}: PropsWithChildren<PropsManagementOptionsContainer>): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Si el usuario no está logueado se ejecutará la utilidad para pedir credenciales */
  useAskUserCredentials();

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Cambiamos el estado del header cuando entra en un estado administrativo */
  const dispatch = useAppDispatch();

  /** Uso de los efectos del componente para ocultar o mostrar elementos en el header*/
  useEffect(() => {
    /** Actualiza el estado Redux del managementState al montar el componente      */
    if (isMobile) {
      dispatch(change({ value: true }));
    }

    return () => {
      /** Actualiza el estado Redux del managementState al desmontar el componente      */
      dispatch(change({ value: false }));
    };
  }, [width, maxLargeWidth, dispatch]);

  /** Representa el tipo de usuario cliente */
  const kindClientUser: string = 'CLIENT';

  return userLogued.user.isLogued && userLogued.user.kind !== kindClientUser ? (
    <Container fluid className={`${!isMobile ? 'bg-secondary-5 ps-4 pe-5' : 'bg-primary-4'} p-0 pb-5 pt-3 ${type} `}>
      {!isMobile && (
        <Row className=' mt-1 me-2 mb-3 ms-3 g-0'>
          <Col lg={12} className='p-2 bg-primary-4 shadow rounded'>
            <Breadcrumbs list={breadcrumbs ? breadcrumbs : breadcrumbsList} backFunction={breadcrumbsFunction && breadcrumbsFunction} />
          </Col>
        </Row>
      )}
      <Row className='mx-2 g-0'>
        {!isMobile && (
          <Col lg={2}>
            <Container className='nav-desktop'>
              <Row className='g-0'>
                <Col lg={12} className='p-0 align-self-center bg-primary-4 rounded-2'>
                  <Container fluid className='p-0 h-100'>
                    <Row className='g-0'>
                      <Col lg={12} className='px-1'>
                        <ManagementList subtitles={false} />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        )}
        <Col xs={12} md={10} className={`${!isMobile && 'bg-primary-4 rounded-2'}`}>
          <Container fluid>
            <Row>
              <Col lg={12} className={`${classMain} ${!isMobile && 'pt-3 pb-4 px-3'}`}>
                {children}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  ) : (
    <>
      <p>No Autorizado, Mostrar Interface</p>
    </>
  );
};

export default ManagementOptionsContainer;
