import { useLayoutEffect, useState } from 'react';

import { useLocation, useParams, useSearchParams, type Location } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { updateInitialRanges, updateCurrentRanges } from '@/ui/store/slices/priceRangeSlice';
import { updateAttributes } from '@/ui/store/slices/attributesSlice';

import ProductsController from '@/controllers/ProductsController';

import { IProduct } from '@/domain/interfaces/IProduct';
import { IProductResponse } from '@/domain/interfaces/IProductResponse';

import { IMenu } from '@/domain/interfaces/IMenu';
import { IAttributesFilters, IQualitativeFIlters, IQuantitativeFIlters, IOrderRequest } from '@/domain/interfaces/IAttributes';
import { IPaginationSettings } from '@/domain/interfaces/IPagination';

import ListGrid from '@/ui/components/store/ListGrid';
import FilterStoreForm from '@/ui/components/store/FilterStoreForm';
import OrderStoreForm from '@/ui/components/store/OrderStoreForm';
import LoginForm from '@/ui/components/user/LoginForm';
import ProductPreview from '@/ui/components/store/ProductPreview';

import StoreLoading from '@/ui/pages/Store/StoreLoading';

/** Componente pagina Store
 * @component
 */
const Store = (): JSX.Element => {
  /** Hook de actualización de states Redux */
  const dispatch = useAppDispatch();

  /** State de criterio de orden */
  const orderRequest: IOrderRequest[] = useAppSelector((state) => state.storeSortCriteria);

  /**valida si el sessionStorage tiene el valor de storeFilterState */
  const storeFilterShow = sessionStorage.getItem('storeFilterState') === 'true';

  /**Cantidad de productos por página por defecto */
  const [productsPerPage, setProductsPerPage] = useState<number>(storeFilterShow ? 6 : 8);

  /**  Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Constante booleano que confirma si es necesaria la autorización */
  const needAuth: boolean = location.state?.needAuth === true;

  /** Estado inicial del modal para Login */
  const [showLogin, setShowLogin] = useState<boolean>(needAuth);

  /** Estado inicial de la interfaz de loading */
  const [loading, setLoading] = useState<boolean>(true);

  /**  Estado inicial del modal para Registro */
  const [showRegister, setShowRegister] = useState<boolean>(false);

  /** Trae el Slug de la categoría para renderizar los elementos */
  const { categorySlug, page } = useParams();

  /**Hook para traer el parámetro de la ruta */
  const [searchParams] = useSearchParams();

  /**Renderiza la pagina actual o 1 si no viene */
  const currentPage: number = parseInt(page ?? '1');

  /**  Controlador de productos   */
  const { getProductsStore, getProductsStoreByCategoryAndFilters } = ProductsController();

  /**  Estado Inicial que almacena el arreglo de productos para enviarlo a la lista   */
  const [products, setProducts] = useState<IProduct[]>([]);

  /** Inicializa el objeto que puede contener categorías asociadas a la búsqueda si no se ha seleccionado una categoría */
  const [associatedCategories, setAssociatedCategories] = useState<IMenu[]>([]);
  const [productCategoriesFound, setProductCategoriesFound] = useState<string[]>([]);

  const [paginationSettings, setPaginationSettings] = useState<IPaginationSettings | null>(null);

  /**  Evento que muestra y oculta el modal para login	*/
  const handleShowLogin = (): void => setShowLogin(!showLogin);

  /**  Consulta y lista productos */
  const fetchProducts = async (): Promise<void> => {
    //Al finalizar de cargar productos quitara el loading
    setLoading(true);
    /** Almacenara el slug de la categoría a buscar si existe */
    const categorySlugValue: string = categorySlug ?? '';

    /** Objeto que se entregara al controlador para realizar a búsqueda */
    const paramsToFilter: IAttributesFilters =
      categorySlugValue.length > 0 ? { categorySlug: categorySlugValue, productPerPage: productsPerPage } : { productPerPage: productsPerPage };

    /** Transforma el texto que viene del parámetro del precio */
    //se utiliza map para convertir cada elemento del array de precios a números y se utiliza destructuring para asignar directamente a las variable initialRange y finalRange
    const prices = (searchParams.get('price')?.split('_') || []).map(parseFloat);
    //se utiliza destructuring para asignar directamente a las variable initialRange y finalRange
    const [initialRange, finalRange] = prices;
    //se asigna el valor de initialRange y finalRange a las propiedades priceRangeA y priceRangeB del objeto paramsToFilter
    paramsToFilter['priceRangeA'] = initialRange;
    paramsToFilter['priceRangeB'] = finalRange;

    /** Objeto que almacenara los filtros cualitativos a entregar al controlador */
    const qualitativeFilters: IQualitativeFIlters = {};

    /** Objeto que almacenara los filtros cuantitativos a entregar al controlador */
    const quantitativeFilters: IQuantitativeFIlters = {};

    /**  Recorre todos los parámetros de consulta y los guarda en el objeto paramsToFilter*/
    searchParams.forEach((value, key) => {
      //si el key es igual a find se asigna el valor a la propiedad keyWord
      if (key === 'find') {
        //se asigna el valor de find a la propiedad keyWord
        paramsToFilter.keyWord = value;
        //si el key es igual a price se asigna el valor a la propiedad price
      } else if (key !== 'price') {
        //se realiza el split de key para obtener el nombre del atributo y el valor del atributo
        const [keyAttribute, valueAttribute, typeAttribute] = key.split('_');

        //si el valor existe se setea el dato como llave: [valores]
        if (valueAttribute) {
          /**Valida que el valor sea un numero unicamente */
          const parsedValue = /^[+-]?\d+(\.\d+)?$/.test(valueAttribute) ? parseFloat(valueAttribute) : NaN;

          if (typeAttribute === 'ql') {
            //se valida si el valor es un numero o un string y se asigna al objeto de filtros
            //const parsedValue = !isNaN(Number(valueAttribute)) ? Number(valueAttribute) : parseFloat(valueAttribute);
            //se valida si el atributo ya existe en el objeto de filtros
            qualitativeFilters[keyAttribute] = qualitativeFilters[keyAttribute]
              ? //si existe se agrega el valor al array
                [...qualitativeFilters[keyAttribute], valueAttribute]
              : //si no existe se crea un array con el valor
                [valueAttribute];
          } else {
            quantitativeFilters[keyAttribute] = quantitativeFilters[keyAttribute]
              ? //si existe se agrega el valor al array
                [...quantitativeFilters[keyAttribute], parsedValue]
              : //si no existe se crea un array con el valor
                [parsedValue];
          }
        }
      }
    });

    /** Se asignan los filtros al objeto que se envía al controlador */
    paramsToFilter.qualitativeFilters = qualitativeFilters;
    paramsToFilter.quantitativeFilters = quantitativeFilters;

    /** Asigna la solicitud para ordenar los resultados */
    paramsToFilter.orderData = [...orderRequest];

    /** El valor de categorySlugValue es el indicador mínimo para saber que es una consulta con categoría asociada */
    if (categorySlugValue.length > 0) {
      /** Consulta por medio del controlador de los productos por slug de categorías y filtros  */
      await getProductsStoreByCategoryAndFilters(paramsToFilter, currentPage).then((productsStore: IProductResponse) => {
        setProductAndPrices(productsStore);
        if (productsStore.products && productsStore.products.length > 0 && productsStore.subCategoriesSlugs) {
          /** Asigna el elemento de sub-categorías a mostrar luego de filtros */
          setProductCategoriesFound(productsStore.subCategoriesSlugs);
        }
        setPaginationSettings(productsStore.pagination);
      });
    } else {
      /** Consulta por medio del controlador de los productos sin categoría asociada y filtros */
      await getProductsStore(paramsToFilter, currentPage).then((productosStore: IProductResponse) => {
        /** Asigna las categorías asociadas para enviar al componente filtro */
        setAssociatedCategories(productosStore.categories ?? []);
        setPaginationSettings(productosStore.pagination);
        /** Actualiza los atributos de la búsqueda */
        dispatch(updateAttributes(productosStore.attributes ?? []));
        setProductAndPrices(productosStore);
      });
    }
    //Al finalizar de cargar productos quitara el loading
    setLoading(false);
  };

  /**
   * Establece los productos y los rangos de precios
   *
   * @param {IProductResponse} productosStore
   */
  const setProductAndPrices = async (productosStore: IProductResponse): Promise<void> => {
    /** Si existen errores identificados por el caso de uso se muestran en el componente lista vacía  */
    if (productosStore.error) {
      //Limpia los productos si obtenemos un error.
      setProducts([]);
    } else {
      // Establece los productos encontrados
      setProducts(productosStore.products);

      // Establece los valores mínimo y máximo del rango de precios
      const lowestPrice: number = productosStore.prices?.minPriceStore ?? 0;
      const higherPrice: number = productosStore.prices?.maxPriceStore ?? 0;

      /**Actualiza los precios iniciales y actuales si no hay filtros de precios, si los hay solo actualiza los precios actuales */
      if (!searchParams.has('price')) {
        dispatch(updateInitialRanges({ minValue: lowestPrice, maxValue: higherPrice }));
        dispatch(updateCurrentRanges({ minValue: lowestPrice, maxValue: higherPrice }));
      } else {
        dispatch(updateCurrentRanges({ minValue: lowestPrice, maxValue: higherPrice }));
      }
    }
  };

  /**UseEffect que renderiza los producto por pagina de acuerdo a el estado redux */
  useLayoutEffect(() => {
    if (storeFilterShow) {
      setProductsPerPage(6);
    } else {
      setProductsPerPage(8);
    }
  }, [storeFilterShow, productsPerPage, location]);

  /** UseEffect que trae la información de productos disponibles   */
  useLayoutEffect((): void => {
    fetchProducts();
  }, [location.search, categorySlug, productsPerPage, currentPage, orderRequest]);

  return (
    <>
      {loading ? (
        <StoreLoading />
      ) : (
        <ListGrid
          arrayData={products}
          handleArrayData={setProducts}
          paginationSettings={paginationSettings}
          RenderItem={ProductPreview}
          FilterItems={FilterStoreForm}
          filterItemsAttributes={associatedCategories}
          OrderItems={OrderStoreForm}
          productCategoriesFound={productCategoriesFound}
        />
      )}
      {showLogin && <LoginForm showLogin={showLogin} handleShowLogin={handleShowLogin} handleShowRegister={() => setShowRegister} />}
    </>
  );
};

export default Store;
