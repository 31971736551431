/**
 * Función que toma la ultima cadena de números de la URL, si la misma no es nula
 * Al tomar el valor el mismo reemplaza '-' por espacios en blanco y convierte la primera letra en Mayúscula
 * Si es nula genera la palabra Atrás
 * Ejemplo si la URL es tienda/productos-destacados tomara 'Productos destacados'
 * @param {string} url
 * @returns {string} value con formato
 */

/**lasPageName toma como parámetro url de tipo string o null y devuelve un string o undefined*/
const lastPageName = (url: string | null): string | undefined => {
  if (url === null || typeof url !== 'string') {
    return 'Atrás';
  }

  const regex = /([^/?#]+)([?#].*)?$/;
  const match = RegExp(regex).exec(url);

  if (match) {
    const textBeforeLastSlash = match[1];
    const decodedText = decodeURIComponent(textBeforeLastSlash.replace(/-/g, ' '));
    const capitalizeFirstLetter = decodedText.charAt(0).toUpperCase() + decodedText.slice(1);
    return capitalizeFirstLetter;
  }

  return undefined;
};

export default lastPageName;
