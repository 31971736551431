import { useEffect, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { RoutesDirections } from '@/data/libraries/Routes';
import { isEmpty } from 'lodash';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';
import useValidateUnavailableProducts from '@/ui/hooks/useValidateUnavailableProducts';
import useReservation from '@/ui/hooks/useReservation';
import useAttributesPresenter from '@/ui/hooks/useAttributesPresenter';

import { Col, Container, Row, Image, Button, Modal } from 'react-bootstrap';

import { useAppSelector, useAppDispatch } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';
import { updateIndexCheckOut } from '@/ui/store/slices/indexCheckOutSlice';

import CartController from '@/controllers/CartController';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { ICart, ICartDetail } from '@/domain/interfaces/ICart';

import currencyFormat from '@/domain/utils/currencyFormat';
import lastPageName from '@/domain/utils/lastPageName';

import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import GuideTip from '@/ui/components/GuideTip';
import IconList from '@/ui/components/IconList';
import EmptyCart from '@/ui/components/store/EmptyCart';
import ProductCounter from '@/ui/components/product/ProductCounter';
import ReservationsModals from '@/ui/components/product/ReservationsModals';
import ProductStatusPill from '@/ui/components/product/ProductStatusPill';
import MainPrice from '@/ui/components/product/MainPrice';
import SecondaryPrice from '@/ui/components/product/SecondaryPrice';

/**
 * Componente Carrito de Compras
 * @component
 */
const Cart = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Controlador de carrito */
  const { productQuantity, deleteCart, updateCartProductsData } = CartController();

  /** Hook actualización Redux */
  const dispatch = useAppDispatch();

  /** Hook  que valdia el carrito y sus items */
  const { hasUnavailableProducts } = useValidateUnavailableProducts();

  const [cartAlert, setCartAlert] = useState<boolean>(false);
  const [cartMessage, setCartMessage] = useState<string>('');

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);
  const userStore: string = String(userLogued.user?.userStore ?? 1);

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  //Transforma atributos en un objeto como se presentan para el cliente
  const { attributesPresenter } = useAttributesPresenter();
  const [showReservationModal, setShowReservationModal] = useState<boolean>(false);
  const [showErrorReservationModal, setShowErrorReservationModal] = useState<boolean>(false);

  /**Modal para inactividad del usuario */
  const [showModalIdle, setShowModalIdle] = useState<boolean>(location.state?.showIdleModal);

  /**Caso de uso que modifica el estado de la  location luego de renderizar */
  useEffect(() => {
    // Limpia el estado después de utilizarlo
    if (showModalIdle) {
      navigate(location, { state: { originURL: location.pathname, showModalIdle: false } });
    }
  }, []);

  const reservationHook = useReservation();

  const handleReservation = async () => {
    setShowReservationModal(true);
    await cartRefresh();
    setTimeout(async () => {
      const isReserved: boolean = await reservationHook.reservation();

      if (!isReserved) {
        setShowReservationModal(false);
        setShowErrorReservationModal(true);
      } else {
        navigate(RoutesDirections.CHECK_OUT_ROUTE, { state: { originURL: location.pathname } });
      }
    }, 2000);
  };

  /** Actualiza datos de los productos del carrito */
  const cartRefresh = async (): Promise<void> => {
    await updateCartProductsData();
  };

  /** Al cambiar el carrito se aplica el hook que valida y alerta */
  useEffect(() => {
    const validateCart = hasUnavailableProducts();
    setCartAlert(validateCart.error);
    setCartMessage(validateCart.message);
  }, [cartProducts]);

  useEffect(() => {
    cartRefresh();
    dispatch(updateIndexCheckOut(1));
  }, []);

  /**CONSTANTES DE TEXTO */

  const CART_TITLE: string = 'Mi carrito';

  const CART_RESUMEN: string = 'Resumen de la compra';
  const CART_SUBTOTAL: string = 'Subtotal';
  const CART_SUBTOTAL_MESSAGE: string = 'Costos de envío no incluidos';
  const BUY_BUTTON_TEXT: string = 'CONTINUAR COMPRA';
  const CLEAN_CART_TEXT: string = 'Limpiar carrito';
  const CART_ITEMS_COUNTER_LABEL: string = productQuantity() > 1 ? `Productos agregados` : `Producto agregado`;

  const MODAL_IDLE_URL: string = `${URL_IMAGES}/client/idle-modal.png`;
  const MODAL_IDLE_TITLE: string = '¡Oops!  Parece que has estado inactivo. Por seguridad, te hemos retornado al carrito.';
  const MODAL_IDLE_UPDATE_BUTTON: string = 'ACTUALIZAR';

  /**COMPONENTES BASE */

  /**Componente Imagen */
  const PreviewImage = (product: ICartDetail): JSX.Element => (
    <NavLink to={`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${product.skuProduct}`} className='image-cart-container rounded rounded-3'>
      <Image
        className='rounded rounded-3 image-full cursor-hand'
        src={`${product.image}`}
        alt={''}
        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
          e.currentTarget.src = ErrorImage;
        }}
      />
    </NavLink>
  );

  /**Componente Mensaje de Alerta */
  const AlertUnavaliableProducts: JSX.Element = (
    <Container fluid className='p-3 bg-primary-4 my-4 shadow rounded position-relative'>
      <Col lg={12} className='align-self-end  position-absolute top-0 end-0'>
        <div className='bg-complementary-4 pt-1 rounded-4 rounded-top-5' />
      </Col>

      <Row>
        <Row className='d-flex justify-content-center'>
          <Col lg={9} className='mt-3'>
            <IconList title='¡ALERTA!' classTitle='text-complementary-4 p-title-bold' iconName='ico-warning' classMain='svg-complementary-4' />
          </Col>
          <Col lg={9} className='text-secondary-3 d-flex text-justify mt-3 mb-3'>
            {cartMessage}
          </Col>
        </Row>
      </Row>
    </Container>
  );

  /**Componente Metodos de Envío REFACTORIZAR*/
  const ShipingMethods: JSX.Element = (
    <>
      <span className='p-small-bold text-primary-3 pe-4'>{`Entrega:`}</span>
      <GuideTip position='bottom' message={'Entrega a domicilio'}>
        <Icon name='ico-national-shipment' classMain='pe-2' />
      </GuideTip>
    </>
  );

  return (
    <>
      <Container fluid className='p-0 min-vh-50 bg-secondary-5'>
        {productQuantity() > 0 ? (
          <Container fluid>
            {isMobile ? (
              <>
                <Row className=' pt-4  g-0'>
                  <Col xxl={12} className='p-2 bg-primary-4 shadow rounded'>
                    <Breadcrumbs />
                  </Col>
                </Row>
                <Row className='pt-3 pb-3  g-0'>
                  <Col xs={12} className='py-2 '>
                    <Row
                      xs={12}
                      className='d-flex g-0 py-2 pe-2 justify-content-between align-items-center bg-primary-4 shadow rounded row-cols-auto'>
                      <Col>
                        <IconList
                          classMain='ps-3 text-primary-3'
                          classIcon='svg-primary-3'
                          title={CART_TITLE}
                          iconName='ico-market-car'
                          classTitle='p-large-bold'
                        />
                      </Col>
                      <Col>
                        <span className='my-2 p-regular-bold ps-3 text-primary-3'>{`${productQuantity()} ${CART_ITEMS_COUNTER_LABEL}`}</span>
                      </Col>
                    </Row>
                    {cartAlert && AlertUnavaliableProducts}
                    {!isEmpty(cartProducts) &&
                      cartProducts.details.map((product) => {
                        const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null;
                        const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;

                        const attributes: string = attributesPresenter(product.attributes);
                        return (
                          <Container fluid className='p-0 bg-primary-4 my-3 rounded rounded-3' key={product.skuProduct}>
                            <Row className='g-0 p-1 d-flex '>
                              <Col xs={5} className='mx-auto text-center position-relative'>
                                {PreviewImage(product)}
                                <div className='top-0 end-0 position-absolute pe-2'>
                                  <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />{' '}
                                </div>
                              </Col>
                              <Col xs={7} className='ps-3 pe-2 position-relative '>
                                <Row className='g-0 d-flex '>
                                  <Col xs={12} className='p-regular text-truncate'>
                                    <span className='p-regular-medium'>{product.title}</span>
                                  </Col>
                                  <Col xs={12} className='text-truncate'>
                                    <span className='p-small'>{attributes}</span>
                                  </Col>
                                  <Row className='my-2 g-0'>
                                    <Col xs={12} className='p-large-bold'>
                                      <MainPrice
                                        isFree={isFree}
                                        value={isInOffer ? product.offerPrice ?? 0 : product.price}
                                        className='p-title-bold'
                                      />
                                    </Col>
                                    <Col xs={12} className='p-large-medium'>
                                      <SecondaryPrice isInOffer={isInOffer} value={product.price} className='pe-3 p-regular-medium' />
                                    </Col>
                                  </Row>
                                </Row>
                                <Row className='g-0 mb-1 row-cols-auto'>{ShipingMethods}</Row>
                              </Col>
                            </Row>
                            <Row className='g-0 px-1'>
                              <Col xs={12}>
                                <ProductCounter skuProduct={product.skuProduct} productSelected={true} />
                              </Col>
                            </Row>
                          </Container>
                        );
                      })}
                  </Col>
                  <Col xs={12}>
                    <Container fluid className='p-0'>
                      <Row className='g-0 py-2 px-4  d-flex justify-content-start bg-primary-4 shadow rounded '>
                        <Col xs={12}>
                          <h4 className='text-primary-3 mb-4 mt-2 text-center'>{CART_RESUMEN}</h4>
                        </Col>
                        <Col xs={6}>
                          <h4 className='text-secondary-2'>{CART_SUBTOTAL}</h4>
                          <span className='p-regular text-secondary-2'>{CART_SUBTOTAL_MESSAGE}</span>
                        </Col>
                        <Col xs={6} className='text-end'>
                          <span className='p-large-bold text-secondary-2'>
                            {currencyFormat(cartProducts.totalWithTax, ConfigNumberParameters.DECIMALS_ALLOWED)}
                          </span>
                        </Col>
                        <Col xs={12}>
                          <Button
                            className={`my-2 w-100 svg-primary-4 btn-primary-icon-standard ${cartAlert ? 'disabled' : ''}`}
                            type='button'
                            onClick={handleReservation}>
                            <span className={`pe-2 p-regular-medium`}>{BUY_BUTTON_TEXT}</span>
                          </Button>
                        </Col>
                        <Col xs={12} className='text-center my-2'>
                          <NavLink to={'#'} className='p-large-medium  link-text-standard' onClick={deleteCart}>
                            {CLEAN_CART_TEXT}
                          </NavLink>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </>
            ) : (
              <Container className='p-0'>
                <Row className=' pt-4  g-0'>
                  <Col xxl={12} className='p-2 bg-primary-4 shadow rounded'>
                    <Breadcrumbs list={breadcrumbsList} />
                  </Col>
                </Row>
                <Row className='pt-3 pb-3  g-0'>
                  <Col lg={8} className='py-2 '>
                    <Row xs={12} className='d-flex g-0 py-2 justify-content-between align-items-center bg-primary-4 shadow rounded row-cols-auto'>
                      <Col>
                        <IconList
                          classMain='ps-3 text-primary-3'
                          classIcon='svg-primary-3'
                          title={CART_TITLE}
                          iconName='ico-market-car'
                          classTitle='h2'
                        />
                      </Col>
                      <Col>
                        <span className='my-2 h4 pe-3 text-primary-3'>{`${productQuantity()} ${CART_ITEMS_COUNTER_LABEL}`}</span>
                      </Col>
                    </Row>
                    {cartAlert && AlertUnavaliableProducts}

                    {!isEmpty(cartProducts) &&
                      cartProducts.details.map((product) => {
                        const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null;
                        const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;
                        const attributes: string = attributesPresenter(product.attributes);
                        return (
                          <Container fluid className='p-2 bg-primary-4 my-4 shadow rounded' key={product.skuProduct}>
                            <Row className={'g-0'}>
                              <Col lg={2}>{PreviewImage(product)}</Col>
                              <Col lg={10} className='ps-3'>
                                <Row className='g-0'>
                                  <Col xs={9} className='text-truncate'>
                                    <span className='paragraph text-truncate pe-2'>{product.title}</span>
                                  </Col>
                                  <Col lg={3} className='text-end'>
                                    {ShipingMethods}
                                  </Col>
                                  <Col xs={12}>
                                    <span>{attributes}</span>
                                  </Col>
                                </Row>
                                <Row className={'g-0 mt-4 d-flex align-items-center'}>
                                  <Col lg={5}>
                                    <Col xs={12} className='d-flex align-items-center p-large-bold'>
                                      <MainPrice isFree={isFree} value={isInOffer ? product.offerPrice ?? 0 : product.price} className='pe-3' />
                                    </Col>
                                    <Col xs={12} className='d-flex align-items-center p-regular-medium'>
                                      <SecondaryPrice isInOffer={isInOffer} value={product.price} />
                                    </Col>
                                  </Col>
                                  <Col lg={2} xxl={1} className='text-start'>
                                    <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />
                                  </Col>
                                  <Col lg={5} className='text-end ms-auto'>
                                    <ProductCounter skuProduct={product.skuProduct} productSelected={true} />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        );
                      })}
                  </Col>
                  <Col lg={4} className='ms-auto pt-2 ps-3'>
                    <Container fluid className='p-0'>
                      <Row className='g-0 py-2 px-4  d-flex justify-content-start align-items-center bg-primary-4 shadow rounded '>
                        <Col xs={12}>
                          <h4 className='text-primary-3 mb-4 mt-2'>{CART_RESUMEN}</h4>
                        </Col>
                        <Col xs={5}>
                          <h4 className='text-secondary-2'>{CART_SUBTOTAL}</h4>
                        </Col>
                        <Col xs={7} className='text-end'>
                          <span className='h4 text-secondary-2'>
                            {currencyFormat(cartProducts.totalWithTax, ConfigNumberParameters.DECIMALS_ALLOWED)}
                          </span>
                        </Col>
                        <Col xs={12}>
                          <span className='p-large text-secondary-2'>{CART_SUBTOTAL_MESSAGE}</span>
                        </Col>
                        <Col xs={12} className=' my-3'>
                          <Button
                            className={`my-2 w-100 svg-primary-4 btn-primary-icon-standard ${cartAlert ? 'disabled' : ''}`}
                            type='button'
                            onClick={handleReservation}>
                            <span className={`pe-2 p-regular-medium`}>{BUY_BUTTON_TEXT}</span>
                          </Button>
                        </Col>
                        <Col xs={12} className='text-center my-3'>
                          <NavLink to={'#'} className='h5 link-text-standard' onClick={deleteCart}>
                            {CLEAN_CART_TEXT}
                          </NavLink>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            )}
          </Container>
        ) : (
          <Row className='min-vh-85 g-0'>
            <EmptyCart />
          </Row>
        )}
      </Container>

      {/* Inicio Modal inactividad */}
      {showModalIdle && (
        <Modal
          show={showModalIdle}
          onHide={undefined}
          dialogClassName='modal-dialog-centered'
          contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
          size='lg'>
          <Container fluid className='px-0 g-0 '>
            <Row className='d-flex justify-content-center g-0  '>
              <Col lg={12} className='mt-5 pt-3'>
                <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0  '>
                  <Image
                    src={MODAL_IDLE_URL}
                    width={200}
                    alt='gif'
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = ErrorImage;
                    }}
                  />
                </Col>
              </Col>

              <Col lg={9} className='d-flex justify-content-center text-primary-3 g-0 my-0 px-0'>
                <span className=' text-primary-3 p-large-bold text-center'>{MODAL_IDLE_TITLE}</span>
              </Col>

              <Col lg={8} className='d-flex justify-content-center mt-2 mb-4 g-0 px-2 px-5'>
                <Button className={`my-2  svg-primary-4 btn-primary-icon-standard `} type='button' onClick={() => setShowModalIdle(false)}>
                  {MODAL_IDLE_UPDATE_BUTTON}
                </Button>
              </Col>
            </Row>
          </Container>
          <Col xs={12} className='align-self-end pt-2 '>
            <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
          </Col>
        </Modal>
      )}

      {/* Fin Modal inactividad */}
      {showReservationModal && ( //Midal de proceso de Reserva
        <ReservationsModals />
      )}
      {showErrorReservationModal && ( // Modal de proceso de Reserva con Error
        <ReservationsModals error />
      )}
    </>
  );
};

export default Cart;
