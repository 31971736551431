import TypeList from '@/data/libraries/IdentificationTypes';

/**
 * Función para obtener el nombre del tipo de identificación según el ID.
 *
 * @param {number} typeId - El ID del tipo de identificación del cliente.
 * @return {string} El nombre del tipo de identificación correspondiente.
 */
const getTypeNameById = (typeId: number): string => {
  // Buscamos en TypeList el elemento que tenga el id correspondiente al typeId
  const type = TypeList.find((type) => type.id === typeId);

  // Si se encuentra el tipo, retornamos su nombre; de lo contrario, retornamos un mensaje de error o vacío
  return type ? type.name : 'S/I';
};

export default getTypeNameById;
