import {Col, Button} from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';


/** Componente que agrupa otras formas de login
    * @component
*/
const LoginByOthers = ():JSX.Element => {
    return (
        <Col xs={12}>
            <Col xs={12} className='py-3'>
                <div className='w-100 text-center'><Icon name='ico-separator'/></div>
            </Col>
            <Col xs={12}>
                <div className='d-grid gap-2'>
                    <Button className='btn btn btn-light border text-blue' type='button'>
                        <IconList iconName='ico-phone'  classTitle='p-regular-medium' title='Continuar con tu celular' />
                    </Button>
                    <Button className='btn btn btn-light border text-blue' type='button'>
                        <IconList iconName='ico-google'  classTitle='p-regular-medium' title='Continuar con Google' />
                    </Button>
                    <Button className='btn btn btn-light border text-blue' type='button'>
                        <IconList iconName='ico-microsoft'  classTitle='p-regular-medium' title='Continuar con Microsoft' />
                    </Button>
                    <Button className='btn btn btn-light border text-blue' type='button'>
                        <IconList iconName='ico-apple'  classTitle='p-regular-medium' title='Continuar con Apple' />
                    </Button>
                </div>
            </Col>
        </Col>
    );
}

export default LoginByOthers;