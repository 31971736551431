import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

/**
 * Da formato a los precios
 * @param {number} value
 * @returns {string} value con formato
 */
const priceFormat = (number: string): string => {
  /** Si DECIMALS_ALLOWED es 0, no permitir comas */
  if ((ConfigNumberParameters.DECIMALS_ALLOWED as number) === 0) {
    number = number.replace(/,/g, '');
  }

  /** Eliminar caracteres no válidos (todo excepto números y la coma) */
  number = number.replace(/[^\d,]/g, '');

  /** Reemplazar coma por un punto  */
  number = number.replace(/,/g, '.');

  /** Dividir la cadena en partes antes y después del punto */
  const parts = number.split('.');

  /** Formatear la parte entera con separadores de miles */
  const formattedIntegerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  let formattedDecimalPart = '';
  let formattedValue = '';
  if (parts.length > 1) {
    /** Si hay decimales, se formatean */
    if (parts[1] !== '') {
      /** Solo permite los decimales de la configuracion permitida por el enum */
      formattedDecimalPart = parts[1].slice(0, ConfigNumberParameters.DECIMALS_ALLOWED as number);
      /** Combina las partes para obtener el valor formateado */
      formattedValue = formattedDecimalPart ? `${formattedIntegerPart},${formattedDecimalPart}` : formattedIntegerPart;
    } else {
      /** Si no hay decimales pero se digitó coma se deja allí */
      formattedValue = `${formattedIntegerPart},`;
    }
  } else {
    /** Se retorna el valor entero formateado */
    formattedValue = formattedIntegerPart;
  }
  return formattedValue;
};

export default priceFormat;
