import { PropsWithChildren } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';

interface PropsProductList {
  /**Cantidad de productos base */
  baseProducts: number;
  /** Estado de Productos Base*/
  baseProductsStatus?: boolean;
  /** Funcion que se ejecutar al hacer click a productos base */
  onClickBaseProdcuts?: () => void;
  /**Cantidad de variantes */
  variants: number;
  /** Estado de Variantes*/
  variantStatus?: boolean;
  /** Funcion que se ejecutar al hacer click a variantes */
  onClickVariants?: () => void;
}

/**
 * Componente para lista de prodcutos bases y variantes
 * @component
 */
const ProductList = ({
  baseProducts,
  baseProductsStatus,
  onClickBaseProdcuts,
  variants,
  variantStatus,
  onClickVariants,
}: PropsWithChildren<PropsProductList>): JSX.Element => {
  return (
    <Container fluid className="p-0">
      <Row className="g-0">
        <Col xs={12} className="pt-4">
          <ul className="list-unstyled svg-secondary-1">
            <IconList
              iconName="ico-product-base"
              classMain={`p-2 my-1 btn-list-link-icon-light-small ${baseProductsStatus && 'active'}`}
              classTitle="p-regular"
              title={'Producto Base'}
              pillText={baseProducts}
              onClick={onClickBaseProdcuts}
            />
            <IconList
              iconName="ico-variant"
              classMain={`p-2 my-1 btn-list-link-icon-light-small ${variantStatus && 'active'}`}
              classTitle="p-regular"
              title={'Variantes'}
              pillText={variants}
              onClick={onClickVariants}
            />
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductList;
