import ProductBackErrors, { IProductBackErrors } from '@/data/libraries/errors/backErrors/ProductBackErrors';

import { IErrorsBulk } from '@/domain/interfaces/IBulkLoad';
import { IProductForm, IVariantForm } from '@/domain/interfaces/IProduct';
import { IBackErrorDTO, IResponseValidationBack, IVariantErrorsBack } from '@/domain/interfaces/IProductResponse';

/**
 * Caso de uso de validación de producto base y productos (variantes) con homologación de errores del back
 * IProductBackErrors es un objeto tipo librería con códigos y mensajes de error homologados del back.
 *
 *
 * @param {IResponseValidationBack} errors
 * @param {IProductForm[]} productsBulkLoad - data de producto base
 * @param {IProductForm[]} allProductsBulkLoad - data de producto base
 * @returns {IVariationValidation[]} errors
 */
const validateBulkLoadingBackUseCase = (errors: IResponseValidationBack[], productsBulkLoad: IProductForm[], allProductsBulkLoad: IProductForm[]) => {
  // Errores homologados
  const errorsBulkLoad: IErrorsBulk[] = [];

  //Productos validos definitivos
  const productsBulkLoadFinal: IProductForm[] = [];

  //Guarda nombres de producto base con error
  const invalidProductBaseNames: string[] = [];
  //Guarda numero de linea en el excel de variantes no principales con error
  const invalidProductRows: number[] = [];

  const LINE_TEXT: string = 'Linea';

  if (errors !== null && errors.length > 0) {
    errors.forEach((errorBack: IResponseValidationBack) => {
      let errorFlag: boolean = false;

      // Ubica el producto base a homologar
      const foundAllBaseProduct: IProductForm | undefined = allProductsBulkLoad.find((baseProduct: IProductForm) => {
        return baseProduct.name === errorBack.baseProductName;
      });

      if (foundAllBaseProduct) {
        // Define la linea del excel
        const excelRow: number = foundAllBaseProduct?.row ?? 0;

        // Si existen errores de producto base se homologan
        if (errorBack.baseProductErrors && errorBack.baseProductErrors?.length > 0) {
          errorBack.baseProductErrors.forEach((baseProductErrorCode: IBackErrorDTO) => {
            const errorStructure: IProductBackErrors | undefined = ProductBackErrors.find(
              (error: IProductBackErrors) => error.code === baseProductErrorCode.code
            );
            if (errorStructure) {
              errorFlag = true;

              let errorsMessage: string = `${LINE_TEXT} ${excelRow} [${errorStructure?.key}] = ${errorStructure?.message}`;
              errorsMessage = errorsMessage.replace('REFERENCE_VALUE', `[${baseProductErrorCode.value ?? ''}]`);

              if (baseProductErrorCode.range !== null) {
                errorsMessage = errorsMessage.replace('MIN_RANGE', `(${baseProductErrorCode.range.min ?? ''})`);
                errorsMessage = errorsMessage.replace('MAX_RANGE', `(${baseProductErrorCode.range.max ?? ''})`);
              }
              errorsBulkLoad.push({ row: excelRow, errorsBulkLoad: errorsMessage });
            }
          });
        }

        // Si existen errores de producto base se alista su eliminación del objeto de productos base validos
        if (errorFlag) {
          invalidProductBaseNames.push(errorBack.baseProductName);
        }

        // Si existen errores de producto (variante) se homologan
        if (errorBack.productErrors && errorBack.productErrors?.length > 0) {
          errorBack.productErrors.forEach((productError: IVariantErrorsBack) => {
            const variantNumber: number = parseInt(productError.line);
            productError.errors.forEach((productErrorCode: IBackErrorDTO) => {
              const errorStructure: IProductBackErrors | undefined = ProductBackErrors.find(
                (error: IProductBackErrors) => error.code === productErrorCode.code
              );
              if (errorStructure) {
                let variantExcelRow: number = excelRow;
                let mainVariant: boolean = false;

                // Identifica la linea del excel de la variante y si no es main prepara su eliminación del objeto valido
                const variants: IVariantForm[] = foundAllBaseProduct?.variants;
                if (variants && variants.length > 0) {
                  variantExcelRow = variants[variantNumber - 1].row ?? 0;
                  mainVariant = variants[variantNumber - 1].main ?? false;
                  if (variantExcelRow !== 0 && !mainVariant) {
                    invalidProductRows.push(variantExcelRow);
                  } else {
                    //Si la variante con error es main se debe eliminar todo el producto base
                    invalidProductBaseNames.push(errorBack.baseProductName);
                  }
                } else {
                  //Si existe error pero no se identifica la variante con error se elimina todo el producto base
                  invalidProductBaseNames.push(errorBack.baseProductName);
                }

                // Prepara el mensaje de error
                let errorsMessage: string = `${LINE_TEXT} ${variantExcelRow}, variante ${mainVariant && 'MAIN'} numero: ${variantNumber} [${
                  errorStructure?.key
                }] = ${errorStructure?.message}`;

                // Actualiza las referencias en el mensaje
                if (errorsMessage.includes('REFERENCE')) {
                  errorsMessage = errorsMessage.replace('REFERENCE_VALUE', `(${productErrorCode.value ?? ''})`);
                  if (productErrorCode.range !== null) {
                    errorsMessage = errorsMessage.replace('MIN_RANGE', `(${productErrorCode.range.min ?? ''})`);
                    errorsMessage = errorsMessage.replace('MAX_RANGE', `(${productErrorCode.range.max ?? ''})`);
                  }
                }
                errorsBulkLoad.push({ row: excelRow, errorsBulkLoad: errorsMessage });
              }
            });
          });
        }
      }
    });

    // Extrae del objeto de productos base validos del front los que ahora tienen error
    const filteredBaseProducts: IProductForm[] = productsBulkLoad.filter((product) => !invalidProductBaseNames.includes(product.name));

    // Extrae del objeto de productos validos del front las variantes no principales con error
    filteredBaseProducts.forEach((baseProduct: IProductForm) => {
      const variants: IVariantForm[] = JSON.parse(JSON.stringify(baseProduct.variants));
      const filteredVariants: IVariantForm[] = variants.filter((variant: IVariantForm) => !invalidProductRows.includes(variant.row ?? 0));
      baseProduct.variants.length = 0;
      baseProduct.variants = [...filteredVariants];
    });

    // Extrae del objeto de productos base validos del front los que ahora tienen error
    const filteredVariantsProducts: IProductForm[] = filteredBaseProducts.filter(
      (product: IProductForm) => !invalidProductRows.includes(product.row ?? 0)
    );

    // Actualiza el objeto de salida
    productsBulkLoadFinal.push(...filteredVariantsProducts);
  } else {
    // Si no existen errores actualiza el objeto de salida
    productsBulkLoadFinal.push(...productsBulkLoad);
  }

  return { errorsBulkLoad, productsBulkLoadFinal };
};

export default validateBulkLoadingBackUseCase;
