import moment from 'moment';

/** Valida el formato de fecha
  * @param {string} dateField
  * @returns {boolean}
*/
const useDateValidation = (dateField: string): boolean => {

    const date = moment(dateField, 'DD/MM/YYYY', true);
    return date.isValid();

  }
  
  export default useDateValidation;