import { PropsWithChildren } from 'react';
import { Col, Modal } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';

interface PropsContentModal {
  /** Propiedad que muestra el modal */
  showModal: boolean;
  /** Evento que oculta y muestra el modal */
  handleShowModal: () => void;
  /** Mensaje del modal */
  message: string;
  /**Contenido interno del componente */
  content: JSX.Element | null;
  /** Función que ejecuta el modal */
  buttons: JSX.Element | null;
}

/**
 * Modal que muestra contenido o imágenes para confirmar o notificar la ejecución de una acción
 * @component
 */
const ContentModal = ({ showModal, handleShowModal, message, buttons, content }: PropsWithChildren<PropsContentModal>): JSX.Element => {
  return (
    <Modal
      show={showModal}
      onHide={undefined}
      dialogClassName='modal-dialog-centered'
      contentClassName=' rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
      size='lg'>
      <Col xs={12} className='text-end pe-2 pt-2 d-flex justify-content-end'>
        <CloseIcon classMain='btn-icon-light' onClick={handleShowModal} />
      </Col>
      <Col xs={8} className='mx-auto'>
        <Col xs={12} className='text-center px-5 text-primary-3 h4 mb-4 fw-bold'>
          {message}
        </Col>
        <Col xs={12} className='text-start p-2 bg-secondary-4 rounded-3'>
          {content}
        </Col>
        <Col xs={12} className='mx-auto mt-3'>
          {buttons}
        </Col>
      </Col>
      <Col xs={12} className='align-self-end pt-2'>
        <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
      </Col>
    </Modal>
  );
};

export default ContentModal;
