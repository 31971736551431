/**
 * Mockup que representa la información de los metodos de pago que obtendrá del back
 */

import { IPaymentMethods } from '@/domain/interfaces/IProduct';


export const MockPaymentMethods: IPaymentMethods[] = [
    {
        id: 1,
        title: 'Webpay',
        icon: 'ico-webpay',
    },
    /*{
        id: 2,
        title:'Visa Debito/Crédito', 
        icon: 'ico-visa',
    },
    {
        id: 3,
        title:'Mastercard Debito/Crédito', 
        icon: 'ico-mastercard',
    },
    {
        id: 4,
        title:'Pago contra entrega', 
        icon: 'ico-pay',
    }, */

];