import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import apiConfig from '@/data/services/api';

import { ICart } from '@/domain/interfaces/ICart';
import { IOrderByCodeResponse, IOrderDetailResponse, IOrderResponse, IOrderToProcess, IOrderToStatusChange } from '@/domain/interfaces/IOrder';

/** Representa la interacción con el Back */
export default class OrdersRepository {
  /** Instancia de la clase   */
  private static _instance: OrdersRepository;

  /** Patron Singleton
   * @returns {instance} _instance OrdersRepository
   */
  static get instance(): OrdersRepository {
    if (!this._instance) {
      this._instance = new OrdersRepository();
    }
    return this._instance;
  }

  /** Envía la orden de compra
   * @param {ICart} cart - Carrito de compras
   * @returns {object} data - objeto de respuesta
   * @param {string} token - Token de autorización
   */
  async purchaseOrder(cart: ICart): Promise<AxiosResponse> {
    return await axios.post('/order/purchase', cart, apiConfig);
  }

  /** Recupera el histórico de órdenes
   * @returns {object} data - tipos de identificación en el back
   */
  async getOrders(token: string): Promise<AxiosResponse<IOrderResponse[]>> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await axios.get('/order', requestConfig);
    } catch (error) {
      throw error;
    }
  }

  /** Recupera el histórico de órdenes detallado
   * @returns {object} data - tipos de identificación en el back
   */
  async getOrdersDetails(token: string): Promise<AxiosResponse<IOrderDetailResponse[]>> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await axios.get('/order/detail', requestConfig);
    } catch (error) {
      throw error;
    }
  }

  /** Recupera los datos de la orden al enviarle su código
   * @param {string} orderCode - código de la orden
   * @returns {IOrderByCodeResponse} data - tipos de identificación en el back
   */
  async getOrdersByCode(orderCode: string): Promise<AxiosResponse<IOrderByCodeResponse>> {
    /** URL a consultar */
    let url: string = `/order/search/${orderCode}`;

    try {
      return await axios.get(url, apiConfig);
    } catch (error) {
      throw error;
    }
  }

  /** Aprueba una orden
   * @param IOrderToProcess  orden a procesar
   * @returns @AxiosResponse respuesta de axios
   */
  async approveOrder(order: IOrderToProcess): Promise<AxiosResponse> {
    return await axios
      .post('/order/acceptedPayment', order, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /** rechaza una orden
   * @param IOrderToProcess  orden a procesar
   * @returns @AxiosResponse respuesta de axios
   */
  async rejectOrder(order: IOrderToProcess): Promise<AxiosResponse> {
    return await axios
      .post('/order/rejectedPayment', order, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /** rechaza una orden
   * @param IOrderToProcess  orden a procesar
   * @returns @AxiosResponse respuesta de axios
   */
  async cancelOrder(order: IOrderToProcess): Promise<AxiosResponse> {
    return await axios
      .post('/order/cancelPayment', order, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /** Envía el correo de la orden mediante un get al endpoint send-order/{id}
   * @param {number} orderId - ID de la orden
   * @param {string} token - Token de autorización
   * @returns {object} data - objeto de respuesta
   */
  async sendOrderEmail(orderId: string, token: string): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    return await axios
      .get(`/order/send-order/${orderId}`, requestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  /** Descarga el inventario en un formato PDF
   * @param {String} orderId Código de orden
   * @returns {object} data - Template en PDF
   */
  async getOrderPdf(orderId: string): Promise<any> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    };

    try {
      const response = await axios.get(`/order/pdf/${orderId}`, requestConfig);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**  cambia el estado de una orden
   * @param {IOrderToStatusChange} order  objeto de orden a procesar
   * @param {string} token - Token de autorización
   * @returns @AxiosResponse respuesta de axios
   */
  async changeStatusOrder(order: IOrderToStatusChange, token): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios
      .post('/order/change-status', order, requestConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
