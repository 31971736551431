import { PropsWithChildren } from 'react';

import CloseIcon from '@/ui/assets/CloseIcon';

/** Componente que  renderiza los textos en pastilla
 * @component
 */
interface TagProps {
  /** Nombre del tag */
  name: string;
  /** Función para el boton X de los textos en pastilla */
  onClose: () => void;
}

const PillText = ({ name, onClose }: PropsWithChildren<TagProps>): JSX.Element => {
  return (
    <div className='m-1 px-2 tag  d-flex flex-row  justify-content-between align-items-center'>
      <span className='text-secondary-3 p-regular-medium'>{name}</span>
      <CloseIcon classMain='btn-tag ms-1' onClick={onClose} />
    </div>
  );
};
export default PillText;
