import { PropsWithChildren, useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { useAppDispatch } from '@/ui/store/helperRedux';
import { change } from '@/ui/store/slices/managementSlice';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import UserController from '@/controllers/UserController';

import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import BackIcon from '@/ui/assets/BackIcon';
import CloseIcon from '@/ui/assets/CloseIcon';

import BackClose from '@/ui/components/BackClose';
import SignOutForm from '@/ui/components/user/SignOutForm';

import '@/ui/containers/userOptions/NavUser/style.css';
import Header from '@/ui/containers/Header';
import Footer from '@/ui/containers/Footer';
import NavUser from '@/ui/containers/userOptions/NavUser';

interface PropsUserOptions {
  /** Propiedad que muestra el modal de opciones de usuario */
  showOptionsUser?: boolean;
  /** Evento que oculta y muestra el modal */
  handleShowOptionsUser: () => void;
  /** Propiedad que define si el modal es fullscreen o no */
  fullscreen?: string | true | undefined;
}

/** Componente para la modal de opciones de usuario
 * @component
 */
const ModalNavUser = ({ showOptionsUser, handleShowOptionsUser, fullscreen = '' }: PropsWithChildren<PropsUserOptions>): JSX.Element => {
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Cambiamos el estado del header cuando entra en un estado administrativo */
  const dispatch = useAppDispatch();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Maneja un state para ocultar la ventana de cierre de modal al cargar la web    */
  const [closeModal, setCloseModal] = useState<boolean>(false);

  /** Funciones que Muestra / Oculta el modulo de confirmación cierre de modal*/
  const switchCloseModal = (): void => setCloseModal(!closeModal);

  /**  Controlador para hacer logout */
  const { doLogout } = UserController();

  /** Cierra el Formulario    */
  const closeForm = (): void => {
    setCloseModal(false);
    handleShowOptionsUser();
  };

  /** Proceso de Logout en el controlador y cierra el formulario */
  const handleLogout = (): void => {
    doLogout();
    closeForm();
    navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } });
  };

  /** Uso de los efectos del componente :
   * - Ocultar o mostrar elementos en el header
   */
  useEffect(() => {
    /** Actualiza el estado Redux del managementState al montar el componente      */
    if (isMobile) {
      dispatch(change({ value: true }));
    }

    return () => {
      /** Actualiza el estado Redux del managementState al desmontar el componente      */
      dispatch(change({ value: false }));
    };
  }, [width, maxLargeWidth, dispatch]);

  return (
    <Modal
      id='modal-nav-user'
      show={showOptionsUser}
      onHide={handleShowOptionsUser}
      fullscreen={fullscreen}
      dialogClassName='left'
      contentClassName='rounded-start min-vh-30'>
      <Modal.Body className='p-0'>
        <Container fluid className='p-0'>
          {isMobile && <Header />}
          {closeModal ? (
            <>
              <Row className='g-0 px-2'>
                <BackClose classMain='justify-content-between py-2'>
                  <BackIcon onClick={switchCloseModal} classMain='btn-icon-light' />
                  <CloseIcon onClick={closeForm} classMain='btn-icon-light' />
                </BackClose>
              </Row>
              <Col xs={12} className='pt-3 px-2 min-vh-25'>
                <SignOutForm message='¿Desea Cerrar Sesión?' submit={handleLogout} cancel={switchCloseModal} />
              </Col>
            </>
          ) : (
            <Row className='g-0'>
              <Col xs={12}>
                <NavUser
                  classBackIcon={isMobile ? '' : 'invisible'}
                  clickCloseIcon={closeForm}
                  clickSignOutIcon={switchCloseModal}
                  handleModal={handleShowOptionsUser}
                />
                {isMobile && <Footer />}
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalNavUser;
