import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { isEmpty } from 'lodash';

import useReservation from '@/ui/hooks/useReservation';
import { useCreateCartClientDetailsForm } from '@/ui/hooks/useCreateCartClientDetailsForm';

import { RootState } from '@/ui/store/store';
import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { updateOrderRedux } from '@/ui/store/slices/orderSlice';

import PaymentController from '@/controllers/PaymentController';
import CartController from '@/controllers/CartController';
import OrdersController from '@/controllers/OrdersController';

import { IWebPayCommitResponse } from '@/domain/interfaces/IPayment';
import { ICart } from '@/domain/interfaces/ICart';

import SuccessfulPayment from '@/ui/components/sales/SuccessfulPayment';
import DeclinedPayment from '@/ui/components/sales/DeclinedPayment';
import ProcessingPayment from '@/ui/components/sales/ProcessingPayment';

/** Componente que retorna la respuesta de Webpay
 * @component
 */
const TransbankResponse = (): JSX.Element => {
  /** Actualización de estado Redux */
  const dispatch = useAppDispatch();

  /** Funciones del controlador de carrito */
  const { deleteCart, updateClientInCart } = CartController();

  /** Funciones del Controlador de la orden */
  const { approveOrder, rejectOrder, cancelOrder } = OrdersController();

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /** Hook de React Router Dom que trae los parámetros de la URL */
  const [searchParams] = useSearchParams();

  /** Hook para las reservas */
  const reservationHook = useReservation();

  /** Respuesta del token de una transacción anulada */
  const nullToken: string | null = searchParams.get('TBK_TOKEN')?.toString() ?? null;

  /** Respuesta de la orden de una transacción anulada */
  const nullOrder: string | null = searchParams.get('TBK_ORDEN_COMPRA')?.toString() ?? '0';

  /** Respuesta del token de una transacción procesada (Autorizada o Rechazada por Transbank) */
  const processedToken: string = searchParams.get('token_ws')?.toString() ?? '';

  /** State del loading */
  const [loading, setLoading] = useState<boolean>(true);

  /** Respuesta de la operación Transbank */
  const [webpayResponse, setWebpayResponse] = useState<IWebPayCommitResponse | null>(null);

  /**LLamado al controlador para confirmar transacción */
  const { commitWebPayTransaction } = PaymentController();

  /** Hook para traer el objeto vacío del cliente en el carrito */
  const emptyCartClientObject = useCreateCartClientDetailsForm();

  /**Estado de respuesta de OK para la respuesta de transbank */
  const SUCCESS_STATE: boolean = webpayResponse?.status === 'AUTHORIZED';

  /**Estado de respuesta de Error para la respuesta de transbank */
  const ERROR_STATE: boolean = webpayResponse?.status === 'FAILED';

  /**Estado de respuesta de Null para la respuesta de transbank */
  const NULL_STATE: boolean = webpayResponse === null && (nullToken !== null || nullOrder !== null);

  /**Función asíncrona que confirma la transacción */
  const getCommitWebpayResponse = async () => {
    try {
      const apiResponse: IWebPayCommitResponse | null = await commitWebPayTransaction(processedToken);
      if (apiResponse) {
        setWebpayResponse(apiResponse);
      }
    } catch (error) {
      setWebpayResponse(null);
    }
  };

  /**Función que elimina la reserva y oculta el modal */
  const handleDeleteReservation = async () => {
    const isReservedDeleted: boolean = await reservationHook.deleteReservation();
    if (isReservedDeleted) {
      const updatedClientDetails = emptyCartClientObject;
      const updatedCartProducts = {
        ...cartProducts,
        clientDetails: updatedClientDetails,
      };
      updateClientInCart(updatedCartProducts.clientDetails);
    }
  };

  /** UseEffect que coloca en falso el componente después de 5 segundos */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 8000);

    return () => clearTimeout(timeoutId);
  }, []);

  /** UseEffect que se ejecuta para confirmar la transacción solo si viene un token procesado */
  useEffect(() => {
    nullToken === null && webpayResponse?.status === undefined && getCommitWebpayResponse();
  }, [webpayResponse, nullToken]);

  /** UseEffect que se ejecuta para confirmar la transacción solo si viene un token procesado */
  useEffect(() => {
    if (SUCCESS_STATE) {
      approveOrder({
        orderId: webpayResponse?.buyOrder ?? '',
        gatewayResponse: JSON.stringify(webpayResponse),
      });
      const Order: ICart = JSON.parse(localStorage.getItem('IlisPlacesOrder') ?? '');
      isEmpty(cartProducts) && !isEmpty(Order) && dispatch(updateOrderRedux(Order));
      !isEmpty(cartProducts) && dispatch(updateOrderRedux(cartProducts));
      deleteCart();
    }

    if (ERROR_STATE) {
      rejectOrder({
        orderId: webpayResponse?.buyOrder ?? '',
        gatewayResponse: JSON.stringify(webpayResponse),
      });
      handleDeleteReservation();
    }

    if (NULL_STATE) {
      if (nullOrder && nullOrder.length > 0) {
        cancelOrder({ orderId: nullOrder, gatewayResponse: null });
        handleDeleteReservation();
      }
    }
  }, [webpayResponse]);

  /**Renderizado de componentes de pago exitoso y rechazado y procesando */
  return (
    <>
      {SUCCESS_STATE ? <SuccessfulPayment orderCode={webpayResponse?.buyOrder ?? ''} /> : <DeclinedPayment />}
      {loading && <ProcessingPayment />}
    </>
  );
};

export default TransbankResponse;
