import { PropsWithChildren } from 'react';

import { Modal } from 'react-bootstrap';
import './style.css';

/**
 * Propiedades para el componente PdfModal.
 */
interface PdfModalProps {
  /**
   * La URL del archivo PDF a mostrar.
   */
  pdfUrl: string;

  /**
   * Indica si el modal debe mostrarse o no.
   */
  showModal: boolean;

  /**
   * Función para cerrar el modal.
   */
  handleClose: () => void;
}

/**
 * Componente de modal lateral que muestra un PDF.
 *
 * @component
 * @param {object} props - Las propiedades del componente.
 * @param {string} props.pdfUrl - La URL del PDF a mostrar.
 * @param {boolean} props.showModal - Indica si el modal debe mostrarse o no.
 * @param {function} props.handleClose - Función para cerrar el modal.
 * @returns {JSX.Element} El elemento JSX que representa el modal lateral.
 */
const SideModal = ({ pdfUrl, showModal, handleClose }: PropsWithChildren<PdfModalProps>): JSX.Element => {
  const iframeSrc = `${pdfUrl}?#scrollbar=0&statusbar=0&navpanes=0&toolbar=0&view=fitH&embedded=true`;

  return (
    <Modal
      show={showModal}
      fullscreen
      className='slide-modal'
      dialogClassName='slide-modal-dialog left'
      contentClassName='border-0'
      onHide={handleClose}>
      <Modal.Header closeButton className='d-flex'></Modal.Header>
      <Modal.Body className='d-flex flex-grow-1 col-fixed'>
        <iframe src={iframeSrc} title='PDF' className='w-100 h-100 border-0'></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default SideModal;
