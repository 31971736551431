import { AxiosResponse } from 'axios';

import OrdersRepository from '@/data/repositories/OrdersRepository';

import { IOrderByCodeResponse, IOrderDetailResponse, IOrderResponse, IOrderToProcess, IOrderToStatusChange } from '@/domain/interfaces/IOrder';

/** Representa el controlador que maneja los eventos de los casos de las órdenes
 * @returns {object} Funciones del controlador
 * @param {string} token - Token válido
 * @returns {Promise<IOrderResponse[]>} - Órdenes
 */
const OrdersController = () => {
  /** Instancia del Repository
   * @const {OrdersRepository}
   */
  const ordersRepository = OrdersRepository.instance;

  /** Solicita las órdenes del back
   * @returns {IOrderResponse} órdenes
   * @param {string} token - token valido
   *
   * */
  const getOrders = async (token: string = ''): Promise<IOrderResponse[] | null> => {
    try {
      const orders: AxiosResponse<IOrderResponse[]> = await ordersRepository.getOrders(token);
      const formattedOrders: IOrderResponse[] = orders.data.map((order) => {
        return { ...order, dateFormatted: order.date, timeFormatted: order.date };
      });
      return formattedOrders;
    } catch {
      return null;
    }
  };

  /** Solicita las órdenes del back en un formato detallado separándose por producto comprado
   * @returns {IOrderResponse} órdenes
   * @param {string} token - token valido
   *
   * */
  const getOrdersDetails = async (token: string = ''): Promise<IOrderDetailResponse[] | null> => {
    try {
      const orders: AxiosResponse<IOrderDetailResponse[]> = await ordersRepository.getOrdersDetails(token);
      return orders.data;
    } catch (error) {
      return null;
    }
  };

  /** Recupera los datos de la orden al enviarle su código
   * @param {string} orderCode - código de la orden
   * @returns {IOrderByCodeResponse} data - tipos de identificación en el back
   */
  const getOrdersByCode = async (orderCode: string): Promise<IOrderByCodeResponse | null> => {
    try {
      const order: AxiosResponse<IOrderByCodeResponse> = await ordersRepository.getOrdersByCode(orderCode);
      if (order.status === 204) {
        return null;
      } else {
        return order.data;
      }
    } catch (error) {
      return null;
    }
  };

  /** Aprueba las órdenes que se envíen
   * @returns {boolean} confirmación de que la orden fue aprobada
   * @param {IOrderToProcess} order - orden a procesar
   *
   * */
  const approveOrder = async (order: IOrderToProcess): Promise<boolean> => {
    let orderApproved = false;
    try {
      const result = await ordersRepository.approveOrder(order);
      orderApproved = result.status === 200;
    } catch (error) {
      orderApproved = false;
    }
    return orderApproved;
  };

  /** Aprueba las órdenes que se envíen
   * @returns {boolean} confirmación de que la orden fue aprobada
   * @param {IOrderToProcess} order - orden a procesar
   *
   * */
  const rejectOrder = async (order: IOrderToProcess): Promise<boolean> => {
    let orderRejected = false;
    try {
      const result = await ordersRepository.rejectOrder(order);
      orderRejected = result.status === 200;
    } catch (error) {
      orderRejected = false;
    }
    return orderRejected;
  };

  /** Aprueba las órdenes que se envíen
   * @returns {boolean} confirmación de que la orden fue aprobada
   * @param {IOrderToProcess} order - orden a procesar
   *
   * */
  const cancelOrder = async (order: IOrderToProcess): Promise<boolean> => {
    let orderCanceled = false;
    try {
      if (order.orderId && order.orderId != '0') {
        const result = await ordersRepository.cancelOrder(order);
        orderCanceled = result.status === 200;
      } else {
        orderCanceled = true;
      }
    } catch (error) {
      orderCanceled = false;
    }
    return orderCanceled;
  };

  const sendOrderEmail = async (orderId: string, token = ''): Promise<boolean> => {
    let emailSent = false;
    try {
      const result = await ordersRepository.sendOrderEmail(orderId, token);
      emailSent = result.status === 200;
    } catch (error) {
      emailSent = false;
    }
    return emailSent;
  };

  const getOrderPdf = async (orderId: string = ''): Promise<Blob | null> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const ordersRepository = OrdersRepository.instance;
    try {
      const result = await ordersRepository.getOrderPdf(orderId);
      /** Crea un Blob a partir del array buffer que devolvió el API */
      const blob = new Blob([result.data], { type: 'application/pdf' });
      return blob;
    } catch {
      return null;
    }
  };

  const changeStatusOrder = async (order: IOrderToStatusChange, token = ''): Promise<boolean> => {
    let emailSent = false;
    try {
      const result = await ordersRepository.changeStatusOrder(order, token);
      emailSent = result.status === 200;
    } catch (error) {
      emailSent = false;
    }
    return emailSent;
  };

  /**
   * retorna la funciones del controlador
   */
  return { getOrders, getOrdersDetails, getOrdersByCode, approveOrder, rejectOrder, cancelOrder, sendOrderEmail, getOrderPdf, changeStatusOrder };
};

export default OrdersController;
