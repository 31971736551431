const validateBulkLoadingTemplateUseCase = (product: any[] = []): boolean => {

    let validate: boolean = true;

    if (product && product.length > 0) {
        const titles = product[0];

        Object.keys(titles).forEach((key) => {
            if (String(titles[key]) !== 'undefined' && String(titles[key]) !== 'null') {
                if (String(titles[key]).trim().toLowerCase() !== key.trim().toLowerCase()) {
                    validate = false;
                }
            } else {
                validate = false;
            }
        });
    } else {
        validate = false;
    }

    return validate;
}

export default validateBulkLoadingTemplateUseCase;