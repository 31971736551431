/** Valida el formato de contraseña
  * @param {string} password
  * @returns {boolean}
*/
const usePasswordValidation = (password: string): boolean => {
    /**
      * Almacena un patron para validar formato de password
      * @const {RegExp};
    */
    const expression: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,16}$/;
    
    /** Almacena el resultado de evaluar la expresion
      * @const {boolean}
    */
    const result: boolean = expression.test(password);
  
    return result;
  }
  

const lowercaseCapitalValidation = (password: string): boolean => {
  /**
    * Almacena un patron para validar formato de password
    * @const {RegExp};
  */
  const expression: RegExp = /(?=.*[A-Z])(?=.*[a-z])/;
  
  /** Almacena el resultado de evaluar la expresion
    * @const {boolean}
  */
  const result: boolean = expression.test(password);

  return result;
}


const numberandSymbolValidation = (password: string): boolean => {
  /**
    * Almacena un patron para validar formato de password
    * @const {RegExp};
  */
  const expression: RegExp = /(?=.*[0-9])(?=.*[$@$!%*?&#.$($)$-$_])/;
  
  /** Almacena el resultado de evaluar la expresion
    * @const {boolean}
  */
  const result: boolean = expression.test(password);

  return result;
}

const lengthValidation = (password: string): boolean => {
  /** Validacion de largo de contraseña */
  return (!(password.length < 8 || password.length > 16));

}

/** Referencia a errores del password */
const refPasswordErrors = {
  errorTypeNumberAndSymbol: 'numberAndSymbol',
  errorTypeLowercaseCapitalLetters: 'lowercaseAndCapital',
  errorTypeLength: 'length',
  errorTypeEqualToEmail: 'equalToEmail',
  errorTypeEqualToLast: 'equalToLast',
  errorTypeNotTheSame: 'notTheSame',
}

export { usePasswordValidation, lengthValidation, lowercaseCapitalValidation, numberandSymbolValidation, refPasswordErrors };
