import { IMenu } from '@/domain/interfaces/IMenu';

/**
     * Busca una categoría en una estructura de categorías de navegación.
     * @param data - La estructura de categorías de navegación.
     * @param value - La URL o el Slug del producto a buscar.
     * @returns El producto encontrado, o null si no se encontró.
*/
const findCategory = (data:IMenu, value:string):IMenu | null => {
    if (data.slug === value || data.url === value || String(data.id) === String(value)) {
        return data;
    } else if (data.children && data.children.length > 0) {
        for (let child of data.children) {
            const result = findCategory(child, value);
            if (result) {
                return result;
            }
        }
    }
    return null;
}; 

export default findCategory;
