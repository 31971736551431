import { RoutesDirections } from '@/data/libraries/Routes';

import MessageLayout from '@/ui/containers/MessageLayout';

/** Componente pagina NotFound
 *@component
 */
const NotFound = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Titulo principal */
  const MAIN_TITLE = '¡Oops!, página no encontrada';
  const TITLE_DESCRIPTION = 'Verifica la URL o utiliza nuestro menú de navegación.';
  const TITLE_LINK = 'Ir a la página principal';

  return (
    <MessageLayout
      title={MAIN_TITLE}
      imgSrc={`${URL_IMAGES}/client/empty-list-img.png`}
      description={TITLE_DESCRIPTION}
      linkText={TITLE_LINK}
      route={RoutesDirections.MAIN_ROUTE}
    />
  );
};

export default NotFound;
