import PasswordUserForm from '@/ui/components/user/PasswordUserForm';

import UserOptionsContainer from '@/ui/containers/userOptions/UserOptionsContainer';

/** Componente pagina User/Info
 * @component
 */
const UserPassword = (): JSX.Element => {
  return (
    <UserOptionsContainer type='content-password'>
      <PasswordUserForm />
    </UserOptionsContainer>
  );
};

export default UserPassword;
