import { IRegistrationData } from '@/domain/interfaces/IUser';

import useEmailValidation from '@/domain/genericValidations/useEmailValidation';
import { usePasswordValidation } from '@/domain/genericValidations/usePasswordValidation';

import ClientRepository from '@/data/repositories/ClientRepository';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';


/**
 * Caso de uso de registro de nuevo cliente - Validaciones
 * @param {IRegistrationData} user - data para nuevo registro
 * @returns {IUserResponse} - usuario registrado
 */
const registerUser = async (user: IRegistrationData): Promise<IUserResponse> => {

  const validaEmail = useEmailValidation;
  const validaPassword = usePasswordValidation;
  const messageExist = 'Correo ya registrado';
  const messageErrEmail = 'Formato de email invalido';
  const messageErrPass = 'Clave invalida';


  /**
   * Instancia del Repository
   * @const {ClientRepository}
   */
  const clientRepository = ClientRepository.instance;

  let response: IUserResponse = {
    data: null,
    isError: true,
    inputError: null,
    message: []
  };

  if (!validaEmail(user.email!)) {
    response.inputError = 'email';
    response.message.push(messageErrEmail);
    return response;
  }

  user.email = user.email?.toLowerCase() ?? null;

  if (!validaPassword(user.password!)) {
    response.inputError = 'password';
    response.message.push(messageErrPass);
    return response;
  }

  /**
   * Registra el cliente
   * @const {Promise}
   */

  let errorCatch = null;
  const dataRegister: Promise<any> = await clientRepository.registerUser(user)
    .then((result) => {
      response.isError = false;
      return result.data;
    })
    .catch((error) => {
      errorCatch = error.response.status;
      //let codError = error.response.data.split(' ')[0];
      //response.message = errorsBack[codError];
      return error;
    });

  if (await errorCatch === 422) {
    response.message.push(messageExist);
    return response;
  }

  if (!response.isError) {
    response.data = await dataRegister;
  }


  return response;
}

export { registerUser };