import { useEffect, useRef, PropsWithChildren } from 'react';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { Col, Container, Row, Alert } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';
import Title from '@/ui/components/Title';

import './style.css';

interface PropsAlertModal {
  /** Propiedad que define el tipo de Alert*/
  type?: 'danger' | 'success' | 'warning' | 'info';
  /** Propiedad que define el contenido del mensaje*/
  content?: string;
  /** Propiedad que define el tiempo que se oculta el modal en (milisegundos)*/
  hide?: number;
  /** Propiedad que define el primer link*/
  link1?: string;
  /** Propiedad que define el segundo link*/
  link2?: string;
  /** Función que setea el llamado del padre a este modal en false
   *  el padre debe llamar una función con esta sintaxis:
   *  const [alert, setAlert] = useState(false);
   *  const hideAlert = () => {
   *      setAlert(false)
   *  };
   */
  hideAlert?: () => void;
}

/** Componente para alertas
 * @component
 */
const AlertModal = ({
  hideAlert,
  type = 'info',
  content = 'no content',
  hide = 1800000,
  link1 = '',
  link2 = '',
}: PropsWithChildren<PropsAlertModal>): JSX.Element => {
  /** Manejamos un estado para mostrar o eliminar el modal */
  const showAlert = true;

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  const nodeRef = useRef(null);

  useEffect((): void => {
    const load = new Promise((resolve) => setTimeout(() => resolve(hideAlert && hideAlert()), hide));
    load.then((response) => response).catch((err) => console.error(err));
  }, [hide, hideAlert]);

  if (showAlert) {
    return (
      <Container fluid className='fixed-bottom ultra-top'>
        <Col
          id='alert-modal-container'
          xs={!isMobile ? { span: 3, offset: 3 } : 12}
          className='bg-primary-4 rounded position-absolute bottom-0 end-0 my-5 shadow'>
          <Alert
            ref={nodeRef}
            onClose={() => {
              hideAlert && hideAlert();
            }}
            className='m-0 p-0'
            dismissible>
            <Container fluid className='p-0'>
              <Row className='g-0'>
                <div
                  className={`alert alert-dismissible fade show m-0 p-0 border-${type}  border-top border-5 rounded-top rounded-bottom border-start-0 border-end-0`}
                  role='alert'>
                  <Container fluid className='px-0 pb-3 bg-primary-4'>
                    <Row className='g-0'>
                      <Col xs={11}>
                        <Title>
                          <ul className='list-unstyled'>
                            <IconList
                              id={'alert-modal'}
                              iconName={
                                type === 'success' ? 'ico-success' : type === 'warning' ? 'ico-warning' : type === 'info' ? 'ico-info' : 'ico-error'
                              }
                              classTitle={`${isMobile ? 'p-regular-bold' : 'p-large-bold'}`}
                              title={type === 'success' ? '¡Exito!' : type === 'warning' ? '!Alerta!' : type === 'info' ? 'Aviso' : 'Error'}
                              classMain={`p-2 text-${type}`}
                            />
                            <p className='ps-5 text-secondary-3'>
                              <span className={`${isMobile && 'p-small-medium'}`}>{content}</span>
                            </p>
                          </ul>
                        </Title>
                      </Col>
                    </Row>
                    <Row className='g-0'>
                      <Col xs={12} className='ps-5'>
                        <span className='px-2 text-primary-2'>{link1}</span>
                        <span className='px-2 text-primary-2'>{link2}</span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Container>
          </Alert>
        </Col>
      </Container>
    );
  }
  return <></>;
};

export default AlertModal;
