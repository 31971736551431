import { PropsWithChildren, useState } from 'react';

import { subYears, addDays } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import PhoneInput from 'react-phone-input-2';
import es from 'date-fns/locale/es';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { Col, Container, Row, Form, InputGroup, Modal } from 'react-bootstrap';

import '@/ui/components/forms/form.css';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import { useAppSelector } from '@/ui/store/helperRedux';

import UserController from '@/controllers/UserController';

import { IClient } from '@/domain/interfaces/IUser';
import { IIdTypes } from '@/domain/interfaces/IIdTypes';

import dateFormat from '@/domain/utils/dateFormat';

import Icon from '@/ui/assets/Icon';
import CloseIcon from '@/ui/assets/CloseIcon';

import InputForm from '@/ui/components/forms/InputForm';
import Title from '@/ui/components/Title';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import BackClose from '@/ui/components/BackClose';

registerLocale('es', es);

/**
 * Modal que muestra toda la información a modificar del usuario
 * @component
 */

interface PropsModalUserInfo {
  /** Propiedad que muestra el modal de opciones de usuario */
  showUserInfo: boolean;
  /** Evento que oculta y muestra el modal */
  handleShowUserInfo: () => void;
  /** Propiedad que define si el modal es fullscreen o no */
  fullscreen?: string | true | undefined;
  /** Propiedad que recibe los tipos de identificación */
  idTypes?: IIdTypes[] | undefined;
  /** Propiedad que define el contenido */
  contentKey?: 'name' | 'email' | 'phone' | 'document' | 'birthdate' | string;
  /** Refresca a data del cliente en el componente padre */
  updateDataClient: () => Promise<void> | undefined;
  /** Valor para inicializar el input correspondiente */
  inputValue: string;
}

const ModalUserInfo = ({
  showUserInfo,
  handleShowUserInfo,
  contentKey = 'email',
  idTypes,
  updateDataClient,
  inputValue,
  children,
}: PropsWithChildren<PropsModalUserInfo>): JSX.Element => {
  /** Funcion para formatear una fecha a string solicitado por el back */
  const { formatDateToString, formatStringToDate } = dateFormat();

  /** Estado para inicializar el input de email */
  const [valEmail, setValEmail] = useState<string>(inputValue);

  /**
   * Estado para inicializar el input de nombres
   * Se separan nombre y apellidos y se asignan independientemente
   * */
  let firstNameValue: string = '';
  let lastNameValue: string = '';
  if (contentKey === 'name') {
    firstNameValue = inputValue.split('|')[0];
    lastNameValue = inputValue.split('|')[1];
  }
  const [firstName, setFirstName] = useState<string>(firstNameValue);
  const [lastName, setLastName] = useState<string>(lastNameValue);

  /** Estado para inicializar el input de phone */
  const [phone, setPhone] = useState<string>(inputValue);

  /**
   * Estado para inicializar el input de tipo y numero de identificacion oficial
   * Se separan tipo y numero y se asignan independientemente
   * */
  let idTypeValue: string = '';
  let idOfficialValue: string = '';
  if (contentKey === 'document') {
    idOfficialValue = inputValue.split('|')[0];
    idTypeValue = inputValue.split('|')[1];
  }
  /** Estado para inicializar el input de id oficial */
  const [idType, setIdType] = useState<string>(idTypeValue);
  /** Estado para inicializar el input de id oficial */
  const [idOfficial, setIdOfficial] = useState<string>(idOfficialValue);

  /** Almacena un date para inicializar el estado para birthday */
  let dateObject: Date = new Date();
  /** Cuando viene 'birthdate' como llave de input se establece un tipo Date de la fecha de nacimiento */
  if (contentKey === 'birthdate') {
    /** Si la fecha no es valida no se asigna al estado */
    const formattedDate = formatStringToDate(inputValue);
    if (formattedDate instanceof Date && !isNaN(formattedDate.getTime())) {
      dateObject = formattedDate;
    }
  }
  /** Estado para inicializar el input de birthday */
  const [birthDate, setBirthDate] = useState<Date>(dateObject);

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Status de usuario redux */
  const userLogued = useAppSelector((state) => state.userState);

  /** Hook useForm para manejo de formularios */
  const { handleSubmit, control, setValue, watch } = useForm<IClient>();

  /** Funcion del controlador para hacer update
   * @constant {object}
   */
  const { updateClient } = UserController();

  /** Declaración inicial de los botones como deshabilitado    */
  const [stateButton, setStateButton] = useState<boolean>(false);

  /** Declaración inicial un input en su estado normal    */
  const [inputWithError, setInputWithError] = useState<boolean>(false);
  /** Declaración inicial de los mensajes de error como vacios    */
  const [inputMessageError, setInputMessageError] = useState<string>('');

  /** Habilita un botón para avanzar a la siguiente fase*/
  const onButton = () => setStateButton(true);
  /** Deshabilita un botón para bloquear el avance a la siguiente fase*/
  const offButton = () => setStateButton(false);

  /** Cierra el Formulario    */
  const closeForm = (): void => {
    handleShowUserInfo();
  };

  /** Funcion para activar input,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Enciente el boton,
   */
  const validInput = (): void => {
    setInputMessageError('');
    setInputWithError(true);
    onButton();
  };

  /** Funcion para etiquear un input con errores
   *  Coloca los mensajes como error (en caso de existir),
   *  Muestra los mensajes,
   *  Muestra los iconos de error,
   *  Coloca el input como error,
   *  Apaga el boton,
   */
  const errorInput = (): void => {
    setInputWithError(true);
    offButton();
  };

  /**
   * Envia el formulario para validación y actualización
   * @param {IClient} values - data del formulario
   * @param {boolean} toSend - indica si es disparado por un submit, puede ser también disparado por un onChange
   * */
  const onSubmit = async (values: IClient, toSend: boolean): Promise<void> => {
    /** Modifica el formato de la fecha que se va a enviar al back */
    if (values.birthDayInput !== undefined) {
      /** Formato requerido en el back para la fecha */
      values.birthDay = formatDateToString(values.birthDayInput!);
    }

    /** Se implementa el objeto que modifica el teléfono en el back */
    if (values.phoneInput !== undefined || values.countryInputAux !== undefined) {
      /** Formato requerido en el back para el telefono */
      values.phone = {
        country: values.countryInputAux,
        number: values.phoneInputAux,
      };
    }

    /**
     * Uso del controlador useUserController con la funcion updateClient
     * @param {IClient} values - data del formulario
     * @param {string} id - id del cliente
     * @param {string} token - token
     * @param {boolean} toSend - indica si es disparado por un submit, puede ser también disparado por un onChange
     * @returns {IUserResponse}
     * */
    const request = await updateClient(values, userLogued.user.id!, userLogued.user.token!, toSend);

    if (request.isError === true) {
      errorInput();
      setInputMessageError(Array.isArray(request.message) ? request.message[0] : request.message);
    } else {
      validInput();
    }

    /** Si pasa las validaciones y posterior envio al back sin problemas y además se realizó submit
     * se actualiza la data del cliente y se cierra el formulario
     */
    if (request.isError === false && toSend) {
      /** Funcion del componente padre para refrescar la data del cliente */
      await updateDataClient();
      /** Cierra el formulario */
      closeForm();
    }
  };

  /**
   * Realiza el submit
   * @param {boolean} toSend - indica si se ejecuto un submit si es true
   *
   */
  const onSubmitWithFlag = (toSend: boolean) => {
    return (data: IClient) => onSubmit(data, toSend);
  };

  /** Se ejecuta en el onChange de los input, genera un false en toSend para no enviar el formulario */
  const handleInputChange = (): void => {
    /** Extrae la data del formulario con watch() del useForm */
    const formData = watch();
    onSubmit(formData, false);
  };

  return (
    <Modal
      id='modal-user-info'
      show={showUserInfo}
      onHide={handleShowUserInfo}
      dialogClassName={isMobile ? 'm-0' : 'modal-dialog-centered'}
      contentClassName={isMobile ? 'min-vh mt-20 border border-0 rounded-5 rounded-bottom-0 modal-slide-up' : 'min-vh modal-slide-up rounded-4'}
      size={isMobile ? 'sm' : 'lg'}>
      <Container fluid className='p-0'>
        <Row className='g-0 min-vh'>
          <Col xs={12} className='pt-2 px-4'>
            <BackClose classMain='justify-content-end py-2'>
              <CloseIcon onClick={closeForm} classMain='btn-icon-light ps-2' />
            </BackClose>
            <Col xs={12} className='text-center py-3'>
              <Title>
                <span className='text-primary-3 p-large-bold'>
                  {contentKey === 'email' && 'Ingresa tu nuevo correo electrónico'}
                  {contentKey === 'name' && 'Ingresa tus nombres y apellidos'}
                  {contentKey === 'phone' && 'Ingresa tu número de celular'}
                  {contentKey === 'document' && 'Ingresa tu número de documento de identidad'}
                  {contentKey === 'birthdate' && 'Ingresa tu fecha de nacimiento'}
                </span>
              </Title>
            </Col>
            {children}
            <Form onSubmit={handleSubmit(onSubmitWithFlag(true))}>
              <Col xs={12} md={6} className={`mx-auto pt-4 ${isMobile && 'pb-4'}`}>
                {contentKey === 'email' && (
                  <Controller
                    control={control}
                    name='email'
                    render={({ field: { ref } }) => (
                      <InputForm
                        className='mb-3'
                        inputRef={ref}
                        label='Correo Electrónico'
                        classLabel='p-regular-medium'
                        type='email'
                        onChange={(value) => {
                          setValue('email', value.target.value);
                          setValEmail(value.target.value);
                          return handleInputChange();
                        }}
                        value={valEmail}
                        withErrors={inputWithError}
                        messageError={inputMessageError}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    )}
                  />
                )}
                {contentKey === 'name' && (
                  <>
                    <Controller
                      control={control}
                      name='firstName'
                      render={({ field: { ref } }) => (
                        <InputForm
                          className='mb-3'
                          inputRef={ref}
                          label='Nombres'
                          classLabel='p-regular-medium'
                          value={firstName}
                          type='text'
                          onChange={(value) => {
                            setValue('firstName', value.target.value);
                            setValue('lastName', lastName);
                            setFirstName(value.target.value);
                            return handleInputChange();
                          }}
                          classMain={`text-capitalize`}
                          messageError={inputMessageError}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='lastName'
                      render={({ field: { ref } }) => (
                        <InputForm
                          className='mb-3'
                          inputRef={ref}
                          label='Apellidos'
                          classLabel='p-regular-medium'
                          type='text'
                          value={lastName}
                          onChange={(value) => {
                            setValue('lastName', value.target.value);
                            setValue('firstName', firstName);
                            setLastName(value.target.value);
                            return handleInputChange();
                          }}
                          classMain={`text-capitalize`}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {contentKey === 'phone' && (
                  <>
                    <div className='text-end pe-2'>
                      <span className={'text-complementary-2 text-nowrap'}>{inputMessageError}</span>
                    </div>
                    <InputGroup className={'mb-3 text-center'}>
                      <Controller
                        control={control}
                        name='phoneInput'
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={'cl'}
                            value={phone}
                            countryCodeEditable={false}
                            preferredCountries={['cl', 'co']}
                            masks={{ cl: '. .... ....', col: '... .......' }}
                            autoFormat={true}
                            inputClass={`form-control ${inputWithError ? 'form-control-error' : ''}  position-relative w-100`}
                            onChange={(phone: string, country: { country: string; countryCode: string }) => {
                              setValue('phoneInputAux', phone);
                              setValue('countryInputAux', country ? country.countryCode.toUpperCase() : '');
                              setPhone(phone);
                              return handleInputChange();
                            }}
                          />
                        )}
                      />
                      {inputWithError && (
                        <span className={'last-input-icon svg-complementary-2'}>
                          <Icon name='ico-error' />
                        </span>
                      )}
                    </InputGroup>
                  </>
                )}
                {contentKey === 'document' && (
                  <>
                    <div className='text-end pe-2'>
                      <span className={'text-complementary-2 text-nowrap'}>{inputMessageError}</span>
                    </div>
                    <Form.Group className='pb-3'>
                      <Controller
                        control={control}
                        name='idType'
                        render={({ field: { ref } }) => (
                          <Form.Select
                            aria-label='Default select example'
                            ref={ref}
                            className={`form-control ${inputMessageError ? 'form-control-error' : ''}  position-relative`}
                            value={idType}
                            onChange={(value) => {
                              setValue('idType', value.target.value);
                              setValue('identification', idOfficial);
                              setIdType(value.target.value);
                              return handleInputChange();
                            }}>
                            <option>Tipo de Documento</option>
                            {idTypes?.map((element) => {
                              return (
                                <option key={element.id} value={element.id}>
                                  {element.title}
                                </option>
                              );
                            })}
                          </Form.Select>
                        )}
                      />
                    </Form.Group>

                    <Controller
                      control={control}
                      name='identification'
                      render={({ field: { ref } }) => (
                        <InputForm
                          className='mb-3'
                          inputRef={ref}
                          label='Número de documento'
                          classLabel='p-regular-medium'
                          type='text'
                          value={idOfficial}
                          onChange={(value) => {
                            setValue('identification', value.target.value);
                            setValue('idType', idType);
                            setIdOfficial(value.target.value ?? '');
                            return handleInputChange();
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {contentKey === 'birthdate' && (
                  <InputGroup bsPrefix='text-center'>
                    <Controller
                      control={control}
                      name='birthDayInput'
                      render={({ field }) => (
                        <DatePicker
                          locale='es'
                          selected={birthDate}
                          onChange={(date) => {
                            setBirthDate(date ?? new Date());
                            field.onChange(date);
                          }}
                          className='form-control shadow'
                          showMonthDropdown
                          showYearDropdown
                          fixedHeight
                          minDate={subYears(new Date(), 100)}
                          maxDate={addDays(new Date(), 0)}
                          dropdownMode='select'
                          inline
                        />
                      )}
                    />
                  </InputGroup>
                )}
                {stateButton || contentKey === 'birthdate' ? (
                  <TwoButtonsGroup
                    firstButtonClass='btn-secondary-text-standard'
                    firstButtonText='Cancelar'
                    firstButtonClick={handleShowUserInfo}
                    secondButtonClass='btn-primary-text-standard'
                    secondButtonText='Aceptar'
                  />
                ) : null}
              </Col>
            </Form>
          </Col>
          <Col xs={12} className='align-self-end'>
            <div className={isMobile ? 'bg-primary-3 py-2 w-100 mt-2' : 'bg-primary-3 pt-2 pb-1 rounded-4 rounded-top-0 '} />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalUserInfo;
