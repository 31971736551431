import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import StatusList, { StatusCodes } from '@/data/libraries/Status';
import TypeList from '@/data/libraries/IdentificationTypes';

import { ColumnDef } from '@tanstack/react-table';
import _, { isEmpty } from 'lodash';

import useDownloadExcel from '@/ui/hooks/useDownloadExcel';

import { Button, Col, Image, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import { IOrderDetailResponse, IOrderResponse, IOrderToStatusChange } from '@/domain/interfaces/IOrder';
import { ISelectOptionType } from '@/domain/interfaces/ISelect';

import dateFormat from '@/domain/utils/dateFormat';
import currencyFormat from '@/domain/utils/currencyFormat';
import getTypeNameById from '@/domain/utils/getTypeById';
import getStatusById from '@/domain/utils/getStatusById';

import OrdersController from '@/controllers/OrdersController';

import Icon from '@/ui/assets/Icon';
import ErrorImage from '@/ui/assets/ErrorImage';

import MainTitle from '@/ui/components/MainTitle';
import TableCustom from '@/ui/components/TableCustom';
import ContentModal from '@/ui/components/modals/ContentModal';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import InputSelect from '@/ui/components/forms/InputSelect';
import GifModal from '@/ui/components/modals/GifModal';
import AlertModal from '@/ui/components/modals/AlertModal';

import ManagementOptionsContainer from '@/ui/containers/managementOptions/ManagementOptionsContainer';

/** componente de órdenes de venta */
const SalesHistory = () => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  /** Funciones de formato de fecha*/
  const { formatDateToString, formatTimeToString } = dateFormat();

  /**Controlador para obtener las órdenes */
  const { getOrders, getOrdersDetails, sendOrderEmail, changeStatusOrder } = OrdersController();

  /**Hook para la descarga del excel */
  const downloadExcelHook = useDownloadExcel();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Estado que guarda las órdenes*/
  const [orders, setOrders] = useState<IOrderResponse[]>([]);

  /** Estado para mostrar el modal de confirmación de cambio de estado */
  const [showConfirmationStatusModal, setShowConfirmationStatusModal] = useState<boolean>(false);

  /**Estado para mostrar el modal de envío de correo */
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);

  /** guarda el id de la orden almacenada en el local para cambiar el estado */
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

  /**Guarda todos los datos de la orden seleccionada  */
  const [selectedOrder, setSelectedOrder] = useState<IOrderResponse | null>(null);

  /** guarda el nuevo estado seleccionado para la orden seleccionada */
  const [selectedNewStatus, setSelectedNewStatus] = useState<number | null>(null);

  /**Modal de loading */
  const [showLoadingEmailModal, setShowLoadingEmailModal] = useState<boolean>(false);

  /** Estado inicial del modal de estado de orden */
  const [alertStatus, setAlertStatus] = useState<boolean>(false);

  /** Estado inicial del tipo de modal para el estado de orden */
  const [alertStatusType, setAlertStatusType] = useState<'danger' | 'success' | undefined>(undefined);

  /** Estado inicial del modal de alertas de envío de correo*/
  const [alertEmail, setAlertEmail] = useState<boolean>(false);

  /** Estado inicial del tipo de modal para el envío de correo */
  const [alertEmailType, setAlertEmailType] = useState<'danger' | 'success' | undefined>(undefined);

  /**ID de estados para las ordenes */
  const orderStatusIds = [
    StatusCodes.ORDER_PENDING,
    StatusCodes.ORDER_PROCESSING,
    StatusCodes.ORDER_SENT,
    StatusCodes.ORDER_DELIVERED,
    StatusCodes.ORDER_ERROR,
    StatusCodes.ORDER_CANCELED,
  ];

  /**Retorna los estados según los ID enviados */
  const statusOrder = StatusList.filter((status) => orderStatusIds.includes(status.id));

  /**Gif de imagen URL */
  const LOADING_IMAGE_URL: string = `${URL_IMAGES}/client/send-email.png`;

  /** Definición de columnas */
  const columns: ColumnDef<IOrderResponse>[] = [
    {
      /** Id de transacción */
      accessorKey: 'id',
      header: () => <div className='text-center'>{COLUMN_ID}</div>,
      cell: (info) => {
        const idClient = info.renderValue() === null || (info.renderValue() as string).length === 0 ? 'S/I' : (info.renderValue() as string);
        return (
          <span className='d-flex justify-content-center my-2 p-large-regular bg-secondary-3 text-primary-4 badge rounded-pill mx-5'>{`${idClient}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      /** Código de la transacción */
      accessorKey: 'ordCodeNum',
      header: () => <div className='text-center'>{COLUMN_ID}</div>,
      cell: (info) => {
        const idClient = info.renderValue() === null || (info.renderValue() as string).length === 0 ? 'S/I' : (info.renderValue() as string);
        return (
          <span className='d-flex justify-content-center my-2 p-large-regular bg-secondary-3 text-primary-4 badge rounded-pill mx-5'>{`${idClient}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      // Fecha de la orden
      accessorKey: 'dateFormatted',
      header: () => <div className='text-center'>{COLUMN_DATE}</div>,
      cell: (info) => {
        return (
          <span className='d-flex justify-content-center my-2 p-large-regular  text-secondary-2 w-100'>{`${formatDateToString(
            info.renderValue() as Date,
          )}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      // Hora de la orden
      accessorKey: 'timeFormatted',
      header: () => <div className='text-center'>{COLUMN_HOUR}</div>,
      cell: (info) => {
        return (
          <span className='d-flex justify-content-center p-large-regular my-2  text-secondary-2 w-100'>{`${formatTimeToString(
            info.renderValue() as Date,
          )}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      // Nombre del cliente
      accessorKey: 'customerName',
      header: () => <div className='text-center'>{COLUMN_CLIENT_NAME}</div>,
      cell: (info) => {
        const nameClient = info.renderValue() === null || (info.renderValue() as string).length === 0 ? '' : (info.renderValue() as string);
        return <span className='d-flex justify-content-start p-large-regular my-2 text-secondary-2 w-100'>{`${nameClient}`}</span>;
      },
      footer: (info) => info.column.id,
    },

    {
      // Id del cliente
      accessorKey: 'customerId.value',
      header: () => <div className='text-center'>{COLUMN_ID_CLIENT}</div>,
      cell: (info) => {
        const idClient = info.renderValue() ?? {};
        return (
          <span className='d-flex justify-content-center my-2 p-large-regular  text-secondary-2  w-100'>
            {!isEmpty(idClient) ? `${idClient}` : 'S/I'}
          </span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      // Total de la orden
      accessorKey: 'totalAmount',
      header: () => <div className='text-center'>{COLUMN_TOTAL}</div>,

      cell: (info) => {
        return (
          <span className='d-flex justify-content-end my-2 p-large-regular  text-secondary-2  w-100'>{`${currencyFormat(
            info.renderValue() as number,
            ConfigNumberParameters.DECIMALS_ALLOWED,
          )}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      // Estado de la orden
      accessorKey: 'status',
      header: () => <div className='text-center'>{COLUMN_STATE}</div>,
      cell: (info) => <StatusCell status={info.renderValue() as number} id={info.row.original.id} orderData={info.row.original} />,
      footer: (info) => info.column.id,
    },
    {
      //Envío de correo electrónico
      accessorKey: 'send',
      header: () => <div className='text-center'>{COLUMN_SEND}</div>,
      cell: (info) => {
        const disabledStatuses = [StatusCodes.ORDER_ERROR, StatusCodes.ORDER_CANCELED, StatusCodes.ORDER_DELIVERED];
        const disabled: boolean = disabledStatuses.includes(info.row.original.status ?? 0);
        return (
          <span className='d-flex justify-content-center mt-2'>
            <Icon
              name='ico-send'
              classMain={disabled ? 'svg-tertiary-1' : ''}
              onClick={disabled ? undefined : () => handleSelectOrderToSend(info.row.original)}
            />
          </span>
        );
      },
      footer: (info) => info.column.id,
    },
  ];

  /** mapeo de la lista de tipos de identificación */
  const identificationType = TypeList.find((type) => type.id === parseInt(selectedOrder?.customerId?.type ?? '0'));

  /** mapeo de las transiciones de estado */
  const transitionMap = statusOrder.reduce((accumulator, status) => {
    //se acumula en un objeto las transiciones permitidas para cada estado
    accumulator[status.id] = new Set(status.allowedTransitions);

    //se retorna el objeto con las transiciones permitidas
    return accumulator;
  }, {});

  /** Contenido de las modales  */
  const contentModalProp: JSX.Element = selectedOrder ? (
    <Row className='g-0'>
      <Col className='d-flex flex-fill py-1 justify-content-between'>
        <span className='p-large text-center text-truncate'>{`Nombre: ${selectedOrder.customerName}`}</span>
        <span className='p-large badge rounded-pill bg-secondary-3 me-2'>{selectedOrder.ordCodeNum}</span>
      </Col>
      {identificationType && <Col className='py-1 flex-fill p-large'>{`${identificationType.name}: ${selectedOrder.customerId?.value}`}</Col>}
      <Col className='flex-fill py-1 p-large'>{`Correo electrónico: ${selectedOrder.customerEmail}`}</Col>
      <Col className='flex-fill py-1 p-large'>{`Total: ${currencyFormat(selectedOrder.totalAmount, ConfigNumberParameters.DECIMALS_ALLOWED)}`}</Col>
    </Row>
  ) : (
    <></>
  );

  /** Interface para el componente que maneja la celda de los estados */
  interface PropsStatusCell {
    status: number;
    id: number;
    orderData: IOrderResponse;
  }

  /** Componente que muestra el estado de la orden */
  const StatusCell = ({ status, id, orderData }: PropsWithChildren<PropsStatusCell>) => {
    // función que se utilizará para actualizar este estado.
    const [selectedStatus, setSelectedStatus] = useState<number>(status);

    //verifica si un cambio de estado es valido dentro de el mapa de transiciones
    const isValidStatusChange = useCallback((currentStatus: number, newStatus: number): boolean => {
      //si el estado actual existe en el mapa de transiciones, se verifica si el nuevo estado es valido
      if (currentStatus === newStatus) return true;
      //se retorna si el nuevo estado es valido para el estado actual
      return transitionMap[currentStatus]?.has(newStatus);
    }, []);

    //manejador de cambio de estado
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      //se obtiene el nuevo estado seleccionado
      const newStatus: number = parseInt(event.target.value);
      //si el nuevo estado es diferente al estado actual, se actualiza el estado seleccionado y se muestra el modal
      if (newStatus !== selectedStatus) {
        setSelectedOrderId(id);
        setSelectedOrder(orderData);
        setSelectedNewStatus(newStatus);
        setShowConfirmationStatusModal(true);
      }
    };

    // Transforma la data que viene del estado de la orden en parámetros para el InputSelect
    const optionStatusOrderData = (): ISelectOptionType[] => {
      const data: ISelectOptionType[] = [];

      statusOrder.forEach((element) => {
        data.push({
          id: element.id.toString(),
          value: element.id.toString(),
          label: element.name.toUpperCase(),
          className: 'p-regular text-secondary-2',
          disabled: !isValidStatusChange(selectedStatus, element.id),
        });
      });

      return data;
    };

    //se actualiza el estado inicial si cambia el estado de la orden
    useEffect(() => {
      setSelectedStatus(status);
    }, [status]);

    return (
      <InputSelect
        value={selectedStatus.toString()}
        onChange={handleSelectChange}
        className='p-regular text-secondary-2'
        disabled={[StatusCodes.ORDER_ERROR, StatusCodes.ORDER_DELIVERED, StatusCodes.ORDER_CANCELED].includes(selectedStatus)}
        optionData={optionStatusOrderData()}
      />
    );
  };

  /**CONSTANTES DE TEXTO */
  const COLUMN_ID: string = 'N° Orden';
  const COLUMN_STATE: string = 'Estado';
  const COLUMN_TOTAL: string = 'Total';
  const COLUMN_DATE: string = 'Fecha';
  const COLUMN_HOUR: string = 'Hora';
  const COLUMN_CLIENT_NAME: string = 'Nombre Cliente';
  const COLUMN_ID_CLIENT: string = 'N° Documento';
  const COLUMN_SEND: string = 'Enviar';
  const MAIN_TITLE: string = 'Órdenes';
  const MAIN_TITLE_LABEL: string = 'Administra todas tus órdenes de compra aquí.';
  const DOWNLOAD_BUTTON_TEXT: string = 'DESCARGAR HISTÓRICO';
  const CANCEL_CONFIRMATION_TEXT: string = `¿Estás seguro que deseas cambiar el estado de esta orden a ${getStatusById(
    selectedNewStatus ?? 0,
  ).toUpperCase()}? `;
  const CHANGE_BUTTON_TEXT: string = 'CAMBIAR';
  const CANCEL_BUTTON_TEXT: string = 'CANCELAR';
  const SEND_BUTTON_TEXT: string = 'ENVIAR';
  const SEND_CONFIRMATION_TEXT: string = '¿Estás seguro que deseas enviar la información de la orden de compra al cliente?';
  const LOADING_TEXT: string = 'Enviando correo, por favor espere...';
  const SUCCESS_STATUS_MESSAGE: string = 'Estado de la orden cambiado correctamente';
  const ERROR_STATUS_MESSAGE: string = 'Error al intentar cambiar la orden, inténtalo nuevamente';
  const SUCCESS_EMAIL_MESSAGE: string = 'Correo enviado correctamente';
  const ERROR_EMAIL_MESSAGE: string = 'Error al enviar el correo, inténtalo nuevamente';
  const EXCEL_FILE_NAME: string = 'Ordenes';

  /**Función que maneja los estados de alerta del estado de la orden y su tipo */
  const handleAlertStatus = (emailSent: boolean) => {
    if (emailSent) {
      setAlertStatusType('success');
      setAlertStatus(true);
    } else {
      setAlertStatusType('danger');
      setAlertStatus(true);
    }
  };

  /**Función que maneja los estados de alerta del envío de correo y su tipo */
  const handleAlertEmail = (emailSent: boolean) => {
    if (emailSent) {
      setAlertEmailType('success');
      setAlertEmail(true);
    } else {
      setAlertEmailType('danger');
      setAlertEmail(true);
    }
  };

  /** Función que confirma el cambio de estado */
  const confirmStatusChange = () => {
    if (selectedOrderId && selectedNewStatus) {
      changeStatus({ orderId: selectedOrderId, statusId: selectedNewStatus });
    }
    setShowConfirmationStatusModal(false);
  };

  /** Función que trae las órdenes */
  const getOrdersBack = async (): Promise<void> => {
    await getOrders(userLogued.user.token ?? '').then((order: IOrderResponse[] | null) => {
      if (order !== null) {
        setOrders(order);
      }
    });
  };

  //función que envía el email de la orden seleccionada
  const sendOrder = async (): Promise<void> => {
    if (selectedOrder) {
      // Mostrar el modal de carga
      setShowSendEmailModal(false);
      setShowLoadingEmailModal(true);

      await sendOrderEmail(selectedOrder.ordCodeNum, userLogued.user.token ?? '').then((value: boolean) => {
        // Ocultar el modal de carga
        setShowLoadingEmailModal(false);
        handleAlertEmail(value);
      });
    }
  };

  const changeStatus = async (order: IOrderToStatusChange): Promise<void> => {
    if (selectedOrder) {
      // Mostrar el modal de carga
      setShowConfirmationStatusModal(false);

      await changeStatusOrder(order, userLogued.user.token ?? '').then((value: boolean) => {
        // Ocultar el modal de carga
        getOrdersBack();
        handleAlertStatus(value);
      });
    }
  };

  /** Evento que muestra y oculta el modal de envío de correo */
  const handleSelectOrderToSend = (order: IOrderResponse): void => {
    setSelectedOrder(order);
    setShowSendEmailModal(true);
  };

  /** Evento que muestra y oculta el modal de carga */
  const showLoading = (): void => {
    setShowLoadingEmailModal(!showLoadingEmailModal);
  };

  /** Función que trae las órdenes para la descarga del excel */
  const getOrdersDetailsBack = async (): Promise<void> => {
    const Excel: any[] = [];
    await getOrdersDetails(userLogued.user.token ?? '').then((order: IOrderDetailResponse[] | null) => {
      if (order !== null) {
        order.forEach((row) => {
          const transformedRow = {
            codigo_orden: row.ordCodeNum,
            nombre_cliente: row.customerName,
            tipo_id_cliente: getTypeNameById(parseInt(row.customerTypeId)),
            id_cliente: row.customerId,
            telefono_cliente: row.customerPhone,
            email_cliente: row.customerEmail,
            monto_total_orden: row.orderTotalAmount,
            estado_orden: getStatusById(row.orderStatus),
            fecha_orden: row.orderDate,
            hora_orden: row.orderTime,
            codigo_autorizacion_pago: row.authId,
            tipo_de_pago: row.payType,
            cantidad_cuotas: row.amountOfFees,
            monto_por_cuota: row.amountPaidPerFee,
            numero_tarjeta: row.cardNumber,
            estado_pago: getStatusById(row.paymentStatus),
            sku_producto: row.productSku,
            nombre_producto: row.productName,
            precio_producto: row.productPrice,
            cantidad: row.productQuantity,
            total_producto: row.productTotalAmount,
          };
          Excel.push(transformedRow);
        });
        const sortedData = _.sortBy(Excel, ['numero_orden']);
        downloadExcelHook.download(sortedData, EXCEL_FILE_NAME);
      }
    });
  };

  /** Se ejecuta cuando el usuario se loguea*/
  useEffect(() => {
    userLogued.user.token && getOrdersBack();
  }, [userLogued.user.isLogued]);

  return (
    <>
      <ManagementOptionsContainer type='sales-history'>
        <MainTitle iconName='ico-sales' boldTitle={MAIN_TITLE} detailsText={MAIN_TITLE_LABEL} />
        <Col lg={12} className='d-flex flex-row align-items-center my-3'>
          {/**<Col lg={4} className='d-flex justify-content-end align-items-center'>
                        <Col lg={12} >

                            <InputForm
                                type='text'
                                firstIconClass='first-input-icon svg-tertiary-1'
                                placeholder='Buscar'
                                firstIconName='ico-search'
                                lastIconName='ico-close'
                                value={inputValue}
                                inputRef={filterKeyWordRef}
                                lastIconClass={lastIconClass}
                                lastIconClicked={resetInput}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}


                            />
                        </Col>

                    </Col> */}

          {/*  <Col lg={2} className="px-5">
                        <IconList iconName='ico-filter' classTitle='p-large-bold' title='Filtrar' />
                    </Col>
                    <Col lg={2}>
                        <IconList
                            iconName='ico-order'
                            classTitle='p-large-medium'
                            title='Ordenar'
                        />
                    </Col> */}
          <Col lg={12}>
            <Col lg={12} className='d-flex justify-content-end align-items-center '>
              <Button className={`btn-secondary-text-small p-title-bold ${!orders.length ? 'disabled' : ''}`} onClick={() => getOrdersDetailsBack()}>
                {DOWNLOAD_BUTTON_TEXT}
              </Button>
            </Col>
          </Col>
        </Col>
        <Col className='text-break'>
          <TableCustom dataTable={orders} columns={columns} sortingState={[{ id: 'status', desc: false }]} visibilityState={{ id: false }} />
        </Col>
      </ManagementOptionsContainer>
      {/* Modal de confirmación */}
      <ContentModal
        showModal={showConfirmationStatusModal}
        handleShowModal={() => setShowConfirmationStatusModal(false)}
        message={CANCEL_CONFIRMATION_TEXT}
        content={contentModalProp}
        buttons={
          <TwoButtonsGroup
            firstButtonClass='btn-secondary-text-small'
            secondButtonClass='btn-primary-text-small'
            firstButtonText={CANCEL_BUTTON_TEXT}
            secondButtonText={CHANGE_BUTTON_TEXT}
            firstButtonClick={() => setShowConfirmationStatusModal(false)}
            secondButtonClick={confirmStatusChange}
          />
        }
      />
      {/* Modal de envío de correo */}
      <ContentModal
        showModal={showSendEmailModal}
        handleShowModal={() => setShowSendEmailModal(false)}
        message={SEND_CONFIRMATION_TEXT}
        content={contentModalProp}
        buttons={
          <TwoButtonsGroup
            firstButtonClass='btn-secondary-text-small'
            secondButtonClass='btn-primary-text-small'
            firstButtonText={CANCEL_BUTTON_TEXT}
            secondButtonText={SEND_BUTTON_TEXT}
            firstButtonClick={() => setShowSendEmailModal(false)}
            secondButtonClick={sendOrder}
          />
        }
      />
      <GifModal
        showModal={showLoadingEmailModal}
        handleShowModal={showLoading}
        image={
          <Image
            src={LOADING_IMAGE_URL}
            width={150}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{LOADING_TEXT}</span>}
      />

      {alertStatus && (
        <AlertModal
          hideAlert={() => setAlertStatus(false)}
          type={alertStatusType}
          hide={6000}
          content={alertStatusType === 'success' ? SUCCESS_STATUS_MESSAGE : ERROR_STATUS_MESSAGE}
        />
      )}
      {alertEmail && (
        <AlertModal
          hideAlert={() => setAlertEmail(false)}
          type={alertEmailType}
          hide={6000}
          content={alertEmailType === 'success' ? SUCCESS_EMAIL_MESSAGE : ERROR_EMAIL_MESSAGE}
        />
      )}
    </>
  );
};

export default SalesHistory;
