import { Col, Container, Row } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';
import IconsLibrary from '@/ui/assets/IconsLibrary';
import _ from 'lodash';
/**
 * Detalla los iconos guardados en el sitema
 * @component
 */
const Library = (): JSX.Element => {
  //se realiza un slice para no modificar el array original y se ordena por id de icono
  const LibraryOrdered = _.sortBy(IconsLibrary, 'id');

  return (
    <Container>
      <Row className='fluid'>
        {LibraryOrdered.map((icon) => (
          <Col key={icon.id} xs={4} md={2} className='text-center border border-1 p-2'>
            <p>
              <Icon name={icon.id} />
            </p>
            <p>
              <small>{icon.id}</small>
            </p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Library;
