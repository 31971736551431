import { useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';
import { ColumnDef } from '@tanstack/react-table';

import { useLocation, useNavigate } from 'react-router-dom';
import type { NavigateFunction } from 'react-router-dom';

import { Col, Image } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import InventoryController from '@/controllers/InventoryController';

import findCategory from '@/domain/utils/findCategory';

import { IBaseProductInventory } from '@/domain/interfaces/IInventory';
import { IMenu } from '@/domain/interfaces/IMenu';

import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import TableCustom from '@/ui/components/TableCustom';
import IconList from '@/ui/components/IconList';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import AlertModal from '@/ui/components/modals/AlertModal';
import MainTitle from '@/ui/components/MainTitle';

import ManagementOptionsContainer from '@/ui/containers/managementOptions/ManagementOptionsContainer';

import './style.css';

/** Componente pagina de inventario
 * @component
 */
const Inventory = () => {
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location = useLocation();

  /** Estado inicial del modal de alertas */
  const [alertSuccess, setAlertSuccess] = useState<boolean>(location.state.successAlert === true);

  /**Caso de uso que modifica el estado de la  location luego de renderizar */
  useEffect(() => {
    // Limpia el estado después de utilizarlo
    if (alertSuccess) {
      navigate(location, { state: { originURL: location.pathname, successAlert: false } });
    }
  }, []);

  /**
   * Controlador para el objeto de
   * registros seleccionados del inventario de productos base
   */
  const { getSelectedBaseProductInventory, updateSelectedBaseProductInventory, getBaseProductInventory } = InventoryController();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Prepara el state de la data de la tabla */
  const [inventoryBaseProduct, setInventoryBaseProduct] = useState<IBaseProductInventory[]>([]);

  /** Objeto de selección en LocalStorage */
  const rowsOnLocalStorage = getSelectedBaseProductInventory();

  /** Referencia al objeto de selección */
  const [rowSelection, setRowSelection] = useState({ ...rowsOnLocalStorage });

  /** Usa el controlador para traer el inventario */
  const getInventory = async (): Promise<void> => {
    await getBaseProductInventory(userLogued.user.token ?? '').then((inventory: IBaseProductInventory[] | null) => {
      if (inventory !== null) {
        /** Solo modifica el state cuando hay un cambio en el objeto,
         * esto debido a la ejecucuión doble en useEffect */
        if (JSON.stringify(inventory) !== JSON.stringify(inventoryBaseProduct)) {
          setInventoryBaseProduct(inventory);
        }
      }
    });
  };

  /**  Objeto Redux que almacena la data del menu   */
  const navCategory: IMenu = useAppSelector((state) => state.categoriesMenuState);

  /** Definición de columnas */
  const columns: ColumnDef<IBaseProductInventory>[] = [
    {
      accessorKey: 'multimedia',
      header: () => <div className='text-center'>{COLUMN_IMAGE}</div>,
      cell: (info) => {
        const dataRow: IBaseProductInventory | null | {} = info.renderValue() ?? null;

        let imageRow: string = ErrorImage;

        if (dataRow !== null && Object.keys(dataRow).length > 0) {
          if (dataRow.hasOwnProperty('images')) {
            imageRow = dataRow['images'][0] ?? ErrorImage;
          }
        }

        return (
          <Col className='text-center d-flex justify-content-center'>
            <div className='image-crud-container rounded rounded-3'>
              <Image
                className='rounded rounded-3 image-full'
                src={`${imageRow}`}
                alt={''}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = ErrorImage;
                }}
              />
            </div>
          </Col>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'id',
      header: () => <div className='text-center'>{COLUMN_ID}</div>,
      cell: (info) => {
        return (
          <span className='p-large-regular bg-secondary-5 badge badge-pill text-secondary-2 text-center w-100 my-2'>{`${info.renderValue()}`}</span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'name',
      header: () => COLUMN_NAME,
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'category',
      header: () => COLUMN_CATEGORY,
      cell: ({ row }) => {
        // Buscar la categoría actual basada en la URL actual
        const category: IMenu | null = findCategory(navCategory, row.getValue('category'));
        return category && category.icon && <IconList iconName={category.icon} classTitle='svg-secondary-2 p-large' title={category.title} />;
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'numOfVariants',
      header: () => COLUMN_VARIANTS,
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'stock',
      header: () => COLUMN_TOTAL,
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'status',
      header: () => COLUMN_STATE,
      cell: (info) => {
        const value = info.renderValue() === 'ACTIVE' ? STATE_ACTIVE : STATE_INACTIVE;
        const valueClass = info.renderValue() === 'ACTIVE' ? 'text-complementary-1' : 'text-complementary-2';
        return <span className={`${valueClass} p-large-medium`}>{value}</span>;
      },
      footer: (info) => info.column.id,
    },
    // {
    //   accessorKey: 'actions',
    //   header: () => <div className='text-center'>{COLUMN_ACTIONS}</div>,
    //   cell: ({ row }) => {
    //     return (
    //       <span className='icon-hover text-center'>
    //         <Icon
    //           name='ico-edit'
    //           classMain='svg-secondary-2 d-flex justify-content-center'
    //         />
    //       </span>
    //     );
    //   },
    // },
    {
      accessorKey: 'select',
      header: () => <div className='text-center'>{COLUMN_SELECTION}</div>,
      cell: ({ row }) => {
        return (
          <span className='icon-selection text-center'>
            <Icon name='ico-selection' classMain='svg-tertiary-5 d-flex justify-content-center' />
          </span>
        );
      },
    },
  ];

  /**
   * Manejador de click al seleccionar una fila
   */
  const updateRowSelectionRef = (selectionObject: { [index: number]: string }) => {
    /** Actualiza la referencia local del objeto de selección */
    setRowSelection(JSON.parse(JSON.stringify(selectionObject)));

    /** Actualiza LocalStorage */
    updateSelectedBaseProductInventory(JSON.parse(JSON.stringify(selectionObject)));
  };

  useEffect(() => {
    /** Solicita el inventario */
    userLogued.user.isLogued && getInventory();
  }, [userLogued.user.isLogued]);

  /**CONSTANTES DE TEXTO */

  const COLUMN_ID: string = 'ID';
  const COLUMN_IMAGE: string = 'Imagen';
  const COLUMN_NAME: string = 'Nombre';
  const COLUMN_CATEGORY: string = 'Categoría';
  const COLUMN_STATE: string = 'Estado';
  const COLUMN_VARIANTS: string = 'Variantes';
  const COLUMN_TOTAL: string = 'Cantidad';
  const COLUMN_ACTIONS: string = 'Acciones';
  const COLUMN_SELECTION: string = 'Seleccionar';

  const STATE_ACTIVE: string = 'ACTIVO';
  const STATE_INACTIVE: string = 'INACTIVO';

  const MAIN_TITLE: string = 'Inventario';
  const MAIN_TITLE_LABEL: string = 'Consulta, crea o modifica tus productos o servicios.';

  const ADD_PRODUCT_BUTTON_TEXT: string = 'AGREGAR PRODUCTO';
  const MASSIVE_CHARGE_BUTTON_TEXT: string = 'CARGA MASIVA';

  const MASSIVE_CHARGE_ROUTE: string = RoutesDirections.MASSIVE_CHARGE_ROUTE;
  const ADD_PRODUCT_ROUTE: string = RoutesDirections.ADD_PRODUCTS_FORM_WIZARD_ROUTE;

  const SUCCESS_ALERT_MESSAGE: string = '¡Producto Agregado con Éxito!';

  return (
    <>
      <ManagementOptionsContainer type='management-inventory'>
        <MainTitle boldTitle={MAIN_TITLE} detailsText={MAIN_TITLE_LABEL} />
        <Col xs={5} className='my-3 ms-auto'>
          <TwoButtonsGroup
            firstButtonText={MASSIVE_CHARGE_BUTTON_TEXT}
            firstButtonClass='btn-secondary-text-small'
            firstButtonClick={() => navigate(MASSIVE_CHARGE_ROUTE)}
            secondButtonText={ADD_PRODUCT_BUTTON_TEXT}
            secondButtonClass='btn-primary-text-small'
            secondButtonClick={() => navigate(ADD_PRODUCT_ROUTE)}
          />
        </Col>
        <Col className='text-break'>
          <TableCustom
            dataTable={inventoryBaseProduct}
            columns={columns}
            sortingState={[{ id: 'id', desc: true }]}
            visibilityState={{ id: false, select: false }}
            rowSelection={rowSelection}
            updateRowSelection={updateRowSelectionRef}
          />
        </Col>
      </ManagementOptionsContainer>
      {alertSuccess && <AlertModal hideAlert={() => setAlertSuccess(false)} type='success' hide={1500} content={SUCCESS_ALERT_MESSAGE} />}
    </>
  );
};

export default Inventory;
