import { PropsWithChildren } from 'react';

import MenuButton from '@/ui/containers/Header/MenuButton';

/** Componente menu Login
 * @component
 */
interface PropsLoginMenu {
  /** Evento que oculta y muestra el modal de opciones de usuario */
  handleShowOptionsUser: () => void;
}

const LoginMenu = ({ handleShowOptionsUser }: PropsWithChildren<PropsLoginMenu>): JSX.Element => {
  return <MenuButton handleShowOptionsUser={handleShowOptionsUser} />;
};

export default LoginMenu;
