import { useState } from 'react';
import { NavLink, Location, useLocation } from 'react-router-dom';
import { useAppSelector } from '@/ui/store/helperRedux';

import { IMenu } from '@/domain/interfaces/IMenu';

import Submenu from '@/ui/containers/Header/SubMenu';

/** Componente menu principal
 * @component
 */
const Menu = () => {
  /**  Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Objeto Redux que almacena la data del menu  */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);

  /** Estado inicial que llama el submenu */
  const [submenu, setSubmenu] = useState(false);

  /** Estado inicial que llama el ID del submenu */
  const [submenuId, setSubmenuId] = useState('');

  /** Oculta el menu */
  const hideSubmenu = () => {
    setSubmenu(false);
  };

  /** Maneja el Submenu segun el id entregado*/
  const handleSubmenu = (id) => {
    if (submenu && submenuId === id) {
      setSubmenu(false);
    } else {
      setSubmenu(true);
      setSubmenuId(id);
    }
  };

  return (
    <div id='menu-main ultra-top'>
      <nav className='menu'>
        <ul className='list-inline m-0 px-2 d-flex text-primary-4 pb-1'>
          {navMenu?.map((item) => (
            <div key={item.id}>
              {item.url === undefined || '#' ? (
                <li className='list-inline-item px-1 btn-link-text-small' onClick={() => handleSubmenu(`${item.slug}`)}>
                  <span className='text-reset p-large-medium px-2 mx-1 '>{item.title}</span>
                </li>
              ) : (
                <NavLink to={item.url} state={{ originURL: location.pathname }} onClick={() => handleSubmenu(`${item.slug}`)}>
                  <li className='list-inline-item px-1 btn-link-text-small'>
                    <span className='text-reset p-large-medium px-2 mx-1 '>{item.title}</span>
                  </li>
                </NavLink>
              )}
            </div>
          ))}
        </ul>
      </nav>
      {submenu && <Submenu keySubmenu={submenuId} hideSubmenu={hideSubmenu} />}
    </div>
  );
};

export default Menu;
