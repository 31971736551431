import { ALPHANUMERIC_WITHOUT_SPECIAL_CHARACTER } from '@/data/libraries/ConfigParameters';

import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { clean, validate } from 'rut.js';

import { IClientDetail } from '@/domain/interfaces/ICart';

import useEmailValidation from '@/domain/genericValidations/useEmailValidation';
import useNameValidation from '@/domain/genericValidations/useNameValidation';
import validatePattern from '@/domain/utils/validatePattern';

export interface IClientDetailsValidation {
  // Campo a evaluar
  error: boolean;
  field: 'FIRST_NAME' | 'LAST_NAME' | 'DOCUMENT_ID' | 'EMAIL' | 'PHONE';
  // Mensaje de error
  message: string;
}
/**
 * Caso de uso de detalle de clientes en el carrito - Validaciones
 * @param {IClientDetails} client - data para nuevo registro
 * @returns {IClientDetailsValidation[]} - Errores de validación
 */
const validateClientDetailsUseCase = async (client: IClientDetail): Promise<IClientDetailsValidation[]> => {
  /** Objeto de respuesta con todos los errores */
  const errors: IClientDetailsValidation[] = [];

  /** Hooks de validaciones genericas */
  const validationEmail = useEmailValidation;
  const validationNames = useNameValidation;

  const REQUIRED_FIELD_ERROR: string = 'Campo requerido';
  const FORMAT_ERROR: string = 'Formato Inválido';

  /** Inicio Validación Nombre */
  if (client.firstName === '') {
    errors.push({
      error: true,
      field: 'FIRST_NAME',
      message: REQUIRED_FIELD_ERROR,
    });
  } else if (!validationNames(client.firstName)) {
    errors.push({ error: true, field: 'FIRST_NAME', message: FORMAT_ERROR });
  }

  /** Fin Validación Nombre */

  /** Inicio Validación Apellido */

  if (client.lastName === '') {
    errors.push({
      error: true,
      field: 'LAST_NAME',
      message: REQUIRED_FIELD_ERROR,
    });
  } else if (!validationNames(client.lastName)) {
    errors.push({ error: true, field: 'LAST_NAME', message: FORMAT_ERROR });
  }

  /** Fin Validación Apellido */

  /** Inicio Validación Tipo de Documento */
  if (client.identificationId.type !== '' && client.identificationId.value === '') {
    errors.push({
      error: true,
      field: 'DOCUMENT_ID',
      message: REQUIRED_FIELD_ERROR,
    });
  } else if (client.identificationId.type === '0' && client.identificationId.value !== '') {
    errors.push({
      error: true,
      field: 'DOCUMENT_ID',
      message: REQUIRED_FIELD_ERROR + 2,
    });
  } else if (client.identificationId.type === '1' && (!validate(client.identificationId.value) || clean(client.identificationId.value).length < 8)) {
    errors.push({ error: true, field: 'DOCUMENT_ID', message: FORMAT_ERROR });
  } else if (
    (client.identificationId.type === '2' || client.identificationId.type === '3') &&
    !validatePattern(ALPHANUMERIC_WITHOUT_SPECIAL_CHARACTER, client.identificationId.value)
  ) {
    errors.push({ error: true, field: 'DOCUMENT_ID', message: FORMAT_ERROR });
  }
  //validar que el rut no exceda los 9 digitos
  if (client.identificationId.type === '1' && clean(client.identificationId.value).length > 9) {
    errors.push({ error: true, field: 'DOCUMENT_ID', message: FORMAT_ERROR });
  }

  /** Fin Validación Tipo de Documento */

  /** Inicio Validación Email */

  if (client.email === '') {
    errors.push({ error: true, field: 'EMAIL', message: REQUIRED_FIELD_ERROR });
  } else if (!validationEmail(client.email)) {
    errors.push({ error: true, field: 'EMAIL', message: FORMAT_ERROR });
  }

  /** Fin Validación Email */

  /** Inicio Validación Telefono */

  if (!client.phone.number || client.phone.number === '' || !client.phone.country || client.phone.country === '') {
    errors.push({ error: true, field: 'PHONE', message: REQUIRED_FIELD_ERROR });
  } else {
    if ((client.phone.country === 'CL' && client.phone.number.length - 2 !== 9) || !client.phone.number.startsWith('569')) {
      errors.push({ error: true, field: 'PHONE', message: FORMAT_ERROR });
    } else {
      const parsedNumber = parsePhoneNumber(client.phone.number, client.phone.country as CountryCode);

      if (!parsedNumber.isValid()) {
        errors.push({ error: true, field: 'PHONE', message: FORMAT_ERROR });
      }
    }
  }

  /** Fin Validación Telefono */

  return errors;
};

export default validateClientDetailsUseCase;
