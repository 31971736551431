import axios, { AxiosResponse } from 'axios';
import config from '@/data/services/api';

/** Representa la interacción con el Back */
export default class AttributesRepository {
  /** Instancia de la clase   */
  private static _instance: AttributesRepository;

  /** Patron Singleton
   * @returns {instance} _instance AttributesRepository
   */
  static get instance(): AttributesRepository {
    if (!this._instance) {
      this._instance = new AttributesRepository();
    }
    return this._instance;
  }

  /** Solicita los atributos por categoría asociada
   * @param {string} token - token valido
   * @param {number} categoryId - id de la catgoría
   * @returns {object} data - atributos
   */
  async getAttributesByCategory(token: string = '', categoryId: number): Promise<AxiosResponse> {
    config.headers!['Authorization'] = `Bearer ${token}`;
    return await axios.get(`/attributes/${categoryId}`, config);
  }
}
