import { IMenu } from '@/domain/interfaces/IMenu';

/**
     * Función que busca la categoría padre de una categoría en base a su slug.
     * @param data El array de categorías a buscar.
     * @param childSlug El slug de la categoría hija.
     * @returns La categoría padre encontrada o null si no se encuentra.
*/
const getParentCategory = (data: IMenu[], childSlug: string): IMenu | null => {
    let parentCategory: IMenu | null = null;

    const findParent = (category: IMenu, slug: string) => {
        if (category.children) {
            for (let i = 0; i < category.children.length; i++) {
            const child = category.children[i];
            if (child.slug === slug) {
                parentCategory = category;
                break;
            } else {
                findParent(child, slug);
            }
            }
        }
        }

        findParent(data[0], childSlug);

        return parentCategory;
}

export default getParentCategory;


