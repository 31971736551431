import { useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavLink, useLocation, useNavigate, type NavigateFunction, Location } from 'react-router-dom';

import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Row, Col, Image } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import MenuController from '@/controllers/MenuController';

import Languages from '@/ui/assets/Languages';

import NotificationsWidget from '@/ui/components/NotificationsWidget';
import FavoritesWidget from '@/ui/components/product/FavoritesWidget';
import CartWidget from '@/ui/components/sales/CartWidget';
import ModalNavUser from '@/ui/containers/userOptions/ModalNavUser';
import CartModal from '@/ui/components/modals/CartModal';
import SearchInput from '@/ui/components/store/SearchInput';

import LoginMenu from '@/ui/containers/Header/LoginMenu';
import Menu from '@/ui/containers/Header/Menu';

import './style.css';

/** Componente contenedor del header
 * @component
 *
 */
const Header = (): JSX.Element => {
  /** Llamado del nav desde el controlador */
  const { mainMenu } = MenuController();

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Logo */
  const LOGO: string = `${URL_IMAGES}/client/logo-header.png`;

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Traemos la data el usuario para mostrarla */
  const userLogued = useAppSelector((state) => state.userState);

  /** Constante que define si el usuario está logueado */
  const isUserLogued: boolean = userLogued.user.isLogued ?? false;

  /** Traemos el estado redux del header para identificar si el modulo es administrativo o general */
  const managementState = useAppSelector((state) => state.managementState);

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Estado inicial del modal opciones de usuario */
  const [showOptionsUser, setShowOptionsUser] = useState(false);

  /** Estado inicial del modal de guía */
  const [showCartModal, setShowCartModal] = useState<boolean>(false);

  /** Evento que muestra y oculta el modal de opciones de usuario */
  const handleShowOptionsUser = () => setShowOptionsUser(!showOptionsUser);

  /** Promesa que toma los valores que renderiza el controlador de menu */
  const getMenu = async (): Promise<void> => {
    try {
      await mainMenu();
    } catch (err) {
      console.error(err);
    }
  };

  /** Función que muestra el modal del carrito */
  const handleShowCartModal = (): void => {
    if (location.pathname === RoutesDirections.CART_ROUTE || location.pathname === RoutesDirections.CHECK_OUT_ROUTE) return;
    setShowCartModal(!showCartModal);
  };

  /** UseEffect para renderizado de Menu */
  useEffect(() => {
    getMenu();
  }, [location.pathname]);

  /** Renderizado del componente */
  return (
    <Container fluid id='header' className={`bg-primary-3 sticky-top p-0 m-0 ${!isMobile ? 'px-5 pt-1 ' : 'pt-1 pb-2'}`}>
      <Container className='p-0'>
        <Row id={`${!isMobile ? 'header-desktop' : 'header-mobile'}`} className=' g-0 d-flex align-items-center justify-content-center '>
          {!isMobile ? (
            <Row>
              <Col lg={12} className='d-flex align-items-center px-4'>
                <Col>
                  <Row className='align-items-center g-0 '>
                    <Col lg={3} className='d-flex justify-content-start pb-2'>
                      <NavLink to={RoutesDirections.MAIN_ROUTE} state={{ originURL: location.pathname }}>
                        <Image
                          id='logo'
                          src={LOGO}
                          alt='logo'
                          className='logo-header'
                          onClick={() => navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } })}
                        />
                      </NavLink>
                    </Col>
                    <Col lg={5}>
                      <SearchInput />
                    </Col>

                    <Col lg={4} id='header-items' className=' d-flex justify-content-end align-items-center'>
                      <Menu />
                      <ul className='list-inline m-0 '>
                        {/* <li className='list-inline-item px-2 svg-link-dark'>
                      <FavoritesWidget inHeader={true}/>
                    </li> */}
                        <li className='list-inline-item px-2 pe-4 svg-link-dark '>
                          <button className='cursor-hand btn border-0' onClick={handleShowCartModal}>
                            <CartWidget />
                          </button>
                        </li>
                        <CartModal showModal={showCartModal} handleClose={handleShowCartModal} />
                        {isUserLogued && (
                          <li className='list-inline-item px-2 '>
                            <LoginMenu handleShowOptionsUser={handleShowOptionsUser} />
                          </li>
                        )}
                        {/* {userLogued.user.isLogued &&
                      <li className='list-inline-item px-2 svg-link-dark'>
                        <NotificationsWidget/>
                      </li>
                    } */}
                      </ul>
                    </Col>
                  </Row>
                </Col>

                {/* <Col lg={3}>
              <Row className='align-items-center g-0 '>
                 <Col xs={1} className='text-center'>
                  <Languages />
                </Col> 
              </Row>
            </Col> */}
              </Col>
            </Row>
          ) : (
            <>
              <Col xs={6} className={`${managementState.value ? 'pt-3' : ''} px-2`}>
                <NavLink to={RoutesDirections.MAIN_ROUTE} state={{ originURL: location.pathname }}>
                  <Image
                    id='logo'
                    src={LOGO}
                    alt='logo'
                    onClick={() => navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } })}
                    className='logo-header'
                  />
                </NavLink>
              </Col>
              <Col xs={6} className='d-flex justify-content-end'>
                <Menu />
              </Col>
              {!managementState.value && (
                <>
                  {/* <Col id="header-items" xs={6} className="text-end">
                  <LoginMenu handleShowOptionsUser={handleShowOptionsUser} />
                </Col>
                <Col xs={2} className="text-center">
                  <Languages />
                </Col> */}
                  <Col xs={12} className='px-2 pt-1'>
                    <SearchInput />
                  </Col>
                </>
              )}
            </>
          )}
          {showOptionsUser && (
            <ModalNavUser
              showOptionsUser={showOptionsUser}
              handleShowOptionsUser={handleShowOptionsUser}
              fullscreen={width <= maxLargeWidth ? true : ''}
            />
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default Header;
