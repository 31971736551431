import { createRoot } from 'react-dom/client';

import App from '@/ui/routes/App';

/**
 * Almacena la representacion del objeto div donde se renderiza REACT
 * @constant {HTMLDivElement}
 */
const container = document.getElementById('root')!;
/**
 * Establece el root para la aplicacion
 */
const root = createRoot(container);
/**
 * Renderiza la aplicacion
 */
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
