import { PropsWithChildren } from 'react';
import { NavLink, Location, useLocation } from 'react-router-dom';
import { useAppSelector } from '@/ui/store/helperRedux';

import { IMenu } from '@/domain/interfaces/IMenu';

import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Row, Col } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';

/**
 * Representa las listas de los submenus segun una llave entregada
 * @component
 */

type PropsListSubmenu = {
  /** Valor que renderiza el submenu segun su valor   */
  keySubmenu: string;
  /** Oculta submenu */
  hideSubmenu?: any;
};

const ListSubmenu = ({ keySubmenu, hideSubmenu }: PropsWithChildren<PropsListSubmenu>) => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido     */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Objeto Redux que almacena la data del menu  */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);

  /** Trae el id como objeto del menú a donde se esta accediendo */
  const currentMenu = navMenu.find((menuItem) => menuItem.slug === keySubmenu);

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  return (
    <Container id='submenu-list' fluid className='p-0'>
      <Row className='g-0'>
        {currentMenu && isMobile ? (
          <Col xs={12}>
            <Row>
              {currentMenu.children?.map((child, index) => {
                const isLastChild = currentMenu.children && index === currentMenu.children.length - 1;
                return (
                  <Col xs={12} className='px-3' key={child.id}>
                    <ul
                      className={`list-unstyled pt-2 pb-3 border border-3 border-top-0 border-start-0 border-end-0 ${
                        isLastChild && 'border-bottom-0'
                      }`}>
                      <span className='text-secondary-3 p-large-bold'>{child.title}</span>
                      {child?.children?.map((list) => (
                        <div key={list.id}>
                          <NavLink to={list.url} state={{ originURL: location.pathname }}>
                            <IconList
                              classMain='fw-light py-2 btn-list-link-icon-light-small'
                              iconName={list.icon}
                              classTitle='p-regular'
                              title={list.title}
                              onClick={hideSubmenu}
                            />
                          </NavLink>
                        </div>
                      ))}
                    </ul>
                  </Col>
                );
              })}
            </Row>
          </Col>
        ) : (
          currentMenu &&
          !isMobile && (
            <Row className=' d-flex align-items-stretch '>
              {currentMenu.children?.map((child, index) => {
                const isLastChild = currentMenu.children && index === currentMenu.children.length - 1;
                return (
                  currentMenu.children && (
                    <Col
                      xs={12}
                      key={child.id}
                      className={`my-3 border border-secondary-5 border-3 border-top-0 border-start-0 border-end-0 ${
                        isLastChild && 'border-bottom-0'
                      }`}>
                      <ul className='list-unstyled text-start'>
                        <Row>
                          {child?.children?.map((list) => (
                            <Col lg={3} className='py-2 px-0' key={list.id}>
                              <Col lg={7} className='d-flex justify-content-center w-100'>
                                <NavLink to={list.url} state={{ originURL: location.pathname }}>
                                  <IconList
                                    iconName={list.icon}
                                    classMain='p-2 btn-list-link-icon-light-small'
                                    title={list.title}
                                    onClick={hideSubmenu}
                                  />
                                </NavLink>
                              </Col>
                            </Col>
                          ))}
                        </Row>
                      </ul>
                    </Col>
                  )
                );
              })}
            </Row>
          )
        )}
      </Row>
    </Container>
  );
};

export default ListSubmenu;
