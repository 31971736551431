import { useRef } from 'react';

import { Col, Row, Image, Container } from 'react-bootstrap';

import generateNumberId from '@/domain/utils/generateNumberId';

import CloseIcon from '@/ui/assets/CloseIcon';
import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import GuideTip from '@/ui/components/GuideTip';

/** Propiedades del componente */
interface UrlPresenterProps {
  /** Link de la ur */
  urlInfo?: string;
  /** Tipo de archivo a renderizar */
  type: 'MEDIA' | 'PDF';
  /** Arreglo de Urls */
  urlArray?: string[];
  /** Cierre de elemento */
  onClose?: () => void;
  /** Évento eliminar */
  onClickErase?: (index: number) => void;
}

/** Componente que  muestra la información  post insertar URL*/
const UrlPresenter = ({ urlInfo, urlArray, onClose, onClickErase, type }: UrlPresenterProps): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const ICO_PDF_DEFAULT: string = `${URL_IMAGES}/static/ico-pdf.png`;

  const containerRef = useRef<HTMLDivElement>(null);
  let startX: number;
  let startY: number;
  let isDown = false;

  /** Funcion que se ejecuta al tener el mouse sobre el container */
  const handleMouseDown = (e) => {
    isDown = true;
    if (containerRef.current) {
      startX = e.pageX - containerRef.current.offsetLeft;
      startY = e.pageY - containerRef.current.offsetTop;
      containerRef.current.style.cursor = 'grabbing';
    }
  };

  /** Funcion que se ejecuta al sacar el mouse del container */
  const handleMouseLeave = () => {
    isDown = false;
    if (containerRef.current) {
      containerRef.current.style.cursor = 'auto';
    }
  };

  /** Funcion que se ejecuta al mover con scroll el mouse dentro del container */
  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    if (containerRef.current) {
      const x = e.pageX - containerRef.current.offsetLeft;
      const y = e.pageY - containerRef.current.offsetTop;
      const walkX = x - startX;
      const walkY = y - startY;
      containerRef.current.scrollLeft -= walkX;
      containerRef.current.scrollTop -= walkY;
      startX = x;
      startY = y;
    }
  };

  /** Funcion que se ejecuta al soltar el scroll del mouse sobre el container */
  const handleMouseUp = () => {
    isDown = false;
    if (containerRef.current) {
      containerRef.current.style.cursor = 'auto';
    }
  };

  /** Funcion que se ejecuta al darle clic al boton de continuar > */
  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100;
    }
  };

  /** Funcion que se ejecuta al darle clic al boton de continuar < */
  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100;
    }
  };

  const MAIN_IMAGE_TEXT: string = 'Portada';

  /** Componente de imagen dentro del UrlPresenter */
  const ImageContainer = (url: string): JSX.Element => {
    return (
      <>
        {type === 'MEDIA' ? (
          <div className={'image-thumbnail-container'}>
            <Image
              className={'image-full rounded rounded-3  shadow'}
              width={'120px'}
              src={url}
              alt={``}
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </div>
        ) : (
          <div>
            <Image
              className={'image-full'}
              width={'120px'}
              src={`${type === 'PDF' ? ICO_PDF_DEFAULT : ''}`}
              alt={``}
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </div>
        )}
      </>
    );
  };

  return urlArray ? (
    <>
      {urlArray.length > 0 && (
        <Container fluid className='p-0 position-relative'>
          <Row className={`g-0 ${urlArray.length > 0 && 'px-5'}`}>
            <Col
              lg={12}
              className='d-flex justify-content-start col-scrollable scrollable-x cursor-hand py-1  position-relative'
              ref={containerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
              {urlArray.map((element, index) => (
                <div key={`url-${generateNumberId()}`} className='position-relative mx-2'>
                  <GuideTip position='bottom' message={element} delay={{ show: 0, hide: 0 }}>
                    {ImageContainer(element)}
                  </GuideTip>
                  {index === 0 && (
                    <div className='d-flex justify-content-center'>
                      <span className=' py-1 px-3 rounded rounded-4 mb-2 position-absolute bottom-0  bg-black-transparent  text-primary-4'>
                        {MAIN_IMAGE_TEXT}
                      </span>
                    </div>
                  )}
                  <CloseIcon
                    classMain='position-absolute top-0 end-0 text-end me-1 mt-1 btn-icon-light bg-black-transparent svg-primary-4 d-flex justify-content-center pt-1'
                    {...(onClickErase ? { onClick: () => onClickErase(index) } : { onClick: onClose })}
                  />
                </div>
              ))}
            </Col>
          </Row>
          {type === 'MEDIA' && urlArray.length > 0 && (
            <>
              <Icon
                name='ico-left-arrow'
                onClick={handleScrollLeft}
                classMain='cursor-hand position-absolute top-50 bg-primary-4 svg-primary-3 btn-icon-light text-center'
              />
              <Icon
                name='ico-right-arrow'
                onClick={handleScrollRight}
                classMain='cursor-hand  position-absolute top-50 end-0 bg-primary-4 svg-primary-3 btn-icon-light text-center'
              />
            </>
          )}
        </Container>
      )}
    </>
  ) : (
    <Col lg={12}>
      <Row className='m-1 px-1 py-2 bg-tertiary-5 bg-border-tertiary-5 input-presenter-border d-flex align-items-center '>
        <Col sm={2} className='d-flex justify-content-center'>
          {ImageContainer(urlInfo ?? '')}
        </Col>
        <Col sm={9}>
          <span className='p-regular-medium text-tertiary-3 d-flex justify-content-start text-truncate'>{urlInfo ?? ''}</span>
        </Col>
        <Col sm={1}>
          <CloseIcon classMain='btn-tag ms-1' {...(onClickErase ? { onClick: onClickErase } : { onClick: onClose })} />
        </Col>
      </Row>
    </Col>
  );
};

export default UrlPresenter;
