/**
     * Obtenemos la url y nombre temporal de un tipo File.
     * @param file - Archivo.
     * @returns el objeto con la url y el nombre del archivo.
*/
const getFileURL = (file: File): { url: string; fileName: string } => {
    const url = URL.createObjectURL(file);

    /** Extrae el nombre del archivo de la URL */
    const fileName = url.split('/').pop();

    return { url, fileName: fileName || '' };
};

export default getFileURL;
