import React, { useEffect, useRef, useState } from 'react';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import { Col, Container, Row } from 'react-bootstrap';

import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';
import ProductsController from '@/controllers/ProductsController';
import TaxesController from '@/controllers/TaxesController';
import { useAppSelector } from '@/ui/store/helperRedux';

import priceFormat from '@/domain/utils/priceFormat';
import getFileURL from '@/domain/utils/getFileURL';
import { validateURLs } from '@/domain/utils/validateURLs';

import { IProductForm, IVariantForm } from '@/domain/interfaces/IProduct';
import { ITaxes } from '@/domain/interfaces/ITaxes';
import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IPathCategoryType } from '@/domain/interfaces/ICategories';

import InputForm from '@/ui/components/forms/InputForm';
import SwitchCheckbox from '@/ui/components/forms/SwitchCheckbox';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import IconList from '@/ui/components/IconList';
import DragAndDrop from '@/ui/components/DragAndDrop';
import Thumbnail from '@/ui/components/formWizard/Thumbnail';
import DropDownCustom from '@/ui/components/forms/DropDownCustom';
import UrlPresenter from '@/ui/components/formWizard/UrlPresenter';
import AlertModal from '@/ui/components/modals/AlertModal';
import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import MainTitle from '@/ui/components/MainTitle';

/** Propiedades del componente */
interface PropsAddVariant {
  /**titulo de la variante de producto*/
  variantProductTitle: string;
  /**titulo tab*/
  mainTitle: string;
  /**titulo de la clasificación*/
  classificationLabelText: string;
  /**titulo del botón cancelar*/
  cancelButtonText: string;
  /**titulo del botón siguiente*/
  nextButtonText: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Objeto que representa la categoría referenciada */
  pathCategoryState: IPathCategoryType[];
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

/** Componente que Agrega los datos básicos de la variante principal (Paso 4 del Form Wizard) */
const AddVariant = ({
  mainTitle,
  variantProductTitle,
  classificationLabelText,
  cancelButtonText,
  nextButtonText,
  formRef,
  pathCategoryState,
  updateFormRef,
  handleNextButton,
  handleCancelButton,
}: PropsAddVariant): JSX.Element => {
  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Estado para habilitar el botón de siguiente paso */
  const [isAValidData, setIsAValidData] = useState<boolean>(false);

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Estado inicial del modal de alertas */
  const [alert, setAlert] = useState<boolean>(false);

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /** Referencia al input sku */
  const skuRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Declaración inicial input SKU en su estado normal    */
  const [skuInputWithError, setSkuInputWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de SKU  */
  const [skuMessageError, setSkuMessageError] = useState<string>('');

  /** Estado del valor del switch 'Estado' */
  const [statusSwitch, setStatusSwitch] = useState<boolean>(false);

  /** Referencia al input status */
  const statusRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Ref que almacena media adjunta */
  const mediaProductsAttached = useRef<File[]>([]);

  /** Ref que almacena las url de media cargada en input text */
  const [mediaProductsUrl, setMediaProductsUrl] = useState<string[]>([]);

  /** Estado que hará visible o no el carrusel */
  const [showMediaProducts, setShowMediaProducts] = useState<boolean>(false);

  /** Estado que muestra el contador de archivos */
  const [mediaProductsCounter, setMediaProductsCounter] = useState<number>(0);

  /** Estado que almacena los items del carrusel */
  const [arrayProductItems, setArrayProductItems] = useState<JSX.Element[]>([]);

  /** Referencia al input url */
  const urlMediaProductsRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Declaración inicial input de url en su estado normal    */
  const [urlMediaProductsWithError, setUrlMediaProductsWithError] = useState<boolean>(false);
  /** Declaración inicial del mensajes de error de URL  */
  const [urlMediaProductsMessageError, setUrlMediaProductsMessageError] = useState<string>('');

  /** State input Url disabled */
  const [disabledUrlMediaProductsState, setDisabledUrlMediaProductsState] = useState<boolean>(false);

  /** State para las galerías */
  const [errorMediaProductsState, setErrorMediaProductsState] = useState<boolean>(false);

  /** Referencia al input precio */
  const priceRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Declaración inicial input precio en su estado normal    */
  const [priceInputWithError, setPriceInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de error como vacíos  */
  const [messageErrorPrice, setMessageErrorPrice] = useState<string>('');

  /** Referencia al input precio oferta */
  const offerPriceRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Declaración inicial input precio oferta en su estado normal    */
  const [offerInputWithError, setOfferInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de error como vacíos  */
  const [messageErrorOfferPrice, setMessageErrorOfferPrice] = useState<string>('');

  /** Estado del valor del switch 'exento' */
  const [nonTaxableSwitch, setNonTaxableSwitch] = useState<boolean>(false);

  /** Declaración inicial input tax en su estado normal    */
  const [taxInputWithError, setTaxInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de tax de error como vacíos  */
  const [messageErrorTax, setMessageErrorTax] = useState<string>('');

  /** state que almacena las referencias de los dropdowns de impuestos */
  const [dropDownRefs, setDropDownRefs] = useState<React.RefObject<HTMLSelectElement>[]>([]);

  /** Slate para los valores de los dropdowns. */
  const [taxValues, setTaxValues] = useState<number[]>([]);

  /** Controlador de impuestos */
  const { getTaxes } = TaxesController();

  /** Data de los impuestos disponibles en el sistema */
  const [taxesArray, setTaxesArray] = useState<ITaxes[]>([]);

  /** Clase para el link de agregar impuestos */
  const [classLinkNewTax, setClassLinkNewTax] = useState<string>('link-icon-standard');

  /** Data de los impuestos disponibles en el sistema */
  const getTaxesFromController = async () => {
    const taxes: ITaxes[] = await getTaxes();
    setTaxesArray(taxes);
  };

  /** Al cambiar el switch de estado */
  const handleChangeStatus = (): void => {
    setStatusSwitch(!statusSwitch);
  };

  /** Onchange Input SKU */
  const handleSku = (e: React.ChangeEvent<HTMLInputElement>): void => {
    skuRef.current!.value = skuRef.current ? e.target.value.toUpperCase() : '';
    //Valida al cambiar el sku
    handleValidate();
  };

  /**Función que transforma y guarda la URL que se procesa */
  const handleUrlMediaProductsChange = (value: string) => {
    const trimmedValue = value.trim();
    const isAValidMedia = validateURLs(trimmedValue);

    if (trimmedValue.length > 0) {
      if (isAValidMedia) {
        if (!(mediaProductsAttached.current.length + mediaProductsUrl.length + 1 > 10)) {
          setUrlMediaProductsWithError(false);
          setUrlMediaProductsMessageError('');

          const mediasUrl: string[] = mediaProductsUrl;
          mediasUrl.push(trimmedValue);
          setMediaProductsUrl(mediasUrl);

          urlMediaProductsRef.current!.value = '';
          incrementMediaProductsCounter(1);
        }
      } else {
        setUrlMediaProductsWithError(true);
        setUrlMediaProductsMessageError('Url con formato errado, Ejemplo: (https://foo.com/bar)');
      }
    } else {
      setUrlMediaProductsWithError(false);
      setUrlMediaProductsMessageError('');
    }

    handleValidate();
  };

  /** Función que desenfoca el input cuando se presiona enter */
  const handleUrlMediaProductsKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const value: string = urlMediaProductsRef.current?.value ?? '';
      handleUrlMediaProductsChange(value);
    }
  };

  /**Función que se ejecuta al pegar un valor en el input de la galería */
  const handleUrlMediaProductsPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto
    const pastedData = e.clipboardData.getData('text/plain');
    handleUrlMediaProductsChange(pastedData);
  };

  /**handle para limpiar el input de url*/
  const handleCleanURLMediaProducts = () => {
    const value: string = urlMediaProductsRef.current?.value.trim() ?? '';
    if (value.length === 0) {
      setUrlMediaProductsWithError(false);
      setUrlMediaProductsMessageError('');
    }
  };

  /** Maneja el evento de arrastrar archivo en el componente DragAndDrop */
  const handleDropMediaProductsFile = (attachedMedia: File[]): void => {
    if (attachedMedia.length > 0) {
      handleAttachedMediaProductsFile(attachedMedia);
    }
  };

  /** Maneja el evento al cambiar del input */
  const handleMediaProductsFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // Guarda los archivos
    if (e.target.files && e.target.files.length > 0) {
      const files: File[] = [...e.target.files];
      handleAttachedMediaProductsFile(files);
    }
  };

  /** Guarda los archivos que se adjuntan */
  const handleAttachedMediaProductsFile = (attachedMedia: File[]): void => {
    // Guarda los archivos
    if (attachedMedia.length > 0) {
      const files: File[] = mediaProductsAttached.current;

      // No se permiten más de 10 elementos de media
      if (files.length + mediaProductsUrl.length + attachedMedia.length > 10) {
        setAlert(true);
      } else {
        // Actualiza los archivos adjuntos
        mediaProductsAttached.current = files.concat(attachedMedia);
        // Habilita el carrousel
        setShowMediaProducts(true);
        // Actualiza el contador
        incrementMediaProductsCounter(attachedMedia.length);
      }
    }
    handleValidate();
  };

  /** Función eliminar imágenes adjuntas */
  const eraseMediaProductsFile = (index: number): void => {
    const mediaArray: File[] = mediaProductsAttached.current;
    // Eliminamos el elemento
    mediaArray.splice(index, 1);
    // Actualizamos el objeto
    mediaProductsAttached.current = mediaArray;
    // Actualiza el contador
    decrementMediaProductsCounter();
    // Redefinimos los elementos del carrusel
    setArrayProductItems(getCarouselMediaProducts());
    // Si no hay más imágenes se activa el DragAndDrop grande
    mediaArray.length === 0 && setShowMediaProducts(false);
    // Valida que la galería no este vacía
    handleValidate();
  };

  /** Función eliminar imágenes adjuntas por URL */
  const eraseMediaProductsUrl = (index: number): void => {
    const mediaArray: string[] = mediaProductsUrl;
    // Eliminamos el elemento
    mediaArray.splice(index, 1);
    // Actualizamos el objeto
    setMediaProductsUrl(mediaArray);
    // Actualiza el contador
    decrementMediaProductsCounter();
    // Actualiza el state de items del carrusel
    setArrayProductItems(getCarouselMediaProducts());
    // Valida que la galería no este vacía
    handleValidate();
  };

  /**Incrementa el contador */
  const incrementMediaProductsCounter = (counter: number): void => {
    setMediaProductsCounter((prevCount) => prevCount + counter);
    handleValidate();
  };

  /**Disminuye el contador */
  const decrementMediaProductsCounter = (): void => {
    setMediaProductsCounter((prevCount) => Math.max(0, prevCount - 1));
    handleValidate();
  };

  /** Modifica elementos del carrusel (arrayItems) a partir del state mediaProductsAttached */
  const getCarouselMediaProducts = (): JSX.Element[] => {
    const items: JSX.Element[] = [];

    mediaProductsAttached.current.forEach((item, index) => {
      items.push(
        <div className='p-2' key={index}>
          <Thumbnail mainImg={index === 0} imageSrc={getFileURL(item).url} onClickErase={() => eraseMediaProductsFile(index)} />
        </div>,
      );
    });

    if (mediaProductsAttached.current.length + mediaProductsUrl.length < 10) {
      items.push(
        <Col className='p-2' key={mediaProductsAttached.current.length}>
          <DragAndDrop
            fileType='media'
            size='120px'
            classMain='h-100'
            errorMessage='El archivo que intentas cargar no es un archivo tipo media.'
            handleFileChange={handleMediaProductsFileChange}
            externalHandleDrop={handleDropMediaProductsFile}
            thumbnail={true}
            enabledButtons={false}
          />
        </Col>,
      );
    }

    return items;
  };

  /** Onchange Input precio */
  const handlePrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    priceRef.current!.value = priceRef.current ? priceFormat(e.target.value) : '';
    handleValidate();
  };

  /** Onchange Input precio oferta */
  const handleOfferPrice = (e: React.ChangeEvent<HTMLInputElement>): void => {
    offerPriceRef.current!.value = priceRef.current ? priceFormat(e.target.value) : '';
    handleValidate();
  };

  /** Al cambiar el switch de estado de exento */
  const handleNonTaxableChange = (): void => {
    setNonTaxableSwitch(!nonTaxableSwitch);
  };

  /** Función que limpia las tags */
  const handleCleanTaxes = (): void => {
    setTaxValues([]);
    setDropDownRefs([]);
  };

  /** Evento desactivar el link de agregar impuestos  */
  const disableLink = (): void => {
    setClassLinkNewTax('link-icon-standard disabled');
  };

  /** Evento activar el link de agregar impuestos  */
  const activeLink = (): void => {
    setClassLinkNewTax('link-icon-standard');
  };

  /** Agrega un nuevo componente dropdown para impuestos */
  const addDropDownTax = (): void => {
    const taxValuesInfo: number[] = [...taxValues];
    const dropdownInfo: React.RefObject<HTMLSelectElement>[] = [...dropDownRefs];

    // No se pueden crear mas dropdowns que la cantidad de impuestos que existen en el sistema
    if (dropdownInfo.length < taxesArray.length) {
      // Crea una nueva referencia para el dropdown
      const newRef: React.RefObject<HTMLSelectElement> = React.createRef();

      // Actualiza el State de los valores de los dropdowns
      taxValuesInfo.push(0);
      setTaxValues(taxValuesInfo);

      // Actualiza el state que renderiza los dropdowns con la nueva referencia
      dropdownInfo.push(newRef);
      setDropDownRefs(dropdownInfo);

      // Habilita/deshabilita el link para crear más impuestos
      if (dropdownInfo.length < taxesArray.length) {
        activeLink();
      } else {
        disableLink();
      }
    } else {
      // Deshabilita el link para crear más impuestos
      disableLink();
    }
  };

  /** Maneja el onChange de los dropdowns  */
  const handleTaxChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number): void => {
    // Obtiene el valor seleccionado
    const selectedValue: number = isNaN(parseInt(e.currentTarget.value)) ? 0 : parseInt(e.currentTarget.value);

    const updatedValues: number[] = [...taxValues];
    const exist: number | undefined = updatedValues.find((val) => val === selectedValue);

    // Si el impuesto fue seleccionado en otro dropdown no se permite su selección
    if (exist === undefined || !isNaN(selectedValue)) {
      updatedValues[index] = selectedValue;
    } else {
      updatedValues[index] = 0;
    }

    //Actualiza el state de los valores de los dropdowns
    setTaxValues(updatedValues);
  };

  /** Crea los dropdowns iniciales cuando el formulario tiene ya guardados impuestos */
  const createInitialDropdownsTax = (taxesInfo: number[]): void => {
    // Si existe data se crean los dropdowns
    if (taxesInfo.length > 0) {
      // Aseguramos no tener dropdowns
      handleCleanTaxes();

      // Actualizamos los objetos de los dropdowns con la data entregada
      const taxValuesInfo: number[] = [...taxValues];
      const dropdownInfo: React.RefObject<HTMLSelectElement>[] = [...dropDownRefs];
      taxesInfo.forEach((taxValue) => {
        // Crea una nueva referencia para el dropdown
        const newRef: React.RefObject<HTMLSelectElement> = React.createRef();
        // Asigna el valor
        taxValuesInfo.push(taxValue);
        // Asigna la referencia
        dropdownInfo.push(newRef);
      });
      setTaxValues(taxValuesInfo);
      setDropDownRefs(dropdownInfo);
    } else {
      // Si no existe data se crea un dropdown sin seleccionar
      addDropDownTax();
    }
  };

  /** Al eliminar un impuesto*/
  const handleDeleteTax = (position: number): void => {
    /** Trae la data actual de los dropdowns */
    const dropdownInfo: React.RefObject<HTMLSelectElement>[] = [...dropDownRefs];
    const taxValuesInfo: number[] = [...taxValues];

    /** Si solo hay un dropdown se limpia */
    if (dropdownInfo.length === 1) {
      /** Actualiza los states */
      setTaxValues([0]);
    } else {
      /** Elimina la data del impuesto  */
      dropdownInfo.splice(position, 1);
      taxValuesInfo.splice(position, 1);

      /** Actualiza los states */
      setTaxValues(taxValuesInfo);
      setDropDownRefs(dropdownInfo);
    }

    activeLink();
  };

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    // Establece el formulario a actualizar
    const formProduct: IProductForm = formRef.current ? JSON.parse(JSON.stringify(formRef.current)) : { ...productBaseFormClean };
    formProduct.status = statusRef.current?.checked ? 1 : 2;

    // Formatea los precios */
    let priceValue: string = priceRef.current?.value.replace(/[^\d,]/g, '') ?? '';
    priceValue = priceValue.replace(',', '.');
    let offerPriceValue: string = offerPriceRef.current?.value.replace(/[^\d,]/g, '') ?? '';
    offerPriceValue = offerPriceValue.replace(',', '.');

    // Unifica las URL de los adjuntos por input file o input text en un solo arreglo
    //Eliminar la función Replace cuando se agregue la funcionalidad del repositorio
    const carouselMedia: string[] = mediaProductsAttached.current.map(
      (itemFile) => getFileURL(itemFile).url.split('blob:')[1].replace('localhost', 'ilisplace.cl') ?? '',
    );

    const mediaProductsUrlValues: string[] = mediaProductsUrl.length >= 0 ? [...mediaProductsUrl] : formProduct.variants[0].media;
    const allMedia: string[] = carouselMedia.concat(mediaProductsUrlValues);

    // Evita los valores vacíos de posibles dropdowns de impuestos sin seleccionar
    const taxesInfo: number[] = taxValues.filter((tax) => tax !== 0);

    const mainVariant: IVariantForm = formRef.current?.variants.length
      ? JSON.parse(JSON.stringify(formRef.current!.variants[0]))
      : JSON.parse(JSON.stringify(productBaseFormClean.variants[0]));
    // Construye la data de la variante
    mainVariant.sku = skuRef.current?.value.trim() ?? '';
    mainVariant.price = Number(priceValue);
    mainVariant.offerPrice = offerPriceValue === '' ? null : Number(offerPriceValue);
    mainVariant.status = statusRef.current?.checked ? 1 : 2;
    mainVariant.media = allMedia;
    mainVariant.non_taxable = nonTaxableSwitch;
    mainVariant.taxes = [...taxesInfo];
    mainVariant.baseProductName = formRef.current?.name.trim() ?? '';
    mainVariant.attributes = JSON.parse(JSON.stringify(formRef.current!.variants[0].attributes)) ?? [];
    mainVariant.urlGuide = formRef.current?.variants[0].urlGuide ?? '';

    // Actualiza el objeto del formulario
    formProduct.variants = [{ ...mainVariant }];

    // Valida desde el controlador
    await addProductBase(formProduct, false, 'mainVariant', userLogued.user?.token ?? '')
      .then((response: IProductValidation) => {
        // Si hay error se muestra

        // Error de SKU
        if (response.error && response.kindError?.skuError && response.kindError?.skuError !== '') {
          setSkuInputWithError(true);
          setSkuMessageError(response.kindError.skuError);
        } else {
          setSkuInputWithError(false);
          setSkuMessageError('');
        }

        // Error de precio
        if (response.error && response.kindError?.priceError && response.kindError?.priceError !== '') {
          setPriceInputWithError(true);
          setMessageErrorPrice(response.kindError.priceError);
        } else {
          setPriceInputWithError(false);
          setMessageErrorPrice('');
        }

        // Error de precio oferta
        if (response.error && response.kindError?.offerPriceError && response.kindError?.offerPriceError !== '') {
          setOfferInputWithError(true);
          setMessageErrorOfferPrice(response.kindError.offerPriceError);
        } else {
          setOfferInputWithError(false);
          setMessageErrorOfferPrice('');
        }

        // Error de galería
        if (response.error && response.kindError?.galleryError && response.kindError?.galleryError !== '') {
          setErrorMediaProductsState(true);
          setUrlMediaProductsWithError(true);
        } else {
          setErrorMediaProductsState(false);
          setUrlMediaProductsWithError(false);
        }

        // Error de impuestos
        if (response.error && response.kindError?.taxError && response.kindError?.taxError !== '') {
          setTaxInputWithError(true);
          setMessageErrorTax(response.kindError.taxError);
        } else {
          setTaxInputWithError(false);
          setMessageErrorTax('');
        }

        // Si se solicitó el objeto del formulario se actualiza
        if (!response.error) {
          setIsAValidData(true);
          send && updateFormRef(formProduct);
        } else {
          setIsAValidData(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /** Evento que envía el formulario cuando se presiona el botón */
  const handleSendInfo = async (): Promise<void> => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /**UseEffect para activar o desactivar la carga de URLs según el máximo requerido */
  useEffect((): void => {
    // Actualiza el state de items del carrusel
    setArrayProductItems(getCarouselMediaProducts());
    if (mediaProductsUrl.length + mediaProductsAttached.current.length > 9) {
      setDisabledUrlMediaProductsState(true);
    } else {
      setDisabledUrlMediaProductsState(false);
    }
    if (mediaProductsCounter > 0) {
      setErrorMediaProductsState(false);
    }
  }, [mediaProductsUrl, mediaProductsCounter]);

  /** UseEffect que limpia las tags si el producto es exento de IVA */
  useEffect(() => {
    if (nonTaxableSwitch) {
      handleCleanTaxes();
    } else {
      if (dropDownRefs.length === 0) {
        addDropDownTax();
      }
    }
  }, [nonTaxableSwitch, taxesArray]);

  /** UseEffect para renderizar los valores si ya los tiene */
  useEffect((): void => {
    getTaxesFromController();
    if (formRef.current && formRef.current?.variants.length > 0) {
      skuRef.current!.value = formRef.current?.variants[0].sku ?? '';
      priceRef.current!.value = priceFormat(String(formRef.current?.variants[0].price).replace('.', ',')) ?? '';
      offerPriceRef.current!.value = priceFormat(String(formRef.current?.variants[0].offerPrice).replace('.', ',')) ?? '';
      setStatusSwitch(formRef.current.variants[0].status === 1 ? true : false);

      const savedMedia: string[] = formRef.current?.variants[0].media.length ? [...formRef.current?.variants[0].media] : [];
      setMediaProductsUrl([...savedMedia]);
      setMediaProductsCounter(savedMedia.length);

      /** Renderiza los impuestos */
      setNonTaxableSwitch(formRef.current?.variants[0].non_taxable ?? false);
      const taxesInfo: number[] =
        !formRef.current?.variants[0].non_taxable && formRef.current?.variants[0].taxes?.length ? [...formRef.current?.variants[0].taxes] : [];
      if (taxesInfo.length > 0) {
        createInitialDropdownsTax(taxesInfo);
      } else {
        setErrorMediaProductsState(false);
        dropDownRefs.length === 0 && addDropDownTax();
      }

      handleValidate();
    } else {
      /** Muestra el dropdown inicial de impuestos */
      addDropDownTax();
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleValidate();
  }, [mediaProductsUrl, taxValues, nonTaxableSwitch]);

  /**CONSTANTES DE TEXTO */

  const REQUIRED_TEXT: string = '(Obligatorio)';

  const ADD_VARIANT_GENERAL_DESCRIPTION: string =
    'Agrega las imágenes de la variante del producto, asignale un precio base y uno de oferta en caso de que aplique, y define los impuestos que apliquen.';
  const SKU_LABEL: string = 'SKU';

  const STATE_LABEL: string = 'Estado';
  const STATE_ACTIVE: string = 'Activo';
  const STATE_INACTIVE: string = 'Inactivo';

  const GALLERY_MEDIA_LABEL: string = `Portada y multimedia ${REQUIRED_TEXT}`;
  const GALLERY_URL_MEDIA_TYPE_ERROR: string = 'El archivo que intentas cargar no es un archivo tipo media.';
  const GALLERY_MEDIA_ADD: string = 'Agregados: ';
  const GALLERY_MEDIA_MAX_RANGE: string = '10';
  const GALLERY_MEDIA_RANGE_ERROR: string = `¡Solo se pueden adjuntar ${GALLERY_MEDIA_MAX_RANGE} elementos!`;

  const GALLERY_MEDIA_URL_LABEL: string = 'URL de imagen';
  const GALLERY_MEDIA_URL_DESCRIPTION: string =
    'Solo se admiten enlaces públicos de imágenes en este campo de texto, presiona "Enter" o "Tab" para guardar la acción';

  const PRICE_LABEL: string = `Precio base ${REQUIRED_TEXT}`;

  const OFFER_PRICE_LABEL: string = 'Precio oferta';

  const TAX_LABEL: string = 'Impuestos';
  const TAX_DESCRIPTION_1: string = 'Agregue los impuestos que apliquen al producto.';
  const TAX_DESCRIPTION_2: string = 'Si este producto está Exento de impuestos,';
  const TAX_DESCRIPTION_3: string = 'por favor, habilite la opción correspondiente utilizando el siguiente interruptor';
  const TAX_BOX_LABEL: string = 'Impuesto';
  const TAX_NON_TAXABLE_DESCRIPTION: string = 'Producto exento de impuestos';
  const ADD_TAX_LABEL: string = 'Agregar otro impuesto';

  const URL_INFO_TEXT_1: string = errorMediaProductsState ? 'Debe haber al menos 1 foto en la galería.' : ''; //'Selecciona primero la foto portada de tu producto. ';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <MainTitle boldTitle={mainTitle} detailsText={ADD_VARIANT_GENERAL_DESCRIPTION} />
        <Col lg={12} className='mb-3'>
          <span className='p-title-medium  me-2'>{variantProductTitle}</span>
          <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
        </Col>
        <span className='p-regular-medium'>{classificationLabelText}</span>
        <Col lg={12} className='bg-secondary-4 border border-1 p-2 mb-4 rounded rounded-2'>
          <CategoryPath pathState={pathCategoryState} referenced />
        </Col>
        <Col lg={12}>
          <InputForm
            className='mb-3'
            inputRef={skuRef}
            label={SKU_LABEL}
            onChange={handleSku}
            classLabel='p-regular-medium'
            type='text'
            withErrors={skuInputWithError}
            messageError={skuMessageError}
          />
        </Col>
        <Col lg={12} className='py-2'>
          <SwitchCheckbox
            inputRef={statusRef}
            checked={statusSwitch}
            classMain='py-2 px-0'
            label={STATE_LABEL}
            type='switch'
            showValue={true}
            value={!statusSwitch ? STATE_INACTIVE : STATE_ACTIVE}
            classSpan={`p-regular-bold ${statusSwitch ? 'text-complementary-1' : 'text-complementary-2'}`}
            onChange={handleChangeStatus}
          />
        </Col>
        <Col lg={12}>
          <Col lg={12} className='mb-2'>
            <span className='p-large-bold text-primary-3'>{GALLERY_MEDIA_LABEL}</span>
          </Col>
          {/* {!showMedia ?
            (
              <DragAndDrop
                fileType='media'
                size='140px'
                errorStatus={errorMediaProductsState}
                errorMessage={GALLERY_URL_MEDIA_TYPE_ERROR}
                handleFileChange={handleFileChange}
                externalHandleDrop={handleDropMediaProductsFile}
                enabledButtons={false}
              />
            )
            :
            <ScrollingCarousel>
              {arrayItems}
            </ScrollingCarousel>
          } */}
        </Col>

        <UrlPresenter type='MEDIA' urlArray={mediaProductsUrl} onClickErase={eraseMediaProductsUrl} />

        <Col lg={12}>
          <InputForm
            inputRef={urlMediaProductsRef}
            disabled={disabledUrlMediaProductsState}
            label={GALLERY_MEDIA_URL_LABEL}
            classLabel='p-regular-medium'
            withErrors={urlMediaProductsWithError}
            messageError={urlMediaProductsMessageError}
            onChange={handleCleanURLMediaProducts}
            onKeyDown={handleUrlMediaProductsKeyDown}
            onPaste={handleUrlMediaProductsPaste}
            type='text'
          />
          <Col lg={12} className='mb-4'>
            <Row className='g-0 d-flex align-items-center'>
              <Col lg={2}>
                <span className={`p-regular-medium ${errorMediaProductsState && 'text-complementary-2'}`}>
                  {`${GALLERY_MEDIA_ADD} ${mediaProductsCounter}/${GALLERY_MEDIA_MAX_RANGE}`}
                </span>
              </Col>
              <Col>
                <IconList
                  classMain='fw-light py-2 svg-18'
                  classIcon={errorMediaProductsState ? 'svg-complementary-2' : ''}
                  iconName={errorMediaProductsState ? 'ico-error' : ''} //'ico-info'
                  classTitle={`p-regular-medium ${errorMediaProductsState && 'text-complementary-2'}`}
                  title={URL_INFO_TEXT_1}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} className='mb-4'>
            <p className='p-regular text-tertiary-1'>{GALLERY_MEDIA_URL_DESCRIPTION}</p>
          </Col>
        </Col>
        <Col lg={12}>
          <InputForm
            className='mb-3'
            inputRef={priceRef}
            label={PRICE_LABEL}
            classLabel='p-regular-medium'
            withErrors={priceInputWithError}
            messageError={messageErrorPrice}
            firstIconClass='first-input-icon svg-tertiary-1 svg-18'
            firstIconName='ico-dollar'
            onChange={handlePrice}
            type='text'
          />
        </Col>
        <Col lg={12}>
          <InputForm
            className='mb-3'
            inputRef={offerPriceRef}
            label={OFFER_PRICE_LABEL}
            classLabel='p-regular-medium'
            withErrors={offerInputWithError}
            messageError={messageErrorOfferPrice}
            firstIconClass='first-input-icon svg-tertiary-1 svg-18'
            firstIconName='ico-dollar'
            onChange={handleOfferPrice}
            type='text'
          />
        </Col>

        <Col lg={12} className='mt-2'>
          <p className='p-large-bold text-primary-3'>{TAX_LABEL}</p>
        </Col>
        <Container fluid className='d-flex p-0 m-0 '>
          <Col lg={11} className='mt-2 mb-4'>
            <span className='p-title-medium text-secondary-3'>
              {TAX_DESCRIPTION_1}
              <span className='p-title-bold ms-1'>{TAX_DESCRIPTION_2}</span>
              <span className='p-title-medium ms-1'>{TAX_DESCRIPTION_3}</span>
            </span>
          </Col>
          <Col lg={1} className='py-2 d-flex justify-content-end'>
            <SwitchCheckbox checked={nonTaxableSwitch} classMain='py-2 px-0' type='switch' showValue={true} onChange={handleNonTaxableChange} />
          </Col>
        </Container>
        {!nonTaxableSwitch ? (
          <Col lg={12}>
            {dropDownRefs.map((dropDownref, index) => {
              return (
                <DropDownCustom
                  key={index}
                  classMain={`form-control ${taxInputWithError ? 'form-control-error' : ''}`}
                  messageError={messageErrorTax}
                  position={index}
                  arrayData={taxesArray}
                  refDropDown={dropDownref}
                  label={index === 0 ? `${TAX_BOX_LABEL} ${REQUIRED_TEXT}` : `${TAX_BOX_LABEL} ${index + 1}`}
                  classLabel='p-large-bold'
                  value={taxValues.length > 0 ? taxValues[index].toString() : ''}
                  onChange={(e) => handleTaxChange(e, index)}
                  handleReset={handleDeleteTax}
                  selectedItems={taxValues}
                />
              );
            })}
          </Col>
        ) : (
          <Col lg={12} className='mb-3 d-flex justify-content-center border border-primary-5 shadow '>
            <span className='fw-bold h5 text-bold text-secondary-3 my-4'>{TAX_NON_TAXABLE_DESCRIPTION}</span>
          </Col>
        )}
        <Row className='d-flex g-0'>
          <Col lg={6} className='d-flex flex-wrap align-content-center'>
            {!nonTaxableSwitch && (
              <IconList iconName='ico-more' classMain={classLinkNewTax} classTitle='p-title-medium' title={ADD_TAX_LABEL} onClick={addDropDownTax} />
            )}
          </Col>

          <Col lg={6}>
            <TwoButtonsGroup
              firstButtonText={cancelButtonText}
              secondButtonText={nextButtonText}
              firstButtonClass='btn-secondary-text-standard'
              firstButtonClick={handleCancelButton}
              secondButtonClass={`btn-primary-text-standard ${(!isAValidData || !isActive) && 'disabled'}`}
              secondButtonClick={handleSendInfo}
            />
          </Col>
        </Row>

        {alert && <AlertModal hideAlert={() => setAlert(false)} type='danger' hide={5000} content={GALLERY_MEDIA_RANGE_ERROR} />}
      </Col>
    </Row>
  );
};

export default AddVariant;
