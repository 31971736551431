import { PropsWithChildren } from 'react';
import { Col, Modal } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';

interface PropsGifModal {
  /** Propiedad que muestra el modal */
  showModal: boolean;
  /** Evento que oculta y muestra el modal */
  handleShowModal: () => void;
  /** Mensaje del modal */
  message: JSX.Element;
  /** GIF o Imagen para modal */
  image?: React.ReactElement<HTMLImageElement>;
  /** Función que ejecuta el modal */
  buttons?: JSX.Element | null;
  className?: string;

  /** Mostrar o no el icono de cerrar */
  showCloseIcon?: boolean;
}

/**
 * Modal que muestra Gif o imagen junto con el contenido
 * @component
 */
const GifModal = ({
  showModal,
  handleShowModal,
  image,
  message,
  buttons,
  className,
  showCloseIcon = true,
}: PropsWithChildren<PropsGifModal>): JSX.Element => {
  return (
    <Modal
      show={showModal}
      onHide={undefined}
      dialogClassName={`modal-dialog-centered ${className}`}
      contentClassName={`rounded-4 mh-400-px d-flex justify-content-between shadow border-0 `}
      size='lg'>
      {showCloseIcon && buttons && (
        <Col xs={12} className='text-end position-absolute top-0 pe-2 pt-2 d-flex justify-content-end'>
          <CloseIcon classMain='btn-icon-light' onClick={handleShowModal} />
        </Col>
      )}
      {image && (
        <Col xs={12} className='text-center pt-4'>
          {image}
        </Col>
      )}
      <Col xs={12} className='text-center px-5'>
        {message}
      </Col>
      {buttons && (
        <Col xs={11} className='mx-auto mt-3'>
          {buttons}
        </Col>
      )}
      <Col xs={12} className='align-self-end pt-2'>
        <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
      </Col>
    </Modal>
  );
};

export default GifModal;
