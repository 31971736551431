import InfoUserForm from '@/ui/components/user/InfoUserForm';

import UserOptionsContainer from '@/ui/containers/userOptions/UserOptionsContainer';

/** Componente pagina User/Info
 * @component
 */
const UserInfo = (): JSX.Element => {
  return (
    <UserOptionsContainer type='content-info'>
      <InfoUserForm />
    </UserOptionsContainer>
  );
};

export default UserInfo;
