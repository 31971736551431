import Slider from 'react-slick';

import { PropsWithChildren } from 'react';

import './style.css';
import Icon from '@/ui/assets/Icon';

interface ICarousel {
  slidesToShow: number;
  slidesToScroll: number;
  speed: number;
  centerMode?: boolean;
  infinite?: boolean;
  autoplaySpeed?: number;
  autoplay?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  centerPadding?: string;
  dots?: boolean;
  arrows?: boolean;
  variableWidth?: boolean;
  className?: string;
}

const Carousel = ({
  slidesToShow,
  slidesToScroll,
  speed,
  centerMode = false,
  infinite = true,
  autoplaySpeed = 1000,
  autoplay = false,
  pauseOnHover = true,
  draggable = true,
  centerPadding,
  dots = false,
  arrows = false,
  variableWidth = false,
  className,
  children,
}: PropsWithChildren<ICarousel>) => {
  const settings = {
    centerMode: centerMode,
    infinite: infinite,
    dots: dots,
    draggable: draggable,
    centerPadding: centerPadding,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: autoplay,
    speed: speed,
    autoplaySpeed: autoplaySpeed,
    cssEase: 'linear',
    swipeToSlide: true,
    pauseOnHover: pauseOnHover ?? autoplay,
    variableWidth: variableWidth,
    nextArrow: arrows ? (
      <Icon name={'ico-right-arrow'} classMain={`btn-slider shadow p-2 rounded-circle bg-primary-4 position-absolute top-50 end-0 me-2 z-1`} />
    ) : (
      <></>
    ),
    prevArrow: arrows ? (
      <Icon name={'ico-left-arrow'} classMain={`btn-slider shadow p-2 rounded-circle bg-primary-4 position-absolute top-50 start-0 ms-2 z-1`} />
    ) : (
      <></>
    ),
  };

  return (
    <div className={`my-lg-2 p-0  carousel ${className} ${arrows ? 'arrow-carousel mb-lg-4' : ''}`}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default Carousel;
