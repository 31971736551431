/**
 * Convierte un String en un String con formato decimal
 * @param {number} value 
 * @returns {string} value con formato decimal
*/
const decimalFormat = (value: string, decimalPrecision?: number): string => {

    let number:string;

    if (decimalPrecision) {
        number = parseFloat(value).toFixed(decimalPrecision);
    } else {
        number = parseFloat(value).toString();
    }
    // Reemplazar las comas por puntos

    let decimalValue = number.replace(',', '.') ?? '';

    // Eliminar puntos que no son el separador decimal
    decimalValue = decimalValue.replace(/\.(?!\d+$)/g, '');

    return decimalValue;
}


export default decimalFormat;
