import { PropsWithChildren, useLayoutEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import type { NavigateFunction, Location } from 'react-router-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppSelector } from '@/ui/store/helperRedux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import { IProduct, IShippingTypes } from '@/domain/interfaces/IProduct';

import ErrorImage from '@/ui/assets/ErrorImage';

import ShippingMethods from '@/ui/components/product/ShippingMethods';
import ProductStatusPill from '@/ui/components/product/ProductStatusPill';
import MainPrice from '@/ui/components/product/MainPrice';
import SecondaryPrice from '@/ui/components/product/SecondaryPrice';

import './style.css';

/** Propiedades para el componente ProductPreview. */
interface ProductPreviewProps {
  /** Datos del producto a renderizar. */
  dataToRendering: IProduct;
  /** Indica si se debe mostrar en formato de cuadrícula o lista. */
  isGrid?: boolean;
  /** Opción para indicar si esta activo el filtro.   */
  filterActivated?: boolean;
  /**Con Sombra */
  shadow?: boolean;
}
/**
 * Componente para renderizar todos los items del preview del producto
 * @component
 */
const ProductPreview = ({ dataToRendering, isGrid, filterActivated, shadow }: PropsWithChildren<ProductPreviewProps>): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook para trabajar con la URL y query parameters */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  const [realPrice, setRealPrice] = useState<number | null>(dataToRendering.price);

  /** State tipos de envío */
  const [shippingData, setShippingData] = useState<IShippingTypes[]>([]);

  /** Establece el valor del state shippingData */
  const asignShippingData = (): void => {
    if (dataToRendering.shippingTypes === null) {
      /** Para el catálogo se recibe un string (title_icon) */
      if (dataToRendering.catalogShippingTypes && dataToRendering.catalogShippingTypes !== null) {
        /** Se forma el objeto IShippingTypes  */
        const shippingData: string[] = dataToRendering.catalogShippingTypes.split('_');
        if (shippingData.length > 0) {
          const shippingObject: IShippingTypes = {
            title: shippingData[0],
            icon: shippingData[1],
            id: 0,
          };
          setShippingData([shippingObject]);
        }
      }
    } else {
      /** Asigna el valor como llega del back que es un arreglo IShippingTypes */
      setShippingData(dataToRendering.shippingTypes);
    }
  };

  useLayoutEffect(() => {
    if (dataToRendering.offerPrice !== null && dataToRendering.offerPrice >= 0) {
      setRealPrice(dataToRendering.offerPrice);
    }
    if (dataToRendering) {
      asignShippingData();
    }
  }, []);

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);
  const userStore: string = String(userLogued.user?.userStore ?? '1');

  /** Estado del componente que almacena la URL de la imagen por defecto a mostrar.  */
  const [imageUrl, setImageUrl] = useState<string>(ErrorImage);

  /** Función de manejo de carga de imagen.  */
  const handleImageLoad = (): void => {
    setImageUrl(`${dataToRendering.multimedia[0]}`);
  };

  const handleNavigate = (sku: string): void => {
    navigate(`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${sku}`, { state: { originURL: location.pathname } });
  };

  /**CONSTANTES DE TEXTO */
  const MORE_TITLE: string = 'VER MÁS';

  /**COMPONENTES BASE */

  /**Componente Imagen */
  const PreviewImage = (): JSX.Element => {
    const cointainerType = () => {
      if (isGrid) {
        if (filterActivated) {
          return 'image-grid-container';
        } else {
          return 'image-grid-without-filter-container';
        }
      } else {
        return 'image-list-container';
      }
    };
    return (
      <div className={`${cointainerType()} rounded rounded-3`}>
        <Image
          fluid
          className={`rounded rounded-3 image-full`}
          src={imageUrl}
          onLoad={handleImageLoad}
          onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
            e.currentTarget.src = ErrorImage;
          }}
        />
      </div>
    );
  };

  /**Componente Precio Secundario */
  // const SecondaryPrice: JSX.Element = (
  //   <span className={`text-tertiary-5 text-decoration-line-through ${dataToRendering.offerPrice === null && 'invisible'}`}>
  //     {currencyFormat(dataToRendering.price, ConfigNumberParameters.DECIMALS_ALLOWED)}
  //   </span>
  // );

  return (
    <Container fluid className={`product-preview p-0 text-start bg-primary-4 position-relative rounded rounded-3 ${shadow ? 'shadow' : ''}`}>
      {isMobile ? (
        <>
          {/* ENTORNO GRID MOBILE*/}
          {isGrid ? (
            <>
              <Row className='g-0 text-center cursor-hand' onClick={() => handleNavigate(dataToRendering.sku)}>
                <Col xs={12} className='position-relative'>
                  {/* <FavoritesWidget
                classMain={`position-absolute end-0 me-2 mt-2 pt-1 bg-secondary-5 svg-primary-2 btn-icon-light ${
                  !isMobile && 'invisible'
                }`}
                skuProduct={dataToRendering.sku}
              /> */}
                  <PreviewImage />
                  <div className='top-0 end-0 position-absolute pe-1'>
                    <ProductStatusPill isInOffer={dataToRendering.offerPrice !== null} isSoldOut={dataToRendering.totalProducts === 0} />
                  </div>
                </Col>
              </Row>
              <Row className='g-0 px-2 mb-1'>
                <Col xs={12} className='my-1 text-truncate-3'>
                  <span className='p-regular-medium'>{dataToRendering.title}</span>
                </Col>
                <Row className='my-2 g-0'>
                  <Col xs={12} className='p-regular-bold'>
                    <MainPrice isFree={realPrice === 0} value={realPrice ?? 0} />
                  </Col>
                  <Col xs={12} className='p-regular-medium'>
                    <SecondaryPrice isInOffer={dataToRendering.offerPrice !== null} value={dataToRendering.price} />
                  </Col>
                </Row>
                <Row className='g-0 row-cols-auto'>
                  <ShippingMethods data={shippingData} />
                </Row>
              </Row>
              <Row className='g-0 px-1'>
                <Col xs={12}>
                  <Button
                    className='mb-2 w-100 svg-primary-4 btn-primary-icon-small svg-16'
                    type='button'
                    onClick={() => handleNavigate(dataToRendering.sku)}>
                    <span className='pe-2 p-small-bold'>{MORE_TITLE}</span>
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {/* ENTORNO LISTA MOBILE*/}
              <Row className='g-0 p-1 d-flex '>
                {/* {isMobile && (
            <Col xs={12} className='position-relative'>
              <FavoritesWidget
                classMain='position-absolute end-0 me-2 mt-2 pt-1 svg-primary-2 bg-secondary-5 btn-icon-light'
                skuProduct={dataToRendering.sku}
              />
            </Col>
          )} */}
                <Col xs={5} className=' text-center position-relative cursor-hand  ' onClick={() => handleNavigate(dataToRendering.sku)}>
                  <PreviewImage />
                  <div className='top-0 end-0 position-absolute pe-2'>
                    <ProductStatusPill isInOffer={dataToRendering.offerPrice !== null} isSoldOut={dataToRendering.totalProducts === 0} />
                  </div>
                </Col>
                <Col xs={7} className='ps-3 pe-2 position-relative '>
                  <Row className='g-0 d-flex '>
                    <Col xs={12} className='p-regular mh-55-px'>
                      <span className='p-regular-medium'>{dataToRendering.title}</span>
                    </Col>
                    <Row className='my-2 g-0'>
                      <Col xs={12} className='p-large-bold'>
                        <MainPrice isFree={realPrice === 0} value={realPrice ?? 0} />
                      </Col>
                      <Col xs={12} className='p-large-medium'>
                        <SecondaryPrice isInOffer={dataToRendering.offerPrice !== null} value={dataToRendering.price} />
                      </Col>
                    </Row>
                  </Row>
                  <Row className='g-0 mb-1 row-cols-auto'>
                    <ShippingMethods data={shippingData} />
                  </Row>
                </Col>
              </Row>
              <Row className='g-0 px-1'>
                <Col xs={12}>
                  <Button
                    className='mb-2 w-100  svg-primary-4 btn-primary-icon-small svg-16'
                    type='button'
                    onClick={() => handleNavigate(dataToRendering.sku)}>
                    <span className='pe-2 p-small-bold'>{MORE_TITLE}</span>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          {/* ENTORNO GRID DESKTOP*/}
          {isGrid ? (
            <Link to={`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${dataToRendering.sku}`} replace={true}>
              <Container fluid className='p-0'>
                <Row className='g-0 text-center cursor-hand' onClick={() => handleNavigate(dataToRendering.sku)}>
                  <Col xs={12} className='position-relative cursor-hand'>
                    {/* <FavoritesWidget
                classMain={`position-absolute end-0 me-2 mt-2 pt-1 bg-secondary-5 svg-primary-2 btn-icon-light ${
                  !isMobile && 'invisible'
                }`}
                skuProduct={dataToRendering.sku}
              /> */}

                    <PreviewImage />
                    <div className='top-0 end-0 position-absolute pe-2 pt-2 p-regular-medium'>
                      <ProductStatusPill isInOffer={dataToRendering.offerPrice !== null} isSoldOut={dataToRendering.totalProducts === 0} />
                    </div>
                  </Col>
                </Row>
                <Row className='g-0 px-2'>
                  <Col xs={12} className='my-1 text-truncate-3'>
                    <span className='p-large-medium'>{dataToRendering.title} </span>
                  </Col>
                  <Col xs={12} className='p-large-bold'>
                    <MainPrice isFree={realPrice === 0} value={realPrice ?? 0} />
                  </Col>
                  <Col xs={12} className='p-large-medium'>
                    <SecondaryPrice isInOffer={dataToRendering.offerPrice !== null} value={dataToRendering.price} />
                  </Col>
                  <Row className='g-0 mb-1 row-cols-auto'>
                    <ShippingMethods data={shippingData} />
                  </Row>
                  <Col xs={12}>
                    <Button
                      className='mb-2 w-100 svg-primary-4 btn-primary-icon-standard'
                      type='button'
                      onClick={() => handleNavigate(dataToRendering.sku)}>
                      <span className='pe-2 p-regular-medium'>{MORE_TITLE}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Link>
          ) : (
            <Link to={`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${dataToRendering.sku}`} replace={true}>
              <Container fluid className='p-0'>
                <Row className='g-0 py-2'>
                  {/* ENTORNO LISTA DESKTOP*/}
                  {/* {isMobile && (
            <Col xs={12} className='position-relative'>
              <FavoritesWidget
                classMain='position-absolute end-0 me-2 mt-2 pt-1 svg-primary-2 bg-secondary-5 btn-icon-light'
                skuProduct={dataToRendering.sku}
              />
            </Col>
          )} */}
                  <Col md={2} className='d-flex mx-auto py-1 text-center cursor-hand' onClick={() => handleNavigate(dataToRendering.sku)}>
                    <div className=' position-relative'>
                      <PreviewImage />
                      <div className='top-0 end-0 position-absolute pe-2 pt-2 p-regular-medium'>
                        <ProductStatusPill isInOffer={dataToRendering.offerPrice !== null} isSoldOut={dataToRendering.totalProducts === 0} />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} className='ps-3 pe-2 position-relative '>
                    <Col xs={12}>
                      <span className='p-large-medium'>{dataToRendering.title} </span>
                    </Col>
                    <Col xs={12} className='p-large-bold'>
                      <MainPrice isFree={realPrice === 0} value={realPrice ?? 0} />
                    </Col>
                    <Col xs={12} className='p-large-medium'>
                      <SecondaryPrice isInOffer={dataToRendering.offerPrice !== null} value={dataToRendering.price} />
                    </Col>
                    <Row className='g-0 mb-1 row-cols-auto'>
                      <ShippingMethods data={shippingData} />
                    </Row>
                  </Col>
                  <Col md={{ span: '2', offset: '1' }} className='mx-auto d-flex flex-column justify-content-between'>
                    {/* <FavoritesWidget
                classMain='me-2 mt-2 pt-1 svg-primary-2 text-end invisible bg-secondary-5 align-self-end btn-icon-light'
                skuProduct={dataToRendering.sku}
              /> */}
                    <Button
                      className='my-2 w-100 svg-primary-4 mt-auto  btn-primary-icon-standard'
                      type='button'
                      onClick={() => handleNavigate(dataToRendering.sku)}>
                      <span className='pe-2 p-regular-medium'>{MORE_TITLE}</span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Link>
          )}
        </>
      )}
    </Container>
  );
};

export default ProductPreview;
