import { PropsWithChildren } from 'react';

interface PropsProductStatusPill {
  /**Identifica si está en oferta */
  isInOffer: boolean;
  /**Identifica si está agotado */
  isSoldOut: boolean | number;
}
/**
 * Componente para mostrar una pill con el estado del producto
 * @component
 */
const ProductStatusPill = ({ isInOffer, isSoldOut }: PropsWithChildren<PropsProductStatusPill>): JSX.Element => {
  const SOLD_OUT_TEXT: string = 'AGOTADO';
  const IN_OFFER_TEXT: string = 'OFERTA';
  return (
    <span
      className={` rounded-pill p-1 p-small-medium
                    ${isSoldOut ? 'bg-tertiary-5 text-secondary-2 p-title-bold' : 'bg-complementary-1 text-primary-4'} 
                    ${!isInOffer && !isSoldOut && 'd-none'}`}>
      {`${isSoldOut ? SOLD_OUT_TEXT : IN_OFFER_TEXT}`}
    </span>
  );
};

export default ProductStatusPill;
