import { useCallback, useEffect, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { ColumnDef } from '@tanstack/react-table';

import { Row, Col, Image, Table, Container, Button, Modal } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import PreInsertController from '@/controllers/PreInsertController';
import InventoryController from '@/controllers/InventoryController';

import { IProductForm } from '@/domain/interfaces/IProduct';
import { IBulkLoadingResponse } from '@/domain/interfaces/IInventory';
import { IAttributesForm } from '@/domain/interfaces/IAttributes';
import { IMenu } from '@/domain/interfaces/IMenu';

import downloadTxt from '@/domain/utils/downloadTxt';
import findCategory from '@/domain/utils/findCategory';
import currencyFormat from '@/domain/utils/currencyFormat';
import { errorMessageFormatter, getErrorInfo, getErrorLine } from '@/domain/utils/errorFormatter';

import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import InfoBox from '@/ui/components/InfoBox';
import MainTitle from '@/ui/components/MainTitle';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import ModalError from '@/ui/components/inventory/ModalError';
import TableCustom from '@/ui/components/TableCustom';
import IconList from '@/ui/components/IconList';

import GuideTip from '@/ui/components/GuideTip';
import GifModal from '@/ui/components/modals/GifModal';

import ValidationInterface from '@/ui/containers/ValidationInterface';
import ProductList from '@/ui/containers/ValidationInterface/ProductList';

const AddProductsValidationInterface = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /**inicio de variables de estado para el modal de errores*/
  const location: Location = useLocation();

  /** Hook para generar una navegación dentro de la aplicación.  */
  const navigate: NavigateFunction = useNavigate();

  /** Arreglo que viene desde la carga de archivos */
  const [productPresenter, setProductPresenter] = useState<IProductForm[]>([]);

  /** Estado que identifica si es un producto base o variante */
  const [isBaseProduct, setIsBaseProduct] = useState<boolean>(true);

  /** Contador de Errores */
  const [counterError, setCounterError] = useState<number>(0);

  /**useEffect para actualizar el estado de las variables de estado*/
  useEffect(() => {
    setErrorArray(location.state.errorArray);
    setProductPresenter(location.state.productPresenter);
    setCounterError(location.state.errorArray?.length ?? 0);
    !location.state?.productPresenter && navigate(RoutesDirections.MASSIVE_CHARGE_ROUTE, { state: { originURL: location.pathname } });
  }, [location.state.errorArray, location.state.productPresenter, navigate, productPresenter.length]);

  /**INICIO ESTRUCTURA DE TABLAS */

  interface IBaseProductTable {
    id: number;
    image: string;
    name: string;
    category: number;
    variants: number;
    branchOffice: string;
    quantity: number;
    status: string;
    selected: boolean;
  }

  /**Arreglo de items para renderizar tabla de productos base */
  const [baseProductForm, setBaseProductForm] = useState<IBaseProductTable[]>([]);

  interface IVariantTable {
    id: string;
    image: string;
    main: boolean;
    sku: string;
    name: string;
    atributes: IAttributesForm[];
    price: number;
    quantity: number;
    status: number | null;
    selected: boolean;
    disabled: boolean;
  }

  /**Arreglo de items para renderizar tabla de variantes*/
  const [variantForm, setVariantForm] = useState<IVariantTable[]>([]);

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Renderiza el objeto de productos bases y variantes segun lo que recibe */
  const tableData = useCallback(() => {
    const baseProducts: IBaseProductTable[] = [];
    const variantsProducts: IVariantTable[] = [];

    //Iteramos cada producto del presenter
    productPresenter &&
      productPresenter.forEach((product, index) => {
        //Cantidad total de variantes por producto Base
        const totalQuantity = product.variants.reduce((i, variant) => i + (variant.quantity ?? 0), 0);

        //URL de la variante principal del producto base
        let imageUrl: string | undefined = '';
        imageUrl = product?.variants.find((variant) => variant.main)?.media[0] ?? product?.variants[0].media[0];

        //Armamos estructura  de cada  producto base
        const baseProduct: IBaseProductTable = {
          id: index + 1,
          image: imageUrl ?? '',
          name: product.name,
          category: product.category,
          variants: product.variants.length,
          branchOffice: userLogued.user?.branchOffice?.name ?? '',
          quantity: totalQuantity,
          status: product.status === 2 ? STATE_INACTIVE : STATE_ACTIVE,
          selected: true,
        };
        product.idTemp = index + 1;

        //Incluimos objeto al arreglo de productos base
        baseProducts.push(baseProduct);

        //Iteramos cada variante de cada producto base
        product.variants.forEach((variant, indexV) => {
          //Definimos los atributos principales de la variante
          const mainItems = variant.attributes.filter((attribute) => attribute.main);

          //Armamos estructura  de cada variante
          const variantInfo: IVariantTable = {
            id: `${baseProduct.id}_${indexV + 1}`,
            image: variant.media.length > 0 && variant.media[0].length > 0 ? variant.media[0] : imageUrl ?? '',
            main: variant.main,
            sku: !variant.sku || variant.sku === '' ? WITHOUT_SKU_TEXT : variant.sku,
            name: variant.baseProductName,
            atributes: mainItems,
            price: variant.price ?? 0,
            quantity: variant.quantity ?? 0,
            status: variant.status,
            selected: true,
            disabled: false,
          };
          variant.idTemp = `${baseProduct.id}_${indexV + 1}`;
          //Incluimos objeto al arreglo de variantes
          variantsProducts.push(variantInfo);
        });
        // Filtra los productos con selected true
        const variantsSelected = variantsProducts.filter((variant) => variant.selected);

        // Crea un objeto de selección usando el índice como clave
        const selectionVariants = variantsSelected.reduce((obj, variant, index) => {
          obj[index] = variant.id.toString();
          return obj;
        }, {});
        // Actualiza la referencia local y el localStorage
        updateVariantsSelectionRef(selectionVariants);
        //Cambiamos el estado del arreglo de variantes
        setVariantForm(variantsProducts);
      });

    // Filtra los productos con selected true
    const baseProductsSelected = baseProducts.filter((product) => product.selected);

    // Crea un objeto de selección usando el índice como clave
    const selectionBaseProduct = baseProductsSelected.reduce((obj, product, index) => {
      obj[index] = product.id.toString();
      return obj;
    }, {});

    // Actualiza la referencia local y el localStorage
    updateBaseProductSelectionRef(selectionBaseProduct);
    //Cambiamos el estado del arreglo de productos bases
    setBaseProductForm(baseProducts);
  }, [productPresenter, userLogued.user?.branchOffice?.name]);

  /** UseEffect que renderiza el objeto de productos bases y variantes segun lo que recibe */
  useEffect(() => {
    tableData();
  }, [productPresenter, tableData]);
  /**  Objeto Redux que almacena la data del menu   */
  const navCategory: IMenu = useAppSelector((state) => state.categoriesMenuState);

  /** Controlador para el objeto de registros seleccionados del inventario de productos base */
  const {
    getSelectedBaseProductTable,
    updateSelectedBaseProductTable,
    getSelectedVariantTable,
    updateSelectedVariantTable,
    deleteObjSelectedProductBase,
    deleteObjSelectedVariant,
  } = PreInsertController();

  /** Objeto de selección de productos base en LocalStorage */
  const baseProductOnLocalStorage: { [index: number]: string } | null = getSelectedBaseProductTable();

  /** Referencia al objeto de productos base de selección */
  //const baseProductSelection = useRef<{ [index: number]: string }>({ ...baseProductOnLocalStorage });
  const [baseProductSelection, setBaseProductSelection] = useState<{ [index: number]: string }>({ ...baseProductOnLocalStorage });

  /** Manejador de click al seleccionar una fila en productos base  */
  const updateBaseProductSelectionRef = (selectionObj: { [index: number]: string }) => {
    //Actualiza la referencia local del objeto de selección
    setBaseProductSelection(JSON.parse(JSON.stringify(selectionObj)));

    // Actualiza LocalStorage
    updateSelectedBaseProductTable(JSON.parse(JSON.stringify(selectionObj)));
  };

  /** Objeto de selección de variantes en LocalStorage */
  const variantsOnLocalStorage = getSelectedVariantTable();

  /** Referencia al objeto de variantes de selección */
  const [variantsSelection, setVariantsSelection] = useState<{ [index: number]: string }>({ ...variantsOnLocalStorage });

  /** Manejador de click al seleccionar una fila en variante  */
  const updateVariantsSelectionRef = (selectionObj: { [index: number]: string }) => {
    //Actualiza la referencia local del objeto de selección
    setVariantsSelection(JSON.parse(JSON.stringify(selectionObj)));

    // Actualiza LocalStorage
    updateSelectedVariantTable(JSON.parse(JSON.stringify(selectionObj)));
  };

  /** Definición de columnas de producto base */
  const baseProductsColumns: ColumnDef<IBaseProductTable>[] = [
    {
      accessorKey: 'id',
      header: () => <div className='text-center'> {COLUMN_ID}</div>,
      cell: (info) => {
        return <span className='p-large-medium bg-secondary-5 badge badge-pill text-secondary-2 text-center w-100'>{`${info.renderValue()}`}</span>;
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'image',
      header: () => <div className='text-center'> {COLUMN_IMAGE}</div>,
      cell: (info) => {
        return (
          <Col className='d-flex justify-content-center'>
            <div className='image-crud-container rounded rounded-3'>
              <Image
                className='rounded rounded-3 image-full'
                src={`${info.renderValue()}`}
                alt={''}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = ErrorImage;
                }}
              />
            </div>
          </Col>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'name',
      header: () => COLUMN_NAME,
      cell: (info) => {
        return (
          <div className='text-secondary-2 text-break'>
            <span className='p-large-medium'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'category',
      header: () => COLUMN_CATEGORY,
      cell: ({ row }) => {
        //Buscar la categoría actual basada en la URL actual
        const category: IMenu | null = findCategory(navCategory, row.getValue('category'));
        return (
          category?.icon && <IconList iconName={category.icon} classTitle='svg-secondary-2 p-large-medium text-secondary-2 ' title={category.title} />
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'variants',
      header: () => <div className='text-center'> {COLUMN_VARIANTS}</div>,
      cell: (info) => {
        return (
          <div className='text-secondary-2 text-center'>
            <span className='p-large-medium'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'branchOffice',
      header: () => <div className='text-center'> {COLUMN_BRANCH_OFFICE}</div>,
      cell: (info) => {
        return (
          <div className='text-secondary-2 text-center'>
            <span className='p-large-medium'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'quantity',
      header: () => <div className='text-center'> {COLUMN_QUANTITY}</div>,
      cell: (info) => {
        return (
          <div className='text-secondary-2 text-center'>
            <span className='p-large-medium'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'status',
      header: () => <div className='text-center'>{COLUMN_STATE}</div>,
      cell: (info) => {
        const valueClass = info.renderValue() === STATE_ACTIVE ? 'text-complementary-1' : 'text-complementary-2';
        return (
          <div className='text-secondary-2 text-center'>
            <span className={`${valueClass} p-large-medium`}>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'select',
      header: () => <div className='text-center'>{COLUMN_SELECTION}</div>,
      cell: ({ row }) => {
        return (
          <span className='icon-selection text-center'>
            <Icon name='ico-selection' classMain='svg-tertiary-5 d-flex justify-content-center' />
          </span>
        );
      },
    },
  ];

  /** Definición de columnas */
  const variantsColumns: ColumnDef<IVariantTable>[] = [
    {
      accessorKey: 'id',
      header: () => <div className='text-center'>{COLUMN_ID}</div>,
      cell: (info) => {
        return <span className='p-large-medium bg-secondary-5 badge badge-pill text-secondary-2 text-center w-100'>{`${info.renderValue()}`}</span>;
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'image',
      header: () => <div className='text-center'>{COLUMN_IMAGE}</div>,
      cell: (info) => {
        return (
          <Col className='d-flex justify-content-center'>
            <div className='image-crud-container rounded rounded-3'>
              <code className='position-relative'>
                <Image
                  className='rounded rounded-3 image-full '
                  src={`${info.renderValue()}`}
                  alt={` `}
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = ErrorImage;
                  }}
                />
                {info.row.original.main && <Icon name='ico-major' classMain='svg-primary-1 position-absolute end-0 top-100' />}
              </code>
            </div>
          </Col>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'main',
      header: () => <div className='text-center'>{COLUMN_VARIANT_TYPE}</div>,
      cell: (info) => {
        const value = info.renderValue() === true ? MAIN_VARIANT : STANDAR_VARIANT;
        return (
          <div className='text-secondary-2 text-center'>
            <span className='p-large-medium'>{`${value}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'sku',
      minSize: 500,
      header: () => <div className='text-center'>{COLUMN_SKU}</div>,
      cell: (info) => {
        return (
          <Col xs={12} className='text-secondary-2 text-center d-flex aling-items-center justify-content-center'>
            <span className='p-large-medium bg-secondary-3 rounded rounded-5 py-1 px-3 text-primary-4 w-100 text-break'>{`${info.renderValue()}`}</span>
          </Col>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'name',
      minSize: 1000,
      header: () => COLUMN_BASE_PRODUCT,
      cell: (info) => {
        return (
          <div className='text-secondary-2'>
            <span className='p-large-medium text-break'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'atributes',
      minSize: 500,
      header: () => COLUMN_ATTRIBUTES,
      cell: ({ row }) => {
        const attributes: IAttributesForm[] = row.getValue('atributes');

        return (
          <div className='text-secondary-2'>
            <div>
              <span className='p-regular-bold text-truncate'>{MAIN_1_ATTRIBUTE}</span>
              <span className='ms-1 p-regular-medium  text-truncate'>{`${attributes[0].key} : ${attributes[0].value}`}</span>
            </div>
            <div>
              <span className='p-regular-bold text-truncate '>{MAIN_2_ATTRIBUTE}</span>
              <span className='ms-1 p-regular-medium  text-truncate'>{`${attributes[1].key} : ${attributes[1].value}`}</span>
            </div>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'price',
      header: () => <div>{COLUMN_PRICE}</div>,
      cell: (info) => {
        const value: string = info.renderValue() as string;
        return (
          <div className='text-secondary-2'>
            <span className='p-regular-medium text-break'>{`${currencyFormat(parseFloat(value), ConfigNumberParameters.DECIMALS_ALLOWED)}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'quantity',
      header: () => <div className='text-center'>{COLUMN_QUANTITY}</div>,
      cell: (info) => {
        return (
          <div className='text-secondary-2 text-center text-break'>
            <span className='p-regular-medium'>{`${info.renderValue()}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'status',
      header: () => <div className='text-center'>{COLUMN_STATE}</div>,
      cell: (info) => {
        const value = info.renderValue() === 1 ? STATE_ACTIVE : STATE_INACTIVE;
        const valueClass = info.renderValue() === 1 ? 'text-complementary-1' : 'text-complementary-2';
        return (
          <div className='text-secondary-2 text-center'>
            <span className={`${valueClass} p-large-medium`}>{`${value}`}</span>
          </div>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'select',
      header: () => <div className='text-center'>{COLUMN_SELECTION}</div>,
      cell: (info) => {
        return (
          <GuideTip
            position='bottom'
            classMain={`${!info.row.original.disabled && 'icon-selection'} text-center p-small`}
            message={info.row.original.disabled && 'Selecciona primero la variante principal'}>
            <Icon name={`${!info.row.original.disabled ? 'ico-selection' : 'ico-locked'}`} classMain='svg-tertiary-5 d-flex justify-content-center' />
          </GuideTip>
        );
      },
    },
    {
      accessorKey: 'disabled',
      header: () => <div className='text-center'>{}</div>,
      cell: (info) => {
        return <span className='text-center'>{`${info.renderValue()}`}</span>;
      },
    },
  ];

  /**FIN ESTRUCTURA DE TABLAS */

  /** INICIO FUNCIONES DE SELECCION DE TABLAS */

  /** Productos base seleccionados  */
  const [baseProductSelectedRows, setBaseProductSelectedRows] = useState<number>(0);

  /** Función para actualizar el contador de elementos seleccionados */
  const updateBaseProductsSelectedRowsCount = () => {
    const countSelectedItems = baseProductForm.reduce((count, item) => count + (item.selected ? 1 : 0), 0);
    setBaseProductSelectedRows(countSelectedItems);
  };

  const handleBaseProductSelection = (itemId: number | string) => {
    // Copia del objeto producto base de la tabla
    const baseProductFormCopy = [...baseProductForm];

    // Encuentra el elemento seleccionado por su ID
    const selectedItem = baseProductFormCopy.find((item) => item.id === itemId);

    // Si se encuentra el elemento, invierte su estado de selección
    if (selectedItem) {
      selectedItem.selected = !selectedItem.selected;

      // Cambia la selección de variantes de acuerdo al valor de la acción de selección

      // Copia del objeto variante de la tabla
      const variantFormCopy = [...variantForm];
      // Copia del objeto de selección de variante
      const variantsSelectionCopy: { [index: number]: string } = { ...variantsSelection };

      // Actualiza los objetos de variantes
      variantFormCopy.forEach((item, index) => {
        const productBaseId: string[] = item.id.split('_');
        if (productBaseId[0] === String(selectedItem.id)) {
          item.selected = selectedItem.selected;
          //Si el producto base esta deseleccionado , bloquea las variantes que no son principales
          !item.main && !selectedItem.selected && (item.disabled = true);
          //Si el producto base esta seleccionado , desbloquea las variantes que no son principales
          !item.main && selectedItem.selected && (item.disabled = false);

          if (selectedItem.selected) {
            variantsSelectionCopy[index] = item.id;
          } else {
            delete variantsSelectionCopy[index];
          }
        }
      });

      // Actualiza los objetos según la logica de selección
      setBaseProductForm(baseProductFormCopy);
      setVariantForm(variantFormCopy);
      updateVariantsSelectionRef(variantsSelectionCopy);
    }
  };

  /** Prodctos base seleccionados  */
  const [variantSelectedRows, setVariantSelectedRows] = useState<number>(0);

  /** Función para actualizar el contador de elementos seleccionados */
  const updateVariantSelectedRowsCount = () => {
    const countSelectedItems = variantForm.reduce((count, item) => count + (item.selected ? 1 : 0), 0);
    setVariantSelectedRows(countSelectedItems);
  };

  const handleVariantsSelection = (itemId: number | string) => {
    // Copia del objeto variante de la tabla
    const variantFormCopy = [...variantForm];

    // Encuentra el elemento seleccionado por su ID
    const selectedItem = variantFormCopy.find((item) => item.id === itemId);

    // Si se encuentra el elemento, invierte su estado de selección
    if (selectedItem) {
      selectedItem.selected = !selectedItem.selected;

      const productBaseId: string[] = selectedItem.id.split('_');

      const baseProductIndex: IBaseProductTable | undefined = baseProductForm.find((baseProduct) => baseProduct.id === Number(productBaseId[0]));
      if (baseProductIndex) {
        // Si la variante es principal afecta la selección del producto base y otras variantes
        if (selectedItem.main) {
          // Copia del objeto de selección de variante
          const baseProductSelectionCopy: { [index: number]: string } = { ...baseProductSelection };

          if (selectedItem.selected) {
            baseProductSelectionCopy[baseProductIndex.id - 1] = String(baseProductIndex.id);
          } else {
            delete baseProductSelectionCopy[baseProductIndex.id - 1];
          }

          updateBaseProductSelectionRef(baseProductSelectionCopy);

          const selectionIndex = baseProductIndex.id;
          handleBaseProductSelection(selectionIndex);
        } else {
          /** Si la variante no es principal y se esta activando una variante
           * que tiene su producto base no seleccionado revierte la selección
           */
          if (selectedItem.selected && !baseProductIndex.selected) {
            const variantsSelectionCopy: { [index: number]: string } = { ...variantsSelection };

            for (const key in variantsSelectionCopy) {
              if (variantsSelectionCopy.hasOwnProperty(key) && variantsSelectionCopy[key] === selectedItem.id) {
                delete variantsSelectionCopy[key];
              }
            }

            selectedItem.selected = false;

            updateVariantsSelectionRef(variantsSelectionCopy);
          }
        }
      }
      // Actualiza el objetos según la logica de selección
      setVariantForm(variantFormCopy);
    }
  };

  // Actualizar el valor de baseProductSelectedRowsRef al renderizar el componente
  useEffect(() => {
    updateBaseProductsSelectedRowsCount();
  }, [baseProductForm]);

  // Actualizar el valor de baseProductSelectedRowsRef al renderizar el componente
  useEffect(() => {
    updateVariantSelectedRowsCount();
  }, [variantForm]);

  /** FIN FUNCIONES DE SELECCION DE TABLAS */

  /** FUNCIÓN PARA PUBLICAR EL INVENTARIO SELECCIONADO */

  /** Controlador para el objeto de registros seleccionados del inventario de productos base */
  const { inventoryBulkLoading } = InventoryController();

  /** Publica los productos base seleccionados */
  const postInventory = async (): Promise<void> => {
    // Filtra los productos base seleccionados para enviar al back
    const filteredBaseProducts: IProductForm[] = productPresenter
      ? productPresenter.filter((product) => baseProductForm.some((itemTable) => itemTable.id === product.idTemp && itemTable.selected))
      : [];

    // Filtra las variantes seleccionadas para enviar al back
    filteredBaseProducts.forEach((baseProduct) => {
      baseProduct.variants = baseProduct.variants.filter((variant) => {
        return !variantForm.some((item) => item.id === variant.idTemp && !item.selected);
      });
    });
    setShowLoadingModal(true);
    setConfirmationModal(false);

    // Carga masiva al back por medio del controlador
    await inventoryBulkLoading(filteredBaseProducts, userLogued.user.token ?? '').then((responseController: IBulkLoadingResponse | null) => {
      //Elimina el objeto del local storage
      deleteObjSelectedProductBase();
      deleteObjSelectedVariant();

      setShowLoadingModal(false);

      if (responseController !== null) {
        responseController.errors.length > 0 ? setServerErrorModal(true) : setSuccessfulModal(true);
      } else {
        setServerErrorModal(true);
      }
    });
  };

  /**INICIO MODAL DE ERRORES */

  const [errorArray, setErrorArray] = useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const handleErrorShowModal = (): void => {
    setShowErrorModal(!showErrorModal);
  };

  /** FIN MODAL DE ERRORES */

  const [serverErrorModal, setServerErrorModal] = useState(false);

  const handleNavigate = () => navigate(RoutesDirections.MASSIVE_CHARGE_ROUTE, { state: { originURL: location.pathname } });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);

  /**CONSTANTES DE TEXTO */

  const COLUMN_ID: string = 'ID';
  const COLUMN_IMAGE: string = 'Imagen';
  const COLUMN_NAME: string = 'Nombre';
  const COLUMN_CATEGORY: string = 'Categoría';
  const COLUMN_VARIANTS: string = 'Variantes';
  const COLUMN_BRANCH_OFFICE: string = 'Sucursal';
  const COLUMN_QUANTITY: string = 'Cantidad';
  const COLUMN_STATE: string = 'Estado';
  const COLUMN_SELECTION: string = 'Seleccionar';
  const COLUMN_VARIANT_TYPE: string = 'Tipo de variante';
  const COLUMN_SKU: string = 'SKU';
  const COLUMN_BASE_PRODUCT: string = 'Producto Base';
  const COLUMN_ATTRIBUTES: string = 'Atributos';
  const COLUMN_PRICE: string = 'Precio';

  const MAIN_1_ATTRIBUTE: string = 'Atributo principal 1:';
  const MAIN_2_ATTRIBUTE: string = 'Atributo principal 2:';

  const MAIN_VARIANT: string = 'PRINCIPAL';
  const STANDAR_VARIANT: string = 'SECUNDARIO';
  const WITHOUT_SKU_TEXT: string = 'S/I';

  const STATE_ACTIVE: string = 'ACTIVO';
  const STATE_INACTIVE: string = 'INACTIVO';

  const MAIN_TITLE: string = 'Carga Masiva :';
  const MAIN_TITLE_LABEL_BASE_PRODUCT: string = 'Productos Base';
  const MAIN_TITLE_DETAILS_BASE_PRODUCT: string =
    'Revisa, elige y publica los productos base del archivo para actualizar tu inventario. Al desmarcar  un producto base, se desactivarán automáticamente todas sus variantes asociadas.';
  const MAIN_TITLE_LABEL_VARIANT: string = 'Variantes';
  const MAIN_TITLE_DETAILS_VARIANT: string =
    'Revisa, elige y publica las variantes del archivo para actualizar tu inventario. Al desmarcar una variante principal, se desactivará automáticamente el producto base y todas sus variantes';

  const INFOBOX_1_TITLE: string = 'Importados:';
  const INFOBOX_2_TITLE: string = 'Seleccionados:';
  const INFOBOX_3_TITLE: string = 'Errores:';
  const INFOBOX_3_LINK: string = 'Ver detalles';

  const CANCEL_BUTTON_TEXT: string = 'Cancelar';
  const PUBLISH_BUTTON_TEXT: string = 'Publicar';

  const MODAL_RELOAD_BUTTON_TEXT: string = 'CARGAR OTRO ARCHIVO';
  const MODAL_CONTINUE_BUTTON_TEXT: string = 'OMITIR Y CONTINUAR';

  const MODAL_FINDED_ERROR_TITLE: string = 'Errores encontrados';
  const MODAL_FINDED_ERROR_TEXT: string = 'Algunos de estos errores son:';
  const MODAL_FINDED_ERROR_DETAILS: string =
    'Para garantizar la calidad de la carga de productos, le recomendamos revisar los posibles errores detallados en el archivo adjunto. Puede descargar el archivo haciendo clic en el siguiente enlace .';
  const MODAL_COLUMN_1: string = 'Linea';
  const MODAL_COLUMN_2: string = 'Detalle';
  const MODAL_FINDED_ERROR_LINK: string = 'Descargar archivo';
  const MODAL_ERROR_SVG_URL: string = `${URL_IMAGES}/client/img_error.png`;
  const MODAL_ERROR_SERVER_TEXT: string =
    'Falló la publicación de los productos. Por favor, inténtelo nuevamente. Si el problema persiste, comuníquese con el soporte técnico.';
  const MODAL_ERROR_SERVER_BUTTON_TEXT: string = 'CARGAR ARCHIVO';
  const MODAL_CONFIRMATION_TEXT: string = '¿Estás seguro de que deseas publicar estos artículos base y sus variantes?';
  const MODAL_CONFIRMATION_DETAILS: string = '¡Publicación completa!';
  const MODAL_SUCCESSFUL_ICON_BASE_PRODUCT: string = 'Productos base publicados:';
  const MODAL_SUCCESSFUL_ICON_VARIANT: string = 'Variantes publicadas:';
  const MODAL_SUCCESSFUL_SECOND_BUTTON_TEXT: string = 'VER INVENTARIO';
  const MODAL_SUCCESSFUL_SVG_URL: string = `${URL_IMAGES}/client/publish-product.png`;
  const MODAL_CONFIRMATION_ICON_BASE_PRODUCT: string = 'Productos Base:';
  const MODAL_CONFIRMATION_ICON_VARIANT: string = 'Variantes:';
  const LOAGING_IMAGE_URL: string = `${URL_IMAGES}/client/doc-loading.png`;

  /** Estado inicial del modal opciones de usuario */
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);

  const LOADING_TEXT: string = 'Cargando archivo, espera un momento';

  return (
    <>
      <ValidationInterface
        menu={
          <ProductList
            baseProducts={baseProductForm.length}
            baseProductsStatus={isBaseProduct}
            onClickBaseProdcuts={() => setIsBaseProduct(true)}
            variants={variantForm.length}
            variantStatus={!isBaseProduct}
            onClickVariants={() => setIsBaseProduct(false)}
          />
        }>
        <MainTitle
          boldTitle={MAIN_TITLE}
          regularTitle={`${isBaseProduct ? MAIN_TITLE_LABEL_BASE_PRODUCT : MAIN_TITLE_LABEL_VARIANT}`}
          detailsText={`${isBaseProduct ? MAIN_TITLE_DETAILS_BASE_PRODUCT : MAIN_TITLE_DETAILS_VARIANT}`}
        />
        <Row className='pb-5 border-secondary-4 border-bottom border-2'>
          <Col lg={4}>
            <InfoBox
              iconName='ico-import'
              iconClass='svg-primary-1'
              text={`${INFOBOX_1_TITLE} ${isBaseProduct ? baseProductForm.length : variantForm.length}`}
              textClass='p-large-bold'
            />
          </Col>
          <Col lg={4}>
            <InfoBox
              iconName='ico-selection'
              iconClass='svg-primary-1'
              text={`${INFOBOX_2_TITLE} ${isBaseProduct ? baseProductSelectedRows : variantSelectedRows}`}
              textClass='p-large-bold'
            />
          </Col>
          <Col lg={4}>
            <InfoBox
              iconName='ico-error'
              iconClass='svg-primary-1'
              text={`${INFOBOX_3_TITLE} ${errorArray?.length ?? 0}`}
              textClass='p-large-bold text-complementary-2'
              link={errorArray && errorArray.length > 0 ? INFOBOX_3_LINK : ''}
              onClick={errorArray && errorArray.length > 0 ? handleErrorShowModal : undefined}
            />
          </Col>
        </Row>

        <Row className='my-5'>
          <Col md={6} className='ms-auto'>
            <TwoButtonsGroup
              firstButtonText={CANCEL_BUTTON_TEXT}
              firstButtonClick={() => navigate(RoutesDirections.MASSIVE_CHARGE_ROUTE, { state: { originURL: location.pathname } })}
              firstButtonClass='btn-secondary-text-standard'
              secondButtonText={PUBLISH_BUTTON_TEXT}
              secondButtonClass={`btn-primary-text-standard ${baseProductSelectedRows === 0 && 'disabled'}`}
              secondButtonClick={() => setConfirmationModal(true)}
            />
          </Col>
        </Row>

        <Row>
          {isBaseProduct ? (
            <TableCustom
              dataTable={baseProductForm}
              columns={baseProductsColumns}
              sortingState={[{ id: 'name', desc: false }]}
              visibilityState={{ id: false, disabled: false }}
              rowSelection={baseProductSelection}
              updateRowSelection={updateBaseProductSelectionRef}
              specialAction={handleBaseProductSelection}
            />
          ) : (
            <TableCustom
              dataTable={variantForm}
              columns={variantsColumns}
              sortingState={[{ id: 'name', desc: false }]}
              visibilityState={{ id: false, disabled: false }}
              rowSelection={variantsSelection}
              updateRowSelection={updateVariantsSelectionRef}
              specialAction={handleVariantsSelection}
            />
          )}
        </Row>
      </ValidationInterface>
      {/**INICIO MODALES*/}
      <ModalError
        showModal={showErrorModal}
        handleShowModal={handleErrorShowModal}
        buttons={
          <Row className='d-flex align-items-center px-4 mb-4 justify-content-end g-0'>
            {/* SE VA A REVISAR DESPUES DEL MVP
                  }<Col className='text-center'>
                    <div>
                      <span className='link-text-standard p-title-medium'>
                        {obtainHelpText}
                      </span>
                    </div>
              </Col>{*/}
            <Col lg={8}>
              <TwoButtonsGroup
                firstButtonText={MODAL_RELOAD_BUTTON_TEXT}
                firstButtonClass='btn-secondary-text-small px-3 '
                firstButtonClick={() => navigate(RoutesDirections.MASSIVE_CHARGE_ROUTE, { state: { originURL: location.pathname } })}
                secondButtonText={MODAL_CONTINUE_BUTTON_TEXT}
                secondButtonClass='btn-primary-text-small px-3'
                secondButtonClick={handleErrorShowModal}
              />
            </Col>
          </Row>
        }>
        <Container className='p-4 px-4 mt-0'>
          <Row>
            <Col className='d-flex'>
              <IconList title={MODAL_FINDED_ERROR_TITLE} classTitle='h5 fw-bold' iconName='ico-error' />
              <span className='h5 fw-bold text-complementary-2 mx-2'>{counterError}</span>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <p className='text-left p-large-bold'>{MODAL_FINDED_ERROR_TEXT}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead className='bg-tertiary-3 text-primary-4 p-regular-bold header text-left'>
                  <tr>
                    <th>{MODAL_COLUMN_1}</th>
                    <th>{MODAL_COLUMN_2}</th>
                  </tr>
                </thead>
                <tbody>
                  {errorArray.slice(0, 4).map((error) => (
                    <tr key={error}>
                      <td className='p-regular-bold'>{getErrorLine(error)}</td>
                      <td className='p-regular-medium'>{getErrorInfo(error)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <span className='p-large-regular'>{MODAL_FINDED_ERROR_DETAILS}</span>
                <span
                  className='link-text-standard p-title-medium mx-2'
                  onClick={() => {
                    downloadTxt(errorMessageFormatter(errorArray), 'errores_carga_masiva.txt');
                  }}>
                  {MODAL_FINDED_ERROR_LINK}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalError>
      <Modal
        show={serverErrorModal}
        onHide={undefined}
        dialogClassName='modal-dialog-centered'
        contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
        size='lg'>
        <Container fluid className='px-0 g-0 '>
          <Row xs={12} className='d-flex justify-content-center g-0 '>
            <Col xs={12} className='d-flex justify-content-center mt-4 '>
              <Image
                src={MODAL_ERROR_SVG_URL}
                className='mb-3'
                width={213}
                height={203}
                alt='gif'
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = ErrorImage;
                }}
              />
            </Col>
            <Col xs={7} className='d-flex justify-content-center mb-4 text-primary-3 g-0 px-2'>
              <span className='p-title-bold'>{MODAL_ERROR_SERVER_TEXT}</span>
            </Col>
            <Col xs={12} className='d-flex justify-content-center'>
              <Button className='btn-primary-text-small  px-5 mt-4 ' onClick={handleNavigate}>
                <span>{MODAL_ERROR_SERVER_BUTTON_TEXT}</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Col xs={12} className='align-self-end pt-2 '>
          <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
      <Modal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        dialogClassName='modal-dialog-centered'
        contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
        size='lg'>
        <Container fluid className='px-0 g-0 '>
          <Row className='d-flex justify-content-center g-0 '>
            <Col xs={5} className='mt-5 pt-3'>
              <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0 px-2 '>
                <span className='p-title-bold'>{MODAL_CONFIRMATION_TEXT}</span>
              </Col>

              <Col className='d-flex  justify-content-center '>
                <IconList
                  iconName='ico-product-base'
                  classMain='p-2 text-secondary-3 mt-3 '
                  classTitle='p-regular'
                  title={MODAL_CONFIRMATION_ICON_BASE_PRODUCT}
                />

                <Col xs={4} className='d-flex  justify-content-center '>
                  <span className='d-flex align-content-center flex-wrap px-5  text-secondary-3 p-title-bold mt-3'>{baseProductSelectedRows}</span>
                </Col>
              </Col>

              <Col className='d-flex   justify-content-center'>
                <IconList
                  iconName='ico-variant'
                  classMain='p-2  text-secondary-3 mx-3'
                  classTitle='p-regular'
                  title={MODAL_CONFIRMATION_ICON_VARIANT}
                />
                <Col xs={5} className='d-flex  justify-content-center ms-1 '>
                  <span className='d-flex align-content-center flex-wrap px-3  text-secondary-3 p-title-bold mt-3'>{variantSelectedRows}</span>
                </Col>
              </Col>

              <Col className='d-flex justify-content-center mb-4 g-0 px-2 mt-5'>
                <TwoButtonsGroup
                  firstButtonText={CANCEL_BUTTON_TEXT}
                  firstButtonClick={() => setConfirmationModal(false)}
                  firstButtonClass='btn-secondary-text-small'
                  secondButtonText={PUBLISH_BUTTON_TEXT}
                  secondButtonClass={`btn-primary-text-small ${baseProductSelectedRows === 0 && 'disabled'}`}
                  secondButtonClick={postInventory}
                />
              </Col>
            </Col>
          </Row>
        </Container>
        <Col xs={12} className='align-self-end pt-2 '>
          <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
      <Modal
        show={successfulModal}
        dialogClassName='modal-dialog-centered'
        contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
        size='lg'>
        <Container fluid className='px-0 g-0 '>
          <Row className='d-flex justify-content-center g-0  '>
            <Col xs={8} className='mt-5 pt-3'>
              <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0 px-2 '>
                <Image
                  src={MODAL_SUCCESSFUL_SVG_URL}
                  width={134}
                  alt='gif'
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = ErrorImage;
                  }}
                />
              </Col>
              <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0 px-2 '>
                <span className='p-title-bold text-primary-3 p-large'>{MODAL_CONFIRMATION_DETAILS}</span>
              </Col>

              <Col className='d-flex  justify-content-center mx-5 px-4 ms-5'>
                <IconList
                  iconName='ico-selection'
                  classMain='p-2  mt-3 '
                  classTitle='text-secondary-3 p-regular-medium '
                  title={MODAL_SUCCESSFUL_ICON_BASE_PRODUCT}
                  classIcon='svg-secondary-3'
                />

                <Col xs={5} className='d-flex  justify-content-end px-0'>
                  <span className='d-flex align-content-center flex-wrap px-5 ms-1  text-secondary-3 p-title-bold mt-3'>
                    {baseProductSelectedRows}
                  </span>
                </Col>
              </Col>

              <Col className='d-flex justify-content-center mx-4 ms-4  px-1'>
                <IconList
                  iconName='ico-selection'
                  classMain='p-2 mx-1 text-secondary-3'
                  classTitle='text-secondary-3 p-regular-medium '
                  title={MODAL_SUCCESSFUL_ICON_VARIANT}
                  classIcon='svg-secondary-3'
                />
                <Col xs={5} className='d-flex  justify-content-end px-1'>
                  <span className='d-flex align-content-center flex-wrap px-5  text-secondary-3 p-title-bold mt-3 '>{variantSelectedRows}</span>
                </Col>
              </Col>

              <Col className='d-flex justify-content-center mb-4 g-0 px-2 mt-5 px-5'>
                <TwoButtonsGroup
                  firstButtonText={MODAL_RELOAD_BUTTON_TEXT}
                  firstButtonClick={() => {
                    navigate(RoutesDirections.MASSIVE_CHARGE_ROUTE, { state: { originURL: location.pathname } });
                  }}
                  firstButtonClass='btn-secondary-text-standard'
                  secondButtonText={MODAL_SUCCESSFUL_SECOND_BUTTON_TEXT}
                  secondButtonClass={`btn-primary-text-standard py-2 ${baseProductSelectedRows === 0 && 'disabled'}`}
                  secondButtonClick={() => {
                    navigate(RoutesDirections.INVENTORY_ROUTE, { state: { originURL: location.pathname } });
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Container>
        <Col xs={12} className='align-self-end pt-2 '>
          <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
      <GifModal
        showModal={showLoadingModal}
        handleShowModal={() => setShowLoadingModal(true)}
        image={
          <Image
            src={LOAGING_IMAGE_URL}
            width={191}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{LOADING_TEXT}</span>}
      />
    </>
  );
};

export default AddProductsValidationInterface;
