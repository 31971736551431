import { Col, Container, Row } from 'react-bootstrap';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import generateNumberId from '@/domain/utils/generateNumberId';

const HomeLoading = (): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  return (
    <>
      {isMobile ? (
        <Container fluid className='p-0 '>
          <Row className='gradient-loading mh-200-px g-0 shadow rounded '></Row>

          <Row className=' px-4 g-0  '>
            {[...Array(3)].map((_) => (
              <Col className='text-center gradient-loading mx-2 py-4 my-4 rounded-pill' key={`gradient-categoria-${generateNumberId()}}`}></Col>
            ))}
          </Row>

          <Container fluid className='py-3 '>
            <Row className='g-0 '>
              <Col>
                <Row>
                  <Col xs={12} className='shadow rounded  '>
                    <Row>
                      <Col xs={12} className='gradient-loading mh-150-px rounded'></Col>
                    </Row>
                  </Col>
                </Row>

                <Row xs={12} className='d-flex justify-content-between mh-265-px mt-4  '>
                  <Col xs={1} className='gradient-loading shadow rounded '></Col>
                  <Col xs={4} className='gradient-loading shadow rounded '></Col>
                  <Col xs={4} className='gradient-loading shadow rounded '></Col>
                  <Col xs={1} className='gradient-loading shadow rounded '></Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      ) : (
        <Container fluid className='p-0'>
          <Row className='gradient-loading mh-300-px '></Row>

          <Row className='px-5 mx-5 '>
            <Col lg={7} className='mx-auto d-flex justify-content-center'>
              {[...Array(6)].map((_) => (
                <Col
                  xs={2}
                  className='text-center gradient-loading  mx-5 my-4 py-4 rounded-pill'
                  key={`gradient-categoria-${generateNumberId()}}`}></Col>
              ))}
            </Col>
          </Row>

          <Container fluid className='py-3 px-1'>
            <Row className='g-0'>
              <Col className=' px-5'>
                <Row className='mx-5'>
                  <Col lg={12} className='shadow rounded  '>
                    <Row>
                      <Col md={12} className='gradient-loading mh-95-px rounded'></Col>
                    </Row>
                  </Col>
                </Row>

                <Row lg={12} className='mx-2 mt-4 '>
                  {[...Array(5)].map((_) => (
                    <Col key={`gradient-novedades${generateNumberId()}`}>
                      <Col lg={12} className='mx-auto gradient-loading mh-400-px mb-3 rounded'></Col>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};

export default HomeLoading;
