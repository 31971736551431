import { PropsWithChildren, useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { change } from '@/ui/store/slices/catalogFilterState';

import Icon from '@/ui/assets/Icon';

import FavoritesWidget from '@/ui/components/product/FavoritesWidget';
import CartWidget from '@/ui/components/sales/CartWidget';
import LoginForm from '@/ui/components/user/LoginForm';
import SignUpForm from '@/ui/components/user/SignUpForm';
import ModalMenuMobile from '@/ui/components/mobile/ModalMenuMobile';
import ModalNavUser from '@/ui/containers/userOptions/ModalNavUser';
import CartModal from '@/ui/components/modals/CartModal';

import './style.css';

/**
 * Componente que se utiliza para mostrar el Nav de los usuarios
 * @component
 */

/** Componente que se utiliza para mostrar el Nav de los usuarios */
const NavMobile = (): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Función para volver a la posición inicial */
  const scrollToZero = (): void => {
    window.scrollTo(0, 0);
  };

  /** Actualización de estado Redux */
  const dispatch = useAppDispatch();

  /** Estado inicial del modal para Login */
  const [showLogin, setShowLogin] = useState<boolean>(false);
  /** Evento que muestra y oculta el modal para login*/
  const handleShowLogin = (): void => setShowLogin(!showLogin);

  /** Estado inicial del modal para Registro */
  const [showRegister, setShowRegister] = useState<boolean>(false);
  /** Evento que muestra y oculta el modal para registro*/
  const handleShowRegister = (): void => setShowRegister(!showRegister);

  /** Estado inicial del modal para configuración de usuario */
  const [showOptionsUser, setShowOptionsUser] = useState<boolean>(false);
  /** Evento que muestra y oculta el modal para registro*/
  const handleShowOptionsUser = (): void => setShowOptionsUser(!showOptionsUser);

  /** Estado inicial del modal para configuración de usuario */
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  /** Evento que muestra y oculta el modal para registro*/
  const handleShowMenuMobile = (): void => setShowMenuMobile(!showMenuMobile);

  /** Estado inicial del modal de guía */
  const [showCartModal, setShowCartModal] = useState<boolean>(false);

  /**Estado de la modal de filtro */
  const showFilterModal: boolean = useAppSelector((state) => state.catalogFilterState.value);

  /** Función que muestra el modal de guía */
  const handleShowCartModal = (): void => {
    if (location.pathname === RoutesDirections.CART_ROUTE || location.pathname === RoutesDirections.CHECK_OUT_ROUTE) {
      return;
    }
    setShowCartModal(!showCartModal);
  };

  /** Función que cierra el modal de carrito */
  const closeModal = (): void => {
    setShowCartModal(false);
    /** Cierra el modal de filtro si esta activo */
    showFilterModal && dispatch(change(!showFilterModal));
  };

  /** Constante que define si es la ruta principal */
  const isMainRoute: boolean =
    !showCartModal && (location.pathname === RoutesDirections.MAIN_ROUTE || location.pathname === `${RoutesDirections.MAIN_ROUTE}/#`);

  /** Constante que define si es la ruta principal */
  const isCartOpen: boolean = location.pathname === RoutesDirections.CART_ROUTE || showCartModal;

  /** Renderiza el componente de NavMobile */
  return (
    <>
      <Container fluid className='nav-mobile fixed-bottom text-center bg-primary-5 pt-2 ultra-top'>
        <Row>
          <Col xs={12} className='p-0'>
            <ul className='list-inline m-0 d-flex justify-content-between svg-secondary-3 align-items-center '>
              <Col xs={6}>
                <li className='px-2 '>
                  <NavLink
                    to={RoutesDirections.MAIN_ROUTE}
                    state={{ originURL: location.pathname }}
                    className={({ isActive }) =>
                      (isActive || isMainRoute) && !isCartOpen ? 'd-flex justify-content-center active' : 'd-flex justify-content-center'
                    }>
                    <button
                      className='py-2 link-text-standard'
                      onClick={() => {
                        scrollToZero();
                        closeModal();
                      }}>
                      <Icon name='ico-home' />
                    </button>
                  </NavLink>
                </li>
              </Col>
              <Col xs={6}>
                <li className='px-2 '>
                  <a href='#' className={`d-flex justify-content-center ${isCartOpen ? 'active' : ''}`}>
                    <button className='py-2 link-text-standard' onClick={() => handleShowCartModal()}>
                      <CartWidget />
                    </button>
                  </a>
                </li>
              </Col>

              {/**  <NavLink to={'/favorites'} state={{ originURL: location.pathname }} className='flex-fill'>
              <li className='list-inline-item'>
                <FavoritesWidget classMain='py-2' />
              </li>
            </NavLink>*/}

              {/* <div onClick={!IsAOperMenu ? handleShowMenuMobile : undefined} className={`flex-fill ${IsAOperMenu && 'active'}`}>
              <li className='list-inline-item'>
                <div className='py-2'>
                  <Icon name='ico-menu' />
                </div>
              </li>
            </div> */}
            </ul>
          </Col>
        </Row>

        {showMenuMobile && (
          <ModalMenuMobile
            showMenuMobile={showMenuMobile}
            handleShowMenuMobile={handleShowMenuMobile}
            handleShowLogin={handleShowLogin}
            handleShowRegister={handleShowRegister}
            handleShowOptionsUser={handleShowOptionsUser}
          />
        )}
        {showLogin && <LoginForm showLogin={showLogin} handleShowLogin={handleShowLogin} handleShowRegister={handleShowRegister} />}
        {showRegister && <SignUpForm showRegister={showRegister} handleShowRegister={handleShowRegister} handleShowLogin={handleShowLogin} />}
        {showOptionsUser && <ModalNavUser showOptionsUser={showOptionsUser} handleShowOptionsUser={handleShowOptionsUser} fullscreen={true} />}
      </Container>

      <CartModal showModal={showCartModal} handleClose={handleShowCartModal} />
    </>
  );
};

export default NavMobile;
