import { Col, Container, Image, Row } from 'react-bootstrap';
import ErrorImage from '@/ui/assets/ErrorImage';

const ConstructionPage = () => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const IMAGE: string = `${URL_IMAGES}/client/update.png`;

  const DESCRIPTION_TEXT: string =
    ' Estamos actualizando nuestro sitio web! Pronto podrás descubrir nuestros productos y experiencias.¡Gracias por tu paciencia!';
  return (
    <Container fluid className='d-flex align-items-center justify-content-center g-0 p-0 min-vh-90 '>
      <Row className='g-0 '>
        <Col lg={12} className='text-center mb-4'>
          <Col xs={11} lg={5} className='mx-auto'>
            <Image
              src={IMAGE}
              alt='imagen-actualización'
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </Col>
        </Col>
        <Col lg={12} className='d-flex justify-content-center text-center  '>
          <Col lg={4} className='text-center h4 px-3'>
            {DESCRIPTION_TEXT}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ConstructionPage;
