import { createSlice } from '@reduxjs/toolkit';

const initialState = {value:false} 

/** Almacena el estado managementState de Redux con sus reducers
  * @constant {object}
*/
export const catalogFilterSlice = createSlice({
  name: 'catalogFilterState',
  initialState,
  reducers: {
    change: (state, action) => {
      state.value= action.payload;
    },
  }
})

export const { change } = catalogFilterSlice.actions;

export default catalogFilterSlice.reducer;
