import StatusList from '@/data/libraries/Status';

/**
 * Función para obtener el nombre del estado según el ID.
 *
 * @param {number} statusId - El ID del c buscado.
 * @return {string} El nombre del estado correspondiente.
 */
const getStatusById = (statusId: number): string => {
  // Buscamos en StatusList el elemento que tenga el id correspondiente al statusId
  const status = StatusList.find((status) => status.id === statusId);

  // Si se encuentra el estado, retornamos su nombre; de lo contrario, retornamos un mensaje de error o vacío
  return status ? status.name : 'S/I';
};

export default getStatusById;
