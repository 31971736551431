import { useEffect, useState, memo } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { useAppSelector } from '@/ui/store/helperRedux';
import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import UserController from '@/controllers/UserController';
import IdTypesController from '@/controllers/IdTypesController';

import { IUserResponse } from '@/domain/interfaces/ILoginResponse';
import { IIdTypes } from '@/domain/interfaces/IIdTypes';

import { Col, Container, Row } from 'react-bootstrap';
import '@/ui/containers/userOptions/UserOptionsContainer/style.css';

import CloseIcon from '@/ui/assets/CloseIcon';

import IconList from '@/ui/components/IconList';
import InputList from '@/ui/components/InputList';
import Title from '@/ui/components/Title';
import BackClose from '@/ui/components/BackClose';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import ModalUserInfo from '@/ui/components/user/ModalUserInfo';

/** Componente Lista de información de usuario
 * @component
 */
const InfoUserList = (): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /**
   * Función que redirige al usuario a la página de inicio.
   * @category Advanced Use
   */
  const goHome = (): void => {
    navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } });
  };

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Controlador que válida el email y token con el endpoint */
  const { getUser } = UserController();

  /** Controlador que obtiene los tipos de identificación */
  const { getIdTypes } = IdTypesController();

  /** Estado inicial de arreglo de tipos */
  const [idTypes, setIdTypes] = useState<IIdTypes[]>([]);

  /** Estado inicial del campo Documento  */
  const [lastName, setLastName] = useState<string>('');

  /** Estado inicial del campo id oficial  */
  const [idType, setIdType] = useState<string>('');

  /** Estado inicial del campo id oficial  */
  const [document, setDocument] = useState<string>('');

  /** Estado inicial del campo Cumpleaños  */
  const [birthday, setBirthday] = useState<string>('');

  /** Estado inicial del campo Telefono  */
  const [phone, setPhone] = useState<string>('');

  /** Estado inicial del campo ID  */
  const [userId, setId] = useState<number>(0);

  /** Estado para enviar valor al formulario */
  const [valueToShow, setValueToShow] = useState<string>('');

  /** Estado inicial del modal opciones de usuario */
  const [showUserInfo, setShowUserInfo] = useState<boolean>(false);

  /** Estado inicial del contenido llave del modal */
  const [contentKey, setContentKey] = useState<string>('email');

  const VERIFY_ACCOUNT_TEXT: string = 'Verifica o modifica la información asociada a tu cuenta';

  /** Evento que muestra y oculta el modal de opciones de usuario */
  const handleShowUserInfo = (): void => {
    setShowUserInfo(!showUserInfo);
  };

  /** Evento que asigna el key con el que se muestra el contenido del modal */
  const handleContentKey = (key: string): void => {
    setContentKey(key);
  };

  /** Muestra información del cliente */
  const user = async (): Promise<void> => {
    /** Uso del controlador useUserController con la funcion getUser */
    const userFinded: IUserResponse = await getUser(userLogued.user.email ?? '', userLogued.user.token ?? '');

    /** Si el flag de error esta apagado muestra la info */
    if (userFinded.isError === false) {
      if (userFinded.user!.surname !== undefined && userFinded.user!.surname !== null) {
        setLastName(userFinded.user!.surname.charAt(0).toUpperCase() + userFinded.user!.surname.slice(1).toLowerCase());
      }
      setDocument(userFinded.user!.identification);
      setBirthday(userFinded.user!.birthday);
      setPhone(userFinded.user!.phone);
      setId(userFinded.user!.id);
      setIdType(userFinded.user!.idType);
    }
  };

  /** Trae los tipos de identificación del back */
  const fetchIdTypes = async (): Promise<void> => {
    /** Uso del controlador useIdTypesController con la funcion getIdTypes */
    const getIdTypesDropdown: IIdTypes[] = await getIdTypes();
    setIdTypes(getIdTypesDropdown);
  };

  /** UseEffect que trae la información del usuario y tipos de identificación al renderizar el componente */
  useEffect((): void => {
    /** Trae información del cliente */
    user();

    /** Trae los tipos de identificación */
    fetchIdTypes();
  }, [userLogued.user.email, userLogued.user.token]);

  return (
    <>
      <Container>
        <Row>
          {isMobile && (
            <BackClose classMain='justify-content-end'>
              <Breadcrumbs />
              <CloseIcon classMain='btn-icon-light' onClick={goHome} />
            </BackClose>
          )}
          <Col xs={12}>
            <ul id='user-title' className='list-unstyled text-primary-3'>
              <Title>
                <IconList
                  iconName='ico-info'
                  title={'Información de mi cuenta'}
                  classMain='fw-bold'
                  classTitle={`${isMobile ? 'p-large-bold' : 'h5'}`}
                />
                <span className={` text-secondary-3 ${isMobile ? 'p-regular-medium' : 'p-large'}`}>{VERIFY_ACCOUNT_TEXT}</span>
              </Title>
            </ul>
          </Col>

          <ul id='info-options-list' className='list-unstyled'>
            <Row className='pt-2'>
              <Col xs={12}>
                <InputList
                  title='Correo'
                  className='text-truncate'
                  text={userLogued.user.email ?? ''}
                  iconName='ico-edit'
                  onClick={() => {
                    setValueToShow(userLogued.user.email ?? '');
                    handleShowUserInfo();
                    handleContentKey('email');
                  }}
                />
                <div className='text-capitalize text-truncate'>
                  <InputList
                    title='Nombre'
                    className='text-truncate'
                    text={`${userLogued.user.firstName ?? ''}  ${lastName ?? ''}`}
                    iconName='ico-edit'
                    onClick={() => {
                      setValueToShow(`${userLogued.user.firstName ?? ''}|${lastName ?? ''}` ?? '');
                      handleShowUserInfo();
                      handleContentKey('name');
                    }}
                  />
                </div>
                <InputList
                  title='Teléfono'
                  text={phone ?? ''}
                  iconName='ico-edit'
                  onClick={() => {
                    setValueToShow(phone ?? '');
                    handleShowUserInfo();
                    handleContentKey('phone');
                  }}
                />
                <InputList
                  title='Documento'
                  text={document ?? ''}
                  iconName='ico-edit'
                  onClick={() => {
                    setValueToShow(document !== null && document !== '' ? `${document}|${idType}` : '');
                    handleShowUserInfo();
                    handleContentKey('document');
                  }}
                />
                <InputList
                  title='Cumpleaños'
                  text={birthday ?? ''}
                  iconName='ico-edit'
                  onClick={() => {
                    setValueToShow(birthday ?? '');
                    handleShowUserInfo();
                    handleContentKey('birthdate');
                  }}
                />
                <InputList title='Código ID' text={`${userId}`} />
              </Col>
            </Row>
          </ul>

          <Row>
            <Col xs={12} className='text-center form-text'>
              <span className='p-regular-medium  link-text-standard'>Privacidad y confidencialidad de la información</span>
            </Col>
          </Row>
        </Row>
      </Container>
      {showUserInfo && (
        <ModalUserInfo
          showUserInfo={showUserInfo}
          inputValue={valueToShow}
          handleShowUserInfo={handleShowUserInfo}
          contentKey={contentKey}
          idTypes={idTypes}
          updateDataClient={user}
        />
      )}
    </>
  );
};

export default memo(InfoUserList);
