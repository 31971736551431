import CartLocalStorage from '@/data/localStorage/CartLocalStorage';
import InventoryLocalStorage from '@/data/localStorage/InventoryLocalStorage';
import InventoryRepository from '@/data/repositories/InventoryRepository';
import { ICart, IReservation, IReservationResponse } from '@/domain/interfaces/ICart';
import { IBaseProductInventory, IBulkLoadingResponse } from '@/domain/interfaces/IInventory';
import { IProductForm } from '@/domain/interfaces/IProduct';
import { AxiosResponse } from 'axios';

/** Representa el controlador que maneja los eventos de los casos de uso del inventario
 * @returns {object} Funciones del controlador
 */
const InventoryController = () => {
  /** Solicita los tipos de identificación del back
   * @param {string} token - token valido
   * @returns {IBaseProductInventory} inventory
   */
  const getBaseProductInventory = async (token: string = ''): Promise<IBaseProductInventory[] | null> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;

    try {
      const result = await inventoryRepository.getProductBaseInventory(token);
      return result.data;
    } catch {
      return null;
    }
  };

  const getSelectedBaseProductInventory = (): { [index: number]: string } | null => {
    /** Instancia del Repository
     * @const {InventoryLocalStorage}
     */
    const inventoryRepository = InventoryLocalStorage.instance;
    const result = inventoryRepository.getObj();
    const inventory: { [index: number]: string } | null = result;

    return inventory;
  };

  const updateSelectedBaseProductInventory = (objSelectedRows: { [index: number]: string }): void => {
    /** Instancia del Repository
     * @const {InventoryLocalStorage}
     */
    const inventoryRepository = InventoryLocalStorage.instance;
    inventoryRepository.updateObj(objSelectedRows);
  };

  const getInventoryTemplate = async (token: string = ''): Promise<Blob | null> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;
    try {
      const result = await inventoryRepository.getInventoryTemplate(token);
      /** Crea un Blob a partir del array buffer que devolvió el API */
      const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      return blob;
    } catch {
      return null;
    }
  };

  /**
   * Invoca al repository para enviar carga masiva al back
   * @param {IProductForm[]} productsForm
   * @param {string} token
   * @returns {IBulkLoadingResponse}
   */
  const inventoryBulkLoading = async (productsForm: IProductForm[], token: string = ''): Promise<IBulkLoadingResponse | null> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;

    try {
      const responseController = await inventoryRepository.inventoryBulkLoading(productsForm, token);
      return responseController.data;
    } catch (error) {
      return null;
    }
  };

  /**
   * Invoca al repository para enviar una reserva de productos
   * @param {IReservation} reservation
   * @returns {IReservationResponse}
   */
  const productReservation = async (reservation: IReservation): Promise<IReservationResponse | null> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;

    /** Instancia del Repository Local Storage
     * @const {CartLocalStorage}
     */
    const cartRepository = CartLocalStorage.instance;

    try {
      /** Realiza la reserva de productos  */
      const responseController = await inventoryRepository.productsReservation(reservation);

      /** Obtiene la respuesta */
      const responseReservation: IReservationResponse = responseController.data;

      /** Si se logró realizar la reserva se actualiza el carrito con el id de la reserva */
      if (responseReservation.reservationId && responseReservation.reservationId !== null) {
        const actualCart: ICart | null = cartRepository.getCart();
        if (actualCart !== null) {
          actualCart.reservationId = responseController.data.reservationId;
          cartRepository.updateCart(actualCart);
        }
      }

      /** Devuelve la respuesta del back */
      return responseReservation;
    } catch (error) {
      return null;
    }
  };

  /**
   * Invoca al repository para enviar una reserva de productos
   * @param {number} reservationId
   * @returns {IReservationResponse}
   */
  const productDeleteReservation = async (reservationId: number): Promise<boolean> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;

    /** Instancia del Repository Local Storage
     * @const {CartLocalStorage}
     */
    const cartRepository = new CartLocalStorage;

    try {
      if (reservationId > 0) {
        /** Realiza la eliminación de la reserva de productos  */
        const responseController = await inventoryRepository.productsDeleteReservation(reservationId);
        if (responseController.status === 200 || responseController.status === 204) {
          /** Si se logró realizar la reserva se actualiza el carrito con el id de la reserva */
          const actualCart: ICart | null = cartRepository.getCart();
          if (actualCart) {
            actualCart.reservationId = null;
            cartRepository.updateCart(actualCart);
          }
        }
      } 
      /** Devuelve la respuesta del back */ 
      return true;
    } catch (error) {
      return false;
    }
  };

  const checkReservation = async (reservationId: number): Promise<number> => {
    /** Instancia del Repository
     * @const {InventoryRepository}
     */
    const inventoryRepository = InventoryRepository.instance;

    try {
      /** Realiza la eliminación de la reserva de productos  */
      const responseController: AxiosResponse = await inventoryRepository.checkReservation(reservationId);

      if (responseController.status === 200) {
        return responseController.data;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  /**
   * retorna la funciones del controlador
   */
  return {
    getBaseProductInventory,
    getSelectedBaseProductInventory,
    updateSelectedBaseProductInventory,
    getInventoryTemplate,
    inventoryBulkLoading,
    productReservation,
    productDeleteReservation,
    checkReservation,
  };
};

export default InventoryController;

/**
 * Invoca al repository para enviar carga masiva al back y validar
 * @param {IProductForm[]} productsForm
 * @param {string} token
 * @returns {IBulkLoadingResponse}
 */
export const inventoryBulkLoadingValidation = async (productsForm: IProductForm[], token: string = ''): Promise<IBulkLoadingResponse | null> => {
  /** Instancia del Repository
   * @const {InventoryRepository}
   */
  const inventoryRepository = InventoryRepository.instance;

  const responseController = await inventoryRepository
    .inventoryBulkLoadingValidation(productsForm, token)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });

  return responseController;
};
