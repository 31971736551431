import { useEffect, useRef, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';

import { Col, Container, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import ShippingTypesController from '@/controllers/ShippingTypesController';
import PaymentController from '@/controllers/PaymentController';
import ProductsController from '@/controllers/ProductsController';

import { IPathCategoryType } from '@/domain/interfaces/ICategories';
import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IPaymentMethods, IProductForm, IShippingTypes } from '@/domain/interfaces/IProduct';

import getFileURL from '@/domain/utils/getFileURL';

import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import InputForm from '@/ui/components/forms/InputForm';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import SwitchCheckbox from '@/ui/components/forms/SwitchCheckbox';
import PillText from '@/ui/components/PillText';
import IconList from '@/ui/components/IconList';
import DragAndDrop from '@/ui/components/DragAndDrop';
import UrlPresenter from '@/ui/components/formWizard/UrlPresenter';
import MainTitle from '@/ui/components/MainTitle';

/** Propiedades del componente */
interface PropsBaseProductDescription {
  /**Títulos pasados por props de AddProducts */
  //titulo tab
  mainTitle: string;
  //Titulo de producto
  productTitle: string;
  //Texto de clasificación
  classificationLabelText: string;
  //Texto de botón GUARDAR Y CONTINUAR
  nextButtonText: string;
  //Texto de botón CANCELAR
  cancelButtonText: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Objeto que representa la categoría referenciada */
  pathCategoryState: IPathCategoryType[];
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

/**
 * Componente para configurar la sección de Descripción de Producto Base
 * @component
 */
const BaseProductDescription = ({
  mainTitle,
  productTitle,
  classificationLabelText,
  nextButtonText,
  cancelButtonText,
  formRef,
  pathCategoryState,
  updateFormRef,
  handleNextButton,
  handleCancelButton,
}: PropsBaseProductDescription): JSX.Element => {
  /** Parámetro de Configuración */

  /**Cantidad máxima de tags permitidas */
  const BASE_PRODUCT_TAG_MAX_QUANTITY = ConfigNumberParameters.BASE_PRODUCT_TAG_MAX_QUANTITY;
  /**longitud maxima de la descripción de un producto*/
  const BASE_PRODUCT_DESCRIPTION_MAX_LENGTH = ConfigNumberParameters.BASE_PRODUCT_DESCRIPTION_MAX_LENGTH;

  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Controlador de Tipos de envío */
  const { getShippingTypes } = ShippingTypesController();

  /** Data de los métodos de pago disponibles en el sistema */
  const shippingTypesArray: IShippingTypes[] = getShippingTypes();

  /** Controlador de métodos de Pago */
  const { getPaymentMethods } = PaymentController();

  /** Data de los impuestos disponibles en el sistema */
  const paymentMethodsArray: IPaymentMethods[] = getPaymentMethods();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Referencia al input descripción */
  const descriptionRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /**Estado de error del input de descripción */
  const [descriptionInputWithError, setDescriptionInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de error como vacíos para el campo de descripción  */
  const [descriptionMessageError, setDescriptionMessageError] = useState<string>('');

  /** Utiliza useState para mantener el estado del contador de caracteres */
  const [characterCount, setCharacterCount] = useState<number>(0);

  /** Referencia al input url */
  const urlBannerRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado que muestra el contador de archivos */
  const [urlBanner, setUrlBanner] = useState<string>(urlBannerRef.current ? urlBannerRef.current.value : '');

  /**Estado de error del input de url Banner */
  const [urlBannerWithError, setUrlBannerWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de URL  */
  const [urlBannerMessageError, setUrlBannerMessageError] = useState<string>('');

  /** State para guardar el archivo */
  const [bannerFile, setBannerFile] = useState<File | null>(null);

  /** Estado que muestra el contador de archivos */
  const [mediaCounter, setMediaCounter] = useState<number>(0);

  /** Referencia al input tag  */
  const tagRef = useRef<HTMLInputElement>(null);

  /**Estado de error del input de tags */
  const [tagInputWithError, setTagInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de error como vacíos para el campo de tags  */
  const [tagMessageError, setTagMessageError] = useState<string>('');

  /** Estado para habilitar el botón de siguiente paso */
  const [isAValidData, setIsAValidData] = useState<boolean>(false);

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /** Estado del valor del switch del tipo de envío */
  const [shippingTypeSwitch, setShippingTypeSwitch] = useState<number[]>([]); //este valor se envía por defecto ya que solo tenemos un método de envío

  /** Estado que renderiza los tags colocados */
  const [tagList, setTagList] = useState<string[]>([]);

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Función para contar caracteres */
  const countCharacters = () => {
    if (descriptionRef.current) {
      const count = descriptionRef.current.value.length;
      setCharacterCount(count);
    }
  };

  /** Función que se ejecuta al escribir en el textArea */
  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    descriptionRef.current!.value = descriptionRef.current ? e.target.value : '';
    countCharacters();
    handleValidate();
  };

  /** Función que actualiza el archivo */
  const updateBannerFile = (newFile: File | null) => {
    setBannerFile(newFile);
    handleValidate();
  };

  /** Maneja el evento al cambiar del input */
  const handleBannerFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Guarda el archivo
    const selectedFile = e.target.files && e.target.files[0];
    setBannerFile(selectedFile);
    handleValidate();
  };

  /** Maneja el evento al soltar el archivo */
  const handleBannerFileDrop = (attachedMedia: File[]): void => {
    if (attachedMedia.length) {
      setBannerFile(attachedMedia[0]);
    }
    handleValidate();
  };

  /** Función para manejar el cambio de URL y la validación en un solo lugar */
  const handleBannerUrlChange = (value: string) => {
    if (urlBannerRef.current) {
      urlBannerRef.current.value = value;
    }
    setUrlBanner(value);
    handleValidate();
  };

  /**handle para limpiar el input de url*/
  const handleCleanUrlBanner = () => {
    const value: string = urlBannerRef.current?.value.trim() ?? '';
    if (value.length === 0) {
      setUrlBannerWithError(false);
      setUrlBannerMessageError('');
    }
  };

  /** En el evento onBlur se valida  que si no existe el dato se coloque vacío */
  const handleBlurUrlBanner = () => {
    if (urlBanner === '') {
      urlBannerRef.current && (urlBannerRef.current.value = '');
      handleValidate();
    }
  };

  /** Función que desenfoca el input cuando se presiona enter o Tab */
  const handleBannerUrlKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      handleBannerUrlChange(urlBannerRef.current?.value ?? '');
    }
  };

  /**Función que se ejecuta al pegar en el Banner la data */
  const handleBannerUrlPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto
    const pastedData = e.clipboardData.getData('text/plain');
    handleBannerUrlChange(pastedData);
  };

  /** Función que elimina url de la barra */
  const handleResetBannerUrl = (): void => {
    setMediaCounter(0);
    setUrlBanner('');
    urlBannerRef.current!.value = '';
  };

  /** Función que se ejecuta al presionar Enter o Tab */
  const handleChangeTags = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      const tags: string[] = [...tagList];
      if (tagRef.current && tagList.length < BASE_PRODUCT_TAG_MAX_QUANTITY) {
        tags.push(tagRef.current.value.trim().toLowerCase() ?? '');
        tagRef.current.value = '';
        setTagList(tags);
      }
    }
  };

  /** Función que se ejecuta al eliminar el tag */
  const handleDeleteTag = (index: number) => {
    const updatedTagList = [...tagList];
    updatedTagList.splice(index, 1); // Elimina la etiqueta en el índice dado
    setTagList(updatedTagList);
    handleValidate();
  };

  /** Evento que envía el formulario cuando se presiona el botón */
  const handleSendInfo = async () => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /**función que borra los espacios en blanco al principio y al final de la descripción*/
  const handleBlurDescription = (e: React.FocusEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (descriptionRef.current) {
      const trimmedValue = descriptionRef.current.value.trim();
      descriptionRef.current.value = trimmedValue;
      countCharacters();
    }
  };

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    /** Establece el formulario a actualizar */
    const formProduct: IProductForm = formRef.current
      ? JSON.parse(JSON.stringify(formRef.current))
      : JSON.parse(JSON.stringify(productBaseFormClean));

    formProduct.description = descriptionRef.current?.value ?? '';
    formProduct.tags = [...tagList];
    formProduct.shippingTypes = [...shippingTypeSwitch];
    //Eliminar la función Replace cuando se agregué la funcionalidad del repositorio
    formProduct.urlBanner = bannerFile
      ? getFileURL(bannerFile).url.split('blob:')[1].replace('localhost', 'ilisplace.cl')
      : urlBanner !== ''
      ? urlBanner
      : urlBannerRef.current?.value;

    // Valida desde el controlador
    await addProductBase(formProduct, false, 'baseProductDescription', userLogued.user?.token ?? '')
      .then((response: IProductValidation) => {
        // Si hay error en la descripción se muestra
        if (response.error && response.kindError?.descriptionError && response.kindError?.descriptionError !== '') {
          setDescriptionInputWithError(true);
          setDescriptionMessageError(response.kindError.descriptionError);
        } else {
          setDescriptionInputWithError(false);
          setDescriptionMessageError('');
        }

        // Si hay error en los tags se muestra
        if (response.error && response.kindError?.tagError && response.kindError?.tagError !== '') {
          setTagInputWithError(true);
          setTagMessageError(response.kindError.tagError);
          if (tagRef.current != null) {
            tagRef.current.disabled = true;
          }
        } else {
          setTagMessageError('');
          setTagInputWithError(false);
          if (tagRef.current != null) {
            tagRef.current.disabled = false;
          }
        }

        // Si hay error en la url se muestra
        if (!bannerFile || !(bannerFile instanceof File)) {
          if (response.error && response.kindError?.urlBanner && response.kindError?.urlBanner !== '') {
            setUrlBannerWithError(true);
            setUrlBannerMessageError('Url con formato errado, Ejemplo: (https://foo.com/bar)');
            setUrlBanner('');
          } else {
            setUrlBannerWithError(false);
            setUrlBannerMessageError('');
            if (urlBannerRef.current != null && urlBannerRef.current!.value.length > 0) {
              urlBannerRef.current.disabled = false;
              setMediaCounter(1);
            }
          }
        }

        // Si no hay error pasa
        if (response.error) {
          setIsAValidData(false);
        } else {
          setIsAValidData(true);
          send && updateFormRef(formProduct);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      descriptionRef.current!.value = formRef.current?.description ?? '';
      countCharacters();
      formRef.current.tags?.length && setTagList(formRef.current.tags);
      formRef.current.shippingTypes?.length && setShippingTypeSwitch(formRef.current.shippingTypes);
      formRef.current.urlBanner && setUrlBanner(formRef.current.urlBanner);
      formRef.current.urlBanner!.length > 0 && setMediaCounter(1);
      formRef.current.tags!.length > 0 && setTagList(formRef.current?.tags ?? []);
      handleValidate();
    }
    window.scrollTo(0, 0);
  }, []);

  /** UseEffect para actualizar el archivo cada vez que cambia */
  useEffect((): void => {
    if (bannerFile && bannerFile instanceof File) {
      setBannerFile(bannerFile);
      urlBannerRef.current!.value = '';
      setUrlBannerWithError(false);
      setUrlBannerMessageError('');
      setMediaCounter(1);
    } else {
      setMediaCounter(0);
    }
    handleValidate();
  }, [bannerFile]);

  // Valida las tags
  useEffect(() => {
    handleValidate();
  }, [tagList]);

  /**CONSTANTES DE TEXTO */

  const REQUIRED_TEXT: string = '(Obligatorio)';

  const BASE_PRODUCT_DESCRIPTION_DETAILS: string =
    'Agrega las imágenes del producto, ingresa las características, adjunta el banner publicitario y agrega los tags que permitan a tus clientes buscar y filtrar.';
  const CHARACTERISTIC_LABEL: string = `Características ${REQUIRED_TEXT}`;

  const SHIPPING_TYPES_LABEL: string = 'Tipo  de envío';
  const SHIPPING_TYPES_DESCRIPTION: string = 'Este es el tipo de envió que estará habilitado para este producto y sus variantes.';

  const PAYMENT_METHODS_LABEL: string = 'Método de pago';
  const PAYMENT_METHODS_DESCRIPTION: string = 'Este es el método de pago que estará habilitado para este producto y sus variantes.';

  const BANNER_LABEL: string = 'Banner publicitario';
  const BANNER_DESCRIPTION: string = 'Agregue la imagen correspondiente al banner de marca del producto o pauta publicitaria.';
  const BANNER_TYPE_ERROR: string = 'El archivo que intentas cargar no es un archivo tipo media.';
  const BANNER_MEDIA_ADD: string = 'Agregados: ';
  const BANNER_MEDIA_MAX_RANGE: string = '1';

  const BANNER_MEDIA_LABEL: string = 'Banner publicitario';
  const BANNER_MEDIA_URL_LABEL: string = 'URL Banner publicitario';
  const BANNER_MEDIA_URL_DESCRIPTION: string =
    'Solo se admiten enlaces públicos de imágenes en este campo de texto, presiona "Enter" o "Tab" para guardar la acción';

  const TAGS_LABEL: string = 'TAGS de Búsqueda';
  const TAGS_DESCRIPTION: string =
    'Los TAGS de búsqueda son palabras clave que permiten a los clientes buscar y filtrar productos fácilmente, agrega por cada producto base los TAGS que consideres necesarios, presiona ENTER para agregar cada TAG.';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <MainTitle boldTitle={mainTitle} detailsText={BASE_PRODUCT_DESCRIPTION_DETAILS} />
        <Col lg={12} className='mb-3'>
          <span className='p-title-medium  me-2'>{productTitle}</span>
          <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
        </Col>
        <span className='p-regular-medium'>{classificationLabelText}</span>
        <Col lg={12} className='bg-secondary-4 border border-1 p-2 rounded rounded-2'>
          <CategoryPath pathState={pathCategoryState} referenced />
        </Col>
        <Col lg={12} className='my-4 text-end'>
          <InputForm
            className='mb-3'
            inputRef={descriptionRef}
            label={CHARACTERISTIC_LABEL}
            classLabel='p-regular-medium'
            withErrors={descriptionInputWithError}
            messageError={descriptionMessageError}
            type='textarea'
            rowsValue={10}
            onChange={handleChangeDescription}
            onBlur={handleBlurDescription}
          />
          <span
            className={`p-regular-bold ${
              characterCount >= BASE_PRODUCT_DESCRIPTION_MAX_LENGTH && 'text-complementary-2'
            }`}>{`${characterCount}/${BASE_PRODUCT_DESCRIPTION_MAX_LENGTH}`}</span>
        </Col>
        {/** INICIO Container tipos de envío y métodos de pago */}
        <Container className='  p-0 mb-5' fluid>
          <Row className='g-0'>
            <Col lg={6}>
              <Col lg={12}>
                <span className='p-large-bold text-primary-3'>{SHIPPING_TYPES_LABEL}</span>
              </Col>
              <Col lg={12} className='mb-3'>
                <span className='p-title-medium'>{SHIPPING_TYPES_DESCRIPTION}</span>
              </Col>

              {shippingTypesArray.map((item) => (
                <Col lg={12} className='d-flex justify-content-between my-1' key={item.id}>
                  <Col lg={6} className='my-auto'>
                    <IconList iconName={item.icon} classTitle='p-regular-bold' title={item.title} />
                  </Col>
                  {/* <Col lg={6}>
                    <SwitchCheckbox
                      classMain="py-2 px-0"
                      type="switch"
                      showValue={true}
                      checked={shippingTypeSwitch.includes(item.id)}
                    />
                  </Col> */}
                </Col>
              ))}
            </Col>

            <Col xs={6}>
              <Col lg={12}>
                <span className='p-large-bold text-primary-3'>{PAYMENT_METHODS_LABEL}</span>
              </Col>

              <Col lg={12} className='mb-3'>
                <span className='p-title-medium'>{PAYMENT_METHODS_DESCRIPTION}</span>
              </Col>

              {paymentMethodsArray.map((item) => (
                <Col lg={12} className='d-flex justify-content-between my-1' key={item.id}>
                  <Col lg={6} className='my-auto'>
                    <IconList iconName={item.icon} classTitle='p-regular-bold' title={item.title} />
                  </Col>
                </Col>
              ))}
            </Col>
          </Row>
        </Container>

        {/** FIN Container tipos de envío y métodos de pago */}

        {/* <Col lg={12} className='pt-4 my-4'>
          <span className='p-regular-bold'>{BANNER_LABEL}</span>
          <DragAndDrop
            fileType='media'
            active={urlValue !== '' ? false : true}
            size='240px'
            errorMessage={BANNER_TYPE_ERROR}
            externalHandleDrop={handleDrop}
            handleFileChange={handleFileChange}
            enabledButtons={false}
            fileAttached={file}
            updateFile={updateFile}
          />
          <Col lg={12} className='mb-4'>
            <Row className='g-0 d-flex align-items-center'>
              <Col lg={2}>
                <span className='p-regular-medium text-secondary-3'>
                  {`${BANNER_MEDIA_ADD} ${mediaCounter}/${BANNER_MEDIA_MAX_RANGE}`}
                </span>
              </Col>
              <Col>
                <IconList
                  classMain='fw-light py-2 svg-18 svg-secondary-3'
                  iconName='ico-info'
                  classTitle='p-regular-medium text-secondary-3'
                  title={BANNER_DESCRIPTION}
                />
              </Col>
            </Row>
          </Col>
        </Col> */}

        <Col lg={12} className='my-4'>
          <span className='p-large-bold'>{BANNER_MEDIA_LABEL}</span>
          <Col lg={12} className={`${urlBanner !== '' && 'd-none'}`}>
            <InputForm
              className='mb-3'
              inputRef={urlBannerRef}
              disabled={mediaCounter > 0}
              label={BANNER_MEDIA_URL_LABEL}
              classLabel='p-regular-medium'
              withErrors={urlBannerWithError}
              messageError={urlBannerMessageError}
              onBlur={handleBlurUrlBanner}
              onKeyDown={handleBannerUrlKeyDown}
              onChange={handleCleanUrlBanner}
              onPaste={handleBannerUrlPaste}
              type='text'
            />
          </Col>
        </Col>

        {urlBanner !== '' && (
          <Col lg={12}>
            <UrlPresenter type='MEDIA' urlInfo={urlBanner} onClose={handleResetBannerUrl} />
          </Col>
        )}

        <Col lg={12} className='mb-4'>
          <p className='p-regular text-tertiary-1'>{BANNER_MEDIA_URL_DESCRIPTION}</p>
        </Col>

        <Col lg={12} className='my-4'>
          <InputForm
            className='mb-1'
            inputRef={tagRef}
            label={TAGS_LABEL}
            classLabel='p-regular-medium'
            classMain={`text-lowercase`}
            withErrors={tagInputWithError}
            type='text'
            onKeyDown={handleChangeTags}
            messageError={tagMessageError}
            disabled={tagList.length === BASE_PRODUCT_TAG_MAX_QUANTITY}
          />
          <Col className='text-end'>
            <span
              className={`p-regular-bold ${
                tagList.length === BASE_PRODUCT_TAG_MAX_QUANTITY && 'text-complementary-2'
              }`}>{`${tagList.length}/${BASE_PRODUCT_TAG_MAX_QUANTITY}`}</span>
          </Col>
          {tagList && tagList.length > 0 && (
            <Col className='d-flex flex-row flex-wrap'>
              {tagList.map((tag, index) => (
                <PillText name={tag} onClose={() => handleDeleteTag(index)} key={index} />
              ))}
            </Col>
          )}
          <span className='p-regular text-tertiary-1'>{TAGS_DESCRIPTION}</span>
        </Col>
        <Col lg={{ span: 6, offset: 6 }} className='my-3'>
          <TwoButtonsGroup
            firstButtonText={cancelButtonText}
            secondButtonText={nextButtonText}
            firstButtonClass='btn-secondary-text-standard'
            firstButtonClick={handleCancelButton}
            secondButtonClass={`btn-primary-text-standard ${(!isAValidData || !isActive) && 'disabled'}`}
            secondButtonClick={handleSendInfo}
          />
        </Col>
      </Col>
    </Row>
  );
};

export default BaseProductDescription;
