import React, { useRef, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';
import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';
import getFileURL from '@/domain/utils/getFileURL';

import AttributesController from '@/controllers/AttributesController';
import ProductsController from '@/controllers/ProductsController';

import { IProductForm } from '@/domain/interfaces/IProduct';
import { IAttributes, IAttributesForm } from '@/domain/interfaces/IAttributes';
import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IPathCategoryType } from '@/domain/interfaces/ICategories';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import IconList from '@/ui/components/IconList';
import DragAndDrop from '@/ui/components/DragAndDrop';
import AttributesSelection from '@/ui/components/inventory/AttributesSelection';
import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import InputForm from '@/ui/components/forms/InputForm';
import UrlPresenter from '@/ui/components/formWizard/UrlPresenter';
import MainTitle from '@/ui/components/MainTitle';

/** Propiedades del componente */
interface PropsMainVariantAttributes {
  /**Títulos pasados por props de AddProducts */
  //titulo tab
  mainTitle: string;
  // Texto del titulo de la variante del producto
  variantProductTitle: string;
  // Texto del titulo de la clasificación
  classificationLabelText: string;
  // Texto del botón de cancelar
  cancelButtonText: string;
  // Texto del botón guardar y continuar
  nextButtonText: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Objeto que representa la categoría referenciada */
  pathCategoryState: IPathCategoryType[];
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

/**
 * Componente para configurar los atributos de la variante principal
 * @component
 */
const MainVariantAttributes = ({
  mainTitle,
  variantProductTitle,
  classificationLabelText,
  cancelButtonText,
  nextButtonText,
  formRef,
  pathCategoryState,
  updateFormRef,
  handleNextButton,
  handleCancelButton,
}: PropsMainVariantAttributes): JSX.Element => {
  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Estado para habilitar el botón de siguiente paso */
  const [isAValidData, setIsAValidData] = useState<boolean>(false);

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** State que construye/destruye div de atributos */
  const [flagAttributeDiv, setFlagAttributeDiv] = useState<boolean>(true);

  /** Auxiliar que guarda la posición que será eliminada del arreglo de atributos */
  const positionToDelete = useRef<number | null>(null);

  /** State para guardar el archivo */
  const [urlGuideFile, setUrlGuideFile] = useState<File | null>(null);

  /** Estado que muestra el contador de archivos */
  const [urlGuideCounter, setUrlGuideCounter] = useState<number>(0);

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /** Referencia al input url */
  const urlGuideRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado que muestra el contador de archivos */
  const [urlGuideValue, setUrlGuideValue] = useState<string>(urlGuideRef.current ? urlGuideRef.current.value : '');

  /** Declaración inicial del mensajes de error de URL  */
  const [urlGuideMessageError, setUrlGuideMessageError] = useState<string>('');

  /** Declaración inicial un input en su estado normal    */
  const [urlGuideInputWithError, setUrlGuideInputWithError] = useState<boolean>(false);

  /** Objeto inicial para la data de atributos */
  const initialAttribute: IAttributesForm = {
    key: '',
    value: '',
    position: 0,
    main: false,
    type: '',
    decimalPrecision: 0,
    range: [],
  };

  /** Controlador de atributos */
  const { getAttributesByCategory } = AttributesController();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** State que representa el arreglo de atributos desde el controlador */
  const [attributesState, setAttributesState] = useState<IAttributes[]>([]);

  /** UseRef que guarda los valores de los componentes de atributos ya seleccionados. */
  const attributesValues = useRef<number[]>([0, 0]);

  /** State para los valores con formato IAttributesForm de los componentes de atributos seleccionados. */
  const [attributesValuesForm, setAttributesValuesForm] = useState<IAttributesForm[]>([{ ...initialAttribute }, { ...initialAttribute }]);

  /** Clase para el link de agregar atributos */
  const [classLinkNewAttribute, setClassLinkNewAttribute] = useState<string>('link-icon-standard');

  /** Función que actualiza el archivo */
  const updateUrlGuideFile = (newFile: File | null) => {
    setUrlGuideFile(newFile);
  };

  /** Maneja el evento al cambiar del input */
  const handleUrlGuideFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //Guarda el archivo
    const selectedFile = e.target.files && e.target.files[0];
    setUrlGuideFile(selectedFile);
  };

  /**Maneja el evento de soltar el archivo de la guía */
  const handleUrlGuideFileDrop = (attachedMedia: File[]): void => {
    if (attachedMedia.length > 0) {
      setUrlGuideFile(attachedMedia[0]);
    }
  };

  /** En el evento  se maneja el guardado de la url */
  const handleUrlGuide = (value: string) => {
    if (urlGuideRef.current) {
      urlGuideRef.current.value = value;
    }
    setUrlGuideValue(value);
    handleValidate();
  };

  /**handle para limpiar el input de url*/
  const handleCleanUrlGuide = () => {
    const value: string = urlGuideRef.current?.value.trim() ?? '';
    if (value.length === 0) {
      setUrlGuideInputWithError(false);
      setUrlGuideMessageError('');
    }
  };

  /** En el evento onBlur se valida  que si no existe el dato se coloque vacío */
  const handleBlurUrlGuide = () => {
    if (urlGuideValue === '') {
      urlGuideRef.current && (urlGuideRef.current.value = '');
      handleValidate();
    }
  };

  /** Función que desenfoca el input cuando se presiona enter o Tab */
  const handleUrlGuideKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      handleUrlGuide(e.currentTarget.value);
    }
  };

  /**Función que se ejecuta al pegar en el Banner la data */
  const handleUrlGuidePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    handleUrlGuide(pastedData);
  };

  /** Función que elimina url de la barra */
  const handleResetUrlGuide = (): void => {
    setUrlGuideCounter(0);
    setUrlGuideValue('');
    urlGuideRef.current!.value = '';
  };

  /** Función que trae los atributos de acuerdo a la categoría */
  const getAttributes = async (): Promise<void> => {
    if (attributesState.length === 0) {
      await getAttributesByCategory(userLogued.user?.token ?? '', formRef.current!.category).then((attributes: IAttributes[]) => {
        //Si el atributo es cuantitativo se trae el prefijo y se lo agrega al nombre
        attributes.forEach((attribute) => {
          const name = !attribute.isQualitative ? `${attribute.name}_${attribute.values && attribute.values[0].prefix}` : attribute.name;
          attribute.name = name;
        });
        setAttributesState([...attributes]);
      });
    }
  };

  /** Evento desactivar el link de agregar atributos  */
  const disableLink = (): void => {
    setClassLinkNewAttribute('link-icon-standard disabled');
  };

  /** Evento activar el link de agregar atributos  */
  const activeLink = (): void => {
    setClassLinkNewAttribute('link-icon-standard');
  };

  /** Agrega un nuevo componente para selección de atributos */
  const addAttributeSelection = (): void => {
    const attributesValuesInfo: number[] = [...attributesValues.current];
    const attributesValuesFormInfo: IAttributesForm[] = [...attributesValuesForm];

    // No se pueden crear mas componentes que la cantidad de componentes de selección de atributos que existen en el sistema
    if (attributesValuesFormInfo.length < attributesState.length && attributesValuesFormInfo.length < 10) {
      // Actualiza el State de los valores de los componentes
      attributesValuesInfo.push(0);
      attributesValuesFormInfo.push({ ...initialAttribute });

      attributesValues.current = attributesValuesInfo;

      setAttributesValuesForm(attributesValuesFormInfo);

      // Habilita/deshabilita el link para crear más componentes de selección de atributos
      if (attributesValuesFormInfo.length < attributesState.length) {
        activeLink();
      } else {
        disableLink();
      }
    } else {
      // Deshabilita el link para crear más componentes de selección de atributos
      disableLink();
    }
  };

  /** Actualiza el state que almacena los valores de los atributos seleccionados */
  const changeAttributeSelection = (value: string, index: number): void => {
    // Obtiene el valor seleccionado
    const selectedValue: number = parseInt(value);
    attributesValues.current[index] = selectedValue;
  };

  /** Actualiza el state que almacena los valores de los atributos en formato IAttributesForm seleccionados */
  const changeAttributeSelectionForm = (attribute: IAttributesForm, index: number): void => {
    const updatedValues: IAttributesForm[] = [...attributesValuesForm];
    updatedValues[index] = { ...attribute };

    // Actualiza el state de los valores de los componentes para selección de atributos
    setAttributesValuesForm(updatedValues);

    if (attributesState.length > 0) {
      const idAttribute = attributesState.find((element) => element.name === attribute.key);
      changeAttributeSelection(idAttribute?.id ?? '0', index);
    }
  };

  /** Entrega al cambio del state flagAttributeDiv el borrado del atributo */
  const deleteAttributeSelection = (position: number): void => {
    positionToDelete.current = position;
    setFlagAttributeDiv(false);
  };

  /** Al eliminar un componente de selección de atributos */
  const deleteAttributeSelectionA = (position: number): void => {
    /** Trae la data actual de los componentes de selección de atributos  */

    const attributesValuesFormInfo: IAttributesForm[] = [...attributesValuesForm];

    /** Si solo hay dos atributos se limpia */
    if (attributesValuesFormInfo.length === 2) {
      if (position === 0) {
        attributesValues.current[0] = attributesValues.current[1];
        attributesValues.current[1] = 0;

        attributesValuesFormInfo[0] = { ...attributesValuesFormInfo[1] };
        attributesValuesFormInfo[1] = { ...initialAttribute };
      } else {
        attributesValues.current[position] = 0;
        attributesValuesFormInfo[position] = { ...initialAttribute };
      }
    } else {
      /** Elimina los valores del componente que se esta eliminando */
      attributesValues.current.splice(position, 1);
      attributesValuesFormInfo.splice(position, 1);
    }

    /** Actualización de states */
    setAttributesValuesForm(attributesValuesFormInfo);

    activeLink();

    /** Muestra div de atributos */
    positionToDelete.current = null;
    setFlagAttributeDiv(true);
  };

  /** Establece componentes si el objeto del formulario almacena atributos */
  const setInitialValues = (): void => {
    const attributesForm: IAttributesForm[] = formRef.current!.variants[0].attributes
      ? JSON.parse(JSON.stringify(formRef.current!.variants[0].attributes))
      : [];

    const attributesValuesAux = [0, 0];
    const attributesValuesFormAux = [{ ...initialAttribute }, { ...initialAttribute }];

    if (attributesForm.length > 0 && attributesState.length > 0) {
      for (let i = 0; i < attributesForm.length; i++) {
        const attribute: IAttributes | undefined = attributesState.find((attribute) => attribute.name === attributesForm[i].key);
        const idAttribute: number = attribute ? parseInt(attribute?.id) : 0;

        if (i < 2) {
          attributesValuesAux[i] = idAttribute;
          attributesValuesFormAux[i] = { ...attributesForm[i] };
        } else {
          attributesValuesAux.push(idAttribute);
          attributesValuesFormAux.push({ ...attributesForm[i] });
        }
      }

      attributesValues.current = [...attributesValuesAux];
      setAttributesValuesForm([...attributesValuesFormAux]);
    }
  };

  /** Evento que envia el formulario cuando se presiona el botón */
  const handleSendInfo = async () => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    const attributesForm = attributesValuesForm.filter((attributes) => attributes.key !== '');

    // Establece el formulario a actualizar
    const formProduct: IProductForm = formRef.current ? JSON.parse(JSON.stringify(formRef.current)) : { ...productBaseFormClean };
    formProduct.variants[0].urlGuide = urlGuideFile
      ? getFileURL(urlGuideFile).url.split('blob:')[1].replace('localhost', 'ilisplace.cl') + '.pdf'
      : urlGuideValue !== ''
      ? urlGuideValue
      : urlGuideRef.current?.value;
    formProduct.variants[0].attributes = [...attributesForm];

    // Le asigna la position de acuerdo al index y coloca como principales los dos primeros
    formProduct.variants[0].attributes.forEach((attribute, index) => {
      attribute.position = index + 1;
      attribute.position < 3 && (attribute.main = true);
    });

    // Valida desde el controlador
    await addProductBase(formProduct, false, 'attributes', userLogued.user?.token ?? '')
      .then((response: IProductValidation) => {
        // Error de Atributos
        if (response.error && response.kindError?.attributesError && response.kindError?.attributesError !== '') {
          setIsAValidData(false);
        } else {
          setIsAValidData(true);
        }
        // Si hay error en la url se muestra
        if (!urlGuideFile || !(urlGuideFile instanceof File)) {
          if (response.error && response.kindError?.guideError && response.kindError?.guideError !== '') {
            setUrlGuideInputWithError(true);
            setUrlGuideMessageError('El valor no cumple con el formato de una URL de PDF válida Ejemplo: (https://foo.com/bar.pdf)');
            setUrlGuideValue('');
          } else {
            setUrlGuideInputWithError(false);
            setUrlGuideMessageError('');
            if (urlGuideRef.current != null && urlGuideRef.current.value.length > 0) {
              urlGuideRef.current.disabled = false;
              setUrlGuideCounter(1);
            }
          }
        }

        /** Si se solicitó el objeto del formulario se actualiza */
        if (!response.error) {
          setIsAValidData(true);
          send && updateFormRef(formProduct);
        } else {
          setIsAValidData(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /** UseEffect para actualizar el archivo cada vez que cambia */
  useEffect((): void => {
    urlGuideFile ? setUrlGuideCounter(1) : setUrlGuideCounter(0);
  }, [urlGuideFile]);

  /** Comportamiento necesario para renderizar toda la lista de atributos */
  useEffect(() => {
    /** Elimina el atributo solicitado */
    if (!flagAttributeDiv && positionToDelete.current !== null) {
      deleteAttributeSelectionA(positionToDelete.current);
    }
  }, [flagAttributeDiv]);

  /**UseEffect que llama los atributos al cargar el componente */
  useEffect(() => {
    //Función que trae los atributos de acuerdo a la categoría
    const findAttributes = async () => {
      if (attributesState.length === 0) {
        await getAttributes();
      }
    };
    findAttributes();

    window.scrollTo(0, 0);
  }, []);

  /** Cuando tenemos los atributos desde el controlador se establecen los componentes de atributos */
  useEffect(() => {
    attributesState.length > 0 && setInitialValues();
    attributesState.length === attributesValues.current.length && disableLink();
  }, [attributesState]);

  /** Validación al cambiar el objeto de atributos */
  useEffect(() => {
    handleValidate();
  }, [attributesValuesForm]);

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      formRef.current.variants[0].urlGuide && setUrlGuideValue(formRef.current.variants[0].urlGuide);
      formRef.current.variants[0].urlGuide!.length > 0 && setUrlGuideCounter(1);
    }
    window.scrollTo(0, 0);
  }, []);

  /**CONSTANTES DE TEXTO */

  const REQUIRED_TEXT: string = '(Obligatorio)';

  const ATTRIBUTES_DESCRIPTION: string =
    'Agrega los atributos que correspondan a la variante del producto, definiendo 2 como atributos principales y los demás como atributos adicionales. También tiene la opción de cargar una guía asociada a alguno de estos atributos, si es necesario.';
  const SKU_LABEL: string = 'SKU:';
  const SKU_NO_DATA: string = 'S/I';

  const SPECIFICATIONS_GUIDE_LABEL: string = 'Guía';
  const SPECIFICATIONS_GUIDE_DESCRIPTION: string =
    'Selecciona el archivo en formato PDF de la guía de variantes del producto como por ejemplo: tallas, tamaños, dimensiones, etc.';
  const SPECIFICATIONS_GUIDE_TYPE_ERROR: string = 'El archivo que intentas cargar no es un archivo tipo PDF.';
  const SPECIFICATIONS_GUIDE_ADD: string = 'Agregados: ';
  const SPECIFICATIONS_GUIDE_MAX_RANGE: string = '1';

  const SPECIFICATIONS_GUIDE_URL_LABEL: string = 'URL guía de especificaciones';

  const ATTRIBUTE_LABEL: string = 'Atributo ';
  const ADD_ATTRIBUTE: string = 'Agregar otro atributo';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <Row className='g-0'>
          <MainTitle boldTitle={mainTitle} detailsText={ATTRIBUTES_DESCRIPTION} />
          <Col lg={12} className='mb-3'>
            <span className='p-title-medium  me-2'>{variantProductTitle}</span>
            <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
          </Col>
          <Col lg={12} className='rounded rounded-3 bg-secondary-5 py-2 mb-5 px-3  d-flex '>
            <span className='text-primary-4 p-large-medium py-1 px-2 me-4 rounded-5  bg-primary-3 text-break px-3 '>
              <span className='text-primary-4 p-large-medium px-1 rounded-5  bg-primary-3 text-break'>{SKU_LABEL}</span>
              {`${formRef.current!.variants[0].sku ? formRef.current?.variants[0].sku : SKU_NO_DATA}`}
            </span>
          </Col>
          <span className='p-title-medium'>{classificationLabelText}</span>
          <Col lg={12} className='bg-secondary-4 border border-1 p-2 mb-4 rounded rounded-2'>
            <CategoryPath pathState={pathCategoryState} referenced />
          </Col>
          <Col lg={12} className='mt-4'>
            <span className='p-large-bold'>{SPECIFICATIONS_GUIDE_LABEL}</span>
            {/* <DragAndDrop
                                fileType='pdf'
                                size='120px'
                                active={urlGuideValue !== '' ? false : true}
                                errorMessage={SPECIFICATIONS_GUIDE_TYPE_ERROR}
                                handleFileChange={handleFileChange}
                                externalHandleDrop={handleDrop}
                                enabledButtons={false}
                                fileAttached={urlGuideFile}
                                updateFile={updateFile}
                            /> */}
            {urlGuideCounter > 0 && (
              <Col lg={12}>
                <UrlPresenter type='PDF' urlInfo={urlGuideValue} onClose={handleResetUrlGuide} />
              </Col>
            )}
            <Col lg={12}>
              <InputForm
                className={`mb-2 ${urlGuideCounter > 0 ? 'd-none' : ''}`}
                inputRef={urlGuideRef}
                disabled={urlGuideCounter > 0}
                label={SPECIFICATIONS_GUIDE_URL_LABEL}
                classLabel='p-regular-medium'
                withErrors={urlGuideInputWithError}
                messageError={urlGuideMessageError}
                onChange={handleCleanUrlGuide}
                onKeyDown={handleUrlGuideKeyDown}
                onPaste={handleUrlGuidePaste}
                onBlur={handleBlurUrlGuide}
                type='text'
              />
            </Col>
          </Col>
        </Row>
        <Col lg={12} className='mb-4'>
          <Row className='g-0 d-flex align-items-top '>
            <Col lg={2} className=' d-flex py-1'>
              <span className='p-regular-medium text-secondary-3 '>
                {`${SPECIFICATIONS_GUIDE_ADD} ${urlGuideCounter}/${SPECIFICATIONS_GUIDE_MAX_RANGE}`}
              </span>
            </Col>
            <Col lg={10} className='py-1'>
              <IconList
                classMain='fw-light svg-18 svg-secondary-3 align-items-top  '
                iconName='ico-error'
                classIcon='mb-auto'
                classTitle='p-regular-medium text-secondary-3 d-flex align-items-top'
                title={SPECIFICATIONS_GUIDE_DESCRIPTION}
              />
            </Col>
          </Row>
        </Col>
        {flagAttributeDiv && (
          <>
            {attributesState.length > 0 &&
              attributesValuesForm.map((value, index) => {
                return (
                  <AttributesSelection
                    key={index}
                    index={index}
                    attributesForm={attributesValuesForm}
                    label={`${ATTRIBUTE_LABEL} ${index + 1} ${index < 2 ? REQUIRED_TEXT : ''}`}
                    attributes={attributesState}
                    selectedAttributes={attributesValues}
                    handleDelete={deleteAttributeSelection}
                    onChangeForm={changeAttributeSelectionForm}
                  />
                );
              })}
          </>
        )}
        <Row className='d-flex g-0'>
          <Col lg={6} className='d-flex flex-wrap align-content-center'>
            <IconList
              iconName='ico-more'
              classMain={classLinkNewAttribute}
              classTitle='p-title-medium'
              title={ADD_ATTRIBUTE}
              onClick={addAttributeSelection}
            />
          </Col>
          <Col lg={6}>
            <TwoButtonsGroup
              firstButtonText={cancelButtonText}
              secondButtonText={nextButtonText}
              firstButtonClass='btn-secondary-text-small'
              firstButtonClick={handleCancelButton}
              secondButtonClass={`btn-primary-text-standard ${(!isAValidData || !isActive) && 'disabled'}`}
              secondButtonClick={handleSendInfo}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MainVariantAttributes;
