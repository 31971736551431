export interface SortCriteria<T> {
  field: keyof T;
  order: 'ASC' | 'DESC';
}

/**
 * Ordena un arreglo de objetos por una o varias propiedades por los metodos ascendente o descendente
 * 
 * @param {T[]} objects Recibe un arreglo de objetos de cualquier tipo
 * @param {SortCriteria[]} sortCriteria arreglo de objetos tipo SortCriteria para realizar el ordenamiento
 * @returns {T[]} Arreglo de objetos ordenado 
 */
const orderObjectsByProperties = <T>(objects: T[], sortCriteria: SortCriteria<T>[]): T[] => {
  return objects.sort((a, b) => {
    for (const criteria of sortCriteria) {
      const { field, order } = criteria;
      const valueA = a[field];
      const valueB = b[field];

      if (valueA < valueB) {
        return order === 'ASC' ? -1 : 1;
      } else if (valueA > valueB) {
        return order === 'DESC' ? 1 : -1;
      }
    }

    return 0;
  });
};
  
export default orderObjectsByProperties;