import { PropsWithChildren, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';

import { change } from '@/ui/store/slices/managementSlice';

import UserController from '@/controllers/UserController';
import MenuController from '@/controllers/MenuController';

import { IMenu } from '@/domain/interfaces/IMenu';

import { Container, Row, Col, Modal } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';
import BackIcon from '@/ui/assets/BackIcon';

import AvatarUser from '@/ui/components/user/AvatarUser';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import IconList from '@/ui/components/IconList';
import BackClose from '@/ui/components/BackClose';
import ListSubmenu from '@/ui/components/ListSubmenu';
import SignOutForm from '@/ui/components/user/SignOutForm';

import Header from '@/ui/containers/Header';

/**
 * Modal del menú que se despliega desde el NavMobile
 * @component
 */

interface PropsMenuMobile {
  /** Propiedad que muestra el modal de opciones de usuario */
  showMenuMobile?: boolean;
  /** Evento que oculta y muestra el modal Menu */
  handleShowMenuMobile: () => void;
  /** Evento que oculta y muestra el modal Login */
  handleShowLogin: () => void;
  /** Evento que oculta y muestra el modal Registro */
  handleShowRegister: () => void;
  /** Evento que oculta y muestra el modal Opciones de Usuario */
  handleShowOptionsUser: () => void;
}

const ModalMenuMobile = ({
  showMenuMobile,
  handleShowMenuMobile,
  handleShowLogin,
  handleShowRegister,
  handleShowOptionsUser,
}: PropsWithChildren<PropsMenuMobile>): JSX.Element => {
  /**  Hook para trabajar con document location   */
  const location: Location = useLocation();

  /** Controlador para hacer logout y tomar la infor de los usuarios
   * @constant {object}
   */
  const { doLogout } = UserController();

  /** Llamado del nav desde el controlador */
  const { mainMenu } = MenuController();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Cambiamos el estado del header cuando entra en un estado administrativo */
  const dispatch = useAppDispatch();

  /** Objeto Redux que almacena la data del menu */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);

  /** Uso de los efectos del componente :
   * - Ocultar o mostrar elementos en el header
   */
  useEffect(() => {
    /** Actualiza el estado Redux del managementState al montar el componente      */
    if (isMobile) {
      dispatch(change({ value: true }));
    }

    return () => {
      /** Actualiza el estado Redux del managementState al desmontar el componente      */
      dispatch(change({ value: false }));
    };
  }, [width, maxLargeWidth, dispatch]);

  /** Estado Redux del usuario */
  const userLogued = useAppSelector((state) => state.userState);

  /** Maneja un state para mostrar los menus de acuerdo al key enviado    */
  const [keySubmenu, setKeySubmenu] = useState<string>('categoria');

  /** Maneja un state para ocultar la ventana de cierre de modal al cargar la web    */
  const [closeModal, setCloseModal] = useState<boolean>(false);

  /** Funciones que Muestra / Oculta el modulo de confirmación cierre de modal*/
  const switchCloseModal = (): void => setCloseModal(!closeModal);

  /** Cierra el formulario  */
  const closeForm = (): void => {
    doLogout();
    setCloseModal(false);
    handleShowMenuMobile();
  };

  /** Cambia enter el formulario mobile y el de opciones de usuario  */
  const userOptionForm = (): void => {
    handleShowOptionsUser();
    handleShowMenuMobile();
  };

  const getMenu = async (): Promise<void> => {
    await mainMenu();
  };

  /** UseEffect para renderizado de Menu */
  useEffect(() => {
    getMenu();
  }, []);

  return (
    <Modal
      id='modal-menu-mobile'
      show={showMenuMobile}
      onHide={handleShowMenuMobile}
      fullscreen={true}
      dialogClassName='left'
      contentClassName='min-vh-100'>
      <Container fluid className='p-0 bg-primary-4'>
        <Row className='g-0'>
          <Col xs={12} className='pb-2 sticky-top bg-primary-5'>
            <Header />
            {userLogued.user.isLogued ? (
              closeModal ? (
                <>
                  <Row className='g-0 px-2'>
                    <BackClose classMain='justify-content-between py-2'>
                      <BackIcon onClick={switchCloseModal} classMain='btn-icon-light' />
                      <CloseIcon onClick={switchCloseModal} classMain='btn-icon-light' />
                    </BackClose>
                  </Row>
                  <Col xs={12} className='pt-3 px-2 min-vh-100'>
                    <SignOutForm message='¿Desea Cerrar Sesión?' submit={closeForm} cancel={switchCloseModal} />
                  </Col>
                </>
              ) : (
                <>
                  <BackClose classMain='justify-content-end py-2'>
                    <CloseIcon onClick={handleShowMenuMobile} classMain='btn-icon-light' />
                  </BackClose>
                  <AvatarUser>
                    <p className='m-0 p-large-bold text-secondary-3 text-capitalize'>{`¡ Hola ${userLogued.user.firstName}!`}</p>
                  </AvatarUser>
                  <ul className='list-unstyled px-4'>
                    <IconList
                      iconName='ico-user'
                      classTitle='p-regular'
                      title='Configurar mi cuenta'
                      classMain='list-unstyled pe-1 mt-2'
                      onClick={userOptionForm}
                    />
                    <IconList
                      iconName='ico-exit'
                      classTitle='p-regular'
                      title='Cerrar sesión'
                      id='close-session'
                      classMain='list-unstyled text-secondary-3 pe-1 mt-2'
                      onClick={switchCloseModal}
                    />
                  </ul>
                </>
              )
            ) : (
              <>
                <BackClose classMain='justify-content-end py-2'>
                  <CloseIcon onClick={handleShowMenuMobile} classMain='btn-icon-light' />
                </BackClose>
                <p className='text-center pt-3 p-large-bold'> Bienvenido a ILIS PLACES</p>
                <TwoButtonsGroup
                  firstButtonClass='btn-secondary-text-standard'
                  firstButtonText='Administrador'
                  firstButtonClick={handleShowLogin}
                  secondButtonClass='btn-primary-text-standard'
                  secondButtonText='Registrarme'
                  secondButtonClick={handleShowRegister}
                />
              </>
            )}
          </Col>

          <Col xs={12} className='px-4 my-2 bg-primary-5'>
            <ul className='list-unstyled py-2'>
              {navMenu.map((item) => (
                <div key={item.id}>
                  <NavLink
                    to={`${item.url}`}
                    state={{ originURL: location.pathname }}
                    onClick={item.url === undefined || item.url === '#' ? () => setKeySubmenu(item.slug) : handleShowMenuMobile}>
                    <li className={`py-2  ${item.slug === keySubmenu && 'd-none'}`}>
                      <span className='p-regular-medium'>{item.title}</span>
                    </li>
                  </NavLink>
                </div>
              ))}
            </ul>
          </Col>

          <Col xs={12} className='bg-primary-5'>
            <ListSubmenu keySubmenu={keySubmenu} hideSubmenu={handleShowMenuMobile} />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalMenuMobile;
