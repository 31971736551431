/** Representa la interacción con LocalStorage */
export default class InventoryLocalStorage {
  /** Instancia de la clase   */
  private static _instance: InventoryLocalStorage;

  /** Patron Singleton
   * @returns {instance} _instance CartLocalStorage
   */
  static get instance() {
    if (!this._instance) {
      this._instance = new InventoryLocalStorage();
    }
    return this._instance;
  }

  /** Key del objeto en el localStorage para columnas seleccionadas */
  private static selectedRowsNameLocalStorage = 'inventoryTabSelectedRows';

  /** Actualiza registros seleccionados  */
  public updateObj(objSelectedRows: { [index: number]: string }) {
    localStorage.setItem(InventoryLocalStorage.selectedRowsNameLocalStorage, JSON.stringify(objSelectedRows));
  }

  /** Obtiene registros seleccionados  */
  public getObj(): { [index: number]: string } | null {
    const localStorageRows = localStorage.getItem(InventoryLocalStorage.selectedRowsNameLocalStorage);
    return localStorageRows !== null ? JSON.parse(localStorageRows) : null;
  }

  /** Elimina el objeto de registros seleccionados */
  public deleteObj() {
    localStorage.removeItem(InventoryLocalStorage.selectedRowsNameLocalStorage);
  }
}
