import { ChangeEvent, PropsWithChildren } from 'react';

import { Form } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';
import GuideTip from '@/ui/components/GuideTip';

/** Propiedades del componente */
interface PropsInputForm extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Propiedad que define el id del input */
  id?: string;
  /** Propiedad que define la clase del input */
  classMain?: string;
  /** Propiedad que define la clase del label */
  classLabel?: string;
  /** Propiedad que define el valor del input */
  value?: string;
  /** Propiedad que define el placeholder del input */
  placeholder?: string;
  /** Propiedad que define el titulo del input */
  label?: string;
  /** Propiedad que define el tipo del input */
  type?: 'email' | 'number' | 'textarea' | 'input' | 'password' | 'text' | 'numberInputText';
  /** Propiedad que define la cantidad de renglones de un textarea */
  rowsValue?: number;
  /** Propiedad que define la cantidad maxima que se puede escribir en el input */
  maxLength?: number;
  /** Propiedad que define la referencia del input */
  inputRef?: any;
  /** Propiedad que define si es un error */
  withErrors?: boolean;
  /** Propiedad que define el mensaje de error del input */
  messageError?: string;
  /** Propiedad que define el primer icono del input */
  firstIconName?: string;
  /** Propiedad que define la clase el nombre del primer icono del input */
  firstIconClass?: string;
  /** Propiedad que define el nombre del ultimo icono del input */
  lastIconName?: string;
  /** Propiedad que define la clase del ultimo icono del input */
  lastIconClass?: string;

  lastIconTitle?: string;
  /** Evento que se desencadena al darle clic al primer icono el input */
  firstIconClicked?: () => void;
  /** Evento que se desencadena al darle clic al ultimo icono el input */
  lastIconClicked?: () => void;
  /** Estado del input */
  disabled?: boolean;
}

/** Componente Input
 * @component
 */
const InputForm = ({
  id = '',
  className = '',
  label = '',
  withErrors = false,
  messageError = '',
  type = 'input',
  rowsValue = 1,
  maxLength,
  inputRef = undefined,
  classMain = '',
  classLabel = 'p-regular-medium',
  value,
  firstIconClass = '',
  firstIconName = '',
  lastIconClass = '',
  placeholder = '',
  lastIconName = '',
  lastIconTitle = '',
  onChange,
  onPaste,
  onKeyDown,
  onBlur,
  onFocus,
  onMouseMove,
  firstIconClicked,
  lastIconClicked,
  disabled = false,
}: PropsWithChildren<PropsInputForm>): JSX.Element => {
  /**Funciona manejadora de cambios del input */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    // tipo especial numberInputText: como un input text que permite solo números positivos para evitar el input number de html
    if (type === 'numberInputText') {
      const numericValue = e.target.value.replace(/[^\d]/g, '');
      if (/^[1-9]\d*$/.test(numericValue) || numericValue === '') {
        if (inputRef?.current) {
          inputRef.current.value = numericValue;
        }
        if (onChange) {
          e.target.value = numericValue;
          onChange(e);
        }
      }
    } else {
      onChange && onChange(e);
    }
  };

  return (
    <div id='input-form' className={className}>
      <Form.Group>
        {label && (
          <div className='d-flex justify-content-between'>
            <Form.Label className='text-truncate'>
              <span className={`${classLabel}`}>{label}</span>
            </Form.Label>
            <div>
              <span className={`p-regular-medium ${withErrors ? 'text-complementary-2 text-nowrap' : ''}`}>{withErrors ? messageError : ''}</span>
            </div>
          </div>
        )}
        <div className='ils-form-control position-relative '>
          <Form.Control
            disabled={disabled}
            autoComplete='off'
            as={type === 'textarea' ? type : 'input'}
            type={type !== 'textarea' ? (type === 'numberInputText' ? 'text' : type) : 'input'}
            id={id}
            ref={inputRef}
            onChange={handleInputChange}
            onPaste={onPaste}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onMouseMove={onMouseMove}
            className={`${lastIconName ? 'pe-5' : ''} ${classMain} form-control ${withErrors ? 'form-control-error' : ''} position-relative ${
              firstIconClass && 'ps-5'
            }`}
            value={value}
            placeholder={placeholder}
            onFocus={onFocus}
            {...(type === 'textarea' ? { rows: rowsValue } : {})}
            {...(maxLength ? { maxLength: maxLength } : {})}
          />
          {firstIconClass && (
            <button id='first-icon' className={`link-icon-standard ${firstIconClass}`} onClick={firstIconClicked}>
              <Icon name={firstIconName} />
            </button>
          )}
          {lastIconName && (
            <button
              className={`link-icon-standard svg-tertiary-3 ${withErrors ? 'last-input-icon svg-complementary-2' : lastIconClass} `}
              onClick={lastIconClicked}>
              <GuideTip position='bottom' message={lastIconTitle}>
                <Icon name={withErrors ? 'ico-error' : lastIconName} />
              </GuideTip>
            </button>
          )}
        </div>
      </Form.Group>
    </div>
  );
};
export default InputForm;
