import { createSlice } from '@reduxjs/toolkit';

/** Almacena la representacion de favoritos en localstorage */
const arrayFavoritesInLocalStorage = localStorage.getItem('favorites');

/** Inicializa el state */
const initialState:string[] = arrayFavoritesInLocalStorage ? JSON.parse(arrayFavoritesInLocalStorage) : [];

/** Almacena el estado favoritesState de Redux con sus reducers
  * @constant {object}
*/
export const favoritesSlice = createSlice({
  name: 'favoritesState',
  initialState,
  reducers: {
    addFavorite: (state, action) => {
        const index = state.findIndex((item) => item === action.payload);
        if (index === -1) {
          state.push(action.payload);
          localStorage.setItem('favorites', JSON.stringify(state));
        }
    },
    removeFavorite: (state, action) => {
        const index = state.findIndex((item) => item === action.payload);
        if (index !== -1) {
          state.splice(index, 1);
        }
        localStorage.setItem('favorites', JSON.stringify(state));
    },
    updateFavorites: (state, action) => {
        state.splice(0, state.length, ...action.payload);
    },
  },
  
})

export const { updateFavorites, addFavorite, removeFavorite } = favoritesSlice.actions;

export default favoritesSlice.reducer;
