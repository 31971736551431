import { createSlice } from '@reduxjs/toolkit';

const initialState = {value:false}

/** Almacena el estado showSessionModalSlice de Redux con sus reducers
  * @constant {object}
*/
export const showSessionModalSlice = createSlice({
  name: 'showSessionModal',
  initialState,
  reducers: {
    changeSessionState: (state) => {
      state.value = !state.value;
    },
  }
})

export const { changeSessionState } = showSessionModalSlice.actions;

export default showSessionModalSlice.reducer;
