import React, { PropsWithChildren, ReactNode } from 'react';

import Icon from '@/ui/assets/Icon';

interface PropsCloseIcon {
  /** Clase principal del icono close 'x' */
  classMain?: string;
  /** Evento al hacer click al icono close 'x' */
  onClick?: (params) => ReactNode | void;
}

/** Componente SVG de cierre
 * @component
 */
const CloseIcon = ({ classMain = '', onClick }: PropsWithChildren<PropsCloseIcon>): JSX.Element => {
  return (
    <button id="close-icon" onClick={onClick} className={`${classMain} cursor-hand link-text-standard`}>
      <Icon name="ico-close" />
    </button>
  );
};
export default CloseIcon;
