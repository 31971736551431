/**
 * Mockup que representa la información de tipos de envio que obtendrá del back
 */

import { IShippingTypes } from '@/domain/interfaces/IProduct';


export const MockShippingTypes: IShippingTypes[] = [
    {
        id: 1,
        title: 'Envío a domicilio',
        icon: 'ico-national-shipment',
    },
    /* {
        id: 2,
        title:'Envío Internacional', 
        icon: 'ico-international-shipment',
    },
    {
        id: 3,
        title:'Retiro en Tienda', 
        icon: 'ico-store',
    },*/

];