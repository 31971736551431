import { PropsWithChildren } from 'react';

import { Col, Container, Row, Modal, Image } from 'react-bootstrap';
import './style.css';

/**
 * Propiedades para el componente ModalStore.
 */
interface PropsModalOrderFilterMobile {
  /** Propiedad que muestra el modal. */
  showModal?: boolean;
  /** Evento que oculta y muestra el modal. */
  handleShowModal?: () => void;
  /** Propiedad que define si el modal es fullscreen o no. */
  fullscreen?: string | true | undefined;
}

/**
 * Modal que muestra toda la información a modificar de la tienda.
 * @component
 */
const ModalStore = ({ showModal, handleShowModal, children }: PropsWithChildren<PropsModalOrderFilterMobile>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  /** Logo */
  const LOGO: string = `${URL_IMAGES}/client/logo-header-modal.png`;

  return (
    <Modal
      show={showModal}
      onHide={handleShowModal}
      className='modal-store-mobile p-0'
      dialogClassName='m-0 min-vh-100 min-vw-100 d-flex'
      contentClassName='border border-0  rounded rounded-0 modal-slide-up bg-primary-3 d-flex'>
      <Col xs={12} className='px-1 pt-2 min-vh-10'>
        <Image src={LOGO} alt='logo' className='logo-header-modal' />
      </Col>
      <Container fluid className='p-0 min-vh-90 rounded-5 rounded-bottom-0 bg-primary-4 align-self-end'>
        <Row className='g-0 min-vh'>
          <Col xs={12} className='pt-4 px-4'>
            {children}
          </Col>
        </Row>
      </Container>
      <div className='bg-primary-3 w-100 py-2' />
    </Modal>
  );
};

export default ModalStore;
