import { PropsWithChildren } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import currencyFormat from '@/domain/utils/currencyFormat';

interface PropsMainPrice {
  /**Identifica si es gratis */
  isFree: boolean;
  /** valor a formatear */
  value: number;
  /**Clase del componente */
  className?: string;
}
/**
 * Componente que muestra el precio principal
 * @component
 */
const MainPrice = ({ isFree, value, className }: PropsWithChildren<PropsMainPrice>): JSX.Element => {
  const FREE_TEXT: string = 'GRATIS';
  return (
    <span className={`${className} ${isFree ? 'text-complementary-1' : 'text-secondary-1'}`}>
      {isFree ? FREE_TEXT : currencyFormat(value, ConfigNumberParameters.DECIMALS_ALLOWED)}
    </span>
  );
};

export default MainPrice;
