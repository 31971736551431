import { PropsWithChildren, useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { IOrderRequest } from '@/domain/interfaces/IAttributes';
import { updateCritera, resetCritera } from '@/ui/store/slices/storeSortCriteriaSlice';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { Col, Container, Form, Row } from 'react-bootstrap';
import '@/ui/components/forms/form.css';

import CloseIcon from '@/ui/assets/CloseIcon';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import SwitchCheckbox from '@/ui/components/forms/SwitchCheckbox';
import IconList from '@/ui/components/IconList';

/**
 * Propiedades para el componente ModalOrderInfo.
 */
interface PropsOrderOptions {
  /** Evento que oculta y muestra el modal. */
  handleShowModal: () => void;
}

/**
 * Propiedades para los valores del formulario
 */
interface propertiesUseForm {
  cheapest: undefined | false | true;
  mostExpenive: undefined | false | true;
  lowestDiscount: undefined | false | true;
  biggestDiscount: undefined | false | true;
  newest: undefined | false | true;
  relevance: undefined | false | true;
}

/**
 * Componente que muestra las opciones para ordenar
 * @component
 */
const OrderOptions = ({ handleShowModal }: PropsWithChildren<PropsOrderOptions>): JSX.Element => {
  /** Hook de actualización de states Redux */
  const dispatch = useAppDispatch();

  /** State de criterio de orden */
  const objectOrder: IOrderRequest[] = useAppSelector((state) => state.storeSortCriteria);

  /** Hook useForm para manejo de formularios */
  const { handleSubmit, control, setValue } = useForm<propertiesUseForm>();

  /**
   * Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido.
   */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /**
   * LOGICA DEL COMPONENTE DE ORDENAMIENTO
   */

  /** Estado inicial del Switch 'Destacados' */
  const [topRatedStatus, setTopRatedStatus] = useState<boolean>(false);

  /** Estado inicial del Switch 'Mas Descuento' */
  const [higherDiscountStatus, setHigherDiscountStatus] = useState<boolean>(false);

  /** Estado inicial del Switch 'Menos Descuento' */
  const [lowerDiscountStatus, setLowerDiscountStatus] = useState<boolean>(false);

  /** Estado inicial del Switch 'Más Reciente' */
  const [recentStatus, setRecentStatus] = useState<boolean>(true);

  /** Estado inicial del Switch 'Mayor Descuento' */
  const [higherPriceStatus, setHigherPriceStatus] = useState<boolean>(false);

  /** Estado inicial del Switch 'Menor Descuento' */
  const [lowerPriceStatus, setLowerPriceStatus] = useState<boolean>(false);

  /** Estructura de homologación para conocer que tipo de orden realiza
   * cada checkbox definido en el componente
   * Es importante agregar acá los nuevos checkboxes que se quieran implementar
   */
  const homologationForChecksAndProperties = {
    mostExpenive: ['prodPrice', 'DESC'],
    cheapest: ['prodPrice', 'ASC'],
    lowestDiscount: ['prodOfferPercentage', 'ASC'],
    biggestDiscount: ['prodOfferPercentage', 'DESC'],
    newest: ['prodCreatedAt', 'DESC'],
  };

  /** Jerarquia para el objeto que define el orden de los productos */
  const criteriaOrder: string[] = ['prodPrice', 'prodOfferPercentage', 'prodCreatedAt'];

  /** Funcion a aplicar como parametro a sort de javascript para que ordene según la jerarquia definida */
  const compareCriteria = (propertyA: IOrderRequest, propertyB: IOrderRequest): number => {
    const indexA = criteriaOrder.indexOf(propertyA.field);
    const indexB = criteriaOrder.indexOf(propertyB.field);
    return indexA - indexB;
  };

  /** Eventos que se realizan cuando se marca el check de Mayor Descuento */
  const handleHigherDiscountCheck = (): void => {
    setHigherDiscountStatus(!higherDiscountStatus);
    setValue('biggestDiscount', !higherDiscountStatus);
    setLowerDiscountStatus(higherDiscountStatus && false);
    setValue('lowestDiscount', higherDiscountStatus && false);
  };

  /** Eventos que se realizan cuando se marca el check de Menor Descuento */
  const handlerLowerDiscountCheck = (): void => {
    setLowerDiscountStatus(!lowerDiscountStatus);
    setValue('lowestDiscount', !lowerDiscountStatus);
    setHigherDiscountStatus(lowerDiscountStatus && false);
    setValue('biggestDiscount', lowerDiscountStatus && false);
  };

  /** Eventos que se realizan cuando se marca el check de Mas Reciente */
  const handlerRecentCheck = (): void => {
    setRecentStatus(!recentStatus);
    setValue('newest', !recentStatus);
  };

  /** Eventos que se realizan cuando se marca el check de Mayor Precio */
  const handleHigherPriceCheck = (): void => {
    setHigherPriceStatus(!higherPriceStatus);
    setValue('mostExpenive', !higherPriceStatus);
    setLowerPriceStatus(higherPriceStatus && false);
    setValue('cheapest', higherPriceStatus && false);
  };

  /** Eventos que se realizan cuando se marca el check de Menor Precio */
  const handlerLowerPriceCheck = (): void => {
    setLowerPriceStatus(!lowerPriceStatus);
    setValue('cheapest', !lowerPriceStatus);
    setHigherPriceStatus(lowerPriceStatus && false);
    setValue('mostExpenive', lowerPriceStatus && false);
  };

  /** Función que Resetea el Formulario */
  const resetForm = (): void => {
    setTopRatedStatus(false);
    setHigherDiscountStatus(false);
    setLowerDiscountStatus(false);
    setRecentStatus(true);
    setHigherPriceStatus(false);
    setLowerPriceStatus(false);
    resetUseForm();
  };

  /** Función que Resetea los valores del formulario del hook useForm */
  const resetUseForm = (): void => {
    setValue('cheapest', false);
    setValue('mostExpenive', false);
    setValue('lowestDiscount', false);
    setValue('biggestDiscount', false);
    setValue('newest', true);
    dispatch(resetCritera());
  };

  /** Función que Cierra el Formulario */
  const closeForm = (): void => {
    handleShowModal();
  };

  /**
   * Establece los criterios de orden
   * @param {propertiesUseForm} valuesUseForm
   * @return sortCriteria objeto con los criterios para ordenar
   */
  const setOrderCriteria = (valuesUseForm: propertiesUseForm): IOrderRequest[] => {
    const sortCriteria: IOrderRequest[] = [];

    Object.entries(valuesUseForm).forEach(([keyCheck, valueCheck]) => {
      let propertyVal: string;
      let orderVal: 'ASC' | 'DESC';

      if (valueCheck === true) {
        propertyVal = homologationForChecksAndProperties[keyCheck][0];
        orderVal = homologationForChecksAndProperties[keyCheck][1];
        sortCriteria.push({
          field: propertyVal,
          order: orderVal,
        });
      }
    });

    sortCriteria.sort(compareCriteria);
    return sortCriteria;
  };

  /** Maneja el submit del formulario */
  const onSubmit: SubmitHandler<propertiesUseForm> = (values): void => {
    /** El valor de más reciente en el formulario se actualiza con el state del checkbox */
    values.newest = recentStatus ? true : false;

    /** Establece los criterios de orden */
    const orderCriteria: IOrderRequest[] = setOrderCriteria(values);

    dispatch(updateCritera(orderCriteria));
  };

  /** Función que inicializa los checkbox si ya existe un ordenamiento previo */
  const inicializeCheck = (): void => {
    /** Recorre el objeto que contiene los criterios de orden */
    objectOrder.map(({ field, order }) => {
      const targetValues = [field, order];
      /** Ubica los checkbox a inicializar gracias al objeto homologationForChecksAndProperties */
      const foundedKey = _.findKey(homologationForChecksAndProperties, (value) => _.isEqual(value, targetValues));
      switch (foundedKey) {
        case 'mostExpenive':
          setHigherPriceStatus(true);
          setValue('mostExpenive', true);
          break;
        case 'cheapest':
          setLowerPriceStatus(true);
          setValue('cheapest', true);
          break;
        case 'lowestDiscount':
          setLowerDiscountStatus(true);
          setValue('lowestDiscount', true);
          break;
        case 'biggestDiscount':
          setHigherDiscountStatus(true);
          setValue('biggestDiscount', true);
          break;
        case 'newest':
          setRecentStatus(true);
          setValue('newest', true);
          break;
        default:
          break;
      }
    });
  };

  /**STRING DE TEXTOS */
  const ORDER_LABEL: string = 'Ordenar';
  const LOWER_PRICE_LABEL: string = 'Menor Precio';
  const HIGHER_PRICE_LABEL: string = 'Mayor Precio';
  const RESTORE_BUTTON: string = 'RESTABLECER';
  const APLY_BUTTON: string = 'APLICAR';

  /** Implementamos useEffect para inicializar los checkbox */
  useEffect(() => {
    inicializeCheck();
  }, []);

  return (
    <Container fluid>
      <Row>
        <div className='d-flex justify-content-end'>
          <CloseIcon onClick={closeForm} classMain='ps-2 btn-icon-light' />
        </div>

        <Col xs={12}>
          <IconList iconName='ico-order' classTitle='p-large-bold' title={ORDER_LABEL} />
        </Col>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Col xs={12} className={`mx-auto  ${isMobile && 'py-4'}`}>
            {/* <Controller
              control={control}
              name="relevance"
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  disabled={true}
                  checked={topRatedStatus}
                  classMain="py-2"
                  classSpan="p-regular-bold text-complementary-1"
                  label="Relevancia"
                  type="switch"
                />
              )}
            /> */}
            {/* <Controller
              control={control}
              name="newest"
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  checked={recentStatus}
                  classMain="py-2"
                  label="Más Reciente"
                  type="switch"
                  onChange={handlerRecentCheck}
                />
              )}
            /> */}
            <Controller
              control={control}
              name='cheapest'
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  checked={lowerPriceStatus}
                  classMain='py-2'
                  label={LOWER_PRICE_LABEL}
                  type='switch'
                  onChange={handlerLowerPriceCheck}
                />
              )}
            />
            <Controller
              control={control}
              name='mostExpenive'
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  checked={higherPriceStatus}
                  classMain='py-2'
                  label={HIGHER_PRICE_LABEL}
                  type='switch'
                  onChange={handleHigherPriceCheck}
                />
              )}
            />
            {/*<Controller
              control={control}
              name="lowestDiscount"
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  checked={lowerDiscountStatus}
                  classMain="py-2"
                  label="Menor Descuento"
                  type="switch"
                  onChange={handlerLowerDiscountCheck}
                />
              )}
            />
            <Controller
              control={control}
              name="biggestDiscount"
              render={({ field: { ref } }) => (
                <SwitchCheckbox
                  inputRef={ref}
                  checked={higherDiscountStatus}
                  classMain="py-2"
                  label="Mayor Descuento"
                  type="switch"
                  onChange={handleHigherDiscountCheck}
                />
              )}
            />*/}
          </Col>
          <Col>
            <TwoButtonsGroup
              firstButtonClass='btn-secondary-icon-standard'
              firstButtonText={RESTORE_BUTTON}
              firstButtonClick={resetForm}
              secondButtonClass='btn-primary-icon-standard'
              secondButtonText={APLY_BUTTON}
            />
          </Col>
        </Form>
      </Row>
    </Container>
  );
};

export default OrderOptions;
