import { PropsWithChildren } from 'react';

import { Col, Container, Modal, Row } from 'react-bootstrap';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import CloseIcon from '@/ui/assets/CloseIcon';

interface PropsModalBlockedByCheckOut {
  /**Estado por defecto de renderización */
  show?: boolean;
  /** Evento que ejecuta la opción submit del modal*/
  handleSubmit: () => void;
  /** Evento que ejecuta la opción cancel del modal*/
  handleCancel: () => void;
}

/** Componente Modal para escenarios de bloqueo por Checkout
 * @component
 */

const ModalBlockedByCheckOut = ({ show = false, handleSubmit, handleCancel }: PropsWithChildren<PropsModalBlockedByCheckOut>) => {
  const MODAL_EXIT_PROCESS_MESSAGE: string =
    'Estás a solo unos pasos de desbloquear todas las ventajas que tenemos para ti, ¡Tus beneficios te están esperando, no te los pierdas!';
  const MODAL_EXIT_PROCESS_TITLE: string = '¿Por qué no completar el proceso y descubrir lo que te espera?';
  const MODAL_EXIT_PROCESS_BUTTON: string = 'SALIR';
  const BUY_BUTTON_TEXT: string = 'CONTINUAR COMPRA';

  return (
    <Modal
      show={show}
      dialogClassName='modal-dialog-centered'
      contentClassName='rounded-4 mh-300-px mh-xs-ignore d-flex justify-content-between shadow border-0'
      size='lg'>
      <Container fluid className='px-0 g-0 py-3 my-3'>
        <CloseIcon classMain='btn-close-modal text-primary-3 position-absolute top-0 end-0 pe-3 pt-2' onClick={handleCancel}></CloseIcon>

        <Row className='d-flex justify-content-center g-0 py-4 px-3 pe-4'>
          <Col lg={6} className='d-flex justify-content-center '>
            <span className=' text-primary-3 p-large-bold text-center'>{MODAL_EXIT_PROCESS_TITLE}</span>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center g-0  '>
          <Col lg={6} className='d-flex justify-content-center'>
            <span className='text-secondary-2 p-medium text-lg-justify px-3'>{MODAL_EXIT_PROCESS_MESSAGE}</span>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center g-0  '>
          <Col lg={6} className='d-flex justify-content-center g-0 py-2  px-3'>
            <TwoButtonsGroup
              firstButtonText={MODAL_EXIT_PROCESS_BUTTON}
              firstButtonClick={handleSubmit}
              firstButtonClass='btn-secondary-text-standard'
              secondButtonText={BUY_BUTTON_TEXT}
              secondButtonClass={`btn-primary-text-standard py-2 `}
              secondButtonClick={handleCancel}
            />
          </Col>
        </Row>
      </Container>
      <Col xs={12} className='align-self-end pt-2 '>
        <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
      </Col>
    </Modal>
  );
};

export default ModalBlockedByCheckOut;
