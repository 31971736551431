import { useNavigate } from 'react-router';

import { useLocation, type NavigateFunction, Location } from 'react-router-dom';

import { RoutesDirections } from '@/data/libraries/Routes';
import { ConfigStringParameters } from '@/data/libraries/ConfigParameters';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

/** Interface de respuesta de fallido en transbank
 * @component
 */
const DeclinedPayment = (): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /**Variables de Entorno */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  const PHONE_NUMBER: string = process.env.REACT_APP_SUPPORT_DEFAULT_NUMBER ?? '';
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /**Constantes de texto */

  const DECLINED_PAYMENT_IMAGE: string = `${URL_IMAGES}/client/declined-payment.png`;
  const DECLINED_PAYMENT_TEXT: string = '¡Oops! Transacción fallida';
  const DECLINED_PAYMENT_DESCRIPTION: string = `Revisa si se ha realizado algún cargo a tu cuenta, ante alguna duda contáctanos al ${PHONE_NUMBER}`;
  const BUTTON_TEXT: string = 'INTENTAR DE NUEVO';

  /**Renderizado del componente */
  return (
    <Container fluid className='px-0 bg-secondary-5 mh-700-px'>
      <Row className='pt-4 px-3 g-0 d-flex justify-content-center  mh-600-px'>
        <Col lg={6} className='bg-primary-4 shadow rounded d-flex pt-3 flex-column '>
          <Col lg={12} className='d-flex justify-content-center mt-5'>
            <Col xs={11} lg={8} xxl={5}>
              <Col className='d-flex justify-content-center'>
                <Image
                  src={DECLINED_PAYMENT_IMAGE}
                  alt='gif'
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = ErrorImage;
                  }}
                />
              </Col>

              <Col className='pb-4 mb-2 d-flex text-center justify-content-center'>
                <span className={`text-primary-3 fw-bold ${isMobile ? 'h3' : 'h2'}  `}>{DECLINED_PAYMENT_TEXT}</span>
              </Col>
              <Col className='d-flex  justify-content-justify '>
                <p className=' text-secondary-2 h5 text-center'>{DECLINED_PAYMENT_DESCRIPTION}</p>
              </Col>
              <Col>
                <Button
                  className='btn-primary-icon-standard w-100 mt-4 '
                  onClick={() => navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } })}>
                  {BUTTON_TEXT}
                </Button>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default DeclinedPayment;
