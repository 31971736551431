import { IMenu } from '@/domain/interfaces/IMenu';
import { createSlice } from '@reduxjs/toolkit';

const initialState:IMenu[] = []; 

/** Almacena el estado managementState de Redux con sus reducers
  * @constant {object}
*/
export const mainMenuSlice = createSlice({
  name: 'mainMenu',
  initialState,
  reducers: {
    updateMainMenu: (state, action) => {
        state.splice(0, state.length, ...action.payload);
    },
  }
})

export const { updateMainMenu } = mainMenuSlice.actions;

export default mainMenuSlice.reducer;
