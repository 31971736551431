import TaxesRepository from '@/data/repositories/TaxesRepository';
import { ITaxes } from '@/domain/interfaces/ITaxes';
import { AxiosResponse } from 'axios';

/** Representa el controlador que maneja los eventos de los casos de uso de impuestos
  * @returns {object} Funciones del controlador
*/
const TaxesController = () => {
  
    /** Solicita los impuestos del back
      * @returns {ITaxes} taxes - Impuestos
    */
    const getTaxes = async(): Promise<ITaxes[]> => {

        /** Instancia del Repository
        * @const {TaxesRepository}
        */
        const taxesRepository = TaxesRepository.instance;
        const taxes:AxiosResponse<ITaxes[]> = await taxesRepository.getTaxes();

        const formatedTaxes:ITaxes[] = taxes.data.map((tax)=> {
          const maxFirstPartLength = 40; 
          const spaces = '\u00A0'.repeat(maxFirstPartLength - tax.tax.length);
          const formatedOption = `${tax.tax}${spaces} ${(tax.percentage * 100)}%`;
          return { ...tax, option: formatedOption };
        });
        
        return formatedTaxes;
    }

    /**
     * retorna la funciones del controlador
     */
    return { getTaxes };
};

export default TaxesController;
