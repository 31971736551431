import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IAttributesFilters } from '@/domain/interfaces/IAttributes';


/**
 * Caso de uso de texto de busqueda <br>
 * Se valida el texto de busqueda y se establece la url de busqueda
 * 
 * @param {IAttributesFilters} filters
 * @returns {IProductResponse}
 */
const getProducts = (filters: IAttributesFilters): IProductValidation => {

    /** Mensajes de error de maximo de caracteres buscados */
    const maxLengthMessage: string = 'Tu término de busqueda';

    /** Maximo tamaño de busqueda */
    const maxLength: number = 100;

    /** Prepara el request */
    let response: IProductValidation = { 'error': false, 'message': '' };

    const keyWord: string = filters.keyWord ?? '';

    /** Validación cantidad de caracteres */
    if (keyWord.length > maxLength) {
        response.message = maxLengthMessage;
        response.error = true;
    }

    return response;
}

export default getProducts;