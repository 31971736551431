import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

import userReducer from '@/ui/store/slices/userSlice';
import managementReducer from '@/ui/store/slices/managementSlice';
import categoriesMenuReducer from '@/ui/store/slices/categoriesMenuSlice';
import mainMenuReducer from '@/ui/store/slices/mainMenuSlice';
import attributesReducer from '@/ui/store/slices/attributesSlice';
import priceRangeReducer from '@/ui/store/slices/priceRangeSlice';
import cartReducer from '@/ui/store/slices/cartSlice';
import orderReducer from '@/ui/store/slices/orderSlice';
import favoritesReducer from '@/ui/store/slices/favoritesSlice';
import catalogFilterReducer from '@/ui/store/slices/catalogFilterState';
import storeSortCriteriaReducer from '@/ui/store/slices/storeSortCriteriaSlice';
import showSessionModalReducer from '@/ui/store/slices/showSessionModalSlice';
import showLoginReducer from '@/ui/store/slices/showLoginSlice';
import indexCheckOutReducer from '@/ui/store/slices/indexCheckOutSlice';

// Define tus reducers
const rootReducer = {
  userState: userReducer,
  managementState: managementReducer,
  categoriesMenuState: categoriesMenuReducer,
  attributesState: attributesReducer,
  mainMenuState: mainMenuReducer,
  priceRangeState: priceRangeReducer,
  cartState: cartReducer,
  orderState: orderReducer,
  favoritesState: favoritesReducer,
  catalogFilterState: catalogFilterReducer,
  storeSortCriteria: storeSortCriteriaReducer,
  showSessionModal: showSessionModalReducer,
  showLogin: showLoginReducer,
  indexCheckOut: indexCheckOutReducer,
};

// Crear el store de Redux
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware()),
});

// Inicializar el estado con la pestaña previa
initStateWithPrevTab(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

/* eslint-disable-next-line @typescript-eslint/no-invalid-void-type */
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
