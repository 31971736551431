import ClientRepository from '@/data/repositories/ClientRepository';
import {
  usePasswordValidation,
  numberandSymbolValidation,
  lengthValidation,
  lowercaseCapitalValidation,
  refPasswordErrors
} from '@/domain/genericValidations/usePasswordValidation';
import { IChangePaswd } from '@/domain/interfaces/IChangePaswd';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';

/**
 * Caso de uso de cambiode contraseña
 * @param {IChangePaswd} passwords - actual contraseña y/o nueva contraseña
 * @param {boolean} onlyPassword - flag para saber si debe validar unicamente el password actual
 * @param {number} id - ID del cliente
 * @param {string} token - token valido
 * @param {boolean} toSend - flag para saber si debe enviar al back
 * @param {string} email - email del usuario activo
 */
const changePasswdUseCase = async (
  passwords: IChangePaswd,
  onlyPassword: boolean = true,
  id: number,
  token: string = '',
  toSend: boolean,
  email: string = ''): Promise<IUserResponse> => {

  /**
   * Instancia del Repository
   * @const {ClientRepository}
   */
  const clientRepository = ClientRepository.instance;

  /** Objeto enviado como respuesta */
  let response: IUserResponse = {
    data: null,
    isError: false,
    inputError: null,
    message: [],
  };

  const arrMsg: string[] = [];

  /** Hook validacion completa de contraseña */
  const validaPassword = usePasswordValidation;

  /** Mensajes de error */
  const messageNoExist: string = 'Usuario no existe!';
  const messageErrOldPassword: string = 'Contraseña actual invalida';
  const messageErrNewPassword: string = 'Contraseña nueva invalida';


  /** Valida formato de la contraseña actual */
  if (!validaPassword(passwords.password!)) {
    response.inputError = 'password';
    response.isError = true;
    arrMsg.push(messageErrOldPassword);
  }

  /** Valida formato de la contraseña nueva y doble digitación si se digitó */
  if ((passwords?.newPassword ?? '') || (!onlyPassword)) {
    /** Validación de simbolos y numeros */
    if (!numberandSymbolValidation(passwords.newPassword!)) {
      response.inputError = 'newPassword';
      response.isError = true;
      arrMsg.push(refPasswordErrors.errorTypeNumberAndSymbol);
    }

    /** Validación de Mayusculas y minusculas */
    if (!lowercaseCapitalValidation(passwords.newPassword!)) {
      response.inputError = 'newPassword';
      response.isError = true;
      arrMsg.push(refPasswordErrors.errorTypeLowercaseCapitalLetters);
    }

    /** Validación de tamaño */
    if (!lengthValidation(passwords.newPassword!)) {
      response.inputError = 'newPassword';
      response.isError = true;
      arrMsg.push(refPasswordErrors.errorTypeLength);
    }

    /** Validación completa formato de contraseña */
    if (!validaPassword(passwords.newPassword!)) {
      response.inputError = 'newPassword';
      response.isError = true;
      arrMsg.push(messageErrNewPassword);
    }

    /** Validación contraseña no puede ser igual al email */
    if (passwords.newPassword === email) {
      response.inputError = 'newPassword';
      arrMsg.push(refPasswordErrors.errorTypeEqualToEmail);
      response.isError = true;
    }

    /** Validación contraseña no puede ser igual a la anterior */
    if (passwords.newPassword === passwords.password) {
      response.inputError = 'newPassword';
      arrMsg.push(refPasswordErrors.errorTypeEqualToLast);
      response.isError = true;
    }

    /** Validación doble digitación contraseña nueva */
    if (passwords.newPassword !== passwords.newPasswordAgain) {
      response.inputError = 'newPasswordAgain';
      arrMsg.push(refPasswordErrors.errorTypeNotTheSame);
      response.isError = true;
    }
  }

  response.message = arrMsg;

  /**
   * Registra el cliente si es un submit action
   */
  if (toSend) {
    /** Almacenara el codigo de error del repository si existe */
    let errorCatch = null;

    /**
     * Envia info al back para cambiar la contraseña
     */
    await clientRepository.changePasswordClient(passwords, id, token)
      .then((result) => {
        if (result.status === 200) {
          /** Apaga el flag de error */
          response.isError = false;
        }
      })
      .catch((error) => {
        /** Devuelve el error */
        errorCatch = error.response.status;
      });

    /** Error 204 el usuario no existe */
    if (errorCatch === 204) {
      response.message.push(messageNoExist);
      return response;
    }

    /** Error 422 el usuario no paso validación en back */
    if (errorCatch === 422) {
      response.isError = true;
      response.inputError = 'password';
      response.message.push(messageErrOldPassword);
      return response;
    }
  }

  return response;
}

export default changePasswdUseCase;