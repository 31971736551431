import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import CartController from '@/controllers/CartController';

import { ICart } from '@/domain/interfaces/ICart';

import MainTitle from '@/ui/components/MainTitle';
import InputForm from '@/ui/components/forms/InputForm';
import InputMapForm from '@/ui/components/forms/InputMapForm';

interface PropsAddressLayout {
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<ICart>;
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: ICart) => void;
  /** Función para el boton del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
}

/**
 * Componente para formulario de Direcciones
 * @component
 */
const AddressLayout = ({ handleNextButton, formRef, updateFormRef }: PropsWithChildren<PropsAddressLayout>): JSX.Element => {
  /** Referencia al input dirección principal */
  const mainAddressRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Referencia al input detalles de dirección */
  const addressDetailsRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado de la direccion principal al seleccionar la opcion */
  const [address, setAddress] = useState<string>(formRef.current?.clientDetails?.address ?? '');
  /** Estado de la comuna y region al seleccionar la opcion */
  const [region, setRegion] = useState<string>(formRef.current?.clientDetails?.region ?? '');

  /** Función que setea el lugar a donde hace referencia el input */
  const handleSelect = (address: string, region: string) => {
    setAddress(address);
    setRegion(region);
  };

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /** Controlador de carrito */
  const { updateClientInCart } = CartController();

  /** Objeto inicial del carrito */
  const initialCartCheckoutForm: ICart = cartProducts;

  /** */
  const handleValidate = () => {
    /** Establece el formulario a actualizar */
    const formCartCheckOut: ICart = formRef.current
      ? JSON.parse(JSON.stringify(formRef.current))
      : JSON.parse(JSON.stringify(initialCartCheckoutForm));
    if (formCartCheckOut.clientDetails) {
      formCartCheckOut.clientDetails.address = address;
      formCartCheckOut.clientDetails.region = region;
      formCartCheckOut.clientDetails.optionalAddress = addressDetailsRef.current?.value ?? '';
      updateFormRef(formCartCheckOut);
      updateClientInCart(formCartCheckOut.clientDetails);
    }
  };

  const onkeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  /** Evento que envia el formulario cuando se presiona el boton */
  const handleSendInfo = () => {
    handleValidate();
    handleNextButton(new MouseEvent('click'));
  };

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      mainAddressRef.current!.value = formRef.current?.clientDetails?.address ?? '';
      addressDetailsRef.current!.value = formRef.current?.clientDetails?.optionalAddress ?? '';
      handleValidate();
    }

  }, []);

  /**CONSTANTES DE TEXTO */
  const SHIPPING_TEXT = 'Envío a domicilio';
  const SHIPPING_DESCRIPTION = '¡Compra personalizada! Ingresa tu dirección para la entrega de tu compra. ¡Hazlo tuyo en segundos con un clic!';

  const COUNTRY_LABEL = 'Pais';
  const STREET_LABEL = 'Calle y número';
  const ADDRESS_DETAILS_LABEL = 'Torre/ Apartamento/ Conjunto/ Oficina/ Condominio';

  return (
    <Container fluid className='p-0'>
      <Row className='g-0 mb-3 p-3 bg-primary-4 rounded rounded-4  shadow'>
        <Col lg={12} className='d-flex text-start'>
          <MainTitle
            titleSize='h2'
            iconName='ico-store'
            iconClass='svg-primary-3 svg-32'
            boldTitle={SHIPPING_TEXT}
            detailsText={SHIPPING_DESCRIPTION}
          />
        </Col>
      </Row>
      <Row className='g-0 mt-4 p-3 bg-primary-4 rounded rounded-4 shadow'>
        <Col lg={5} className='mx-auto my-4'>
          <Form onKeyDown={onkeyDown}>
            <Col>
              <InputForm className='my-2' label={COUNTRY_LABEL} value='CHILE' disabled />
              <InputMapForm
                inputRef={mainAddressRef}
                className='my-2'
                classMain={address === 'error' ? 'form-control-error' : ''}
                errorClass='text-complementary-2 text-nowrap'
                messageError={address === 'error' ? 'Seleccione una dirección válida' : ''}
                onPlaceSelected={handleSelect}
                label={STREET_LABEL}
              />
              <InputForm inputRef={addressDetailsRef} className='my-2' label={ADDRESS_DETAILS_LABEL} />
            </Col>
            <div className='d-grid gap-2 mt-3'>
              <Button
                className={`btn-primary-icon-standard px-5 ${address === '' || address === 'error' ? 'disabled' : ''}`}
                onClick={handleSendInfo}>
                {'CONTINUAR'}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressLayout;
