import { IClientDetail } from '@/domain/interfaces/ICart';
import { IIdentification, IPhone } from '@/domain/interfaces/IUser';

/**
 * Hook personalizado para crear un objeto de de detalle del cliente vacio en el carrito .
 * @returns {IClientDetail}  cartClientDetailProductForm
 */
const useCreateCartClientDetailsForm = (): IClientDetail => {

    const emptyIdentificationObject: IIdentification = {
        type: '',
        value: ''
    }

    const emptyPhoneObject: IPhone = {
        number: '',
        country: ''
    }

    /**Objeto Vacío de Detalles de cliente */
    const emptyCartClientObject: IClientDetail = {
        id: null,
        firstName: '',
        lastName: '',
        address: '',
        region: '',
        identificationId: emptyIdentificationObject,
        email: '',
        phone: emptyPhoneObject
    }


    return emptyCartClientObject;
};

export { useCreateCartClientDetailsForm };
