import { PropsWithChildren } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import useAttributesPresenter from '@/ui/hooks/useAttributesPresenter';

import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import { ICart, ICartDetail } from '@/domain/interfaces/ICart';

import currencyFormat from '@/domain/utils/currencyFormat';

import Icon from '@/ui/assets/Icon';
import ErrorImage from '@/ui/assets/ErrorImage';

import ProductStatusPill from '@/ui/components/product/ProductStatusPill';
import SecondaryPrice from '@/ui/components/product/SecondaryPrice';
import MainPrice from '@/ui/components/product/MainPrice';

interface PropsPurchaseSummaryLayout {
  handleNextButton: (e: MouseEvent) => void;
}

/**componente principal de la pagina de resumen de compra*/
const PurchaseSummaryLayout = ({ handleNextButton }: PropsWithChildren<PropsPurchaseSummaryLayout>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /**Trae la información del carrito desde Redux*/
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /**Trae la direccion de envio desde el carrito de compras */
  const address = cartProducts.clientDetails.address;
  const region = cartProducts.clientDetails.region;
  const optionalAddress = cartProducts.clientDetails.optionalAddress;

  //Transforma atributos en un objeto como se presentan para el cliente
  const { attributesPresenter } = useAttributesPresenter();

  /**Componente de resumen de compra*/
  const Resume = ({ handleNextButton }: PropsWithChildren<PropsPurchaseSummaryLayout>, hide?): JSX.Element => {
    const handleClick = () => {
      handleNextButton(new MouseEvent('click'));
    };

    return (
      <Col lg={4} className={`g-0 ${hide && 'd-none'}`}>
        {[
          { label: PRODUCT_COST_TEXT, value: currencyFormat(cartProducts.totalWithTax, ConfigNumberParameters.DECIMALS_ALLOWED) },
          { label: SHIPPING_COST_TEXT, value: currencyFormat(cartProducts.totalShipping, ConfigNumberParameters.DECIMALS_ALLOWED) },
          { label: TOTAL_PAYMENT_TEXT, value: currencyFormat(cartProducts.totalAmount, ConfigNumberParameters.DECIMALS_ALLOWED), bold: '-bold' },
        ].map((item, index: number) => (
          <Row key={index} className='text-start d-flex justify-content-between px-1 mb-2 g-0 '>
            <Col xs={7} className={`${isMobile ? 'p-regular' : 'p-large'}${item.bold ?? ''} text-secondary-2`}>
              {item.label}
            </Col>
            <Col xs={5} className={`${isMobile ? 'p-regular' : 'p-large'}${item.bold ?? ''} text-secondary-2 text-end`}>
              {item.value}
            </Col>
          </Row>
        ))}
        <Col className='pb-2'>
          <Button className='w-100 svg-primary-4 btn-primary-icon-standard' type='button' onClick={handleClick}>
            <span className='p-regular-medium'>{BUY_BUTTON_TEXT}</span>
          </Button>
        </Col>
      </Col>
    );
  };

  const CART_RESUMEN = 'Resumen de la compra';
  const CART_RESUMEN_MESSAGE =
    'Antes de finalizar, asegúrate de que los productos estén correctos y verifica los costos de envío, una vez todo esté en orden, continúa con tu pago. ';
  const BUY_BUTTON_TEXT = 'PAGAR';
  const SHIPPING_COST_TEXT = 'Costo de entrega:';
  const PRODUCT_COST_TEXT = 'Costo de productos';
  const TOTAL_PAYMENT_TEXT = 'Pagas';
  const QUANTITY_TEXT = 'Cantidad';
  const HOME_DELIVERY_TEXT = 'Envío a domicilio';

  /**COMPONENTES BASE */

  /**Componente Imagen */
  const PreviewImage = (product: ICartDetail): JSX.Element => (
    <div className='image-cart-container rounded rounded-3'>
      <Image
        className='rounded rounded-3 image-full cursor-hand'
        src={product.image || ErrorImage}
        alt=''
        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
          e.currentTarget.src = ErrorImage;
        }}
      />
    </div>
  );

  /**Componente Metodo de envío (REFACTORIZAR) */
  const ShippingMethod: JSX.Element = (
    <>
      <Icon name='ico-national-shipment'></Icon>
      <span className='px-2 text-secondary-1 '>{HOME_DELIVERY_TEXT}</span>
    </>
  );

  /**Componente Cantidad */
  const QuantityLabel = (value: number): JSX.Element => <span>{`${QUANTITY_TEXT}: ${value}`}</span>;

  /**Componente direccion de envío*/
  const ShippingAddress: JSX.Element = (
    <>
      <Icon name='ico-address'></Icon>
      <span className='text-secondary-1 px-2 break-all'>
        {`${address}`}
        {optionalAddress ? `, ${optionalAddress}` : ''}
        {`, ${region}`}
      </span>
    </>
  );

  return (
    <Container fluid className='px-0 pb-3'>
      <>
        {/**inicio resumen de compra */}
        <Row className='d-flex justify-content-between bg-primary-4 shadow rounded g-0 pt-4 px-4 pb-3'>
          <Col lg={8} className='g-0'>
            <Col lg={8} className='text-primary-3 text-start'>
              <h2>{CART_RESUMEN}</h2>
            </Col>
            <Col lg={8} className='text-secondary-2 text-start pb-2'>
              <span>{CART_RESUMEN_MESSAGE}</span>
            </Col>
          </Col>
          {Resume({ handleNextButton }, isMobile)}
        </Row>
        {/**Fin resumen de compra */}
        {/**Inicio  carrito de compras */}
        {isMobile ? (
          <Row className='d-flex justify-content-between align-items-start bg-primary-4 shadow rounded g-0 px-3 my-4'>
            <Col xs={12} className='text-primary-1 d-flex  text-start  mt-1 mb-2 p-small-bold'>
              {ShippingMethod}
            </Col>
            <Col xs={12} className='text-primary-1 d-flex text-start  p-small-bold'>
              {ShippingAddress}
            </Col>
            {cartProducts.details.map((product) => {
              const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice >= 0;
              const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;

              const attributes: string = attributesPresenter(product.attributes);
              return (
                <Container fluid className='p-0 bg-primary-4 my-2 rounded rounded-3 shadow' key={product.skuProduct}>
                  <Row className='g-0 p-1 d-flex '>
                    <Col xs={5} className='mx-auto text-center position-relative'>
                      {PreviewImage(product)}
                      <div className='top-0 end-0 position-absolute pe-2'>
                        <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />
                      </div>
                    </Col>
                    <Col xs={7} className='ps-3 pe-2 position-relative '>
                      <Row className='g-0 d-flex '>
                        <Col xs={12} className='p-regular text-start text-truncate'>
                          <span className='p-regular-medium'>{product.title}</span>
                        </Col>
                        <Col xs={12} className=' text-start text-truncate'>
                          <span className='p-small'>{attributes}</span>
                        </Col>
                        <Row className='mt-4 g-0 text-end'>
                          <Col xs={12} className='p-large-bold'>
                            <MainPrice isFree={isFree} value={isInOffer ? product.offerPrice ?? 0 : product.price} className='p-title-bold' />
                          </Col>
                          <Col xs={12} className='p-large-medium'>
                            <SecondaryPrice isInOffer={isInOffer} value={product.price} className='p-regular-medium' />
                          </Col>
                          <Col xs={12} className='p-large-medium'>
                            {QuantityLabel(product.quantity)}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              );
            })}
          </Row>
        ) : (
          <Row className='d-flex justify-content-between align-items-start bg-primary-4 shadow rounded g-0 px-3 my-4'>
            <Col lg={12} className='text-primary-1 d-flex  text-start  mt-2 mb-2 p-title-bold'>
              {ShippingMethod}
            </Col>
            <Col lg={12} className='text-primary-1  p-title-bold d-flex text-start'>
              {ShippingAddress}
            </Col>
            {cartProducts.details.map((product) => {
              const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice >= 0;
              const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;
              const attributes: string = attributesPresenter(product.attributes);
              return (
                <Container fluid className='p-2 bg-primary-4 my-3 shadow rounded' key={product.skuProduct}>
                  <Row className={'g-0'}>
                    <Col lg={2}>
                      <Col className='mx-4'>{PreviewImage(product)}</Col>
                    </Col>
                    <Col lg={10} className='text-start'>
                      <Row className='ps-3 d-flex'>
                        <Col xs={12}>
                          <Row className='d-flex'>
                            <Col>
                              <span className='p-large-bold'>{product.title}</span>
                            </Col>
                            <Col className='text-end'>
                              <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className='mb-3'>
                          <span>{attributes}</span>
                        </Col>
                        <Col xs={12} className='d-flex mt-4 align-self-end align-items-center p-large-bold justify-content-end'>
                          <SecondaryPrice isInOffer={isInOffer} value={product.price} className='pe-3' />
                          <MainPrice isFree={isFree} value={isInOffer ? product.offerPrice ?? 0 : product.price} />
                        </Col>
                        <Col xs={12} className='p-large-bold text-end'>
                          {QuantityLabel(product.quantity)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              );
            })}
          </Row>
        )}

        <Row className='d-flex justify-content-center bg-primary-4 shadow rounded g-0 pt-2 px-2  '>{Resume({ handleNextButton })}</Row>
      </>
    </Container>
  );
};

export default PurchaseSummaryLayout;
