import { PropsWithChildren } from 'react';

import { Col, Form } from 'react-bootstrap';

import '@/ui/components/forms/form.css';

import Icon from '@/ui/assets/Icon';


interface PropsDropDownCustom<T extends { id: number; option: string }> {
  /** Arreglo de objetos de cualquier tipo, pero cada objeto debe incluir las propiedades id y option. */
  arrayData: T[];
  /** Posicion en el arreglo de componentes */
  position: number;
  /** Valor seleccionado del dropdown */
  value?: string;
  /** Clase principal */
  classMain?: string;
  /** Referencia useRef */
  refDropDown: React.RefObject<HTMLSelectElement>;
  /** Si se desea mostrar una opción inicial */
  initialOption?: string;
  /** Evento */
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  /** Propiedad que define el titulo del select */
  label?: string;
  /** Propiedad que define la clase del label del select */
  classLabel?: string;
  /** Propiedad que define el mensaje de error del select */
  messageError?: string;
  /** Manejador eliminar dropdown */
  handleReset?: (position: number) => void;
  /** Opciones seleccionadar a deshabilitar */
  selectedItems?: number[];
}

const DropDownCustom = <
  T extends {
    id: number;
    option: string;
  }
>({
  arrayData = [],
  position,
  onChange,
  value = '',
  classMain = '',
  refDropDown,
  initialOption = '',
  label = '',
  classLabel = '',
  messageError = '',
  handleReset,
  selectedItems = [],
}: PropsWithChildren<PropsDropDownCustom<T>>): JSX.Element => {
  return (
    <Form.Group className='mb-3 border border-1 rounded rounded-3 p-2 shadow '>
      <Col lg={11} className='mx-auto pt-2'>
        <div className='d-flex justify-content-between'>
          <Form.Label>
            <span className={`text-nowrap ${classLabel}`}>{label}</span>
          </Form.Label>
          <div>
            {messageError &&
              <span className={`p-regular-medium text-complementary-2 text-nowrap`}>
                {messageError}
              </span>}
          </div>
        </div>
        <div className='ils-form-control position-relative'>

          <Form.Select
            ref={refDropDown}
            className={`monospace-font ${classMain}`}
            value={value}
            onChange={onChange}
          >
            <option key={0}>{initialOption}</option>
            {arrayData &&
              arrayData.map((element) => {
                const exist: boolean = selectedItems?.includes(element.id);

                return (
                  <option key={element.id} value={element.id} disabled={exist} className={exist ? 'text-tertiary-1' : ''}>
                    {element.option}
                  </option>
                );
              })}
          </Form.Select>
        </div>
      </Col>
      <Col className='text-end mt-3'>
        {value !== '' && handleReset && <Icon name='ico-trash' classMain='cursor-hand' onClick={() => handleReset(position)} />}
      </Col>
    </Form.Group>
  );
};

export default DropDownCustom;
