import { createSlice } from '@reduxjs/toolkit';
import { cartLocalStorage } from '@/data/navLocalStorage';
import { ICart } from '@/domain/interfaces/ICart';

/** Objeto inicial del carrito */
const initialState: ICart | {} = cartLocalStorage();

/** Almacena el estado userState de Redux con sus reducers
  * @constant {object}
*/
export const cartSlice = createSlice({
  name: 'cartState',
  initialState,
  reducers: {
    updateCartRedux: (state, action) => {
      // Actualiza el estado mutando el objeto state con los nuevos cartItems
      Object.assign(state, action.payload);
    },
    removeCartRedux: (state) => {
      // Limpia el estado haciendo que sea un objeto vacío
      Object.keys(state).forEach((key) => delete state[key]);
    },
  },

})

export const { updateCartRedux, removeCartRedux } = cartSlice.actions;

export default cartSlice.reducer;
