/**
     * Recibe un array de texto y la descarga como archivo txt.
     * @param array - arreglo a descargar .
     * @param text - titulo del archivo .
     * @returns  booleano .
*/
const downloadTxt = (array: string[], text: string): void => {
    const arrayText = array.join('\n');
    const blob = new Blob([arrayText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = text;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export default downloadTxt;