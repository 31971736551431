import axios, { AxiosResponse } from 'axios';
import config from '@/data/services/api';
import { IClient, ILoginData, IRegistrationData } from '@/domain/interfaces/IUser';
import { IChangePaswd } from '@/domain/interfaces/IChangePaswd';
import { ILoginApi } from '@/domain/interfaces/ILoginResponse';

/** Representa la interacción con el Back */
export default class ClientRepository {
  /** Instancia de la clase   */
  private static _instance: ClientRepository;

  /** Patron Singleton
   * @returns {instance} _instance ClientRepository
   */
  static get instance(): ClientRepository {
    if (!this._instance) {
      this._instance = new ClientRepository();
    }
    return this._instance;
  }

  /** Realiza login
   * @param {ILoginData} user - Data del usuario a logear
   * @returns {object} data - email - token -status
   */
  async loginUser(user: ILoginData): Promise<AxiosResponse> {
    return await axios.post('/client/auth', user, config);
  }

  /**
   * Registro de nuevo cliente
   * @param {IRegistrationData} user - Data del cliente a registrar
   * @returns {object} data - id del nuevo registro
   */
  async registerUser(user: IRegistrationData): Promise<AxiosResponse> {
    return await axios.post('/client/register', user, config);
  }

  /**
   * Get info Usuario
   * @param {string} email - email del usuario
   * @param {string} token - token valido
   * @returns {object} data usuario
   */
  async getUserByEmail(email: string, token: string = ''): Promise<AxiosResponse> {
    config.headers!['Authorization'] = `Bearer ${token}`;
    return await axios.get(`/client/${encodeURIComponent(email)}`, config);
  }

  /**
   * Actualiza info Usuario
   * @param {IClient} client - data usuario
   * @param {number} id - id cliente
   * @param {string} token - token valido
   * @returns {object} data usuario
   */
  async updateClient(client: IClient, id: number = 0, token: string = ''): Promise<AxiosResponse> {
    config.headers!['Authorization'] = `Bearer ${token}`;
    return await axios.post(`/client/update/${id}`, client, config);
  }

  /**
   * Actualiza info Usuario
   * @param {IChangePaswd} passwords - contraseña actual y nueva
   * @param {number} id - id cliente
   * @param {string} token - token valido
   * @returns {object} change status
   */
  async changePasswordClient(passwords: IChangePaswd, id: number = 0, token: string = ''): Promise<AxiosResponse> {
    config.headers!['Authorization'] = `Bearer ${token}`;
    return await axios.post(`/client/changePassword/${id}`, passwords, config);
  }

  /**
   * Actualiza el token
   * @param {string} token - token valido
   * @returns {object} change status
   */
  async renovateToken(token: string = ''): Promise<AxiosResponse<ILoginApi>> {
    config.headers!['Authorization'] = `Bearer ${token}`;
    return await axios.post('/client/renovate-login',{},config);
  }
}
