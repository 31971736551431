import { ISelectOptionType } from '@/domain/interfaces/ISelect';
import { PropsWithChildren } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface PropsInputSelect {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  disabled?: boolean;
  optionData: ISelectOptionType[];
}
/**
 * Componente Select para formularios
 * @component
 */
const InputSelect = ({ value, onChange, className, disabled, optionData }: PropsWithChildren<PropsInputSelect>): JSX.Element => {
  return (
    <Row className="d-flex justify-content-center">
      <Col lg={6} xxl={12}>
        <Form.Select value={value} onChange={onChange} className={className} disabled={disabled}>
          {optionData.map((option) => (
            <option key={option.id} value={option.id} disabled={option.disabled} className={option.className}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Row>
  );
};

export default InputSelect;
