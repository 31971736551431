import { PropsWithChildren, ReactNode } from 'react';
import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';
import { FormCheck } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';

import Title from '@/ui/components/Title';

interface PropsIconCheck {
  /** Id del componente IconCheck */
  id?: string;
  /** Clase del componente IconCheck */
  classMain?: string;
  /** Clase del titulo */
  classTitle?: string;
  /** Titulo del componente IconCheck */
  title: ReactNode;
  /** Nombre del Icono del componente IconCheck */
  iconName?: string;
  /** Subtitulo del componente IconCheck */
  subtitle?: string;
  /** Texto alternativo en pila */
  pillText?: string;
  /** Color del componente ColorCheck en código Hexadecimal */
  color?: string;
  /** Indicar si el checkbox está marcado */
  isChecked?: boolean;
  /** Función de devolución de llamada para manejar el cambio de estado */
  onCheckChange?: (isChecked: boolean) => void;
}

/** Componente SVG de salida de lista con icono
 * @component
 */
const IconCheck = ({
  id = '',
  classMain = '',
  classTitle = '',
  iconName = '',
  title,
  subtitle = '',
  pillText = '',
  color = '',
  isChecked = false,
  onCheckChange,
}: PropsWithChildren<PropsIconCheck>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido    */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  const handleCheck = (): void => {
    if (onCheckChange) {
      onCheckChange(!isChecked);
    }
  };

  return (
    <li id={id} className={`d-flex align-items-center my-1 ${classMain} ${isChecked && 'active'} `} onClick={handleCheck}>
      {iconName !== '' && <Icon name={iconName} />}
      {color !== '' && (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='24' height='24' rx='12' fill={color} stroke='#dee2e6' />
        </svg>
      )}
      <Title classMain='ps-3'>
        <span className={classTitle}>{title}</span>
        <span className={`align-self-baseline d-block ${isMobile ? 'p-regular' : 'p-small'}`}>{subtitle}</span>
      </Title>

      {pillText && <span className='p-regular text-tertiary-1 pe-3'>({pillText})</span>}

      <FormCheck className='pe-1' checked={isChecked} onChange={() => {}} />
    </li>
  );
};

export default IconCheck;
