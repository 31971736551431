import { PropsWithChildren, useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { change } from '@/ui/store/slices/managementSlice';

import lastPageName from '@/domain/utils/lastPageName';
import useAskUserCredentials from '@/ui/hooks/useAskUserCredentials';

import { Col, Container, Row } from 'react-bootstrap';

import Breadcrumbs from '@/ui/components/Breadcrumbs';

import NavUser from '@/ui/containers/userOptions/NavUser';

import './style.css';

interface PropsUserOptionsContainer {
  /** Propiedad que define el tipo de Alert*/
  type: string;
}

/**
 * Container de las páginas para administración del usuario
 * @component
 */
const UserOptionsContainer = ({ type = '', children }: PropsWithChildren<PropsUserOptionsContainer>): JSX.Element => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Si el usuario no está logueado se ejecutará la utilidad para pedir credenciales */
  useAskUserCredentials();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Cambiamos el estado del header cuando entra en un estado administrativo */
  const dispatch = useAppDispatch();

  /** Uso de los efectos del componente para ocultar o mostrar elementos en el header*/
  useEffect(() => {
    /** Actualiza el estado Redux del managementState al montar el componente      */
    if (isMobile) {
      dispatch(change({ value: true }));
    }

    return () => {
      /** Actualiza el estado Redux del managementState al desmontar el componente      */
      dispatch(change({ value: false }));
    };
  }, [width, maxLargeWidth, dispatch]);

  return userLogued.user.isLogued ? (
    <Container fluid id='content-user' className={`${!isMobile ? 'bg-secondary-5 ps-4 pe-5' : 'bg-primary-4'} p-0 pb-5 pt-3 ${type}`}>
      {!isMobile && (
        <Row className=' mt-1 me-2 mb-3 ms-3 g-0'>
          <Col xs={12} className='p-2 bg-primary-4 shadow rounded'>
            <Breadcrumbs list={breadcrumbsList} />
          </Col>
        </Row>
      )}
      <Row className='mx-2 g-0'>
        {!isMobile && (
          <Col xs={3}>
            <Container className='nav-desktop'>
              <Row className='g-0'>
                <Col xs={12} className='p-0 align-self-center bg-primary-4 rounded-2 min-vh-85'>
                  <Container fluid className='p-0 min-vh-85'>
                    <NavUser classCloseIcon='invisible' classSignOutIcon='d-none' />
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        )}
        <Col xs={12} md={9} className={`${!isMobile && 'bg-primary-4 rounded-2 shadow'}`}>
          <Container fluid>
            <Col xs={12} md={{ span: 8, offset: 1 }} className={`${!isMobile && 'py-4 px-3'}`}>
              {children}
            </Col>
          </Container>
        </Col>
      </Row>
    </Container>
  ) : (
    <p>No Autorizado, Mostrar Interface</p>
  );
};

export default UserOptionsContainer;
