import axios, { AxiosResponse } from 'axios';
import config from '@/data/services/api';
import { IAttributesFilters } from '@/domain/interfaces/IAttributes';
import { IMainAttributesVariantsValues, IProduct, IProductForm } from '@/domain/interfaces/IProduct';
import { IBackResponseAdd, IResponseValidationBack } from '@/domain/interfaces/IProductResponse';

/** Representa la interacción con el Back */
export default class ProductRepository {
  /** Instancia de la clase   */
  private static _instance: ProductRepository;

  /** Patron Singleton
   * @returns {instance} _instance ProductRepository
   */
  static get instance(): ProductRepository {
    if (!this._instance) {
      this._instance = new ProductRepository();
    }
    return this._instance;
  }

  /**
   * Solicita los productos disponibles en la tienda,
   * @param {IAttributesFilters} queryParams
   * @param {number} page
   * @returns {object} productos encontrados
   */
  async getProductsStore(queryParams: IAttributesFilters, page: number): Promise<AxiosResponse<IProduct[]>> {
    /** URL a consultar */
    let url: string = `/product/store/${page}`;

    return await axios.post(url, queryParams, config);
  }

  /**
   * Obtiene una lista de los productos más nuevos desde el servidor.
   *
   * @param {number} productToShow - El número de productos nuevos a mostrar.
   * @returns {Promise<AxiosResponse>} - Una promesa que resuelve en una lista de los productos más nuevos.
   */
  async getNewProducts(productToShow: number): Promise<AxiosResponse<IProduct[]>> {
    /** URL a consultar */
    let url: string = `/product/store/news/${productToShow}`;

    return await axios.get(url, config);
  }

  /** Solicita los rangos de precios de la tienda y/o de la categoría
   * @param {String} slugCategory
   * @returns {object} rangos de precios encontrados
   */
  async getPricesStore(slugCategory: String = ''): Promise<AxiosResponse> {
    /** URL a consultar */
    let url: string = `/product/prices`;

    /** Se agrega el slug de la categoría si se requiere */
    url += slugCategory.length > 0 ? `/${slugCategory}` : '';

    return await axios.get(url, config);
  }

  /** Solicita los rangos de precios de la tienda y/o de la categoría
   * @param {string} skuProduct
   * @returns {object} Producto encontrado
   */
  async getProductBySku(skuProduct: string = ''): Promise<AxiosResponse<IProduct[]>> {
    /** URL a consultar */
    let url: string = `/product/${skuProduct}`;

    return await axios.get(url, config);
  }

  /** Solicita las variantes asociadas a un producto agrupadas por atributo principal
   * @param {string} skuProduct
   * @returns {object} Producto encontrado
   */
  async getVariantsBySku(skuProduct: string = ''): Promise<AxiosResponse<IProduct[]>> {
    /** URL a consultar */
    let url: string = `/product/variants/${skuProduct}`;

    return await axios.get(url, config);
  }

  /** Solicita atributo principal y sus posibles valores agrupados, de todas las variantes asociadas al SKU consultado
   * @param {string} skuProduct
   * @returns {object} Producto encontrado
   */
  async getMainAttributeValuesBySku(skuProduct: string = ''): Promise<AxiosResponse<IMainAttributesVariantsValues[]>> {
    /** URL a consultar */
    let url: string = `/product/main-attributes/${skuProduct}`;

    return await axios.get(url, config);
  }

  /** Solicita los nombres de atributos asociados a variantes para la segunda personalización
   * @param {string} skuProduct
   * @returns {object} Producto encontrado
   */
  async getVariantsSecondCustomization(skuProduct: string = ''): Promise<AxiosResponse> {
    /** URL a consultar */
    let url: string = `/product/variants/attributesName/${skuProduct}`;

    return await axios.get(url, config);
  }

  /**
   * Valida y agrega producto base y variantes en el back
   * @param {IProductForm} productForm
   * @param {string} token
   * @returns {object} Id Creado
   */
  async addProductBase(productForm: IProductForm, token: string = ''): Promise<AxiosResponse<IBackResponseAdd>> {
    /** URL a consultar */
    let url: string = `/product/add`;
    config.headers!['Authorization'] = `Bearer ${token}`;

    return await axios.post(url, productForm, config);
  }

  /**
   * Validación de producto base y variantes en el back
   * @param {IProductForm} productForm
   * @param {string} token
   * @returns {object} Id Creado
   */
  async validateProductBase(productForm: IProductForm, token: string = ''): Promise<AxiosResponse<IResponseValidationBack>> {
    /** URL a consultar */
    let url: string = `/product/validate`;
    config.headers!['Authorization'] = `Bearer ${token}`;

    return await axios.post(url, productForm, config);
  }
}
