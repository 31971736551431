import { useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import { isEmpty } from 'lodash';

import useUpdateProductsInCart from '@/ui/hooks/useValidateUnavailableProducts';

import { RootState } from '@/ui/store/store';
import { useAppSelector } from '@/ui/store/helperRedux';

import CartController from '@/controllers/CartController';

import Icon from '@/ui/assets/Icon';

/**
 * Componente SVG a carrito de compras
 * @component
 */
const CartWidget = () => {
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /**Hook que valida el carrito */
  const { hasUnavailableProducts } = useUpdateProductsInCart();

  /**Respuesta del hook que valida el carrito */
  const validateCart = hasUnavailableProducts();

  /** Controlador de carrito */
  const { productQuantity } = CartController();

  const cartProducts = useAppSelector((state: RootState) => state.cartState);

  /** State del contador del componente */
  const [counter, setCounter] = useState<number>(0);

  /** UseEffect para traer la cantidad de productos desde el carrito */
  useEffect((): void => {
    setCounter(productQuantity);
  }, [cartProducts, productQuantity]);

  /** Renderiza el contador del carrito */
  const renderCounter = () => (
    <span className='position-absolute top-0 start-100 translate-middle-60-30 badge rounded-pill bg-danger p-small '>{counter}</span>
  );

  /** Renderiza el icono de advertencia */
  const renderWarningIcon = () => (
    <span className='position-absolute top-100 translate-middle badge rounded-circle bg-complementary-4 small-circle '>
      <Icon name='ico-warning' classMain='svg-12' />
    </span>
  );

  /** Renderiza el icono de carrito de compras */
  return (
    <div className='position-relative'>
      <Icon name='ico-market-car' />
      {location.pathname === RoutesDirections.CHECK_OUT_ROUTE || isEmpty(cartProducts) || !validateCart.error ? renderCounter() : renderWarningIcon()}
    </div>
  );
};

export default CartWidget;
