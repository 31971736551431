import { IMenu } from '@/domain/interfaces/IMenu';
import { AxiosResponse } from 'axios';
import CategoriesRepository from '@/data/repositories/CategoriesRepository';

/** Representa el controlador que maneja los eventos de menus
 * @returns {object} Funciones del controlador
 */
const CategoriesController = () => {

  /**
   * Solicita las categorías disponibles
   */
  const categories = async (): Promise<IMenu[]> => {
    /** Instancia del Repository
     * @const {MenuRepository}
     */
    const menuRepository = CategoriesRepository.instance;
    const categories: IMenu[] = [];

    try {
      /** Trae del back la información de categorias */
      const categoriesList:AxiosResponse<IMenu[]> = await menuRepository.getCategories();
      if (categoriesList.data && categoriesList.data.length > 0) {
        categories.splice(0, categories.length -1, ...categoriesList.data);
      }
      
    } catch(error) {
      console.error("Categorías", error);
    }

    return categories;
  };

  return { categories };
};

export default CategoriesController;
