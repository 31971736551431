import { useEffect, useState } from 'react';

import { ColumnDef } from '@tanstack/react-table';

import CategoriesController from '@/controllers/CategoriesController';
import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';

import { Col, Modal, Row } from 'react-bootstrap';

import { IProductForm } from '@/domain/interfaces/IProduct';
import { IMenu } from '@/domain/interfaces/IMenu';
import { ICategories, IPathCategoryType } from '@/domain/interfaces/ICategories';

import findCategory from '@/domain/utils/findCategory';

import Icon from '@/ui/assets/Icon';
import CloseIcon from '@/ui/assets/CloseIcon';

import TableCustom from '@/ui/components/TableCustom';
import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import IconList from '@/ui/components/IconList';
import TextAndLink from '@/ui/components/TextAndLink';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import MainTitle from '@/ui/components/MainTitle';

interface PropsSelectCategory {
  /**Títulos pasados por props de AddProducts */
  //Titulo de producto
  productTitle: string;
  //Titulo del tab
  mainTitle: string;
  //Texto de clasificación
  classificationLabelText: string;
  //Texto de botón GUARDAR Y CONTINUAR
  nextButtonText: string;
  //Texto de botón CANCELAR
  cancelButtonText: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Objeto que representa la categoría referenciada */
  pathCategoryState: IPathCategoryType[];
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;

  /** Función que actualiza el pathCategoryState */
  updatePathCategoryState: (value: IPathCategoryType[]) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

const SelectCategory = ({
  mainTitle,
  productTitle,
  classificationLabelText,
  nextButtonText,
  cancelButtonText,
  formRef,
  pathCategoryState,
  updateFormRef,
  updatePathCategoryState,
  handleNextButton,
  handleCancelButton,
}: PropsSelectCategory): JSX.Element => {
  /** Controlador categorías */
  const { categories } = CategoriesController();

  /** Data inicial para el state de categorías */
  const initialMenuData = {
    icon: '',
    id: '',
    title: '',
    slug: '',
    url: '',
  };

  /** State de categorías disponibles */
  const [categoriesState, setCategoriesState] = useState<IMenu>(initialMenuData);

  /** Data de la tabla categorías */
  const [currentCategory, setCurrentCategory] = useState<ICategories[]>([]);

  /** Estado que Detecta si es un nombre valido para habilitar el botón */
  const [isAValidCategory, setIsAValidCategory] = useState<boolean>(false);

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Estado para controlar la visibilidad del modal */
  const [isModalVisible, setIsModalVisible] = useState(false);

  /** Estado de selección de categoría */
  const [inCategorySelection, setInCategorySelection] = useState<boolean>(formRef.current?.category ? false : true);
  /** Estado de selección de subcategoría */
  const [inSubcategorySelection, setInSubcategorySelection] = useState<boolean>(false);
  /** Estado final de selección de categoría */
  const [inFinalCategorySelection, setInFinalCategorySelection] = useState<boolean>(formRef.current?.category ? true : false);

  /** Definición de columnas */
  const columns: ColumnDef<ICategories>[] = [
    {
      accessorKey: 'id',
      header: () => 'ID',
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'category',
      header: () => (inCategorySelection ? 'Categoría' : 'Subcategoría'),
      cell: ({ row }) => {
        // Buscar la categoría actual basada en la URL actual
        const category: IMenu | null = findCategory(categoriesState, row.getValue('category'));
        return (
          category && (
            <IconList
              iconName={inCategorySelection ? category.icon : ''}
              classIcon='svg-secondary-3'
              title={category.title}
              classTitle='svg-secondary-3 p-large'
            />
          )
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorKey: 'select',
      header: () => <div className='text-end '>{'Seleccionar'}</div>,
      cell: ({ row }) => {
        return (
          <span className='icon-selection text-center'>
            <Icon name='ico-selection' classMain='svg-tertiary-5 d-flex justify-content-end' />
          </span>
        );
      },
    },
  ];

  /**Función que envía el formulario */
  const handleSendForm = () => {
    setIsActive(false);
    handleNextButton(new MouseEvent('click'));
  };

  /** Agrega/elimina la categoría al componente CategoryPath */
  const updatePathState = (action: 'add' | 'delete', idCategory: string) => {
    const foundCategory: IMenu | null = findCategory(categoriesState, idCategory.toString());
    //este state es el que se usa en el componente CategoryPath para mostrar la ruta de categorías seleccionadas
    const newState: IPathCategoryType[] = [...pathCategoryState];
    //si la acción es agregar se agrega la categoría al state de la tabla y si es eliminar se elimina la categoría del state de la tabla
    if (action === 'add') {
      newState.push({
        idCategory: idCategory,
        iconCategory: foundCategory?.icon ?? '',
        slugCategory: foundCategory?.slug ?? '',
        nameCategory: foundCategory?.title ?? '',
      });
    }
    //si la acción es eliminar se elimina la categoría del state de la tabla y se actualiza el state de la tabla con el nuevo state
    if (action === 'delete') {
      const filteredState = newState.filter((element) => element.idCategory !== idCategory);
      newState.splice(0, newState.length, ...filteredState);

      //Luego de eliminar la sub-categoría se actualiza e state de la tabla
      const idParent: string = findParentId(categoriesState, foundCategory?.slug ?? '');
      findSubCategory(Number(idParent));
    }
    //se actualiza el state de la tabla con el nuevo state
    updatePathCategoryState(newState);
  };

  const handleShowModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  /** Funciones para cambiar el estado de selección de categoría */
  const onCategorySelection = (): void => {
    setInCategorySelection(true);
    setInSubcategorySelection(false);
    setInFinalCategorySelection(false);
    setIsAValidCategory(false);
  };
  /** Funciones para cambiar el estado de selección de subcategoría */
  const onSubCategorySelection = (): void => {
    setInCategorySelection(false);
    setInSubcategorySelection(true);
    setInFinalCategorySelection(false);
    setIsAValidCategory(false);
  };
  /** Funciones para cambiar el estado de selección de categoría final */
  const onFinalCategorySelection = (): void => {
    setInCategorySelection(false);
    setInSubcategorySelection(false);
    setInFinalCategorySelection(true);
    setIsAValidCategory(true);
  };

  /**funciones para modificar la selección de categoría*/
  const modifyCategorySelection = (): void => {
    formRef.current!.variants[0].attributes = [];
    updatePathState('delete', formRef.current?.category.toString() ?? '');

    /** Establece el formulario a actualizar */
    const formProduct: IProductForm = formRef.current
      ? JSON.parse(JSON.stringify(formRef.current))
      : JSON.parse(JSON.stringify(productBaseFormClean));
    formProduct.category = 0;
    updateFormRef(formProduct);

    setIsModalVisible(false);
  };

  /** Devuelve el id del padre de la categoría */
  const findParentId = (categories: IMenu, slugCategory: string): string => {
    if (categories.slug === slugCategory) {
      return ''; // No hay categoría padre
    }

    if (categories.children) {
      for (const child of categories.children) {
        if (child.slug === slugCategory) {
          return categories.id; // Devuelve el id del padre
        }

        const parentId = findParentId(child, slugCategory);
        if (parentId) {
          return parentId;
        }
      }
    }

    return ''; // No se encontró el slug
  };

  /** Busca de subcategorías y formato del array para el state de la tabla */
  const findSubCategory = (idCategory: number | string): void => {
    const categoriesOnState: IMenu | null = findCategory(categoriesState, idCategory.toString());
    if (categoriesOnState && categoriesOnState.children && categoriesOnState.children?.length > 0) {
      const categories = formatCurrentCategory(categoriesOnState.children);
      setCurrentCategory(categories);
      categoriesOnState?.slug === '' ? onCategorySelection() : onSubCategorySelection();
    } else {
      /** Establece el formulario a actualizar */
      const formProduct: IProductForm = formRef.current
        ? JSON.parse(JSON.stringify(formRef.current))
        : JSON.parse(JSON.stringify(productBaseFormClean));
      formProduct.category = Number(idCategory);
      onFinalCategorySelection();
      updateFormRef(formProduct);
    }

    /** Agrega la categoría al componente CategoryPath */
    updatePathState('add', idCategory.toString());
  };

  /** Inicializa el state de la tabla con las categorías padre */
  const initialCategory = async (): Promise<void> => {
    /** Consulta en el back las categorías disponibles  */
    const categoriesProducts: IMenu[] = await categories();
    const categoriesChildren: IMenu[] =
      categoriesProducts.length > 0 && categoriesProducts[0].children !== undefined ? categoriesProducts[0].children : [];

    if (categoriesProducts.length > 0) {
      /** Establece el valor en el state de categorías */
      setCategoriesState(categoriesProducts[0]);
    }

    const formattedCategories = formatCurrentCategory(categoriesChildren);
    /** Establece el valor actual con las categorías padre. */
    setCurrentCategory(formattedCategories);
  };

  /** Mapea las categorías seleccionadas al state de la tabla */
  const formatCurrentCategory = (arrayCategories: IMenu[]): ICategories[] => {
    const categories: ICategories[] = [];
    arrayCategories.forEach((child) => {
      categories.push({ id: Number(child['id']), category: child['slug'] });
    });
    return categories;
  };

  useEffect(() => {
    initialCategory();
  }, []);

  useEffect(() => {
    formRef.current?.category === 0 && inCategorySelection && updatePathCategoryState([]);
  }, [formRef.current?.category]);

  /**CONSTANTES DE TEXTO */

  /**  Descripción del segundo tab según el estado de selección de categoría y subcategoría */
  const CATEGORY_SELECTION_DESCRIPTION: string = inCategorySelection
    ? 'Busca y selecciona la categoría correspondiente a tu articulo'
    : inSubcategorySelection
    ? 'Busca y selecciona la subcategoría correspondiente a tu articulo '
    : inFinalCategorySelection
    ? 'Verifica que hayas clasificado  correctamente el artículo   y continúa con el registro, o clasifícalo de manera automática.'
    : '';

  const CANCEL_BUTTON_TEXT: string = 'CANCELAR';
  const CHANGE_CATEGORY_TEXT: string =
    'Al cambiar la clasificación, se borrarán los atributos actuales. Deberás asignar nuevos atributos según la nueva clasificación ¿deseas continuar?';
  const CHANGE_CATEGORY_LINK_TITLE: string = '¿Deseas realizar algún cambio ?';
  const CHANGE_CATEGORY_LINK_TEXT: string = 'Modificar clasificación';
  const CHANGE_BUTTON_TEXT: string = 'CAMBIAR CLASIFICACIÓN';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <Row className='g-0 text-start'>
          <MainTitle boldTitle={mainTitle} detailsText={CATEGORY_SELECTION_DESCRIPTION} />
          <Col lg={12} className='mb-3'>
            <span className='p-title-medium  me-2'>{productTitle}</span>
            <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
          </Col>
          <span className='p-regular-medium'>{classificationLabelText}</span>

          {inSubcategorySelection && (
            <Col lg={12} className='mt-2 mb-3 px-1 py-2 border border-1 rounded rounded-2  border-tertiary-1 bg-primary-5 shadow '>
              <CategoryPath pathState={pathCategoryState} updatePathState={updatePathState} />
            </Col>
          )}
          {!inFinalCategorySelection && (
            <Col lg={12} className='mx-auto'>
              <TableCustom
                dataTable={currentCategory}
                columns={columns}
                sortingState={[{ id: 'category', desc: false }]}
                visibilityState={{ id: false }}
                specialAction={findSubCategory}
              />
            </Col>
          )}
        </Row>

        {inFinalCategorySelection && (
          <Row className='g-0 text-start'>
            <Col lg={12}>
              <Col lg={12} className='bg-secondary-4 py-2 border border-2 rounded rounded-2 '>
                <CategoryPath pathState={pathCategoryState} confirmationMode />
              </Col>
              <Col lg={6} className='my-2 '>
                <TextAndLink
                  text={CHANGE_CATEGORY_LINK_TITLE}
                  onClick={formRef.current!.variants[0].attributes.length > 0 ? handleShowModal : modifyCategorySelection}>
                  <span className='p-title-medium'>{CHANGE_CATEGORY_LINK_TEXT}</span>
                </TextAndLink>
              </Col>
            </Col>
          </Row>
        )}

        <Col lg={{ span: 7, offset: 5 }} className={`my-3  ${!inFinalCategorySelection && 'ms-auto'} `}>
          <TwoButtonsGroup
            firstButtonText={cancelButtonText}
            secondButtonText={nextButtonText}
            firstButtonClass='btn-secondary-text-standard '
            firstButtonClick={handleCancelButton}
            secondButtonClass={`btn-primary-text-standard ${((!isAValidCategory && !inFinalCategorySelection) || !isActive) && 'disabled'}`}
            secondButtonClick={handleSendForm}
          />
        </Col>
      </Col>
      <Modal
        show={isModalVisible}
        onHide={handleShowModal}
        dialogClassName='modal-dialog-centered'
        contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
        size='lg'>
        <Col xs={12} className='text-end top-0 pe-2 pt-2 d-flex justify-content-end'>
          <CloseIcon classMain='btn-icon-light' onClick={handleShowModal} />
        </Col>

        <Col xs={12} className='text-center px-4 pt-4 mt-5 w-75 mx-auto'>
          <span className='p-large-bold'>{CHANGE_CATEGORY_TEXT}</span>
          <div className='mt-5'>
            <TwoButtonsGroup
              firstButtonText={CANCEL_BUTTON_TEXT}
              firstButtonClick={handleShowModal}
              secondButtonText={CHANGE_BUTTON_TEXT}
              secondButtonClick={modifyCategorySelection}
              firstButtonClass='btn-secondary-text-standard'
              secondButtonClass='btn-primary-text-standard'
            />
          </div>
        </Col>
        <Col xs={12} className='align-self-end'>
          <div className={'bg-primary-3 pt-2 mt-4 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
    </Row>
  );
};

export default SelectCategory;
