import axios, { AxiosRequestConfig } from 'axios';
import { RoutesDirections } from '@/data/libraries/Routes';

/**
 * Guarda la configuración principal del llamado axios
 * @const {object}
 */
const config = {
  baseURL: process.env.REACT_APP_URL_API ?? '',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
} as AxiosRequestConfig;

/**Función que hace las consultas con el Token en el Back
 * En caso de que el token no sea válido, limpiará localstorage y pedira login
 */
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    //Propiedades que mantiene del carrito
    const cartData = localStorage.getItem('IlisPlacesCart');

    if (error.code === 'ERR_NETWORK') {
      //localStorage.clear();
      window.location.href = RoutesDirections.ERROR_ROUTE;
    }
    if (error.response.status === 401) {
      localStorage.clear();
      if (cartData !== null) {
        localStorage.setItem('IlisPlacesCart', cartData);
      }
      window.location.href = RoutesDirections.MAIN_ROUTE;
    }
    return Promise.reject(error);
  },
);

export default config;
