import { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';

/** Componente Anchor para cambiar de modal entre login y  nuevo usuario
 * @component
 */

type PropsTextAndLink = {
  /** Propiedad que define el texto del componente */
  text?: string;
  /** Propiedad que define la clase del texto del componente */
  classText?: string;
  /** Evento que se desencadena al  clickear el link*/
  onClick: () => void;
};
const TextAndLink = ({ text = '', classText, onClick, children }: PropsWithChildren<PropsTextAndLink>): JSX.Element => {
  return (
    <Row xs={12} className="align-items-center mt-auto py-2 d-flex">
      <Col xs={7} className={classText}>
        <span className="">{text}</span>
      </Col>
      <Col xs={5} className={classText}>
        <span className="p-title-medium">
          <div onClick={onClick} className="link-text-standard">
            {' '}
            {children}{' '}
          </div>
        </span>
      </Col>
    </Row>
  );
};

export default TextAndLink;
