import { PropsWithChildren, useEffect } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import RangeSlider from 'react-range-slider-input';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { updateCurrentRanges } from '@/ui/store/slices/priceRangeSlice';

import currencyFormat from '@/domain/utils/currencyFormat';

import './style.css';

interface PropsSlider {
  sliderRef: any;
}

/**
 * Componente que permite filtrar los precios de los productos.
 *
 * @component
 * @example
 * return (
 *   <PriceSlider min={0} max={1000} />
 * )
 */
const PriceSlider = ({ sliderRef }: PropsWithChildren<PropsSlider>) => {
  /** Hook de actualización de states Redux */
  const dispatch = useAppDispatch();

  /** Estado Redux de los valores iniciales del slide de precios */
  const { minInitialValue, maxInitialValue } = useAppSelector((state) => state.priceRangeState.initialRange);

  /** Estado Redux del ultimo valor máximo configurado en el redux */
  const { minCurrentValue, maxCurrentValue } = useAppSelector((state) => state.priceRangeState.currentRange);
  /** Restablece los valores del rango de precios a los valores predeterminados. */
  const handleSliderChange = (newValue: [number, number]): void => {
    /** Actualiza el estado Redux del rango de precios */
    dispatch(updateCurrentRanges({ minValue: newValue[0], maxValue: newValue[1] }));
  };

  /** UseEffect para actualizar los valores del los rangos de precio */
  useEffect(() => {}, [minInitialValue, maxInitialValue]);
  return minInitialValue !== maxInitialValue ? (
    <Container fluid className='my-2'>
      <Row>
        <span className='p-large-bold py-2'>Precios</span>
        <Col>
          <RangeSlider
            min={minInitialValue}
            max={maxInitialValue}
            ref={sliderRef}
            value={[minCurrentValue, maxCurrentValue]}
            onInput={handleSliderChange}
          />
        </Col>
      </Row>
      <Row className='py-2 d-flex justify-content-center'>
        <Col className='text-end'>
          <span className='p-small-bold text-complementary-1'>{`Min ${currencyFormat(
            minCurrentValue,
            ConfigNumberParameters.DECIMALS_ALLOWED,
          )}`}</span>
        </Col>
        <Col>
          <span className='p-small-bold text-complementary-1'>{`Max ${currencyFormat(
            maxCurrentValue,
            ConfigNumberParameters.DECIMALS_ALLOWED,
          )}`}</span>
        </Col>
      </Row>
    </Container>
  ) : (
    <></>
  );
};

export default PriceSlider;
