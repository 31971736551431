import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { useAppSelector } from '@/ui/store/helperRedux';

import { IUserStore } from '@/domain/interfaces/IUser';
import { ICart } from '@/domain/interfaces/ICart';

const useStorageListener = () => {
  /** Estado Redux del usuario */
  const userLogued: IUserStore = useAppSelector((state) => state.userState);
  /** Representa el tipo de usuario cliente */
  const kindClientUser: string = 'CLIENT';
  const kinAdminUser: string = 'ADMIN';

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Funcion de refrescar pagina */
  const handleRefresh = () => {
    navigate(location.pathname);
    window.location.reload();
  };

  const [cartStorageChange, setCartStorageChange] = useState<boolean>(false);
  const [userStorageChange, setUserStorageChange] = useState<boolean>(false);
  const [blockedByCheckoutChange, setBlockedByCheckoutChange] = useState<boolean>(false);

  window.addEventListener('storage', (change) => {
    if (userLogued.user.kind === kinAdminUser && localStorage.length === 0) {
      handleRefresh();
    }

    if (change.key === 'IlisPlacesCart') {
      const newValue = change.newValue;
      const oldValue = change.oldValue;

      if (newValue && oldValue) {
        //Verifica si la reserva es nula o no para indicar el cambio del localStorage
        const oldDataCart = JSON.parse(oldValue) as ICart;
        const newDataCart = JSON.parse(newValue) as ICart;

        if (newDataCart.reservationId) {
          setBlockedByCheckoutChange(true);
        } else {
          setBlockedByCheckoutChange(false);
          oldDataCart.totalProducts !== newDataCart.totalProducts && setCartStorageChange(true);
          oldDataCart.reservationId !== newDataCart.reservationId && setCartStorageChange(true);
        }
      }

      if (!change.newValue) {
        setCartStorageChange(true);
      }
    }

    if (change.key === 'ilisPlacesEmail') {
      setUserStorageChange(true);
    }
  });

  const handleSync = (confirm: boolean) => {
    confirm && handleRefresh();
    setCartStorageChange(false);
    setUserStorageChange(false);
    setBlockedByCheckoutChange(false);
  };

  return {
    cartChange: cartStorageChange,
    userChange: userStorageChange,
    blockedByCheckout: blockedByCheckoutChange,
    handleSync,
    handleRefresh,
  };
};

export default useStorageListener;
