import { BLANK_CHARACTER_IN_TEXT, PRODUCT_SKU_FORMAT, ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { IVariantForm } from '@/domain/interfaces/IProduct';

import countDecimals from '@/domain/utils/countDecimals';
import { validateURLs, validatePdfURL } from '@/domain/utils/validateURLs';
import priceFormat from '@/domain/utils/priceFormat';
import validatePattern from '@/domain/utils/validatePattern';
import currencyFormat from '@/domain/utils/currencyFormat';

interface IVariationValidation {
  row: number;
  /** Campo a evaluar */
  field:
    | 'VARIANTE'
    | 'PRECIO_BASE'
    | 'GALERÍA'
    | 'ATRIBUTOS'
    | 'SUCURSAL'
    | 'CANTIDAD'
    | 'SKU'
    | 'PRECIO_OFERTA'
    | 'IMPUESTOS'
    | 'ESTADO'
    | 'GUÍA_ESPECIFICACIONES'
    | 'TITULO_ESPECIFICACIONES_TÉCNICAS'
    | 'ESPECIFICACIONES_TÉCNICAS';
  /** Mensaje de error */
  message: string;
  /** Indice del arreglo, si el campo es un arreglo */
  indexField?: number;
}

/**
 * Caso de uso de validación de producto base
 * @param {IVariantForm} product - data de producto base
 * @returns {IVariationValidation[]} errors
 */
const validateVariantUseCase = (variant: IVariantForm): IVariationValidation[] => {
  /** Objeto de respuesta con todos los errores */
  const errors: IVariationValidation[] = [];

  /** Constantes que evalúan campos */
  const MAXIMUM_QUANTITY: number = ConfigNumberParameters.MAXIMUM_QUANTITY;
  const MAXIMUM_ANY_PRICE: number = ConfigNumberParameters.MAXIMUM_PRICE - parseInt(process.env.REACT_APP_STANDARD_SHIPPING_COST ?? '0');
  const SKU_MAX_LENGTH = ConfigNumberParameters.PRODUCT_SKU_MAX_LENGTH;
  const TECHNICAL_SPECIFICATIONS_TITLE_LENGTH = ConfigNumberParameters.VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH;
  const TECHNICAL_DESCRIPTION_SECCION_LENGTH = ConfigNumberParameters.MAXIMUM_DB_TEXT_LENGTH;

  /** Mensajes de error */
  const QUANTITY_ERROR: string = 'El valor debe ser mayor a 0';
  const QUANTITY_NEGATIVE_ERROR: string = 'El valor debe ser mayor o igual a 0';
  const INTEGER_ERROR: string = 'El valor debe ser un numero entero';
  const OFFER_PRICE_ERROR: string = 'El precio oferta debe ser menor al precio base';
  const TAX_NON_TAXABLE_ERROR: string = 'El producto no puede ser exento y tener impuestos asignados';
  const MAIN_MEDIA_ERROR: string = 'Si es una variante principal debe tener al menos 1 URL multimedia';
  const MAIN_ATTRIBUTES_ERROR: string = 'La variante debe tener al menos dos atributos';
  const URL_ERROR = 'El valor no cumple con el formato de una URL válida Ejemplo: (https://foo.com/bar)';
  const URL_PDF_ERROR = 'El valor no cumple con el formato de una URL de PDF válida Ejemplo: (https://foo.com/bar.pdf)';
  const GALLERY_URL_ERROR = `En uno o varios links de galería ${URL_ERROR}`;
  const BLANK_ERROR = 'Tienes mas de 1 espacio en blanco seguidos o espacios en blanco antes o después del texto escrito';
  const REQUIRED_ERROR = 'Campo Requerido';
  const TECHNICAL_SHEET_TITLE_EMPTY_WITH_TECHNICAL_SHEET_DATA =
    'Debe ingresar datos en la sección de especificaciones técnicas si existe un título de especificaciones técnicas';
  const MAXIMUM_QUANTITY_MESSAGE = `La cantidad maxima permitida es ${priceFormat(MAXIMUM_QUANTITY.toString())}`;
  const SKU_LENGTH_ERROR: string = `Contenga máximo ${SKU_MAX_LENGTH} caracteres`;
  const TECHNICAL_SPECIFICATIONS_TITLE_LENGTH_ERROR: string = `Contenga máximo ${TECHNICAL_SPECIFICATIONS_TITLE_LENGTH} caracteres`;
  const TECHNICAL_DESCRIPTION_SECCION_LENGTH_ERROR: string = `Contenga máximo ${TECHNICAL_DESCRIPTION_SECCION_LENGTH} caracteres`;
  const FORMAT_ERROR: string = 'El valor no cumple con el formato requerido';
  const SKU_FORMAT_MESSAGE: string = 'letras y/o números, puede tener guiones entre textos';
  const ATTRIBUTE_FORMAT: string = '{LLAVE = VALOR}';
  const DECIMAL_FORMAT_BASE_PRICE_ERROR: string = 'El precio base no puede tener decimales';
  const DECIMAL_FORMAT_OFFER_PRICE_ERROR: string = 'El precio oferta no puede tener decimales';
  const PRICE_LENGTH_FORMAT: string = `El valor ingresado en el precio no está dentro del rango de [1 - ${currencyFormat(
    MAXIMUM_ANY_PRICE,
    ConfigNumberParameters.DECIMALS_ALLOWED,
  )} ]`;
  const OFFER_PRICE_LENGTH_FORMAT: string = `El valor ingresado en el precio oferta no está dentro del rango de [1 - ${currencyFormat(
    MAXIMUM_ANY_PRICE,
    ConfigNumberParameters.DECIMALS_ALLOWED,
  )} ]`;

  /** Mensaje de formato con errores (puede incluir formato a validar) */
  const formatError = (formatExample?: string): string => {
    const format: string = formatExample ?? '';

    return `${FORMAT_ERROR} ${format}`;
  };

  const DUPLICATE_ERROR: string = 'Existen valores repetidos en esta sección';
  const REFERENCE_INDICATOR: string = 'Referencia';
  /** Mensaje de error con valores duplicados (puede incluir referencia) */
  const duplicateError = (ref?: string): string => {
    const reference: string = ref ? `${REFERENCE_INDICATOR} = (${ref})` : '';
    return `${DUPLICATE_ERROR} ${reference}`;
  };

  const DECIMAL_PRECISION_1: string = 'El valor del atributo';
  const DECIMAL_PRECISION_2: string = 'debe ser de máximo';
  const DECIMAL_PRECISION_3: string = 'decimales';

  /** Recibe la llave del atributo y la precisión decimal para retornar el error */
  const attributesDecimalPrecisionError = (key: string, decimalPrecision: number): string =>
    `${DECIMAL_PRECISION_1} ${key} ${DECIMAL_PRECISION_2} ${decimalPrecision} ${DECIMAL_PRECISION_3}`;

  /** Validaciones */

  /** SKU */
  if (variant.sku && variant.sku !== '' && !validatePattern(BLANK_CHARACTER_IN_TEXT, variant.sku)) {
    errors.push({ row: variant.row ?? 0, field: 'SKU', message: BLANK_ERROR });
  }
  if (variant.sku && variant.sku !== '' && !validatePattern(PRODUCT_SKU_FORMAT, variant.sku)) {
    errors.push({ row: variant.row ?? 0, field: 'SKU', message: formatError(SKU_FORMAT_MESSAGE) });
  }
  if (variant.sku && variant.sku !== '' && variant.sku.length > SKU_MAX_LENGTH) {
    errors.push({ row: variant.row ?? 0, field: 'SKU', message: SKU_LENGTH_ERROR });
  }

  /** Sucursal */
  if (!variant.branchOfficeId || variant.branchOfficeId === 0) {
    errors.push({ row: variant.row ?? 0, field: 'SUCURSAL', message: REQUIRED_ERROR });
  }

  /** Cantidad */

  if (variant.quantity && variant.quantity < 0) {
    errors.push({ row: variant.row ?? 0, field: 'CANTIDAD', message: QUANTITY_NEGATIVE_ERROR });
  }

  if (!Number.isInteger(variant.quantity)) {
    errors.push({ row: variant.row ?? 0, field: 'CANTIDAD', message: INTEGER_ERROR });
  }

  if (variant.quantity && variant.quantity > MAXIMUM_QUANTITY) {
    errors.push({ row: variant.row ?? 0, field: 'CANTIDAD', message: MAXIMUM_QUANTITY_MESSAGE });
  }

  /** Precio  */
  if (!variant.price || variant.price === null) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_BASE', message: QUANTITY_ERROR });
  } else if (variant.price < 1 || variant.price > MAXIMUM_ANY_PRICE) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_BASE', message: PRICE_LENGTH_FORMAT });
  }
  // validar que el precio no tenga decimales
  if (variant.price && countDecimals(variant.price) > ConfigNumberParameters.DECIMALS_ALLOWED) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_BASE', message: DECIMAL_FORMAT_BASE_PRICE_ERROR });
  }

  /** Precio oferta */
  if (variant.offerPrice && variant.offerPrice !== null) {
    if (variant.offerPrice < 1 || variant.offerPrice > MAXIMUM_ANY_PRICE) {
      errors.push({ row: variant.row ?? 0, field: 'PRECIO_OFERTA', message: OFFER_PRICE_LENGTH_FORMAT });
    }
  }
  //validar que el precio oferta no tenga decimales
  if (variant.offerPrice && countDecimals(variant.offerPrice) > ConfigNumberParameters.DECIMALS_ALLOWED) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_OFERTA', message: DECIMAL_FORMAT_OFFER_PRICE_ERROR });
  }

  if (variant.price && variant.offerPrice && variant.offerPrice >= variant.price) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_OFERTA', message: OFFER_PRICE_ERROR });
  }

  if (variant.offerPrice !== undefined && variant.offerPrice !== null && isNaN(variant.offerPrice)) {
    errors.push({ row: variant.row ?? 0, field: 'PRECIO_OFERTA', message: INTEGER_ERROR });
  }

  /** Impuestos */
  if (!variant.non_taxable && variant.taxes.length < 1) {
    errors.push({ row: variant.row ?? 0, field: 'IMPUESTOS', message: REQUIRED_ERROR });
  }

  if (variant.non_taxable && variant.taxes.length > 0) {
    errors.push({ row: variant.row ?? 0, field: 'IMPUESTOS', message: TAX_NON_TAXABLE_ERROR });
  }

  const setTaxes = new Set(variant.taxes);
  if (variant.taxes && setTaxes.size !== variant.taxes.length) {
    errors.push({ row: variant.row ?? 0, field: 'IMPUESTOS', message: duplicateError() });
  }

  /** Estado */
  if (variant.status === null || variant.status <= 0) {
    errors.push({ row: variant.row ?? 0, field: 'ESTADO', message: REQUIRED_ERROR });
  }

  /** Guía de especificaciones */
  if (variant.urlGuide && !validatePdfURL(variant.urlGuide)) {
    errors.push({ row: variant.row ?? 0, field: 'GUÍA_ESPECIFICACIONES', message: URL_PDF_ERROR });
  }

  /** Galería Media */
  if (variant.main && variant.media?.length === 0) {
    errors.push({ row: variant.row ?? 0, field: 'GALERÍA', message: MAIN_MEDIA_ERROR });
  }
  if (variant.media.length > 0) {
    variant.media.forEach((val, index) => {
      if (!validateURLs(val)) {
        errors.push({ row: variant.row ?? 0, field: 'GALERÍA', message: GALLERY_URL_ERROR, indexField: index });
      }
    });
  }

  /** Atributos */

  if (variant.attributes?.length < 2) {
    errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: MAIN_ATTRIBUTES_ERROR });
  }

  const setKeys = new Set();

  variant.attributes.forEach((val, index) => {
    if (val.key === '' && (val.value === '' || !val.value)) {
      errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: formatError(ATTRIBUTE_FORMAT), indexField: index });
    }
    if (val.key !== '' && val.value === undefined) {
      errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: formatError(ATTRIBUTE_FORMAT), indexField: index });
    }
    if (val.key !== '' && val.value === '') {
      errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: TAX_NON_TAXABLE_ERROR, indexField: index });
    }
    if (val.decimalPrecision && countDecimals(val.value) > val.decimalPrecision) {
      errors.push({
        row: variant.row ?? 0,
        field: 'ATRIBUTOS',
        message: attributesDecimalPrecisionError(val.key, val.decimalPrecision),
        indexField: index,
      });
    }
    if (setKeys.has(val.key)) {
      errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: duplicateError(val.key), indexField: index });
    } else {
      setKeys.add(val.key);
    }
  });

  /** Titulo de Especificaciones Técnicas*/
  if (variant.technicalSheetTitle && variant.technicalSheetTitle.length > TECHNICAL_SPECIFICATIONS_TITLE_LENGTH) {
    errors.push({ row: variant.row ?? 0, field: 'TITULO_ESPECIFICACIONES_TÉCNICAS', message: TECHNICAL_SPECIFICATIONS_TITLE_LENGTH_ERROR });
  }

  //si existe titulo pero no hay datos en la sección de especificaciones técnicas
  if (variant.technicalSheetTitle && variant.technicalSheetTitle.length > 0 && (!variant.technicalSheet || variant.technicalSheet.length === 0)) {
    errors.push({ row: variant.row ?? 0, field: 'TITULO_ESPECIFICACIONES_TÉCNICAS', message: TECHNICAL_SHEET_TITLE_EMPTY_WITH_TECHNICAL_SHEET_DATA });
  }

  /** Especificaciones */
  if (variant.technicalSheet && variant.technicalSheet.length > 0) {
    //validar que no haya campos vacios o que no cumplan con el formato
    variant.technicalSheet.forEach((val, index) => {
      if (!val.key || !val.value) {
        errors.push({ row: variant.row ?? 0, field: 'ESPECIFICACIONES_TÉCNICAS', message: formatError(ATTRIBUTE_FORMAT), indexField: index });
      }

      // val.key debe tener una largo maximo de 30 caracteres si no error
      if (val.key && val.key.length > TECHNICAL_SPECIFICATIONS_TITLE_LENGTH) {
        errors.push({
          row: variant.row ?? 0,
          field: 'ESPECIFICACIONES_TÉCNICAS',
          message: `Título ${TECHNICAL_SPECIFICATIONS_TITLE_LENGTH_ERROR}`,
          indexField: index,
        });
      }
      // val.value debe tener una largo maximo de 255 caracteres si no error
      if (val.value && val.value.length > TECHNICAL_DESCRIPTION_SECCION_LENGTH) {
        errors.push({
          row: variant.row ?? 0,
          field: 'ESPECIFICACIONES_TÉCNICAS',
          message: `Características ${TECHNICAL_DESCRIPTION_SECCION_LENGTH_ERROR}`,
          indexField: index,
        });
      }
    });
  }

  return errors;
};

export default validateVariantUseCase;

export type { IVariationValidation };
