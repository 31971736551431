import { MockShippingTypes } from '@/data/mocks/MockShippingTypes';

import { IShippingTypes } from '@/domain/interfaces/IProduct';

/** Representa la interacción con el Back */
export default class ShippingTypesRepository {
  /** Instancia de la clase   */
  private static _instance: ShippingTypesRepository;

  /** Patron Singleton
    * @returns {instance} _instance ShippingTypesRepository
  */
  static get instance(): ShippingTypesRepository {
    if (!this._instance) {
      this._instance = new ShippingTypesRepository();
    }
    return this._instance;
  }

  /** Solicita los tipos de identificación
   * @param {string} token - token valido
   * @returns {object} data - tipos de identificación en el back
  */
  get getShippingTypes(): IShippingTypes[] {
    return MockShippingTypes;
  }

}