import { PropsWithChildren, ReactNode } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';

/** Propiedades para el componente TwoButtonsGroup.*/
type PropsButtonGroup = {
  /** Clase para el botón de lado izquierdo. */
  firstButtonClass?: string;
  /** Nombre del Titulo del primer botón  */
  firstButtonIconTitle?: string;
  /** Clase del icono del primer botón  */
  firstButtonIconTitleClass?: string;
  /** Nombre del icono del primer botón  */
  firstButtonIcon?: string;
  /** Clase del icono del primer botón  */
  firstButtonIconClass?: string;
  /** Información para el botón de lado izquierdo. */
  firstButtonText: string;
  /** Evento al darle clic al botón de lado izquierdo.  */
  firstButtonClick?: (params: any) => ReactNode | void | Promise<void>;
  /** Clase para el botón de lado derecho. */
  secondButtonClass?: string;
  /** Nombre del icono del segundo botón  */
  secondButtonIcon?: string;
  /** Clase del icono del segundo botón  */
  secondButtonIconClass?: string;
  /** Información para el botón de lado derecho. */
  secondButtonText: string;
  /** Evento al darle clic al botón de lado derecho. */
  secondButtonClick?: (params: any) => ReactNode | void | Promise<void>;
};

/**
 * Componente de grupo de dos botones.
 * @component
 */
const TwoButtonsGroup = ({
  firstButtonClass = '',
  firstButtonIcon = '',
  firstButtonIconClass = '',
  firstButtonText = '',
  secondButtonClass = '',
  secondButtonIcon = '',
  secondButtonIconClass = '',
  secondButtonText = '',
  firstButtonClick,
  secondButtonClick,
}: PropsWithChildren<PropsButtonGroup>): JSX.Element => {
  return (
    <Container fluid className='pt-4 pb-1 px-0'>
      <Row className='g-0'>
        <Col xs={6} className='d-grid gap-2 pe-2'>
          <Button className={firstButtonClass} onClick={firstButtonClick}>
            <IconList
              iconName={firstButtonIcon}
              classIcon={firstButtonIconClass}
              classTitle='p-regular-medium text-uppercase'
              title={firstButtonText}
            />
          </Button>
        </Col>
        <Col xs={6} className='d-grid gap-2 ps-2'>
          <Button
            className={secondButtonClass}
            type={secondButtonClick === undefined ? 'submit' : 'button'}
            onClick={secondButtonClick}
          >
            <IconList
              iconName={secondButtonIcon}
              classIcon={secondButtonIconClass}
              classTitle='p-regular-medium text-uppercase'
              title={secondButtonText}
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TwoButtonsGroup;
