import { PropsWithChildren } from 'react';
import { Col, Modal } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';

interface PropsModalError {
  /** Propiedad que muestra el modal */
  showModal?: boolean;
  /** Evento que oculta y muestra el modal */
  handleShowModal: () => void;
  /** Función que ejecuta el modal */
  buttons?: JSX.Element | null;
}

/**
 * Modal que muestra Gift para el procesamiento, error o éxito de la carga de documento
 * @component
 */
const ModalError = ({ showModal, handleShowModal, buttons, children }: PropsWithChildren<PropsModalError>): JSX.Element => {
  return (
    <Modal
      show={showModal}
      onHide={buttons ? undefined : handleShowModal}
      dialogClassName='modal-dialog-centered '
      contentClassName='rounded-4 d-flex flex-column justify-content-between shadow border-0'
      size='lg'>
      {buttons && (
        <Col xs={12} className='text-end position-absolute top-0 pe-2 pt-2 d-flex justify-content-end'>
          <CloseIcon classMain='btn-icon-light mt-3 mx-2' onClick={handleShowModal} />
        </Col>
      )}
      {children}
      {buttons && (
        <Col xs={12} className='mx-auto mt-3 mt-auto'>
          {buttons}
        </Col>
      )}
      <Col xs={12} className='align-self-end pt-2  '>
        <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
      </Col>
    </Modal>
  );
};

export default ModalError;
