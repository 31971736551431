import { PropsWithChildren, ReactNode } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';

type PropsInputList = {
  /**
   * Información para el titulo
   */
  id?: string;
  /**
   * Información para el titulo
   */
  title?: string;
  /**
   * Información para el subtitulo o texto
   */
  text?: string | ReactNode;
  /**
   * Información para mostrar el icono
   */
  iconName?: string;
  /**
   *  Evento al darle click al componente InputList
   */
  onClick?: (params: any) => ReactNode | void;

  className?: string;
};

/** Componente SVG de salida de lista de inputs
 * @component
 */
const InputList: React.FC<PropsInputList> = ({
  id = '',
  title = '',
  text = '',
  iconName = '',
  onClick,
  className,
}: PropsWithChildren<PropsInputList>) => {
  return (
    <Container fluid id='input-list' className='p-0'>
      <Row className='g-0'>
        <Col xs={12} className='border-bottom pt-3 pb-2 cursor-hand'>
          <li id={id} onClick={onClick}>
            <Container fluid id='input-list'>
              <Row className='px-2'>
                <Col xs={3} md={2} className='text-secondary-3'>
                  <span className='p-regular-medium'>{title}</span>
                </Col>
                <Col xs={5} md={6}>
                  <small className={`p-regular text-nowrap ${iconName === '' ? 'text-tertiary-1 fw-light' : ''} ${className}`}>{text}</small>
                </Col>
                {text === null || text === '' ? (
                  <Col xs={4} className='text-end text-primary-1'>
                    <Container fluid className='p-0 d-flex flex-row-reverse'>
                      <Row className='g-0 align-items-center'>
                        <Col xs={8}>
                          <span className='p-regular-medium'>Agregar&nbsp;&nbsp;</span>
                        </Col>
                        <Col xs={4} className='text-end'>
                          <span className='p-regular-medium'>+</span>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                ) : (
                  <Col xs={4} className='text-end text-tertiary-5'>
                    <Icon name={iconName} />
                  </Col>
                )}
              </Row>
            </Container>
          </li>
        </Col>
      </Row>
    </Container>
  );
};

export default InputList;
