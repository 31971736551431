import { Col, Container, Image, Modal, Row } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';
/**
 * @Return Componente que muestra la página de procesando pago
 */
const ProcessingPayment = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Logo en blanco del proyecto */

  /**Constantes de texto */
  const LOGO: string = `${URL_IMAGES}/client/logo-full-modal.png`;
  const PROCESSING_PAYMENT_IMAGE: string = `${URL_IMAGES}/client/processing-payment.png`;
  const PROCESSING_PAYMENT_TITLE: string = 'Procesando...';
  const PROCESSING_PAYMENT_DESCRIPTION: string = 'Por favor, espera un momento mientras confirmamos la operación con la plataforma.';

  /**Renderizado del componente */
  return (
    <Modal show={true} fullscreen={true} animation={false}>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row id='full-modal-bg-ilis' className='min-vh-100 d-flex align-items-end flex-column p-0 '>
            <Col xs={12} className='bg-ilis-modal min-vh-40'>
              <Col xs={12} className='align-self-center d-flex justify-content-around pb-5 '>
                <div className='text-center w-100 py-3'>
                  <img src={LOGO} id='modal-mobile-logo' alt='logo' className='d-sm-none logo-full-modal'></img>
                </div>
                <div>
                  <img
                    src={LOGO}
                    id='modal-desktop-logo'
                    alt='logo'
                    className='login-desktop-logo d-none d-sm-block position-absolute top-10 start-10 logo-full-modal'></img>
                </div>
              </Col>
            </Col>
            <Col xs={12} sm={6} lg={4} className='px-0  bg-primary-4 position-absolute top-50 start-50 translate-middle rounded-5 shadow mh-600-px'>
              <Col lg={12} className='  d-flex align-items-end mh-500-px pb-5'>
                <Col lg={12} className='d-flex justify-content-center flex-column'>
                  <Col className='d-flex justify-content-center'>
                    <Image
                      src={PROCESSING_PAYMENT_IMAGE}
                      width={338.61}
                      height={200}
                      alt='gif'
                      onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                        e.currentTarget.src = ErrorImage;
                      }}
                    />
                  </Col>
                  <Col className='pb-4 text-center text-primary-3 pt-4'>
                    <span className='h2 fw-bold'>{PROCESSING_PAYMENT_TITLE}</span>
                  </Col>
                  <Col className='d-flex  justify-content-justify px-2 px-lg-5'>
                    <span className=' text-secondary-2 h5 text-center'>{PROCESSING_PAYMENT_DESCRIPTION}</span>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ProcessingPayment;
