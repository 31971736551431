import { createSlice } from '@reduxjs/toolkit';

const initialState = {value:false}

/** Almacena el estado managementState de Redux con sus reducers
  * @constant {object}
*/
export const managementSlice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    change: (state, action) => {
      state.value= action.payload.value;
    },
  }
})

export const { change} = managementSlice.actions;

export default managementSlice.reducer;
