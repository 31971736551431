import { useEffect, useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';

import ProductsController from '@/controllers/ProductsController';
import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';

import priceFormat from '@/domain/utils/priceFormat';
import quantityFormat from '@/domain/utils/quantityFormat';

import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IProductForm } from '@/domain/interfaces/IProduct';
import { IPathCategoryType } from '@/domain/interfaces/ICategories';

import Icon from '@/ui/assets/Icon';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import IconList from '@/ui/components/IconList';
import SwitchCheckbox from '@/ui/components/forms/SwitchCheckbox';
import InputForm from '@/ui/components/forms/InputForm';
import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import MainTitle from '@/ui/components/MainTitle';

/** Propiedades del componente */
interface PropsBranchOfficeInventory {
  /**Títulos pasados por props de AddProducts */
  // titulo tab
  mainTitle: string;
  // Texto del titulo de la variante del producto
  variantProductTitle: string;
  // Texto del titulo de la clasificación
  classificationLabelText: string;
  // Texto del botón de cancelar
  cancelButtonText: string;
  // Texto del botón guardar y continuar
  nextButtonText: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Propiedad que define el path de la categoría */
  pathCategoryState: IPathCategoryType[];
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

/**
 * Componente para configurar las sucursales de la variante principal
 * @component
 */
const BranchOfficeInventory = ({
  mainTitle,
  variantProductTitle,
  classificationLabelText,
  cancelButtonText,
  nextButtonText,
  formRef,
  pathCategoryState,
  updateFormRef,
  handleCancelButton,
  handleNextButton,
}: PropsBranchOfficeInventory): JSX.Element => {
  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /** Referencia al switch transferible */
  const transferableRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado del valor del switch 'Transferible' */
  const [switchValue, setSwitchValue] = useState<boolean>(false);

  /** Función que se ejecuta al cambiar el switch de Transferible */
  const changeStatus = (): void => {
    setSwitchValue(!switchValue);
  };

  /** Referencia al input cantidad */
  const quantityRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado del componente cantidad */
  const [quantity, setQuantity] = useState<string>(quantityRef.current?.value ?? '');

  /** Onchange Input cantidad */
  const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
    quantityRef.current!.value = quantityRef.current ? priceFormat(e.target.value) : '';
    quantityRef.current && setQuantity(quantityRef.current?.value);
    handleValidate();
  };

  /** Estado para habilitar el botón de siguiente paso */
  const [isAValidData, setIsAValidData] = useState<boolean>(false);

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Declaración inicial input cantidad en su estado normal    */
  const [quantityInputWithError, setQuantityInputWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de cantidad  */
  const [quantityMessageError, setQuantityMessageError] = useState<string>('');

  /** Evento que limpia el formulario de cantidad */
  const handleReset = () => {
    quantityRef.current!.value = '';
    setIsAValidData(false);
    setQuantity('');
  };

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    /** Establece el formulario a actualizar */
    const formProduct: IProductForm = formRef.current ? JSON.parse(JSON.stringify(formRef.current)) : { ...productBaseFormClean };

    const quantityValue: string = quantityFormat(quantityRef.current?.value ?? '');

    /** Construye la data de la variante */
    formProduct.variants[0].transferable = switchValue;
    formProduct.variants[0].quantity = Number(quantityValue);

    /** Valida desde el controlador */
    await addProductBase(formProduct, false, 'branchOffice', userLogued.user?.token ?? '')
      .then((response: IProductValidation) => {
        /** Si hay error se muestra */

        // Error de Cantidad
        if (response.error && response.kindError?.quantityError && response.kindError?.quantityError !== '') {
          setQuantityInputWithError(true);
          setQuantityMessageError(response.kindError.quantityError);
        } else {
          setQuantityInputWithError(false);
          setQuantityMessageError('');
        }

        /** Si se solicitó el objeto del formulario se actualiza */
        if (!response.error) {
          setIsAValidData(true);
          send && updateFormRef(formProduct);
        } else {
          setIsAValidData(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /** Evento que envía el formulario cuando se presiona el botón */
  const handleSendInfo = async (): Promise<void> => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /** Valida cada vez que se cambia la cantidad  */
  useEffect(() => {
    handleValidate();
  }, [quantity]);

  /** UseEffect para renderizar los valores si ya los tiene */
  useEffect((): void => {
    if (formRef.current && formRef.current?.variants.length > 0) {
      formRef.current?.variants[0].quantity && (quantityRef.current!.value = quantityFormat(String(formRef.current?.variants[0].quantity)) ?? '');

      setQuantity(formRef.current.variants[0].quantity?.toString() ?? '0');
      setSwitchValue(formRef.current.variants[0].transferable);

      handleValidate();
    }

    window.scrollTo(0, 0);
  }, []);

  /**CONSTANTES DE TEXTO */

  const REQUIRED_TEXT: string = '(Obligatorio)';

  const BRANCH_OFFICE_INVENTORY_DESCRIPTION: string =
    'Agrega las sucursales en donde hay existencia de esta variante de producto, defina si el artículo es transferible entre sucursales o no es transferible, y registra la cantidad de artículos existentes de esa variante en cada sucursal agregada.';

  const TRANSFERABLE_LABEL: string = 'Transferible entre sucursales';
  const TRANSFERABLE_DESCRIPTION: string =
    'Activa esta opción en caso  de que esta variante de producto pueda ser transferida entre varias sucursales';

  const BRANCH_OFFICE_LABEL: string = 'Sucursal';

  const QUANTITY_LABEL: string = `Cantidad ${REQUIRED_TEXT}`;

  const SKU_LABEL: string = 'SKU:';
  const SKU_NO_DATA: string = 'S/I';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <Row className='d-flex g-0'>
          <MainTitle boldTitle={mainTitle} detailsText={BRANCH_OFFICE_INVENTORY_DESCRIPTION} />
          <Col lg={12} className='mb-3'>
            <span className='p-title-medium  me-2'>{variantProductTitle}</span>
            <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
          </Col>
          <Col lg={12} className='rounded rounded-3 bg-secondary-5 py-2 mb-4 px-3 d-flex align-content-center'>
            <Col lg={3}>
              <div className='rounded rounded-3 bg-secondary-5 py-2 px-3  d-flex  '>
                <span className='text-primary-4 p-large-medium py-1 px-2 me-4 rounded-5  bg-primary-3 text-break px-3 '>
                  <span className='text-primary-4 p-large-medium px-1 rounded-5  bg-primary-3 text-break'>{SKU_LABEL}</span>
                  {`${formRef.current!.variants[0].sku ? formRef.current?.variants[0].sku : SKU_NO_DATA}`}
                </span>
              </div>
            </Col>
            <Col lg={9} className='d-flex'>
              <div className='flex-grow-1 d-flex align-items-center text-truncate'>
                <span className='text-tertiary-3 p-large-medium'>
                  {`${formRef.current?.variants[0].attributes.map((attribute) => `${attribute.key}: ${attribute.value}`)}
                                    `}
                </span>
              </div>
            </Col>
          </Col>
          <span className='p-title-medium'>{classificationLabelText}</span>
          <Col lg={12} className='bg-secondary-4 border border-1 p-2 mb-4 rounded rounded-2'>
            <CategoryPath pathState={pathCategoryState} referenced />
          </Col>
          <Col lg={12} className='d-flex justify-content-between mb-1'>
            <Col lg={8} className='my-auto text-secondary-3'>
              <div>
                <span className='p-regular-bold'>{TRANSFERABLE_LABEL}</span>
              </div>
              <div>
                <span>{TRANSFERABLE_DESCRIPTION} </span>
              </div>
            </Col>
            <Col lg={4}>
              <SwitchCheckbox
                inputRef={transferableRef}
                checked={switchValue}
                classMain='py-2 px-0'
                type='switch'
                disabled
                showValue={true}
                classSpan={`p-regular-bold ${switchValue ? 'text-complementary-1' : 'text-complementary-2'}`}
                onChange={changeStatus}
              />
            </Col>
          </Col>
          <Col xs={12} className={`border border-1 rounded rounded-3 my-3 shadow rounded-bottom`}>
            <Col xs={11} className='py-3 mx-auto position-relative '>
              <InputForm label={BRANCH_OFFICE_LABEL} classLabel='p-regular-medium' value={userLogued.user?.branchOffice?.name ?? ''} disabled />
            </Col>
            <Col xs={11} className='py-2 mx-auto position-relative'>
              <InputForm
                inputRef={quantityRef}
                label={QUANTITY_LABEL}
                classLabel='p-regular-medium'
                type='numberInputText'
                withErrors={quantityInputWithError}
                messageError={quantityMessageError}
                onChange={handleQuantity}
              />
            </Col>
            <Col xs={12} className='d-flex justify-content-end'>
              <Icon name='ico-trash' classMain='me-2 mb-2 cursor-hand' onClick={handleReset} />
            </Col>
          </Col>
        </Row>
        <Row className='d-flex g-0'>
          <Col lg={12} className='d-flex justify-content-end align-items-center text-primary-4 pe-3 bg-secondary-3 rounded rounded-2 py-2'>
            <IconList classTitle='p-large-bold' classIcon='svg-primary-4' iconName='ico-inventory' title='Cantidad registrada: ' />
            <span className='ps-3 p-large-bold'>{quantity}</span>
          </Col>
          <Col lg={6} className='d-flex flex-wrap align-content-center'>
            <IconList iconName='ico-more' classMain={'link-icon-standard disabled '} classTitle='p-title-medium ' title={'Agregar sucursal'} />
          </Col>

          <Col lg={6}>
            <TwoButtonsGroup
              firstButtonText={cancelButtonText}
              secondButtonText={nextButtonText}
              firstButtonClass='btn-secondary-text-standard'
              firstButtonClick={handleCancelButton}
              secondButtonClass={`btn-primary-text-standard ${
                ((quantityRef.current && quantityRef.current?.value.length < 1) || !isAValidData || !isActive) && 'disabled'
              }`}
              secondButtonClick={handleSendInfo}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BranchOfficeInventory;
