import { PropsWithChildren } from 'react';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Row, Col, Image } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

import OptionsEmptyList from '@/ui/components/store/OptionsEmptyList';

interface EmptyListProps {
  textSearched: string;
}
/**
 * Componente lista vacia cuando el resultado de la busqueda no coincide
 * @component
 */
const EmptyList = ({ textSearched }: PropsWithChildren<EmptyListProps>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const EMPTY_LIST_IMG: string = `${URL_IMAGES}/client/empty-list-img.png`;

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido   */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /**  Texto generico del mensaje del componente  */
  const DEFAULT_TEXT: string = 'Lo sentimos, no hay ningún resultado para';

  return (
    <Container fluid className='p-0 bg-primary-5'>
      {isMobile ? (
        <Row className='bg-secondary-5 g-0'>
          <Col xs={{ span: 8, offset: 2 }} className='pb-3'>
            <Image
              src={EMPTY_LIST_IMG}
              alt='lista-vacia'
              className='w-100'
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </Col>
          <Col xs={12} className='text-center'>
            <p className={`mb-1 text-primary-3 ${isMobile ? 'p-large-bold' : 'h5'}`}>{DEFAULT_TEXT}</p>
            <p className={`${isMobile ? 'p-large-bold' : 'h4'}`}>{`'${textSearched}'`}</p>
          </Col>
          <Col xs={12} className='px-3'>
            <OptionsEmptyList />
          </Col>
        </Row>
      ) : (
        <Row className='bg-secondary-5 g-0 p-5'>
          <Col xs={{ span: 6 }} className='mx-auto'>
            <h4 className='mb-1 text-primary-3'>{DEFAULT_TEXT}</h4>
            <h4 className='fw-bold text-secondary-3'>{`'${textSearched}'`}</h4>
            <OptionsEmptyList />
          </Col>
          <Col xs={{ span: 3, offset: 1 }} className='mx-auto'>
            <Image
              src={EMPTY_LIST_IMG}
              alt='lista-vacia'
              className='w-100'
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EmptyList;
