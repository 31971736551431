import ClientRepository from '@/data/repositories/ClientRepository';

import { IUserResponse } from '@/domain/interfaces/ILoginResponse';
import dateFormat from '@/domain/utils/dateFormat';

/** Caso de uso traer info de usuario 
  * @param {string} email 
  * @param {string} token
  * @returns {IUserResponse} data de usuario 
*/
const getInfoUserCase = async (email: string, token: string): Promise<IUserResponse> => {

  /** Funcion para formatear una fecha a string solicitado por el back */
  const { formatDateToString } = dateFormat();

  /** Instancia del Repository
    * @const {ClientRepository}
  */
  const clientRepository = ClientRepository.instance;

  const messageUserError = 'Usuario no existe';

  let response: IUserResponse = {
    user: null,
    isError: true,
    inputError: null,
    message: []
  }

  await clientRepository.getUserByEmail(email, token)
    .then((result) => {
      if (result.status === 204) {
        response.message.push(messageUserError);
      } else {

        response.user = result.data;

        /** Si llega algun dato de fecha se formatea para mostrar */
        if (response.user!.birthday !== null) {

          // Crear un objeto Date con la fecha y hora
          const fechaStr = new Date(response.user!.birthday);
          const userBirthday: string = formatDateToString(fechaStr);
          response.user!.birthday = userBirthday

        } else {
          /** Deja vacio si no hay data de fecha */
          response.user!.birthday = '';
        }

        response.isError = false;
      }
    })
    .catch((error) => {
      response.message = error.name;
      return error;
    })

  return response;
}

export { getInfoUserCase };