import { useEffect, useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { validateAttributesValuesUseCase } from '@/domain/useCases/product/addProductBaseUseCase';

import { IAttributesForm, IAttributeValues, IAttributes } from '@/domain/interfaces/IAttributes';

import generateNumberId from '@/domain/utils/generateNumberId';

import Icon from '@/ui/assets/Icon';

import InputForm from '@/ui/components/forms/InputForm';

/** Propiedades del componente */
interface PropsAttributesSelection {
  /** index del componente */
  index: number;
  /** Atributos en el formulario */
  attributesForm: IAttributesForm[];
  /** Label del componente */
  label: string;
  /** Arreglo de atributos */
  attributes: IAttributes[] | null;
  /** Valores de atributos seleccionados */
  selectedAttributes: React.RefObject<number[]>;
  /** Manejador eliminar componente de atributos */
  handleDelete: (index: number) => void;
  /** Evento para cambiar el valor del objeto attributo que se envia al form*/
  onChangeForm: (attribute: IAttributesForm, index: number) => void;
}

/**
 * Componente con un select de atributos combinado
 * @component
 */
const AttributesSelection = ({
  index,
  attributesForm,
  label,
  attributes,
  selectedAttributes,
  handleDelete,
  onChangeForm,
}: PropsAttributesSelection): JSX.Element => {
  const initialAttribute = useRef<IAttributesForm>({ ...attributesForm[index] });

  /** INICIO INPUT LLAVE DE ATRIBUTO */

  /** Referencia al input attribute - key */
  const [keyAttributeValue, setKeyAttributeValue] = useState<string>(attributesForm[index].key);

  /** Estado muestra el componente que selecciona las llaves en su estado Default */
  const [selectedAttributeKeyState, setSelectedAttributeKeyState] = useState<boolean>(false);

  /** Estado muestra el componente que selecciona las llaves en su estado Default */
  const [selectedValue, setSelectedValue] = useState<boolean>(false);

  /** Estado de los atributos que se filtran al escribir en el input de la llave */
  const [filteredAttributeKey, setFilteredAttributeKey] = useState<IAttributes[] | null>(null);

  /** State - objeto tipo atributo - al seleccionar el atributo deseado */
  const [selectedAttributeKey, setSelectedAttributeKey] = useState<IAttributes | null>(null);

  /** Función que busca las llaves según el nombre elegido */
  const findKeyByName = (name: string): IAttributes | null => {
    const findedObject = attributes && attributes.find((item) => item.name === name);

    if (findedObject) {
      // Crear un nuevo objeto con la información encontrada
      const newObject: IAttributes = {
        id: findedObject.id,
        name: findedObject.name,
        position: findedObject.position,
        isQualitative: findedObject.isQualitative,
        main: findedObject.main,
        values: findedObject.values,
      };
      return newObject;
    } else {
      return null; // Retornar null si no se encuentra el elemento
    }
  };

  /** Función que se ejecuta al escribir en el input de la llave */
  const handleChangeKey = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setKeyAttributeValue(e.target.value);
    setSelectedAttributeKey(findKeyByName(e.target.value));
    attributes && e.target.value !== ''
      ? setFilteredAttributeKey(attributes.filter((attribute) => new RegExp(e.target.value, 'i').test(attribute.name)))
      : setFilteredAttributeKey(attributes);
  };

  /** Función que se ejecuta al seleccionar la llave del atributo */
  const handleSelectKey = (value: string) => {
    setKeyAttributeValue(value ?? '');
    handleResetInputValue();
    setSelectedValue(true);
    setSelectedAttributeKeyState(false);
    setSelectedAttributeKey(findKeyByName(value));
    setSelectedAttributeValue('');
    attributesIdRef.current && (attributesIdRef.current.value = '');
    attributesValueRef.current && attributesValueRef.current.focus();
  };

  const attributesKeyRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /**Función de salida del input attribute - key */
  const handleBlurKey = (): void => {
    setSelectedAttributeKeyState(false);
    if (filteredAttributeKey && filteredAttributeKey.length < 1) {
      setKeyAttributeValue('');
      setFilteredAttributeKey(attributes);
    }
    attributesKeyRef.current && attributesKeyRef.current.blur();
  };

  /**Función de entrada del input attribute - key */
  const handleFocusKey = (): void => {
    setSelectedAttributeKeyState(true);
  };

  /**Función que resetea los valores del atributo - key */
  const handleReset = () => {
    if (selectedAttributes.current && selectedAttributes.current.length < 3) {
      setKeyAttributeValue('');
      setSelectedAttributeValue('');
      setSelectedAttributeKey(null);
      setSelectedValue(false);
    }
    handleDelete(index);
  };

  /** FIN INPUT LLAVE DE ATRIBUTO */

  /** INICIO INPUT VALOR DE ATRIBUTO */

  /** Referencia al input attribute - value */
  const attributesValueRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /**Estado que toma el valor de atributo seleccionado */
  const [selectedAttributeValue, setSelectedAttributeValue] = useState<string>(attributesForm[index].value);

  /** Estado muestra el componente que selecciona los valores en su estado Default */
  const [selectedAttributeValueState, setSelectedAttributeValueState] = useState<boolean>(false);

  /** Estado de los atributos que se filtran al escribir en el input del valor */
  const [filteredAttributeValue, setFilteredAttributeValue] = useState<IAttributeValues[] | null>(null);

  /** Función que se ejecuta al seleccionar el valor del atributo */
  const handleSelectValue = (value: string, id: string) => {
    setSelectedAttributeValue(value ?? '');
    attributesIdRef.current!.value = id ?? '';
    attributesValueRef.current!.blur();
    setSelectedAttributeValueState(false);
    initialAttribute.current.value = value ?? '';
    initialAttribute.current.key = keyAttributeValue;
    initialAttribute.current.type = 'qualitative';
    initialAttribute.current.main = index < 2 && true;
    handleValidateValue();
    onChangeForm(initialAttribute.current, index);
  };

  /** Función que se ejecuta al escribir en el input del valor*/
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedAttributeValue(e.target.value ?? '');
    if (selectedAttributeKey && selectedAttributeKey.isQualitative) {
      selectedAttributeKey.values
        ? e.target.value !== ''
          ? setFilteredAttributeValue(selectedAttributeKey.values.filter((values) => new RegExp(e.target.value, 'i').test(values.name)))
          : setFilteredAttributeValue(selectedAttributeKey.values)
        : setFilteredAttributeValue(null);
    } else {
      attributesIdRef.current!.value = attributesIdRef.current ? selectedAttributeKey!.id : '';
      const numberValue = parseFloat(e.target.value);
      if (!isNaN(numberValue)) {
        initialAttribute.current.value = e.target.value;
        initialAttribute.current.key = keyAttributeValue;
        initialAttribute.current.type = 'quantitative';
        initialAttribute.current.decimalPrecision = selectedAttributeKey?.values ? selectedAttributeKey.values[0].decimalPrecision : null;
        initialAttribute.current.range = selectedAttributeKey?.values ? selectedAttributeKey.values[0].range : [];
        handleValidateValue();
        onChangeForm(initialAttribute.current, index);
      } else if (isNaN(numberValue) || e.target.value === '') {
        initialAttribute.current.value = '';
        handleValidateValue();
        onChangeForm(initialAttribute.current, index);
      }
    }
  };

  /** Estado maneja el error del input de valor de atributo */
  const [valueInputWithError, setValueInputWithError] = useState<boolean>(false);

  /** Estado muestra el icono del input de valor de atributo */
  const [valueMessageError, setValueMessageError] = useState<string>('');

  /** Función que valida el valor del atributo */
  const handleValidateValue = (): void => {
    const response = validateAttributesValuesUseCase(
      initialAttribute.current.value,
      initialAttribute.current.decimalPrecision,
      initialAttribute.current.type,
      initialAttribute.current.range,
    );
    if (response.error && response.kindError?.attributesValueError) {
      setValueInputWithError(true);
      setValueMessageError(response.kindError.attributesValueError);
      initialAttribute.current.error = true;
    } else {
      handleResetInputValue();
      setValueMessageError('');
      initialAttribute.current.error = false;
    }
  };
  /**Funcion que resetea los valores del atributo - value para que no se muestre el icono de error y el mensaje de error*/
  const handleResetInputValue = (): void => {
    setValueInputWithError(false);
    setValueMessageError('');
  };

  /**Función de salida del input attribute - key */
  const handleBlurValue = (): void => {
    setSelectedAttributeValueState(false);
    if (filteredAttributeValue && filteredAttributeValue.length < 1) {
      setSelectedAttributeValue('');
      selectedAttributeKey && selectedAttributeKey.values && setFilteredAttributeValue(selectedAttributeKey.values);
    }
    attributesValueRef.current!.blur();
  };

  /**Función de entrada del input attribute - value */
  const handleFocusValue = (): void => {
    setSelectedAttributeValueState(true);
  };
  /** FIN INPUT VALOR DE ATRIBUTO */

  /** INICIO ID VALOR DE ATRIBUTO (INPUT OCULTO) */

  /** Referencia al input attribute - id */
  const attributesIdRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** FIN ID VALOR DE ATRIBUTO  (INPUT OCULTO)*/

  /** UseEffect para renderizar los componentes cada vez que los atributos y sus valores cambien */
  useEffect(() => {
    selectedAttributeKey && selectedAttributeKey.values && setFilteredAttributeValue(selectedAttributeKey.values);
  }, [selectedAttributeKey]);

  /** UseEffect para renderizar los componentes cada vez que los atributos y sus valores cambien */
  useEffect(() => {
    initialSetComponent();
    setFilteredAttributeKey(attributes);
  }, []);

  /** Si cambia el arreglo de atributos seleccionados en el padre  */
  useEffect(() => {
    initialAttribute.current = { ...attributesForm[index] };
    initialSetComponent();
  }, [attributesForm]);

  /** Establece los valores de los input */
  const initialSetComponent = () => {
    setKeyAttributeValue(initialAttribute.current.key ?? '');
    setSelectedAttributeValue(initialAttribute.current.value ?? '');
    setSelectedAttributeKey(findKeyByName(initialAttribute.current.key ?? ''));
    if (initialAttribute.current.key.length > 0) {
      setSelectedValue(true);
    }
  };

  /**CONSTANTES DE TEXTO */

  const ATRIBUTE_INPUT_VALUE: string = 'Busca un atributo';
  const SELECTED_ATRIBUTE_INPUT_VALUE: string = 'Buscar';

  return (
    <Row className='d-flex g-0 mb-3 '>
      <Col xs={12} className={`border border-1 rounded rounded-3 shadow rounded-bottom`}>
        <Col xs={11} className='py-3 mx-auto position-relative ' onMouseLeave={handleBlurKey}>
          <InputForm
            inputRef={attributesKeyRef}
            label={label}
            value={keyAttributeValue}
            classLabel='p-large-bold'
            placeholder={selectedAttributeKeyState ? SELECTED_ATRIBUTE_INPUT_VALUE : ATRIBUTE_INPUT_VALUE}
            firstIconClass={selectedAttributeKeyState ? 'first-input-icon svg-tertiary-1' : ''}
            firstIconName={selectedAttributeKeyState ? 'ico-search' : ''}
            onChange={handleChangeKey}
            onFocus={handleFocusKey}
            onClick={handleFocusKey}
          />
          {selectedAttributeKeyState && (
            <div className='bg-primary-4 position-absolute ultra-top start-0 end-0 shadow rounded rounded-3 '>
              <ul className='list-unstyled px-2 attribute-list'>
                {filteredAttributeKey?.map((attribute) => (
                  <div key={`attribute-${generateNumberId()}`}>
                    <li
                      className={`py-1 cursor-hand ${
                        selectedAttributes.current!.find((elem) => elem === parseInt(attribute.id)) !== undefined && 'text-tertiary-1'
                      }`}
                      onClick={
                        selectedAttributes.current!.find((elem) => elem === parseInt(attribute.id)) === undefined
                          ? () => handleSelectKey(attribute.name)
                          : undefined
                      }>
                      {attribute.name}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}
        </Col>
        {selectedValue && selectedAttributeKey && (
          <>
            <Col xs={11} className='py-2 mx-auto position-relative' onMouseLeave={handleBlurValue}>
              <InputForm
                label={'Valor'}
                type={selectedAttributeValueState && selectedAttributeKey && !selectedAttributeKey.isQualitative ? 'number' : undefined}
                inputRef={attributesValueRef}
                value={selectedAttributeValue}
                classLabel='p-large-bold'
                withErrors={valueInputWithError}
                messageError={valueMessageError}
                placeholder={
                  selectedAttributeValueState && selectedAttributeKey && selectedAttributeKey.isQualitative ? SELECTED_ATRIBUTE_INPUT_VALUE : ''
                }
                firstIconClass={
                  selectedAttributeValueState && selectedAttributeKey && selectedAttributeKey.isQualitative ? 'first-input-icon svg-tertiary-1' : ''
                }
                firstIconName={selectedAttributeValueState && selectedAttributeKey && selectedAttributeKey.isQualitative ? 'ico-search' : ''}
                onChange={handleChangeValue}
                onFocus={handleFocusValue}
                onClick={handleFocusValue}
              />
              <InputForm className='invisible position-absolute' inputRef={attributesIdRef} />
              {selectedAttributeValueState && selectedAttributeKey && selectedAttributeKey.isQualitative && (
                <div className='bg-primary-4 position-absolute ultra-top start-0 end-0 shadow rounded rounded-3 '>
                  <ul className='list-unstyled px-2 attribute-list'>
                    {filteredAttributeValue?.map((values, index) => (
                      <div key={index}>
                        <li className='py-1 cursor-hand' onClick={() => handleSelectValue(values.name, values.id)}>
                          {values.name}
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
            <Col xs={12} className='d-flex justify-content-end'>
              <Icon name='ico-trash' classMain='me-2 mb-2 cursor-hand' onClick={handleReset} />
            </Col>
          </>
        )}
      </Col>
    </Row>
  );
};

export default AttributesSelection;
