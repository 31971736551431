import { useEffect } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { useAppSelector, useAppDispatch } from '@/ui/store/helperRedux';

import { changeSessionState } from '@/ui/store/slices/showSessionModalSlice';

/**
 * Hook que evalúa el tiempo transcurrido desde el login del usuario para controlar su expiración
 */
const useSessionExpiration = () => {
  const dispatch = useAppDispatch();
  const loginTime: number | null = useAppSelector((state) => state.userState.user.date);

  const sessionTimeInMinutes: number = ConfigNumberParameters.REACT_APP_SESSION_DURATION;

  /** Revisa cada tantos segundos */
  const CHECK_EVERY: number = 60;
  /** Se activa tantos minutos antes de completar el tiempo de expiración */
  const WARNING_TIME_IN_MINUTES: number = 1;

  useEffect(() => {
    /** Solo inicia el intervalo si existe una hora de login, es decir si se logeo un usuario */
    if (loginTime) {
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const hoursInMilliseconds = sessionTimeInMinutes * 60 * 1000;
        const warningTimeInMilliseconds = WARNING_TIME_IN_MINUTES * 60 * 1000;
        const timeElapsed = currentTime - loginTime;

        if (timeElapsed >= hoursInMilliseconds - warningTimeInMilliseconds) {
          /** Lanza el modal */
          dispatch(changeSessionState());
          clearInterval(interval);
        }
      }, 1000 * CHECK_EVERY);

      return () => clearInterval(interval);
    }
  }, [loginTime]);
};

export default useSessionExpiration;
