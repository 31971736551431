import { useState, useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavLink } from 'react-router-dom';

import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';

import { Container, Row, Col } from 'react-bootstrap';

import { useAppSelector, useAppDispatch } from '@/ui/store/helperRedux';
import { changeLoginState } from '@/ui/store/slices/showLoginSlice';

import MenuController from '@/controllers/MenuController';
import { updateCart } from '@/domain/useCases/updateCartUseCase';

import { IMenu } from '@/domain/interfaces/IMenu';

import generateNumberId from '@/domain/utils/generateNumberId';

import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';
import DropDownList from '@/ui/components/DropDownList';

import './style.css';
import { ICart } from '@/domain/interfaces/ICart';
import { RootState } from '@/ui/store/store';

/** Componente contenedor del footer para desktop
 * @component
 */
const Footer = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const dispatch = useAppDispatch();

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /** Logo en blanco del proyecto */
  const logo: string = `${URL_IMAGES}/client/logo.png`;

  const RESERVED_RIGHTS: string = `${URL_IMAGES}/client/footer.png`;

  /** Logo de Webpay en blanco */
  const LOGO_WEBPAY_WHITE: string = `${URL_IMAGES}/static/webpay-white.png`;

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Objeto Redux que almacena la data del menu  */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);

  /**  Llamado del footer desde el controlador */
  const { footer } = MenuController();

  /**  Estado inicial que trae un arreglo vacío bajo la interface FooterInterface 	*/
  const [footerMenu, setFooterMenu] = useState<IMenu[]>([]);

  /**  Evento que muestra y oculta el modal para login	*/
  const handleShowLogin = (): void => {
    /** Al ser una función pasada en el objeto footerList no guarda la ultima actualización Redux */
    /** Se usa el valor de reservationId en localStorage */
    const cartUseCase = updateCart();
    const cartLocal: ICart | null = cartUseCase.getCart();
    if (!cartLocal || cartLocal.reservationId === null) {
      /** Si no esta bloqueado se muestra el modal de Login */
      dispatch(changeLoginState());
    }
  };

  /**  Objeto que contiene la data del footer 	*/
  const footerList: IMenu[] = [
    {
      icon: '',
      id: 'itemFooter1',
      title: 'Acerca de',
      url: '#',
      slug: '',
      children: [
        { icon: '', id: 'footer1-1', title: 'Quienes Somos', url: '#', slug: '', children: [] },
        { icon: '', id: 'footer1-2', title: 'Contacta con Nosotros', url: '#', slug: '', children: [] },
        // { icon: '', id: 'footer1-4', title: 'Políticas de privacidad', url: '#', slug: '', children: [] },
        // { icon: '', id: 'footer1-5', title: 'Términos de uso', url: '#', slug: '', children: [] },
        // { icon: '', id: 'footer1-6', title: 'Política de devoluciones y garantías', url: '#', slug: '', children: [] },
        // { icon: '', id: 'footer1-3', title: 'Envíos y Entregas', url: '#', slug: '', children: [] },
      ],
    },
    {
      icon: '',
      id: 'itemFooter4',
      title: 'Usuarios',
      url: '#',
      slug: '',
      children: [
        { icon: '', id: '', title: 'Administrador', url: '#', slug: '', children: [], function: handleShowLogin },
        { icon: '', id: '', title: 'Seguimiento de compra', url: `${RoutesDirections.TRACKING_ROUTE}`, slug: '', children: [] },
        /*{
          icon: '',
          id: 'footer4-2',
          title: 'Carrito de compras',
          url: '#',
          slug: '',
          children: [],
          function: () => navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } }),
        },*/
      ],
    },
  ];

  /** Variable que toma la longitud del footer para definir como renderizará las grids */
  const lengthFooter = footerMenu.length;

  /**  Texto de los medios de pago 	*/
  const PAYMENT_METHODS_TEXT: string = 'Medios de pago';

  /**  Función que renderiza los items del footer 	*/
  const FooterItem = (menu: IMenu, url: string, index: number): JSX.Element => {
    return (
      <div key={`footer-child-${index + 1}`}>
        {url !== '#' ? (
          <NavLink to={menu.url} className={`svg-primary-4`}>
            <IconList iconName={menu.icon} classMain='py-2 btn-list-link-icon-dark-small' title={menu.title} />
          </NavLink>
        ) : (
          <IconList
            iconName={menu.icon}
            classMain={` py-2 btn-list-link-icon-dark-small ${
              cartProducts.reservationId && cartProducts.reservationId !== null && menu.function ? 'text-tertiary-1 disabled' : ''
            }`}
            title={menu.title}
            onClick={menu.function}
          />
        )}
      </div>
    );
  };

  /**  UseEffect para renderizado de Footer 	*/
  useEffect(() => {
    // Recupera el objeto del Footer del controlador
    const footerData: IMenu[] = JSON.parse(JSON.stringify(footer()));

    //Extrae la información de las categorías
    const categoryMenu: IMenu | null = navMenu.find((menu) => menu.slug === 'categoria') ?? null;
    const productsMenu: IMenu | null = categoryMenu?.children!.find((menu) => menu.title === 'Productos') ?? null;

    //Arma el objeto de categorías con el titulo necesario en el Footer
    const categoryMenuFooter: IMenu = {
      icon: '',
      id: '',
      title: 'Categoría de productos',
      slug: '',
      url: '',
      children: productsMenu != null ? JSON.parse(JSON.stringify(productsMenu.children)) : [],
    };

    // Agrega las categorías al objeto footer
    footerData.unshift(categoryMenuFooter);

    // Agrega los items del footer y los items del menu principal al objeto footer final
    footerList.forEach((list) => {
      footerData.push(list);
    });

    //Actualiza el state
    setFooterMenu(footerData);
  }, [navMenu]);

  /** Renderizado del componente */
  return (
    <Container fluid className='bg-tertiary-3 py-3'>
      <Container>
        <Row id='footer' className='g-0 '>
          <Col lg={12} className='pt-3'>
            {!isMobile && <div className='mb-4 h-2-px bg-primary-1'></div>}
            <Col xs={12}>
              {isMobile ? (
                footerMenu.map((list, index) => (
                  <div key={`footer-${index + 1}`}>
                    <DropDownList id={list.id} title={list.title} classTitle='p-large-bold text-primary-4'>
                      {list.children?.filter((child) => child.title !== 'Administrador').map((child, index) => FooterItem(child, child.url, index))}
                    </DropDownList>
                  </div>
                ))
              ) : (
                <Container fluid className='p-0'>
                  <Row className='g-0 d-flex justify-content-between'>
                    {footerMenu.map((list, index) => (
                      <Col xs={3} key={`footer-${index + 1}`}>
                        <ul className={`text-primary-4 list-unstyled ${lengthFooter > 3 || (lengthFooter % 2 === 0 && 'mx-auto')}`}>
                          <span className='p-large-bold'>{list.title}</span>
                          {list.children?.slice(0, 6).map((list, index) => FooterItem(list, list.url, index))}
                        </ul>
                      </Col>
                    ))}
                  </Row>
                </Container>
              )}
            </Col>
            {!isMobile && <div className='mt-2 h-2-px bg-primary-1'></div>}
            <Row className=' my-4 g-0 text-center'>
              <Col lg={12} className='d-flex justify-content-between align-items-center  d-md-block  px-md-3 text-primary-5 '>
                <span className='h5'>{PAYMENT_METHODS_TEXT}</span>
                <img src={LOGO_WEBPAY_WHITE} alt='logo webpay' className='ps-4' />
              </Col>
              <Col lg={12} className='d-flex justify-content-center mt-4'>
                <img src={RESERVED_RIGHTS} alt='rights'></img>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
