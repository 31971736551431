import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import CartLocalStorage from '@/data/localStorage/CartLocalStorage';

import { ICart, ICartDetail, ICartResponse, IClientDetail } from '@/domain/interfaces/ICart';
import { IIdentification, IPhone } from '@/domain/interfaces/IUser';

/**
 * Caso de uso de actualizar carrito de compras
 */
const updateCart = () => {
  /** Mensajes de error */
  const SKU_MESSAGE_ERROR: string = 'SKU Invalido';
  const STATUS_ERROR: string = 'Producto inactivo';
  /**
   * Instancia del Repository
   * @const {CartLocalStorage}
   */
  const cartRepository = CartLocalStorage.instance;

  /** Devuelve el carrito */
  const getCart = (): ICart | null => {
    return cartRepository.getCart();
  };

  /** Extrae un articulo especifico del carrito por su SKU */
  const getArticleFromCart = (sku: string): ICartDetail | {} | null => {
    const cart: ICart | null = getCart();

    if (cart !== null) {
      const articlesInCart: ICartDetail[] = cart.details ?? [];
      const article: ICartDetail | {} = articlesInCart.find((item) => item.skuProduct === sku) ?? {};
      return article;
    }

    return null;
  };

  /** Determina si un producto esta en el carrito según su SKU */
  const isInCart = (sku: string = ''): boolean => {
    let exist: boolean = false;

    const cart: ICart | null = getCart();
    if (cart !== null) {
      const articles: ICartDetail[] = cart?.details ?? [];

      exist = articles.findIndex((item) => item.skuProduct === sku) !== -1;
    }

    return exist;
  };

  /** Establece el carrito cuando es el primer articulo a agregar */
  const setCart = (article: ICartDetail) => {
    const phoneEmpty: IPhone = {
      number: '',
      country: '',
    };
    const identificationEmpty: IIdentification = {
      type: '',
      value: '',
    };

    /**Objeto Vacío de Detalles de cliente */
    const CartEmptyClientDetail: IClientDetail = {
      id: null,
      firstName: '',
      lastName: '',
      address: '',
      region: '',
      identificationId: identificationEmpty,
      email: '',
      phone: phoneEmpty,
    };

    const cart: ICart = {
      // Pendiente definir cliente anónimo
      gatewayId: 1,
      clientDetails: CartEmptyClientDetail,
      totalWithTax: 0,
      totalDiscount: 0,
      totalAmount: 0,
      totalProducts: 0,
      totalShipping: parseInt(process.env.REACT_APP_STANDARD_SHIPPING_COST ?? '0'),
      totalTaxes: 0,
      totalWithoutTax: 0,
      /** Agrega el articulo */
      details: [article],
    };
    /** Actualiza el carrito */
    cartRepository.updateCart(cart);
  };

  /** Recalcula los montos del carrito según sus artículos relacionados */
  const calculateAmounts = (): void => {
    /** Obtenemos el carrito */
    const cart: ICart | null = getCart();

    let totalWithTax: number = 0;
    let totalDiscount: number = 0;
    let totalProducts: number = 0;

    let totalTaxes: number = 0;
    let totalWithoutTax: number = 0;

    if (cart !== null) {
      /** Extraemos los artículos */
      const cartTemp: ICart = JSON.parse(JSON.stringify(cart));
      const articles: ICartDetail[] = cartTemp?.details ?? [];

      /** Recalculamos los totales */
      articles.forEach((item: ICartDetail) => {
        totalProducts += item.quantity ?? 0;

        /** Precio full del producto */
        const totalFull: number = (item.price ?? 0) * (item.quantity ?? 1);

        /** Precio real con precio oferta */
        let amountProduct: number = 0;
        if (item.offerPrice !== undefined && item.offerPrice !== null && item.offerPrice >= 0) {
          amountProduct = item.offerPrice * (item.quantity ?? 1);
        } else {
          amountProduct = (item.price ?? 0) * (item.quantity ?? 1);
        }

        /** Calculo de impuestos por productos */
        const taxes: number = item.taxes.reduce((total, taxa) => total + taxa.percentage, 0);

        /**Calculo de productos sin impuestos */
        const amountWithoutTax: number = amountProduct !== 0 ? amountProduct / (1 + taxes) : 0;

        /**Total de productos sin impuestos */
        totalWithoutTax += amountWithoutTax;

        /** Total de impuestos por producto */
        totalTaxes += amountWithoutTax * taxes;

        /** Total con impuestos */
        totalWithTax += amountProduct;

        /** Descuento */
        totalDiscount += totalFull - amountProduct;
      });

      cartTemp.totalWithTax = Math.round(totalWithTax);
      cartTemp.totalAmount = Math.round(totalWithTax + cartTemp.totalShipping);
      cartTemp.totalDiscount = Math.round(totalDiscount);
      cartTemp.totalProducts = Math.round(totalProducts);
      cartTemp.totalTaxes = Math.round(totalTaxes);
      cartTemp.totalWithoutTax = Math.round(totalWithoutTax);

      /** Actualizamos el carrito */
      cartRepository.updateCart(cartTemp);
    }
  };

  /** Valida y agrega un articulo al carrito  */
  const addArticle = (article: ICartDetail): ICartResponse => {
    /** Objeto de respuesta */
    const response: ICartResponse = {
      error: false,
      message: '',
      cart: null,
    };

    let zeroArticles: boolean = false;

    /** Valida SKU */
    if (article.skuProduct === null) {
      response.message = SKU_MESSAGE_ERROR;
      response.error = true;
    }

    /** Valida cantidad */
    if (article.quantity === undefined || article.quantity === null || article.quantity < 1) {
      zeroArticles = true;
    }

    //Pendiente validaciones de impuestos y descuentos

    /** Si no hay errores agrega el articulo */
    if (!response.error) {
      if (!zeroArticles) {
        /** Consultamos si ya existe un carrito */
        const cart: ICart | null = getCart();
        if (cart !== null) {
          /** Agregamos/Actualizamos el articulo en el carrito */
          const articlesInCart = cart?.details ?? [];
          const indexOfArticle = articlesInCart.findIndex((item) => item.skuProduct === article.skuProduct);
          if (indexOfArticle !== -1) {
            articlesInCart[indexOfArticle].quantity = article.quantity ?? 0;
            if (cart.reservationId === undefined || cart.reservationId === null || cart.reservationId === 0) {
              articlesInCart[indexOfArticle].price = article.price ?? 0;
              articlesInCart[indexOfArticle].offerPrice = article.offerPrice ?? null;
              articlesInCart[indexOfArticle].title = article.title ?? '';
              articlesInCart[indexOfArticle].image = article.image ?? '';
              articlesInCart[indexOfArticle].status = article.status ?? 2;
              articlesInCart[indexOfArticle].quantityAvailable = articlesInCart[indexOfArticle].status !== 1 ? 0 : article.quantityAvailable ?? 0;
              articlesInCart[indexOfArticle].attributes.splice(0, articlesInCart[indexOfArticle].attributes.length, ...article.attributes);
              articlesInCart[indexOfArticle].taxes.splice(0, articlesInCart[indexOfArticle].taxes.length, ...article.taxes);
            }
          } else {
            cart.details?.push(article);
          }

          /** Se actualiza el carrito */
          cartRepository.updateCart(cart);
        } else {
          /** Se establece el carrito si no existe */
          setCart(article);
        }
        calculateAmounts();

        /** Agregamos el carrito a la respuesta */
        response.cart = getCart();
      } else {
        /** Se asume que actualizar a cero la cantidad de artículos, se quiere eliminar el articulo */
        return deleteArticle(article);
      }
    }

    return response;
  };

  /** Eliminar artículos del carrito */
  const deleteArticle = (article: ICartDetail): ICartResponse => {
    /** Objeto de respuesta */
    const response: ICartResponse = {
      error: false,
      message: '',
      cart: null,
    };

    /** Consultamos el carrito */
    const cart: ICart | null = getCart();
    if (cart !== null) {
      /** Establecemos si el carrito tiene artículos adicionales */
      const articlesInCart = cart?.details ?? [];
      const articlesUpdated: ICartDetail[] = articlesInCart.filter((item) => item.skuProduct !== article.skuProduct);

      if (articlesUpdated.length > 0) {
        /** Eliminamos el articulo */
        cart.details = articlesUpdated;
        cartRepository.updateCart(JSON.parse(JSON.stringify(cart)));
        calculateAmounts();
        const updatedCart: ICart | null = getCart();
        if (updatedCart !== null) {
          response.cart = JSON.parse(JSON.stringify(updatedCart));
        }
      } else {
        /** Si no hay mas artículos eliminamos el carrito */
        cartRepository.deleteCart();
        response.cart = null;
      }
    }

    return response;
  };

  /** Funciones que retorna el caso de uso */
  return { addArticle, deleteArticle, isInCart, getArticleFromCart, getCart };
};

export { updateCart };
