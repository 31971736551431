import { ICart } from '@/domain/interfaces/ICart';
import { IUserStore } from '@/domain/interfaces/IUser';


/** Devuelve data del usuario almacenada en localeStorage
    * @returns {IUserStore} user - Data del usuario 
*/
export const userLocalStorage = (): IUserStore => {
    const storageId = localStorage.getItem('ilisPlacesIdUser') !== undefined ? Number(localStorage.getItem('ilisPlacesIdUser')) : null;
    let userStore: number | null = null
    if (localStorage.getItem('ilisPlacesUserStore') !== null) {
        if (localStorage.getItem('ilisPlacesUserStore') !== undefined && !Number.isNaN(localStorage.getItem('ilisPlacesUserStore'))) {
            userStore = Number(localStorage.getItem('ilisPlacesUserStore'));
        }
    }

    let officeId: number = 0
    if (localStorage.getItem('ilisPlacesUserOfficeID') !== null) {
        if (localStorage.getItem('ilisPlacesUserOfficeID') !== undefined && !Number.isNaN(localStorage.getItem('ilisPlacesUserOfficeID'))) {
            officeId = Number(localStorage.getItem('ilisPlacesUserOfficeID'));
        }
    }

    return {
        user: {
            email: localStorage.getItem('ilisPlacesEmail') ?? null,
            userStore: userStore,
            branchOffice: { id: officeId, name: localStorage.getItem('ilisPlacesUserOfficeName') ?? '' },
            status: localStorage.getItem('ilisPlacesActiveUser') ?? null,
            token: localStorage.getItem('ilisPlacesToken') ?? null,
            firstName: localStorage.getItem('ilisPlacesName') ?? null,
            position: localStorage.getItem('ilisPlacesPosition') ?? null,
            kind: localStorage.getItem('ilisPlacesKindUser') ?? null,
            id: storageId,
            isLogued: !(localStorage.getItem('ilisPlacesEmail') === null || localStorage.getItem('ilisPlacesEmail') === ''),
            date: Number(localStorage.getItem('ilisLoginDate')) ?? 0,
        },
        ip: localStorage.getItem('ipUser') ?? '',
    }
}

/** Devuelve el carrito almacenado en localeStorage
    * @returns {ICart} cart - Carrito
*/
export const cartLocalStorage = (): ICart | {} => {

    const cartLocalStorage: string | null = localStorage.getItem('IlisPlacesCart');

    if (cartLocalStorage === null) {
        return {};
    } else {
        return JSON.parse(cartLocalStorage);
    }


}