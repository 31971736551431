import { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

/** Propiedades para el componente ButtonGroup.*/
type PropstepIndications = {
  /** indicador puede ser numeor o letra. */
  indicator: string | number;
  /** Titulo de la indicacion  */
  title: string;
  /** Detalles de la indicacion  */
  details?: string;
};

/**
 * Componente que define un listado de paso a paso, recibe un numero un titulo y un detalles
 * @component
 */
const StepIndications = ({
  indicator,
  title,
  details,
}: PropsWithChildren<PropstepIndications>): JSX.Element => {
  return (
    <Container fluid className='p-0'>
      <Row className='g-0'>
        <Col xs={12} className='my-3 d-flex flex-row'>
          <div>
            <span className='bg-secondary-4 text-primary-3 py-1 px-2 me-2 rounded rounded-circle p-regular-bold'>
              {indicator}
            </span>
          </div>
          <div className='ps-3'>
            <p className='mb-1'>
              <span className='p-large-bold'>{title}</span>
            </p>
            <span className='text-tertiary-1 p-large'>{details}</span>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StepIndications;
