import { createSlice } from '@reduxjs/toolkit';

const initialState = {value:false}

/** Almacena el estado showLoginReducer de Redux con sus reducers
  * @constant {object}
*/
export const showLoginModalSlice = createSlice({
  name: 'showLogin',
  initialState,
  reducers: {
    changeLoginState: (state) => {
      state.value = !state.value;
    },
  }
})

export const { changeLoginState } = showLoginModalSlice.actions;

export default showLoginModalSlice.reducer;
