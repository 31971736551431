import { PropsWithChildren } from 'react';
import { useAppSelector } from '@/ui/store/helperRedux';
import { Container, Row, Col } from 'react-bootstrap';

import { IMenu } from '@/domain/interfaces/IMenu';

import CloseIcon from '@/ui/assets/CloseIcon';

import ListSubmenu from '@/ui/components/ListSubmenu';

interface PropsSubmenu {
  /** Valor que renderiza el submenu segun su valor   */
  keySubmenu: string;
  /** Función que setea el llamado del padre a este submenu en false
   *  el padre debe llamar una función con esta sintaxis:
   *  const [submenu, setSubmenu] = useState(false);
   *  const hideSubmenu = () => {
   *      setSubmenu(false)
   *  };
   */
  hideSubmenu?: any;
}

/**
 * Componente submenu de imagenes
 * @component
 */
const Submenu = ({ hideSubmenu, keySubmenu = '' }: PropsWithChildren<PropsSubmenu>): JSX.Element => {
  /** Objeto Redux que almacena la data del menu  */
  const navMenu: IMenu[] = useAppSelector((state) => state.mainMenuState);

  /** Trae el id como objeto del menú a donde se esta accediendo */
  const currentMenu = navMenu.find((menuItem) => menuItem.slug === keySubmenu);

  return (
    <Container
      fluid
      id='submenu'
      className='bg-primary-5 shadow px-5 position-fixed start-0 top-submenu text-secondary-2 shadow ultra-top'
      onMouseLeave={hideSubmenu}>
      {currentMenu && currentMenu.url === '#' && (
        <Container>
          <Row>
            <Col xs={12} className=' pt-3 pb-2 d-flex justify-content-between'>
              <h5 className='text-primary-3 fw-bold'>{currentMenu.title}</h5>
              <CloseIcon onClick={hideSubmenu} classMain='btn-icon-light' />
            </Col>
          </Row>
          <Row className=' d-flex align-items-stretch'>
            <Col>
              <ListSubmenu keySubmenu={keySubmenu} hideSubmenu={hideSubmenu} />
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default Submenu;
