import { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Icon from '@/ui/assets/Icon';

interface PropsInfoBox {
  /**Nombre de icono */
  iconName: string;
  /**Clase del icono */
  iconClass?: string;
  /**Texto de caja */
  text: string;
  /**Clase del texto */
  textClass?: string;
  /**Link de acción */
  link?: string
  /**Función de acción de click link */
  onClick?: () => void;
}
/**
 * Caja de información usada como ancla
 * @component
*/
const InfoBox = ({
  iconName,
  iconClass,
  text,
  textClass,
  link,
  onClick
}: PropsWithChildren<PropsInfoBox>): JSX.Element => {

  return (
    <Container fluid className='shadow p-3 rounded rounded-2'>
      <Row className='pb-2'>
        <Icon name={iconName} classMain={iconClass} />
      </Row>
      <Row>
        <Col lg={8}>
          <span className={textClass}>{text}</span>
        </Col>
        {link &&
          <Col lg={4} className='text-end'>
            <span onClick={onClick ? onClick : undefined} className='link-text-standard'>{link}</span>
          </Col>
        }
      </Row>
    </Container>
  );
}

export default InfoBox;