import { createSlice } from '@reduxjs/toolkit';
import { IOrderRequest } from '@/domain/interfaces/IAttributes';

/** Objeto inicial del carrito */
const initialState: IOrderRequest[] = [{field:'prodCreatedAt', order:'DESC'}];

/** Almacena el estado userState de Redux con sus reducers
 * @constant {object}
 */
export const storeSortCriteriaSlice = createSlice({
  name: 'storeSortCriteriaState',
  initialState,
  reducers: {
    updateCritera: (state, action) => {
      state.splice(0, state.length, ...action.payload);
    },
    resetCritera: (state) => {
      state.splice(0, state.length, ...initialState);
    },
  },
});

export const { updateCritera, resetCritera } = storeSortCriteriaSlice.actions;

export default storeSortCriteriaSlice.reducer;