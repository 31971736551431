import { useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useNavigate, useLocation, type NavigateFunction, Location } from 'react-router';

import MessageLayout from '@/ui/containers/MessageLayout';

/** Componente pagina de Error de Servidor
 *@component
 */
const ErrorPage = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Tiempo para eliminar reserva por inactividad */
  const REFRESH_ERROR_PAGE_TIMEOUT_DURATION = 180000; // 3 minutos en milisegundos

  useEffect(() => {
    const navigationTimeout = setTimeout(() => {
      navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } });
    }, REFRESH_ERROR_PAGE_TIMEOUT_DURATION);

    return () => clearTimeout(navigationTimeout); // Limpiar el timeout en la limpieza del efecto
  }, []);

  /** Titulo principal */
  const MAIN_TITLE = '¡Oops! Hubo un pequeño contratiempo.';
  const TITLE_DESCRIPTION = 'Por favor, inténtalo de nuevo actualizando la página. ';
  const TITLE_LINK = 'Actualizar página';

  return (
    <MessageLayout
      title={MAIN_TITLE}
      imgSrc={`${URL_IMAGES}/client/img_error.png`}
      description={TITLE_DESCRIPTION}
      linkText={TITLE_LINK}
      route={'-1'}
    />
  );
};

export default ErrorPage;
