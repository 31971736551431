/**
     * Recibe un arreglo y un valor, filtrando del arreglo el valor enviado.
     * @param array - Arreglo .
     * @param file - Valor que se quiere filtrar.
     * @returns  el arreglo de las propiedades que no tengan el valor enviado.
*/
const filterValues = (array, value) => {
    const filteredValue = array.filter((data) => data !== value);

    return filteredValue;
  }

export default filterValues;
