import PreInsertBaseProductLocalStorage from '@/data/localStorage/PreInsertBaseProductLocalStorage';
import PreInsertVariantLocalStorage from '@/data/localStorage/PreInsertVariantLocalStorage';


/** Representa el controlador que maneja los eventos de los casos de uso de la tabla de productos base
  * @returns {object} Funciones del controlador
*/
const PreInsertController = () => {

    const getSelectedBaseProductTable = (): { [index: number]: string } | null => {

        /** Instancia del Repository
        * @const {PreInsertBaseProductLocalStorage}
        */
        const inventoryRepository = PreInsertBaseProductLocalStorage.instance;
        const result = inventoryRepository.getObj();
        const inventory: { [index: number]: string } | null = result;

        return inventory;
    }

    const updateSelectedBaseProductTable = (objSelectedRows: { [index: number]: string }): void => {

        /** Instancia del Repository
        * @const {PreInsertBaseProductLocalStorage}
        */
        const inventoryRepository = PreInsertBaseProductLocalStorage.instance;
        inventoryRepository.updateObj(objSelectedRows);

    }

    const getSelectedVariantTable = (): { [index: number]: string } | null => {

        /** Instancia del Repository
        * @const {PreInsertVariantLocalStorage}
        */
        const inventoryRepository = PreInsertVariantLocalStorage.instance;
        const result = inventoryRepository.getObj();
        const inventory: { [index: number]: string } | null = result;

        return inventory;
    }

    const updateSelectedVariantTable = (objSelectedRows: { [index: number]: string }): void => {

        /** Instancia del Repository
        * @const {PreInsertVariantLocalStorage}
        */
        const inventoryRepository = PreInsertVariantLocalStorage.instance;
        inventoryRepository.updateObj(objSelectedRows);

    }

    const deleteObjSelectedProductBase = () => {
        const inventoryRepository = PreInsertBaseProductLocalStorage.instance;
        inventoryRepository.deleteObj();
    }
    
    const deleteObjSelectedVariant = () => {
        const inventoryRepository = PreInsertVariantLocalStorage.instance;
        inventoryRepository.deleteObj();
    }

    /**
     * retorna la funciones del controlador
     */
    return { 
        getSelectedBaseProductTable, 
        updateSelectedBaseProductTable, 
        getSelectedVariantTable, 
        updateSelectedVariantTable,
        deleteObjSelectedProductBase,
        deleteObjSelectedVariant,
    };
};

export default PreInsertController;
