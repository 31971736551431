import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

/** Tipo que define las propiedades para la tabla tipo @tanstack/react-table */
interface TableCustomProps<
  T extends {
    id: number | string;
  }
> {
  /** Arreglo de objetos de cualquier tipo, pero cada objeto debe incluir una propiedad `id`.   */
  dataTable: T[];
  /** Arreglo de objetos donde se definen las columnas.   */
  columns: ColumnDef<T>[];
  /** Estado inicial de orden */
  sortingState?: SortingState;
  /** Estado inicial de visibilidad de la tabla */
  visibilityState?: VisibilityState;
  /** Estado inicial de filtros */
  filterState?: ColumnFiltersState;
  /** Objeto tipo useRef que almacenará la selección de registros */
  rowSelection?: { [index: number]: string };
  /** Función para actualizar el atributo rowSelectionRef  */
  updateRowSelection?: (newRowSelection: { [index: number]: string }) => void;
  /** Función para disparar en el onclick del registro, el parametro debe ser el id de algun objeto de dataTable  */
  specialAction?: (id: number | string) => void;
}

/** Componente Tabla
 * @component
 */
const TableCustom = <
  T extends {
    id: number | string;
  }
>({
  dataTable = [],
  columns = [],
  sortingState = [],
  visibilityState = {},
  filterState = [],
  rowSelection,
  updateRowSelection,
  specialAction,
}: PropsWithChildren<TableCustomProps<T>>) => {
  /** Estado inicial de orden de la tabla */
  const [sorting, setSorting] = useState<SortingState>(sortingState);

  /** Estado inicial de la visibilidad de la tabla de la tabla */
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(visibilityState);

  /** Estado inicial de la visibilidad de la tabla de la tabla */
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(filterState);

  /** Referencia para las filas de la tabla */
  const trRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  /** Inicializa la tabla */
  const table = useReactTable({
    data: dataTable,
    columns,
    state: {
      sorting,
      columnVisibility,
      columnFilters,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  /**
   * Manejador de click al seleccionar una fila
   * @param {number} rowIndex
   */
  const handleRowSelectionChange = (rowIndex: number): void => {
    if (rowSelection) {
      /** Se clona el estado actual para no mutarlo directamente */
      const newRowSelection = JSON.parse(JSON.stringify(rowSelection));

      /** Se extrae el valor del ID del producto base */
      const selectedRow = table.getRowModel().rows[rowIndex];
      const columnValue = String(selectedRow.original['id']);

      /** Si el ID existe en el estado se elimina (deselección) */
      if (newRowSelection[rowIndex] === columnValue) {
        delete newRowSelection[rowIndex];
      } else {
        /** Si el ID no existe en el estado se agrega (selección) */
        newRowSelection[rowIndex] = columnValue;
      }

      if (updateRowSelection) {
        /** Actualiza el objeto useRef en el componente padre */
        updateRowSelection({ ...newRowSelection });
      }

      /** Actualiza la clase del elemento TR como registro seleccionado */
      const trRef = trRefs.current[rowIndex];
      if (trRef) {
        trRef.classList.toggle('active');
      }
    }
  };

  useEffect(() => {
    /**
     * Al cambiar el objeto de selección externamente, se deben actualizar las clases
     * de los registros en la tabla
     */

    // Extraemos las llaver del objeto de selección
    const keysRowSelection = Object.keys({ ...rowSelection });

    // Recorremos el objeto que contiene las referencias de las filas de la tabla
    trRefs.current.forEach((element, index) => {
      // Actualizamos la clase de la fila de acuerdo al objeto rowSelection
      if (keysRowSelection.includes(String(index))) {
        if (!element?.classList.contains('active')) {
          element?.classList.add('active');
        }
      } else {
        if (element?.classList.contains('active')) {
          element?.classList.remove('active');
        }
      }
    });
  }, [rowSelection]);

  return (
    <Table responsive>
      <thead className="bg-tertiary-3 text-primary-4 header">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} style={{ width: header.getSize() }}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr
            key={row.id}
            ref={(element) => (trRefs.current[rowIndex] = element)}
            className={`${row.hasOwnProperty('disabled') && row.renderValue('disabled') === true && 'disabled'} ${
              rowSelection && rowSelection.hasOwnProperty(rowIndex) ? 'active' : `${row.id}`
            }`}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                onClick={() => {
                  if (cell.column.id === 'select') {
                    handleRowSelectionChange(rowIndex);
                    /** Si la funcion especial specialAction fue recibida como parametro se ejecutara en este punto */
                    if (specialAction) {
                      specialAction(row.getValue('id'));
                    }
                  }
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableCustom;
