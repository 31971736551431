/**
     * Recibe un array de texto y la descarga como archivo txt.
     * @param array - arreglo a descargar .
     * @param text - titulo del archivo .
     * @returns  booleano .
*/
const errorMessageFormatter = (array: string[]): string[] => {
    /**Funcion para ordenar los errores por linea*/
    const sortedErrorArray = array && array.length > 0 ? [...array].sort((a, b) => {
        const lineA: number | string = getErrorLine(a);
        const lineB: number | string = getErrorLine(b);

        if (lineA === undefined || lineA === '') {
            return 1; // Mueve lineas vacías al final
        } else if (lineB === undefined || lineB === '') {
            return -1; // Mueve lineas vacías al final
        }

        return Number(lineA) - Number(lineB);
    }) : [];


    const errorArrayPresenter = sortedErrorArray.map(error => `${getErrorLine(error)}\t${getErrorInfo(error)}`);

    return errorArrayPresenter;
}




/**Funciones para obtener la linea y el detalle del error*/
const getErrorInfo = (error: string): string => {
    const descriptionMatch = /\[([^\]]+)\]\s*=\s*(.+)/.exec(error);
    const description = descriptionMatch ? descriptionMatch[2].trim() || '' : error;

    const headerMatch = /\[([^\]]+)\]/.exec(error);
    const header = headerMatch ? headerMatch[0] : '';

    return `${header} ${description}`;
};


/**Funcion para obtener la linea del error*/
const getErrorLine = (error: string): number | string => {
    const match = /^(.*?)\[([^\]]+)\]/.exec(error);
    const line = match ? parseInt(match[1].trim().split(' ')[1]) || '' : '';
    return line;
};

export { getErrorInfo, getErrorLine, errorMessageFormatter };