import { useCreateCartClientDetailsForm } from '@/ui/hooks/useCreateCartClientDetailsForm';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { updateCartRedux } from '@/ui/store/slices/cartSlice';
import { updateOrderRedux } from '@/ui//store/slices/orderSlice';
import { updateIndexCheckOut } from '@/ui/store/slices/indexCheckOutSlice';

import { RootState } from '@/ui/store/store';

import InventoryController from '@/controllers/InventoryController';

import { ICart, IIReservationDetail, IReservation, IReservationResponse } from '@/domain/interfaces/ICart';


/**
 * Hook personalizado para realizar la reserva de los productos del carrito de compras
 *
 * @returns {}
 */

const useReservation = () => {
  /** Hook para traer el objeto vacio del cliente en el carrito */
  const emptyCartClientObject = useCreateCartClientDetailsForm();

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /** Actualización de estado Redux */
  const dispatch = useAppDispatch();

  // Función para realizar la reserva de los productos del carrito
  const reservation = async (): Promise<boolean> => {
    /** Respuesta del hook, falso si no logró la reserva */
    let completedFlag: boolean = false;

    const { productReservation } = InventoryController();

    /** Prepara los objetos para enviar la reserva */
    const reservationDetails: IIReservationDetail[] = [];

    /** Prepara a partir de la información del carrito Redux */
    const cartProductsCopy: ICart = { ...cartProducts };
    if (cartProductsCopy.details && cartProductsCopy.details.length > 0) {
      cartProductsCopy.details.forEach((element) => {
        const reservationDetail: IIReservationDetail = {
          skuProduct: element.skuProduct,
          quantity: element.quantity,
        };
        reservationDetails.push(reservationDetail);
      });

      const reservationPayload: IReservation = {
        idClient: null,
        details: [...reservationDetails],
      };

      /** Envia la reservación al back */
      await productReservation(reservationPayload).then((responseReservation: IReservationResponse | null) => {
        /** Si es una reserva positiva se actualiza el id de la reserva en redux */
        if (responseReservation?.reservationId) {
          cartProductsCopy.reservationId = responseReservation.reservationId;
          localStorage.setItem('IlisPlacesOrder', JSON.stringify(cartProductsCopy));
          dispatch(updateOrderRedux(cartProductsCopy));
          dispatch(updateCartRedux(cartProductsCopy));
          completedFlag = true;
        }
      });
    }

    return completedFlag;
  };

  // Función para eliminar la reserva de los productos del carrito
  const deleteReservation = async (): Promise<boolean> => {
    /** Respuesta del hook, falso si no logró eliminar la reserva */
    let completedFlag: boolean = false;

    const { productDeleteReservation } = InventoryController();
    /** Prepara a partir de la información del carrito Redux */
    const cartProductsCopy: ICart = JSON.parse(JSON.stringify(cartProducts));
    if (cartProductsCopy.reservationId && cartProductsCopy.reservationId > 0) {
      /** Envia la solicitud de eliminar reservación al back */
        await productDeleteReservation(cartProductsCopy.reservationId).then(() => {
          /** Si se elimina  actualiza el id de la reserva en redux a null */
          completedFlag = true;
          cartProductsCopy.reservationId = null;
          cartProductsCopy.clientDetails = emptyCartClientObject;
          dispatch(updateCartRedux(cartProductsCopy));
        });
    }

    dispatch(updateIndexCheckOut(1));

    return completedFlag;
  };

  // Función para verificar la reserva de los productos del carrito
  const checkReservation = async (): Promise<number> => {
    /** Respuesta del hook, falso no existe la reserva */
    let reservationState: number = 0;

    const { checkReservation } = InventoryController();

    if (cartProducts.reservationId) {
      /** Consulta la reservación al back */
      reservationState = await checkReservation(cartProducts.reservationId);
    }

    return reservationState;
  };
  return { reservation, deleteReservation, checkReservation };
};

export default useReservation;
