import axios, { AxiosResponse } from 'axios';
import config from '@/data/services/api';

/** Representa la interacción con el Back */
export default class IdTypesRepository {
  /** Instancia de la clase   */
  private static _instance: IdTypesRepository;

  /** Patron Singleton
   * @returns {instance} _instance IdTypesRepository
   */
  static get instance(): IdTypesRepository {
    if (!this._instance) {
      this._instance = new IdTypesRepository();
    }
    return this._instance;
  }

  /** Solicita los tipos de identificación
   * @returns {object} data - tipos de identificación en el back
   */
  async getIdTypes(): Promise<AxiosResponse> {
    return await axios.get('/id-types', config);
  }
}
