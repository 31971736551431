import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiConfig from '@/data/services/api';
import { IProductForm } from '@/domain/interfaces/IProduct';
import { IBulkLoadingResponse } from '@/domain/interfaces/IInventory';
import { IReservation, IReservationResponse } from '@/domain/interfaces/ICart';

/** Representa la interacción con el Back */
export default class InventoryRepository {
  /** Instancia de la clase   */
  private static _instance: InventoryRepository;

  /** Patron Singleton
   * @returns {instance} _instance InventoryRepository
   */
  static get instance(): InventoryRepository {
    if (!this._instance) {
      this._instance = new InventoryRepository();
    }
    return this._instance;
  }

  /** Recupera el inventario de productos base
   * @param {String} token
   * @returns {object} data - Inventory
   */
  async getProductBaseInventory(token: string): Promise<AxiosResponse> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await axios.get('/inventory/base-products', requestConfig);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Publica carga masiva de producto base y variantes en el back
   * @param {IProductForm[]} productsForm
   * @param {string} token
   * @returns {IBulkLoadingResponse} cantidades cargadas
   */
  async inventoryBulkLoading(productsForm: IProductForm[], token: string = ''): Promise<AxiosResponse<IBulkLoadingResponse>> {
    /** URL a consultar */
    let url: string = `/inventory/bulk-loading`;
    //authorization token hace que el back sepa que usuario esta realizando la petición y si tiene permisos para realizarla
    apiConfig.headers!['Authorization'] = `Bearer ${token}`;

    return await axios
      .post(url, productsForm, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return error.response;
        }
      });
  }

  /**
   * Valida carga masiva de producto base y variantes en el back
   * @param {IProductForm[]} productsForm
   * @param {string} token
   * @returns {IResponseValidationBack} cantidades cargadas
   */
  async inventoryBulkLoadingValidation(productsForm: IProductForm[], token: string = ''): Promise<AxiosResponse<IBulkLoadingResponse>> {
    /** URL a consultar */
    let url: string = `/inventory/bulk-loading-validation`;
    apiConfig.headers!['Authorization'] = `Bearer ${token}`;

    return await axios
      .post(url, productsForm, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return error.response;
        }
      });
  }

  /** Recupera el inventario de productos en un template xlsx
   * @param {String} token
   * @returns {object} data - Template
   */
  async getInventoryTemplate(token: string): Promise<any> {
    const requestConfig: AxiosRequestConfig = {
      ...apiConfig,
      headers: {
        ...apiConfig.headers,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    };

    try {
      const response = await axios.get('/inventory/products-file', requestConfig);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Reserva productos del inventario
   *
   * @param {IReservation} reservation
   * @returns {IReservationResponse} response
   */
  async productsReservation(reservation: IReservation): Promise<AxiosResponse<IReservationResponse>> {
    /** URL a consultar */
    let url: string = '/inventory/reservation';

    return await axios
      .post(url, reservation, apiConfig)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 422) {
          return error.response;
        } else {
          console.error(error);
        }
      });
  }

  /**
   * Consulta un reserva
   *
   * @param {number} reservationId
   * @returns {AxiosResponse} response
   */
  async checkReservation(reservationId: number): Promise<AxiosResponse> {
    /** URL a consultar */
    const url: string = `/reservation/${reservationId}`;

    return await axios.get(url, apiConfig);
  }

  /**
   * Eliminar Reserva de Producto y devolver inventario
   *
   * @param {IReservation} reservation
   * @returns {status:number}
   */
  async productsDeleteReservation(idReservation: number): Promise<AxiosResponse<number>> {
    /** URL a consultar */
    let url: string = '/inventory/deleteReservation';

    return await axios
      .delete(url, {
        data: idReservation,
        ...apiConfig,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  }
}
