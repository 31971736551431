import React, { useLayoutEffect } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { RoutesDirections } from '@/data/libraries/Routes';

import { Provider } from 'react-redux';
import { store } from '@/ui/store/store';

import '@/ui/styles/index.scss';

import Layout from '@/ui/containers/Layout';

import NotFound from '@/ui/pages/NotFound';
import Library from '@/ui/pages/Library';

import UserInfo from '@/ui/pages/user/UserInfo';
import UserPassword from '@/ui/pages/user/UserPassword';

import Store from '@/ui/pages/Store';
import ProductDetails from '@/ui/pages/product/ProductDetails';

import AddProducts from '@/ui/pages/inventory/AddProducts';
import ProductsValidationInterface from '@/ui/pages/inventory/ProductsValidationInterface';
import Inventory from '@/ui/pages/inventory';
import MassiveCharge from '@/ui/pages/inventory/MassiveCharge';

import Cart from '@/ui/pages/sales/Cart';
import CartCheckOut from '@/ui/pages/sales/CartCheckOut';
import SalesHistory from '@/ui/pages/sales/SalesHistory';
import TransbankResponse from '@/ui/pages/sales/TransbankResponse';
import ErrorPage from '@/ui/pages/ErrorPage';
import Home from '@/ui/pages/Home';
import SearchOrder from '@/ui/pages/sales/SearchOrder';
import ConstructionPage from '@/ui/pages/ConstructionPage';
import TrackingOrderDetails from '@/ui/pages/sales/TrackingOrderDetails';

/** Componente de configuración de rutas y estados de la aplicación
 * @component
 */

const App = (): JSX.Element => {
  useLayoutEffect(() => {
    document.title = process.env.REACT_APP_TITLE || 'Default Title';
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <Layout>
            <Routes>
              <Route path={RoutesDirections.MAIN_ROUTE} element={<Home />} />
              <Route path={RoutesDirections.ERROR_ROUTE} element={<ErrorPage />} />
              <Route path={RoutesDirections.NOT_FOUND_ROUTE} element={<NotFound />} />
              <Route path={RoutesDirections.USER_INFO_ROUTE} element={<UserInfo />} />
              <Route path={RoutesDirections.CHANGE_PASSWORD_ROUTE} element={<UserPassword />} />
              <Route path={RoutesDirections.STORE_ROUTE} element={<Store />}>
                <Route path={RoutesDirections.STORE_BY_CATEGORY_ROUTE} element={<Store />} />
                <Route path={RoutesDirections.STORE_BY_PAGE} element={<Store />} />
              </Route>
              <Route path={RoutesDirections.PRODUCT_DETAILS_ROUTE} element={<ProductDetails />} />
              <Route path={RoutesDirections.CART_ROUTE} element={<Cart />} />
              <Route path={RoutesDirections.CHECK_OUT_ROUTE} element={<CartCheckOut />} />
              <Route path={RoutesDirections.SALES_HISTORY_ROUTE} element={<SalesHistory />} />
              <Route path={RoutesDirections.TRANSBANK_RESPONSE_ROUTE} element={<TransbankResponse />} />
              <Route path={RoutesDirections.INVENTORY_ROUTE} element={<Inventory />} />
              <Route path={RoutesDirections.MASSIVE_CHARGE_ROUTE} element={<MassiveCharge />} />
              <Route path={RoutesDirections.INSERT_VALIDATION_INTERFACE_ROUTE} element={<ProductsValidationInterface />} />
              <Route path={RoutesDirections.ADD_PRODUCTS_FORM_WIZARD_ROUTE} element={<AddProducts />} />

              <Route path={RoutesDirections.TRACKING_ROUTE} element={<SearchOrder />} />
              <Route path={RoutesDirections.TRACKING_ROUTE_DETAIL} element={<TrackingOrderDetails />} />

              <Route path={RoutesDirections.CONSTRUCTION_ROUTE} element={<ConstructionPage />} />
              <Route path={RoutesDirections.LIBRARY_ROUTE} element={<Library />} />
            </Routes>
          </Layout>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
