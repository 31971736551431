import { useState, useRef } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';

import { RootState } from '@/ui/store/store';
import { useAppSelector } from '@/ui/store/helperRedux';

import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';
import { refPasswordErrors } from '@/domain/genericValidations/usePasswordValidation';

import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import '@/ui/containers/userOptions/UserOptionsContainer/style.css';

import UserController from '@/controllers/UserController';

import { IChangePaswd } from '@/domain/interfaces/IChangePaswd';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';

import CloseIcon from '@/ui/assets/CloseIcon';
import BackIcon from '@/ui/assets/BackIcon';

import IconList from '@/ui/components/IconList';
import Title from '@/ui/components/Title';
import BackClose from '@/ui/components/BackClose';
import AlertModal from '@/ui/components/modals/AlertModal';
import InputForm from '@/ui/components/forms/InputForm';
import Breadcrumbs from '@/ui/components/Breadcrumbs';

/** Componente From para cambio de contraseña
 * @component
 */
const PasswordUserForm = (): JSX.Element => {
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /**
   * Función que redirige al usuario a la página de inicio.
   * @category Advanced Use
   */
  const goHome = (): void => {
    navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } });
  };

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Estado inicial del modal de alertas */
  const [alert, setAlert] = useState<boolean>(false);

  /** Hook manejo de formularios */
  const { handleSubmit, control, watch, setValue } = useForm<IChangePaswd>();

  /** Referencia al formulario cambio contraseña */
  const formRef = useRef<HTMLFormElement>(null);

  /** Status de usuario redux */
  const userLogued = useAppSelector((state: RootState) => state.userState);

  /** Funcion del controlador para cambiar contraseña*/
  const { updatePassword } = UserController();

  /** Maneja un state para mostrar los inputs relacionados a la contraseña actual   */
  const [inOldPassword, setOldPassword] = useState<boolean>(true);

  /** Maneja un state para mostrar los inputs relacionados a la contraseña nueva   */
  const [inNewPassword, setInNewPassword] = useState<boolean>(false);

  /** Mensaje inicial de error para la segunda parte del formulario */
  const messageInitialNewPasswd: string = 'Debes digitar la nueva contraseña';

  /** Declaración inicial de los botones como deshabilitado    */
  const [stateButton, setStateButton] = useState<string>('btn-primary-icon-standard disabled');

  /** Declaración inicial un input en su estado normal    */
  const [inputWithError, setInputWithError] = useState<boolean>(false);

  /** Declaración inicial de los mensajes de error como vacios    */
  const [messageError, setMessageError] = useState<string>('');

  /** Maneja un state ocultar o mostrar el password digitado  */
  const [showPasswordText, setPasswordText] = useState<boolean>(false);

  /** Muestra y Oculta la contraseña cada vez que se declara  */
  const switchPasswordText = (): void => setPasswordText(!showPasswordText);

  /** Declaración inicial del campo de contraseña como oculta    */
  const [passwordRules, setPasswordRules] = useState<boolean>(false);

  /** Define el estado inicial del <li> de validación de longitud de texto */
  const [lengthLi, setLengthLi] = useState<string>('');

  /** Define el estado inicial del <li> de validación de letras mayusculas y minusculas */
  const [minusMayusLi, setMinusMayusLi] = useState<string>('');

  /** Define el estado inicial del <li> de validación de numeros y textos */
  const [numberAndSymbolLi, setNumberAndSymbolLi] = useState<string>('');

  /** Define el estado inicial del <li> de validación que el texto no contenga el correo */
  const [emailLi, setEmailLi] = useState<string>('');

  /** Define el estado inicial del <li> de validación que el texto no contenga el correo */
  const [passwordLi, setPasswordLi] = useState<string>('');

  /** Define el estado inicial del <li> de validación que el texto no contenga el correo */
  const [repeatPasswordLi, setRepeatPasswordLi] = useState<string>('');

  /** Define el estado para una clase que muestra el icono de error  */
  const [infoIcon, setInfoIcon] = useState<string>('last-input-icon');

  /** Define el estado para el mensaje de el input de contraseña actual  */
  const [password, setPassword] = useState<string>('');

  /** Define el estado para el mensaje de el input de contraseña nueva  */
  const [newPassword, setNewPassword] = useState<string>('');

  /** Define el estado para el mensaje de el input de repetir contraseña  */
  const [newPasswordAgain, setNewPasswordAgain] = useState<string>('');

  /** Enciende el input relacionado a la contraseña actual*/
  const onOldPasswordInput = (): void => setOldPassword(true);

  /** Apaga el input relacionado a la contraseña actual*/
  const offOldPasswordInput = (): void => setOldPassword(false);

  /** Enciende el input relacionado a la contraseña nueva*/
  const onNewPasswordInput = (): void => setInNewPassword(true);

  /** Apaga el input relacionado a la contraseña nueva*/
  const offNewPasswordInput = (): void => setInNewPassword(false);

  /** Muestra las <li> de validación de contraseñas*/
  const onPasswordRules = (): void => setPasswordRules(true);

  /** Oculta las <li> de validación de contraseñas*/
  const offPasswordRules = (): void => setPasswordRules(false);

  /** Habilita un botón para avanzar a la siguiente fase*/
  const onButton = (): void => setStateButton('btn-primary-icon-standard');

  /** Deshabilita un botón para bloquear el avance a la siguiente fase*/
  const offButton = (): void => setStateButton('btn-primary-icon-standard disabled');

  /** Funcion para activar input,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Enciente el boton,
   */
  const validInput = (): void => {
    setMessageError('');
    setInfoIcon('last-input-icon');
    setInputWithError(false);
    onButton();
  };

  /** Funcion para etiquear un input con errores
   *  Coloca los mensajes como error (en caso de existir),
   *  Muestra los mensajes,
   *  Muestra los iconos de error,
   *  Coloca el input como error,
   *  Apaga el boton,
   */
  const errorInput = (): void => {
    setInputWithError(true);
    setInfoIcon('last-input-icon svg-complementary-2');
    offButton();
  };

  /** Funcion para etiquear un input vacio,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Apaga el boton,
   */
  const emptyInput = (): void => {
    setMessageError('');
    setInfoIcon('last-input-icon');
    setInputWithError(false);
    offButton();
  };

  /** Funcion para moverse de la contraseña anterior a la nueva contraseña
   *  Apaga Input de contraseña anterior,
   *  Enciende Input de nueva contraseña
   */
  const switchOldPasswordToNewPassword = (): void => {
    /** Limpia el formulario */
    cleanFormPassword();

    onNewPasswordInput();
    offOldPasswordInput();
    offPasswordRules();
    emptyInput();
  };

  /** Limpia el formulario */
  const cleanFormPassword = (): void => {
    setNewPasswordAgain('');
    setNewPassword('');
    setValue('newPassword', '');
    setValue('newPasswordAgain', '');
  };

  /** Funcion para moverse de la contraseña anterior a la nueva contraseña
   *  Apaga Input de contraseña anterior,
   *  Enciende Input de nueva contraseña
   */
  const switchNewPasswordToOldPassword = (): void => {
    /** Limpia el formulario */
    cleanFormPassword();

    onOldPasswordInput();
    offNewPasswordInput();
    validInput();
  };

  /**
   * Envia el formulario para validación y cambio de contraseña
   * @param {IClient} values - data del formulario
   * @param {boolean} toSend - indica si es disparado por un submit, puede ser también disparado por un onChange
   * */
  const onSubmit = async (values: IChangePaswd, toSend: boolean): Promise<void> => {
    /** Evalua si esta en la primera parte del formulario actual password */
    if (inOldPassword) {
      /** Uso del controlador UserController con la funcion updatePassword */
      const request = await updatePassword(values, true, userLogued.user.id!, userLogued.user.token!, toSend, userLogued.user.email!);

      /** Si el use case genera errores en la contraseña actual genera error o
       * continua a la siguente parte del formulario
       * */
      if (request.isError === true && request.inputError === 'password') {
        errorInput();
        /** El error para password actual viene en el primer elemento del arreglo */
        setMessageError(request.message[0]);
      } else {
        /** Si fue un submit se deshabilita el boton de envio y continua a la
         * siguiente parte del formulario
         */
        if (toSend) {
          switchOldPasswordToNewPassword();
          /** Establece error ya que aun no se ha digitado nada */
          errorInput();
          setMessageError(messageInitialNewPasswd);
        } else {
          /** Si no es un submit sino validaciones del evento onChange se habilita el boton submit */
          validInput();
        }
      }
    }

    /** Evalua si esta en la segunda parte del formulario nuevo password*/
    if (inNewPassword) {
      const request: IUserResponse = await updatePassword(values, false, userLogued.user.id!, userLogued.user.token!, toSend, userLogued.user.email!);
      /** Habilita los avisos de formato para la contraseña */
      onPasswordRules();

      /** Define todos los mensajes de error de formato de password como validos
       * para luego evaluar cuales debe dejar como error
       */
      setNumberAndSymbolLi('text-complementary-1');
      setMinusMayusLi('text-complementary-1');
      setLengthLi('text-complementary-1');
      setEmailLi('text-complementary-1');
      setPasswordLi('text-complementary-1');
      setRepeatPasswordLi('text-complementary-1');

      /** Si encuentra errores en los campos de nueva contraseña */
      if (request.isError && ['newPasswordAgain', 'newPassword'].includes(request.inputError ?? '')) {
        /** Evalua el tipo de error para activar el error correspondiente */
        /** Error de Numeros y Simbolos */
        if (request.message.includes(refPasswordErrors.errorTypeNumberAndSymbol)) {
          setNumberAndSymbolLi('text-complementary-2');
        }
        /** Error de Mayusculas y minusculas */
        if (request.message.includes(refPasswordErrors.errorTypeLowercaseCapitalLetters)) {
          setMinusMayusLi('text-complementary-2');
        }
        /** Error de tamaño */
        if (request.message.includes(refPasswordErrors.errorTypeLength)) {
          setLengthLi('text-complementary-2');
        }
        /** Error passwd igual al email */
        if (request.message.includes(refPasswordErrors.errorTypeEqualToEmail)) {
          setEmailLi('text-complementary-2');
        }
        /** Error nuevo passwd igual al actual */
        if (request.message.includes(refPasswordErrors.errorTypeEqualToLast)) {
          setPasswordLi('text-complementary-2');
        }
        /** Error doble digitación de nueva contraseña */
        if (request.message.includes(refPasswordErrors.errorTypeNotTheSame)) {
          setRepeatPasswordLi('text-complementary-2');
        }
        /** Imprime error adicional si existe */
        setMessageError(refPasswordErrors[request.message[0]]);
        /** Establece formulario con errores */
        errorInput();
      } else {
        offPasswordRules();
        /** Des-habilita los avisos de formato para la contraseña */
        if (values.newPassword!.length > 0) {
          /** Establece formulario sin errores */
          validInput();
          setInfoIcon('last-input-icon');
        } else {
          /** Establece formulario con errores */
          errorInput();
        }
        /** Fue un submit y ademas pasó todas las validaciones quiere decir en este punto
         * que la contraseña se cambio satisfactoriamente
         */
        if (toSend) {
          /** Muestra mensaje de confirmación */
          setMessageError('Contraseña Exitosa');
          setAlert(true);
          cleanFormPassword();
          /** Reenvia a información de usuario */
          setTimeout(() => {
            navigate(RoutesDirections.USER_INFO_ROUTE, { state: { originURL: location.pathname } });
          }, 2000);
        }
      }
    }
  };

  /**
   * Realiza el submit
   * @param {boolean} toSend - indica si se ejecuto un submit si es true
   *
   */
  const onSubmitWithFlag =
    (toSend: boolean) =>
    (data: IChangePaswd): Promise<void> =>
      onSubmit(data, toSend);

  /** Se ejecuta en el onChange de los input, genera un false en toSend para no enviar el formulario  */
  const handleInputChange = (): void => {
    /** Extrae la data del formulario con watch() del useForm */
    const formData = watch();
    onSubmit(formData, false);
  };

  const BACK = 'Atrás';

  return (
    <>
      <Container fluid>
        <Row className={` ${!isMobile && 'ps-3 pe-5'}`}>
          {isMobile && (
            <BackClose classMain='justify-content-between py-2'>
              {inNewPassword ? (
                <Col className='d-flex align-items-center' onClick={switchNewPasswordToOldPassword}>
                  <BackIcon classMain='btn-icon-light' />
                  <span className='ps-3 text-tertiary-1'>Contraseña Actual</span>
                </Col>
              ) : (
                <Breadcrumbs />
              )}
              <CloseIcon classMain='btn-icon-light' onClick={goHome} />
            </BackClose>
          )}
          <Col xs={12} className='p-0'>
            <ul id='user-title' className='list-unstyled text-primary-3'>
              <Title>
                <IconList iconName='ico-password' title='Cambiar contraseña' classMain='fw-bold' classTitle='h5 fw-bold' />
                <span className='text-secondary-3'>Para cambiar tu contraseña verifica tu actual contraseña y crea una nueva</span>
              </Title>
            </ul>

            <ul id='password-user-form' className='list-unstyled'>
              <Row className='pt-2'>
                <Col xs={12}>
                  <Form ref={formRef} onSubmit={handleSubmit(onSubmitWithFlag(true))} className='svg-tertiary-1'>
                    <div className={inOldPassword ? 'display' : 'invisible position-absolute'}>
                      <Controller
                        control={control}
                        name='password'
                        render={({ field: { ref } }) => (
                          <InputForm
                            className='mb-3'
                            inputRef={ref}
                            label='Contraseña Actual'
                            classLabel='p-regular-medium'
                            value={password}
                            type={showPasswordText ? 'text' : 'password'}
                            onChange={(value) => {
                              setValue('password', value.target.value);
                              setPassword(value.target.value);
                              return handleInputChange();
                            }}
                            withErrors={inputWithError}
                            messageError={messageError}
                            lastIconClass={infoIcon}
                            lastIconClicked={switchPasswordText}
                            lastIconName={showPasswordText ? 'ico-hide' : 'ico-show'}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        )}
                      />
                      <Col className='pb-5 form-text'>
                        <span className='link-text-standard'>Olvide mi contraseña.</span>
                      </Col>
                    </div>
                    <div className={inNewPassword ? 'display' : 'invisible position-absolute'}>
                      <Controller
                        control={control}
                        name='newPassword'
                        render={({ field: { ref } }) => (
                          <InputForm
                            className='mb-3'
                            inputRef={ref}
                            label='Nueva contraseña'
                            classLabel='p-regular-medium'
                            value={newPassword}
                            type={showPasswordText ? 'text' : 'password'}
                            onChange={(value) => {
                              setValue('newPassword', value.target.value);
                              setNewPassword(value.target.value);
                              return handleInputChange();
                            }}
                            withErrors={inputWithError}
                            messageError={messageError}
                            lastIconClass={infoIcon}
                            lastIconClicked={switchPasswordText}
                            lastIconName={showPasswordText ? 'ico-hide' : 'ico-show'}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name='newPasswordAgain'
                        render={({ field: { ref } }) => (
                          <InputForm
                            className='mb-3'
                            inputRef={ref}
                            label='Repita nueva contraseña'
                            classLabel='p-regular-medium'
                            value={newPasswordAgain}
                            type={showPasswordText ? 'text' : 'password'}
                            onChange={(value) => {
                              setValue('newPasswordAgain', value.target.value);
                              setNewPasswordAgain(value.target.value);
                              return handleInputChange();
                            }}
                            withErrors={inputWithError}
                            lastIconClass={infoIcon}
                            lastIconClicked={switchPasswordText}
                            lastIconName={showPasswordText ? 'ico-hide' : 'ico-show'}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        )}
                      />

                      {passwordRules && (
                        <div>
                          <div className='mb-3'>
                            <p className='text-black'>Crea una contraseña que:</p>
                            <ul className='text-secondary-3'>
                              <li className={`fw-light ${lengthLi}`}>Contenga de 8 a 16 caracteres.</li>
                              <li className={`fw-light ${minusMayusLi}`}>Contenga tanto letras minúsculas (a-z) como mayúsculas (A-Z).</li>
                              <li className={`fw-light ${numberAndSymbolLi}`}>Contenga al menos un número (0-9) y un símbolo ($@!%*?&#.()_).</li>
                              <li className={`fw-light ${emailLi}`}>No sea tu dirección de correo electrónico</li>
                              <li className={`fw-light ${passwordLi}`}>La contraseña anterior y la nueva no pueden ser iguales</li>
                              <li className={`fw-light ${repeatPasswordLi}`}> Nueva contraseña y Repetir nueva contraseña son iguales</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='d-grid gap-2'>
                      <Button className={`${stateButton} p-large-medium`} type='submit'>
                        {inOldPassword ? 'CONTINUAR' : 'CAMBIAR CONTRASEÑA'}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </ul>

            <Row className='g-0'>
              <Col xs={12} className='form-text text-center'>
                <span className='link-text-standard'>Privacidad y confidencialidad de la información</span>
              </Col>
            </Row>

            {inNewPassword && (
              <Row className='g-0 px-2 mt-2 cursor-hand' onClick={switchNewPasswordToOldPassword}>
                <Col xs={12} className='form-text'>
                  <big className='link-text-standard'>{BACK}</big>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>

      {alert && <AlertModal hideAlert={() => setAlert(false)} type='success' hide={5000} content='¡Tu contraseña se ha cambiado exitosamente!' />}
    </>
  );
};

export default PasswordUserForm;
