import AttributesRepository from '@/data/repositories/AttributesRepository';
import { IAttributes } from '@/domain/interfaces/IAttributes';

/** Representa el controlador que maneja los eventos de los atributos
  * @returns {object} Funciones del controlador
*/
const AttributesController = () => {
  
    /** Solicita los atributos por categoria asociada
      * @param {string} token - token valido
      * @returns {IAttributes} attributes - atributos
    */
    const getAttributesByCategory = async (token:string = '', categoryId:number): Promise<IAttributes[]> => {

        /** Instancia del Repository
        * @const {AttributesRepository}
        */
        const attributesRepository = AttributesRepository.instance;
        const result = await attributesRepository.getAttributesByCategory(token, categoryId);
        const attributes: IAttributes[] = result.data;

        //Ordena los valores por la posición antes de retornar
        attributes.forEach(attribute => {
          if (attribute.values) {
              attribute.values.sort((a, b) => a.position - b.position);
            }
        });

        return attributes;
    }

    /**
     * retorna la funciones del controlador
     */
    return { getAttributesByCategory };
};

export default AttributesController;
