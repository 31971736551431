/** Valida el formato del email
 * @param {string} email
 * @returns {boolean}
 */
const useEmailValidation = (email: string): boolean => {
  /**
   * Almacena un patron para validar formato de email
   * @const {RegExp};
   */
  const expression: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  /**
   * Almacena el resultado de evaluar la expresion
   * @const {boolean}
   */
  const result: boolean = expression.test(email);

  return result;
};

export default useEmailValidation;
