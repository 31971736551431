import { PropsWithChildren, ReactNode } from 'react';

import { Container, Image, Row, Col } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';

interface PropsSignOutForm {
  /** mensaje del componente */
  message?: string;
  /** Evento al darle al botón de confirmar */
  submit?: (params: any) => ReactNode | void;
  /** Evento al darle al botón de cancelar */
  cancel?: (params: any) => ReactNode | void;
}

/** Componente para salir y regresar
 * @component
 */
const SignOutForm = ({ submit, cancel, message = '' }: PropsWithChildren<PropsSignOutForm>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  const logOutIcon: string = `${URL_IMAGES}/client/log-out.png`;

  return (
    <Container fluid className='p-0'>
      <Row className='g-0 pt-1'>
        <Col xs={{ span: 4, offset: 4 }}>
          <Image
            src={logOutIcon}
            alt='log-out-icon'
            className='w-100'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        </Col>
      </Row>

      <Row className='g-0'>
        <Col xs={12} className='pb-3'>
          <div className='p-3 text-center'>
            {' '}
            <span className='p-large-medium'>{message}</span>
          </div>
          <TwoButtonsGroup
            firstButtonClass='btn-secondary-text-standard'
            firstButtonText='Aceptar'
            firstButtonClick={submit}
            secondButtonClass='btn-primary-text-standard'
            secondButtonText='Cancelar'
            secondButtonClick={cancel}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SignOutForm;
