import { PropsWithChildren } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';

/**
 * Componente para mensajes a iconos u otros elementos
 * @component
*/

interface PropsGuideTip {
    position: 'bottom' | 'top' | 'left' | 'right';
    classMain?: string;
    delay?: OverlayDelay
    message: string | boolean;
}

const GuideTip = ({ position, classMain = '', message, delay = { show: 250, hide: 0 }, children }: PropsWithChildren<PropsGuideTip>) => {
    return (
        <OverlayTrigger
            placement={position}
            delay={delay}
            overlay={
                message ?
                    <Tooltip id='button-tooltip'>
                        {message}
                    </Tooltip>
                    :
                    <></>
            }
        >
            <span className={classMain}>
                {children}
            </span>
        </OverlayTrigger>
    );
}

export default GuideTip;