import React, { useEffect, useState, useCallback } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';
import { saveAs } from 'file-saver';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import type { Location, NavigateFunction } from 'react-router-dom';

import { Button, Col, Row, Image } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';
import '@/ui/pages/inventory/style.css';

import BulkLoadController from '@/controllers/BulkLoadController';
import InventoryController from '@/controllers/InventoryController';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IProductForm } from '@/domain/interfaces/IProduct';

import lastPageName from '@/domain/utils/lastPageName';
import downloadTxt from '@/domain/utils/downloadTxt';
import { errorMessageFormatter } from '@/domain/utils/errorFormatter';

import ErrorImage from '@/ui/assets/ErrorImage';

import StepIndications from '@/ui/components/StepIndications';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import DragAndDrop from '@/ui/components/DragAndDrop';
import AlertModal from '@/ui/components/modals/AlertModal';

import ManagementOptionsContainer from '@/ui/containers/managementOptions/ManagementOptionsContainer';

import { chargeExcelTemplate, titlesExcelTemplate } from '@/data/libraries/chargeExcelTemplate';
import MainTitle from '@/ui/components/MainTitle';
import GifModal from '@/ui/components/modals/GifModal';

/** Propiedades de archivo Excel */
interface ExcelRow {
  row: string;
  [key: string]: string;
}

/** Componente carga masiva
 * @component
 */
const MassiveCharge = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Controlador de carga masiva */
  const { bulkLoad, bulkLoadBackValidation, validateTemplateFormat } = BulkLoadController();

  /** Controlador de inventario */
  const { getInventoryTemplate } = InventoryController();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Mensaje cantidad de errores */
  const [counterError, setCounterError] = useState<number>(0);

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: 'Inventario', url: RoutesDirections.INVENTORY_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /** INICIO INTERFACE DE DESCARGA */

  /** Maneja un state para mostrar los inputs relacionados al email al cargar la web    */
  const [inDownloadInterface, setInDownloadInterface] = useState<boolean>(true);

  /** Maneja la alerta tipo danger si existen problemas al descargar el template del inventario */
  const [alertModal, setAlertModal] = useState<boolean>(false);

  /** Maneja la alerta tipo danger si existen problemas de formato al cargar el template del inventario */
  const [alertModalTemplate, setAlertModalTemplate] = useState<boolean>(false);

  /**Gif de imagen URL */
  const DOWNLOAD_IMAGE_URL: string = `${URL_IMAGES}/client/doc-download.png`;
  /**Modal de loading */
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);

  /** Estado inicial del modal de alertas de descarga de orden*/
  const [alertDownload, setAlertDownload] = useState<boolean>(false);
  /** Evento que muestra y oculta el modal de carga */
  const showLoading = (): void => {
    setShowDownloadModal(!showDownloadModal);
  };

  /** Función para descargar el archivo */
  const handleDownload = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    setShowDownloadModal(true);
    /** Solicita por medio del controlador el template file del inventario */
    const response: Blob | null = await getInventoryTemplate(userLogued.user?.token ?? '');
    /**if que controla si la respuesta es null o no para mostrar el modal de alerta de error de descarga o descargar el archivo respectivamente */
    if (response === null) {
      setShowDownloadModal(false);
      setAlertModal(true);
    } else {
      setShowDownloadModal(false);
      setAlertModal(false);
      /** Descarga el archivo */
      saveAs(response, 'plantilla_carga_masiva.xlsm');
    }
  };

  /** FIN INTERFACE DE DESCARGA */

  /** Función para cambiar de interface */
  const handleChangeInterface = (e: React.MouseEvent): void => {
    e.preventDefault();
    setInDownloadInterface(!inDownloadInterface);
    setInUploadInterface(!inUploadInterface);
  };

  /** Función para volver a la interface anterior */
  const handleBackButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    handleChangeInterface(e);
  };

  /** INICIO INTERFACE DE CARGA */

  /** Maneja un state para ocultar los inputs relacionados al password al cargar la web    */
  const [inUploadInterface, setInUploadInterface] = useState<boolean>(false);

  /** State para guardar el archivo */
  const [file, setFile] = useState<File | null>(null);

  /** Función que actualiza el archivo */
  const updateFile = (newFile: File | null) => {
    setFile(newFile);
  };

  /** UseEffect para actualizar el archivo cada vez que cambia */
  useEffect((): void => {
    updateFile(file);
    setErrorArray([]);
    SetProductPresenter([]);
  }, [file]);

  /** State para guardar las filas  */
  const [rows, setRows] = useState<ExcelRow[]>([]);

  /** Linea inicial de la data en el archivo de variantes */
  const productsRange = 7;

  /** Linea de los títulos del template */
  const ROW_OF_TITLES = 6;

  /** Maneja el evento al cambiar del input */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    /** Guarda el archivo */
    const selectedFile = e.target.files?.[0] ?? null;
    setFile(selectedFile);
  };

  /** Función para cargar documento */
  const handleChargeFile = () => {
    if (file) {
      const fileReader = new FileReader();

      /** Lector de archivo */
      fileReader.onload = async (e: ProgressEvent<FileReader>) => {
        /** Crea un buffer con el archivo */
        const data = new Uint8Array(e.target?.result as ArrayBuffer);

        /** Lee el buffer creado y obtenemos un libro de trabajo */
        const workbook: WorkBook = read(data, { type: 'array' });

        /** Obtenemos la hoja de variantes */
        const products: WorkSheet = workbook.Sheets[workbook.SheetNames[0]];

        /** Genera objeto JSON de los títulos  */
        const productsTitles = utils.sheet_to_json<ExcelRow>(products, {
          header: titlesExcelTemplate,
          range: ROW_OF_TITLES,
          defval: '',
        });

        /** Se valida el formato del template */
        const validTemplate: boolean = validateTemplateFormat(productsTitles);
        if (!validTemplate) {
          setShowModal(false);
          setAlertModalTemplate(true);
        } else {
          setShowModal(true);
          /** Genera objeto JSON de la hoja seleccionada */
          const productsData = utils.sheet_to_json<ExcelRow>(products, {
            header: chargeExcelTemplate,
            range: productsRange,
            defval: '',
          });

          /**Agrega el número de fila a cada objeto en productsData */
          productsData.forEach((product, index) => {
            product.row = (index + productsRange + 1).toString();
          });

          /** Guardamos las filas en el state si se desea renderizar */
          setRows(productsData);

          /**Controlador de carga masiva: valida y convierte el excel al presentador de productos */
          const { errorsBulkLoad, productsBulkLoad, allProductsBulkLoad } = bulkLoad(productsData, userLogued.user?.token ?? '');

          //Controlador validar con el back los productos base y sus variantes que pasaron las validaciones del front
          const { errorsBulkLoadBack, productsBulkLoadBack } = await bulkLoadBackValidation(
            productsBulkLoad,
            allProductsBulkLoad,
            userLogued.user?.token ?? '',
          );

          //Si el back encuentra errores se agregan a los ya reportados por el front
          if (errorsBulkLoadBack.length > 0) {
            errorsBulkLoad.push(...errorsBulkLoadBack);
          }

          // Ordena los errores por linea y genera el string de errores
          errorsBulkLoad.sort((a, b) => a.row - b.row);

          const errorsBulkLoadString: string[] = [];
          errorsBulkLoad.forEach((err) => {
            errorsBulkLoadString.push(err.errorsBulkLoad);
          });

          /** si existen errores en el archivo se muestran en el modal de errores */
          if (errorsBulkLoadString.length > 0) {
            // se eliminan los errores duplicados
            const uniqueError = new Set(errorsBulkLoadString);
            // se convierte el set a un array con los errores únicos
            const uniqueErrorArray = Array.from(uniqueError);
            // se actualiza el contador de errores
            setCounterError(uniqueErrorArray.length);
            // se actualiza el state con los errores únicos
            setErrorArray([...uniqueErrorArray]);
            // se oculta el modal de carga si existen errores
            setShowModal(false);
            // se muestra el modal de errores si existen errores
            setShowErrorModal(true);
          }
          /** si existen productos en el archivo se muestran en el presentador de productos */
          if (productsBulkLoadBack.length > 0) {
            SetProductPresenter([...productsBulkLoadBack]);
          } else if (errorsBulkLoadString.length === 0) {
            setShowModal(false);
            setAlertModalTemplate(true);
          }
        }
      };
      /** se lee el archivo como un array buffer para poder leerlo con el fileReader y obtener el libro de trabajo de excel con la data */
      fileReader.readAsArrayBuffer(file);
    } else {
      setShowModal(false);
      setShowErrorModal(true);
    }
  };

  /**errorArray es un arreglo que contiene los errores encontrados en el archivo y setErrorsArray es la función que actualiza el arreglo*/
  const [errorArray, setErrorArray] = useState<string[]>([]);

  /** Creamos un arreglo para el presentador del objeto*/
  const [productPresenter, SetProductPresenter] = useState<IProductForm[]>([]);

  /** Estado inicial del modal opciones de usuario */
  const [showModal, setShowModal] = useState<boolean>(false);

  /** Evento que muestra y oculta el modal de opciones de usuario */
  const handleShowModal = (): void => {
    setShowModal(!showModal);
  };

  /** Link de redirección */
  const handleNavigate = useCallback(() => {
    navigate(RoutesDirections.INSERT_VALIDATION_INTERFACE_ROUTE, { state: { originURL: location.pathname, productPresenter, errorArray } });
  }, [productPresenter, errorArray, navigate]);

  /** UseEffect para actualizar el archivo cada vez que cambia */
  useEffect(() => {
    setTimeout(() => {
      showModal && productPresenter.length > 0 && errorArray.length === 0 && handleNavigate();
    }, 1000);
  }, [showModal, errorArray, productPresenter, handleNavigate]);

  /** Estado inicial del modal de error */
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  /**Función que limpia el documento del registro */
  const resetFile = () => {
    setFile(null);
    handleErrorShowModal();
  };

  /** Evento que muestra y oculta el modal de error */
  const handleErrorShowModal = (): void => {
    setShowErrorModal(!showErrorModal);
    setFile(null);
  };

  /**
   * FIN DE TEXTOS DE CARGA MASIVA
   */

  /** FIN INTERFACE DE CARGA */

  /**CONSTANTES DE TEXTO */
  const DOWNLOAD_MAIN_TITLE: string = 'Carga Masiva';
  const DOWNLOAD_MAIN_DESCRIPTION: string = 'Para cargar masivamente el inventario de tus productos realiza los siguientes pasos:';

  const STEP_1_TITLE: string = 'Descargue la plantilla de Excel de carga masiva.';
  const STEP_1_DESCRIPTION: string = 'De clic en el botón inferior que dice  DESCARGAR PLANTILLA DE CARGA MASIVA y guárdelo en su computadora.';

  const STEP_2_TITLE: string = 'Registre su inventario en el archivo descargado.';
  const STEP_2_DESCRIPTION: string =
    'Al abrir el archivo de Excel encontrara dos páginas una página de productos base y otra página de variantes de productos base, recuerde que en cada página del archivo de Excel carga masiva encontrara las indicaciones para registrar correctamente la información de sus productos.';

  const STEP_3_TITLE: string = 'Suba la plantilla de carga masiva.';
  const STEP_3_DESCRIPTION: string =
    'Cuando termines de registrar tus productos en el archivo de excel valida la información registrada, guarda los cambios, y da clic en el botón que dice SUBIR PLANTILLA DE CARGA MASIVA.';

  const DOWNLOAD_ERROR_MESSAGE: string = 'El archivo presento un problema en la descarga.';

  const UPLOAD_MAIN_TITLE: string = 'Subir plantilla de excel para publicar inventario';
  const UPLOAD_MAIN_DESCRIPTION: string =
    'Adjunta el archivo de Excel de la plantilla de carga masiva o arrastra el archivo al espacio de carga, y da clic en cargar archivo.  ';

  const UPLOAD_SUCCESS_MESSAGE: string = 'Tu archivo se ha subido, da clic en cargar archivo.';
  const UPLOAD_TYPE_ERROR_MESSAGE: string = 'El archivo que intentas cargar no es un archivo Excel, intenta con un archivo (.xls, .xlsx, .xlsm)';
  const UPLOAD_DOCUMENT_ERROR_MESSAGE: string =
    'El archivo adjunto esta vacío o no corresponde al formato esperado. Por favor revíselo e intente nuevamente.';

  const DOWNLOAD_BUTTON_TEXT: string = 'DESCARGAR PLANTILLA DE CARGA MASIVA';
  const UPLOAD_BUTTON_TEXT: string = 'SUBIR PLANTILLA DE CARGA MASIVA';
  const RELOAD_BUTTON_TEXT: string = 'VOLVER A CARGAR';
  const CONTINUE_BUTTON_TEXT: string = 'CONTINUAR';

  const MODAL_ERROR_TEXT_1: string = 'Oops! Hemos encontrado ';
  const MODAL_ERROR_TEXT_2: string = 'error';
  const MODAL_ERROR_TEXT_3: string = 'es ';
  const MODAL_ERROR_TEXT_4: string =
    'en el archivo de carga masiva. Puedes seguir adelante y ver los detalles de los errores, o intentar cargar un nuevo archivo';

  const ALL_ERROR_MODAL_TEXT =
    'Todos los productos adjuntos en el documento presentan error, se recomienda adjuntar las correcciones pertinentes para poder continuar con el proceso de carga.';

  const LOADING_TEXT: string = 'Cargando archivo, espera un momento';
  const DONWLOAD_TEXT: string = 'Descargando archivo, espera un momento';
  const DOWNLOAD_ERRORS_TEXT: string = 'Descargar errores';
  const LOADING_IMAGE_URL: string = `${URL_IMAGES}/client/doc-loading.png`;
  const ERROR_IMAGE_URL: string = `${URL_IMAGES}/client/doc-error.png`;

  const ERROR_DOWNLOAD_MESSAGE: string = 'Error al descargar el Excel, inténtalo nuevamente';

  return (
    <>
      <ManagementOptionsContainer
        type='management-inventory'
        breadcrumbs={breadcrumbsList}
        breadcrumbsFunction={inUploadInterface ? handleBackButtonClick : undefined}
        classMain='pb-5'>
        {inDownloadInterface && (
          <>
            <Col xs={12}>
              <MainTitle boldTitle={DOWNLOAD_MAIN_TITLE} detailsText={DOWNLOAD_MAIN_DESCRIPTION} />
            </Col>
            <Col xs={9} className='mt-4 ms-4'>
              <StepIndications indicator={1} title={STEP_1_TITLE} details={STEP_1_DESCRIPTION} />
              <StepIndications indicator={2} title={STEP_2_TITLE} details={STEP_2_DESCRIPTION} />
              <StepIndications indicator={3} title={STEP_3_TITLE} details={STEP_3_DESCRIPTION} />
              <TwoButtonsGroup
                firstButtonIcon='ico-download'
                firstButtonText={DOWNLOAD_BUTTON_TEXT}
                firstButtonClass='btn-secondary-icon-small'
                firstButtonIconClass='svg-primary-1'
                firstButtonClick={handleDownload}
                secondButtonIcon='ico-upload'
                secondButtonText={UPLOAD_BUTTON_TEXT}
                secondButtonClass='btn-primary-icon-small'
                secondButtonIconClass='svg-primary-4'
                secondButtonClick={handleChangeInterface}
              />
            </Col>
            {alertModal && <AlertModal hideAlert={() => setAlertModal(false)} type='danger' content={DOWNLOAD_ERROR_MESSAGE} />}
          </>
        )}

        {inUploadInterface && (
          <>
            <MainTitle boldTitle={UPLOAD_MAIN_TITLE} detailsText={UPLOAD_MAIN_DESCRIPTION} />
            <Col xs={12} className='my-4'>
              <DragAndDrop
                fileType='excel'
                size='264px'
                classMain='border-dashed border border-1'
                successMessage={UPLOAD_SUCCESS_MESSAGE}
                errorMessage={UPLOAD_TYPE_ERROR_MESSAGE}
                handleFileChange={handleFileChange}
                handleChargeFile={handleChargeFile}
                cancelButton={handleChangeInterface}
                fileAttached={file}
                updateFile={updateFile}
              />
            </Col>
          </>
        )}
      </ManagementOptionsContainer>
      {alertModalTemplate && <AlertModal hideAlert={() => setAlertModalTemplate(false)} type='danger' content={UPLOAD_DOCUMENT_ERROR_MESSAGE} />}
      <GifModal
        showModal={showErrorModal}
        handleShowModal={handleErrorShowModal}
        buttons={
          <Row className='d-flex align-items-center'>
            <Col className='text-center'>
              <div>
                <NavLink
                  to={'#'}
                  className='link-text-standard p-title-medium'
                  onClick={() => downloadTxt(errorMessageFormatter(errorArray), 'errores_carga_masiva.txt')}>
                  {DOWNLOAD_ERRORS_TEXT}
                </NavLink>
              </div>
            </Col>
            <Col className='text-center'>
              <Button className='btn-secondary-text-small px-4 my-3' onClick={resetFile}>
                {RELOAD_BUTTON_TEXT}
              </Button>
            </Col>
            <Col className='text-center'>
              <Button className={`btn-primary-text-small px-5 my-3 ${productPresenter.length === 0 && 'disabled'}`} onClick={handleNavigate}>
                {CONTINUE_BUTTON_TEXT}
              </Button>
            </Col>
          </Row>
        }
        image={
          <Image
            src={ERROR_IMAGE_URL}
            className='mb-3'
            width={141}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={
          <>
            {productPresenter.length === 0 ? (
              <span className='p-large-bold mt-3'>{ALL_ERROR_MODAL_TEXT}</span>
            ) : (
              <div>
                <span className='p-large-bold mt-3'>{MODAL_ERROR_TEXT_1}</span>
                <span className='p-large-bold mt-3 text-complementary-2'>{`${counterError} ${MODAL_ERROR_TEXT_2}${
                  counterError > 1 ? MODAL_ERROR_TEXT_3 : ' '
                }`}</span>
                <span className='p-large-bold mt-3'>{MODAL_ERROR_TEXT_4}</span>
              </div>
            )}
          </>
        }
      />

      <GifModal
        showModal={showModal}
        handleShowModal={handleShowModal}
        image={
          <Image
            src={LOADING_IMAGE_URL}
            width={191}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{LOADING_TEXT}</span>}
      />

      <GifModal
        showModal={showDownloadModal}
        handleShowModal={showLoading}
        image={
          <Image
            src={DOWNLOAD_IMAGE_URL}
            width={200}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{DONWLOAD_TEXT}</span>}
      />
      {alertDownload && <AlertModal hideAlert={() => setAlertDownload(false)} type={'danger'} hide={6000} content={ERROR_DOWNLOAD_MESSAGE} />}
    </>
  );
};

export default MassiveCharge;
