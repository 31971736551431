import { PropsWithChildren, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import type { NavigateFunction, Location } from 'react-router-dom';

import { RoutesDirections } from '@/data/libraries/Routes';
import { StatusCodes } from '@/data/libraries/Status';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';
import useReservation from '@/ui/hooks/useReservation';
import useStorageListener from '@/ui/hooks/useStorageListener';
import { useCreateCartClientDetailsForm } from '@/ui/hooks/useCreateCartClientDetailsForm';

import { Button, Col, Container, Modal, Row, Image } from 'react-bootstrap';

import { RootState } from '@/ui/store/store';
import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { changeLoginState } from '@/ui/store/slices/showLoginSlice';

import CartController from '@/controllers/CartController';

import { ICart } from '@/domain/interfaces/ICart';

import ErrorImage from '@/ui/assets/ErrorImage';

import NavMobile from '@/ui/components/mobile/NavMobile';
import ModalBlockedByCheckOut from '@/ui/components/modals/ModalBlockedByCheckOut';

import useSessionExpiration from '@/ui/hooks/useSessionExpiration';
import ToExpiredModal from '@/ui/components/modals/ToExpiredModal';

import LoginForm from '@/ui/components/user/LoginForm';
import ButtonScrollTop from '@/ui/components/ButtonScrollTop';

import Footer from '@/ui/containers/Footer';
import Header from '@/ui/containers/Header';

import ErrorPage from '@/ui/pages/ErrorPage';
import CartCheckOut from '@/ui/pages/sales/CartCheckOut';

/**
 * Layout general de la aplicación, según el tamaño de la pantalla renderiza el layout correspondiente
 */
const Layout = ({ children }: PropsWithChildren) => {
  /** Manipula states de Redux */
  const dispatch = useAppDispatch();

  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Controlador de carrito */
  const { updateClientInCart } = CartController();

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Si el location.state es null generará por defecto la palabra 'Atrás'  */
  if (location.state === null) {
    location.state = 'Atrás';
  }

  /** State Redux que muestra/oculta modal de login */
  const showLogin: boolean = useAppSelector((state) => state.showLogin.value);
  /** Evento que muestra/oculta el modal de login */
  const handleShowLogin = () => {
    dispatch(changeLoginState());
  };

  const showToExpiredModal: boolean = useAppSelector((state) => state.showSessionModal.value);
  /** Hook que evalúa el tiempo de sesión para mostrar modal */
  useSessionExpiration();

  /**Estado Redux del carrito */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /**Consulta por cambios en el localStorage*/
  const storageChange = useStorageListener();

  /**Estado de alerta de cambios en el LocalStorage */
  const [showStoreChangeAlert, setShowStoreChangeAlert] = useState<boolean>(false);

  /**Estado de alerta que indica que la pagina esta bloqueada por CheckOut */
  const [blockedByCheckOutAlert, setBlockedByCheckOutAlert] = useState<boolean>(false);

  /**Estado de alerta de Modal cuando hay reserva*/
  const [modalExitConfirmation, setModalExitConfirmation] = useState<boolean>(false);

  /** Rutas que están exentas de la regla de bloqueo de checkout */
  const allowedRoutes: string[] = [RoutesDirections.TRANSBANK_RESPONSE_ROUTE, RoutesDirections.CHECK_OUT_ROUTE];

  /**Constante que verifica si la interfaz esta bloqueada por LocalStorage */
  const reservationIdExist: boolean = !!cartProducts.reservationId;

  /**Constante que determina si la interfaz esta bloqueada */
  const IsBlockedByCheckout: boolean = reservationIdExist && !allowedRoutes.includes(location.pathname);

  /** Hook para las reservas */
  const reservationHook = useReservation();

  /** Hook para traer el objeto vacío del cliente en el carrito */
  const emptyCartClientObject = useCreateCartClientDetailsForm();

  /** Función que maneja las alertas */
  const handleCartAlertChanges = () => {
    !cartProducts.reservationId && setShowStoreChangeAlert(storageChange.cartChange);
    !cartProducts.reservationId && storageChange.cartChange && setBlockedByCheckOutAlert(false);
  };

  /** Función que maneja las alertas */
  const handleBlockedByCheckOutAlertChanges = () => {
    setShowStoreChangeAlert(false);
    setBlockedByCheckOutAlert(storageChange.blockedByCheckout);
  };

  /**Función que chequea el estado de la reserva */
  const handleCheckReservation = async (): Promise<boolean> => {
    let processedReservation: boolean = false;
    const response: number = await reservationHook.checkReservation();
    if (response === StatusCodes.PROCESSING) {
      handleDeleteReservation();
      processedReservation = true;
    }
    return processedReservation;
  };

  /**Función que redirecciona a Checkout */
  const handleToCheckout = () => {
    navigate(RoutesDirections.CHECK_OUT_ROUTE, { state: { originURL: location.pathname } });
    setModalExitConfirmation(false);
  };

  /**Función que elimina la reserva y oculta el modal */
  const handleDeleteReservation = async () => {
    const isReservedDeleted: boolean = await reservationHook.deleteReservation();

    if (isReservedDeleted) {
      const updatedClientDetails = emptyCartClientObject;
      const updatedCartProducts = { ...cartProducts, clientDetails: updatedClientDetails };
      updateClientInCart(updatedCartProducts.clientDetails);
      setModalExitConfirmation(false);
    }
  };

  /**Si se ingresa al Layout con la página bloqueada navegará directamente a checkout */
  useEffect(() => {
    handleCheckReservation().then((result) => {
      if (!result && IsBlockedByCheckout) {
        navigate(RoutesDirections.CHECK_OUT_ROUTE, { state: { originURL: location.pathname } });
      }
    });
  }, []);

  /**UseEffect que ejecuta la función cuando el local storage cambia */
  useEffect(() => {
    storageChange.blockedByCheckout && handleBlockedByCheckOutAlertChanges();
    storageChange.cartChange && handleCartAlertChanges();
    storageChange.userChange && storageChange.handleRefresh();
  }, [cartProducts, storageChange]);

  /**useEffect que  muestra modal de confirmación si hay errores */
  useEffect(() => {
    handleCheckReservation().then((result) => {
      if (!result && IsBlockedByCheckout) {
        setModalExitConfirmation(true);
        storageChange.cartChange && setModalExitConfirmation(false);
      } else {
        setModalExitConfirmation(false);
      }
    });
  }, [location.pathname]);

  /**Constantes de Texto */
  const MODAL_CART_UPDATED_TEXT =
    'Se ha actualizado tu carrito de compras desde otra interfaz. Haz clic en "ACEPTAR" para aplicar los cambios en esta pantalla.';
  const MODAL_CART_UPDATED_URL: string = `${URL_IMAGES}/client/idle-modal.png`;
  const UPDATE_BUTTON_TEXT = 'ACEPTAR';

  const MODAL_BLOCKED_BY_CHECKOUT: string = 'Para continuar con la navegación, finaliza el proceso de compra iniciado en la otra interfaz.';
  const MODAL_BLOCKED_BY_CHECKOUT_URL: string = `${URL_IMAGES}/client/cart-locked.png`;
  const LOGO: string = `${URL_IMAGES}/client/logo-header-modal.png`;

  return location.pathname === RoutesDirections.ERROR_ROUTE ? (
    <ErrorPage />
  ) : (
    <>
      <Header />
      <Container fluid className='p-0'>
        <Row className='g-0'>
          <Col xs={12}>
            {IsBlockedByCheckout ? ( //si esta bloqueado muestra checkout si no muestra el contenido
              <CartCheckOut />
            ) : (
              <>{children}</>
            )}
          </Col>
          <Col xs={12}>
            <Footer />
          </Col>
          {isMobile && (
            <Col xs={12}>
              <NavMobile />
            </Col>
          )}
        </Row>
      </Container>
      {/** Inicio Modal de Cambios en el localStorage */}
      <Modal
        show={showStoreChangeAlert}
        onHide={undefined}
        dialogClassName='modal-dialog-centered'
        contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
        size='lg'>
        <Container fluid className='px-0 g-0 '>
          <Row className='d-flex justify-content-center g-0  '>
            <Col lg={12} className='mt-5 pt-3'>
              <Col className='d-flex justify-content-center mb-4 text-primary-3 g-0  '>
                <Image
                  src={MODAL_CART_UPDATED_URL}
                  width={200}
                  alt='gif'
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = ErrorImage;
                  }}
                />
              </Col>
            </Col>

            <Col lg={9} className='d-flex justify-content-center text-primary-3 g-0 my-0 px-0'>
              <span className='text-primary-3 p-large-bold text-center'>{MODAL_CART_UPDATED_TEXT}</span>
            </Col>

            <Col lg={8} className='d-flex justify-content-center mt-2 mb-4 g-0 px-2 px-5'>
              <Button className={`my-2  svg-primary-4 btn-primary-icon-standard `} type='button' onClick={() => storageChange.handleSync(true)}>
                {UPDATE_BUTTON_TEXT}
              </Button>
            </Col>
          </Row>
        </Container>
        <Col xs={12} className='align-self-end pt-2 '>
          <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
      {/** Fin Modal de Cambios en el localStorage */}

      {/** Inicio Modal de Bloqueo por CheckOut */}
      <Modal
        show={blockedByCheckOutAlert}
        onHide={undefined}
        fullscreen
        className='p-0 bg-primary-4'
        dialogClassName='modal-dialog-centered ultra-top'
        contentClassName='d-flex justify-content-between border-0'
        size='xl'>
        <Container fluid className='p-0'>
          <Row className='g-0 bg-primary-3 sticky-top  m-0  px-3 px-lg-5 pt-1'>
            <Col lg={5}>
              <Row className='align-items-center g-0'>
                <Col lg={3} className='px-1 pb-2'>
                  <Image src={LOGO} alt='logo' className='logo-header-modal' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='g-0 min-vh-90 d-flex align-items-center '>
            <Col className='text-center'>
              <Row>
                <Col lg={12} className='mt-5'>
                  <Image
                    src={MODAL_BLOCKED_BY_CHECKOUT_URL}
                    alt='gif'
                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                      e.currentTarget.src = ErrorImage;
                    }}
                  />
                </Col>
                <Col lg={3} className='mx-auto'>
                  <span className='h4 fw-bold text-primary-3 text-center'>{MODAL_BLOCKED_BY_CHECKOUT}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={12} className='mx-auto text-center text-primary-3 px-3'></Col>
          </Row>
        </Container>
        <Col xs={12} className='align-self-end pt-2 '>
          <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
        </Col>
      </Modal>
      {/** FinModal de Bloqueo por CheckOut */}

      {/* Inicio Modal Salir proceso de compra */}
      <ModalBlockedByCheckOut show={modalExitConfirmation} handleSubmit={handleDeleteReservation} handleCancel={handleToCheckout} />
      {/* Fin Modal Salir proceso de compra */}

      {/* Inicio Modal sesión a expirar */}
      {showToExpiredModal && <ToExpiredModal />}
      {/* Fin Modal sesión a expirar */}

      {/* Inicio Modal login */}
      {showLogin && <LoginForm showLogin={showLogin} handleShowLogin={handleShowLogin} handleShowRegister={() => {}} />}
      {/* Fin Modal login */}

      <ButtonScrollTop />
    </>
  );
};

export default Layout;
