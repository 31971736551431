import { PropsWithChildren } from 'react';

import { Col } from 'react-bootstrap';

import generateNumberId from '@/domain/utils/generateNumberId';

import { IShippingTypes } from '@/domain/interfaces/IProduct';

import Icon from '@/ui/assets/Icon';

import GuideTip from '@/ui/components/GuideTip';

interface PropsShippingMethods {
  data: IShippingTypes[];
  classTitle?: string;
  classIcon?: string;
}
/**
 * Componente para métodos de envío
 * @component
 */
const ShippingMethods = ({ data, classTitle = 'p-small-medium', classIcon }: PropsWithChildren<PropsShippingMethods>): JSX.Element => {
  const SEND_TITLE: string = 'Envío:';

  return (
    <>
      <Col>
        <span className={classTitle}>{SEND_TITLE}</span>
      </Col>
      {data && data.length > 0 && data.map((shippingType) => (
        <Col key={`shippingType-${generateNumberId()}`} className={`shipping-type svg-secondary-3 ${classIcon} ps-1`}>
          <GuideTip position='bottom' message={shippingType.title}>
            <Icon name={shippingType.icon} />
          </GuideTip>
        </Col>
      ))}
    </>
  );
};

export default ShippingMethods;
