/**
 * Recibe una expresion Regex y un texto y valida si se cumple o no
 * @param pattern - Expresion regular que valida .
 * @param text - String a validar .
 * @returns  booleano .
 */
const validatePattern = (pattern: RegExp, text: string): boolean => {
  return pattern.test(text);
};

export default validatePattern;
