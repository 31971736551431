import { validateProductBaseBack } from '@/controllers/ProductsController';
import ProductBackErrors, { ProductBackCodes } from '@/data/libraries/errors/backErrors/ProductBackErrors';
import ProductRepository from '@/data/repositories/ProductRepository';
import { IProductForm, ITechnicalSpecification } from '@/domain/interfaces/IProduct';
import {
  IBackResponseAdd,
  IInterfaceNameToValidate,
  IProductValidation,
  IProductValidationBack,
  IVariantErrorsBack,
} from '@/domain/interfaces/IProductResponse';
import validateProductBaseUseCase, { IProductBaseValidation } from '@/domain/useCases/product/validateProductBaseUseCase';
import validateVariantUseCase, { IVariationValidation } from '@/domain/useCases/product/validateVariantUseCase';
import countDecimals from '@/domain/utils/countDecimals';
import validateVariantBackUseCase from '@/domain/useCases/product/validateVariantBackUseCase';
import { AxiosResponse } from 'axios';
import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

/**
 * Caso de uso de creación de producto base - Validaciones
 * @param {IProductForm} product - data para nuevo registro
 * @param {boolean} send - se determina si se debe guardar o si solo valida
 * @param {IInterfaceNameToValidate} interfaceName - nombre de la interface que aplica si es necesaria
 * @returns {IProductValidation}
 */
const addProductBaseUseCase = async (
  product: IProductForm,
  send: boolean = false,
  interfaceName: IInterfaceNameToValidate = '',
  token: string = '',
): Promise<IProductValidation> => {
  const response: IProductValidation = {
    error: false,
    kindError: {
      minError: '',
      maxError: '',
      chrError: '',
      existingNameError: '',
      descriptionError: '',
      urlBanner: '',
      tagError: '',
      skuError: '',
      galleryError: '',
      taxError: '',
      priceError: '',
      offerPriceError: '',
      guideError: '',
      attributesError: '',
      attributesValueError: '',
      technicalSpecificationTitleError: '',
      technicalSpecificationError: '',
      technicalSpecificationKeyError: '',
      technicalSpecificationValueError: '',
      quantityError: '',
    },
    message: '',
  };

  /**
   * Instancia del Repository
   * @const {ProductRepository}
   */
  const productRepository = ProductRepository.instance;

  /** Validación con el caso de uso */
  const errors: IProductBaseValidation[] = validateProductBaseUseCase(product);

  /** Validación formulario */

  if (interfaceName === 'baseProductName' || interfaceName === '') {
    /** Validación nombre */
    const errorMinName = errors.find((error) => {
      return error.field === 'NOMBRE_PRODUCTO_BASE' && error.keyError === 'minError';
    });
    if (errorMinName) {
      response.message = errorMinName.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['minError'] = errorMinName.message;
      }
    }

    const errorMaxName = errors.find((error) => {
      return error.field === 'NOMBRE_PRODUCTO_BASE' && error.keyError === 'maxError';
    });
    if (errorMaxName) {
      response.message = errorMaxName.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['maxError'] = errorMaxName.message;
      }
    }

    const errorFormat = errors.find((error) => {
      return error.field === 'NOMBRE_PRODUCTO_BASE' && error.keyError === 'chrError';
    });
    if (errorFormat) {
      response.message = errorFormat.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['chrError'] = errorFormat.message;
      }
    }

    //Validación en back nombre existe
    if (token !== '') {
      await validateProductBaseBack(product, token, ProductBackCodes.EXISTING_NAME_ERROR)
        .then((responseBack: IProductValidationBack) => {
          if (responseBack.error) {
            const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.EXISTING_NAME_ERROR);
            const errorMessage = errorStructure?.message ?? 'Error';
            response.message = errorMessage;
            response.error = true;
            if (response.kindError !== undefined) {
              response.kindError['existingNameError'] = errorMessage;
            }
          }
        })
        .catch(() => {
          response.error = true;
        });
    }

    /** Fin Validación nombre */
  }

  if (interfaceName === 'baseProductDescription' || interfaceName === '') {
    /** Validación de Descripción */
    const errorDescription = errors.find((error) => {
      return error.field === 'DESCRIPCIÓN_PRODUCTO_BASE';
    });
    if (errorDescription) {
      response.message = errorDescription.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['descriptionError'] = errorDescription.message;
      }
    }

    /** Fin Validación Descripción */

    /** Validación de Tags */
    const errorTags = errors.find((error) => {
      return error.field === 'TAGS_PRODUCTO_BASE';
    });
    if (errorTags) {
      response.message = errorTags.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['tagError'] = errorTags.message;
      }
    }

    /** Fin Validación Tags */

    /** Validación de Url banner */
    const errorUrl = errors.find((error) => {
      return error.field === 'BANNER_PRODUCTO_BASE';
    });
    if (errorUrl) {
      response.message = errorUrl.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['urlBanner'] = errorUrl.message;
      }
    }

    /** Fin Validación Tags */
  }

  /** Validación de variante */
  if (interfaceName === 'mainVariant' || interfaceName === '') {
    /** Validación con el caso de uso */
    const variantErrors: IVariationValidation[] = validateVariantUseCase(product.variants[0]);

    /** SKU */
    const errorSku = variantErrors.find((error) => {
      return error.field === 'SKU';
    });

    if (errorSku) {
      response.message = errorSku.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['skuError'] = errorSku.message;
      }
    } else {
      // Valida SKU repetido en el back
      if (token !== '') {
        await validateProductBaseBack(product, token, ProductBackCodes.EXISTING_SKU_ERROR)
          .then((responseBack: IProductValidationBack) => {
            if (responseBack.error) {
              const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.EXISTING_SKU_ERROR);
              const errorMessage = errorStructure?.message ?? 'Error';
              response.message = errorMessage;
              response.error = true;
              if (response.kindError !== undefined) {
                response.kindError['skuError'] = errorMessage;
              }
            }
          })
          .catch(() => {
            response.error = true;
          });

        await validateProductBaseBack(product, token, ProductBackCodes.INVALID_SKU_CODE)
          .then((responseBack: IProductValidationBack) => {
            if (responseBack.error) {
              const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.INVALID_SKU_CODE);
              const errorMessage = errorStructure?.message ?? 'Error';
              response.message = errorMessage;
              response.error = true;
              if (response.kindError !== undefined) {
                response.kindError['skuError'] = errorMessage;
              }
            }
          })
          .catch(() => {
            response.error = true;
          });
      }
    }

    /** Media */
    const errorMedia = variantErrors.find((error) => {
      return error.field === 'GALERÍA';
    });
    if (errorMedia) {
      response.message = errorMedia.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['galleryError'] = errorMedia.message;
      }
    }

    /** Precio */
    const errorPrice = variantErrors.find((error) => {
      return error.field === 'PRECIO_BASE';
    });
    if (errorPrice) {
      response.message = errorPrice.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['priceError'] = errorPrice.message;
      }
    }

    /** Precio Oferta */
    const errorOfferPrice = variantErrors.find((error) => {
      return error.field === 'PRECIO_OFERTA';
    });
    if (errorOfferPrice) {
      response.message = errorOfferPrice.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['offerPriceError'] = errorOfferPrice.message;
      }
    }

    /** Impuestos */
    const errorTax = variantErrors.find((error) => {
      return error.field === 'IMPUESTOS';
    });
    if (errorTax) {
      response.message = errorTax.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['taxError'] = errorTax.message;
      }
    }
  }

  /** Validación de atributos */
  if (interfaceName === 'attributes' || interfaceName === '') {
    /** Validación con el caso de uso */
    const variantErrors: IVariationValidation[] = validateVariantUseCase(product.variants[0]);

    /** Valida con el back */
    if (token !== '' && product) {
      const errorBack = await validateVariantBackUseCase(product.variants[0], product, token);
      variantErrors.push(...errorBack);
    }

    /** Guía URL */
    const errorGuide = variantErrors.find((error) => {
      return error.field === 'GUÍA_ESPECIFICACIONES';
    });

    if (errorGuide) {
      response.message = errorGuide.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['guideError'] = errorGuide.message;
      }
    }

    /** Atributos */
    const errorAttributes = variantErrors.find((error) => {
      return error.field === 'ATRIBUTOS';
    });
    if (errorAttributes) {
      response.message = errorAttributes.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['attributesError'] = errorAttributes.message;
      }
    }

    // Valida que los atributos vengan sin errores desde el form wizard
    const attributeValueError: boolean = product.variants[0].attributes.some((attribute) => attribute.error);
    if (attributeValueError) {
      response.message = 'Error en valores de atributos';
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['attributesError'] = 'Error en valores de atributos';
      }
    }
  }

  /** Validación de especificaciones técnicas */
  if (interfaceName === 'technicalSpecification' || interfaceName === '') {
    /** Validación con el caso de uso */
    const variantErrors: IVariationValidation[] = validateVariantUseCase(product.variants[0]);

    /** Titulo Especificaciones técnicas */
    const errorTitleTechnicalSpecification = variantErrors.find((error) => {
      return error.field === 'TITULO_ESPECIFICACIONES_TÉCNICAS';
    });

    if (errorTitleTechnicalSpecification) {
      response.message = errorTitleTechnicalSpecification.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['technicalSpecificationTitleError'] = errorTitleTechnicalSpecification.message;
      }
    }
  }

  /** Validación de variante */
  if (interfaceName === 'branchOffice' || interfaceName === '') {
    /** Validación con el caso de uso */
    const variantErrors: IVariationValidation[] = validateVariantUseCase(product.variants[0]);

    /** Guía URL */
    const errorQuantity = variantErrors.find((error) => {
      return error.field === 'CANTIDAD';
    });

    if (errorQuantity) {
      response.message = errorQuantity.message;
      response.error = true;
      if (response.kindError !== undefined) {
        response.kindError['quantityError'] = errorQuantity.message;
      }
    }
  }

  /** Devuelve (si existe) el primer error del back al finalizar Wizard form */
  if (interfaceName === '') {
    /** Valida con el back */
    if (token !== '') {
      await validateProductBaseBack(product, token)
        .then((responseBack: IProductValidationBack) => {
          if (responseBack.error) {
            if (responseBack.baseProductErrors && responseBack.baseProductErrors.length > 0) {
              response.message = responseBack.baseProductErrors[0].message ?? '';
            } else if (responseBack.productErrors && responseBack.productErrors.length > 0) {
              const variantErrors: IVariantErrorsBack[] = [responseBack.productErrors[0]];

              response.message = variantErrors[0].errors[0].message ?? '';
            }
            response.error = true;
          }
        })
        .catch(() => {
          response.error = true;
        });
    }
  }

  /** Pasa las validaciones */
  if (!response.error && send && token !== '') {
    /**
     * Guarda el producto base
     */
    await productRepository
      .addProductBase(product, token)
      .then((result: AxiosResponse<IBackResponseAdd>) => {
        if (result.data.idProduct === null) {
          /** No logró crear el producto base, manejar errores */
          response.error = true;
          let baseProductError = '';
          let productError = '';

          if (result.data.errors.baseProductErrors && result.data.errors.baseProductErrors.length > 0) {
            baseProductError = result.data.errors.baseProductErrors[0].message;
          }

          if (result.data.errors.productErrors && result.data.errors.productErrors.length > 0) {
            productError = result.data.errors.productErrors[0].errors[0].message;
          }

          response.message =
            (baseProductError !== '' ? 'El Nombre de Producto Base ya existe,' : '') +
            (productError !== '' ? 'El SKU de Producto Base ya existe' : '');
        }
        return result.data;
      })
      .catch((error) => {
        response.error = true;
        response.message = 'Repository Error';
        console.error('Repository Error', error);
        return error;
      });
  }

  return response;
};

const validateAttributesValuesUseCase = (value: string, decimalPrecision: number | null, type: string, range: number[]): IProductValidation => {
  const response: IProductValidation = {
    error: false,
    kindError: {
      minError: '',
      maxError: '',
      chrError: '',
      existingNameError: '',
      descriptionError: '',
      urlBanner: '',
      tagError: '',
      skuError: '',
      galleryError: '',
      taxError: '',
      priceError: '',
      offerPriceError: '',
      guideError: '',
      attributesError: '',
      attributesValueError: '',
      technicalSpecificationTitleError: '',
      technicalSpecificationError: '',
      technicalSpecificationKeyError: '',
      technicalSpecificationValueError: '',
      quantityError: '',
    },
    message: '',
  };

  const requiredError = 'Campo Requerido o con formato incorrecto';
  /** Recibe la llave del atributo y la precisión decimal para retornar el error */
  const attributesDecimalPrecisionError = (decimalPrecision: number): string => `El valor debe ser de máximo ${decimalPrecision} decimales`;
  /** Recibe el rango mínimo y máximo como referencia al error */
  const rangeError = (minimumValue: number, maximumValue: number): string =>
    `El valor debe estar entre el siguiente rango: [${minimumValue} y ${maximumValue}]`;

  if (value === '') {
    response.message = requiredError;
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['attributesValueError'] = requiredError;
    }
  }

  if (type === 'quantitative' && decimalPrecision && countDecimals(value) > decimalPrecision) {
    response.message = attributesDecimalPrecisionError(decimalPrecision);
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['attributesValueError'] = attributesDecimalPrecisionError(decimalPrecision);
    }
  }

  if (type === 'quantitative' && range.length > 1 && (parseFloat(value) < range[0] || parseFloat(value) > range[1])) {
    response.message = rangeError(range[0], range[1]);
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['attributesValueError'] = rangeError(range[0], range[1]);
    }
  }

  return response;
};

const validateTechSpecificationsItemUseCase = (item: ITechnicalSpecification): IProductValidation => {
  const response: IProductValidation = {
    error: false,
    kindError: {
      minError: '',
      maxError: '',
      chrError: '',
      existingNameError: '',
      descriptionError: '',
      urlBanner: '',
      tagError: '',
      skuError: '',
      galleryError: '',
      taxError: '',
      priceError: '',
      offerPriceError: '',
      guideError: '',
      attributesError: '',
      attributesValueError: '',
      technicalSpecificationTitleError: '',
      technicalSpecificationError: '',
      technicalSpecificationKeyError: '',
      technicalSpecificationValueError: '',
      quantityError: '',
    },
    message: '',
  };

  const TECHNICAL_SPECIFICATIONS_ERROR: string = `Se deben llenar los dos campos de este item`;
  const TITLE_LENGTH_ERROR: string = `Contenga máximo ${ConfigNumberParameters.VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH} caracteres`;
  const CHARACTERISTIC_LENGTH_ERROR: string = `Contenga máximo ${ConfigNumberParameters.MAXIMUM_DB_TEXT_LENGTH} caracteres`;

  /** validacion de seccion de ficha tecnica */
  if ((item.key !== '' && item.value === '') || (item.key === '' && item.value !== '')) {
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['technicalSpecificationError'] = TECHNICAL_SPECIFICATIONS_ERROR;
    }
  }

  /** Titulo de seccion de especificacion técnica */
  if (item.key.length > ConfigNumberParameters.VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH) {
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['technicalSpecificationKeyError'] = TITLE_LENGTH_ERROR;
    }
  }

  /** Característica de Especificaciones técnicas */
  if (item.value.length > ConfigNumberParameters.MAXIMUM_DB_TEXT_LENGTH) {
    response.error = true;
    if (response.kindError !== undefined) {
      response.kindError['technicalSpecificationValueError'] = CHARACTERISTIC_LENGTH_ERROR;
    }
  }

  return response;
};

export { addProductBaseUseCase, validateAttributesValuesUseCase, validateTechSpecificationsItemUseCase };
