const countDecimals = (value: number | string): number => {
  // Convierte el número a una cadena de texto
  const numberToString: string = value?.toString() ?? '0';
  const decimalIndicator = numberToString.includes('.') ? '.' : ',';
  // Verifica si hay un punto decimal en la cadena
  if (numberToString.includes(decimalIndicator)) {
    // Encuentra la posición del punto decimal y contar los dígitos después de él
    const decimalPosition: number = numberToString.indexOf(decimalIndicator);
    const decimalQuantity: number = numberToString.length - decimalPosition - 1;
    return decimalQuantity;
  } else {
    // Si no hay punto decimal, el número tiene 0 decimales
    return 0;
  }
};

export default countDecimals;
