import { ICart } from '@/domain/interfaces/ICart';

/** Representa la interacción con LocalStorage */
export default class CartLocalStorage {
  /** Instancia de la clase   */
  private static _instance: CartLocalStorage;

  /** Patron Singleton
   * @returns {instance} _instance CartLocalStorage
   */
  static get instance() {
    if (!this._instance) {
      this._instance = new CartLocalStorage();
    }
    return this._instance;
  }

  /** Key del objeto carrito en el localStorage */
  private static nameInLocalStorage = 'IlisPlacesCart';

  /** Actualiza el localStorage de carrito */
  public updateCart(cart: ICart) {
    localStorage.setItem(CartLocalStorage.nameInLocalStorage, JSON.stringify(cart));
  }

  /** Obtiene el carrito del localStorage */
  public getCart(): ICart | null {
    const localStorageCart = localStorage.getItem(CartLocalStorage.nameInLocalStorage);
    return localStorageCart !== null ? JSON.parse(localStorageCart) : null;
  }

  /** Elimina el objeto carrito del localStorage */
  public deleteCart() {
    localStorage.removeItem(CartLocalStorage.nameInLocalStorage);
  }
}
