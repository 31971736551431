import { PropsWithChildren, ChangeEvent } from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';

/** Propiedades del componente */
interface PropsSwitchCheckbox  {
    /** Clase principal del componente. */
    classMain?: string;
    /** Clase del texto . */
    classSpan?: string;
    /** Texto que se renderiza al lado izquierdo del check. */
    label?: string;
    /**
     * Tipo de Check.
     * Por defecto es 'checkbox', pero si se coloca 'switch' cambia a un componente Switch.
     */
    type?: 'radio' | 'checkbox' | 'switch';
    /** Propiedad que define la referencia del checkbox */
    inputRef?: any;
    /** Estado de habilitación del checkBox (Activado o Desactivado). */
    disabled?: boolean;
    /** Estado del Check. */
    checked?: boolean;
    /** Función para el evento de cambio. */
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    /** Mostrar valor */
    showValue?: boolean,
    /** Valor del input */
    value?:string,
    };

/**
 * Componente que muestra un label con un Check.
 * @component
 */
const SwitchCheckbox = ({
    label = '',
    type = 'checkbox',
    inputRef=null,
    classMain = '',
    classSpan = '',
    checked = false,
    disabled = false,
    onChange,
    showValue = false,
    value = '',
}: PropsWithChildren<PropsSwitchCheckbox>):JSX.Element => {
    return (
        <Container fluid>
        <Row>
            <Col className={`d-flex justify-content-between align-items-center ${classMain}`}>
            <span className='p-regular-medium'>{label}</span>
            {showValue &&
                <span className={classSpan}>{value}</span>
            }
            <Form.Check
                checked={checked}
                ref={inputRef}
                disabled={disabled}
                type={type}
                onChange={onChange}
            />
            </Col>
        </Row>
        </Container>
    );
    };

    export default SwitchCheckbox;
