import { shippingTypes } from '@/data/libraries/ShippingTypes';

import { useAppSelector } from '@/ui/store/helperRedux';

import { IProductForm, IVariantForm } from '@/domain/interfaces/IProduct';

/**
 * Hook personalizado para crear un objeto de formulario para creación de producto base.
 * @returns {IProductForm}  productBaseFormClean
 */
const useCreateBaseProductForm = (): IProductForm => {
  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  const mainVariant: IVariantForm = {
    price: null,
    status: 1,
    baseProductName: '',
    main: true,
    branchOfficeId: userLogued.user?.branchOffice?.id ?? 0,
    transferable: false,
    media: [],
    taxes: [],
    quantity: null,
    attributes: [],
    urlGuide: '',
  };

  const productBaseFormClean = {
    id: 0,
    store: userLogued.user?.userStore ?? 0,
    name: '',
    description: '',
    category: 0,
    tags: [],
    shippingTypes: [shippingTypes.NATIONAL_SHIPMENT],
    variants: [mainVariant],
    urlBanner: '',
    status: 0,
  };

  return productBaseFormClean;
};

export { useCreateBaseProductForm };
