import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useAppSelector } from '@/ui/store/helperRedux';

import { Col, Row } from 'react-bootstrap';

import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import ProductsController from '@/controllers/ProductsController';

import { IProductForm, ITechnicalSpecification, IVariantForm } from '@/domain/interfaces/IProduct';
import { IPathCategoryType } from '@/domain/interfaces/ICategories';
import { IProductValidation } from '@/domain/interfaces/IProductResponse';

import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import MainTitle from '@/ui/components/MainTitle';
import CategoryPath from '@/ui/components/formWizard/CategoryPath';
import InputForm from '@/ui/components/forms/InputForm';
import IconList from '@/ui/components/IconList';
import TechSpecificationsItem from '@/ui/components/inventory/TechSpecificationsItem';

/** Propiedades del componente */
interface PropsTechSpecifications {
  /**Titulo de la interface */
  mainTitle: string;
  /**Texto Botón de Cancelar */
  cancelButtonText: string;
  /**Texto Botón Continuar */
  nextButtonText: string;
  // Texto del titulo de la variante del producto
  variantProductTitle: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  // Texto del titulo de la clasificación
  classificationLabelText: string;
  /** Objeto que representa la categoría referenciada */
  pathCategoryState: IPathCategoryType[];
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
}

/**
 * Componente para configurar las especificaciones técnicas de la variante principal
 * @component
 */

const TechSpecifications = ({
  mainTitle,
  variantProductTitle,
  formRef,
  cancelButtonText,
  nextButtonText,
  handleCancelButton,
  classificationLabelText,
  pathCategoryState,
  updateFormRef,
  handleNextButton,
}: PropsTechSpecifications): JSX.Element => {
  /**CONSTANTES DE TEXTO */
  const SOON_TEXT: string = '(Opcional)';
  const TECHNICAL_SPECIFICATIONS_DESCRIPTION: string =
    'Agregue las secciones que considere necesarias para la ficha técnica de la variante, y en cada sección agregue un título y descripción.';
  const SKU_LABEL: string = 'SKU:';
  const SKU_NO_DATA: string = 'S/I';

  const SPECIFICATION_TITLE_TEXT: string = 'Nombre de la ficha (Opcional)';
  const SPECIFICATION_TITLE_PLACEHOLDER: string = 'Ficha técnica (Para modificar este texto, debe ingresarlo)';

  const ADD_SPECIFICATION_ITEM_TEXT: string = 'Agregar otra sección';

  const CONTINUE_TEXT: string = 'OMITIR Y CONTINUAR';

  /** Referencia al input del titulo general de las especificaciones técnicas */
  const techGeneralSpecificationLabelRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Controlador de producto */
  const { addProductBase } = ProductsController();

  /** Declaración inicial input para el titulo de las especificaciones técnicas en su estado normal    */
  const [techSpecificationsLabelWithError, setTechSpecificationsLabelWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de para el titulo de las especificaciones técnicas  */
  const [techSpecificationsLabelMessageError, setTechSpecificationsLabelMessageError] = useState<string>('');

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Estado para habilitar el botón de siguiente paso */
  const [isAValidData, setIsAValidData] = useState<boolean>(false);

  /** Estado para habilitar el botón de siguiente paso */
  const [hasValidItems, setHasValidItems] = useState<boolean>(true);

  /** Utiliza useState para mantener el estado del contador de caracteres */
  const [characterCount, setCharacterCount] = useState<number>(0);

  /**Posición inicial del primer item de especificaciones técnicas */
  const [position, setPosition] = useState<number>(0);

  /**  Estado para almacenar las secciones de la ficha técnica*/
  const [techSpecificationsForm, setTechSpecificationsForm] = useState<ITechnicalSpecification[]>([{ position: position, key: '', value: '' }]);

  /** Se fabrica un objeto de formulario para producto base */
  const [productBaseFormClean] = useState<IProductForm>(useCreateBaseProductForm());

  /**Cambios en los botones de texto */
  const [dynamicNextButtonText, setDynamicNextButtonText] = useState<string>(CONTINUE_TEXT);

  /** Clase para el link de agregar item de especificaciones técnicas */
  const [classLinkNewAccordion, setClassLinkNewAccordion] = useState<string>('link-icon-standard text-tertiary-4');

  /**longitud maxima de la descripción de un producto*/
  const BASE_PRODUCT_FEATURES_MAX_LENGTH = ConfigNumberParameters.VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH;

  /**Verifica si el objeto tiene items */
  const hasEmptyItems = techSpecificationsForm.some((item) => item.key === '' && item.value === '');

  const handleCheckSpecificationItems = (isValid: boolean) => {
    setHasValidItems(isValid);
  };

  /** Evento desactivar el link de agregar impuestos  */
  const disableLink = (): void => {
    setClassLinkNewAccordion('link-icon-standard disabled');
  };

  /** Evento activar el link de agregar impuestos  */
  const activeLink = (): void => {
    setClassLinkNewAccordion('link-icon-standard');
  };

  /**Función que agrega items de especificaciones técnicas */
  const addTechSpecificationItem = () => {
    if (techSpecificationsForm.length < 10 && !hasEmptyItems) {
      setTechSpecificationsForm([...techSpecificationsForm, { position: position, key: '', value: '' }]);
      setPosition(position + 1);
    } else {
      disableLink();
    }
  };

  /** Actualiza el state que almacena los valores de la ficha técnica  */
  const changeTechSpecificationItem = (item: ITechnicalSpecification, index: number): void => {
    const updatedValues: ITechnicalSpecification[] = techSpecificationsForm;
    updatedValues[index] = { ...item };
    // Actualiza el state de los valores de los componentes
    setTechSpecificationsForm(updatedValues);
  };

  /** Función que elimina un item de la ficha técnica */
  const handleDeleteTechSpecificationItem = (index) => {
    const updatedValues = techSpecificationsForm.filter((_, i) => i !== index);
    // Actualiza el state de los valores de los componentes
    setTechSpecificationsForm(updatedValues);
  };

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    // Establece el formulario a actualizar
    const formProduct: IProductForm = formRef.current ? JSON.parse(JSON.stringify(formRef.current)) : { ...productBaseFormClean };

    const mainVariant: IVariantForm = formRef.current?.variants.length
      ? JSON.parse(JSON.stringify(formRef.current.variants[0]))
      : JSON.parse(JSON.stringify(productBaseFormClean.variants[0]));

    // Construye el titulo  de las especificaciones técnicas
    mainVariant.technicalSheetTitle = techGeneralSpecificationLabelRef.current?.value.trim() ?? '';
    //Construye la data de las especificaciones técnicas con información
    mainVariant.technicalSheet = techSpecificationsForm.filter((item) => item.key !== '' || item.value !== '');

    if (mainVariant.technicalSheetTitle.length < 1 && mainVariant.technicalSheet.length < 1) {
      setDynamicNextButtonText(CONTINUE_TEXT);
    } else {
      setDynamicNextButtonText(nextButtonText);
    }

    // Actualiza el objeto del formulario
    formProduct.variants = [mainVariant];

    // Valida desde el controlador
    await addProductBase(formProduct, false, 'technicalSpecification', userLogued.user?.token ?? '')
      .then((response: IProductValidation) => {
        //Si hay error se muestra

        // Error de Titulo de especificaciones técnicas
        if (response.error && response.kindError?.technicalSpecificationTitleError && response.kindError?.technicalSpecificationTitleError !== '') {
          setTechSpecificationsLabelWithError(true);
          setTechSpecificationsLabelMessageError(response.kindError.technicalSpecificationTitleError);
        } else {
          setTechSpecificationsLabelWithError(false);
          setTechSpecificationsLabelMessageError('');
        }

        // Si se solicitó el objeto del formulario se actualiza
        if (!response.error) {
          setIsAValidData(true);
          send && updateFormRef(formProduct);
        } else {
          setIsAValidData(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    countCharacters();
  };

  /** Función para contar caracteres */
  const countCharacters = () => {
    const count = techGeneralSpecificationLabelRef.current?.value.length ?? 0;
    setCharacterCount(count);
  };

  /** Evento que envía el formulario cuando se presiona el botón */
  const handleSendInfo = async (): Promise<void> => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /**UseEffect que crea un item de ficha técnica al eliminarlos todos */
  useLayoutEffect(() => {
    if (!techSpecificationsForm.length) {
      addTechSpecificationItem();
    }

    if (techSpecificationsForm.length < 10 && !hasEmptyItems && hasValidItems) {
      activeLink();
    } else {
      disableLink();
    }

    handleValidate();
  }, [techSpecificationsForm, isAValidData, hasValidItems]);

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      formRef.current.variants[0].technicalSheetTitle &&
        (techGeneralSpecificationLabelRef.current!.value = formRef.current.variants[0].technicalSheetTitle);
      formRef.current.variants[0].technicalSheet &&
        formRef.current.variants[0].technicalSheet?.length > 0 &&
        setTechSpecificationsForm(formRef.current.variants[0].technicalSheet ?? []);
    }
    handleValidate();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <Row className='d-flex g-0'>
          <MainTitle boldTitle={`${mainTitle}  ${SOON_TEXT}`} detailsText={TECHNICAL_SPECIFICATIONS_DESCRIPTION} />
          <Col lg={12} className='mb-3'>
            <span className='p-title-medium  me-2'>{variantProductTitle}</span>
            <span className='p-title-bold '>{formRef.current?.name ?? ''}</span>
          </Col>
          <Col lg={12} className='rounded rounded-3 bg-secondary-5 py-2 mb-4 px-3 d-flex align-content-center'>
            <Col lg={3}>
              <div className='rounded rounded-3 bg-secondary-5 py-2 px-3  d-flex  '>
                <span className='text-primary-4 p-large-medium py-1 px-2 me-4 rounded-5  bg-primary-3 text-break px-3 '>
                  <span className='text-primary-4 p-large-medium px-1 rounded-5  bg-primary-3 text-break'>{SKU_LABEL}</span>
                  {`${formRef.current!.variants[0].sku ? formRef.current?.variants[0].sku : SKU_NO_DATA}`}
                </span>
              </div>
            </Col>
            <Col lg={9} className='d-flex'>
              <div className='flex-grow-1 d-flex align-items-center text-truncate'>
                <span className='text-tertiary-3 p-large-medium'>
                  {`${formRef.current?.variants[0].attributes.map((attribute) => `${attribute.key}: ${attribute.value}`)}
                                    `}
                </span>
              </div>
            </Col>
          </Col>
          <span className='p-title-medium'>{classificationLabelText}</span>
          <Col lg={12} className='bg-secondary-4 border border-1 p-2 mb-4 rounded rounded-2'>
            <CategoryPath pathState={pathCategoryState} referenced />
          </Col>
        </Row>

        <Row className='d-flex g-0 justify-content-center'>
          <Col lg={12}>
            <InputForm
              inputRef={techGeneralSpecificationLabelRef}
              className={`mb-2 `}
              label={SPECIFICATION_TITLE_TEXT}
              classLabel='p-regular-medium'
              type='text'
              onChange={() => handleValidate()}
              placeholder={SPECIFICATION_TITLE_PLACEHOLDER}
              withErrors={techSpecificationsLabelWithError}
              messageError={techSpecificationsLabelMessageError}
            />
            <Col className='text-end'>
              <span
                className={`p-regular-bold  ${
                  characterCount >= BASE_PRODUCT_FEATURES_MAX_LENGTH && 'text-complementary-2'
                }`}>{`${characterCount}/${BASE_PRODUCT_FEATURES_MAX_LENGTH}`}</span>
            </Col>
          </Col>

          {techSpecificationsForm.map((techSpecificationsItem, index) => {
            return (
              <TechSpecificationsItem
                key={techSpecificationsItem.position}
                index={index}
                techSpecificationForm={techSpecificationsForm}
                techSpecificationData={techSpecificationsItem}
                formRef={formRef}
                handleCheckItem={handleCheckSpecificationItems}
                handleDelete={handleDeleteTechSpecificationItem}
                onChangeForm={changeTechSpecificationItem}
              />
            );
          })}
          <Col lg={12} className='text-center my-4 '>
            <IconList
              iconName='ico-more'
              classMain={classLinkNewAccordion}
              classTitle='p-title-medium'
              title={ADD_SPECIFICATION_ITEM_TEXT}
              onClick={addTechSpecificationItem}
            />
          </Col>

          <Col lg={6}>
            <TwoButtonsGroup
              firstButtonText={cancelButtonText}
              firstButtonClass='btn-secondary-text-small'
              firstButtonClick={handleCancelButton}
              secondButtonText={dynamicNextButtonText}
              secondButtonClass={`btn-primary-text-standard ${(!isAValidData || !isActive || !hasValidItems) && 'disabled'}`}
              secondButtonClick={handleSendInfo}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TechSpecifications;
