import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import currencyFormat from '@/domain/utils/currencyFormat';

import { ICart, ICartDetail, ICartResponse } from '@/domain/interfaces/ICart';

const useValidateUnavailableProducts = () => {
  /**Trae la información del carrito desde Redux */
  const cartProducts: ICart = useAppSelector((state: RootState) => state.cartState) as ICart;

  /**Verifica si el carrito en general tiene productos no disponibles */
  const hasUnavailableProducts = (): ICartResponse => {
    const response: ICartResponse = {
      error: false,
      message: '',
      cart: JSON.parse(JSON.stringify(cartProducts)),
    };

    const MAXIMUM_SELL_AMOUNT: number = ConfigNumberParameters.MAXIMUM_PRICE - parseInt(process.env.REACT_APP_STANDARD_SHIPPING_COST ?? '0');
    const MAXIMUN_TOTAL_MESSAGE: string = `El valor de venta no puede superar los ${currencyFormat(
      MAXIMUM_SELL_AMOUNT,
      ConfigNumberParameters.DECIMALS_ALLOWED,
    )}. Por favor, ajusta el precio para continuar con la transacción.`;
    const PRODUCTS_ZERO_ERROR_MESSAGE: string = 'Para comprar articulos gratuitos agrega al menos un producto con costo';
    const PRODUCTS_OUT_STOCK_MESSAGE: string =
      '¡Oops! Parece algunos productos de tu carrito se agotaron, descártalos y descubre opciones similares en nuestro catálogo. ¡Continua tu experiencia de compra sin límites!';

    if (cartProducts.details?.length > 0) {
      for (const reduxProduct of cartProducts.details) {
        if (reduxProduct.quantity > reduxProduct.quantityAvailable) {
          response.error = true;
          response.message = PRODUCTS_OUT_STOCK_MESSAGE;
        }
      }
    }

    if (cartProducts.totalAmount > MAXIMUM_SELL_AMOUNT) {
      response.error = true;
      response.message = MAXIMUN_TOTAL_MESSAGE;
    }

    if (cartProducts.details) {
      const errorStatus: ICartDetail | undefined = cartProducts.details.find((product: ICartDetail) => product.status !== 1);

      if (errorStatus) {
        response.error = true;
        response.message = PRODUCTS_OUT_STOCK_MESSAGE;
      }
    }

    if (cartProducts.totalWithoutTax === 0) {
      response.error = true;
      response.message = PRODUCTS_ZERO_ERROR_MESSAGE;
    }

    return response;
  };

  return { hasUnavailableProducts };
};
export default useValidateUnavailableProducts;
