/**
 * Librería de Patrones
 */

/** Regex que valida que sea Alfanumérico y sin caracteres especiales
 * Usos:
 * 1.- Validación de tipos de documento con ID diferentes a 1
 */
export const ALPHANUMERIC_WITHOUT_SPECIAL_CHARACTER = /^([a-zA-Z\d]+)$/;

/** Regex que valida que no hayan espacios en blanco antes, después o mas de dos entre palabras
 * Usos:
 * 1.- Validación de SKU y cantidades  de variantes (FormWizard y Carga masiva)
 * 2.- Validación de tags, urls Banner y Guía de especificaciones  de productos base (FormWizard y Carga masiva)
 */
export const BLANK_CHARACTER_IN_TEXT = /^(?!\s)(?![\s\S]*\s{2,})([^\s]+(\s[^\s]+)*)(?<!\s)$/;

/** Regex que valida los espacios en blanco (excepto los saltos de línea) al principio de un texto
 * Usos:
 * 1.- Validación de nombre y descripción de producto base (FormWizard y Carga masiva)
 */
export const BLANK_CHARACTER_AT_START = /^(?![ \t\f\v])\S|^\s*\n[ \t\f\v]*\S/;

/** Regex que valida el formato de un SKU
 * Usos:
 * 1.- Validación de Sku de variante (FormWizard y Carga masiva)
 */
export const PRODUCT_SKU_FORMAT = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/;

/**Regex que valida el formato de los tags de un producto base
 * Usos:
 * 1.- Validación de Tags de producto base (FormWizard y Carga masiva)
 */
export const PRODUCT_BASE_TAGS_FORMAT = /^(?=.*[A-Za-zñÑáéíóúüÜÁÉÍÓÚ])[\w\dñÑáéíóúüÜÁÉÍÓÚ\s]+$/;

/**
 * Variables de Configuraciones de Texto
 */
export enum ConfigStringParameters {}

/**
 * Variables de Configuraciones de Números
 */
export enum ConfigNumberParameters {
  /** Tiempo de duración de las reservas pendientes */
  RESERVATION_TIMEOUT_DURATION = 600000,
  /** Tiempo de duración en minutos de la sesión de la aplicación */
  REACT_APP_SESSION_DURATION = 480,
  /**Valor máximo para mostrar en las cantidades disponibles */
  AVAILABLE_STOCK_DEFAULT_VALUE = 99,
  /**Valor máximo de cantidades de productos */
  MAXIMUM_DB_TEXT_LENGTH = 255,
  /**Valor máximo de cantidades de productos */
  MAXIMUM_QUANTITY = 999999999999999,
  /**Valor de precio máximo que puede recibir la Base de Datos */
  MAXIMUM_PRICE = 9999999999,
  /**Valor máximo de caracteres para el SKU */
  PRODUCT_SKU_MAX_LENGTH = 150,
  /**Cantidad de decimales permitidos */
  DECIMALS_ALLOWED = 0,
  /**Valor mínimo de precio */
  BASE_PRODUCT_TAG_MAX_QUANTITY = 10,
  /**longitud minima del nombre de producto */
  BASE_PRODUCT_NAME_MIN_LENGTH = 8,
  /**longitud minima del nombre de producto */
  BASE_PRODUCT_NAME_MAX_LENGTH = 100,
  /**longitud maxima de caracteres del Tag */
  BASE_PRODUCT_TAG_MAX_LENGTH = 20,
  /**longitud minima de la descripción de un producto */
  BASE_PRODUCT_DESCRIPTION_MIN_LENGTH = 1,
  /**longitud maxima de la descripción de un producto */
  BASE_PRODUCT_DESCRIPTION_MAX_LENGTH = 1500,
  /**Longitud máxima del titulo de ficha técnica de productos */
  VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH = 30,
  /**Cantidad de categorías en index*/
  CATEGORY_TO_SHOW = 5,
  /**Cantidad de productos nuevos en index */
  PRODUCTS_TO_SHOW = 11,
}
