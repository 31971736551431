import { useAppDispatch } from '@/ui/store/helperRedux';
import { updateCategoriesMenu } from '@/ui/store/slices/categoriesMenuSlice';
import { updateMainMenu } from '@/ui/store/slices/mainMenuSlice';
import { IMenu } from '@/domain/interfaces/IMenu';
import { AxiosResponse } from 'axios';
import CategoriesRepository from '@/data/repositories/CategoriesRepository';

/** Representa el controlador que maneja los eventos de menus
 * @returns {object} Funciones del controlador
 */
const MenuController = () => {
  const dispatch = useAppDispatch();

  /**
   * Solicita el menú de categorias del back
   */
  const categoriesMenu = async (): Promise<IMenu[]> => {
    /** Instancia del Repository
     * @const {MenuRepository}
     */
    const menuRepository = CategoriesRepository.instance;

    /** Trae del back la información de categorias */
    const categories: IMenu[] = await menuRepository.getMenuCategories().then((menuCategories: AxiosResponse<IMenu[]>) => {
      if (menuCategories.data && menuCategories.data.length > 0) {
        /** Actualiza el estado redux del menu de categorias */
        dispatch(updateCategoriesMenu(menuCategories.data));
      }
      return menuCategories.data;
    });

    return categories;
  };

  /**
   * Construye el menú principal y lo entrega a redux
   */
  const mainMenu = async (): Promise<void> => {
    /** Trae el menú de categorias */
    await categoriesMenu().then((categories) => {
      /**
       * Construimos un objeto tipo IMenu para evitar el restraso de la actualización del state
       * en la función categoriesMenu()
       *
       */
      const initialState: IMenu = {
        icon: '',
        id: 'category',
        title: 'Categorías',
        url: '#',
        slug: 'categoria',
        children: [...categories],
      };

      /** Construye el arreglo de menus, por ahora los menús de sucursales y ayuda vienen de mocks */
      let mainMenuArray: IMenu[] = [];
      mainMenuArray.push(initialState);
      // mainMenuArray.push(MockNavBranchOffices);
      // mainMenuArray.push(MockNavHelp);
      // mainMenuArray.push(MockNavMyBoughts);

      /**Actualiza el estado redux del menú principal. */
      dispatch(updateMainMenu(mainMenuArray));
    });
  };

  const footer = (): IMenu[] => {
    /** Construye el arreglo de footer */
    const mainFooterArray: IMenu[] = [];

    //validar o transformar
    return mainFooterArray;
  };

  return { footer, mainMenu };
};

export default MenuController;
