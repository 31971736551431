import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import type { NavigateFunction } from 'react-router-dom';

import { useScreenSize } from '@/ui/hooks/useScreenSize';
import type { ScreenSize } from '@/ui/hooks/useScreenSize';

import UserController from '@/controllers/UserController';

import { ILoginData } from '@/domain/interfaces/IUser';
import { IUserResponse } from '@/domain/interfaces/ILoginResponse';

import '@/ui/components/forms/form.css';

import BackIcon from '@/ui/assets/BackIcon';

import InputForm from '@/ui/components/forms/InputForm';

import { useAppSelector } from '@/ui/store/helperRedux';

interface PropsLoginForm {
  showLogin: boolean;

  handleShowLogin: () => void;
  handleShowRegister: () => void;
}

/** Componente formulario para login email y contraseña
 * @component
 */
const LoginForm = ({ showLogin, handleShowLogin, handleShowRegister }: PropsWithChildren<PropsLoginForm>): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Logo en blanco del proyecto */
  const LOGO: string = `${URL_IMAGES}/client/logo-full-modal.png`;

  /** Hook para generar una navegación dentro de la aplicación.  */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  /** Referencia al input email
   * @constant {HTMLInputElement}
   */
  /*Trae la data del usuario registrado, incluyendo el tipo de usuario (kind)*/
  const { user } = useAppSelector((state) => state.userState);

  /** Almacena la función del controlador para hacer login
   * @constant {object}
   */
  const { doLogin } = UserController();

  const emailRef = useRef<HTMLInputElement>(null);

  /** Referencia al check mantenerme conectado
   * @constant {HTMLInputElement}
   */
  const sessionCheckRef = useRef<HTMLInputElement>(null);

  /** Referencia al input password
   * @constant {HTMLInputElement}
   */
  const passwordRef = useRef<HTMLInputElement>(null);

  /** Referencia al Formulario Login
   * @constant {HTMLFormElement}
   */
  const loginFormRef = useRef<HTMLFormElement>(null);

  /** Maneja un state para mostrar los inputs relacionados al email al cargar la web    */
  const [inEmailInput, setInEmailInput] = useState<boolean>(true);
  /** Maneja un state para ocultar los inputs relacionados al password al cargar la web    */
  const [inPasswordInput, setInPasswordInput] = useState<boolean>(false);

  /** Declaración inicial de los botones como deshabilitado    */
  const [stateButton, setStateButton] = useState<string>('btn-primary-text-standard disabled');
  /** Declaración inicial un input en su estado normal    */
  const [inputWithError, setInputWithError] = useState<boolean>(false);
  /** Maneja un state para el check de mantenerme conectado*/
  const [checkKeep, setCheckKeep] = useState<boolean>(true);
  /**Cambia el estado del check mantenerme conectado  */
  const changeCheckKeep = (): void => setCheckKeep(!checkKeep);
  /** Maneja el state messageError para los inputs  */
  const [messageError, setMessageError] = useState<string>('');
  /** Maneja un state ocultar o mostrar el password digitado  */
  const [showPasswordText, setShowPasswordText] = useState<boolean>(false);

  /** Muestra y Oculta la contraseña cada vez que se declara  */
  const switchPasswordText = (): void => setShowPasswordText(!showPasswordText);

  /** Enciende el input relacionado al email*/
  const onEmailInput = (): void => setInEmailInput(true);
  /** Apaga el input relacionado al email*/
  const offEmailInput = (): void => setInEmailInput(false);

  /** Enciende el input relacionado a la contraseña*/
  const onPasswordInput = (): void => setInPasswordInput(true);
  /** Apaga el input relacionado a la contraseña*/
  const offPasswordInput = (): void => setInPasswordInput(false);

  /** Habilita un botón para avanzar a la siguiente fase*/
  const onButton = (): void => setStateButton('btn-primary-text-standard');
  /** Deshabilita un botón para bloquear el avance a la siguiente fase*/
  const offButton = (): void => setStateButton('btn-primary-text-standard disabled');

  const LOGIN_TEXT: string = 'Iniciar Sesión (Administrador)';

  /** Función para activar input,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Enciende el botón,
   */
  const validInput = (): void => {
    setMessageError('');
    setInputWithError(false);
    onButton();
  };

  /** Función para etiquetar un input con errores
   *  Coloca los mensajes como error (en caso de existir),
   *  Muestra los iconos de error,
   *  Coloca el input como error,
   *  Apaga el botón,
   */
  const errorInput = (): void => {
    setInputWithError(true);
    offButton();
  };

  /** Función para etiquetar un input vacío,
   *  Oculta los mensajes,
   *  Oculta los iconos de error,
   *  Coloca el input como valido (formato inicial),
   *  Apaga el botón,
   */
  const emptyInput = (): void => {
    setMessageError('');
    setInputWithError(false);
    offButton();
  };

  /** Función para moverse de Email a Contraseña
   *  Apaga Input de Email
   *  Enciende Input de Contraseña,
   *  Declara el input en su estado inicial,
   *  Muestra el icono para Ocultar/Mostrar contraseña
   */
  const switchEmailToPassword = (): void => {
    onPasswordInput();
    offEmailInput();
    passwordRef.current!.value === '' && emptyInput();
  };

  /** Función para moverse de Email a Contraseña
   *  Enciende Input de Email
   *  Apaga Input de Contraseña,
   *  Setea el input como válido,
   *  Oculta los datos del input de contraseña
   */
  const switchPasswordToEmail = (): void => {
    onEmailInput();
    offPasswordInput();
    validInput();
    setShowPasswordText(false);
  };

  /** Manejador del evento onChange en los input email y password  */
  const handleInput = async (): Promise<boolean> => {
    let error: boolean = false;

    const validation = await handleSubmit(false);
    const errorMessage = () => {
      errorInput();
      setMessageError(validation.message[0]);
      error = true;
    };
    /** Validación del formato del email  y password   */
    if (inEmailInput ? validation.inputError === 'email' : null) {
      errorMessage();
      error = true;
    } else if (inPasswordInput ? validation.inputError === 'password' : null) {
      errorMessage();
    } else {
      validInput();
    }
    return error;
  };

  /** Resetea y Cierra el Formulario  */
  const closeForm = (): void => {
    resetForm();
    handleShowLogin();
  };

  /** Resetea el formulario de Registro,
   * Enciende el Input email y oculta el resto de Inputs,
   * Define el Input en su estado inicial,
   * Oculta iconos de error y componentes que no corresponden al input de email,
   * Borra todos los valores del form
   */
  const resetForm = (): void => {
    onEmailInput();
    offPasswordInput();

    emptyInput();
    setShowPasswordText(false);
    loginFormRef.current?.reset();
  };

  /** Cambia el modal del login por el de registro  */
  // const toSignUp = (): void => {
  //   handleShowRegister();
  //   closeForm();
  // };

  /** Función para hacer login y redirigir al usuario a la ruta correspondiente */
  const handleSubmit = async (isALogin: boolean = true): Promise<IUserResponse> => {
    const keepLogin: boolean = sessionCheckRef.current?.checked ?? true;
    const userLogin: ILoginData = { email: emailRef.current?.value!, password: passwordRef.current?.value! };
    const doLoginResponse: IUserResponse = await doLogin(userLogin, isALogin, keepLogin);

    if (doLoginResponse.isError) {
      errorInput();
      setMessageError(doLoginResponse.message[0]);
    } else {
      closeForm();
    }
    return doLoginResponse;
  };

  /** Hook que maneja el cambio de estado del usuario registrado */
  useEffect(() => {
    //si el usuario es de tipo ADMIN, lo redirige a la ruta de inventario y si no a la ruta principal
    const isAdmin: boolean = user.kind === 'ADMIN';
    navigate(isAdmin ? RoutesDirections.INVENTORY_ROUTE : RoutesDirections.MAIN_ROUTE);

    window.scrollTo(0, 0);
  }, [user.kind]);

  return (
    <Modal id='login-modal' show={showLogin} onHide={handleShowLogin} fullscreen={true} animation={false}>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row id='login' className='min-vh-100 d-flex align-items-end flex-column p-0'>
            <Col xs={12} className='bg-ilis-modal min-vh-50'>
              <Col xs={12} className='align-self-center d-flex justify-content-around pb-5'>
                <div className='text-start w-10 align-top text-primary-4 pt-2 cursor-hand'>
                  <BackIcon onClick={inEmailInput ? closeForm : switchPasswordToEmail} classMain='btn-icon-dark ps-2 svg-primary-4' />
                </div>

                <div className='text-center w-100 py-3'>
                  <img src={LOGO} id='modal-mobile-logo' alt='logo' className='d-sm-none logo-full-modal'></img>
                </div>

                <div>
                  <img
                    src={LOGO}
                    id='modal-desktop-logo'
                    alt='logo'
                    className='login-desktop-logo d-none d-sm-block position-absolute top-10 start-10 logo-full-modal'></img>
                </div>
              </Col>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className={`px-5 bg-primary-4 position-absolute top-50 start-50 translate-middle rounded-top-5 ${!isMobile && 'shadow'}`}>
              <Col xs={12} className='pt-3 min-vh-25 d-flex align-items-end flex-column'>
                <Col xs={12} className='pb-4 text-center'>
                  <span className='h5 fw-bold'>{LOGIN_TEXT}</span>
                </Col>
                <Col xs={12}>
                  <Form
                    id='login-form'
                    ref={loginFormRef}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className='svg-tertiary-1'>
                    <div className={inEmailInput ? 'display' : 'invisible position-absolute'}>
                      <InputForm
                        className='mb-3'
                        label='Correo Electrónico'
                        classLabel='p-regular-medium'
                        id='input-login-email'
                        inputRef={emailRef}
                        type='email'
                        messageError={messageError}
                        onChange={handleInput}
                        withErrors={inputWithError}
                      />
                    </div>

                    <div className={inPasswordInput ? 'display' : 'invisible position-absolute'}>
                      <InputForm
                        label='Contraseña'
                        classLabel='p-regular-medium'
                        id='input-login-password'
                        inputRef={passwordRef}
                        type={showPasswordText ? 'text' : 'password'}
                        withErrors={inputWithError}
                        messageError={messageError}
                        onChange={handleInput}
                        lastIconClicked={switchPasswordText}
                        lastIconClass={`${inPasswordInput ? 'last-input-icon' : 'd-none'}`}
                        lastIconName={showPasswordText ? 'ico-hide' : 'ico-show'}
                      />
                    </div>
                    {!inEmailInput && (
                      <Form.Check
                        type={'checkbox'}
                        id={'login-session-check'}
                        label={'Mantenme Conectado'}
                        ref={sessionCheckRef}
                        checked={checkKeep}
                        onChange={changeCheckKeep}
                        className='p-regular'
                      />
                    )}
                    <div className='d-grid gap-2 mb-3'>
                      <Button className={stateButton} type='button' onClick={inEmailInput ? switchEmailToPassword : () => handleSubmit()}>
                        <span className='p-regular-medium'>{inEmailInput ? 'CONTINUAR' : 'INGRESAR'}</span>
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default LoginForm;
