import axios, { AxiosResponse } from 'axios';

/** Representa la interacción con el repositorio externo donde esta la configuración */
export default class ExternalDataConfiguration {
  /** Instancia de la clase   */
  private static _instance: ExternalDataConfiguration;

  /** Patron Singleton
   * @returns {instance} _instance ExternalDataConfiguration
   */
  static get instance(): ExternalDataConfiguration {
    if (!this._instance) {
      this._instance = new ExternalDataConfiguration();
    }
    return this._instance;
  }

  async getIndexJsonConfiguration(): Promise<AxiosResponse> {
    const jsonData: string = process.env.REACT_APP_INDEX_JSON_URL ?? '';
    return await axios.get(jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
