import React, { PropsWithChildren } from 'react';

import { useNavigate, type NavigateFunction } from 'react-router';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import ErrorImage from '@/ui/assets/ErrorImage';

/**Interface para container genérico */
export interface IInfoContainer {
  imageUrl: string;
  title?: string;
  titleImageUrl?: string;
  details: string;
  link?: string;
}

interface PropsInfoContainer {
  data: IInfoContainer;
}

const INFO_CONTAINER_BUTTON_TEXT = 'CONOCE MÁS';

const InfoContainer = ({ data }: PropsWithChildren<PropsInfoContainer>) => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;
  return (
    <Container className='py-lg-5'>
      <Row>
        <Col lg={6} className='mb-4 m-lg-0'>
          <Col xs={10} lg={8} className='m-auto'>
            <Image
              fluid
              src={data.imageUrl}
              alt={`Imagen ${data.imageUrl}`}
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </Col>
        </Col>

        <Col lg={6} className='d-flex p-0'>
          <Col lg={8} className='m-auto'>
            {data.title && (
              <Col className='mb-2 text-center text-lg-start'>
                <span className={`${isMobile ? 'h5' : 'h2'} text-primary-3 `}>{data.title}</span>
              </Col>
            )}
            {data.titleImageUrl && (
              <Col xs={10} className={`mb-4 text-center ${isMobile ? 'mx-auto' : ''}`}>
                <Image
                  fluid
                  src={data.titleImageUrl}
                  alt={`Imagen ${data.titleImageUrl}`}
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = ErrorImage;
                  }}
                />
              </Col>
            )}
            <Col className='text-center text-lg-start'>
              <span className='text-secondary-2'>{data.details}</span>
            </Col>
            {data.link && (
              <Col className='mt-4 text-center text-lg-start'>
                <a href={data.link} target='_blank' className='text-primary-1'>
                  <Button className={`btn-secondary-text-small px-5`}>{INFO_CONTAINER_BUTTON_TEXT}</Button>
                </a>
              </Col>
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoContainer;
