import { AxiosResponse } from 'axios';
import { useAppDispatch } from '@/ui/store/helperRedux';
import { updateCartRedux, removeCartRedux } from '@/ui/store/slices/cartSlice';

import CartLocalStorage from '@/data/localStorage/CartLocalStorage';

import OrdersRepository from '@/data/repositories/OrdersRepository';

import ProductsController from '@/controllers/ProductsController';

import { ICart, ICartDetail, ICartResponse, IClientDetail } from '@/domain/interfaces/ICart';
import { IWebPayResponse } from '@/domain/interfaces/IPayment';
import { IBackErrorDTO, IProductResponse } from '@/domain/interfaces/IProductResponse';

import { updateCart } from '@/domain/useCases/updateCartUseCase';

/** Representa el controlador que maneja los eventos de los casos de uso del carrito
 * @returns {object} Funciones del controlador
 */
const CartController = () => {
  /** Atualización de Redux */
  const dispatch = useAppDispatch();

  /**
   * Instancia del Repository
   * @const {OrdersRepository}
   */
  const ordersRepository = OrdersRepository.instance;

  /**
   * Instancia del LocalStorage
   * @const {CartLocalStorage}
   */
  const cartLocalStorage = CartLocalStorage.instance;
  /** Funciones del caso de uso actualizar carrito */
  const { addArticle, deleteArticle, isInCart, getArticleFromCart, getCart } = updateCart();

  const { getProductBySku } = ProductsController();

  const updateCartProductsData = async (): Promise<void> => {
    const cart = getCart();

    if (cart !== null) {
      const articlesInCart = cart.details ?? [];
      for (const article of articlesInCart) {
        await addToCart(article);
      }
    }
  };

  /** Agrega un articulo al carrito */
  const addToCart = async (article: ICartDetail): Promise<ICartResponse> => {
    /** Objeto de respuesta */
    let response: ICartResponse = {
      error: false,
      message: '',
      cart: null,
    };

    await getProductBySku(article.skuProduct)
      .then((productResponse: IProductResponse) => {
        if (productResponse.products.length > 0) {
          article.price = productResponse.products[0].price;
          article.offerPrice = productResponse.products[0].offerPrice;
          article.title = productResponse.products[0].title;
          productResponse.products[0].multimedia && (article.image = productResponse.products[0].multimedia[0]);

          article.status = productResponse.products[0].status;
          article.quantityAvailable = article.status !== 1 ? 0 : productResponse.products[0].quantityAvailable;

          article.attributes.splice(0, article.attributes.length, ...(productResponse.products[0].attributes ?? []));
          article.taxes.splice(0, article.taxes.length, ...(productResponse.products[0].taxes ?? []));
        }
      })
      .finally(() => {
        response = addArticle(article);
        if (!response.error) {
          if (response.cart !== undefined && response.cart !== null) {
            /** Actualiza el carrito en Redux */
            dispatch(updateCartRedux(JSON.parse(JSON.stringify(response.cart))));
          } else {
            /** Actualiza el carrito en Redux */
            dispatch(removeCartRedux());
          }
        }
      });

    return response;
  };

  /** Actualiza la información del cliente en el carrito */
  const updateClientInCart = (client: IClientDetail) => {
    const cart = getCart();
    if (cart === null) {
      return 0;
    } else {
      cart.clientDetails = client;
      cartLocalStorage.updateCart(cart);
      dispatch(updateCartRedux(JSON.parse(JSON.stringify(cart))));
    }
  };

  /** Elimina un articulo del carrito */
  const deleteFromCart = (article: ICartDetail): ICartResponse => {
    const response: ICartResponse = deleteArticle(article);

    if (!response.error) {
      if (response.cart === null) {
        /** Actualiza el carrito en Redux */
        dispatch(removeCartRedux());
      } else {
        dispatch(updateCartRedux(JSON.parse(JSON.stringify(response.cart))));
      }
    }

    return response;
  };

  /** Consulta si el producto esta en el carrito por su SKU */
  const articleInCart = (sku: string): boolean => {
    return isInCart(sku);
  };

  /** Devuelve la cantidad de articulos agregados en el carrito */
  const productQuantity = (): number => {
    const article = getCart();

    if (article === null) {
      return 0;
    } else {
      return article.totalProducts ?? 0;
    }
  };

  /** Devuelve la cantidad de articulos agregados en el carrito por su SKU */
  const productQuantityBySku = (sku: string): number => {
    const article = getArticleFromCart(sku) as ICartDetail | null;

    if (article === null) {
      return 0;
    } else {
      return article.quantity ?? 0;
    }
  };

  /** Genera la orden de compra y da como respuesta el Token y la URL */
  const purchaseOrder = async (cart: ICart): Promise<IWebPayResponse | number> => {
    /** Errores asociados a la reserva */
    const RESERVATION_ERRORS_CODES = ['ORD029', 'ORD028', 'ORD030'];

    const result = await ordersRepository
      .purchaseOrder(cart)
      .then((result: AxiosResponse<IWebPayResponse>) => {
        return result.data;
      })
      .catch((error) => {
        if (error.response) {
          const errors: IBackErrorDTO[] = error.response.data;
          const findReservationError = errors.find((error) => RESERVATION_ERRORS_CODES.includes(error.code));

          if (findReservationError) {
            return 500;
          } else {
            return error.response.status;
          }
        } else {
          return 500;
        }
      });

    return result;
  };

  /** Elimina el carrito */
  const deleteCart = (): void => {
    /** Eliminar el carrito de localStorage */
    cartLocalStorage.deleteCart();
    /** Actualiza el carrito en Redux */
    dispatch(removeCartRedux());
  };

  /** retorna la funciones del controlador */
  return { addToCart, deleteFromCart, articleInCart, productQuantityBySku, productQuantity, purchaseOrder, deleteCart, updateCartProductsData, updateClientInCart };
};

export default CartController;
