import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '@/ui/store/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
/** Almacena el hook useDispatch para typescript
    * @returns {object} useDispatch Redux
*/
export const useAppDispatch = () => useDispatch<AppDispatch>();
/** Almacena el hook useSelector para typescript
    * @returns {object} useSelector Redux
*/
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
