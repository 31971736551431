/** Representa la interacción con LocalStorage */
export default class PreInsertVariantLocalStorage {
  /** Instancia de la clase   */
  private static _instance: PreInsertVariantLocalStorage;

  /** Patron Singleton
   * @returns {instance} _instance CartLocalStorage
   */
  static get instance() {
    if (!this._instance) {
      this._instance = new PreInsertVariantLocalStorage();
    }
    return this._instance;
  }

  /** Key del objeto en el localStorage para columnas seleccionadas */
  private static selectedVariantProductLocalStorage = 'variantTableSelectedRows';

  /** Actualiza registros seleccionados  */
  public updateObj(objSelectedRows: { [index: number]: string }) {
    localStorage.setItem(PreInsertVariantLocalStorage.selectedVariantProductLocalStorage, JSON.stringify(objSelectedRows));
  }

  /** Obtiene registros seleccionados  */
  public getObj(): { [index: number]: string } | null {
    const localStorageRows = localStorage.getItem(PreInsertVariantLocalStorage.selectedVariantProductLocalStorage);
    return localStorageRows !== null ? JSON.parse(localStorageRows) : null;
  }

  /** Elimina el objeto de registros seleccionados */
  public deleteObj() {
    localStorage.removeItem(PreInsertVariantLocalStorage.selectedVariantProductLocalStorage);
  }
}
