/**
 * Librería de codigos de Status
 */
export enum StatusCodes {
  ACTIVE = 1,
  INACTIVE = 2,
  PENDING = 3,
  REJECTED_PAY = 4,
  ACCEPTED_PAY = 5,
  FINALIZED = 6,
  PROCESSING = 7,
  CANCELED = 8,
  ORDER_PROCESSING = 9,
  ORDER_PENDING = 10,
  ORDER_SENT = 11,
  ORDER_DELIVERED = 12,
  ORDER_CANCELED = 13,
  ORDER_ERROR = 14,
}

/**
 * Estructura de estados del sistema
 */
export interface IStatus {
  id: number;
  name: string;
  classname?: string;
  allowedTransitions?: number[];
}

const StatusList: IStatus[] = [
  {
    id: StatusCodes.ACTIVE,
    name: 'activo',
    classname: 'text-complementary-1',
  },
  {
    id: StatusCodes.INACTIVE,
    name: 'inactivo',
    classname: 'text-complementary-2',
  },
  {
    id: StatusCodes.PENDING,
    name: 'pendiente',
  },
  {
    id: StatusCodes.REJECTED_PAY,
    name: 'rechazado',
  },
  {
    id: StatusCodes.ACCEPTED_PAY,
    name: 'aceptado',
  },
  {
    id: StatusCodes.FINALIZED,
    name: 'finalizado',
  },
  {
    id: StatusCodes.PROCESSING,
    name: 'en proceso',
  },
  {
    id: StatusCodes.CANCELED,
    name: 'cancelado',
  },
  {
    id: StatusCodes.ORDER_PENDING,
    name: 'pendiente',
    allowedTransitions: [
      StatusCodes.ORDER_PROCESSING,
      StatusCodes.ORDER_SENT,
      StatusCodes.ORDER_DELIVERED,
      StatusCodes.ORDER_CANCELED,
      StatusCodes.ORDER_ERROR,
    ],
  },
  {
    id: StatusCodes.ORDER_PROCESSING,
    name: 'procesando',
    allowedTransitions: [StatusCodes.ORDER_SENT, StatusCodes.ORDER_DELIVERED, StatusCodes.ORDER_CANCELED, StatusCodes.ORDER_ERROR],
  },
  {
    id: StatusCodes.ORDER_SENT,
    name: 'enviado',
    allowedTransitions: [StatusCodes.ORDER_DELIVERED, StatusCodes.ORDER_CANCELED, StatusCodes.ORDER_ERROR],
  },
  {
    id: StatusCodes.ORDER_DELIVERED,
    name: 'entregado',
  },
  {
    id: StatusCodes.ORDER_CANCELED,
    name: 'cancelado',
  },
  {
    id: StatusCodes.ORDER_ERROR,
    name: 'error',
  },
];

export default StatusList;
