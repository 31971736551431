import { PropsWithChildren, useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';
import { StatusCodes } from '@/data/libraries/Status';
import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { saveAs } from 'file-saver';

import { type Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { Button, Col, Container, Image, Row, Table } from 'react-bootstrap';
import './style.css';

import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IOrderByCodeResponse, IOrderTaxes } from '@/domain/interfaces/IOrder';

import lastPageName from '@/domain/utils/lastPageName';
import currencyFormat from '@/domain/utils/currencyFormat';

import Icon from '@/ui/assets/Icon';
import ErrorImage from '@/ui/assets/ErrorImage';

import Breadcrumbs from '@/ui/components/Breadcrumbs';
import IconList from '@/ui/components/IconList';
import GifModal from '@/ui/components/modals/GifModal';
import OrdersController from '@/controllers/OrdersController';
import AlertModal from '@/ui/components/modals/AlertModal';

/**
 * Componente de detalles de seguimiento de orden.
 *
 * @param order - La orden de compra.
 * @returns Un componente de React que muestra los detalles de seguimiento de una orden de compra.
 *
 */

const TrackingOrderDetails = () => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';
  /**Gif de imagen URL */
  const DOWNLOAD_IMAGE_URL: string = `${URL_IMAGES}/client/doc-download.png`;
  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook para navegar entre páginas   */
  const navigate: NavigateFunction = useNavigate();

  const order: IOrderByCodeResponse | null = location.state.order;

  /**Controlador para obtener las órdenes */
  const { getOrderPdf } = OrdersController();

  /** Listado de breadcrumbs de la página */
  const breadcrumbsList: IBreadcrumbs[] = [
    { label: 'Inicio', url: RoutesDirections.MAIN_ROUTE },
    { label: lastPageName(location.pathname), url: location.pathname },
  ];

  /*Estados finales de la orden si no se encuentra el icono es un cubo por defecto y el nombre estado desconocido*/
  const errorStates = [StatusCodes.ORDER_ERROR, StatusCodes.ORDER_CANCELED];

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /**Modal de loading */
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);

  /** Estado inicial del modal de alertas de descarga de orden*/
  const [alertDownload, setAlertDownload] = useState<boolean>(false);

  /**Mapa de estados de la orden */
  const statusMap = {
    [StatusCodes.ORDER_PENDING]: { icon: 'ico-pending', name: 'Pendiente' },
    [StatusCodes.ORDER_PROCESSING]: { icon: 'ico-processing', name: 'Procesando' },
    [StatusCodes.ORDER_SENT]: { icon: 'ico-sent', name: 'Enviado' },
    [StatusCodes.ORDER_DELIVERED]: { icon: 'ico-national-shipment', name: 'Entregado' },
    [StatusCodes.ORDER_ERROR]: { icon: 'ico-error', name: 'Error' },
    [StatusCodes.ORDER_CANCELED]: { icon: 'ico-error', name: 'Cancelado' },
  };

  /*Mapa de estados de la orden a pasos */
  const statusToStepMap = {
    [StatusCodes.ORDER_PENDING]: 0,
    [StatusCodes.ORDER_PROCESSING]: 1,
    [StatusCodes.ORDER_SENT]: 2,
    [StatusCodes.ORDER_DELIVERED]: 3,
    [StatusCodes.ORDER_ERROR]: 3,
    [StatusCodes.ORDER_CANCELED]: 3,
  };

  /*extrae el nombre de los estados de la orden*/
  const getStatusName = (status: number) => {
    return statusMap[status]?.name || 'Desconocido';
  };

  /*extrae el nombre de los estados de la orden*/
  const getStatusIcon = (status: number) => {
    return statusMap[status]?.icon || 'ico-cube';
  };

  /*Extrae los detalles del estado de la orden*/
  const getStatusDetails = (status) => statusMap[status] || { icon: 'ico-cube', name: 'Estado desconocido' };

  /** Evento que muestra y oculta el modal de carga */
  const showLoading = (): void => {
    setShowDownloadModal(!showDownloadModal);
  };

  /** Función para descargar el archivo */
  const handleDownload = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    // Mostrar el modal de carga
    setShowDownloadModal(true);
    /** Solicita por medio del controlador el template file del inventario */
    const response: Blob | null = await getOrderPdf(order?.ordCodeNum);
    /**if que controla si la respuesta es null o no para mostrar el modal de alerta de error de descarga o descargar el archivo respectivamente */
    if (response === null) {
      setShowDownloadModal(false);
      setAlertDownload(true);
    } else {
      setShowDownloadModal(false);

      /** Descarga el archivo */
      saveAs(response, `order_${order?.ordCodeNum}.pdf`);
    }
  };

  useEffect(() => {
    !order && navigate(RoutesDirections.TRACKING_ROUTE);
  }, [order]);

  /**CONSTANTES DE TEXTO SEGUIMIENTO*/
  const PREPARING_TEXT: string = 'Pendiente';
  const PROCESSING_TEXT: string = 'En proceso';
  const SHIPPED_TEXT: string = 'Enviado';
  const DELIVERED_TEXT: string = 'Entregado';
  const CURRENT_STATUS_TEXT: string = 'Estado actual:';
  const ORDER_DETAILS_TEXT = 'Verifica la información de tu orden de compra y el estado en el que esta se encuentra.';
  const ORDER_TITLE_TEXT = 'Detalle orden de compra';
  const ID_ORDER_TEXT = `${isMobile ? 'ID:' : 'ID de la compra:'}`;
  const SEARCH_OTHER_ORDER_TEXT = 'BUSCAR OTRA COMPRA';

  /*CONSTANTES DE TEXTO RESUMEN DE COMPRA*/
  const RESUME_PURCHASE_TEXT: string = 'Resumen de la compra';
  const BUYER_TEXT: string = 'Comprador';
  const NAME_TEXT: string = 'Nombre:';
  const IDENTITY_CARD_TEXT: string = 'Documento de identidad:';
  const PHONE_TEXT: string = 'Número de celular:';
  const EMAIL_TEXT: string = 'Correo:';
  const SUB_TOTAL_TEXT: string = 'Sub Total';
  const PRODUCTS_TEXT: string = order?.ordTotalProducts === 1 ? 'Producto' : 'Productos';
  const ADDED_PRODUCTS_TEXT: string = order?.ordTotalProducts === 1 ? 'Agregado' : 'Agregados';
  const SHIPPING_TEXT: string = 'Envío:';
  const TOTAL_TAX_TEXT: string = 'Total impuestos:';
  const TOTAL_TEXT: string = 'Total';
  const EXEMPT_TEXT: string = 'Exento';

  /*CONSTANTES DE TEXTO DETALLE DE PRODUCTOS*/
  const ORDER_TEXT = 'Detalle de pedido';
  const ORDER_TYPE_TEXT = 'Tipo de entrega:';
  const ORDER_DELIVERY_TEXT = 'Dirección de entrega:';

  const DETAIL_TEXT = 'Detalle';
  const SHIPPING_TYPE_TEXT = 'Envío a domicilio';
  const QUANTITY_PRODUCT_TEXT = 'Cantidad:';
  const PRICE_PRODUCT_TEXT = 'Precio:';

  const LOADING_TEXT: string = 'Descargando, por favor espere...';

  const ERROR_DOWNLOAD_MESSAGE: string = 'Error al descargar el PDF, inténtalo nuevamente';

  /**Componente de Form Wizard de la orden */
  const StatusComponent = (): JSX.Element => {
    const getErrorStatusText: string = errorStates.includes(order?.status ?? 0) ? getStatusDetails(order?.status).name : null;

    const currentStep = statusToStepMap[order?.status ?? 0];

    return (
      <Row className='d-flex flex-column g-0 bg-primary-4 shadow rounded pb-3 px-4'>
        <Col className='d-flex my-3 py-2 rounded bg-secondary-3 text-primary-4'>
          <Col lg={6} className='d-flex justify-content-start align-items-center'>
            <span className='p-regular px-3'>{CURRENT_STATUS_TEXT}</span>
            <IconList iconName={getStatusIcon(order?.status ?? 0)} title={getStatusName(order?.status ?? 0)} classMain='svg-primary-4' />
          </Col>
        </Col>

        <Col className={`rounded bg-primary-5 py-4 border tracking-order pb-3`}>
          <Row className='g-0'>
            {getErrorStatusText ? (
              <>
                <Col xs={11} className='d-flex align-items-center mx-auto '>
                  <Col xs={6} className={`position-relative status-order-tab p-0 active`}>
                    <Col className='line'></Col>
                    <Icon name={'ico-pending'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                  <Col xs={6} className={`position-relative status-order-tab p-0 active`}>
                    <Col className='line'></Col>
                    <Icon name={'ico-error'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                </Col>
                <Col xs={11} className='d-flex align-items-center mx-auto '>
                  <Col xs={6} className={`text-center mt-4 status-order-text active`}>
                    <span>{PREPARING_TEXT}</span>
                  </Col>
                  <Col xs={6} className={`text-center mt-4 status-order-text active`}>
                    <span>{getErrorStatusText}</span>
                  </Col>
                </Col>
              </>
            ) : (
              <>
                <Col xs={11} className='d-flex align-items-center mx-auto '>
                  <Col xs={3} className={`position-relative status-order-tab p-0 ${currentStep >= 0 ? 'active' : ''} `}>
                    <Col className='line'></Col>
                    <Icon name={'ico-pending'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                  <Col xs={3} className={`position-relative status-order-tab p-0 ${currentStep >= 1 ? 'active' : ''}`}>
                    <Col className='line'></Col>
                    <Icon name={'ico-processing'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                  <Col xs={3} className={`position-relative status-order-tab p-0 ${currentStep >= 2 ? 'active' : ''}`}>
                    <Col className='line'></Col>
                    <Icon name={'ico-sent'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                  <Col xs={3} className={`position-relative status-order-tab p-0 ${currentStep > 2 ? 'active' : ''}`}>
                    <Col className='line'></Col>
                    <Icon name={'ico-national-shipment'} classMain='icon z-1 p-2 rounded-circle svg-primary-4' />
                  </Col>
                </Col>
                <Col xs={11} className='d-flex align-items-center mx-auto '>
                  <Col xs={3} className={`text-center mt-4 status-order-text ${currentStep >= 0 ? 'active' : ''}`}>
                    <span>{PREPARING_TEXT}</span>
                  </Col>
                  <Col xs={3} className={`text-center mt-4 status-order-text ${currentStep >= 1 ? 'active' : ''}`}>
                    <span>{PROCESSING_TEXT}</span>
                  </Col>
                  <Col xs={3} className={`text-center mt-4 status-order-text ${currentStep >= 2 ? 'active' : ''}`}>
                    <span>{SHIPPED_TEXT}</span>
                  </Col>
                  <Col xs={3} className={`text-center mt-4 status-order-text ${currentStep > 2 ? 'active' : ''}`}>
                    <span>{DELIVERED_TEXT}</span>
                  </Col>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  /**Componente de resumen de compra */
  const ResumeComponent = (): JSX.Element => (
    <Row className='g-0 my-2'>
      <Row className='g-0 bg-primary-4 shadow rounded  '>
        <Col lg={12} className='d-flex justify-content-center '>
          <Col lg={10} xs={11}>
            <Col className='text-start my-3  '>
              <span className={`text-primary-3  ${isMobile ? 'h5' : 'h3'} `}>{RESUME_PURCHASE_TEXT}</span>
            </Col>
          </Col>
        </Col>

        <Col lg={12} xs={12} className='d-flex justify-content-center  '>
          <Col lg={10} xs={11} className='d-flex justify-content-center border mb-5'>
            <Col lg={7} xs={11} className=' d-flex flex-column justify-content-between my-4'>
              <Col>
                <p className='mb-1 fw-bold text-secondary-2'>{BUYER_TEXT}</p>
              </Col>
              <Col className='d-flex justify-content-between mb-1'>
                <span className='text-secondary-2 text-nowrap pe-2'>&bull; {NAME_TEXT}</span>

                <span className='text-secondary-2 text-end'>{order?.customerName}</span>
              </Col>
              <Col className='d-flex justify-content-between mb-1'>
                <span className='text-secondary-2 text-nowrap'>&bull; {IDENTITY_CARD_TEXT}</span>
                <span className='text-secondary-2'>{`${order?.customerId.value ? order?.customerId.value : 'S/I'}`}</span>
              </Col>
              <Col className='d-flex justify-content-between mb-1'>
                <span className='text-secondary-2 text-nowrap'>&bull; {PHONE_TEXT}</span>
                <span className='text-secondary-2'>{`+${order?.customerPhone}`}</span>
              </Col>
              <Col className='d-flex justify-content-between '>
                <span className='text-secondary-2 text-nowrap'>&bull; {EMAIL_TEXT}</span>
                <span className='text-secondary-2'>{order?.customerEmail}</span>
              </Col>
              <hr />
              <Col className='my-2 d-flex justify-content-between'>
                <p className='m-0 fw-bold text-secondary-2'>
                  {SUB_TOTAL_TEXT} ({order?.ordTotalProducts} {PRODUCTS_TEXT}):
                </p>
                <span className='fw-bold text-secondary-2'>{currencyFormat(order?.ordTotalWithoutTax, ConfigNumberParameters.DECIMALS_ALLOWED)}</span>
              </Col>
              <Col className='d-flex justify-content-between mb-1'>
                <span className='text-secondary-2 text-nowrap'>
                  &bull; {PRODUCTS_TEXT} ({order?.ordTotalProducts} {ADDED_PRODUCTS_TEXT}):
                </span>
                <span className='text-secondary-2'>{currencyFormat(order?.ordTotalWithoutTax, ConfigNumberParameters.DECIMALS_ALLOWED)}</span>
              </Col>
              <Col className='d-flex justify-content-between mb-1'>
                <span className='text-secondary-2 text-nowrap'>&bull; {SHIPPING_TEXT}</span>
                <span className='text-secondary-2'>{currencyFormat(order?.ordTotalShipping, ConfigNumberParameters.DECIMALS_ALLOWED)}</span>
              </Col>
              <hr />
              <Col className='my-2 d-flex justify-content-between'>
                <p className='m-0 fw-bold text-secondary-2'>{TOTAL_TAX_TEXT}</p>
                <span className=' text-secondary-2 fw-bold'>
                  {order?.ordTotalTaxes === 0 || null ? (
                    <span className='fw-bold'>{EXEMPT_TEXT}</span>
                  ) : (
                    currencyFormat(order?.ordTotalTaxes, ConfigNumberParameters.DECIMALS_ALLOWED)
                  )}
                </span>
              </Col>
              {order?.orderTaxes.map((tax: IOrderTaxes) => (
                <Col key={order?.ordCodeNum} className='d-flex justify-content-between mb-1'>
                  <span className='text-secondary-2 text-nowrap'>&bull; {tax.name}</span>
                  <span className='text-secondary-2'>{`$${tax.amount}`}</span>
                </Col>
              ))}
              <hr />
              <Col className='d-flex justify-content-between mt-2'>
                <span className='h4 text-secondary-2'>{TOTAL_TEXT}</span>
                <span className='h4 text-secondary-2'>{currencyFormat(order?.totalAmount, ConfigNumberParameters.DECIMALS_ALLOWED)}</span>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
    </Row>
  );

  /**Componente de detalles de la orden */
  const OrderDetailsComponent = (): JSX.Element => (
    <Row className='g-0 bg-primary-4 shadow rounded my-2 '>
      <Col lg={12} className='d-flex justify-content-center '>
        <Col lg={10} xs={11}>
          <Col className='text-start my-3  '>
            <span className={`text-primary-3  ${isMobile ? 'h5' : 'h3'} `}>{ORDER_TEXT}</span>
          </Col>
          <Col lg={12} className='d-flex justify-content-between mb-2  '>
            <span className='text-primary-3 '>{ORDER_TYPE_TEXT}</span>
            <span className='text-primary-3 '>{SHIPPING_TYPE_TEXT}</span>
          </Col>
          <Col lg={12} className='d-flex justify-content-between mb-2 '>
            <span className='text-primary-3  '>{ORDER_DELIVERY_TEXT}</span>
            <span className='text-primary-3 text-end'>{order?.customerAddress}</span>
          </Col>
        </Col>
      </Col>

      <Col className='d-flex justify-content-center'>
        <Col lg={10} xs={11} className='mb-5'>
          <Col lg={12} xs={12} className='my-4'>
            <Col>
              <Table className='border-0'>
                <thead className='bg-secondary-3'>
                  <tr className='text-primary-5 '>
                    <th></th>
                    <th>
                      <p className='p-0 m-0 py-1'>{DETAIL_TEXT}</p>
                    </th>
                    <th>
                      <p className='text-center p-0 m-0 py-1'>{TOTAL_TEXT}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order?.orderProducts.map((product) => (
                    <tr className='border-bottom border-dark' key={`${order?.ordCodeNum}-${product.title}`}>
                      <td className='p-0'>
                        <Col className='text-center d-flex justify-content-center pt-5'>
                          <div className='image-crud-container rounded rounded-3 '>
                            <Image
                              className='rounded rounded-3 image-full'
                              src={product.image}
                              alt={''}
                              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                e.currentTarget.src = ErrorImage;
                              }}
                            />
                          </div>
                        </Col>
                      </td>

                      <td className='text-start '>
                        <Col>
                          <p>{product.title}</p>
                          <span className='paragraph pe-1'>
                            <b>{product.firstAttributeKey}</b>
                            {`:${product.firstAttributeValue},`}
                            <b> {product.secondAttributeKey}</b>
                            {`:${product.secondAttributeValue}`}
                          </span>

                          <p>
                            <span className='paragraph fw-bold pe-1'>{QUANTITY_PRODUCT_TEXT}</span>
                            <span>{product.quantity}</span>
                          </p>
                          <p className='fw-bold'>
                            <span className='paragraph fw-bold pe-1'>{PRICE_PRODUCT_TEXT}</span>
                            <span>{`$${product.price}`}</span>
                          </p>
                        </Col>
                      </td>
                      <td className='text-center'>
                        <span>{`$${product.totalPrice}`}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot></tfoot>
              </Table>
            </Col>
          </Col>
        </Col>
      </Col>
    </Row>
  );
  /**Renderizado del componente principal */
  return (
    <>
      {isMobile ? (
        <Container fluid className='p-0 bg-secondary-5 '>
          <Container className='p-0 bg-secondary-5 '>
            <Row className='g-0'>
              <Col lg={12} className='p-2 bg-primary-4 shadow rounded'>
                <Breadcrumbs />
              </Col>
            </Row>
            <Row className='g-0 bg-primary-4 shadow rounded my-2'>
              <Col lg={12} className='d-flex justify-content-between align-items-center px-3 mt-4 mb-3'>
                <span className='h5 text-primary-3 mb-1'>{ORDER_TITLE_TEXT}</span>
                <span className='text-primary-4 bg-secondary-3 badge rounded-pill px-3 py-3'>
                  {ID_ORDER_TEXT}
                  {order?.ordCodeNum}
                </span>
              </Col>
              <Col className='px-3 mb-4 text-secondary-2'>{ORDER_DETAILS_TEXT}</Col>
            </Row>
            <Row className='g-0 bg-primary-4 shadow rounded px-4  my-2'>
              <Col lg={3} className='border rounded mt-3'>
                <IconList
                  iconName={'ico-purchase-order'}
                  title={'Descargar Orden'}
                  subtitle={'Obtener una copia digital de tu orden.'}
                  classMain='cursor-hand w-100 p-1'
                  onClick={handleDownload}></IconList>
              </Col>
              <Col lg={9} className=' p-1 text-center my-3'>
                <Button className='svg-primary-4 btn-primary-icon-standard w-100' onClick={() => navigate(RoutesDirections.TRACKING_ROUTE)}>
                  {SEARCH_OTHER_ORDER_TEXT}
                </Button>
              </Col>
            </Row>

            <StatusComponent />

            <ResumeComponent />
            <OrderDetailsComponent />
          </Container>
        </Container>
      ) : (
        <Container fluid className='p-0 min-vh-75 bg-secondary-5'>
          <Container className='p-0 bg-secondary-5 min-vh-90'>
            <Row className='pt-4 g-0 '>
              <Col lg={12} className='p-2 bg-primary-4 shadow rounded'>
                <Breadcrumbs list={breadcrumbsList} />
              </Col>
            </Row>
            <Row className='g-0 bg-primary-4 shadow rounded my-4'>
              <Col lg={12} className='d-flex justify-content-between align-items-center px-4 mt-4 mb-2'>
                <span className=' h2 text-primary-3 '>{ORDER_TITLE_TEXT}</span>
                <span className=' h5 text-primary-4 bg-secondary-3 badge rounded-pill px-3'>
                  {ID_ORDER_TEXT} {order?.ordCodeNum}
                </span>
              </Col>
              <Col lg={6} className='px-4 mb-4 text-secondary-2'>
                {ORDER_DETAILS_TEXT}
              </Col>
            </Row>
            <Row className='g-0 bg-primary-4 shadow rounded px-4 mb-4'>
              <Col lg={3} className=' my-5 rounded'>
                <IconList
                  iconName={'ico-purchase-order'}
                  title={'Descargar Orden'}
                  subtitle={'Obtener una copia digital de tu orden.'}
                  classMain='cursor-hand w-100 p-1 btn-list-description-icon-light rounded-3 border border-1 '
                  onClick={handleDownload}></IconList>
              </Col>
              <Col lg={9} className='my-5 p-1 text-end'>
                <Button className='svg-primary-4 btn-primary-icon-standard px-5' onClick={() => navigate(RoutesDirections.TRACKING_ROUTE)}>
                  {SEARCH_OTHER_ORDER_TEXT}
                </Button>
              </Col>
            </Row>
            <StatusComponent />
            <Row className='p-0 g-0 my-3'>
              <ResumeComponent />
            </Row>
            <OrderDetailsComponent />
          </Container>
        </Container>
      )}
      <GifModal
        showModal={showDownloadModal}
        handleShowModal={showLoading}
        image={
          <Image
            src={DOWNLOAD_IMAGE_URL}
            width={200}
            alt='gif'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={<span className='p-large-bold mt-3'>{LOADING_TEXT}</span>}
      />
      {alertDownload && <AlertModal hideAlert={() => setAlertDownload(false)} type={'danger'} hide={6000} content={ERROR_DOWNLOAD_MESSAGE} />}
    </>
  );
};

export default TrackingOrderDetails;
