import { IProductForm, IVariantForm } from '@/domain/interfaces/IProduct';
import { IVariationValidation } from '@/domain/useCases/product/validateVariantUseCase';
import { IProductValidationBack } from '@/domain/interfaces/IProductResponse';
import { validateProductBaseBack } from '@/controllers/ProductsController';
import ProductBackErrors, { ProductBackCodes } from '@/data/libraries/errors/backErrors/ProductBackErrors';


/**
 * Caso de uso de validación de producto (variante) 
 * Solo valida una variante con el back
 * Homologación errores con el back
 * ProductBackCodes y ProductBackMessages son enums que guardan los códigos y los mensajes de error
 * 
 * @param {IVariantForm} product - data de producto (variante)
 * @param {IProductForm} product - data de producto base
 * @returns {IVariationValidation[]} errors
 */
const validateVariantBackUseCase = async (
    variant: IVariantForm,
    product: IProductForm,
    token: string,
): Promise<IVariationValidation[]> => {

    /** Objeto de respuesta con todos los errores */
    const errors: IVariationValidation[] = [];

    const validateProduct: IProductForm = JSON.parse(JSON.stringify(product));
    validateProduct.variants.splice(0, validateProduct.variants.length, JSON.parse(JSON.stringify(variant)));

    // Validación con el back
    await validateProductBaseBack(validateProduct, token)
        .then((response: IProductValidationBack) => {

            // Si existen errores se homologan
            if (response.error && response.productErrors && response.productErrors.length > 0) {

                const skuInStoreError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.EXISTING_SKU_ERROR;
                });
                if (skuInStoreError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.EXISTING_SKU_ERROR);
                    const errorMessage = errorStructure?.message ?? 'Error';
                    errors.push({ row: variant.row ?? 0, field: 'SKU', message: errorMessage });
                }

                const attributeCategoryError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.ATTRIBUTE_CATEGORY_ERROR;
                });
                if (attributeCategoryError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.ATTRIBUTE_CATEGORY_ERROR);
                    const errorMessage = errorStructure?.message ? errorStructure?.message.replace('REFERENCE_VALUE', attributeCategoryError.value) : 'Error';
                    errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: errorMessage });
                }

                const attributeRangeError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.ATTRIBUTE_VALUE_RANGE_ERROR;
                });
                if (attributeRangeError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.ATTRIBUTE_VALUE_RANGE_ERROR);
                    let errorMessage = errorStructure?.message ?? 'Error';

                    errorMessage = errorMessage.replace('REFERENCE_VALUE', attributeRangeError.value);
                    errorMessage = errorMessage.replace('MIN_RANGE', attributeRangeError.range.min.toString());
                    errorMessage = errorMessage.replace('MAX_RANGE', attributeRangeError.range.max.toString());

                    errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: errorMessage });
                }

                const attributeDecimalError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.UNEXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR;
                });
                if (attributeDecimalError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.UNEXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR);
                    let errorMessage = errorStructure?.message ?? 'Error';
                    errorMessage = errorMessage.replace('REFERENCE_VALUE', attributeDecimalError.value);

                    errors.push({ row: variant.row ?? 0, field: 'ATRIBUTOS', message: errorMessage });
                }

                const repeatedAttributeError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.REPEATED_ATTRIBUTES_ERROR;
                });
                if (repeatedAttributeError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.REPEATED_ATTRIBUTES_ERROR);
                    const errorMessage = errorStructure?.message ?? 'Error';
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

                const officeIdError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.NON_EXISTENT_BRANCH_OFFICE_ERROR;
                });
                if (officeIdError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.NON_EXISTENT_BRANCH_OFFICE_ERROR);
                    const errorMessage = errorStructure?.message ?? 'Error';
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

                const attributeKeyError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.NON_EXISTENT_ATTRIBUTE_ERROR;
                });
                if (attributeKeyError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.NON_EXISTENT_ATTRIBUTE_ERROR);
                    let errorMessage = errorStructure?.message ?? 'Error';
                    errorMessage = errorMessage.replace('REFERENCE_VALUE', attributeKeyError.value);
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

                const taxIdError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.NON_EXISTENT_TAX_ERROR;
                });
                if (taxIdError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.NON_EXISTENT_TAX_ERROR);
                    let errorMessage = errorStructure?.message ?? 'Error';
                    errorMessage = errorMessage.replace('REFERENCE_VALUE', taxIdError.value);
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

                const duplicatedTaxError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.DUPLICATED_TAX_ERROR;
                });
                if (duplicatedTaxError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.DUPLICATED_TAX_ERROR);
                    let errorMessage = errorStructure?.message ?? 'Error';
                    errorMessage = errorMessage.replace('REFERENCE_VALUE', duplicatedTaxError.value);
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

                const statusError = response.productErrors[0].errors.find((errorBack) => {
                    return errorBack.code === ProductBackCodes.NON_EXISTENT_STATUS_ERROR;
                });
                if (statusError) {
                    const errorStructure = ProductBackErrors.find((error) => error.code === ProductBackCodes.NON_EXISTENT_STATUS_ERROR);
                    const errorMessage = errorStructure?.message ?? 'Error';
                    errors.push({ row: variant.row ?? 0, field: 'VARIANTE', message: errorMessage });
                }

            }
        });

    return errors;
}

export default validateVariantBackUseCase;
