/**
 * Librería de mensajes de errores de producto base
 */
enum BaseProductCodes {
  REQUIRED_ERROR = 'Campo requerido',
  NAME_MIN_LENGHT_ERROR = 'Contenga al menos REFERENCE caracteres',
  NAME_MAX_LENGHT_ERROR = 'Contenga máximo REFERENCE caracteres',
  BASE_PRODUCT_NAME_FORMAT = 'Texto y/o numeros sin caracteres especiales',
  BLANK_ERROR = 'Tienes mas de 1 espacio en blanco seguidos o espacios en blanco antes o despues del texto escrito',
  BLANK_ERROR_AFTER = 'Tienes espacios en blanco antes del texto escrito',
  DESCRIPTION_MIN_LENGHT_ERROR = 'Tamaño minimo invalido',
  DESCRIPTION_MAX_LENGHT_ERROR = 'Tamaño maximo invalido',
  URL_ERROR = 'El valor no cumple con el formato de una URL válida Ejemplo: (https://foo.com/bar)',
  DUPLICATE_VARIANTS_ERROR = 'Variantes de producto Base REFERENCE con los mismos valores de atributos',
  STATUS_VARIANTS_ERROR = 'La variante principal está inactiva, pero al menos una de las otras variantes está activa.',
  DUPLICATE_MAIN_VARIANTS_VALUES_ERROR = 'El producto Base REFERENCE no puede tener mas de una variante principal',
  VARIANTS_WITH_ERROR = 'El nuevo producto Base Nombre [REFERENCE] no puede tener variantes con ID',
  TAGS_ERROR = 'Tags duplicadas',
  FORMAT_ERROR = 'El valor no cumple con el formato requerido',
  TAGS_FORMAT = 'hasta 20 caracteres y/o números sin caracteres especiales',
  MAIN_VARIANTS_KEYS_ERROR_1 = 'Las llaves de atributos principales de las variantes de',
  MAIN_VARIANTS_KEYS_ERROR_2 = 'son diferentes.',
}

export default BaseProductCodes;

// Define un tipo que representa las claves del enum
export type BaseProductCodeKeysType = keyof typeof BaseProductCodes;

// Define un tipo que representa el valor del enum
export type BaseProductCodeValuesType = (typeof BaseProductCodes)[BaseProductCodeKeysType];
