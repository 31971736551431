import { createSlice } from '@reduxjs/toolkit';

import { IUserStore } from '@/domain/interfaces/IUser';

import {userLocalStorage} from '@/data/navLocalStorage';

/** Almacena la representacion del usuario en localStorage
  * @constant {IUserStore}
*/
const initialState: IUserStore = userLocalStorage();

const userClient = 'CLIENT'
const userAdmin = 'ADMIN'

/** Almacena el estado userState de Redux con sus reducers
  * @constant {object}
*/
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user.email = action.payload.email
      state.user.status = action.payload.status
      state.user.token = action.payload.token
      state.user.userStore = action.payload.storeId
      state.user.branchOffice = action.payload.branchOffice
      state.user.isLogued = true
      const name = action.payload.name ?? '';
      state.user.firstName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
      state.user.id = action.payload.id
      state.user.position = action.payload.position
      state.user.date = new Date().getTime()
      if (action.payload.roles.includes(userClient)
        && action.payload.roles.length > 1) {
          state.user.kind = userAdmin;
      } else {
        state.user.kind = userClient;
      }
      state.ip = '1.0.0.1'
    },
    logout: (state) => {
      state.user.email = null
      state.user.status = null
      state.user.token = null
      state.user.userStore = null
      state.user.branchOffice = null
      state.user.isLogued = false
      state.user.firstName = null
      state.user.position = null
      state.user.id = null
      state.user.kind = null
      state.ip = null
      state.user.date = 0
    },
    updClient: (state, action) => {
      state.user.email = action.payload.email ?? state.user.email
      state.user.firstName = action.payload.name ?? state.user.firstName
    },
    activate: (state, action) => {},
    deactivate: (state) => {},
  },
  
})

export const { login, logout, activate, deactivate, updClient } = userSlice.actions;
//export const selectCount = (state: RootState): number => state.counter.value

export default userSlice.reducer;
