import { RoutesDirections } from '@/data/libraries/Routes';

import MessageLayout from '@/ui/containers/MessageLayout';

/** Componente pagina NotFound
 *@component
 */
/** Propiedades del componente */
interface EmptyCartProps {
  onClick?: () => void;
}
/** Componente pagina NotFound */
const EmptyCart = ({ onClick }: EmptyCartProps): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /**Constantes de texto */
  const MAIN_TITLE = 'Tu carrito está vacio';
  const TITLE_DESCRIPTION = 'Busca o explora nuestras categorías para encontrar lo que necesitas.';
  const TITLE_LINK = 'Descubre nuestras novedades';

  return (
    <MessageLayout
      title={MAIN_TITLE}
      imgWidth='252px'
      imgSrc={`${URL_IMAGES}/client/cart.png`}
      description={TITLE_DESCRIPTION}
      linkText={TITLE_LINK}
      route={RoutesDirections.MAIN_ROUTE}
      onClick={onClick}
    />
  );
};

export default EmptyCart;
