import { useEffect, useState } from 'react';
import Icon from '@/ui/assets/Icon';

/**Botón que hace scroll arriba al presionarlo */
const ButtonScrollTop = () => {
  // Estado para manejar la visibilidad del botón
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Función que verifica la posición del scroll y actualiza la visibilidad del botón
  const toggleVisibility = () => {
    setIsVisible(window.scrollY > 100);
  };

  // Función que desplaza la página hacia arriba con un efecto de suavizado
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Efecto que añade un listener al evento de scroll para controlar la visibilidad del botón
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Limpieza del listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className='position-fixed bottom-0 end-0 ultra-top link-text-standard  bg-primary-3  p-2 rounded rounded-circle  mb-1 mb-lg-3 me-2 me-lg-4 svg-primary-4'>
          <Icon name='ico-up-arrow' />
        </button>
      )}
    </>
  );
};

export default ButtonScrollTop;
