import { PropsWithChildren } from 'react';

import { Container, Row, Col, Image } from 'react-bootstrap';

import ErrorImage from '@/ui/assets/ErrorImage';

import Title from '@/ui/components/Title';

/** Componente para mostrar la foto del usuario
 * @component
 */
const AvatarUser = ({ children }: PropsWithChildren): JSX.Element => {
  /**Variables de Entorno */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Icono usuario logueado */
  const UserLogued: string = `${URL_IMAGES}/static/user-logued.png`;

  return (
    <Container fluid className='px-0'>
      <Row className='g-0  align-items-center'>
        <Col xs={4} className={'text-center'}>
          <Image
            src={UserLogued}
            alt='avatar-user'
            className='img-fluid'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        </Col>
        <Col className='text-start'>
          <Title>{children}</Title>
        </Col>
      </Row>
    </Container>
  );
};

export default AvatarUser;
