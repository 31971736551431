import { useAppDispatch } from '@/ui/store/helperRedux';
import { updateAttributes } from '@/ui/store/slices/attributesSlice';

import ProductRepository from '@/data/repositories/ProductRepository';

import getProducts from '@/domain/useCases/getProductsUseCase';

import orderObjectsByProperties, { SortCriteria } from '@/domain/utils/orderObjectsByProperties';

import { IMainAttributesVariantsValues, IProduct, IProductForm } from '@/domain/interfaces/IProduct';
import {
  IInterfaceNameToValidate,
  IProductResponse,
  IProductResponseOrder,
  IProductValidation,
  IProductValidationBack,
  IResponseValidationBack,
} from '@/domain/interfaces/IProductResponse';
import { IAttributesFilters } from '@/domain/interfaces/IAttributes';
import { IPricesRange } from '@/domain/interfaces/IPricesRange';

import { addProductBaseUseCase } from '@/domain/useCases/product/addProductBaseUseCase';
import { AxiosResponse } from 'axios';

/** Representa el controlador que maneja los eventos de los casos de uso de producto
 * @returns {object} Funciones del controlador
 */
const ProductsController = () => {
  /** Instancia del Repository
   * @const {ProductRepository}
   */
  const productRepository = ProductRepository.instance;

  const dispatch = useAppDispatch();

  /**
   * Solicita los productos disponibles en la tienda por full-text search, precios y/o filtros
   * @param {IAttributesFilters} filters
   * @param {number} page
   * @returns {IProductResponse} productResponse objeto de respuesta
   */
  const getProductsStore = async (filters: IAttributesFilters, page: number): Promise<IProductResponse> => {
    let productResponse: IProductResponse = { pagination: null, products: [], error: true, message: '' };

    /** Se entrega el texto de búsqueda al caso de uso para validar y fabricar el request */
    const searchValidation: IProductValidation = getProducts(filters);

    productResponse.message = searchValidation.message;
    if (!searchValidation.error) {
      /** Consulta el repository de los productos */
      const productsInStore = await productRepository.getProductsStore(filters, page)
        .then((result) => {
          return result.data;
        })
        .catch((error) => {
          if (error.response?.data) {
            productResponse.message = error.response.data;
            return error.response.status;
          } else {
            return error;
          }
        });

      if (productsInStore.products !== undefined && productsInStore.products.length > 0) {
        productResponse.error = false;
        productResponse.products = JSON.parse(JSON.stringify(productsInStore.products));
        productResponse.pagination = JSON.parse(JSON.stringify(productsInStore.pagination));
      }

      if (productsInStore.attributes !== undefined) {
        productResponse.attributes = JSON.parse(JSON.stringify(productsInStore.attributes));
        dispatch(updateAttributes(productResponse.attributes));
      }

      if (productsInStore.categories !== undefined) {
        productResponse.categories = JSON.parse(JSON.stringify(productsInStore.categories));
      }

      if (productsInStore.prices !== undefined) {
        productResponse.prices = JSON.parse(JSON.stringify(productsInStore.prices));
      }
    }
    return productResponse;
  };

  /**
   * Obtiene una lista de los productos más nuevos desde el repositorio.
   *
   * @param {number} productToShow - El número de productos nuevos a mostrar.
   * @returns {Promise<IProduct[]>} - Una promesa que resuelve en una lista de los productos más nuevos.
   */
  const getNewProducts = async (productToShow: number): Promise<IProduct[]> => {
    /** Consulta el repositorio de productos para obtener los productos más recientes. */
    const lastProducts = await productRepository
      .getNewProducts(productToShow)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return error;
      });

    return lastProducts;
  };

  /** Solicita los productos y sus atributos disponibles en la tienda por Slug de categoría y/o filtros
   * @param {IAttributesFilters} filters Categoría + filtros
   * @returns {IProductResponse} productResponse objeto de respuesta
   */
  const getProductsStoreByCategoryAndFilters = async (filters: IAttributesFilters, page: number): Promise<IProductResponse> => {
    const productResponse: IProductResponse = { pagination: null, products: [], attributes: [], error: true, message: '' };

    /** Consulta el repository por los productos */
    const productsInStore = await productRepository
      .getProductsStore(filters, page)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return error.response.status;
      });

    if (productsInStore.products !== undefined && productsInStore.products.length > 0) {
      productResponse.error = false;
      productResponse.products = JSON.parse(JSON.stringify(productsInStore.products));
      productResponse.pagination = JSON.parse(JSON.stringify(productsInStore.pagination));
    } else if (productsInStore === 422) {
      productResponse.message = 'Error de atributo';
    } else {
      productResponse.error = false;
      productResponse.message = filters.categorySlug ?? '';
    }

    if (productsInStore.attributes !== undefined) {
      productResponse.attributes = JSON.parse(JSON.stringify(productsInStore.attributes));
      dispatch(updateAttributes(productResponse.attributes));
    }
    if (productsInStore.prices !== undefined) {
      productResponse.prices = {...productsInStore.prices};
    }
    if (productsInStore.subCategoriesSlugs !== undefined) {
      productResponse.subCategoriesSlugs = [...productsInStore.subCategoriesSlugs];
    }
    return productResponse;
  };

  /**
   * Ordena los productos que se encuentran el el useState de los productos en la tienda.
   */
  const orderProducts = (products: IProduct[], sortCriteria: SortCriteria<IProduct>[]): IProductResponseOrder => {
    const productsResponse: IProductResponseOrder = { products: { ...products }, error: true, message: '' };

    const orderedProducts: IProduct[] = orderObjectsByProperties(products, sortCriteria);

    if (typeof orderedProducts !== 'string') {
      productsResponse.products = JSON.parse(JSON.stringify(orderedProducts));
      productsResponse.error = false;
    } else {
      productsResponse.message = orderedProducts;
    }

    return productsResponse;
  };

  /** Solicita los rangos de precios de la tienda entera o por categoría
   * @param {String} categorySlugValue
   * @returns {IPricesRange} prices
   */
  const getPriceRanges = async (categorySlugValue: string = ''): Promise<IPricesRange> => {
    /** Consulta el repository  */
    const prices = await productRepository.getPricesStore(categorySlugValue);

    return prices.data;
  };

  /** Solicita data de un producto especifico
   * @param {string} skuProduct
   * @returns {IProductResponse} productResponse objeto de respuesta
   */
  const getProductBySku = async (skuProduct: string): Promise<IProductResponse> => {
    let productResponse: IProductResponse = { pagination: null, products: [], error: true, message: '' };

    /** Consulta el repository de los productos */
    const product = await productRepository
      .getProductBySku(skuProduct)
      .then((result) => {
        productResponse.message = result.status.toString();
        return result;
      })
      .catch((error) => {
        if (error.response) {
          productResponse.message = error.response.data;
          return error.response.status;
        } else if (error.request) {
          productResponse.message = error.request;
          return error.request;
        } else {
          return error;
        }
      });

    if (product.status === '204') {
      productResponse.message = product.message;
    } else {
      productResponse.error = false;
      productResponse.products = [product.data];
    }

    return productResponse;
  };

  /** Consulta Variantes
   * @param {string} skuProduct
   * @returns {IProductResponse} productResponse objeto de respuesta
   */
  const getVariants = async (skuProduct: string): Promise<IProductResponse> => {
    let productResponse: IProductResponse = { pagination: null, products: [], error: true, message: '' };

    /** Consulta el repository */
    const products = await productRepository
      .getVariantsBySku(skuProduct)
      .then((result) => {
        productResponse.message = result.status.toString();
        return result;
      })
      .catch((error) => {
        if (error.response) {
          productResponse.message = error.response.data;
          return error.response.status;
        } else if (error.request) {
          productResponse.message = error.request;
          return error.request;
        } else {
          return error;
        }
      });

    if (products.status === '204') {
      productResponse.message = products.message;
    } else {
      productResponse.error = false;
      productResponse.products = products.data;
    }

    return productResponse;
  };

  /** Lista atributo principal y sus posibles valores agrupados, de todas las variantes asociadas al SKU consultado
   * @param {string} skuProduct
   * @returns {IMainAttributesVariantsValues[]}
   */
  const getMainAttributeValuesVariants = async (skuProduct: string): Promise<IMainAttributesVariantsValues[] | null> => {
    /** Consulta el repository */
    const mainAttributeValues = await productRepository
      .getMainAttributeValuesBySku(skuProduct)
      .then((result: AxiosResponse<IMainAttributesVariantsValues[]>) => {
        return result.data;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });

    return mainAttributeValues;
  };

  /** Consulta Variantes
   * @param {string} skuProduct
   * @returns {IProductResponse} productResponse objeto de respuesta
   */
  const getVariantsSecondCustomization = async (skuProduct: string): Promise<IProductResponse> => {
    let productResponse: IProductResponse = { pagination: null, products: [], error: true, message: '' };

    /** Consulta el repository */
    const products = await productRepository
      .getVariantsSecondCustomization(skuProduct)
      .then((result) => {
        productResponse.message = result.status.toString();
        return result;
      })
      .catch((error) => {
        if (error.response) {
          productResponse.message = error.response.data;
          return error.response.status;
        } else if (error.request) {
          productResponse.message = error.request;
          return error.request;
        } else {
          return error;
        }
      });

    if (products.status === '204') {
      productResponse.message = products.message;
    } else {
      productResponse.error = false;
      productResponse.variantsAttr = products.data;
    }

    return productResponse;
  };

  /**
   * Creación de producto base - Validaciones
   * @param {IProductForm} product - data para nuevo registro
   * @param {boolean} send - se determina si se debe guardar o si solo valida
   * @param {IInterfaceNameToValidate} interfaceName - nombre de la interfaz que valida
   * @param {string} token
   * @returns {IProductValidation}
   */
  const addProductBase = async (
    product: IProductForm,
    send: boolean = false,
    interfaceName: IInterfaceNameToValidate,
    token: string = '',
  ): Promise<IProductValidation> => {
    const response = await addProductBaseUseCase(product, send, interfaceName, token);

    return response;
  };

  /**
   * retorna la funciones del controlador
   */
  return {
    getProductsStore,
    getNewProducts,
    orderProducts,
    getProductsStoreByCategoryAndFilters,
    getPriceRanges,
    getProductBySku,
    getVariants,
    getMainAttributeValuesVariants,
    getVariantsSecondCustomization,
    addProductBase,
  };
};

export default ProductsController;

/**
 * Validación de producto base
 * @param {IProductForm} product - data para nuevo registro
 * @param {string} token
 * @param {string} errorBackCode - código de error especifico del back
 * @returns {IProductValidation}
 */
export const validateProductBaseBack = async (
  product: IProductForm,
  token: string = '',
  errorBackCode: string = '',
): Promise<IProductValidationBack> => {
  /**
   * Instancia del Repository
   * @const {ProductRepository}
   */
  const productRepository = ProductRepository.instance;

  const response: IProductValidationBack = {
    error: false,
    baseProductErrors: [],
    productErrors: [],
  };

  await productRepository
    .validateProductBase(product, token)
    .then((result: AxiosResponse<IResponseValidationBack>) => {
      if (
        (result.data.baseProductErrors && result.data.baseProductErrors.length > 0) ||
        (result.data.productErrors && result.data.productErrors.length > 0)
      ) {
        /** Error */
        response.error = true;

        const baseProductErrors = JSON.parse(JSON.stringify(result.data.baseProductErrors));
        const productErrors = JSON.parse(JSON.stringify(result.data.productErrors));

        response.baseProductErrors?.splice(0, response.baseProductErrors.length, ...baseProductErrors);
        response.productErrors?.splice(0, response.productErrors.length, ...productErrors);

        // Si se solicita un error especifico del Back
        if (errorBackCode !== '') {
          response.error = false;
          if (baseProductErrors.length > 0) {
            const errorBackName = baseProductErrors.find((error) => {
              return error.code === errorBackCode;
            });

            if (errorBackName) {
              response.error = true;
            }
          }

          if (productErrors.length > 0) {
            const errorBackName = productErrors[0].errors.find((error) => {
              return error.code === errorBackCode;
            });

            if (errorBackName) {
              response.error = true;
            }
          }
        }
      }
    })
    .catch((err) => {
      response.error = true;
    });

  return response;
};
