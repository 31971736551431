import { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { ITechnicalSheet } from '@/domain/interfaces/IProduct';

import IconList from '@/ui/components/IconList';

interface PropsTechnicalSheet {
  /** Título de la ficha */
  title: string;
  /** Arreglo con características de la ficha */
  technicalSheet: ITechnicalSheet[];
}

/**
 * Componente que muestra la información de la ficha técnica del producto
 * @component
 */
const TechnicalSheet = ({ title, technicalSheet }: PropsWithChildren<PropsTechnicalSheet>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  const DESCRIPTION_TEXT = 'Ficha Técnica';

  return (
    <Container className={`${isMobile ? 'pt-4' : ' py-5'}  `}>
      <Row className='g-0'>
        <Col lg={12} className={`d-flex ${isMobile ? 'justify-content-start h5' : 'justify-content-center'}`}>
          <IconList
            title={`${title && title.length > 0 ? title : DESCRIPTION_TEXT}`}
            iconName={'ico-technical-sheet'}
            classTitle='h2'
            classIcon='svg-primary-3'
            classMain='text-primary-3  justify-content-center'
          />
        </Col>
        <Col lg={12} className={`my-4 px-0 py-1 rounded border bg-secondary-4`}>
          {technicalSheet.map((item, index) => (
            <Row key={`technical-sheet-${index + 1}`} className={`g-0 `}>
              <Col md={isMobile ? 12 : 3} className={`bg-secondary-4 ps-2 ps-lg-3 p-2 text-secondary-2  `}>
                <span className='h4 paragraph'>{item.key}</span>
              </Col>
              <Col md={isMobile ? 12 : 9} className='bg-primary-4 py-2 px-3 border'>
                {item.value}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default TechnicalSheet;
