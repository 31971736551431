import { useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate, type Location, NavigateFunction } from 'react-router-dom';

import { Button, Col, Image, Row } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { changeLoginState } from '@/ui/store/slices/showLoginSlice';
import { changeSessionState } from '@/ui/store/slices/showSessionModalSlice';

import UserController from '@/controllers/UserController';

import { IUserStore } from '@/domain/interfaces/IUser';

import ErrorImage from '@/ui/assets/ErrorImage';

import GifModal from '@/ui/components/modals/GifModal';

const ToExpiredModal = (): JSX.Element => {
  /** VARIABLES DE ENTORNO */
  const URL_IMAGES: string = process.env.REACT_APP_URL_IMAGES ?? '';

  /** Manipula states de Redux */
  const dispatch = useAppDispatch();

  /** Controlador para renovar el token */
  const { tokenRenovation } = UserController();

  /**  Controlador para hacer logout */
  const { doLogout } = UserController();

  /** State Redux que muestra el modal */
  const showToExpiredModal: boolean = useAppSelector((state) => state.showSessionModal.value);

  /** State Redux usuario logueado */
  const userState: IUserStore = useAppSelector((state) => state.userState);

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** State de la visual de modal sesión expirada */
  const [showExpiredModal, setShowExpiredModal] = useState<boolean>(false);

  /** State contador - Inicia en 60 que corresponde a un minuto */
  const [counterSecs, setCounterSecs] = useState<number>(60);

  /* Función que redirige a la página de inicio */
  const handleRedirectHome = () => {
    handleToExpiredShowModal();
    navigate(RoutesDirections.MAIN_ROUTE, { state: { originURL: location.pathname } });
  };

  /* Función que redirige a la página de login */
  const handleRedirectLogin = () => {
    handleToExpiredShowModal();
    dispatch(changeLoginState());
  };

  /** Proceso de Logout en el controlador y cierra el formulario */
  const handleLogout = (): void => {
    doLogout();
    handleToExpiredShowModal();
  };

  /** Evento cierre de modal */
  const handleToExpiredShowModal = (): void => {
    dispatch(changeSessionState());
  };

  /** Evento cambio state de visual sesión expirada */
  const handleExpiredModal = (): void => {
    doLogout();
    setShowExpiredModal(!showExpiredModal);
  };

  //funcion que maneja la continuación
  const handleContinue = async () => {
    /** Solicita renovar el token al back */
    await tokenRenovation(userState.user.token ?? '');
    handleToExpiredShowModal();
  };

  /** Al renderizar el modal se decrementa el contador iniciado en 60 */
  useEffect(() => {
    const intervalMinute = setInterval(() => {
      setCounterSecs((prevCounterSecs) => {
        if (prevCounterSecs > 0) {
          return prevCounterSecs - 1;
        } else {
          clearInterval(intervalMinute);
          /** Cambia modal a la visual de sesión expirada */

          handleExpiredModal();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalMinute);
  }, []);

  /** CONSTANTES DE TEXTO E IMÁGENES */
  const TO_EXPIRED_IMAGE: string = `${URL_IMAGES}/client/to-expired.png`;
  const TITLE_TO_EXPIRED: string = 'Sesión a punto de expirar';
  const CLOSE_SESSION_TO_EXPIRED_BUTTON_TEXT: string = 'CERRAR SESIÓN ';
  const CONTINUE_BUTTON_TO_EXPIRED_TEXT: string = 'CONTINUAR';

  const EXPIRED_IMAGE: string = `${URL_IMAGES}/client/expired-modal.png`;
  const TITLE_EXPIRED: string = 'Sesión Expirada';
  const TITLE_EXPIRED_MESSAGE: string =
    'Tu sesión ha expirado por inactividad. Por favor, inicia sesión nuevamente para continuar o vuelve a la página de inicio.';
  const HOME_BUTTON_EXPIRED_TEXT: string = 'IR AL INICIO';
  const SESSION_BUTTON_EXPIRED_TEXT: string = 'INICIAR SESIÓN';

  return (
    <>
      {/*MODAL POR EXPIRAR*/}

      <GifModal
        showModal={showToExpiredModal}
        handleShowModal={handleToExpiredShowModal}
        showCloseIcon={false}
        className='px-2'
        image={
          <Image
            src={TO_EXPIRED_IMAGE}
            className='mb-3'
            width={200}
            alt='svg'
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              e.currentTarget.src = ErrorImage;
            }}
          />
        }
        message={
          <>
            <Col lg={12} className='text-primary-3 h5'>
              <span>{TITLE_TO_EXPIRED}</span>
            </Col>
            <Col lg={12} className='d-flex d-flex justify-content-center text-center  text-primary-3'>
              <Col lg={6}>
                <p className='m-0'>{`Por motivos de seguridad, tu sesión está a punto de expirar en ${counterSecs} segundos. ¿Deseas continuar?`}</p>
              </Col>
            </Col>
          </>
        }
        buttons={
          <Row className='d-flex align-items-center '>
            <Col className='text-end  pe-2'>
              <Button className='btn-secondary-text-small  mb-5 min-vw-11' onClick={handleLogout}>
                {CLOSE_SESSION_TO_EXPIRED_BUTTON_TEXT}
              </Button>
            </Col>
            <Col className='text-start  ps-2'>
              <Button className='btn-primary-text-small  mb-5 min-vw-11' onClick={handleContinue}>
                {CONTINUE_BUTTON_TO_EXPIRED_TEXT}{' '}
              </Button>
            </Col>
          </Row>
        }
      />
      {/*MODAL DE EXPIRADO*/}
      <GifModal
        showModal={showExpiredModal}
        handleShowModal={handleExpiredModal}
        showCloseIcon={false}
        image={
          <Col lg={12} className=' mt-5 pt-4 '>
            <Image
              src={EXPIRED_IMAGE}
              className='mb-3 '
              width={200}
              alt='svg'
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </Col>
        }
        message={
          <>
            <Col lg={12} className='text-primary-3 h5'>
              <span>{TITLE_EXPIRED}</span>
            </Col>
            <Col lg={12} className='d-flex d-flex justify-content-center text-center  text-primary-3'>
              <Col lg={7}>
                <p className='m-0'>{TITLE_EXPIRED_MESSAGE}</p>
              </Col>
            </Col>
          </>
        }
        buttons={
          <Row className='d-flex align-items-center   '>
            <Col className='text-end  pe-2'>
              <Button className='btn-secondary-text-small  mb-5 min-vw-11' onClick={handleRedirectHome}>
                {HOME_BUTTON_EXPIRED_TEXT}
              </Button>
            </Col>
            <Col className='text-start  ps-2'>
              <Button className='btn-primary-text-small mb-5 min-vw-11' onClick={handleRedirectLogin}>
                {SESSION_BUTTON_EXPIRED_TEXT}
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
};
export default ToExpiredModal;
