import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';

import { inventoryBulkLoadingValidation } from '@/controllers/InventoryController';

import validateExcelInventoryUseCase, { IProductBaseExcelValidation } from '@/domain/useCases/product/validateExcelInventoryUseCase';
import validateProductBaseUseCase, { IProductBaseValidation } from '@/domain/useCases/product/validateProductBaseUseCase';
import validateBulkLoadingTemplateUseCase from '@/domain/useCases/product/validateBulkLoadingTemplateUseCase';
import validateVariantUseCase, { IVariationValidation } from '@/domain/useCases/product/validateVariantUseCase';
import validateBulkLoadingBackUseCase from '@/domain/useCases/product/validateBulkLoadingBackUseCase';

import decimalFormat from '@/domain/utils/decimalFormat';
import filterValues from '@/domain/utils/filterValues';
import generateNumberId from '@/domain/utils/generateNumberId';

import { IBulkLoadingResponse } from '@/domain/interfaces/IInventory';
import { IProductForm, ITechnicalSpecification, IVariantForm } from '@/domain/interfaces/IProduct';
import { IAttributesForm } from '@/domain/interfaces/IAttributes';
import { IErrorsBulk, IResponseBulkLoad, IResponseBulkLoadBack } from '@/domain/interfaces/IBulkLoad';
import _ from 'lodash';

/** Representa el controlador que maneja los eventos de la carga masiva de productos base
 * @returns {object} Funciones del controlador
 *
 */

const BulkLoadController = () => {
  const productTemplate = useCreateBaseProductForm();

  /** Carga masiva de productos base
   * @param {any[]} productsData
   * @returns {IResponseBulkLoad} errores y objeto de producto base
   */
  const bulkLoad = (productsData: any[] = [], token: string = ''): IResponseBulkLoad => {
    const LINE_TEXT: string = 'Linea';

    /** Errores del cargue */
    const errorsBulkLoad: IErrorsBulk[] = [];

    /** Productos validos */
    const productsBulkLoad: IProductForm[] = [];

    /** Almacena todos los objetos del excel */
    const allProductsBulkLoad: IProductForm[] = [];

    /** Objeto auxiliar de variantes */
    const variantsArray: IVariantForm[] = [];

    /** Auxiliar nombre de producto, generamos un string random para comparar en la primera iteración */
    const randomString: string = String(generateNumberId()).padStart(5, '0');
    let nameBaseProductAux: string = String(randomString);

    /** Ordenar los productos por NOMBRE_PRODUCTO_BASE */
    productsData.sort(compareByBaseProductName);

    const errorsProduct: IProductBaseExcelValidation[] = validateExcelInventoryUseCase(productsData);

    errorsProduct.forEach((error) => {
      const row: string = error.row ? `${LINE_TEXT} ${error.row}` : '';
      errorsBulkLoad.push({ row: error.row, errorsBulkLoad: `${row} [${error.field}] = ${error.message}` });
      productsData.forEach((item) => {
        item.row === error.row && (item.excelError = true);
        error.filterKey === item.NOMBRE_PRODUCTO_BASE && (item.excelError = true);
        error.filterKey === item.SKU && (item.excelError = true);
        item.ID_PRODUCTO_BASE !== '' && error.filterKey === item.ID_PRODUCTO_BASE && (item.excelError = true);
      });
    });

    /**Valida y generar errores para los productos base */
    const validateAndAddProducts = (product: IProductForm) => {
      // Pregunta si tiene variantes principales
      const hasMainVariants = variantsArray.some((variant) => variant.main === true);

      // Ordena y agrega todas las variantes  si en el arreglo tiene variantes principales
      if (hasMainVariants) {
        variantsArray.sort((a, b) => (b.main ? 1 : -1) - (a.main ? 1 : -1));
        const mainVariant = variantsArray.find((variant) => variant.main);
        product.status = mainVariant?.status === 2 ? 2 : 1;
      }

      product.variants = [...variantsArray];

      // Valida el producto base
      const errorsProduct: IProductBaseValidation[] = validateProductBaseUseCase(product);

      // Asigna los errores al arreglo de mensajes de errores
      errorsProduct.forEach((error) => {
        const row: string = error.row ? `${LINE_TEXT} ${error.row}` : '';
        errorsBulkLoad.push({ row: error.row, errorsBulkLoad: `${row} [${error.field}] = ${error.message}` });
      });

      //El objeto que va al back a validar no debe tener filtradas las variantes con error
      allProductsBulkLoad.push(JSON.parse(JSON.stringify(product)));

      product.variants = product.variants.filter((objeto) => !objeto.bulkLoadingError);

      // Si el arreglo de errores tiene datos en ese producto base le habilitará el estado de error
      errorsProduct.length > 0 && (product.bulkLoadingError = true);
      // Si el arreglo de variantes dentro del prodcto esta vacio el producto base se marcara con errores
      product.variants.length === 0 && (product.bulkLoadingError = true);

      !product.bulkLoadingError && productsBulkLoad.push(product);
    };

    const productsDataFiltered = productsData.filter((producto) => !producto.excelError);

    let product: IProductForm = JSON.parse(JSON.stringify(productTemplate));

    let index: number = 0;
    for (const element of productsDataFiltered) {
      if (nameBaseProductAux !== element.NOMBRE_PRODUCTO_BASE) {
        if (nameBaseProductAux !== randomString) {
          // Llamar a la función para validar y agregar errores
          validateAndAddProducts(product);
        }

        nameBaseProductAux = element.NOMBRE_PRODUCTO_BASE;
        variantsArray.splice(0, variantsArray.length);

        product = JSON.parse(JSON.stringify(productTemplate));

        // Tags
        const tagsTemplate = element.TAGS_PRODUCTO_BASE === '' ? null : String(element.TAGS_PRODUCTO_BASE).split(',');
        const tagsTrim = tagsTemplate !== null ? tagsTemplate.map((element) => String(element).trim().toLowerCase() ?? '') : [];
        const tagsFiltered = filterValues(tagsTrim, '');

        // Arreglo de tipos de envío
        /*const shippingTypes: number[] = [
          element.ENVIO_NACIONAL_PRODUCTO_BASE === 'SI' ? 1 : 0,
          element.ENVIO_INTERNACIONAL_PRODUCTO_BASE === 'SI' ? 2 : 0,
          element.RETIRO_EN_TIENDA_PRODUCTO_BASE === 'SI' ? 3 : 0
        ];
        const filteredShippingTypes = filterValues(shippingTypes, 0); */

        product.id = !isNaN(parseInt(element.ID_PRODUCTO_BASE)) ? parseInt(element.ID_PRODUCTO_BASE) : 0;
        product.store = transformDataInt(element.ID_TIENDA);
        product.name = String(element.NOMBRE_PRODUCTO_BASE).trim() ?? '';
        product.description = String(element.DESCRIPCION_PRODUCTO_BASE).trim() ?? '';
        product.urlBanner = String(element.BANNER_PRODUCTO_BASE).trim() ?? null;
        product.tags = tagsFiltered;
        product.category = transformDataInt(element.CATEGORÍA_PRODUCTO_BASE);
        product.shippingTypes = [1]; //se envia en duro hasta que se implemente otros tipos de envio
        product.row = parseInt(element.row);
        product.bulkLoadingKey = generateNumberId();
      }

      // Arreglo de ID de Impuestos
      const taxes: number[] = [
        transformDataInt(element.IMPUESTO1),
        transformDataInt(element.IMPUESTO2),
        transformDataInt(element.IMPUESTO3),
        transformDataInt(element.IMPUESTO4),
      ];
      const filteredTaxes = filterValues(taxes, 0);

      /** Arreglo de URLs */
      const urls: string[] = [
        String(element.MEDIA1) === 'undefined' || element.MEDIA1 === null ? '' : String(element.MEDIA1).trim(),
        String(element.MEDIA2) === 'undefined' || element.MEDIA2 === null ? '' : String(element.MEDIA2).trim(),
        String(element.MEDIA3) === 'undefined' || element.MEDIA3 === null ? '' : String(element.MEDIA3).trim(),
        String(element.MEDIA4) === 'undefined' || element.MEDIA4 === null ? '' : String(element.MEDIA4).trim(),
        String(element.MEDIA5) === 'undefined' || element.MEDIA5 === null ? '' : String(element.MEDIA5).trim(),
        String(element.MEDIA6) === 'undefined' || element.MEDIA6 === null ? '' : String(element.MEDIA6).trim(),
        String(element.MEDIA7) === 'undefined' || element.MEDIA7 === null ? '' : String(element.MEDIA7).trim(),
        String(element.MEDIA8) === 'undefined' || element.MEDIA8 === null ? '' : String(element.MEDIA8).trim(),
        String(element.MEDIA9) === 'undefined' || element.MEDIA9 === null ? '' : String(element.MEDIA9).trim(),
        String(element.MEDIA10) === 'undefined' || element.MEDIA1 === null ? '' : String(element.MEDIA10).trim(),
      ];

      const filteredUrls = filterValues(urls, '');

      // Arreglo de objetos atributos convertidos
      const attributes: IAttributesForm[] = [
        stringToKeyValueConverter(element.ATRIBUTO1),
        stringToKeyValueConverter(element.ATRIBUTO2),
        stringToKeyValueConverter(element.ATRIBUTO3),
        stringToKeyValueConverter(element.ATRIBUTO4),
        stringToKeyValueConverter(element.ATRIBUTO5),
        stringToKeyValueConverter(element.ATRIBUTO6),
        stringToKeyValueConverter(element.ATRIBUTO7),
        stringToKeyValueConverter(element.ATRIBUTO8),
        stringToKeyValueConverter(element.ATRIBUTO9),
        stringToKeyValueConverter(element.ATRIBUTO10),
      ].filter((attribute) => attribute !== null) as IAttributesForm[];

      // Iteramos en cada atributo para las configuraciones adicionales
      for (let i = 0; i < attributes.length; i++) {
        // Le asigna una position a cada atributo
        attributes[i].position = i + 1;

        // Agregamos a los primeros dos el atributo main en true, al resto en false
        if (attributes[i] !== null && attributes[i] !== undefined) {
          if (attributes[i]) {
            attributes[i].main = i < 2;
          }
        }

        // Asignamos el tipo adecuado a cada atributo
        if (attributes[i].key.includes('_')) {
          attributes[i].type = 'quantitative';
          attributes[i].value = decimalFormat(attributes[i].value);
        } else {
          attributes[i].type = 'qualitative';
        }
      }

      // Arreglo de objetos especificaciones convertidas
      const specifications: ITechnicalSpecification[] = [
        stringToKeyValueConverter(element.ESPECIFICACION1),
        stringToKeyValueConverter(element.ESPECIFICACION2),
        stringToKeyValueConverter(element.ESPECIFICACION3),
        stringToKeyValueConverter(element.ESPECIFICACION4),
        stringToKeyValueConverter(element.ESPECIFICACION5),
        stringToKeyValueConverter(element.ESPECIFICACION6),
        stringToKeyValueConverter(element.ESPECIFICACION7),
        stringToKeyValueConverter(element.ESPECIFICACION8),
        stringToKeyValueConverter(element.ESPECIFICACION9),
        stringToKeyValueConverter(element.ESPECIFICACION10),
      ].filter((specification) => specification !== null) as ITechnicalSpecification[];

      //Crear una variante
      const variantProductObject: IVariantForm = {
        id: !isNaN(parseInt(element.ID_VARIANTE)) ? parseInt(element.ID_VARIANTE) : 0,
        baseProductName: String(element.NOMBRE_PRODUCTO_BASE).trim() ?? '',
        main: element.VARIANTE_PRINCIPAL === 'SI',
        sku: String(element.SKU) === 'undefined' || element.SKU == null ? null : String(element.SKU).trim(),
        branchOfficeId: String(element.SUCURSAL) === 'undefined' || element.SUCURSAL == null ? 0 : transformDataInt(element.SUCURSAL),
        transferable: element.ES_TRANSFERIBLE === 'SI',
        quantity:
          String(element.CANTIDAD) === 'undefined' || element.CANTIDAD == null ? null : parseFloat(String(element.CANTIDAD).trim().replace(',', '.')),
        price:
          String(element.PRECIO_BASE) === 'undefined' || element.PRECIO_BASE == null
            ? 0
            : parseFloat(String(element.PRECIO_BASE).trim().replace(',', '.')),
        offerPrice:
          String(element.PRECIO_OFERTA) === 'undefined' || String(element.PRECIO_OFERTA) === '' || element.PRECIO_OFERTA == null
            ? null
            : parseFloat(String(element.PRECIO_OFERTA).trim().replace(',', '.')),
        non_taxable: element.EXENTO_DE_IMPUESTOS === 'SI',
        taxes: filteredTaxes,
        media: filteredUrls,
        status: String(element.ESTADO) === 'undefined' || element.ESTADO === '' ? null : transformDataInt(element.ESTADO),
        attributes: filterValues(attributes, null),
        technicalSheetTitle: String(element.TITULO_FICHA_TECNICA).trim() ?? null,
        technicalSheet: filterValues(specifications, null),
        urlGuide: String(element.GUIA) === 'undefined' || element.GUIA == null ? null : String(element.GUIA).trim(),
        row: parseInt(element.row),
        bulkLoadingKey: generateNumberId(),
      };

      // Valida la variante
      const errorsVariant: IVariationValidation[] = validateVariantUseCase(variantProductObject);
      errorsVariant.forEach((error) => {
        const row: string = error.row ? `${LINE_TEXT} ${error.row}` : '';
        errorsBulkLoad.push({ row: parseInt(element.row), errorsBulkLoad: `${row} [${error.field}] = ${error.message}` });
      });

      // Si el arreglo de errores tiene datos en la variante  le habilitará el estado de error
      errorsVariant.length > 0 && (variantProductObject.bulkLoadingError = true);

      //Agrega la variante
      variantsArray.push(variantProductObject);

      // Insert del producto base del ultimo campo del documento excel
      if (index === productsDataFiltered.length - 1) {
        // Llamar a la función para validar y agregar errores
        validateAndAddProducts(product);
      }
      index++;
    }

    return { errorsBulkLoad, productsBulkLoad, allProductsBulkLoad };
  };

  /** Convierte el ultimo valor numerico despues del '_' en un numero */
  const transformDataInt = (value: string): number => {
    if (value) {
      // Valor iniciar de variable
      let transformedValue: number = 0;
      // Divide el string recibido en partes usando el guión bajo como separador
      const parts = String(value).split('_');

      // Verifica si hay al menos dos partes
      if (parts.length >= 2) {
        // El valor después del guión bajo se encuentra en la segunda parte (índice 1)
        transformedValue = parseInt(parts[1]);
      }

      return transformedValue;
    } else {
      return 0;
    }
  };

  /** Función para convertir un value en un objeto de ficha tecnica */
  const stringToKeyValueConverter = (value: string) => {
    if (value && value !== '') {
      const section: string[] = String(value).split('=') ?? []; // Dividimos la cadena recibida de texto en dos partes

      const specification = {
        key: section[0],
        value: section[1],
      };

      return specification;
    } else {
      return null;
    }
  };

  /** Función de comparación personalizada para ordenar por NOMBRE_PRODUCTO_BASE */
  const compareByBaseProductName = (a, b) => {
    if (a.NOMBRE_PRODUCTO_BASE) {
      const nombreA = String(a.NOMBRE_PRODUCTO_BASE).toUpperCase();
      const nombreB = String(b.NOMBRE_PRODUCTO_BASE).toUpperCase();

      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
    }
    return 0;
  };

  const validateTemplateFormat = (productsData: any[] = []): boolean => {
    const validFormat = validateBulkLoadingTemplateUseCase(productsData);
    return validFormat;
  };

  /**
   * Consulta el back-repository por errores y homologa errores en el caso de uso
   *
   * @param {IProductForm[]} productsBulkLoad
   * @param {IProductForm[]} allProductsBulkLoad
   * @param {string} token
   * @returns {IResponseBulkLoadBack}
   */
  const bulkLoadBackValidation = async (
    productsBulkLoad: IProductForm[],
    allProductsBulkLoad: IProductForm[],
    token = '',
  ): Promise<IResponseBulkLoadBack> => {
    /** Errores */
    const errorsBulkLoadBack: IErrorsBulk[] = [];

    /** Productos validos */
    const productsBulkLoadBack: IProductForm[] = [];

    if (token !== '') {
      // Validación masiva al back por medio del controlador
      await inventoryBulkLoadingValidation(allProductsBulkLoad, token ?? '')
        .then((responseController: IBulkLoadingResponse | null) => {
          if (responseController !== null) {
            // Si existen errores del back se homologan en el caso de uso
            const { errorsBulkLoad, productsBulkLoadFinal } = validateBulkLoadingBackUseCase(
              responseController.errors,
              productsBulkLoad,
              allProductsBulkLoad,
            );

            // Si existen errores homologados los inserta en el objeto de la respuesta
            if (errorsBulkLoad.length > 0) {
              errorsBulkLoadBack.push(...errorsBulkLoad);
            }
            // Si existen productos base y sus variantes validos los inserta en el objeto de la respuesta
            if (productsBulkLoadFinal.length > 0) {
              productsBulkLoadBack.push(...productsBulkLoadFinal);
            }
          } else {
            // Si no existen errores reportados por el back se insertan los objetos enviados a la función
            productsBulkLoadBack.push(...productsBulkLoad);
          }
        })
        .catch((err) => {
          console.error(err);
          productsBulkLoadBack.push(...productsBulkLoad);
        });
    } else {
      productsBulkLoadBack.push(...productsBulkLoad);
    }

    return { errorsBulkLoadBack, productsBulkLoadBack };
  };

  return { bulkLoad, bulkLoadBackValidation, validateTemplateFormat };
};

export default BulkLoadController;
