import { PropsWithChildren, useEffect, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { Col, Row, Container } from 'react-bootstrap';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import CartController from '@/controllers/CartController';
import ProductsController from '@/controllers/ProductsController';

import { ICartDetail } from '@/domain/interfaces/ICart';
import { IProduct } from '@/domain/interfaces/IProduct';

import Icon from '@/ui/assets/Icon';

/** Propiedades del componente */
interface PropsProductCounter {
  /** Identifica si el producto esta seleccionado */
  productSelected: boolean;
  /**SKU del Producto */
  skuProduct: string;
}

/**
 * Componente que lleva el conteo de los productos y administra el carrito asociado al producto
 * @component
 */

const ProductCounter = ({ productSelected, skuProduct }: PropsWithChildren<PropsProductCounter>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();
  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /**Estado redux del carrito */
  const cartProducts = useAppSelector((state: RootState) => state.cartState);

  /** Controlador de carrito */
  const { addToCart, deleteFromCart, productQuantityBySku, productQuantity } = CartController();

  /** Objeto inicial de un articulo para el carrito */
  const initialStateCurrentArticle: ICartDetail = {
    skuProduct: skuProduct ?? '',
    quantity: 0,
    price: 0,
    offerPrice: null,
    discount: 0,
    taxes: [],
    image: '',
    title: '',
    attributes: [],
    quantityAvailable: 0,
    status: 1,
  };

  /** State para manejar los articulos del carrito */
  const [article, setArticle] = useState<ICartDetail>();

  /** Promesa que trae el producto desde el controlador   */
  const { getProductBySku } = ProductsController();

  /** Objeto para la inicialización del producto */
  const initialStateCurrentProduct: IProduct = {
    id: '0',
    sku: '',
    title: '',
    description: '',
    bannerUrl: '',
    urlGuide: '',
    multimedia: [''],
    price: 0,
    offer: false,
    offerPrice: null,
    offerPercentage: 0,
    totalProducts: 0,
    slugCategory: '',
    quantityAvailable: 0,
    shippingTypes: [],
    status: 1,
  };

  /** Estado inicial del producto.  */
  const [product, setProduct] = useState<IProduct>(initialStateCurrentProduct);

  /**State del mensaje de disponibles en stock */
  const [messageAvailable, setMessageAvailable] = useState<string>('');

  /** Función asyncrona que trae la data del producto asociada al SKU */
  const fetchProductData = async (skuProduct: string): Promise<void> => {
    try {
      await getProductBySku(skuProduct).then((productResponse) => {
        if (productResponse.products.length > 0) {
          // Si la solicitud fue exitosa y hay datos del producto, establecer el estado 'product'
          const productData: IProduct = JSON.parse(JSON.stringify(productResponse.products[0]));
          if (productData.status !== 1) {
            productData.quantityAvailable = 0;
          }
          setProduct(productData);
        }
      });
    } catch (error) {}
  };

  /** Obtiene la cantidad de productos en el carrito según el SKU */
  const getQuantityBySku = (skuProduct): number => {
    return productQuantityBySku(skuProduct);
  };

  /** State del contador del componente ProductCounter */
  const [counter, setCounter] = useState<number>(getQuantityBySku(skuProduct));

  const [loadingCounter, setLoadingCounter] = useState(false);

  /** Obtiene la cantidad de productos en el carrito */
  const quantityInCart = (): number => productQuantity() ?? 0;

  /** Actualización del carrito agregar/quitar en el controlador */
  const handleUpdateCart = (avoidSend = false): void => {
    /** Se asigna el articulo a un objeto y se actualiza la información de cantidad, precio y precio oferta */
    const updatedArticle: ICartDetail = article && article !== null ? JSON.parse(JSON.stringify(article)) : { ...initialStateCurrentArticle };
    updatedArticle.title = product.title;
    updatedArticle.image = product.multimedia[0];
    updatedArticle.quantity = counter;
    updatedArticle.quantityAvailable = product.status !== 1 ? 0 : product.quantityAvailable;
    updatedArticle.price = product.price;
    updatedArticle.offerPrice = product.offerPrice;
    updatedArticle.attributes = product.attributes ?? [];
    updatedArticle.taxes = product.taxes ?? [];
    updatedArticle.skuProduct = skuProduct;

    /** Se actualiza el state de articulo */
    setArticle(updatedArticle);
    /** Actualiza carrito usando el controlador */
    !avoidSend && addToCart(updatedArticle);
  };

  /**Eliminamos el carrito */
  const handleDeleteFromCart = (): void => {
    /** Elimina el articulo del carrito usando el controlador */
    quantityInCart() > 0 && article && deleteFromCart(article);
    /** Se actualiza el state de articulo a su estado inicial */
    setArticle(initialStateCurrentArticle);
  };

  /** Manejador del contador enviado al componente ProductCounter */
  const handleCounter = (total: number): void => {
    setCounter(total);
  };

  /** Función que maneja el incremento del contador */
  const handleIncrement = (): void => {
    !loadingCounter && productSelected && counter < product.quantityAvailable && handleCounter(counter + 1);
  };

  /** Función que maneja el decremento del contador */
  const handleDecrement = (): void => {
    product.quantityAvailable < 1 || (!loadingCounter && productSelected && counter > 0 && handleCounter(counter - 1));
  };

  /** Función que maneja el reseteo del contador */
  const handleReset = (): void => {
    handleCounter(0);
  };

  /** Texto que cambia segun el stock del producto */
  const handleAvalaibleText = () => {
    let message = '';
    if (product.quantityAvailable > 0) {
      if (counter > 0 && counter === product.quantityAvailable) {
        message = MAXIMUN_STOCK_TEXT;
      } else if (counter !== product.quantityAvailable && product.quantityAvailable > ConfigNumberParameters.AVAILABLE_STOCK_DEFAULT_VALUE) {
        message = OVER_MAXIMUN_STOCK;
      } else {
        const availableText = product.quantityAvailable === 1 ? AVALAIBLE_SINGULAR : AVALAIBLE_PLURAL;
        message = `${product.quantityAvailable} ${availableText}`;
      }
    }

    setMessageAvailable(message);
  };

  /**UseEffect que actualiza el carrito y contador al cambiar el sku */
  useEffect(() => {
    handleUpdateCart(true);
    //setCounter(getQuantityBySku(skuProduct) ?? 0);
  }, []);

  /** UseEffect para traer la información del producto en base al SKU */
  useEffect((): void => {
    /** Consulta el producto según su SKU */
    fetchProductData(skuProduct);
    /** Se actualiza el state del contador a lo que trae el carrito o en su defecto a 0 */
    //setCounter(getQuantityBySku(skuProduct) ?? 0);
  }, [skuProduct]);

  /** UseEffect que actualiza o elimina el item del carrito  */
  useEffect(() => {
    counter > 0 ? handleUpdateCart() : handleDeleteFromCart();
  }, [counter]);

  useEffect((): void => {
    /** Se actualiza el state del contador a lo que trae el carrito o en su defecto a 0 */
    //setCounter(getQuantityBySku(skuProduct) ?? 0);
    handleUpdateCart();
    handleAvalaibleText();
  }, [product]);

  /**Use Effect que actualiza el contador si los productos no estan disponibles */
  /*useEffect((): void => {
    handleUpdateCart();
    handleAvalaibleText();
  }, [product.quantityAvailable, counter]);
*/

  /**Use Effect que actualiza el contador cuando se actualiza el carrito */
  useEffect((): void => {
    setCounter(getQuantityBySku(skuProduct) ?? 0);
    handleAvalaibleText();
  }, [cartProducts]);

  /**Se establece un segundo para actualizar el botón de cantidad */
  useEffect(() => {
    setLoadingCounter(true);
    const timeoutId = setTimeout(() => {
      setLoadingCounter(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [counter]);

  /**CONSTANTES DE TEXTO */

  const QUANTITY: string = 'Cantidad';
  const OVER_MAXIMUN_STOCK: string = 'Más de 100 disponibles';
  const MAXIMUN_STOCK_TEXT: string = 'Máximo disponible en stock';
  const AVALAIBLE_SINGULAR: string = 'disponible';
  const AVALAIBLE_PLURAL: string = 'disponibles';

  return (
    <Container fluid className='p-0'>
      <Row className='border border-secondary-4 g-0 ps-2 pe-3  py-2 rounded rounded-2 d-flex justify-content-between'>
        <Col xs={2} className='text-start d-flex align-items-center'>
          <span className='p-title-bold text-primary-3'>{QUANTITY}</span>
        </Col>
        <Col xs={9} className='d-flex justify-content-between px-4 align-items-center'>
          <Icon
            name='ico-less'
            onClick={handleDecrement}
            classMain={loadingCounter || counter < 1 || product.quantityAvailable < 1 ? 'svg-tertiary-5' : 'svg-tertiary-3'}
          />
          <span
            className={`p-large-bold ${
              !productSelected || product.quantityAvailable < 1 || counter > product.quantityAvailable ? 'text-tertiary-5' : 'text-tertiary-3'
            }`}>
            {counter}
          </span>
          <Icon
            name='ico-more'
            onClick={handleIncrement}
            classMain={
              loadingCounter ||
              !productSelected ||
              counter === product.quantityAvailable ||
              product.quantityAvailable < 1 ||
              counter > product.quantityAvailable
                ? 'svg-tertiary-5'
                : 'svg-tertiary-3'
            }
          />
        </Col>
        <Col xs={1} className='text-end'>
          {counter > 0 && <Icon name='ico-trash' onClick={handleReset} />}
        </Col>
      </Row>
      <Row className='g-0 '>
        <Col className='text-center'>
          <span
            className={`${isMobile ? 'p-small' : 'p-regular'}${product.quantityAvailable === 0 ? '-bold text-tertiary-1' : '-bold text-primary-3'}`}>
            {messageAvailable}
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductCounter;
