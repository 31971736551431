import ExternalDataConfiguration from '@/data/repositories/ExternalDataConfiguration';
import { IIndexConfiguration } from '@/domain/interfaces/IIndexConfiguration';


/** Representa el controlador que maneja los eventos de la página Index del proyecto
  * @returns {object} Funciones del controlador
*/
const IndexController = () => {

  const getIndexJsonConfiguration = async (): Promise<IIndexConfiguration | null> => {

    /** Instancia del Repository
    * @const {ExternalDataConfiguration}
    */
    const externalDataConfiguration = ExternalDataConfiguration.instance;
    let indexJson:IIndexConfiguration | null;
    try {
      const {data} = await externalDataConfiguration.getIndexJsonConfiguration();
      indexJson = data;
    } catch (error) {
      
      indexJson = null;
    }
    return indexJson;
  }

  /**
   * retorna la funciones del controlador
   */
  return { getIndexJsonConfiguration };
};

export default IndexController;
