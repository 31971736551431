import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { Col, Row, Accordion, Container } from 'react-bootstrap';

import { validateTechSpecificationsItemUseCase } from '@/domain/useCases/product/addProductBaseUseCase';

import { IProductForm, ITechnicalSpecification } from '@/domain/interfaces/IProduct';

import Icon from '@/ui/assets/Icon';

import InputForm from '@/ui/components/forms/InputForm';

/** Propiedades del componente */
interface PropsTechSpecificationsItem {
  /** index del elemento */
  index: number;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Data del formulario de las especificaciones técnicas */
  techSpecificationForm: ITechnicalSpecification[];
  /** Data del item de especificación técnica */
  techSpecificationData: ITechnicalSpecification;
  handleCheckItem: (isValid: boolean) => void;
  /** Función para eliminar */
  handleDelete: (position: number) => void;
  /** Función para cambiar datos */
  onChangeForm: (item: ITechnicalSpecification, index: number) => void;
}

/**
 * Componente para configurar las especificaciones técnicas de la variante principal
 * @component
 */

const TechSpecificationsItem = ({
  index,
  techSpecificationForm,
  techSpecificationData,
  handleCheckItem,
  handleDelete,
  onChangeForm,
}: PropsTechSpecificationsItem): JSX.Element => {
  /**Constantes de texto */
  const SPECIFICATION_ITEM_TITLE_TEXT: string = 'Titulo';
  const SPECIFICATION_ITEM_TITLE_PLACEHOLDER: string = 'Ingrese Titulo';
  const SPECIFICATION_ITEM_VALUE_TEXT: string = 'Características';
  const SPECIFICATION_ITEM_VALUE_PLACEHOLDER: string = 'Ingrese Características';

  /**Referencia al objeto en base al index */
  const initialTechSpecificationItem = useRef<ITechnicalSpecification>({ ...techSpecificationForm[index] });

  /** Referencia al input titulo */
  const titleRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Referencia al input descripción */
  const characteristicRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Declaración inicial input para las especificaciones técnicas en su estado normal    */
  const [techSpecificationsWithError, setTechSpecificationsWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error para las especificaciones técnicas  */
  const [techSpecificationsMessageError, setTechSpecificationsMessageError] = useState<string>('');

  /** Declaración inicial input para el titulo de las especificaciones técnicas en su estado normal    */
  const [techSpecificationsTitleWithError, setTechSpecificationsTitleWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de para el titulo de las especificaciones técnicas  */
  const [techSpecificationsTitleMessageError, setTechSpecificationsTitleMessageError] = useState<string>('');

  /** Declaración inicial input para el valor de las especificaciones técnicas en su estado normal    */
  const [techSpecificationsValueWithError, setTechSpecificationsValueWithError] = useState<boolean>(false);

  /** Declaración inicial del mensajes de error de para el valor de las especificaciones técnicas  */
  const [techSpecificationsValueMessageError, setTechSpecificationsValueMessageError] = useState<string>('');

  const [isAccordionOpen, setIsAccordionOpen] = useState<{ [key: number]: boolean }>({});

  /**longitud maxima de la descripción de un producto*/
  const BASE_PRODUCT_FEATURES_MAX_LENGTH = ConfigNumberParameters.MAXIMUM_DB_TEXT_LENGTH;

  /**Longitud maxima del titulo de la ficha de productos */
  const BASE_PRODUCT_FEATURES_TITLE_MAX_LENGTH = ConfigNumberParameters.VARIANT_TECHNICAL_SPECIFICATION_TITLE_MAX_LENGTH;

  /** Utiliza useState para mantener el estado del contador de caracteres */
  const [characterCount, setCharacterCount] = useState<number>(0);

  /** Utiliza useState para mantener el estado del contador de caracteres */
  const [titleCount, setTitleCount] = useState<number>(0);

  const toggleAccordion = (position: number) => {
    setIsAccordionOpen((prevState) => ({
      ...prevState,
      [position]: !prevState[position],
    }));
  };

  /** Función para contar caracteres */
  const countCharacters = () => {
    if (characteristicRef.current) {
      const count = characteristicRef.current.value.length;
      setCharacterCount(count);
    }
    if (titleRef.current) {
      const countTitle = titleRef.current.value.length;
      setTitleCount(countTitle);
    }
  };

  /** Onchange Input Titulo */
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    titleRef.current!.value = titleRef.current ? e.target.value : '';
    initialTechSpecificationItem.current.key = e.target.value;
    onChangeForm(
      {
        position: initialTechSpecificationItem.current.position,
        key: titleRef.current?.value ?? '',
        value: characteristicRef.current?.value ?? '',
      },
      index,
    );
    countCharacters();
    //Valida al cambiar el titulo
    handleValidateItem();
  };

  /** Onchange Input característica */
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    characteristicRef.current!.value = characteristicRef.current ? e.target.value : '';
    initialTechSpecificationItem.current.value = e.target.value;
    onChangeForm(
      {
        position: initialTechSpecificationItem.current.position,
        key: titleRef.current?.value ?? '',
        value: characteristicRef.current?.value ?? '',
      },
      index,
    );
    countCharacters();
    //Valida al cambiar la característica
    handleValidateItem();
  };

  /** Función que valida el valor del item de las especificaciones técnicas */
  const handleValidateItem = (): void => {
    const response = validateTechSpecificationsItemUseCase({
      position: initialTechSpecificationItem.current.position,
      key: titleRef.current?.value ?? '',
      value: characteristicRef.current?.value ?? '',
    });

    if (response.error) {
      handleCheckItem(false);
    } else {
      handleCheckItem(true);
    }

    if (response.error && response.kindError?.technicalSpecificationError && response.kindError?.technicalSpecificationError !== '') {
      setTechSpecificationsWithError(true);
      setTechSpecificationsMessageError(response.kindError.technicalSpecificationError);
    } else {
      setTechSpecificationsWithError(false);
      setTechSpecificationsMessageError('');
    }

    if (response.error && response.kindError?.technicalSpecificationKeyError && response.kindError?.technicalSpecificationKeyError !== '') {
      setTechSpecificationsTitleWithError(true);
      setTechSpecificationsTitleMessageError(response.kindError.technicalSpecificationKeyError);
    } else {
      setTechSpecificationsTitleWithError(false);
      setTechSpecificationsTitleMessageError('');
    }

    if (response.error && response.kindError?.technicalSpecificationValueError && response.kindError?.technicalSpecificationValueError !== '') {
      setTechSpecificationsValueWithError(true);
      setTechSpecificationsValueMessageError(response.kindError.technicalSpecificationValueError);
    } else {
      setTechSpecificationsValueWithError(false);
      setTechSpecificationsValueMessageError('');
    }
  };

  useLayoutEffect(() => {
    titleRef.current!.value = techSpecificationData.key ?? '';
    characteristicRef.current!.value = techSpecificationData.value ?? '';
    countCharacters();
  }, [techSpecificationForm]);

  useEffect(() => {
    handleValidateItem();
  }, [techSpecificationData]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12} className='text-end text-complementary-2 mt-3'>
          <span>{techSpecificationsMessageError}</span>
        </Col>
        <Col lg={12} className={`border border-2 rounded my-2 ${techSpecificationsWithError ? 'border-complementary-2' : ''}`}>
          <Accordion defaultActiveKey='0'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className='g-0 p-0' onClick={() => toggleAccordion(techSpecificationData.position ?? 0)}>
                {isAccordionOpen[techSpecificationData.position ?? 0] && `${SPECIFICATION_ITEM_TITLE_TEXT}: ${titleRef.current?.value ?? ''}`}
              </Accordion.Header>
              <Accordion.Body className='g-0 p-0'>
                <Col lg={12} className='d-flex justify-content-center text-secondary-2'>
                  <Col lg={11}>
                    <InputForm
                      label={SPECIFICATION_ITEM_TITLE_TEXT}
                      classLabel='p-regular-medium'
                      type='text'
                      inputRef={titleRef}
                      placeholder={SPECIFICATION_ITEM_TITLE_PLACEHOLDER}
                      onChange={handleTitleChange}
                      withErrors={techSpecificationsTitleWithError}
                      messageError={techSpecificationsTitleMessageError}
                    />
                    <Col className='text-end'>
                      <span
                        className={`p-regular-bold  ${
                          titleCount >= BASE_PRODUCT_FEATURES_TITLE_MAX_LENGTH && 'text-complementary-2'
                        }`}>{`${titleCount}/${BASE_PRODUCT_FEATURES_TITLE_MAX_LENGTH}`}</span>
                    </Col>
                    <InputForm
                      label={SPECIFICATION_ITEM_VALUE_TEXT}
                      classLabel='p-regular-medium'
                      type='textarea'
                      placeholder={SPECIFICATION_ITEM_VALUE_PLACEHOLDER}
                      inputRef={characteristicRef}
                      onChange={handleValueChange}
                      rowsValue={5}
                      withErrors={techSpecificationsValueWithError}
                      messageError={techSpecificationsValueMessageError}
                    />
                    <Col className='text-end'>
                      <span
                        className={`p-regular-bold  ${
                          characterCount >= BASE_PRODUCT_FEATURES_MAX_LENGTH && 'text-complementary-2'
                        }`}>{`${characterCount}/${BASE_PRODUCT_FEATURES_MAX_LENGTH}`}</span>
                    </Col>
                  </Col>
                </Col>
                <Col xs={12} className='d-flex justify-content-end'>
                  <Icon name='ico-trash' classMain='me-2 mb-2 cursor-hand' onClick={() => handleDelete(index)} />
                </Col>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default TechSpecificationsItem;
