import axios from 'axios';
import config from '@/data/services/api';
import { ITaxes } from '@/domain/interfaces/ITaxes';
import { AxiosResponse } from 'axios';

/** Representa la interacción con el Back */
export default class TaxesRepository {
  /** Instancia de la clase   */
  private static _instance: TaxesRepository;

  /** Patron Singleton
    * @returns {instance} _instance TaxesRepository
  */
  static get instance(): TaxesRepository {
    if (!this._instance) {
      this._instance = new TaxesRepository();
    }
    return this._instance;
  }

  /** Solicita los impuestos
   * @returns {ITaxes} data - tipos de identificación en el back
  */
  async getTaxes(): Promise<AxiosResponse<ITaxes[]>> {
    return await axios.get('/taxes', config);
  }

}