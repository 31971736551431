import { PropsWithChildren } from 'react';

import { Col } from 'react-bootstrap';

import IconList from '@/ui/components/IconList';

interface PropsMainTitle {
  /**Icono de referencia */
  iconName?: string;
  /**Estilos del icono */
  iconClass?: string;
  /** Tamaño del titulo */
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  /** Tamaño del titulo */
  alignment?: 'start' | 'center' | 'end';
  /**Titulo en negrita */
  boldTitle?: string;
  /**Titulo normal */
  regularTitle?: string;
  /**Texto que da mas detalle sobre el titulo */
  detailsText?: string;
}

/**
 * Componente que renderiza los titulos principales de las interfaces
 * @component
 */
const MainTitle = ({
  iconName = '',
  iconClass,
  titleSize = 'h5',
  alignment = 'start',
  boldTitle,
  regularTitle,
  detailsText,
}: PropsWithChildren<PropsMainTitle>): JSX.Element => {
  return (
    <Col xs={12}>
      <ul className={`list-unstyled text-primary-3 text-${alignment}`}>
        <IconList
          iconName={iconName}
          classIcon={iconClass}
          title={
            <>
              <span className={`${titleSize} fw-bold`}>{boldTitle ?? ''}</span>
              <span className='h5'>{` ${regularTitle ?? ''}`}</span>
            </>
          }
          classMain='fw-bold mb-2'
        />
        <p className='text-secondary-3 p-large'>{detailsText ?? ''}</p>
      </ul>
    </Col>
  );
};

export default MainTitle;
