/** Representa la interacción con LocalStorage */
export default class PreInsertBaseProductLocalStorage {
  /** Instancia de la clase   */
  private static _instance: PreInsertBaseProductLocalStorage;

  /** Patron Singleton
   * @returns {instance} _instance CartLocalStorage
   */
  static get instance() {
    if (!this._instance) {
      this._instance = new PreInsertBaseProductLocalStorage();
    }
    return this._instance;
  }

  /** Key del objeto en el localStorage para columnas seleccionadas */
  private static selectedRowsBaseProductLocalStorage = 'baseProductTableSelectedRows';

  /** Actualiza registros seleccionados  */
  public updateObj(objSelectedRows: { [index: number]: string }) {
    localStorage.setItem(PreInsertBaseProductLocalStorage.selectedRowsBaseProductLocalStorage, JSON.stringify(objSelectedRows));
  }

  /** Obtiene registros seleccionados  */
  public getObj(): { [index: number]: string } | null {
    const localStorageRows = localStorage.getItem(PreInsertBaseProductLocalStorage.selectedRowsBaseProductLocalStorage);
    return localStorageRows !== null ? JSON.parse(localStorageRows) : null;
  }

  /** Elimina el objeto de registros seleccionados */
  public deleteObj() {
    localStorage.removeItem(PreInsertBaseProductLocalStorage.selectedRowsBaseProductLocalStorage);
  }
}
