import validator from 'validator';

/**
 * Recibe una cadena de texto y verifica si tiene una URL valida y que comience con http o https.
 * @param text - String a validar .
 * @returns  booleano .
 */
const validateURLs = (text: string): boolean => {
  const isAValidUrl = validator.isURL(text);
  const hasValidProtocol = text.toLowerCase().startsWith('https://');

  return isAValidUrl && hasValidProtocol;
};

const validatePdfURL = (text: string): boolean => {
  const isAValidUrl = validateURLs(text);
  const hasPdfExtension = text.toLowerCase().endsWith('pdf');

  return isAValidUrl && hasPdfExtension;
};

export { validateURLs, validatePdfURL };
