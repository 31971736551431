/**
 * Librería de codigos de errores de producto base y variantes del back
 */
export enum ProductBackCodes {
  // Errores Producto base
  NON_EXISTENT_STORE_ERROR = 'EBP002',
  UNAUTHORIZED = 'EBP003',
  EXISTING_NAME_ERROR = 'EBP006',
  NON_EXISTENT_CATEGORY_ERROR = 'EBP009',
  LENGTH_URL_ERROR = 'EBP014',
  EXISTING_TAG_ERROR = 'EBP016',
  MAX_VARIANTS_ALLOWED_ERROR = 'EBP019',
  REQUIRED_PRODUCT_ERROR = 'EBP021',
  MAIN_PRODUCT_ERROR = 'EBP022',
  STATUS_ACTIVE_VALUE_ERROR = 'EBP023',
  DUPLICATED_SKU_ERROR = 'EBP024',
  STATUS_INACTIVE_VALUE_ERROR = 'EBP025',
  CATEGORY_ERROR = 'EBP026',
  MAIN_VARIANT_REQUIRED_ERROR = 'EBP027',
  DISABLE_MAIN_VARIANT_ERROR = 'EBP028',
  NON_EXISTENT_ID_ERROR = 'EBP029',
  EXISTING_MAIN_PRODUCT_ERROR = 'EBP030',
  REQUIRED_ID_ERROR = 'EBP031',
  INACTIVE_ERROR = 'EBP032',
  // Errores Producto (variante)
  EXISTING_SKU_ERROR = 'EP002',
  REQUIRED_MAIN_ERROR = 'EP004',
  NON_EXISTENT_BRANCH_OFFICE_ERROR = 'EP006',
  NON_EXISTENT_TAX_ERROR = 'EP021',
  DUPLICATED_TAX_ERROR = 'EP022',
  NON_EXISTENT_STATUS_ERROR = 'EP026',
  NON_EXISTENT_ATTRIBUTE_ERROR = 'EP032',
  ATTRIBUTE_CATEGORY_ERROR = 'EP033',
  UNEXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR = 'EP035',
  EXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR = 'EP036',
  ATTRIBUTE_VALUE_RANGE_ERROR = 'EP037',
  NON_EXISTENT_PRODUCT_ID_ERROR = 'EP039',
  POSITION_ATTRIBUTES_ERROR = 'EP040',
  REPEATED_ATTRIBUTES_ERROR = 'EP041',
  ATTRIBUTE_VALUE_ERROR = 'EP045',
  INVALID_LENGTH_QUANTITY_ERROR = 'EP046',
  INVALID_SKU_CODE = 'EP047',
}

/**
 * Estructura de errores de producto base y variantes del back
 * IMPORTANTE - en los textos de los mensajes únicamente se permite :
 * REFERENCE_VALUE -> valor del campo
 * MIN_RANGE -> valor mínimo para rango
 * MAX_RANGE -> valor máximo para rango
 */
export interface IProductBackErrors {
  code: ProductBackCodes;
  message: string;
  key: string;
}
const ProductBackErrors: IProductBackErrors[] = [
  // Errores Producto base
  {
    code: ProductBackCodes.NON_EXISTENT_STORE_ERROR,
    message: 'El valor ingresado como ID de la tienda no existe en la base de datos',
    key: 'ID_TIENDA',
  },
  {
    code: ProductBackCodes.UNAUTHORIZED,
    message: 'El usuario no tiene permisos para operar sobre la tienda seleccionada',
    key: 'PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.EXISTING_NAME_ERROR,
    message: 'El nombre del producto base ya existe en la tienda',
    key: 'NOMBRE_PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_CATEGORY_ERROR,
    message: 'El valor ingresado en la categoría no existe en la base de datos',
    key: 'CATEGORÍA_PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.LENGTH_URL_ERROR,
    message: 'Longitud de URL no válida',
    key: 'MEDIA_PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.EXISTING_TAG_ERROR,
    message: 'El tag ya existe para la variante',
    key: 'TAGS_PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.MAX_VARIANTS_ALLOWED_ERROR,
    message: 'El producto Base REFERENCE_VALUE no puede tener mas de una variante principal',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.REQUIRED_PRODUCT_ERROR,
    message: 'Debe existir al menos una variante principal para el producto base',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.MAIN_PRODUCT_ERROR,
    message: 'El producto base debe tener al menos un variante',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.STATUS_ACTIVE_VALUE_ERROR,
    message: 'No puedes tener un producto base activo con una variante principal inactiva',
    key: 'PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.DUPLICATED_SKU_ERROR,
    message: 'El producto base tiene variantes con el mismo SKU',
    key: 'SKU_VARIANTES',
  },
  {
    code: ProductBackCodes.STATUS_INACTIVE_VALUE_ERROR,
    message: 'No puedes tener un producto base inactivo con variantes activas',
    key: 'PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.CATEGORY_ERROR,
    message: 'La categoría del producto base no puede ser cambiada',
    key: 'CATEGORÍA_PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.MAIN_VARIANT_REQUIRED_ERROR,
    message: 'Para actualizar un producto base es necesario enviar una variante principal',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.DISABLE_MAIN_VARIANT_ERROR,
    message: 'Para desactivar una variante principal debes enviar una nueva variante principal activa',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_ID_ERROR,
    message: 'El ID del producto base no existe en la tienda',
    key: 'PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.EXISTING_MAIN_PRODUCT_ERROR,
    message: 'La variante principal ya existe en el producto base',
    key: 'VARIANTES',
  },
  {
    code: ProductBackCodes.REQUIRED_ID_ERROR,
    message: 'ID de producto base requerido',
    key: 'PRODUCTO_BASE',
  },
  {
    code: ProductBackCodes.INACTIVE_ERROR,
    message: 'El producto base REFERENCE_VALUE esta inactivo y se intentan cargar variantes activas',
    key: 'PRODUCTO_BASE',
  },
  // Errores Producto (variante)
  {
    code: ProductBackCodes.EXISTING_SKU_ERROR,
    message: 'Ya existe el SKU registrado en la base de datos',
    key: 'SKU',
  },
  {
    code: ProductBackCodes.REQUIRED_MAIN_ERROR,
    message: 'El campo que indica si la variante principal es obligatorio',
    key: 'VARIANTE',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_BRANCH_OFFICE_ERROR,
    message: 'El valor ingresado en la sucursal no existe en la base de datos',
    key: 'SUCURSAL',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_TAX_ERROR,
    message: 'El impuesto ingresado REFERENCE_VALUE no existe en la base de datos',
    key: 'IMPUESTOS',
  },
  {
    code: ProductBackCodes.DUPLICATED_TAX_ERROR,
    message: 'El impuesto ingresado REFERENCE_VALUE esta repetido en la base de datos para la variante',
    key: 'IMPUESTOS',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_STATUS_ERROR,
    message: 'El valor ingresado en el estado de la variante no existe en la base de datos',
    key: 'ESTADO',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_ATTRIBUTE_ERROR,
    message: 'La llave o valor del atributo REFERENCE_VALUE no existe en la base de datos',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.ATTRIBUTE_CATEGORY_ERROR,
    message: 'El atributo REFERENCE_VALUE no pertenence a la categoría del producto Base',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.UNEXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR,
    message: 'El valor del atributo REFERENCE_VALUE no debe reportar decimales',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.EXPECTED_DECIMAL_ATTRIBUTE_VALUE_ERROR,
    message: 'El valor del atributo debe tener decimales',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.ATTRIBUTE_VALUE_RANGE_ERROR,
    message: 'El valor REFERENCE_VALUE ingresado no está dentro del rango de [MIN_RANGE - MAX_RANGE ]',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.NON_EXISTENT_PRODUCT_ID_ERROR,
    message: 'El ID de la variante no existe en el producto base',
    key: 'VARIANTE',
  },
  {
    code: ProductBackCodes.POSITION_ATTRIBUTES_ERROR,
    message: 'La variante tiene atributos principales en posición incorrecta',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.REPEATED_ATTRIBUTES_ERROR,
    message: 'Ya existe una variante con los mismos atributos',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.ATTRIBUTE_VALUE_ERROR,
    message: 'El valor del atributo REFERENCE_VALUE no existe',
    key: 'ATRIBUTOS',
  },
  {
    code: ProductBackCodes.INVALID_LENGTH_QUANTITY_ERROR,
    message: 'El valor ingresado no está dentro del rango de [MIN_RANGE - MAX_RANGE ] permitido en: REFERENCE_VALUE',
    key: 'CANTIDAD',
  },
  {
    code: ProductBackCodes.INVALID_SKU_CODE,
    message: 'El SKU ingresado forma parte de un código interno de la tienda, usa otro SKU o deja este campo vacío.',
    key: 'SKU',
  },
];

export default ProductBackErrors;
