import { useEffect, useRef, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import InputForm from '@/ui/components/forms/InputForm';

/** Componente funcionalidad de busqueda
 * @component
 */
const SearchInput = (): JSX.Element => {
  /** Referencia al input de busqueda de texto
   * @constant {HTMLInputElement}
   */
  const filterKeyWordRef = useRef<HTMLInputElement>(null);

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** URL a donde lleva el input */
  const [url, setUrl] = useState<string>(RoutesDirections.STORE_ROUTE);

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Declaración que borra el valor de un input    */
  const [inputValue, setInputValue] = useState<string>('');

  /** Declaración que borra el valor de un input    */
  const [lastIconClass, setLastIconClass] = useState<string>('d-none');

  useEffect((): void => {
    // Verifica si la URL no coincide con RoutesDirections.STORE_ROUTE
    if (location.pathname !== url && location.search === '') {
      // Cambia el valor de inputValue a una cadena vacía
      setInputValue('');
      setLastIconClass('d-none');
    }
  }, [location.pathname, location.search]);

  /** Cambia el valor de acuerdo a lo que se escriba dentro del input */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
    if (e.target.value === '') {
      setUrl(RoutesDirections.STORE_ROUTE);
      setLastIconClass('d-none');
    } else {
      setLastIconClass('last-input-icon cursor-hand');
      setUrl(`${RoutesDirections.STORE_ROUTE}?find=${encodeURIComponent(e.target.value)}`);
    }
  };

  /** Envía el valor escrito al desencadenar un evento */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  /** Función que desenfoca el input cuando se presiona enter */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      if (filterKeyWordRef.current && filterKeyWordRef.current?.value.length > 0) {
        navigate(url, { state: { originURL: location.pathname } });
      }
    }
  };

  /** Función Resetea Input de busqueda */
  const resetInput = (filter: boolean = true): void => {
    if (filterKeyWordRef.current) {
      filterKeyWordRef.current.value = '';
    }
    setInputValue('');
    setLastIconClass('d-none');
    if (filter && filterKeyWordRef.current && filterKeyWordRef.current.value.length > 0) {
      navigate(RoutesDirections.STORE_ROUTE, { state: { originURL: location.pathname } });
    }
  };

  return (
    <div className='search-main'>
      <form onSubmit={handleSubmit}>
        <div className='ils-form-control position-relative'>
          <InputForm
            type='text'
            firstIconClass='first-input-icon svg-tertiary-1'
            placeholder='Buscar producto'
            value={inputValue}
            firstIconName='ico-search'
            inputRef={filterKeyWordRef}
            lastIconClass={lastIconClass}
            lastIconClicked={resetInput}
            lastIconName='ico-close'
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
