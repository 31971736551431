import {
  BLANK_CHARACTER_IN_TEXT,
  BLANK_CHARACTER_AT_START,
  ConfigNumberParameters,
  PRODUCT_BASE_TAGS_FORMAT,
} from '@/data/libraries/ConfigParameters';

import { IProductForm, IVariantForm } from '@/domain/interfaces/IProduct';
import { IAttributesForm } from '@/domain/interfaces/IAttributes';

import { validateURLs } from '@/domain/utils/validateURLs';
import validatePattern from '@/domain/utils/validatePattern';

import BaseProductCodes, { BaseProductCodeValuesType } from '@/data/libraries/errors/frontErrors/BaseProductCodes';

interface IProductBaseValidation {
  row: number;
  // Campo a evaluar
  field:
    | 'ID_TIENDA'
    | 'PRODUCTO_BASE'
    | 'ESTATUS_PRODUCTO_BASE'
    | 'NOMBRE_PRODUCTO_BASE'
    | 'DESCRIPCIÓN_PRODUCTO_BASE'
    | 'BANNER_PRODUCTO_BASE'
    | 'CATEGORÍA_PRODUCTO_BASE'
    | 'TAGS_PRODUCTO_BASE'
    | 'VARIANTES'
    | 'MEDIA_PRODUCTO_BASE'
    | 'SKU_VARIANTES';
  // Mensaje de error
  message: BaseProductCodeValuesType | string;
  // Llave del error exclusivo para Form Wizard */
  keyError?: 'chrError' | 'maxError' | 'minError' | 'existingNameError';
}

/**
 * Caso de uso de validación de producto base
 * @param {IProductForm} product - data de producto base
 * @returns {IProductValidation[]} errors
 */
const validateProductBaseUseCase = (product: IProductForm): IProductBaseValidation[] => {
  /** Objeto de respuesta con todos los errores */
  const errors: IProductBaseValidation[] = [];

  /** Constantes que evaluan campos */
  const NAME_MIN_LENGHT = ConfigNumberParameters.BASE_PRODUCT_NAME_MIN_LENGTH;
  const TAG_MAX_LENGHT = ConfigNumberParameters.BASE_PRODUCT_TAG_MAX_LENGTH;
  const NAME_MAX_LENGHT = ConfigNumberParameters.BASE_PRODUCT_NAME_MAX_LENGTH;
  const DESCRIPTION_MIN_LENGHT = ConfigNumberParameters.BASE_PRODUCT_DESCRIPTION_MIN_LENGTH;
  const DESCRIPTION_MAX_LENGHT = ConfigNumberParameters.BASE_PRODUCT_DESCRIPTION_MAX_LENGTH;
  const BASE_PRODUCT_ID = product.id;
  const BASE_PRODUCT_NAME = product.name;
  const BASE_PRODUCT_REF = BASE_PRODUCT_ID && BASE_PRODUCT_ID !== 0 ? `[ID:${BASE_PRODUCT_ID}]` : `[${BASE_PRODUCT_NAME}]`;
  const BASE_PRODUCT_ROW: number = product.row ?? 0;

  // Establece la visualización de formato de un campo
  const formatError = (formatExample?: string) => `${BaseProductCodes.FORMAT_ERROR} ${formatExample && `(${formatExample}) `}`;

  /** Validaciones */

  /** Tienda */
  if (product.store === 0 || product.store === null) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'ID_TIENDA', message: BaseProductCodes.REQUIRED_ERROR });
  }

  /** Nombre */
  if (product.name.length === 0) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'NOMBRE_PRODUCTO_BASE', message: BaseProductCodes.REQUIRED_ERROR, keyError: 'minError' });
  }

  if (product.name.length < NAME_MIN_LENGHT) {
    errors.push({
      row: BASE_PRODUCT_ROW,
      field: 'NOMBRE_PRODUCTO_BASE',
      message: BaseProductCodes.NAME_MIN_LENGHT_ERROR.replace('REFERENCE', NAME_MIN_LENGHT.toString()),
      keyError: 'minError',
    });
  }

  if (product.name.length > NAME_MAX_LENGHT) {
    errors.push({
      row: BASE_PRODUCT_ROW,
      field: 'NOMBRE_PRODUCTO_BASE',
      message: BaseProductCodes.NAME_MAX_LENGHT_ERROR.replace('REFERENCE', NAME_MAX_LENGHT.toString()),
      keyError: 'maxError',
    });
  }

  if (product.name !== '' && !validatePattern(BLANK_CHARACTER_AT_START, product.name)) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'NOMBRE_PRODUCTO_BASE', message: BaseProductCodes.BLANK_ERROR, keyError: 'chrError' });
  }

  /** Descripción */

  if (product.description.length === 0) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'DESCRIPCIÓN_PRODUCTO_BASE', message: BaseProductCodes.REQUIRED_ERROR });
  }

  if (product.description.length < DESCRIPTION_MIN_LENGHT) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'DESCRIPCIÓN_PRODUCTO_BASE', message: BaseProductCodes.DESCRIPTION_MIN_LENGHT_ERROR });
  }

  if (product.description.length > DESCRIPTION_MAX_LENGHT) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'DESCRIPCIÓN_PRODUCTO_BASE', message: BaseProductCodes.DESCRIPTION_MAX_LENGHT_ERROR });
  }

  if (product.description !== '' && !validatePattern(BLANK_CHARACTER_AT_START, product.description)) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'DESCRIPCIÓN_PRODUCTO_BASE', message: BaseProductCodes.BLANK_ERROR_AFTER, keyError: 'chrError' });
  }

  /** Categoría */
  if (product.category === 0) {
    errors.push({ row: BASE_PRODUCT_ROW, field: 'CATEGORÍA_PRODUCTO_BASE', message: BaseProductCodes.REQUIRED_ERROR });
  }

  /** Banner */
  if (product.urlBanner) {
    if (product.urlBanner.length > 0 && !validateURLs(product.urlBanner)) {
      errors.push({ row: BASE_PRODUCT_ROW, field: 'BANNER_PRODUCTO_BASE', message: BaseProductCodes.URL_ERROR });
    }

    if (product.urlBanner !== '' && !validatePattern(BLANK_CHARACTER_IN_TEXT, product.urlBanner)) {
      errors.push({ row: BASE_PRODUCT_ROW, field: 'BANNER_PRODUCTO_BASE', message: BaseProductCodes.BLANK_ERROR });
    }
  }

  /** Tags  */
  if (product.tags && product.tags.length > 0) {
    product.tags?.forEach((tag) => {
      if (tag.length > TAG_MAX_LENGHT || !validatePattern(PRODUCT_BASE_TAGS_FORMAT, tag)) {
        errors.push({ row: BASE_PRODUCT_ROW, field: 'TAGS_PRODUCTO_BASE', message: formatError(BaseProductCodes.TAGS_FORMAT) });
      }
      if (!validatePattern(BLANK_CHARACTER_IN_TEXT, tag)) {
        errors.push({ row: BASE_PRODUCT_ROW, field: 'TAGS_PRODUCTO_BASE', message: BaseProductCodes.BLANK_ERROR });
      }
    });

    const setTags = new Set(product.tags);
    if (setTags.size !== product.tags.length) {
      errors.push({ row: BASE_PRODUCT_ROW, field: 'TAGS_PRODUCTO_BASE', message: BaseProductCodes.TAGS_ERROR });
    }
  }

  /** Variantes */

  // Muestra la cantidad de valores repetidos entre los dos atributos principales de la variante
  const validateMainAttributes = (baseProductVariants: IVariantForm[]) => {
    const attributeCounts = new Map();

    for (const producto of baseProductVariants) {
      const mainAttributes = {};

      for (const attribute of producto.attributes) {
        if (attribute.main) {
          mainAttributes[attribute.key ? attribute.key.toLocaleLowerCase() : ''] = attribute.value ? attribute.value.toLocaleLowerCase() : '';
        }
      }

      // Convertimos el conjunto de atributos en una cadena JSON para usarlo como clave en el mapa.
      const attributeString = JSON.stringify(mainAttributes);

      if (attributeCounts.has(attributeString)) {
        attributeCounts.set(attributeString, attributeCounts.get(attributeString) + 1);
      } else {
        attributeCounts.set(attributeString, 1);
      }
    }

    // Buscamos el conjunto de atributos que se repite más veces.
    let maxCount = 0;
    for (const count of attributeCounts.values()) {
      if (count > maxCount) {
        maxCount = count;
      }
    }

    // Si el conjunto de atributos se repite al menos dos veces, devolvemos el valor de repeticiones.
    if (maxCount >= 2) {
      return maxCount;
    }

    // Si no se encontró ningún conjunto de atributos repetido al menos dos veces, devolvemos false.
    return 0;
  };

  // Validación de variantes con los mismos atributos y valores
  if (product.variants.length > 0) {
    // Número de cantidades en las que se repite el valor
    const repeatedAttributes: number = validateMainAttributes(product.variants);

    // Si el valor se repite 2 veces se arroja el mensaje de error
    if (repeatedAttributes >= 2) {
      errors.push({
        row: BASE_PRODUCT_ROW,
        field: 'VARIANTES',
        message: BaseProductCodes.DUPLICATE_VARIANTS_ERROR.replace('REFERENCE', BASE_PRODUCT_REF),
      });
    }
  }

  //  Si el producto base no tiene ID, ninguna variante debe tener id
  if ((!product.id || product.id === 0) && product.variants.length > 0) {
    const variantsWithId = product.variants.filter((variant) => variant.id);

    if (variantsWithId.length > 0) {
      errors.push({
        row: BASE_PRODUCT_ROW,
        field: 'VARIANTES',
        message: BaseProductCodes.VARIANTS_WITH_ERROR.replace('REFERENCE', BASE_PRODUCT_NAME),
      });
    }
  }

  //Si la variante principal esta inactiva pero el resto esta activa se genera error
  if (product.variants.length > 0) {
    // Variable para rastrear si el objeto main está inactivo
    let mainInactive = false;

    // Variable para rastrear si al menos una otra variante está activa
    let otherActiveVariants = false;

    // Verificar el estado de los productos
    product.variants.forEach((product) => {
      if (product.main && product.status === 2) {
        mainInactive = true;
      } else if (!product.main && product.status === 1) {
        otherActiveVariants = true;
      }
    });

    if (mainInactive && otherActiveVariants) {
      errors.push({ row: BASE_PRODUCT_ROW, field: 'VARIANTES', message: BaseProductCodes.STATUS_VARIANTS_ERROR });
    }
  }

  // No pueden haber mas de una por producto base
  if (product.variants.length > 0) {
    const mainVariants = product.variants.filter((variant) => variant.main);

    if (mainVariants.length > 1) {
      errors.push({
        row: BASE_PRODUCT_ROW,
        field: 'VARIANTES',
        message: BaseProductCodes.DUPLICATE_MAIN_VARIANTS_VALUES_ERROR.replace('REFERENCE', BASE_PRODUCT_REF),
      });
    }
  }

  // Debe existir al menos una variante principal y no pueden haber mas de una por producto base
  if (product.variants.length > 1) {
    //Función  que trae los atributos principales de una variante
    const getMainAttributes = (variante: IVariantForm): IAttributesForm[] => {
      return variante.attributes ? variante.attributes.filter((attribute) => attribute.main) : [];
    };

    //Función que compara que las llaves de atributos principales sean las mismas
    const compareEqualsKeys = (attribute1: IAttributesForm[], attribute2: IAttributesForm[]): boolean => {
      if (attribute1.length !== attribute2.length) {
        return false;
      }

      for (let i = 0; i < attribute1.length; i++) {
        if (attribute1[i].key.toLocaleLowerCase() !== attribute2[i].key.toLocaleLowerCase()) {
          return false;
        }
      }

      return true;
    };

    const mainVariant = product.variants.find((variant) => variant.main);
    const mainAttributes = mainVariant ? getMainAttributes(mainVariant) : [];

    if (mainAttributes.length > 0) {
      for (let i = 1; i < product.variants.length; i++) {
        const mainAttributesI = getMainAttributes(product.variants[i]);

        if (!compareEqualsKeys(mainAttributes, mainAttributesI)) {
          errors.push({
            row: BASE_PRODUCT_ROW,
            field: 'VARIANTES',
            message: `${BaseProductCodes.MAIN_VARIANTS_KEYS_ERROR_1} ${product.variants[0].baseProductName} ${BaseProductCodes.MAIN_VARIANTS_KEYS_ERROR_2}`,
          });
        }
      }
    }
  }

  return errors;
};

export default validateProductBaseUseCase;

export type { IProductBaseValidation };
