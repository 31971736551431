import { IAttributes } from '@/domain/interfaces/IAttributes';
import { createSlice } from '@reduxjs/toolkit';

const initialState:IAttributes[] = []; 

/** Almacena el estado managementState de Redux con sus reducers
  * @constant {object}
*/
export const attributeslice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    updateAttributes: (state, action) => {
        state.splice(0, state.length, ...action.payload);
    },
  }
})

export const { updateAttributes } = attributeslice.actions;

export default attributeslice.reducer;
