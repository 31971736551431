import React from 'react';

import Icon from '@/ui/assets/Icon';

/** Componente SVG de acción volver
  * @component
*/
const BaseIcon = () => {
  return (
      <Icon name='ico-cube'/>
  );
}

export default  BaseIcon;