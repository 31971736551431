import { useEffect, useState } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { useLocation, useNavigate, type Location, NavigateFunction, NavLink } from 'react-router-dom';

import { isEmpty } from 'lodash';

import useValidateUnavailableProducts from '@/ui/hooks/useValidateUnavailableProducts';
import useAttributesPresenter from '@/ui/hooks/useAttributesPresenter';
import { ScreenSize, useScreenSize } from '@/ui/hooks/useScreenSize';

import { Button, Col, Container, Image, Modal, Row } from 'react-bootstrap';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';

import CartController from '@/controllers/CartController';

import { ICart, ICartDetail } from '@/domain/interfaces/ICart';

import currencyFormat from '@/domain/utils/currencyFormat';

import CloseIcon from '@/ui/assets/CloseIcon';
import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';
import GuideTip from '@/ui/components/GuideTip';
import ProductStatusPill from '@/ui/components/product/ProductStatusPill';
import MainPrice from '@/ui/components/product/MainPrice';
import SecondaryPrice from '@/ui/components/product/SecondaryPrice';
import ProductCounter from '@/ui/components/product/ProductCounter';
import EmptyCart from '@/ui/components/store/EmptyCart';

import './style.css';

/**
 * Componente Modal de carrito
 * @component
 */

interface CartModalProps {
  showModal: boolean;
  handleClose: () => void;
}

/**Componente Modal de carrito */
const CartModal = ({ showModal, handleClose }: CartModalProps): JSX.Element => {
  /** Controlador de carrito */
  const { productQuantity, deleteCart } = CartController();

  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook  que valida el carrito y sus items */
  const { hasUnavailableProducts } = useValidateUnavailableProducts();

  /** Hook que presenta los atributos de los productos */
  const { attributesPresenter } = useAttributesPresenter();

  /**Trae la información del carrito desde Redux */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  /**Trae el usuario logueado desde Redux */
  const userLogued = useAppSelector((state) => state.userState);

  /**si el usuario no esta logueado se asigna el valor 1 de la tienda */
  const userStore: string = String(userLogued.user?.userStore ?? 1);

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /**Alerta de productos no disponibles en el carrito*/
  const [cartAlert, setCartAlert] = useState<boolean>(false);

  /**Mensaje de alerta de productos no disponibles en el carrito*/
  const [cartMessage, setCartMessage] = useState<string>('');

  /**Constantes de texto */
  const CART_ITEMS_COUNTER_LABEL: string = productQuantity() > 1 ? `Productos agregados` : `Producto agregado`;
  const CART_TITLE = 'Mi carrito';
  const CART_SUBTOTAL: string = 'Subtotal:';
  const GO_TO_CART_TEXT: string = 'IR AL CARRITO';
  const CLEAN_CART_TEXT: string = 'Limpiar carrito';

  /**Componente Imagen  de producto */
  const PreviewImage = (product: ICartDetail): JSX.Element => (
    <NavLink to={`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${product.skuProduct}`} className='image-cart-container rounded rounded-3'>
      <Image
        className='rounded rounded-3 image-full cursor-hand'
        src={`${product.image}`}
        alt={''}
        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
          e.currentTarget.src = ErrorImage;
        }}
      />
    </NavLink>
  );

  /**Componente Métodos de Envío REFACTORIZAR*/
  const ShipingMethods: JSX.Element = (
    <>
      <span className='p-small-bold text-primary-3 pe-4'>{`Entrega:`}</span>
      <GuideTip position='bottom' message={'Entrega a domicilio'}>
        <Icon name='ico-national-shipment' classMain='pe-2' />
      </GuideTip>
    </>
  );

  /**Componente Mensaje de Alerta */
  const AlertUnavaliableProducts: JSX.Element = (
    <Container fluid className='p-2 bg-primary-4 mt-1 shadow rounded position-relative '>
      <Col xs={12} className='align-self-end  position-absolute top-0 end-0'>
        <div className='bg-complementary-4 pt-1 rounded-4 rounded-top-5' />
      </Col>

      <Row className='d-flex justify-content-center'>
        <Col xs={11} className='mt-3'>
          <IconList title='¡ALERTA!' classTitle='text-complementary-4 p-title-bold' iconName='ico-warning' classMain='svg-complementary-4' />
        </Col>
        <Col xs={11} className='text-secondary-2 p-smaller d-flex text-justify mt-3 mb-3'>
          {cartMessage}
        </Col>
      </Row>
    </Container>
  );

  /**Función que maneja la opción de ir al carrito y cerrar la modal */
  const handleGoToCart = () => {
    if (cartAlert) {
      return;
    }
    navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } });
    handleClose();
  };

  /** Al cambiar el carrito se aplica el hook que valida y alerta */
  useEffect(() => {
    const validateCart = hasUnavailableProducts();
    setCartAlert(validateCart.error);
    setCartMessage(validateCart.message);
  }, [cartProducts]);

  /**Renderizado del componente */
  return (
    <Modal
      show={showModal}
      fullscreen
      dialogClassName={`slide-cart-modal-dialog  ${isMobile ? 'rounded-top-5' : ''}`}
      contentClassName={`${isMobile ? 'modal-slide-up  rounded-top-5 border-0' : ''} `}
      onHide={handleClose}>
      {/**Si hay productos en el carrito se muestra la modal con la información*/}
      {productQuantity() > 0 ? (
        <>
          <Modal.Header className='border-0 p-0  '>
            {isMobile ? (
              <Container fluid className='p-0 '>
                <Row className='g-0 p-0 pt-3 d-flex align-items-center'>
                  <Col lg={6} className='d-flex justify-content-end px-3 '>
                    <CloseIcon classMain='btn-icon-dark' onClick={handleClose} />
                  </Col>
                </Row>
                <Row className='g-0 p-0 pt-3 d-flex align-items-baseline'>
                  <Col xs={6}>
                    <IconList
                      classMain='h4 fw-bold ps-3 text-primary-3 my-1'
                      classIcon='svg-primary-3'
                      title={CART_TITLE}
                      iconName='ico-market-car'
                      classTitle='p-large-bold '
                    />
                  </Col>
                  <Col xs={6}>
                    <span className='d-flex justify-content-start h5  p-smaller text-primary-3'>{`${productQuantity()} ${CART_ITEMS_COUNTER_LABEL}`}</span>
                  </Col>
                </Row>

                <Row className='g-0 px-2 bg-primary-5 py-2 '>{cartAlert && AlertUnavaliableProducts}</Row>
              </Container>
            ) : (
              <Container fluid className='p-0'>
                <Row className='g-0 p-0 pt-3 d-flex align-items-center'>
                  <Col lg={6}>
                    <IconList
                      classMain='h3 fw-bold ps-3 text-primary-3 my-1'
                      classIcon='svg-primary-3'
                      title={CART_TITLE}
                      iconName='ico-market-car'
                      classTitle='p-large-bold'
                    />
                  </Col>
                  <Col lg={6} className='d-flex justify-content-end px-3 '>
                    <CloseIcon classMain='btn-icon-dark' onClick={handleClose} />
                  </Col>
                </Row>
                <Row className='g-0 p-0 pt-3 d-flex align-items-baseline px-1'>
                  <Col lg={6}>
                    <span className='h5 fw-bold ps-3 text-primary-3'>{`${productQuantity()} ${CART_ITEMS_COUNTER_LABEL}`}</span>
                  </Col>
                  <Col lg={3} className='d-flex justify-content-end px-2'>
                    <span className='text-secondary-2 p-large-bold '>{CART_SUBTOTAL}</span>
                  </Col>
                  <Col lg={3} className='d-flex justify-content-center pe-3  '>
                    <span className='p-large-bold text-secondary-2 '>{currencyFormat(cartProducts.totalWithTax, 2)}</span>
                  </Col>
                </Row>
                <Row className='g-0 p-0 py-2 d-flex align-items-baseline mt-4'>
                  <Col xs={6} className='d-flex justify-content-start px-5 '>
                    <NavLink to={'#'} className='p-large-medium  link-text-standard' onClick={deleteCart}>
                      {CLEAN_CART_TEXT}
                    </NavLink>
                  </Col>
                  <Col xs={6} className='d-flex justify-content-end  pe-4 '>
                    <Button className={`my-2 w-100 svg-primary-4 btn-primary-icon-standard  ${cartAlert ? 'disabled' : ''}`} onClick={handleGoToCart}>
                      {GO_TO_CART_TEXT}
                    </Button>
                  </Col>
                </Row>
                <Row className='g-0 px-2 bg-secondary-5 py-2 '>{cartAlert && AlertUnavaliableProducts}</Row>
              </Container>
            )}
          </Modal.Header>
          <Modal.Body className={`d-flex flex-grow-1 col-fixed border-0 p-0  ${isMobile ? 'bg-primary-5' : 'bg-secondary-5'} `}>
            <Container fluid>
              <Row className='pb-3 g-0 p-0 pe-3 ps-3 '>
                <Col xs={12} className='py-2  '>
                  {!isEmpty(cartProducts) &&
                    cartProducts.details.map((product) => {
                      const isInOffer: boolean = product.offerPrice !== undefined && product.offerPrice !== null;
                      const isFree: boolean = product.offerPrice !== undefined && product.offerPrice !== null && product.offerPrice === 0;

                      const attributes: string = attributesPresenter(product.attributes);
                      return (
                        <Container fluid className='p-0 bg-primary-4 my-3 rounded rounded-3 ' key={product.skuProduct}>
                          <Row className='g-0 p-1 d-flex' onClick={handleClose}>
                            <Col xs={isMobile ? 5 : 3} className='mx-auto text-center position-relative'>
                              {PreviewImage(product)}
                              <div className='top-0 end-0 position-absolute pe-2'>
                                <ProductStatusPill isInOffer={isInOffer} isSoldOut={product.quantityAvailable === 0} />{' '}
                              </div>
                            </Col>
                            <Col xs={7} className='ps-3 pe-2 position-relative '>
                              <Row className='g-0 d-flex '>
                                <Col xs={12} className='p-regular text-truncate'>
                                  <span className='p-regular-medium'>{product.title}</span>
                                </Col>
                                <Col xs={12} className='text-truncate'>
                                  <span className='p-small'>{attributes}</span>
                                </Col>
                                <Row className='my-2 g-0'>
                                  <Col xs={12} className='p-large-bold'>
                                    <MainPrice isFree={isFree} value={isInOffer ? product.offerPrice ?? 0 : product.price} className='p-title-bold' />
                                  </Col>
                                  <Col xs={12} className='p-large-medium'>
                                    <SecondaryPrice isInOffer={isInOffer} value={product.price} className='pe-3 p-regular-medium' />
                                  </Col>
                                </Row>
                              </Row>
                              <Row className='g-0 mb-1 row-cols-auto'>{ShipingMethods}</Row>
                            </Col>
                          </Row>
                          <Row className='g-0 px-1'>
                            <Col xs={12}>
                              <ProductCounter skuProduct={product.skuProduct} productSelected={true} />
                            </Col>
                          </Row>
                        </Container>
                      );
                    })}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          {/*si es mobile se muestra el footer con el subtotal y botones de acción, si es desktop no aparece el footer con los botones*/}
          {isMobile && (
            <Modal.Footer className='no-border p-0'>
              <Row className='g-0 px-0 py-2 d-flex align-items-baseline'>
                <Col xs={12} className='d-flex justify-content-end px-3'>
                  <span className='px-3 text-secondary-2 p-large '>{CART_SUBTOTAL}</span>
                  <span className='p-large-bold text-secondary-2 '>{currencyFormat(cartProducts.totalWithTax, 2)}</span>
                </Col>
                <Col xs={12} className='d-flex justify-content-center p-2 '>
                  <Button className={`my-2 w-100  svg-primary-4 btn-primary-icon-standard ${cartAlert ? 'disabled' : ''}`} onClick={handleGoToCart}>
                    {GO_TO_CART_TEXT}
                  </Button>
                </Col>
                <Col xs={12} className='d-flex justify-content-center'>
                  <NavLink to={'#'} className='p-large-medium  link-text-standard' onClick={deleteCart}>
                    {CLEAN_CART_TEXT}
                  </NavLink>
                </Col>
              </Row>
            </Modal.Footer>
          )}
        </>
      ) : (
        <>
          {/*Si no hay productos en el carrito se muestra la modal con carrito vacío*/}
          {!isMobile ? (
            <>
              <Row className='g-0 p-0 pt-3 pb-3 d-flex align-items-center shadow p-3 mb-5 bg-white rounded'>
                <Col lg={6}>
                  <IconList
                    classMain='h3 fw-bold ps-3 text-primary-3 my-1'
                    classIcon='svg-primary-3'
                    title={CART_TITLE}
                    iconName='ico-market-car'
                    classTitle='p-large-bold'
                  />
                </Col>
                <Col lg={6} className='d-flex justify-content-end px-3 '>
                  <CloseIcon classMain='btn-icon-light' onClick={handleClose} />
                </Col>
              </Row>
              <Col className='p-0 border border-0 mt-5'>
                <EmptyCart onClick={handleClose} />
              </Col>
            </>
          ) : (
            <>
              <Row className='g-0 p-4 d-flex align-items-baseline shadow mb-5 rounded   '>
                <Col xs={6}>
                  <IconList
                    classMain='h4 fw-bold ps-3 text-primary-3 my-1'
                    classIcon='svg-primary-3'
                    title={CART_TITLE}
                    iconName='ico-market-car'
                    classTitle='p-large-bold'
                  />
                </Col>
                <Col xs={6} className='d-flex justify-content-end '>
                  <CloseIcon classMain='btn-icon-dark' onClick={handleClose} />
                </Col>
              </Row>
              <Row className='g-0 px-3 col-scrollable scrollable-y'>
                <Button className='btn-link-text-standard '>
                  <EmptyCart onClick={handleClose} />
                </Button>
              </Row>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default CartModal;
