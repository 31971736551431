import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import type { NavigateFunction, Location } from 'react-router-dom';

import FormWizard from "react-form-wizard-component";

import { RoutesDirections } from '@/data/libraries/Routes';
import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { useResize } from '@/ui/hooks/useScreenSize';
import useValidateUnavailableProducts from '@/ui/hooks/useValidateUnavailableProducts';
import useReservation from '@/ui/hooks/useReservation';
import { useCreateCartClientDetailsForm } from '@/ui/hooks/useCreateCartClientDetailsForm';

import { Col, Row, Container } from 'react-bootstrap';

import 'react-form-wizard-component/dist/style.css';
import '@/ui/components/formWizard/style.css';

import { useAppSelector, useAppDispatch } from '@/ui/store/helperRedux';
import { RootState } from '@/ui/store/store';
import { updateIndexCheckOut } from '@/ui/store/slices/indexCheckOutSlice';

import CartController from '@/controllers/CartController';

import { ICart } from '@/domain/interfaces/ICart';
import { IWebPayResponse } from '@/domain/interfaces/IPayment';

import AddressLayout from '@/ui/components/sales/AddressLayout';
import PurchaseSummaryLayout from '@/ui/components/sales/PurchaseSummaryLayout';
import PaymentLayout from '@/ui/components/sales/PaymentLayout';
import ModalBlockedByCheckOut from '@/ui/components/modals/ModalBlockedByCheckOut';
import AlertModal from '@/ui/components/modals/AlertModal';

/** Componente CartCheckOut de Productos
 * @component
 */
const CartCheckOut = (): JSX.Element => {
  /** Hook para generar una navegación dentro de la aplicación. */
  const navigate: NavigateFunction = useNavigate();

  /** Hook actualizar states de redux */
  const dispatch = useAppDispatch();

  /** State Index de checkout */
  const idexFormwizard:number = useAppSelector((state: RootState) => state.indexCheckOut.value);

  /** Controlador de carrito */
  const { productQuantity, updateClientInCart, purchaseOrder } = CartController();

  /** Valida productos disponibles del carrito */
  const { hasUnavailableProducts } = useValidateUnavailableProducts();

  /**Respues del hook que valida el carrito */
  const validateCart = hasUnavailableProducts();

  /** Hook para las reservas */
  const reservationHook = useReservation();

  /** Hook para trabajar con document location  */
  const location: Location = useLocation();

  /**Estado Redux del carrito */
  const cartProducts = useAppSelector((state: RootState) => state.cartState) as ICart;

  const [alertWarning, setAlertWarning] = useState<boolean>(false);
  const [alertAlert, setAlertAlert] = useState<boolean>(false);

  const hideAlertAlert = (): void => {
    setAlertAlert(false);
    setAlertWarning(false);
    navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } });
  };

  const hideAlertWarning = (): void => {
    setAlertAlert(false);
    setAlertWarning(false);
  };

  /** Inicialización del formulario */
  const formRef = useRef<ICart>(cartProducts);

  /** Referencia al Boton de ir Adelante */
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);

  /** Referencia al formulario de Webpay */
  const webpayFormRef = useRef<HTMLFormElement | null>(null);

  /**Estado de la URL para Procesar Pago */
  const [url, setUrl] = useState<string>('');
  /** Estado del token para procesar pago */
  const [token, setToken] = useState<string>('');

  /**Funcion que actualiza el formulario */
  const updateFormRef = (formProduct: ICart): void => {
    formRef.current = formProduct;
  };

  /** Funcion para ir adelante */
  const handleNextButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    nextButtonRef.current && nextButtonRef.current.click(); // Simula el clic en el input de archivo oculto
  };

  /**funcion para finalizar el registro de producto base*/
  const handleFinishButtonClick = async (e: MouseEvent) => {
    e.preventDefault();
    await purchaseOrder(cartProducts).then((response: IWebPayResponse | number) => {
      if (typeof response === 'number') {
        if (response === 500) {
          setAlertAlert(true);
        } else {
          setAlertWarning(true);
        }
      } else if (response.tokenGateway && response.tokenGateway.length > 0) {
          setToken(response.tokenGateway);
          setUrl(response.urlGateway);
        }
    });
  };

  /**Función que chequea el estado de la reserva */
  const handleCheckReservation = async () => {
    const response: number = await reservationHook.checkReservation();
    if (response === 0) {
      handleDeleteReservation(true);
    }
  };

  /** Hook para traer el objeto vacio del cliente en el carrito */
  const emptyCartClientObject = useCreateCartClientDetailsForm();

  /**Funcion que elimina la reserva y oculta el modal, recibe un true o false para mostrar la alerta de inactividad */
  const handleDeleteReservation = async (showIdleModal: boolean): Promise<void> => {
    const isReservedDeleted: boolean = await reservationHook.deleteReservation();
    if (isReservedDeleted) {
      const updatedClientDetails = emptyCartClientObject;
      const updatedCartProducts = {
        ...cartProducts,
        clientDetails: updatedClientDetails,
      };
      updateClientInCart(updatedCartProducts.clientDetails);
      navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname, showIdleModal } });
    }
  };

  /**UseEffect que envía a carrito si al entrar a la pagina no tengo id de reserva*/
  useEffect(() => {
    !cartProducts.reservationId && navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } });
  }, [cartProducts.reservationId]);

  /**UseEffect que envía a carrito si al entrar a la pagina hay problemas con el carrito  y no tengo id de reserva*/
  useEffect(() => {
    !cartProducts.reservationId && validateCart.error && navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } });
  }, [validateCart.error]);

  /**UseEffect que envía a carrito si al entrar a lvalidateCarta pagina el carrito esta vacío */
  useEffect(() => {
    productQuantity() === 0 && navigate(RoutesDirections.CART_ROUTE, { state: { originURL: location.pathname } });
  }, [productQuantity]);

  
  useEffect(() => {
    
    /** Verifica la reserva */
    handleCheckReservation();

    /** UseEffect que se desencadena a los 10 minutos para eliminar la reserva*/
    const timeoutId = setTimeout(() => {
      handleDeleteReservation(true);
    }, ConfigNumberParameters.RESERVATION_TIMEOUT_DURATION);

    return () => clearTimeout(timeoutId);
  }, []);

  /** Actualiza el index del FormWizard */
  const tabChanged = ({ prevIndex }) => {
    if (idexFormwizard !== prevIndex) {
      dispatch(updateIndexCheckOut(prevIndex));
    }
  };

  //UseEffect que envia el formulario oculto cuando recibe un Token
  useEffect(() => {
    if (token !== '') {
      dispatch(updateIndexCheckOut(1));
      webpayFormRef.current?.submit();
    }
  }, [token]);

  /**CONSTANTES DE TEXTO */
  const MY_CART_TITLE: string = 'Mi carrito';
  const SHIPPING_ADDRESS_TITLE: string = 'Tipo de entrega';
  const BOUGHT_RESUME_TITLE: string = 'Resumen de la compra';
  const PAYMENT_TITLE: string = 'Pagar';

  const WARNING_ALERT: string = 'Revisa nuevamente tus datos personales';
  const ALERT_ALERT: string = 'Oops! Ha ocurrido un error inesperado, el sistema te llevara al carrito nuevamente';

  const fullSize: boolean = useResize() > 1740;

  return (
    <>
      <Container fluid className='p-0 bg-secondary-5'>
        <Container className='p-0'>
          <Row className='g-0'>
            <Col className='mx-auto fw-white'>
              <FormWizard
                startIndex={idexFormwizard}
                onTabChange={tabChanged}
                onComplete={() => /*handleComplete(true)*/ null}
                /**el void es para que no retorne nada y no de error en la consola de la pagina*/
                backButtonTemplate={(handlePrevious: () => void) => (
                  <button className='d-none' onClick={handlePrevious}>
                    back
                  </button>
                )}
                /**el void es para que no retorne nada y no de error en la consola de la pagina*/
                nextButtonTemplate={(handleNext: () => void) => (
                  <button className='d-none' ref={nextButtonRef} onClick={handleNext}>
                    next
                  </button>
                )}
                /**el void es para que no retorne nada y no de error en la consola de la pagina*/
                finishButtonTemplate={(handleComplete: () => void) => (
                  <button className='d-none' onClick={handleComplete}>
                    finish
                  </button>
                )}>
                {/*Carrito de compras*/}
                <FormWizard.TabContent title={fullSize ? MY_CART_TITLE : ''} disabled>
                  <AddressLayout formRef={formRef} updateFormRef={updateFormRef} handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)} />
                  <ModalBlockedByCheckOut show handleSubmit={() => handleDeleteReservation(false)} handleCancel={() => window.location.reload()} />
                </FormWizard.TabContent>
                {/*Configurar Entrega*/}
                <FormWizard.TabContent title={fullSize ? SHIPPING_ADDRESS_TITLE : ''}>
                  <AddressLayout formRef={formRef} updateFormRef={updateFormRef} handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)} />
                </FormWizard.TabContent>
                {/*Resumen de la compra*/}
                <FormWizard.TabContent title={fullSize ? BOUGHT_RESUME_TITLE : ''}>
                  <PurchaseSummaryLayout handleNextButton={(e: MouseEvent) => handleNextButtonClick(e)} />
                </FormWizard.TabContent>
                {/*Pagar*/}
                <FormWizard.TabContent title={fullSize ? PAYMENT_TITLE : ''}>
                  <PaymentLayout formRef={formRef} updateFormRef={updateFormRef} handleNextButton={(e: MouseEvent) => handleFinishButtonClick(e)} />
                  <form ref={webpayFormRef} action={url} method='POST' className='d-none'>
                    <input type='text' name='token_ws' value={token} onChange={() => null} />
                  </form>
                </FormWizard.TabContent>
              </FormWizard>
            </Col>
          </Row>
        </Container>
      </Container>
      {alertAlert && <AlertModal hideAlert={hideAlertAlert} type='danger' content={ALERT_ALERT} hide={15000} />}
      {alertWarning && <AlertModal hideAlert={hideAlertWarning} type='warning' content={WARNING_ALERT} hide={5000} />}
    </>
  );
};

export default CartCheckOut;
