import { Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@/ui/store/helperRedux';
import { RoutesDirections } from '@/data/libraries/Routes';

import { ISelectOptionType } from '@/domain/interfaces/ISelect';

import ErrorImage from '@/ui/assets/ErrorImage';
import Icon from '@/ui/assets/Icon';

import ProductStatusPill from '@/ui/components/product/ProductStatusPill';

import './style.css';

export interface PropsAttributeValue {
  imageUrl: string;
  attributeValue: string;
}

/**
 * Vista tipo 'option' seleccionado para 'select' de atributos
 * @component
 */
const AttributeValue = ({ data }: { data: ISelectOptionType }): JSX.Element => {
  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);
  const userStore: string = String(userLogued.user?.userStore ?? 1);

  const AtributeComponent = (disabled: boolean): JSX.Element => (
    <Row className='g-0 d-flex align-items-center justify-content-between rounded rounded-3 my-1 p-1 dropdown-item mh-50-px'>
      <Col className='d-flex align-items-center justify-content-left'>
        {data.imageUrl && data.imageUrl.length > 0 && (
          <div className='image-crud-container rounded rounded-3  me-3'>
            <Image
              fluid
              className='rounded rounded-3 image-full'
              src={data.imageUrl}
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = ErrorImage;
              }}
            />
          </div>
        )}
        <span className={`align-middle text-wrap ${disabled ? 'text-tertiary-1' : ''}`}>{data.label}</span>
      </Col>
      <Col className='col-auto select-status-pill'>
        <ProductStatusPill isInOffer={false} isSoldOut={disabled} />
      </Col>
      <Col className='select-icon col-auto d-flex align-items-center justify-content-left'>
        <Icon name={'ico-down-arrow-circle'} />
      </Col>
    </Row>
  );

  return (
    <Container fluid className='px-1'>
      {data.isALink ? (
        <NavLink to={`${RoutesDirections.PRODUCT_ROUTE}/${userStore}/${data.value}`} className={({ isActive }) => (isActive ? 'active' : '')}>
          {AtributeComponent(data.disabled ?? false)}
        </NavLink>
      ) : (
        AtributeComponent(data.disabled ?? false)
      )}
    </Container>
  );
};

export default AttributeValue;
