import { BLANK_CHARACTER_IN_TEXT } from '@/data/libraries/ConfigParameters';
import validatePattern from '@/domain/utils/validatePattern';

/**
 * Caso de uso de validación de producto base
 * @param {IProductForm} product - data de producto base
 * @returns {IBooleanValidation[]} errors
 */

interface IProductBaseExcelValidation {
  row: number;
  /** Campo a evaluar */
  field:
    | 'VARIANTE_PRINCIPAL'
    | 'ID_TIENDA'
    | 'ID_PRODUCTO_BASE'
    | 'NOMBRE_PRODUCTO_BASE'
    | 'DESCRIPCION_PRODUCTO_BASE'
    | 'BANNER_PRODUCTO_BASE'
    | 'CATEGORÍA_PRODUCTO_BASE'
    | 'TAGS_PRODUCTO_BASE'
    | 'METODOS_DE_ENVÍO'
    | 'SUCURSAL'
    | 'CANTIDAD'
    | 'ID_VARIANTE'
    | 'SKU'
    | 'PRECIO_BASE';
  /** Mensaje de error */
  message: string;
  /** valor Filtro */
  filterKey: string;
}

const validateExcelInventoryUseCase = (excelFile) => {
  /** Objeto de respuesta con todos los errores */
  const errors: IProductBaseExcelValidation[] = [];

  /** Verifica si ya existe un producto base con el mismo nombre pero diferentes IDs*/
  const validateBaseProductNameByID = (excelFile) => {
    const baseProductsWithTheSameName = {};
    let error: boolean = false;

    for (const product of excelFile) {
      const { NOMBRE_PRODUCTO_BASE, ID_PRODUCTO_BASE } = product;

      const BASE_PRODUCT_NAME_ERROR = `Existen productos con el mismo nombre [${NOMBRE_PRODUCTO_BASE}] pero diferentes IDs.`;

      if (baseProductsWithTheSameName[NOMBRE_PRODUCTO_BASE] && baseProductsWithTheSameName[NOMBRE_PRODUCTO_BASE] !== ID_PRODUCTO_BASE) {
        errors.push({ row: product.row, field: 'NOMBRE_PRODUCTO_BASE', message: BASE_PRODUCT_NAME_ERROR, filterKey: NOMBRE_PRODUCTO_BASE });
        error = true;
      }

      baseProductsWithTheSameName[NOMBRE_PRODUCTO_BASE] = ID_PRODUCTO_BASE;
    }

    return error;
  };

  /** Errores genericos del archivo */
  const BLANK_ERROR = 'Tienes mas de 1 espacio en blanco seguidos o espacios en blanco antes o despues del texto escrito';
  const ID_ERROR = 'El ID debe tener un valor numérico';

  /** Valida linea por linea el archivo */
  excelFile.forEach((product) => {
    if (product.CANTIDAD !== '') {
      if (!validatePattern(BLANK_CHARACTER_IN_TEXT, product.CANTIDAD)) {
        errors.push({ row: product.row, field: 'CANTIDAD', message: BLANK_ERROR, filterKey: product.row });
      }
    }

    if (product.ID_PRODUCTO_BASE !== '' && isNaN(parseInt(product.ID_PRODUCTO_BASE))) {
      errors.push({ row: product.row, field: 'ID_PRODUCTO_BASE', message: ID_ERROR, filterKey: product.row });
    } else {
      //Si el valor es NaN le asigna 0
      product.ID_PRODUCTO_BASE = !isNaN(parseInt(product.ID_PRODUCTO_BASE)) ? parseInt(product.ID_PRODUCTO_BASE) : 0;
    }

    if (product.ID_VARIANTE !== '' && isNaN(parseInt(product.ID_VARIANTE))) {
      errors.push({ row: product.row, field: 'ID_VARIANTE', message: ID_ERROR, filterKey: product.row });
    } else {
      //Si el valor es NaN le asigna 0
      product.ID_VARIANTE = !isNaN(parseInt(product.ID_VARIANTE)) ? parseInt(product.ID_VARIANTE) : 0;
    }
  });

  /**Objeto que agrupa los productos por Tienda */
  const productsByStore = new Map<string, any[]>();

  /**Lógica de agrupación por ID */
  for (const item of excelFile) {
    const PRODUCT_BASE_ID = item.ID_TIENDA;
    if (!productsByStore.has(PRODUCT_BASE_ID)) {
      productsByStore.set(PRODUCT_BASE_ID, []);
    }
    productsByStore.get(PRODUCT_BASE_ID)?.push(item);
  }

  /**Validaciones a los elementos agrupados por STORE */
  productsByStore.forEach((element) => {
    validateBaseProductNameByID(element);

    const skus = new Set(); // Un conjunto para almacenar SKU únicos

    for (const product of element) {
      if (product.SKU !== '' && skus.has(product.SKU)) {
        const REPEATED_SKU_BY_STORE = `El  [SKU:${product.SKU}] esta asignado a 2 o mas variantes dentro de [STORE:${product.ID_TIENDA}]`;
        errors.push({ row: product.row, field: 'SKU', message: REPEATED_SKU_BY_STORE, filterKey: product.SKU });
      } else {
        skus.add(product.SKU);
      }
    }
  });

  /**Objeto que agrupa los productos por ID */
  const productsById = new Map<string, any[]>();

  /**Lógica de agrupación por ID */
  for (const item of excelFile) {
    const PRODUCT_BASE_ID = item.ID_PRODUCTO_BASE;
    if (!productsById.has(PRODUCT_BASE_ID)) {
      productsById.set(PRODUCT_BASE_ID, []);
    }
    productsById.get(PRODUCT_BASE_ID)?.push(item);
  }

  /**Validaciones a los elementos agrupados por ID */
  productsById.forEach((element) => {
    const BASE_PRODUCT_ID = element[0].ID_PRODUCTO_BASE;

    //Mensajes asociados a la agrupación por ID
    const NAME_MISSMATCH_ERROR = `El producto [ID:${BASE_PRODUCT_ID}] tiene diferentes nombres en sus variantes`;
    const DUPLICATED_ID_ERROR = `El producto [ID:${BASE_PRODUCT_ID}]  tiene variantes con los mismos IDs`;
    const CATEGORY_MISSMATCH_ERROR = `El producto [ID:${BASE_PRODUCT_ID}]  tiene diferentes catogorías en sus variantes`;
    const STORE_MISSMATCH_ERROR = `El producto  [ID:${BASE_PRODUCT_ID}]  tiene diferentes tiendas asignadas`;
    const DESCRIPTION_MISSMATCH_ERROR = `El producto  [ID:${BASE_PRODUCT_ID}]  tiene diferentes descripciones en sus variantes`;
    const BANNER_MISSMATCH_ERROR = `El producto [ID:${BASE_PRODUCT_ID}]  tiene diferentes urls de Banners en sus variantes`;
    const TAGS_MISSMATCH_ERROR = `El producto [ID:${BASE_PRODUCT_ID}]  tiene diferentes Tags en sus variantes`;
    const SHIPMENT_METHODS_MISSMATCH_ERROR = `El producto [ID:${BASE_PRODUCT_ID}]  tiene diferentes metodos de envío en sus variantes`;

    const firstObject = element[0];

    // Validaciones de Datos de Bases con el mismo ID pero diferentes valores
    for (let i = 1; i < element.length; i++) {
      const row = element[i];

      //Verifica que el ID de producto Base existe  y valida que el valor este igual en todos los demas campos de las variantes asociadas al mismo ID
      if (row.ID_PRODUCTO_BASE) {
        if (firstObject.NOMBRE_PRODUCTO_BASE !== row.NOMBRE_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'NOMBRE_PRODUCTO_BASE', message: NAME_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.ID_VARIANTE === row.ID_VARIANTE) {
          errors.push({ row: row.row, field: 'ID_VARIANTE', message: DUPLICATED_ID_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.CATEGORÍA_PRODUCTO_BASE !== row.CATEGORÍA_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'CATEGORÍA_PRODUCTO_BASE', message: CATEGORY_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.ID_TIENDA !== row.ID_TIENDA) {
          errors.push({ row: row.row, field: 'ID_TIENDA', message: STORE_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.DESCRIPCION_PRODUCTO_BASE !== row.DESCRIPCION_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'DESCRIPCION_PRODUCTO_BASE', message: DESCRIPTION_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.CATEGORÍA_PRODUCTO_BASE !== row.CATEGORÍA_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'CATEGORÍA_PRODUCTO_BASE', message: CATEGORY_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.BANNER_PRODUCTO_BASE !== row.BANNER_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'BANNER_PRODUCTO_BASE', message: BANNER_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (firstObject.TAGS_PRODUCTO_BASE !== row.TAGS_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'TAGS_PRODUCTO_BASE', message: TAGS_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }

        if (
          firstObject.ENVIO_NACIONAL_PRODUCTO_BASE !== row.ENVIO_NACIONAL_PRODUCTO_BASE ||
          firstObject.ENVIO_INTERNACIONAL_PRODUCTO_BASE !== row.ENVIO_INTERNACIONAL_PRODUCTO_BASE ||
          firstObject.RETIRO_EN_TIENDA_PRODUCTO_BASE !== row.RETIRO_EN_TIENDA_PRODUCTO_BASE
        ) {
          errors.push({ row: row.row, field: 'METODOS_DE_ENVÍO', message: SHIPMENT_METHODS_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_ID });
        }
      }
    }
  });

  /**Objeto que agrupa los productos por Nombre */
  const productsByName = new Map<string, any[]>();

  /**Lógica de agrupación por Nombre */
  for (const item of excelFile) {
    const productBaseName = item.NOMBRE_PRODUCTO_BASE;
    if (!productsByName.has(productBaseName)) {
      productsByName.set(productBaseName, []);
    }
    productsByName.get(productBaseName)?.push(item);
  }

  /**Validaciones a los elementos agrupados por Nombre */
  productsByName.forEach((element) => {
    const BASE_PRODUCT_ID = element[0].ID_PRODUCTO_BASE;
    const BASE_PRODUCT_NAME = element[0].NOMBRE_PRODUCTO_BASE;
    const BASE_PRODUCT_REF = BASE_PRODUCT_ID && BASE_PRODUCT_ID !== 0 ? `[ID:${BASE_PRODUCT_ID}]` : `[Nombre:${BASE_PRODUCT_NAME}]`;

    //Mensajes asociados a la agrupación por Nombre
    const STORE_MISSMATCH_ERROR = `El producto  ${BASE_PRODUCT_REF}  tiene diferentes tiendas asignadas`;
    const DESCRIPTION_MISSMATCH_ERROR = `El producto  ${BASE_PRODUCT_REF}  tiene diferentes descripciones en sus variantes`;
    const CATEGORY_MISSMATCH_ERROR = `El producto ${BASE_PRODUCT_REF}  tiene diferentes catogorías en sus variantes`;
    const BANNER_MISSMATCH_ERROR = `El producto ${BASE_PRODUCT_REF}  tiene diferentes urls de Banners en sus variantes`;
    const TAGS_MISSMATCH_ERROR = `El producto ${BASE_PRODUCT_REF}  tiene diferentes Tags en sus variantes`;
    const SHIPMENT_METHODS_MISSMATCH_ERROR = `El producto ${BASE_PRODUCT_REF}  tiene diferentes metodos de envío en sus variantes`;

    const firstObject = element[0];

    // Validaciones de Datos de Bases con el mismo Nombre pero diferentes valores
    for (let i = 1; i < element.length; i++) {
      const row = element[i];

      //Verifica que el Nombre de producto Base existe  y valida que el valor este igual en todos los demas campos de las variantes asociadas al mismo Nombre
      if ((!validateBaseProductNameByID || !row.ID_PRODUCTO_BASE || row.ID_PRODUCTO_BASE === 0) && row.NOMBRE_PRODUCTO_BASE) {
        if (firstObject.ID_TIENDA !== row.ID_TIENDA) {
          errors.push({ row: row.row, field: 'ID_TIENDA', message: STORE_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }

        if (firstObject.DESCRIPCION_PRODUCTO_BASE !== row.DESCRIPCION_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'DESCRIPCION_PRODUCTO_BASE', message: DESCRIPTION_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }

        if (firstObject.CATEGORÍA_PRODUCTO_BASE !== row.CATEGORÍA_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'CATEGORÍA_PRODUCTO_BASE', message: CATEGORY_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }

        if (firstObject.BANNER_PRODUCTO_BASE !== row.BANNER_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'BANNER_PRODUCTO_BASE', message: BANNER_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }

        if (firstObject.TAGS_PRODUCTO_BASE !== row.TAGS_PRODUCTO_BASE) {
          errors.push({ row: row.row, field: 'TAGS_PRODUCTO_BASE', message: TAGS_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }

        if (
          firstObject.ENVIO_NACIONAL_PRODUCTO_BASE !== row.ENVIO_NACIONAL_PRODUCTO_BASE ||
          firstObject.ENVIO_INTERNACIONAL_PRODUCTO_BASE !== row.ENVIO_INTERNACIONAL_PRODUCTO_BASE ||
          firstObject.RETIRO_EN_TIENDA_PRODUCTO_BASE !== row.RETIRO_EN_TIENDA_PRODUCTO_BASE
        ) {
          errors.push({ row: row.row, field: 'METODOS_DE_ENVÍO', message: SHIPMENT_METHODS_MISSMATCH_ERROR, filterKey: BASE_PRODUCT_NAME });
        }
      }
    }
  });

  return errors;
};

export default validateExcelInventoryUseCase;
export type { IProductBaseExcelValidation };
