import { IMenu } from '@/domain/interfaces/IMenu';
import { createSlice } from '@reduxjs/toolkit';

const initialState:IMenu = {    
        icon: '',
        id: 'category',
        title: 'Categorías',
        url: '#',
        slug:'categoria',
        children: [],
    }; 

/** Almacena el estado managementState de Redux con sus reducers
  * @constant {object}
*/
export const categoriesMenuSlice = createSlice({
  name: 'categoriesMenu',
  initialState,
  reducers: {
    updateCategoriesMenu: (state, action) => {
        state.children?.splice(0, state.children?.length, ...action.payload);
    },
  }
})

export const { updateCategoriesMenu } = categoriesMenuSlice.actions;

export default categoriesMenuSlice.reducer;
