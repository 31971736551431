import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import getFileURL from '@/domain/utils/getFileURL';

import Icon from '@/ui/assets/Icon';
import CloseIcon from '@/ui/assets/CloseIcon';

import AlertModal from '@/ui/components/modals/AlertModal';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';

/** Propiedades para el componente DragAndDrop.*/
type PropsDragAndDrop = {
  /** Tamaño del componente en pixeles */
  size?: string;
  /** Booleano que desactiva el componente */
  active?: boolean;
  /**Booleano que determina si esta en estado error */
  errorStatus?: boolean;
  /** Clase principal del componente */
  classMain?: string;
  /** Define los tipos de documentos. */
  fileType?: 'pdf' | 'media' | 'excel' | 'all';
  /** Booleano que define si se ve en miniatura */
  thumbnail?: boolean;
  /** Mensaje de error */
  errorMessage: string;
  /** Mensaje de éxito */
  successMessage?: string;
  /** Función al cambiar el archivo */
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Función al arrastrar archivos */
  externalHandleDrop?: (media: File[]) => void;
  /** Función al cargar el archivo */
  handleChargeFile?: () => void;
  /** Archivo adjunto que viene del padre*/
  fileAttached?: File | null;
  /** Función que actualiza el documento que viene del padre si se cambia el documento*/
  updateFile?: (newFile: File | null) => void;
  /** Muestra los botones Limpia y Carga */
  enabledButtons?: boolean;
  /** Función del boton cancelar */
  cancelButton?: (params: any) => void;
};

/** Componente para arrastrar y soltar elementos */
const DragAndDrop = ({
  size,
  active = true,
  errorStatus,
  fileType = 'all',
  thumbnail = false,
  classMain = '',
  successMessage,
  errorMessage,
  handleFileChange,
  externalHandleDrop,
  handleChargeFile,
  fileAttached = null,
  updateFile,
  enabledButtons = true,
  cancelButton,
}: PropsWithChildren<PropsDragAndDrop>): JSX.Element => {
  /** Referencia al inputFile */
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  /** Estado del archivo */
  const [attachedFile, setAttachedFile] = useState<File | null>(null);

  /** Estado de archivos adjuntos, por ahora no tiene efecto en el componente */
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);

  /** Tipos de archivo excel validos */
  const enabledExcelFiles:string[] = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroenabled.12'
  ];
  
  useEffect((): void => {
    fileAttached ? setAttachedFile(fileAttached) : handleReset();
  }, [fileAttached]);

  /** Tipos del archivo spreadsheet */
  const spreadsheetTypes: string = '.xls,.xlsx,.xlsm';

  /** Tipos del archivo spreadsheet */
  const pdfType: string = '.pdf';

  /** Tipos del archivo media */
  const mediaTypes: string = 'image/jpeg, image/png, image/jpg, video/mp4';

  /** Configura titulo principal y tipo de archivo aceptado en el InputFile */
  let acceptInInputFile: string = '';
  let mainTitle: string = `${active ? 'Adjuntar documento' : 'Archivo cargado por URL'}`;

  switch (fileType) {
    case 'media':
      acceptInInputFile = mediaTypes;
      active && (mainTitle = 'Agregar fotos o videos');
      break;
    case 'excel':
      acceptInInputFile = spreadsheetTypes;
      break;
    case 'pdf':
      acceptInInputFile = pdfType;
      break;
  }

  const handleFileChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    let error = false;
    const files = e.target.files && e.target.files !== null ? [...e.target.files] : [];

    files.forEach((element) => {
      switch (fileType) {
        case 'media':
          if (!['image/jpeg', 'image/png', 'image/jpg', 'video/mp4'].includes(element.type)) {
            error = true;
          }
          break;
        case 'excel':
          error = !enabledExcelFiles.includes(element.type.toLocaleLowerCase() ?? '');
          break;
        case 'pdf':
          if (element.type !== 'application/pdf') {
            error = true;
          }
          break;
      }
    });

    if (error) {
      setShowAlert(true);
    } else {
      handleFileChange && handleFileChange(e);
    }
  };

  const handleContainerClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    fileInputRef.current && fileInputRef.current.click(); // Simula el clic en el input de archivo oculto
  };

  /** Función para el arrastre del archivo */
  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];

    switch (fileType) {
      case 'media':
        const files: File[] = [];

        if (e.dataTransfer.items) {
          for (let i = 0; i < e.dataTransfer.items.length; i++) {
            const item = e.dataTransfer.items[i];

            if (!['image/jpeg', 'image/png', 'image/jpg', 'video/mp4'].includes(item.type)) {
              setShowAlert(true);
              files.splice(0, files.length);
              i = e.dataTransfer.items.length;
            } else if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                  files.push(file);
                }
              }
          }
        }

        setAttachedFiles(files);
        externalHandleDrop && externalHandleDrop(files);
        break;
      case 'excel':
        /**Boolean que define si el archivo es excels */
        if (enabledExcelFiles.includes(droppedFile.type.toLocaleLowerCase() ?? '')) {
          setAttachedFile(droppedFile);
          updateFile && updateFile(droppedFile);
        } else {
          setShowAlert(true);
        }

        break;
      case 'pdf':
        if (droppedFile.type === 'application/pdf') {
          setAttachedFile(droppedFile);
          updateFile && updateFile(droppedFile);
        } else {
          setShowAlert(true);
        }
        break;
      case 'all':
        setAttachedFile(droppedFile);
        updateFile && updateFile(droppedFile);
        break;
    }
  };

  /** Previene llamado al componente cuando se arrastra sobre el componente */
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  /** Reset de componente */
  const handleReset = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Resetea el valor del input
    }
    setAttachedFile(null);
    updateFile && updateFile(null);
  };

  const handleCancelButton = (e: React.MouseEvent) => {
    handleReset();
    cancelButton && cancelButton(e);
  };

  /** Estado Inicial de la alerta */
  const [showAlert, setShowAlert] = useState<boolean>(false);

  /** Evento que activa el modal y envia el archivo a carga */
  const handleSendFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    handleChargeFile && handleChargeFile();
  };

  /** INICIO PROPIEDADES DE LA IMAGEN DEL BANNER */

  /** Url de la imagen */
  const imageUrl: string = fileAttached ? getFileURL(fileAttached).url : '';
  /** Estilo para rellenar el background */
  const backgroundSize: string = 'cover';
  /** Propiedad para que no se repita */
  const backgroundRepeat: string = 'no-repeat';

  /** Objeto URL para renderizar (Dinamico) */
  const backgroundImage = { backgroundImage: `url(${imageUrl})` };

  /** Objeto background styles para renderizar (Estatico) */
  const backgroundStyles = {
    backgroundSize: backgroundSize,
    backgroundRepeat: backgroundRepeat,
  };

  /** FIN PROPIEDADES DE LA IMAGEN DEL BANNER */

  /** Textos  del componente*/
  const optionalMessage: string = 'También puedes arrastrarlo dentro del recuadro.';

  const primaryButtonText: string = 'CARGAR ARCHIVO';
  const secondaryButtonText: string = 'CANCELAR';

  return (
    <Container
      fluid
      style={{ height: size }}
      className='p-0 position-relative'
      onDrop={active ? handleDrop : undefined}
      onDragOver={active ? handleDragOver : undefined}>
      <Row
        className={`g-0 d-flex ${
          active && `cursor-hand shadow-hover border border-1 ${errorStatus ? 'border-complementary-2' : 'border-tertiary-1'}`
        } justify-content-center align-content-center mh-100 h-100 drag-and-drop bg-secondary-4 rounded rounded-3   ${classMain}`}
        onClick={active ? handleContainerClick : undefined}>
        {attachedFile ? (
          <>
            {fileType === 'media' ? (
              <Col className='h-100 rounded rounded-3 w-100' style={{ ...backgroundImage, ...backgroundStyles }} />
            ) : (
              <>
                <Col xs={4} className='text-end pe-5'>
                  <Icon
                    name={`${fileType === 'excel' ? 'ico-excel' : fileType === 'pdf' ? 'ico-pdf' : 'ico-file-upload'}`}
                    classMain={`${fileType === 'all' && 'svg-primary-1'} svg-64`}
                  />
                </Col>
                <Col xs={8}>
                  <div className='d-flex align-items-center'>
                    <span className='p-large-bold text-primary-1'>{attachedFile.name}</span>
                  </div>
                  <div>
                    <span className='p-large-regular text-tertiary-1'>{successMessage}</span>
                  </div>
                </Col>
              </>
            )}
          </>
        ) : (
          <Col xs={12} className='text-center'>
            <Icon
              name={fileType === 'media' ? 'ico-media' : 'ico-file-upload'}
              classMain={`svg-32  svg-tertiary-1 ${fileType === 'media' && 'svg-primary-1'}`}
            />
            <div>
              <span className={`p-large-regular ${fileType === 'media' ? 'text-tertiary-1' : active ? 'text-primary-1' : 'text-tertiary-1'}`}>
                {mainTitle}
              </span>
            </div>
            {!thumbnail && active && (
              <div className='mt-3'>
                <span className='p-large-regular text-secondary-2'>{optionalMessage}</span>
              </div>
            )}
          </Col>
        )}
      </Row>
      {attachedFile && fileType !== 'media' ? (
        <Icon name='ico-trash' onClick={handleReset} classMain='position-absolute bottom-0 me-2 mb-2 end-0 cursor-hand svg-primary-3' />
      ) : (
        attachedFile && (
          <CloseIcon
            onClick={handleReset}
            classMain='position-absolute top-0 end-0 text-end me-2 mt-2 btn-icon-light bg-black-transparent svg-primary-4 d-flex justify-content-center pt-1'
          />
        )
      )}
      {enabledButtons && (
        <Row className='g-0 d-flex justify-content-end'>
          <Col xs={5}>
            <TwoButtonsGroup
              firstButtonClass='btn-secondary-icon-small'
              firstButtonText={secondaryButtonText}
              firstButtonClick={handleCancelButton}
              secondButtonClass={`btn-primary-icon-small ${!attachedFile && 'disabled'}`}
              secondButtonText={primaryButtonText}
              secondButtonClick={handleSendFile}
            />
          </Col>
        </Row>
      )}
      <input
        type='file'
        ref={fileInputRef}
        className='d-none'
        onChange={handleFileChangeLocal}
        accept={acceptInInputFile}
        {...(fileType === 'media' ? { multiple: true } : {})}
      />

      {showAlert && <AlertModal hideAlert={() => setShowAlert(false)} type='danger' hide={10000} content={errorMessage} />}
    </Container>
  );
};

export default DragAndDrop;
