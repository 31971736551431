import { PropsWithChildren } from 'react';

import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

import { Col, Container, Row } from 'react-bootstrap';

import currencyFormat from '@/domain/utils/currencyFormat';

import { ICart } from '@/domain/interfaces/ICart';

/**Propiedades del componente */
interface PropsPurchaseTotals {
  /** Data del carrito o la orden que se genera depende de la interface */
  data: ICart;
}

/**
 * Componente Resumen Compra (Totales)
 * @component
 */
const PurchaseTotals = ({ data }: PropsWithChildren<PropsPurchaseTotals>): JSX.Element => {
  const PURCHASE_SUMMARY_TEXT: string = 'Resumen de la compra';
  const PRODUCT_TEXT: string = 'Producto';
  const SHIPPING_TEXT: string = 'Envío';
  const TAXES_TEXT: string = 'Impuestos';
  const TAX_EXEMPT_TEXT:string = 'Exento'
  const YOU_WILL_PAY_TEXT: string = 'Total';

  return (
    <Container fluid className="border my-2">
      <Row className="g-0 py-2 d-flex justify-content-start bg-primary-4">
        <Col xs={12}>
          <h4 className="text-primary-3 mb-4 text-start">{PURCHASE_SUMMARY_TEXT}</h4>
        </Col>
      </Row>
      <Row className="g-0 d-flex justify-content-start align-items-left bg-primary-4">
        <Col xs={5} lg={4}>
          <p className="text-secondary-2 text-start">{PRODUCT_TEXT}</p>
        </Col>
        <Col xs={7} lg={8} className="text-end">
          <p className="text-secondary-2">{currencyFormat(data.totalWithoutTax, ConfigNumberParameters.DECIMALS_ALLOWED)}</p>
        </Col>
      </Row>
      <Row className="g-0  d-flex justify-content-start align-items-left bg-primary-4">
        <Col xs={5} lg={4}>
          <p className="text-secondary-2 text-start">{SHIPPING_TEXT}</p>
        </Col>
        <Col xs={7} lg={8} className="text-end">
          <p className="text-secondary-2">{currencyFormat(data.totalShipping, ConfigNumberParameters.DECIMALS_ALLOWED)}</p>
        </Col>
      </Row>
      <Row className="g-0  d-flex justify-content-start align-items-left bg-primary-4">
        <Col xs={5} lg={4}>
          <p className="text-secondary-2 text-start">{TAXES_TEXT}</p>
        </Col>
        <Col xs={7} lg={8} className="text-end">
          <p className="text-secondary-2">{data.totalTaxes !== 0 ? currencyFormat(data.totalTaxes, ConfigNumberParameters.DECIMALS_ALLOWED) : TAX_EXEMPT_TEXT}</p>
        </Col>
      </Row>
      <Row className="g-0  d-flex justify-content-start align-items-left bg-primary-4 p-large">
        <Col xs={5} lg={4}>
          <h5 className="text-primary-1 text-start">{YOU_WILL_PAY_TEXT}</h5>
        </Col>
        <Col xs={7} lg={8} className="text-end">
          <h5 className="text-primary-1">{currencyFormat(data.totalAmount, ConfigNumberParameters.DECIMALS_ALLOWED)}</h5>
        </Col>
      </Row>
    </Container>
  );
};

export default PurchaseTotals;
