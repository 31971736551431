import { PropsWithChildren } from 'react';
import Autocomplete from 'react-google-autocomplete';

import { Form } from 'react-bootstrap';
import { IAddressComponent, IPlace } from '@/domain/interfaces/IPlace';

/** Propiedades del componente */
interface PropsInputMapForm extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Propiedad que define la clase del input */
  classMain?: string;
  /** Propiedad que define la clase del label */
  classLabel?: string;
  /** Propiedad que define el titulo del input */
  label?: string;
  /** Propiedad que define la referencia del input */
  inputRef?: any;
  /** Propiedad que define el mensaje de error del input */
  messageError?: string;
  /** Propiedad que define la clase del error */
  errorClass?: string;
  /** Evento que se desencadena al seleccionar la dirección que viene en el input tipo map */
  onPlaceSelected: (address: string, region: string) => void;
}

/** Componente Input
 * @component
 */
const InputMapForm = ({
  className = '',
  label = '',
  errorClass = '',
  messageError = '',
  inputRef = undefined,
  classMain = '',
  classLabel = 'p-regular-medium',
  onPlaceSelected,
}: PropsWithChildren<PropsInputMapForm>): JSX.Element => {
  const apiKeyGoogle: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

  const selectPlace = (place: IPlace) => {
    // Función para verificar si está disponible el número de calle en los componentes de dirección
    const streetAddressAvailable = (place: IPlace) => place?.address_components?.some((address) => address.types.includes('street_number'));

    // Función para formatear la dirección y numero de calle del lugar
    const formatAddress = (place: IPlace) => {
      // Filtrar los componentes de dirección relevantes
      const filteredComponents = place?.address_components?.filter((component) => ['route', 'street_number'].includes(component.types[0]));

      // mueve la ruta al principio
      if (filteredComponents.some((component) => component.types.includes('route'))) {
        const routeIndex = filteredComponents.findIndex((component) => component.types.includes('route'));
        const routeComponent = filteredComponents.splice(routeIndex, 1);
        filteredComponents.unshift(routeComponent[0]);
      }

      // Formatear los componentes de dirección en una cadena
      return filteredComponents
        .map((component) => component.long_name)
        .join(', ')
        .replace(',', ' ');
    };

    // Función para formatear la comuna y region del lugar
    const formatRegion = (place: IPlace) => {
      // Filtrar los componentes de dirección relevantes
      const filteredComponents = place?.address_components?.filter((component: IAddressComponent) =>
        ['locality', 'administrative_area_level_1'].includes(component.types[0]),
      );

      // Formatear los componentes de dirección en una cadena
      return filteredComponents.map((component: IAddressComponent) => component.long_name).join(', ');
    };

    // Verificar si está disponible el número de calle en los componentes de dirección
    const isStreetAddressAvailable = streetAddressAvailable(place);
    // Formatear la dirección si está disponible el número de calle, de lo contrario, mostrar 'error'
    const formattedAddress = isStreetAddressAvailable ? formatAddress(place) : 'error';
    // Formatear la dirección si está disponible el número de calle, de lo contrario, mostrar 'error'
    const formattedRegion = formattedAddress !== 'error' ? formatRegion(place) : 'error';
    // Llamar a la función 'onPlaceSelected' con la dirección formateada
    onPlaceSelected(formattedAddress, formattedRegion);
  };

  return (
    <div id='input-form' className={className}>
      <Form.Group>
        {label && (
          <div className='d-flex justify-content-between'>
            <Form.Label>
              <span className={`text-nowrap ${classLabel}`}>{label}</span>
            </Form.Label>
            <div>
              <span className={`p-regular-medium ${errorClass}`}>{messageError}</span>
            </div>
          </div>
        )}
        <div className='ils-form-control position-relative'>
          <Autocomplete
            ref={inputRef}
            className={`form-control ${classMain}`}
            inputAutocompleteValue='holi'
            apiKey={apiKeyGoogle}
            onPlaceSelected={selectPlace}
            options={{
              types: ['address'],
              componentRestrictions: { country: 'CL' },
            }}
          />
        </div>
      </Form.Group>
    </div>
  );
};
export default InputMapForm;
