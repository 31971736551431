import { PropsWithChildren, ComponentType, useState, useEffect } from 'react';

import { RoutesDirections } from '@/data/libraries/Routes';

import { NavigateFunction, useLocation, useNavigate, useParams, useSearchParams, type Location } from 'react-router-dom';

import { useScreenSize, type ScreenSize } from '@/ui/hooks/useScreenSize';

import { useAppDispatch, useAppSelector } from '@/ui/store/helperRedux';
import { change } from '@/ui/store/slices/catalogFilterState';

import { ListGroup, Row, Col, Container } from 'react-bootstrap';

import { IMenu } from '@/domain/interfaces/IMenu';
import { IBreadcrumbs } from '@/domain/interfaces/IBreadcrumbs';
import { IPaginationSettings } from '@/domain/interfaces/IPagination';

import lastPageName from '@/domain/utils/lastPageName';
import findCategory from '@/domain/utils/findCategory';
import orderObjectsByProperties, { SortCriteria } from '@/domain/utils/orderObjectsByProperties';
import getParentCategory from '@/domain/utils/getParentCategory';

import Icon from '@/ui/assets/Icon';

import IconList from '@/ui/components/IconList';
import Breadcrumbs from '@/ui/components/Breadcrumbs';
import BackClose from '@/ui/components/BackClose';
import GuideTip from '@/ui/components/GuideTip';
import ModalStore from '@/ui/components/store/ModalStore';
import EmptyList from '@/ui/components/store/EmptyList';
import PaginationStore from '@/ui/components/store/PaginationStore';

import './style.css';

/** Interfaz que define las propiedades que requiere el componente RenderItem. */
interface RenderItemProps<T> {
  /** Datos a renderizar.  */
  dataToRendering: T;
  /** Opción para indicar si se debe utilizar una representación en formato de cuadrícula.   */
  isGrid?: boolean;
  /** Opción para indicar si esta activo el filtro.   */
  filterActivated?: boolean;
}

/** Interfaz que define las propiedades que requiere el componente RenderItem. */
interface OrderItemsProps {
  /** Evento que oculta y muestra el modal. */
  handleShowModal: () => void;

  /** Maneja el evento de ordenamiento */
  handleOrder: any;

  /** Objeto que viene o no en el orden según criterio */
  objectOrder: SortCriteria<any>[];
}

/** Interfaz que define las propiedades de datos del RenderItem.*/
interface FilterItemsProps {
  /** Total de items mostrados*/
  totalItems?: string;
  /** Propiedad que muestra el modal. */
  showFilterModal?: boolean;
  /** Evento que oculta y muestra el modal. */
  handleShowFilterModal?: (e: MouseEvent) => void;
  /** Categorías asociadas a la búsqueda */
  associatedCategories?: IMenu[];
  /** Lista de categorías de los productos encontrados */
  productCategoriesFound?: string[];
}

/** Tipo que define las propiedades para una lista o cuadrícula de elementos. */
interface ListGridProps<T extends { id: string }> {
  /** Arreglo de objetos de cualquier tipo, pero cada objeto debe incluir una propiedad `id`.   */
  arrayData: T[];

  /** Ordena los datos según las variables de orden enviadas*/
  handleArrayData?: (data: T[]) => void;

  /** Categorías asociadas a la búsqueda */
  filterItemsAttributes: IMenu[];

  /** Configuración de paginado */
  paginationSettings: IPaginationSettings | null;

  /**
   * Componente React capaz de renderizar los objetos del arreglo.
   * El componente debe tener una propiedad llamada `dataToRendering` para recibir la data a renderizar.
   */
  RenderItem: ComponentType<RenderItemProps<T>>;

  /** Componente de filtrado */
  FilterItems: ComponentType<FilterItemsProps>;

  /** Componente de orden */
  OrderItems: ComponentType<OrderItemsProps>;

  productCategoriesFound?: string[];
}

/**
 * Componente para renderizar otros componentes en lista o cuadrícula
 * @component
 */
const ListGrid = <
  T extends {
    id: string;
  },
>({
  arrayData = [],
  handleArrayData,
  filterItemsAttributes,
  paginationSettings,
  RenderItem,
  FilterItems,
  OrderItems,
  productCategoriesFound = [],
}: PropsWithChildren<ListGridProps<T>>) => {
  /**  Hook para trabajar con document location  */
  const location: Location = useLocation();

  /** Actualización de estado Redux */
  const dispatch = useAppDispatch();

  /** Hook para trabajar con la URL y query parameters */
  const navigate: NavigateFunction = useNavigate();

  /** Hook que usa los parámetros de la URL */
  const param = useParams();

  /** Trae los parámetros de la url para renderizar los elementos */
  const { categorySlug } = useParams();

  /**Trae los parámetros de búsqueda */
  const [searchParams] = useSearchParams();

  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  /**
   * Contador total de items renderizados.
   * Cuenta los elementos que se renderizan y los formatea con 1 o 2 ceros antes si el valor es menor a 100.
   */
  const totalItems: string = paginationSettings?.totalItems.toString() ?? '0';

  /** Estado inicial breadcrumbs de la página. */
  const [breadcrumbsList, setBreadcrumbsList] = useState<IBreadcrumbs[]>([]);

  /**  Estado inicial de la categoría actual  */
  const [currentCategory, setCurrentCategory] = useState<any>([]);

  /**  Objeto Redux que almacena la data del menu   */
  const navCategory: IMenu = useAppSelector((state) => state.categoriesMenuState);

  /**  Estado inicial del modal de orden*/
  const [showOrderModal, setShowOrderModal] = useState<boolean>(false);

  /** Controlador de Orden  */
  const [objectOrder, setObjectOrder] = useState<SortCriteria<T>[]>([]);

  /**valida si el sessionStorage tiene el valor de storeFilterState */
  const showFilterMenuHidden = sessionStorage.getItem('storeFilterState') === 'false';

  /**valida si el sessionStorage tiene el valor de storeFilterState */
  const isGridHidden = sessionStorage.getItem('gridState') === 'false';

  /**
   * Estado inicial del menu de filtro
   * Inicia en True si es entorno desktop y falso si es entorno mobile
   */
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(!showFilterMenuHidden);

  /** Estado inicial que indica si se muestra el submenu en forma de cuadrícula o lista. */
  const [isGrid, setIsGrid] = useState<boolean>(!isGridHidden);

  /**Estado de la modal de filtro */
  const showFilterModal: boolean = useAppSelector((state) => state.catalogFilterState.value);

  /**
   * Cambia entre la vista de cuadrícula y la vista de lista.
   * @returns {void}
   */
  const handleGrid = (): void => {
    setIsGrid(!isGrid);
  };

  /**  Evento que muestra y oculta el modal de orden  */
  const handleShowOrderModal = () => {
    setShowOrderModal(!showOrderModal);
  };

  /**
   * Función que muestra el orden que esta seteado
   * @param objectOrderFromComponent
   */
  const handleOrder = (objectOrderFromComponent: SortCriteria<T>[]): void => {
    setObjectOrder(objectOrderFromComponent);
    if (handleArrayData && objectOrderFromComponent.length > 0) {
      handleArrayData(orderObjectsByProperties(arrayData, objectOrderFromComponent));
    }
    handleShowOrderModal();
  };

  /** Evento que muestra y oculta el menu de filtro  */
  const handleShowFilterMenu = (e: MouseEvent) => {
    e.preventDefault();
    setShowFilterMenu(!showFilterMenu);
  };

  /**Función para click */
  const handleMenuClick = () => {
    handleShowFilterMenu(new MouseEvent('click'));
  };

  /** Evento que muestra y oculta el menu de filtro  */
  const handleShowFilterModal = (e: MouseEvent) => {
    e.preventDefault();
    dispatch(change(!showFilterModal));
  };

  /**Función para click */
  const handleModalClick = () => {
    handleShowFilterModal(new MouseEvent('click'));
  };

  /**
   * Función que renderiza el componente entrante con un elemento del arreglo.
   * @template T Tipo de los objetos del arreglo.
   * @param {T} element Elemento del arreglo a renderizar.
   * @returns {JSX.Element} Elemento JSX resultante de la renderización.
   */
  const renderProduct = (element: T): JSX.Element => (
    <ListGroup.Item as='li' className={`rounded rounded-3  p-0 ${isMobile && 'shadow'}`} key={element.id}>
      {RenderItem && <RenderItem dataToRendering={element} isGrid={isGrid} filterActivated={showFilterMenu} />}
    </ListGroup.Item>
  );

  /**
   * Renderiza la vista tipo galería o Lista.
   * @returns {JSX.Element} - Elemento JSX que representa la galería renderizada.
   */
  const renderItems = (): JSX.Element => {
    let find: string = '';
    if (arrayData.length < 1) {
      find = searchParams.get('find') ?? '';
    }

    return (
      <>
        {arrayData.length > 0 ? (
          <>
            {arrayData.map((element: T) => {
              const listSize: number = showFilterMenu ? 4 : 3;
              return (
                <Col
                  id='product'
                  xs={isGrid ? 6 : 12}
                  lg={isGrid ? listSize : 12}
                  className='text-center justify-content-end text-break'
                  key={element.id}>
                  {renderProduct(element)}
                </Col>
              );
            })}
          </>
        ) : (
          <EmptyList textSearched={find === '' ? SEARCH_TEXT : find} />
        )}
      </>
    );
  };

  useEffect(() => {
    sessionStorage.setItem('storeFilterState', JSON.stringify(showFilterMenu));
    /** Navega a la tienda para aplicar los filtros */
    navigate(
      `${RoutesDirections.STORE_ROUTE}/${(paginationSettings?.currentPage ?? 0) + 1}${categorySlug ? `/categoria/${categorySlug}` : ''}${
        location.search
      }`,
      {
        state: { originURL: location.pathname },
        replace: true,
      },
    );
  }, [showFilterMenu]);

  useEffect(() => {
    sessionStorage.setItem('gridState', JSON.stringify(isGrid));
  }, [isGrid]);

  /**
   * useEffect para mostrar la categoría actual y los breadcrumbs.
   * Se ejecuta cuando hay cambios en la ubicación, la categoría de navegación y el slug de categoría proporcionado.
   */
  useEffect((): void => {
    // Buscar la categoría actual basada en la URL actual
    const category: IMenu | null = findCategory(navCategory, location.pathname);
    if (category) {
      /** Cambia el estado de la categoría actual por la categoría encontrada.*/
      setCurrentCategory(category);

      // Obtener el primer padre de la categoría actual
      const firstParentCategory = navCategory.children && param.categorySlug && getParentCategory(navCategory.children, param.categorySlug);
      if (firstParentCategory && firstParentCategory.slug !== '') {
        // Obtener el segundo padre de la categoría actual
        const secondParentCategory: any = navCategory.children && getParentCategory(navCategory.children, firstParentCategory.slug);
        if (secondParentCategory && secondParentCategory.slug !== '') {
          // Si ambos padres existen, renderizar los breadcrumbs con un arreglo específico
          setBreadcrumbsList([
            { label: BREADCRUMBS_DEFAULT_TEXT, url: RoutesDirections.MAIN_ROUTE },
            { label: secondParentCategory.title, url: secondParentCategory.url },
            { label: firstParentCategory.title, url: firstParentCategory.url },
            { label: lastPageName(location.pathname), url: location.pathname },
          ]);
        } else {
          // Si solo existe un padre, renderizar los breadcrumbs con un arreglo específico
          setBreadcrumbsList([
            { label: BREADCRUMBS_DEFAULT_TEXT, url: RoutesDirections.MAIN_ROUTE },
            { label: firstParentCategory.title, url: firstParentCategory.url },
            { label: lastPageName(location.pathname), url: location.pathname },
          ]);
        }
      } else {
        // Si no tiene padres, renderizar los breadcrumbs con un arreglo específico
        setBreadcrumbsList([
          { label: BREADCRUMBS_DEFAULT_TEXT, url: RoutesDirections.MAIN_ROUTE },
          { label: lastPageName(location.pathname), url: location.pathname },
        ]);
      }
    } else {
      // Si no se encuentra la categoría, establecer la categoría actual como un arreglo vacío
      setBreadcrumbsList([]);
      setCurrentCategory([]);
    }
  }, [location, navCategory, param.categorySlug]);

  /**UseEffect para vaciar el arreglo cuando se cambia el pathname o los criterios de búsqueda */
  useEffect((): void => {
    setObjectOrder([]);
  }, [location.pathname, location.search]);

  /** FIN RENDERIZADO LISTA O GALERÍA */

  /** CONSTANTES DE TEXTOS */

  const FILTER_TITLE: string = 'Filtrar';
  const SHOW_FILTER_TEXT: string = 'Mostrar Filtro';
  const SHOW_FILTER_TEXT_DETAILS: string = 'Mostrar menú lateral izquierdo de filtros de búsqueda';
  const HIDE_FILTER_TEXT: string = 'Ocultar Filtro';
  const HIDE_FILTER_TEXT_DETAILS: string = 'Ocultar menú lateral izquierdo de filtros de búsqueda';
  const ORDER_TEXT: string = 'Ordenar';
  const ORDER_TEXT_DETAILS: string = 'Organizar resultados de búsqueda';
  const GRID_VIEW_TEXT: string = 'Vista de galería';
  const LIST_VIEW_TEXT: string = 'Vista de lista';
  const RESULT_TEXT: string = 'Resultados';
  const SEARCH_TEXT: string = 'Tu Búsqueda';
  const BREADCRUMBS_DEFAULT_TEXT: string = 'Inicio';

  return (
    <Container fluid className='px-0 bg-secondary-5'>
      {isMobile ? (
        <>
          <Container fluid className='bg-primary-4'>
            <Row className='p-2 g-0'>
              <Col xs={12}>
                {currentCategory?.icon && <Breadcrumbs />}
                <BackClose classMain='justify-content-between align-items-center pt-1'>
                  {currentCategory.icon ? (
                    <IconList iconName={currentCategory.icon} classTitle='text-primary-3 p-large-bold' title={currentCategory.title} />
                  ) : (
                    <Breadcrumbs />
                  )}
                  <span className='text-tertiary-4'>{`${RESULT_TEXT} ${totalItems}`}</span>
                </BackClose>
              </Col>
            </Row>
            <Row className='g-0 text-secondary-3 svg-secondary-3 pb-2 d-flex justify-content-between'>
              <Col xs={5} className='px-3' onClick={handleModalClick}>
                <IconList classMain='p-2 btn-list-link-icon-light-small' iconName='ico-filter' classTitle='p-large-medium' title={FILTER_TITLE} />
              </Col>
              <Col xs={5} onClick={handleShowOrderModal}>
                <IconList classMain='p-2 btn-list-link-icon-light-small' iconName='ico-order' classTitle='p-large-medium' title={ORDER_TEXT} />
              </Col>
              <Col xs={2} className='align-self-center text-center' onClick={handleGrid}>
                {isGrid ? <Icon name='ico-grid' /> : <Icon name='ico-list' />}
              </Col>
            </Row>
          </Container>

          <ListGroup as='ul' className='d-flex flex-row flex-wrap'>
            {renderItems()}
          </ListGroup>
          {paginationSettings && (
            <Col xs={12} className='d-flex align-items-end'>
              <PaginationStore settings={paginationSettings} />
            </Col>
          )}
          {showFilterModal && (
            <ModalStore showModal={showFilterModal}>
              <FilterItems
                showFilterModal={showFilterModal}
                handleShowFilterModal={handleShowFilterModal}
                associatedCategories={filterItemsAttributes}
                productCategoriesFound={productCategoriesFound}
              />
            </ModalStore>
          )}

          {showOrderModal && (
            <ModalStore showModal={showOrderModal}>
              <OrderItems handleShowModal={handleShowOrderModal} handleOrder={handleOrder} objectOrder={objectOrder} />
            </ModalStore>
          )}
        </>
      ) : (
        <Container className='py-3 px-1'>
          <Row className='g-0'>
            <Col xs={12} className='justify-content-end px-3'>
              <Row className='mx-4'>
                <Col xs={12} className='py-2 bg-primary-4 shadow rounded svg-secondary-3 '>
                  <Row>
                    <Col md={7} lg={9} xxl={8}>
                      <Breadcrumbs list={breadcrumbsList} />
                    </Col>
                    <Col md={5} lg={3} xxl={4}>
                      <Row>
                        <Col xs={5} className='d-flex justify-content-end cursor-hand' onClick={handleMenuClick}>
                          <GuideTip position='bottom' message={showFilterMenu ? HIDE_FILTER_TEXT_DETAILS : SHOW_FILTER_TEXT_DETAILS}>
                            <IconList
                              iconName='ico-filter'
                              classTitle='p-large-medium'
                              title={showFilterMenu ? HIDE_FILTER_TEXT : SHOW_FILTER_TEXT}
                            />
                          </GuideTip>
                        </Col>
                        <Col xs={5} className='text-start cursor-hand' onClick={handleShowOrderModal}>
                          <GuideTip position='bottom' message={ORDER_TEXT_DETAILS}>
                            <IconList iconName='ico-order' classTitle='p-large-medium' title={ORDER_TEXT} />
                          </GuideTip>
                        </Col>
                        {showOrderModal && (
                          <Col xs={3} className='position-absolute bg-primary-4 z-1 shadow rounded rounded-3 pt-2 pb-3 mt-5'>
                            <div className='arrow-top'></div>
                            <OrderItems handleShowModal={handleShowOrderModal} handleOrder={handleOrder} objectOrder={objectOrder} />
                          </Col>
                        )}
                        <Col xs={2} className='text-center cursor-hand' onClick={handleGrid}>
                          {isGrid ? (
                            <GuideTip position='bottom' message={LIST_VIEW_TEXT} classMain='p-1'>
                              <Icon name='ico-list' />
                            </GuideTip>
                          ) : (
                            <GuideTip position='bottom' message={GRID_VIEW_TEXT} classMain='p-1'>
                              <Icon name='ico-grid' />
                            </GuideTip>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className='mx-2 mt-3'>
                <Col xs={3} className={`mt-3 h-100 p-0 ${!showFilterMenu && 'invisible position-absolute'}`}>
                  <Col xs={11} className='mx-auto bg-primary-4 pb-3 rounded '>
                    <FilterItems
                      handleShowFilterModal={handleShowFilterMenu}
                      totalItems={totalItems}
                      associatedCategories={filterItemsAttributes}
                      productCategoriesFound={productCategoriesFound}
                    />
                  </Col>
                </Col>
                <Col xs={showFilterMenu ? 9 : 12} className={`${showFilterMenu ? 'p-0 ' : 'px-1'}`}>
                  <Row>
                    <Col xs={12}>
                      <ListGroup as='ul' className='d-flex flex-row flex-wrap'>
                        {renderItems()}
                      </ListGroup>
                    </Col>
                    {paginationSettings && (
                      <Col xs={12} className='d-flex align-items-end'>
                        <PaginationStore settings={paginationSettings} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default ListGrid;
