/**
    * Da formato a los a las cantidades 
    * @param {string} value 
    * @returns {string} value con formato
*/
const quantityFormat = (value: string) => {

  let response: string = (
    // Obtiene el valor del input y elimina todos los caracteres que no sean dígitos ni comas
    value.replace(/[^\d,]/g, '') ??
    ''
  ).replace(',', '.'); // Reemplaza las comas por puntos

  return response;

}

export default quantityFormat;
