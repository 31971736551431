import { useEffect, useRef, useState } from 'react';

import { useCreateBaseProductForm } from '@/ui/hooks/useCreateBaseProductForm';
import ProductsController from '@/controllers/ProductsController';
import { useAppSelector } from '@/ui/store/helperRedux';

import { IProductValidation } from '@/domain/interfaces/IProductResponse';
import { IProductForm } from '@/domain/interfaces/IProduct';

import { Col, Row } from 'react-bootstrap';

import InputForm from '@/ui/components/forms/InputForm';
import TwoButtonsGroup from '@/ui/components/TwoButtonsGroup';
import MainTitle from '@/ui/components/MainTitle';
import { ConfigNumberParameters } from '@/data/libraries/ConfigParameters';

/** Propiedades del componente */
interface PropsBaseProductName {
  mainTitle: string;
  /** Objeto tipo useRef que almacenará la selección de registros */
  formRef: React.RefObject<IProductForm>;
  /** Función para actualizar el atributo rowSelectionRef  */
  updateFormRef: (newFormRef: IProductForm) => void;
  /** Función para el botón del siguiente paso */
  handleNextButton: (e: MouseEvent) => void;
  /** Función para el botón de cancelar*/
  handleCancelButton: (e: MouseEvent) => void;
}

/**
 * Componente para configurar la sección de Nombre de Producto Base
 * @component
 */
const BaseProductName = ({ mainTitle, formRef, updateFormRef, handleNextButton, handleCancelButton }: PropsBaseProductName): JSX.Element => {
  /** Controlador de inventario */
  const { addProductBase } = ProductsController();

  /** Trae la data del usuario registrado */
  const userLogued = useAppSelector((state) => state.userState);

  /** Referencia al input name */
  const nameRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

  /** Estado que Detecta si es un nombre valido para habilitar el botón */
  const [isAValidName, setIsAValidName] = useState<boolean>(false);

  /** Estado activo del formulario (lo usamos para desactivar el botón al enviar el formulario) */
  const [isActive, setIsActive] = useState<boolean>(true);

  /** Declaración inicial del campo de alertas como oculto */
  const [nameRules, setNameRules] = useState<boolean>(formRef.current?.name !== '');

  /** Define el estado inicial del <li> de validación de longitud minima de texto */
  const [lengthMinLi, setLengthMinLi] = useState<string>('text-complementary-2');
  /** Define el estado inicial del <li> de validación de longitud maxima de texto */
  const [lengthMaxLi, setLengthMaxLi] = useState<string>('text-complementary-2');
  /** Define el estado inicial del <li> de validación de nombre existente */
  const [existingNameLi, setExistingNameLi] = useState<string>('text-complementary-2');

  /** Se fabrica un objeto de formulario para producto base */
  const productBaseFormClean = useCreateBaseProductForm();

  /** Manejador validar y/o enviar el wizard-form */
  const handleValidate = async (send: boolean = false): Promise<void> => {
    const formProduct: IProductForm = formRef.current
      ? JSON.parse(JSON.stringify(formRef.current))
      : JSON.parse(JSON.stringify(productBaseFormClean));

    formProduct.name = nameRef.current!.value.trim() ?? '';

    await addProductBase(formProduct, false, 'baseProductName', userLogued.user?.token ?? '').then((response: IProductValidation) => {
      updateErrorMessages(response);

      send && updateFormRef(formProduct);
    });
  };

  /** Función manejadora de los estados de los mensajes de error */
  const updateErrorMessages = (response: IProductValidation): void => {
    if (response.kindError === undefined) return;

    const setErrorMessageClass = (errorField: string | undefined, setter: React.Dispatch<React.SetStateAction<string>>): void => {
      setter(errorField ? 'text-complementary-2' : 'text-complementary-1');
    };

    setIsAValidName(!response.error);

    setErrorMessageClass(response.kindError.minError, setLengthMinLi);
    setErrorMessageClass(response.kindError.maxError, setLengthMaxLi);
    setErrorMessageClass(response.kindError.existingNameError, setExistingNameLi);
  };

  /** Función que se ejecuta al completar el formulario */
  const handleComplete = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    nameRef.current!.value = nameRef.current ? e.target.value : '';
    handleValidate();
  };

  /** Evento que envía el formulario cuando se presiona el botón */
  const handleSendInfo = async () => {
    setIsActive(false);
    await handleValidate(true).then(() => {
      handleNextButton(new MouseEvent('click'));
    });
  };

  /** UseEffect para validar y traer la información guardada */
  useEffect((): void => {
    if (formRef.current) {
      nameRef.current!.value = formRef.current?.name ?? '';
      handleValidate();
    }
    //desplaza la ventana a la posición 0,0 (arriba, izquierda) al cargar el componente
    window.scrollTo(0, 0);
  }, []);

  /**CONSTANTES DE TEXTO */

  const REQUIRED_TEXT: string = '(Obligatorio)';

  const BASE_PRODUCT_NAME_DESCRIPTION: string =
    'Ingresa el nombre de tu producto base, en caso de que aplique incluye en el nombre el tipo de producto, la marca o el modelo';
  const BASE_PRODUCT_NAME_LABEL: string = `Nombre ${REQUIRED_TEXT}`;

  const BASE_PRODUCT_NAME_INDICATIONS_TITLE: string = 'Crea un nombre que:';
  const BASE_PRODUCT_NAME_MINIMUM_LENGTH: string = `Contenga al menos ${ConfigNumberParameters.BASE_PRODUCT_NAME_MIN_LENGTH} caracteres`;
  const BASE_PRODUCT_NAME_MAXIMUM_LENGTH: string = `Contenga máximo ${ConfigNumberParameters.BASE_PRODUCT_NAME_MAX_LENGTH} caracteres`;
  const BASE_PRODUCT_NAME_STORE_DUPLICATED: string = 'El nombre del producto debe ser único en la tienda';
  const BASE_PRODUCT_NAME_INDICATIONS_EXTRA_INFORMATION: string = 'No incluyas condiciones de venta o descripción de ofertas';

  return (
    <Row className='d-flex justify-content-center'>
      <Col lg={11}>
        <MainTitle boldTitle={mainTitle} detailsText={BASE_PRODUCT_NAME_DESCRIPTION} />
        <Col lg={12}>
          <InputForm
            className='mb-3'
            inputRef={nameRef}
            label={BASE_PRODUCT_NAME_LABEL}
            classLabel='p-regular-medium'
            type='textarea'
            rowsValue={3}
            onChange={handleComplete}
            onFocus={() => setNameRules(true)}
          />
        </Col>
        <Col lg={11} className='mx-auto'>
          {nameRules && (
            <Col lg={5} className='mb-3 mx-auto'>
              <span className='text-black p-title-medium'>{BASE_PRODUCT_NAME_INDICATIONS_TITLE}</span>
              <ul className='text-secondary-3'>
                <li className={`p-regular-medium ${lengthMinLi}`}>
                  <span className='p-title-medium'>{BASE_PRODUCT_NAME_MINIMUM_LENGTH}</span>
                </li>
                <li className={`p-regular-medium ${lengthMaxLi}`}>
                  <span className='p-title-medium'>{BASE_PRODUCT_NAME_MAXIMUM_LENGTH}</span>
                </li>
                <li className={`p-regular-medium ${existingNameLi}`}>
                  <span className='p-title-medium'>{BASE_PRODUCT_NAME_STORE_DUPLICATED}</span>
                </li>
              </ul>
              <span className='p-title-medium'>{BASE_PRODUCT_NAME_INDICATIONS_EXTRA_INFORMATION}</span>
            </Col>
          )}
        </Col>
        <Col lg={7} className='mx-auto'>
          {nameRules && (
            <TwoButtonsGroup
              firstButtonText={'CANCELAR REGISTRO'}
              firstButtonClass='btn-secondary-text-small'
              firstButtonClick={handleCancelButton}
              secondButtonText={'GUARDAR Y CONTINUAR'}
              secondButtonClass={`btn-primary-text-small ${(!isAValidName || !isActive) && 'disabled'}`}
              secondButtonClick={handleSendInfo}
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default BaseProductName;
